import React from "react";
import EditRelationship from "../../Components/EditRelationship";
import EditRelationshipModal from "../../Components/EditRelationship/editModal";

function AddRelationShipChatModal({
  title,
  onClose,
  contact,
  onAddClick,
  onAddedRelationShip,
}) {
  return (
    <div className="careeradvice-modal-overlay">
      <div className="careeradvice-modal">
        <div className="careeradvice-modal-header">
          <div>
            <h2 className="careeradvice-modal-title">{title}</h2>
          </div>
          {/* <h2 className="careeradvice-modal-title" style={{marginBottom:'20px'}}>to achieve</h2> */}
          <div>
            <button className="careeradvice-close-button" onClick={onClose}>
              ×
            </button>
          </div>
        </div>
        <div>
          <EditRelationshipModal
            contact={contact}
            onUpdateContact={onAddClick}
            onAddedRelationShip={onAddedRelationShip}
          />
        </div>
      </div>
    </div>
  );
}

export default AddRelationShipChatModal;

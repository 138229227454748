// LoginPage.js
import React, { useEffect, useState } from "react";
import LeftSection from "./LeftSection"; // Import the LeftSection component
import RightSection from "./RightSection";
import "./style.css"; // Import necessary styles
import { useHeader } from "../../Providers/HeaderContextProvider";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const LoginPage = () => {
  const { setHeaderContent } = useHeader();
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderContent(false);
    if (Cookies.get("idtoken")) {
      navigate("/dashboard");
    }
  }, []);

  const [step, setStep] = useState(1); // State to manage steps

  const handleNext = () => {
    setStep(step + 1); // Increment step on Next
  };

  const handleBack = () => {
    setStep(step > 1 ? step - 1 : 1); // Prevent going below step 1
  };

  return (
    <div className="login-container">
      {/* Left section with the carousel and content */}
      <LeftSection />

      {/* Right section for login form or step-based content */}
      <RightSection
        step={step}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    </div>
  );
};

export default LoginPage;

import React, { useEffect, useState } from "react";
import arrowLeft from "../../Assets/icon/arrow-narrow-left.svg";
import GradientBackground from "../../Components/AddRelationshipHeaderGradient";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../Providers/HeaderContextProvider";
import AddMembersModal from "../../Components/MembershipAddmemberModal";
import AnnualMembershipList from "./AnnualMembershipList";
import InvitetoAris from "../RelationshipProfile/Invitetoaris";
import ContactCard from "../../Components/ManageRelationshipContactcard";
import CoachingMinutesModal from "../../Components/CoachingMinutesModal";
import { ReactComponent as Downarrow } from "../../Assets/icon/chevron-down-blue.svg";
import AnnualMembershipplans from "./AnnualMembershipplans";
import TakeOwnership from "./TakeOwnership";
import { getUserSubscriptionDetails } from "../../Services/subscription"; // Import your new API function
import RequestMinutes from "./RequestMinutes";
import "./style.css";
import { useSearchParams } from "react-router-dom";
import Allset from "../../Components/AllsetComponent";
import FaithandBelief from "./FaithandBelief";
import { getProducts, getPricing } from "../../Services/subscription";
import BuyMinutes from "./BuyMinutes";

function ManageMembership() {
  const [searchParams] = useSearchParams();
  const ispurchased = searchParams.get("ispurchased");
  // var successmode = searchParams.get("mode");
  const navigate = useNavigate();
  const { setHeaderContent } = useHeader();
  const [isAddOpen, setisAddOpen] = useState(false);
  const [isAnnualList, setisAnnualList] = useState(false);
  const [isInviteOpen, setisInviteOpen] = useState(false);
  const [isCoachingMinutesOpen, setIsCoachingMinutesOpen] = useState(false);
  const [isFaithandBelief, setisFaithandBelief] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [togglevalue, settogglevalue] = useState("primary");
  const [isArisAnnualMembership, setisArisAnnualMembership] = useState(false);
  const [isTakeOwnership, setisTakeOwnership] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Reqcoachingminutes, setsetReqcoachingminutes] = useState("");
  const [isBuyMinutes, setisBuyMinutes] = useState(false);
  const [listedMembers, setlistedMaembers] = useState(0);
  const [selectedAddonUser, setselectedAddonUser] = useState([]);
  const [currentMemberIndex, setCurrentMemberIndex] = useState(0);
  const [allMemberData, setAllMemberData] = useState([]);
  const [isAllSet, setAllset] = useState(
    ispurchased === "confirm" || ispurchased === "cancel" ? true : false
  );
  const [products, setProducts] = useState([]);
  const [productLoading, setproductLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [selectedValueforBuyMinutes , setselectedValueforBuyMinutes] = useState("");

  useEffect(() => {
    setHeaderContent(false);
    fetchSubscriptionData();
    fetchProducts();
   
  }, []);

  useEffect(()=>{
    console.log(!isAllSet , localStorage.getItem("successMode"),"vv1")
    
    if(localStorage.getItem("successMode")){setAllset(true)}
  });

  const successmode = searchParams.get("mode")
    ? searchParams.get("mode")
    :localStorage.getItem("successMode");

  const rearrangePlans = (products) => {
    return products.sort((a, b) => {
      const getOrder = (planType) => {
        if (planType === "main") return 1;
        if (planType === "addon") return 2;
        return 3;
      };

      const planA = a.metadata?.plan_type || "";
      const planB = b.metadata?.plan_type || "";

      return getOrder(planA) - getOrder(planB);
    });
  };

  const getbuymins = (pricemetadata) => {
    setselectedValueforBuyMinutes(pricemetadata?.metadata?.product_value)
    console.log(pricemetadata,"pricebuymins");
    localStorage.setItem("buyMinsSuccessMode",pricemetadata?.metadata?.product_value)
  };

  const getaddedCoachingMins = (purchasedMins) => {

    localStorage.setItem("addCoachingMinsSuccessMode",purchasedMins?.metadata?.product_value)
  };

  const fetchProducts = async () => {
    try {
      const res = await getProducts();
      console.log(res, "pre111");
      const pricingPromises = res?.payload?.map(async (product, index) => {
        console.log(product?.metadata?.product_type, index);
        // if (
        //   product?.metadata?.product_type === "all" ||
        //   product?.metadata?.product_type === "pre"
        // ) {
        const pricingResponse = await getPricing(product?.id);
        return {
          ...product,
          pricingDetails: [...pricingResponse?.payload],
        };
        // }
      });
      const resolvedPricingData = await Promise.all(pricingPromises);
      console.log(resolvedPricingData, "resolvedPricingData");
      setProducts(rearrangePlans(resolvedPricingData));
      setproductLoading(false);
    } catch (err) {
      console.error(err, "product error");
      setproductLoading(false);
    }
  };

  let faithProductSelectedID =
    products
      ?.find(
        (product) =>
          product?.metadata?.plan_type === "addon" &&
          product?.name === "Integrate Your Faith and Belief System"
      )
      ?.pricingDetails?.find(
        (prod) =>
          prod?.recurring?.interval === subscriptionData?.membership_type
      )?.unit_amount / 100;

  console.log("faithProductSelectedID", faithProductSelectedID);

  const handleAllsetClose = () => {
    localStorage.removeItem("successMode");
    localStorage.removeItem("buyMinsSuccessMode");
    localStorage.removeItem("addCoachingMinsSuccessMode");
    localStorage.removeItem("transfermins");

    navigate("/manageMembership", { replace: true });
    setAllset(false);
  
  };

  const fetchSubscriptionData = async () => {
    try {
      setLoading(true);
      const response = await getUserSubscriptionDetails();
      setSubscriptionData(response.payload);
      setLoading(false);
    } catch (error) {
      setError("Failed to load subscription details");
      setLoading(false);
    }
  };

  const handleContactClick = (user) => {
    setSelectedContact(user); // Store the clicked user's data
    setIsCoachingMinutesOpen(true); // Open modal
  };

  const contact = [
    {
      _id: "1",
      first_name: "John",
      last_name: "Doe",
      profile_pic_url: "",
      related_data: {
        relationship_category: "Family",
      },
    },
  ];

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const memberContIncrement = (t) => {
    setlistedMaembers(t);
  };

  const handleInviteSubmit = (data) => {
    console.log("Received data for member", currentMemberIndex + 1, data);
    // Collect data from all members in an array
    setAllMemberData((prevData) => [...prevData, data]);
  };

  const handleMemberComplete = () => {
    const nextIndex = currentMemberIndex + 1;
    setCurrentMemberIndex(nextIndex);

    if (nextIndex >= listedMembers) {
      // All members have been invited
      console.log("All members invited:", allMemberData);
      setisInviteOpen(false);
      // Reset for next time
      setCurrentMemberIndex(0);
      setAllMemberData([]);
      // Optionally show a success message or fetch updated data
      fetchSubscriptionData();
    }
  };

  const onEmailPhoneSubmit = (e) => {
    setselectedAddonUser(e);
    console.log("phoneEmail", e);
  };

  const openAddMembersModal = () => {
    setCurrentMemberIndex(0);
    setAllMemberData([]);
    setisAddOpen(true);
  };

  const onTransferMinutesComplete=()=>{
    setAllset(true)
  }

  const secondary_price = products[0]?.pricingDetails?.find(
    (price) =>
      price?.metadata?.price_type === "sub-users" &&
      price?.recurring?.interval === subscriptionData?.membership_type
  );

  const handleToggleClick = () => {
    setShowAll((prevState) => !prevState);
  };

  // const secondary_yearly = products[0]?.pricingDetails?.find(
  //   (price) =>
  //     price?.metadata?.price_type === "sub-users" &&
  //     price?.recurring?.includes( subscriptionData?.membership_type)
  // );

  const isReqMinSuccess = () => {
    setsetReqcoachingminutes(false);
    setAllset(true);
  };

  // console.log("selected contact", selectedContact);
  // console.log(selectedAddonUser, "selectedAddonuser");

  // console.log(products, "productsss");
  // console.log(secondary_price?.unit_amount, "secondary_price");

  // console.log(
  //   products?.find(
  //     (product) =>
  //       product?.metadata?.plan_type === "addon" &&
  //       product?.name === "Coaching Minutes"
  //   )?.pricingDetails,
  //   "wertyuytrewerty"
  // );
  console.log(ispurchased, "ispurchased", successmode);
  console.log(selectedValueforBuyMinutes,"selectedValueforBuyMinutes")
  // console.log(ispurchased, typeof ispurchased, "ispurchased");
  // console.log(successMode, "successMode");
  // console.log(localStorage.getItem("successMode"))

  // const value = products?.find(
  //   (product) =>
  //     product?.metadata?.plan_type === "addon" &&
  //     product?.name === "Coaching Minutes"
  // )?.pricingDetails
  return (
    <div>
      <GradientBackground>
        <header className="termsofuse-header">
          <div className="termsofuse-gradient-background ">
            <div className="termsofuse-nav-container">
              <div
                className="termsofuse-logo-container "
                onClick={() => navigate("/dashboard")}
              >
                <img
                  src={arrowLeft}
                  alt="Back"
                  className="termsofuse-back-button"
                />
              </div>
              <h1 className="head-not-txt">Manage Membership</h1>
              <div className="termsofuse-space"></div>
            </div>

            {loading ? (
              <div className="loading-container">
                Loading subscription details...
              </div>
            ) : error ? (
              <div className="error-container">{error}</div>
            ) : (
              <div className="Managemanage-wrapper">
                <div className="Managemembership-container">
                  {/* Current Plan Section */}
                  <div className="Annual-membership">
                    <div className="current-plan">Your current plan</div>
                    <div className="Arismembership-button-container">
                      <div>
                        <div className="membership-aris">Aris</div>
                        <div className="annual-membership-validity-container">
                          <div className="membership-arist2">
                            Annual membership
                          </div>
                          <div className="annual-membership-validity">
                            Your plan will renew on{" "}
                            {formatDate(subscriptionData?.renewal_data)}
                          </div>
                        </div>
                      </div>

                      <div className="membership-btn-container">
                        <button
                          className="switchpayment-btn"
                          onClick={() => setisArisAnnualMembership(true)}
                        >
                          Show details
                        </button>
                        <button
                          className="purchase-additonal-btn"
                          onClick={() => setisAnnualList(true)}
                        >
                          Manage Plan
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Additional Members Section */}
                  {subscriptionData?.user_type === "primary" && (
                    <div className="Annual-membership">
                      <div className="Additional-membership">
                        <div className="Additonal-membership-heading-container">
                          <div>
                            <div className="Additonal-membership-heading">
                              Additional Members
                            </div>
                            <div className="Additonal-membership-para">
                              Add licenses for family or friends $25 per
                              user/month
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            className="membership-Add-lisence-btn"
                            onClick={openAddMembersModal}
                          >
                            Add licenses
                          </button>
                        </div>
                      </div>
                      <div
                        className="manage-contacts-grid"
                        style={{ width: "100%" }}
                      >
                        {subscriptionData?.sub_users_present &&
                          Array.isArray(subscriptionData?.sub_user_data) &&
                          // Only show first 2 items if showAll is false, otherwise show all
                          subscriptionData.sub_user_data
                            .slice(
                              0,
                              showAll
                                ? subscriptionData.sub_user_data.length
                                : 2
                            )
                            .map((user) => (
                              <ContactCard
                                key={user.user_id}
                                contact={{
                                  _id: user.user_id,
                                  first_name: user.first_name || "Invited",
                                  last_name: user.last_name || "User",
                                  profile_pic_url: user.profile_pic || "",
                                  related_data: {
                                    relationship_category: user.is_registered
                                      ? "Registered"
                                      : "Pending",
                                  },
                                  contact: user.contact || "N/A",
                                  email: user.email || "No Email Provided",
                                  coaching_minutes:
                                    user.coaching_minutes?.value || 0,
                                  faith_present: user.faith_present,
                                  faith_value: user.faith_value,
                                }}
                                onClick={() => handleContactClick(user)}
                              />
                            ))}
                      </div>
                      {Array.isArray(subscriptionData?.sub_user_data) &&
                        subscriptionData.sub_user_data.length > 2 && (
                          <div
                            className="membership-showmore"
                            onClick={handleToggleClick}
                          >
                            {showAll ? "Show Less" : "Show More"}
                            <Downarrow
                              style={{
                                marginTop: "2px",
                                transform: showAll
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                                transition: "transform 0.3s ease",
                              }}
                            />
                          </div>
                        )}
                    </div>
                  )}
                  {/* Faith Integration Section */}
                  {!subscriptionData?.faith_present &&
                    subscriptionData?.user_type === "primary" && (
                      <div className="Annual-membership">
                        <div className="Additional-membership">
                          <div className="Additonal-membership-heading-container">
                            <div>
                              <div className="Additonal-membership-heading">
                                Integrate Your Faith and Belief System
                              </div>
                              <div className="Additonal-membership-para">
                                Premium option for personalized, faith-aligned
                                coaching
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              className="membership-Add-lisence-btn"
                              onClick={() => setisFaithandBelief(true)}
                            >
                              Add faith-based coaching
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* Current Faith Section */}
                  {subscriptionData?.user_type === "primary" &&
                    subscriptionData?.faith_present && (
                      <div className="Annual-membership">
                        <div className="Additional-membership">
                          <div className="Additonal-membership-heading-container">
                            <div>
                              <div className="Additonal-membership-heading">
                                {subscriptionData?.faith_value}
                              </div>
                              <div className="Additonal-membership-para">
                                Your current faith and belief system
                              </div>
                            </div>
                          </div>
                          <div className="faith-button-container">
                            <button
                              className="membership-Add-lisence-btn"
                              onClick={() => setisFaithandBelief(true)}
                            >
                              Change faith preferences
                            </button>
                            <div className="myprofile-toggle-container">
                              <label className="myprofile-switch">
                                <span className="myprofile-slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* Primary Account Holder Section */}
                  {subscriptionData?.user_type !== "primary" && (
                    <div className="Annual-membership">
                      <div className="Additional-membership">
                        <div className="Additonal-membership-heading-container">
                          <div>
                            <div className="Additonal-membership-heading">
                              Primary Account Holder
                            </div>
                            <div className="Additonal-membership-para">
                              {subscriptionData?.coaching_minutes
                                ? `Available coaching minutes: ${subscriptionData.coaching_minutes}`
                                : "No coaching minutes available"}
                            </div>
                          </div>
                        </div>
                        <div className="membership-btn-container">
                          <button
                            className="membership-Add-lisence-btn"
                            onClick={() => setsetReqcoachingminutes(true)}
                          >
                            Request minutes
                          </button>

                          <button
                            className="purchase-additonal-btn"
                            onClick={() => setisBuyMinutes(true)}
                          >
                            Buy minutes
                          </button>
                        </div>
                      </div>
                      <div
                        className="manage-card primary-acc-holder"
                        onClick={() => setisTakeOwnership(true)}
                      >
                        <div className="manage-card-content">
                          {contact[0]?.profile_pic_url && !imageError ? (
                            <div className="manage-avatar">
                              <img
                                src={
                                  subscriptionData?.payee_details?.profile_url
                                }
                                alt={`${subscriptionData?.payee_details?.first_name} ${subscriptionData?.payee_details?.last_name}`}
                                className="manage-avatar-image"
                                onError={() => setImageError(true)}
                              />
                            </div>
                          ) : (
                            <div className="manage-avatar">
                              {`${
                                subscriptionData?.payee_details?.first_name?.[0]?.toUpperCase() ||
                                ""
                              }${
                                subscriptionData?.payee_details?.last_name?.[0]?.toUpperCase() ||
                                ""
                              }` || ""}
                            </div>
                          )}
                          <div className="manage-info">
                            <div className="manage-name">
                              {`${
                                subscriptionData?.payee_details?.first_name
                                  ?.charAt(0)
                                  .toUpperCase() || ""
                              }${
                                subscriptionData?.payee_details?.first_name
                                  ?.slice(1)
                                  .toLowerCase() || ""
                              } ${
                                subscriptionData?.payee_details?.last_name
                                  ?.charAt(0)
                                  .toUpperCase() || ""
                              }${
                                subscriptionData?.payee_details?.last_name
                                  ?.slice(1)
                                  .toLowerCase() || ""
                              }`}
                            </div>

                            {/* <div className="manage-type">
                              {contact[0]?.related_data?.relationship_category}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Modals */}
          <AddMembersModal
            isAddOpen={isAddOpen}
            setisAddOpen={setisAddOpen}
            setisInviteOpen={setisInviteOpen}
            memberContIncrement={memberContIncrement}
            onStartInvites={() => setCurrentMemberIndex(0)} // Reset index when starting invites
            secondary_price={secondary_price?.unit_amount / 100}
            subscriptionData={subscriptionData}
          />
          <AnnualMembershipList
            isAnnualList={isAnnualList}
            setisAnnualList={setisAnnualList}
            products={products}
            subscriptionData={subscriptionData}
          />
          <AnnualMembershipplans
            isArisAnnualMembership={isArisAnnualMembership}
            setisArisAnnualMembership={setisArisAnnualMembership}
            showdetails={
              subscriptionData?.subscription_data?.plan_details
                ?.marketing_features
            }
            metadata={
              subscriptionData?.subscription_data?.plan_details?.metadata
            }
            formatDate={formatDate(subscriptionData?.renewal_data)}
            addon={subscriptionData?.addon_packs}
          />
          <RequestMinutes
            Reqcoachingminutes={Reqcoachingminutes}
            setsetReqcoachingminutes={setsetReqcoachingminutes}
            productId={
              products?.find(
                (product) =>
                  product?.metadata?.plan_type === "addon" &&
                  product?.name === "Coaching Minutes"
              )?.id
            }
            priceDetails={
              products?.find(
                (product) =>
                  product?.metadata?.plan_type === "addon" &&
                  product?.name === "Coaching Minutes"
              )?.pricingDetails || []
            }
            user_type={subscriptionData?.user_type}
            // setAllset= {setAllset}
            isReqMinSuccess={isReqMinSuccess}
          />

          <BuyMinutes
            isBuyMinutes={isBuyMinutes}
            setisBuyMinutes={setisBuyMinutes}
            productId={
              products?.find(
                (product) =>
                  product?.metadata?.plan_type === "addon" &&
                  product?.name === "Coaching Minutes"
              )?.id
            }
            priceDetails={
              products?.find(
                (product) =>
                  product?.metadata?.plan_type === "addon" &&
                  product?.name === "Coaching Minutes"
              )?.pricingDetails || []
            }
            user_type={subscriptionData?.user_type}
            contactDetails={selectedContact}
            getbuymins={getbuymins}
          />

          <FaithandBelief
            isFaithandBelief={isFaithandBelief}
            setisFaithandBelief={setisFaithandBelief}
            faithProductSelectedID={faithProductSelectedID}
            productId={
              products?.find(
                (product) =>
                  product?.metadata?.plan_type === "addon" &&
                  product?.name === "Integrate Your Faith and Belief System"
              )?.id
            }
            priceId={
              products
                ?.find(
                  (product) =>
                    product?.metadata?.plan_type === "addon" &&
                    product?.name === "Integrate Your Faith and Belief System"
                )
                ?.pricingDetails?.find(
                  (prod) =>
                    prod?.recurring?.interval ===
                    subscriptionData?.membership_type
                )?.id
            }
            user_type={subscriptionData?.user_type}
            metadata={
              products?.find(
                (product) =>
                  product?.metadata?.plan_type === "addon" &&
                  product?.name === "Integrate Your Faith and Belief System"
              )?.metadata
            }
            subscriptionData={subscriptionData}
          />
          <TakeOwnership
            isTakeOwnership={isTakeOwnership}
            setisTakeOwnership={setisTakeOwnership}
            contact={contact}
          />
          {isInviteOpen && (
            <InvitetoAris
              onClose={() => {
                setisInviteOpen(false);
                setCurrentMemberIndex(0); // Reset index when closing
                setAllMemberData([]); // Clear collected data
              }}
              relationId={1}
              name="New Member" // You can customize this if needed
              onEmailPhoneSubmit={onEmailPhoneSubmit}
              listedMembers={listedMembers}
              currentMemberIndex={currentMemberIndex}
              onMemberComplete={handleMemberComplete}
            />
          )}

          {isCoachingMinutesOpen && selectedContact && (
            <CoachingMinutesModal
              userId={selectedContact.user_id} // Pass selected user_id
              contactDetails={selectedContact} // Pass full contact details
              onClose={() => setIsCoachingMinutesOpen(false)} // Close modal handler
              ispurchased={ispurchased}
              onTransferMinutesComplete={onTransferMinutesComplete}
              productId={
                products?.find(
                  (product) =>
                    product?.metadata?.plan_type === "addon" &&
                    product?.name === "Coaching Minutes"
                )?.id
              }
              priceDetails={
                products?.find(
                  (product) =>
                    product?.metadata?.plan_type === "addon" &&
                    product?.name === "Coaching Minutes"
                )?.pricingDetails
              }
              user_type={subscriptionData?.user_type}
              minutes = {subscriptionData?.coaching_minutes}
            />
          )}
         {isAllSet && (
  <Allset
    closeModal={handleAllsetClose}
    heading={
      ispurchased === "confirm" || successmode === "transfer" 
        ? "You're All set" 
        : "Oops!"
    }
    subText={
      successmode === "transfer"
        ? `${JSON.parse(localStorage.getItem("transfermins"))?.minutes} minutes have been transfered to ${JSON.parse(localStorage.getItem("transfermins"))?.contactDetails}`
        : ispurchased === "confirm"
        ? successmode === "reqMins"
          ? "The Primary account holder will receive your request"
          : successmode === "buyMinutes"
          ? `${localStorage.getItem("buyMinsSuccessMode")} minutes were added to your account.`
          : successmode === "addLicensedUser"
          ? "Licensed user added successfully"
          : successmode === "transferOwnership"
          ? "Ownership has been transferred successfully"
          : successmode === "faith"
          ? "Faith added successfully"
          : successmode === "addCoachingMins"
          ? `${JSON.parse(localStorage.getItem("addCoachingMinsSuccessMode"))?.productvalue} minutes were added to ${JSON.parse(localStorage.getItem("addCoachingMinsSuccessMode"))?.contactDetails} account.`
          : "All set"
        : "Something went wrong."
    }
    isFailure={
      ispurchased === "confirm" || successmode === "transfer" 
        ? false 
        : true
    }
  />
)}
        </header>
      </GradientBackground>
    </div>
  );
}

export default ManageMembership;

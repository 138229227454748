import React, { memo } from "react";
import career_planning from "../../Assets/icon/Career Planning.svg";
import skill_development from "../../Assets/icon/Skills Development.svg";
import work_life_balance from "../../Assets/icon/Work-Life Balance.svg";
import networking from "../../Assets/icon/Networking.svg";
import stress_management from "../../Assets/icon/Stress Management.svg";
import performance_improvement from "../../Assets/icon/Performance Improvement.svg";
import job_search_strategies from "../../Assets/icon/Job Search Strategies.svg";
import career_change from "../../Assets/icon/Career Change.svg";
import leadership_development from "../../Assets/icon/Leadership Development.svg";
import confidence_building from "../../Assets/icon/Confidence Building.svg";
import psychological_safety from "../../Assets/icon/Psychological Safety.svg";
import workplace_confict from "../../Assets/icon/Workplace Conflict.svg";
import growth_icon from "../../Assets/icon/mindset-growth.svg";
import presentation_icon from "../../Assets/icon/presentation_icon.svg";
import speech_icon from "../../Assets/icon/speech_icon.svg";
import game_ready_icon from "../../Assets/icon/game_ready_icon.svg";
import focus_icon from "../../Assets/icon/focus_icon.svg";
import positivity_icon from "../../Assets/icon/positivity_icon.svg";
import negotiation_icon from "../../Assets/icon/negotiation_icon.svg";
import adaptability_icon from "../../Assets/icon/adaptability_icon.svg";
import creativity_icon from "../../Assets/icon/creativity_icon.svg";
import strategy_icon from "../../Assets/icon/strategy_icon.svg";
import problem_solving_icon from "../../Assets/icon/problem_solving_icon.svg";
import collaboration_icon from "../../Assets/icon/collaboration_icon.svg";
import gratitude_icon from "../../Assets/icon/gratitude_icon.svg";
import resilience_icon from "../../Assets/icon/resilience_icon.svg";
import exercise_icon from "../../Assets/icon/exercise_icon.svg";
import nutrition_icon from "../../Assets/icon/nutrition_icon.svg";
import meditation_icon from "../../Assets/icon/meditation_icon.svg";
import sleep_icon from "../../Assets/icon/sleep_icon.svg";
import financial_well_being_icon from "../../Assets/icon/financial_well_being_icon.svg";
import spiritual_well_being_icon from "../../Assets/icon/spiritual_well_being_icon.svg";
import bereavement_icon from "../../Assets/icon/bereavement_icon.svg";

import "./style.css";

const CareerAdviceModal = ({ isOpen, onClose, onSelectGoal, type }) => {
  if (!isOpen) return null;

  // Define a list of career goals with unique colors
  const careerGoals = [
    {
      id: 1,
      icon: career_planning,
      color: "#E5F2FF",
      title: "Career Planning",
      description: "Set long-term goals and create a roadmap to achieve them.",
    },
    {
      id: 2,
      icon: skill_development,
      color: "#E7F8FF",
      title: "Skill Development",
      description:
        "Identify and develop the skills needed for career advancement.",
    },
    {
      id: 3,
      icon: work_life_balance,
      color: "#FFF7E8",
      title: "Work-Life Balance",
      description:
        "Manage your time and energy between work and personal life.",
    },
    {
      id: 4,
      icon: job_search_strategies,
      color: "#E3F6F2",
      title: "Job Search Strategies",
      description: "Optimize your job search with effective strategies.",
    },
    {
      id: 5,
      icon: networking,
      color: "#E5F2FF",
      title: "Networking",
      description: "Build and maintain professional relationships.",
    },
    {
      id: 6,
      icon: career_change,
      color: "#E7F8FF",
      title: "Career Change",
      description: "Navigate a career change or move into a new industry.",
    },
    {
      id: 7,
      icon: leadership_development,
      color: "#FFF7E8",
      title: "Leadership Development",
      description:
        "Enhance your leadership skills to move into management roles.",
    },
    {
      id: 8,
      icon: confidence_building,
      color: "#E3F6F2",
      title: "Confidence Building",
      description: "Improve your self-esteem and confidence in the workplace.",
    },
    {
      id: 9,
      icon: stress_management,
      color: "#E7F8FF",
      title: "Stress Management",
      description:
        "Develop techniques to manage and reduce work-related stress.",
    },
    {
      id: 10,
      icon: performance_improvement,
      color: "#E3F6F2",
      title: "Performance Improvement",
      description:
        "Set and achieve performance goals to excel in your current role.",
    },
    {
      id: 11,
      icon: psychological_safety,
      color: "#E5F2FF",
      title: "Psychological Safety",
      description:
        "Create a work environment where everyone feels safe to express ideas and concerns without fear of negative consequences.",
    },
    {
      id: 12,
      icon: workplace_confict,
      color: "#E7F8FF",
      title: "Workplace Conflict",
      description:
        "Navigate and resolve conflicts in the workplace effectively.",
    },
  ];
  const mindsets = [
    {
      id: 1,
      icon: growth_icon,
      color: "#E5F2FF",
      title: "Growth",
      description:
        "Embrace challenges and view them as opportunities to learn and grow.",
    },
    {
      id: 2,
      icon: resilience_icon,
      color: "#E7F8FF",
      title: "Resilience",
      description: "Learn to bounce back from setbacks stronger than before.",
    },
    {
      id: 3,
      icon: game_ready_icon,
      color: "#FFF7E8",
      title: "Game-Ready",
      description: "Mentally prepare for high-stakes situations.",
    },
    {
      id: 4,
      icon: presentation_icon,
      color: "#E3F6F2",
      title: "Presentation",
      description: "Deliver presentations with confidence and clarity.",
    },
    {
      id: 5,
      icon: speech_icon,
      color: "#E5F2FF",
      title: "Speech",
      description: "Speak with clarity and confidence.",
    },
    {
      id: 6,
      icon: focus_icon,
      color: "#E7F8FF",
      title: "Focus",
      description: "Concentrate fully on the task at hand.",
    },
    {
      id: 7,
      icon: positivity_icon,
      color: "#FFF7E8",
      title: "Positivity",
      description:
        "Maintain an optimistic outlook and focus on the positive aspects of situations.",
    },
    {
      id: 8,
      icon: negotiation_icon,
      color: "#E3F6F2",
      title: "Negotiation",
      description: "Approach negotiations with confidence and clarity.",
    },
    {
      id: 9,
      icon: adaptability_icon,
      color: "#E5F2FF",
      title: "Adaptability",
      description: "Be flexible and open to change.",
    },
    {
      id: 10,
      icon: creativity_icon,
      color: "#E7F8FF",
      title: "Creativity",
      description:
        "Embrace challenges and view them as opportunities to create and innovate.",
    },
    {
      id: 11,
      icon: strategy_icon,
      color: "#FFF7E8",
      title: "Strategy",
      description: "Plan ahead and think long-term.",
    },
    {
      id: 12,
      icon: problem_solving_icon,
      color: "#E3F6F2",
      title: "Problem-Solving",
      description:
        "Tackle problems head-on with a clear and analytical approach.",
    },
    {
      id: 13,
      icon: collaboration_icon,
      color: "#E5F2FF",
      title: "Collaboration",
      description: "Work well with others and value teamwork.",
    },
    {
      id: 14,
      icon: gratitude_icon,
      color: "#E7F8FF",
      title: "Gratitude",
      description:
        "Focus on the positive aspects of life and express gratitude.",
    },
  ];

  const wellBeingGoals = [
    {
      id: 1,
      icon: exercise_icon,
      color: "#E5F2FF",
      title: "Exercise",
      description:
        "Stay motivated and energized to maintain an active and healthy lifestyle.",
    },
    {
      id: 2,
      icon: nutrition_icon,
      color: "#E7F8FF",
      title: "Nutrition",
      description:
        "Make conscious choices to nourish your body with healthy foods.",
    },
    {
      id: 3,
      icon: meditation_icon,
      color: "#FFF7E8",
      title: "Meditation",
      description: "Find peace and clarity through meditation.",
    },
    {
      id: 4,
      icon: sleep_icon,
      color: "#E3F6F2",
      title: "Sleep",
      description:
        "Improve your sleep quality and establish healthy sleep habits.",
    },
    {
      id: 5,
      icon: financial_well_being_icon,
      color: "#E5F2FF",
      title: "Financial Well-being",
      description:
        "Manage your finances effectively to reduce stress and achieve your financial goals.",
    },
    {
      id: 6,
      icon: spiritual_well_being_icon,
      color: "#E7F8FF",
      title: "Spiritual Well-being",
      description:
        "Explore and nurture your spiritual beliefs to find purpose and meaning in life.",
    },
    {
      id: 7,
      icon: bereavement_icon,
      color: "#FFF7E8",
      title: "Bereavement",
      description:
        "Navigate the grieving process with compassion and understanding.",
    },
  ];
  console.log(type, "typetype");

  let mapper =
    type === 2 ? careerGoals : type === 3 ? mindsets : wellBeingGoals;
  let title =
    type === 2
      ? " What career goal do you wish to achieve"
      : type === 3
      ? "What mindset do you want to achieve today?"
      : "What is your well-being goal?";

  return (
    <div className="careeradvice-modal-overlay">
      <div className="careeradvice-modal">
        <div className="careeradvice-modal-header">
          <div>
            <h2 className="careeradvice-modal-title">{title}</h2>
          </div>
          {/* <h2 className="careeradvice-modal-title" style={{marginBottom:'20px'}}>to achieve</h2> */}
          <div>
            <button className="careeradvice-close-button" onClick={onClose}>
              ×
            </button>
          </div>
        </div>
        <ul className="careeradvice-goal-list">
          {mapper.map((goal) => (
            <li
              key={goal.id}
              className="careeradvice-goal-item"
              onClick={() => onSelectGoal(goal.title)}
            >
              <div className="careeradvice-goal-content">
                <div
                  className="careeradvice-goal-icon"
                  style={{
                    backgroundColor: goal.color,
                    borderRadius: "50%",
                    padding: "16px",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={goal.icon} alt={goal.title} />
                </div>
                <div>
                  <h3 className="careeradvice-goal-title">{goal.title}</h3>
                  <p className="careeradvice-goal-description">
                    {goal.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default memo(CareerAdviceModal);

import React from "react";
import "./style.css";
function SaveResumeProgressButton({ txt, onClickButton }) {
  return (
    <div className={"progress-button"} onClick={onClickButton}>
      {txt}
    </div>
  );
}

export default SaveResumeProgressButton;

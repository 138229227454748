import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { Howl } from "howler";
import OnboardingNameRecording from "../../Components/OnboardingNameRecording";
import VideoFile from "../../Assets/Video/loadingSphere.webm";
import { fetchOnboardingData } from "../../Services/Realtionship";
import "./style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { convertTextToSpeech } from "../../Services/arisAI";
import { isSafari } from "react-device-detect";
import FullScreenBackground from "../../Components/BlueSvgBackground";
import {
  getOnBoardingPageNo,
  updateOnBoardingPageNo,
} from "../../Services/onBoarding";
import { useHeader } from "../../Providers/HeaderContextProvider";

const Onboarding = () => {
  const { setHeaderContent, setHeaderContentRightSideContent } = useHeader();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setHeaderContent(true);
    setHeaderContentRightSideContent(false);
  }, []);

  const [step, setStep] = useState(() => {
    // const savedStep = localStorage.getItem("onboardingStep");
    // return savedStep ? parseInt(savedStep, 10) : 1;
  });

  const [sessionState, setSessionState] = useState(false);
  const [text, setText] = useState("");
  const [heading, setHeading] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaying1, setIsPlaying1] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isStep6RecordingComplete, setIsStep6RecordingComplete] =
    useState(false);
  const isMicAllowedAfter = localStorage.getItem("isMicAllowed");
  const howlRef = useRef(null);
  const [isRecordingFlow, setIsRecordingFlow] = useState(false);

  useEffect(() => {
    if (isMicAllowedAfter) {
      setSessionState(true);
      localStorage.removeItem("isMicAllowed");
    }
    if (step <= 4) localStorage.setItem("onboardingStep", step.toString());

    if (step === 5) {
      navigate("/onboarding/chat");
    }
  }, [step, isMicAllowedAfter]);

  useEffect(() => {
    getOnBoardingPageNo()
      .then((e) => {
        setStep(e?.screen_num || 1);
      })
      .catch((err) => console.log(err));
  }, []);

  const cleanupHowl = () => {
    if (howlRef.current) {
      howlRef.current.unload();
      howlRef.current = null;
    }
  };

  const loadAudioData = async (stepNum, audioBlob, isRecorded) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("is_recorded", false);

    if (isRecorded) {
      formData.append("mp3", audioBlob);
      formData.append("is_recorded", isRecorded);
    }

    try {
      const result = await fetchOnboardingData(stepNum, formData);

      if (result.status === "success") {
        updateOnBoardingPageNo(stepNum || 1)
          .then()
          .catch((err) => console.log(err));
        setHeading("");
        setText(result.payload.text);
        if (result.payload.callback_url) {
          setAudioUrl("");

          try {
            const audioBlob = await convertTextToSpeech(
              result.payload.text,
              "alloy",
              false
            );

            const blob = new Blob([audioBlob], { type: "audio/mpeg" });
            const url = URL.createObjectURL(blob);

            howlRef.current = new Howl({
              src: [url],
              format: ["mp3"],
              html5: true,
              autoplay: true,
              onplay: () => setIsPlaying1(true),
              onend: () => {
                setIsPlaying1(false);
                URL.revokeObjectURL(url);
                setStep((prev) => {
                  console.log("prev", prev);
                  if (prev >= 3) return prev + 1;
                });
              },
            });

            howlRef.current.play();
            return;
          } catch (error) {
            console.error("Error handling text-to-speech response:", error);
          }
        }

        setHeading(result.payload.heading || "");

        setAudioUrl(result.payload.audio_url);
      }
    } catch (error) {
      console.error("Error loading audio data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if ([1, 2, 3].includes(step)) {
      loadAudioData(step);
    } else if (step === 4 && !isRecordingFlow) {
      // setIsRecordingFlow(true);
      // This handles the case when coming from step 8
      loadAudioData(step);
    }
  }, [step, isRecordingFlow]);

  useEffect(() => {
    if (step !== 4) {
      setIsRecordingFlow(false);
    }
  }, [step]);

  useEffect(() => {
    if (!audioUrl || isLoading) return;

    cleanupHowl();

    const isAssesstmentOpened = localStorage.getItem("isAssesstmentOpened");
    if (isAssesstmentOpened && step === 7) {
      setIsStep6RecordingComplete(true);
      localStorage.removeItem("isAssesstmentOpened");
      return;
    }

    const isMicAllowed = localStorage.getItem("isMicAllowed1");
    if (isMicAllowed) {
      setSessionState(true);
      localStorage.removeItem("isMicAllowed1");
      return;
    }

    howlRef.current = new Howl({
      src: [audioUrl],
      html5: true,
      autoplay: true,
      onplay: () => setIsPlaying(true),
      onend: () => {
        setIsPlaying(false);

        if (step < 3) {
          setStep((prevStep) => prevStep + 1);
        } else if (step === 3 || step === 4) {
          setSessionState(true);
        }
        // else if (step >= 4 && step <= 6) {
        //   setStep((prevStep) => prevStep + 1);
        // }
        // else if (step >= 4 && step <= 6) {
        //   setStep((prevStep) => prevStep + 1);
        // }
        // else if (step === 7) {
        //   setIsStep6RecordingComplete(true);
        // }
        else if (step === 8) {
          setStep(4);
        }
      },
      onloaderror: (id, error) => console.error("Load error:", error),
      onplayerror: (id, error) => {
        console.error("Play error:", error);
        howlRef.current?.once("unlock", () => {
          howlRef.current?.play();
        });
      },
    });

    return () => cleanupHowl();
  }, [audioUrl, isLoading, step]);

  const gotoassessment = () => {
    navigate("/onboarding");
    localStorage.setItem("isAssesstmentOpened", true);
  };
  const gotoSurvey = () => {
    navigate("/survey");
    localStorage.setItem("isAssesstmentOpened", true);
  };

  const gotoaboutaris = () => {
    navigate("/about-aris");
  };

  const handleNextStep = (stepNum, audioBlob, isVerified) => {
    setIsRecordingFlow(true);
    loadAudioData(stepNum, audioBlob, isVerified);
  };

  console.log(step, sessionState, isMicAllowedAfter, "step");

  return (
    <div>
      <FullScreenBackground>
        <div className="onboarding-container">
          <div className="onboarding-content">
            <video className="responsive-video" autoPlay loop muted>
              <source
                src={
                  isSafari
                    ? "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/sphere-ios.mov"
                    : process.env.REACT_APP_SPHERE_LOADER ||
                      "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/Sphere_Loader.webm"
                }
                type="video/webm"
              />
              Your browser does not support the video tag.
            </video>

            {(step <= 3 || step === 4) && (
              <>
                {heading && heading.trim() !== "" && (
                  <p className="static-text-step7">{heading}</p>
                )}
                <p className="aris-text">{text}</p>
              </>
            )}

            {sessionState && (
              <OnboardingNameRecording
                setSessionState={setSessionState}
                stepNumber={4}
                onNextStep={(stepNum, audioBlob, isVerified) =>
                  handleNextStep(stepNum, audioBlob, isVerified)
                }
                setStep={setStep}
              />
            )}
          </div>

          {/* {step === 7 && isStep6RecordingComplete && (
            <div className="button-container">
              <button onClick={gotoSurvey} className="step6-button-style">
                Let's get started!
              </button>
            </div>
          )} */}
        </div>
      </FullScreenBackground>
    </div>
  );
};

export default Onboarding;

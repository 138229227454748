import React from "react";
import EditRelationship from "../../../Components/EditRelationship";

function EditRelationShipModal() {
  return (
    <div>
      <div className="careeradvice-modal-overlay">
        <div className="careeradvice-modal">
          <div className="careeradvice-modal-header">
            <div>
              <h2 className="careeradvice-modal-title">
                {"Edit Relationship Check"}
              </h2>
            </div>
            {/* <h2 className="careeradvice-modal-title" style={{marginBottom:'20px'}}>to achieve</h2> */}
            <div>
              <button className="careeradvice-close-button" onClick={() => {}}>
                ×
              </button>
            </div>
          </div>
          <EditRelationship
            // contact={selectedContact}
            onBack={() => {}}
            // onUpdateContact={handleUpdateContact}
            // onDeleteContact={handleDeleteContact}
          />
        </div>
      </div>
    </div>
  );
}

export default EditRelationShipModal;

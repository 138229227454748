import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

import { styled } from "@mui/material/styles";

function ToggleButton({ isChecked, onChange, name }) {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#4674FE",
          opacity: 1,
          border: 0,
          ...(theme.palette.mode === "dark" && {
            backgroundColor: "#2ECA45",
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...(theme.palette.mode === "dark" && {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...(theme.palette.mode === "dark" && {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...(theme.palette.mode === "dark" && {
        backgroundColor: "#39393D",
      }),
    },
  }));

  const onSelectionChange = (e) => {
    onChange();
    console.log(e.target.checked);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ m: 1 }}
            checked={isChecked} // Prop directly to the Switch
            onChange={onSelectionChange} // Event handled here
            name={name}
          />
        }
      />
    </div>
  );
}

export default ToggleButton;

import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import mostLeast from "../../Assets/images/mostLeast.svg"; // Stroke image
import rank from "../../Assets/images/rank.svg"; // Stroke image
import choose from "../../Assets/images/choose.svg"; // Stroke image

import { useHeader } from "../../Providers/HeaderContextProvider";
import { startNextTestbyCategoryId } from "../../Services/Assessment";
import "./style.css";
import paymentFailure from "../../Assets/icon/paymentFailure.png";
import { ReactComponent as PaymentFailure } from "../../Assets/icon/paymentFailure.svg";
import { ReactComponent as AllsetIcon } from "../../Assets/icon/allsettick.svg";
// import { ReactComponent as Allset } from "../../Assets/icon/allsettick.svg";

const Allset = ({ closeModal, isFailure, heading, subText, buttonText }) => {
  const navigate = useNavigate();

  // Function to parse query string

  const { number } = useParams();

  //on click of continue button route to assessment screen

  //return the data based on the work percentage

  return (
    <div className="membershipmodal-overlay">
      <div className="membershipmodal-container allset-container">
        <div className="ass-content-container-attacher">
          <div className="assess-header" style={{ justifyContent: "flex-end" }}>
            <CloseIcon onClick={closeModal} className="membershipclose-btn" />
          </div>
          <div className="ass-content-container">
            <div className="overview-container-hero-eng margin-allset">
              <div className="overview-image-container-eng "></div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="141.806"
                height="161"
                viewBox="0 0 253 253"
                fill="none"
                className="po-ob"
              >
                <path
                  opacity="0.1"
                  d="M0.913086 126.723C0.913086 82.0285 24.9073 40.6643 63.641 18.3598C102.395 -3.95645 150.346 -3.95645 189.1 18.3598C227.834 40.6643 251.828 82.0285 251.828 126.723C251.828 171.418 227.834 212.782 189.1 235.087C150.346 257.403 102.395 257.403 63.641 235.087C24.9073 212.782 0.913086 171.418 0.913086 126.723Z"
                  stroke="#fff"
                  stroke-width="1.5"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="196"
                height="196"
                viewBox="0 0 196 196"
                fill="none"
                className="po-ob"
              >
                <path
                  opacity="0.15"
                  d="M0.991211 98.3208C0.991211 63.804 19.5029 31.8665 49.4135 14.6354C79.3373 -2.60334 116.337 -2.60334 146.261 14.6354C176.172 31.8665 194.684 63.804 194.684 98.3208C194.684 132.838 176.172 164.775 146.261 182.006C116.337 199.245 79.3373 199.245 49.4135 182.006C19.5029 164.775 0.991211 132.838 0.991211 98.3208Z"
                  stroke="#fff"
                  stroke-width="2"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="143"
                height="143"
                viewBox="0 0 143 143"
                fill="none"
                className="po-ob"
              >
                <path
                  opacity="0.2"
                  d="M1.9209 71.5434C1.9209 46.682 15.315 23.651 36.8709 11.2564C58.4446 -1.14847 85.2032 -1.14847 106.777 11.2564C128.333 23.651 141.727 46.682 141.727 71.5434C141.727 96.4047 128.333 119.436 106.777 131.83C85.2032 144.235 58.4446 144.235 36.8709 131.83C15.315 119.436 1.9209 96.4047 1.9209 71.5434Z"
                  stroke="#fff"
                  stroke-width="2"
                />
              </svg>
              {/* <div className="stroke-1"></div>
                  <div className="stroke-2"></div>
                  <div className="stroke-3"></div> */}
              {isFailure ? (
                <div className="failure-payment">
                  <img src={paymentFailure} alt="fail" />
                </div>
              ) : (
                <div className="failure-payment">
                  <AllsetIcon className="ass-icon " />
                </div>
              )}
            </div>
            <div className="assess-options-container-eng-allset allset-txt">
              <div className="head-cont allset-title">
                {heading || "You’re all set."}
              </div>
              <div className="subTxt allset-subtext allset-txter">
                {subText || "Your subscription for Aris was cancelled"}
              </div>
            </div>
          </div>
          <div className="btn-cntr transform-up">
            <div className="btn-tmp-cnt" onClick={closeModal}>
              {buttonText || "Done"}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
export default Allset;

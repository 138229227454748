import { axiosProfileInstance } from "../AxiosConfiguration";
import Cookies from "js-cookie";

const relationshipUrl = process.env.REACT_APP_BASE_URL_ASSESSMENT;

export const getUserProfile = async () => {
  if (Cookies.get("idtoken")) {
    try {
      const response = await axiosProfileInstance.get("/api/user/profile");
      if (response.data && response.data.status === "success") {
        return response.data.payload; // Return the payload from the response
      } else {
        throw new Error(response.data.message || "Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      throw error;
    }
  }
};

export const getUserProfileSummary = async () => {
  try {
    const response = await axiosProfileInstance.get(
      "/api/user/profile/summary"
    );
    if (response.data && response.data.status === "success") {
      return response.data.payload;
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error fetching user profile summary:", error);
    throw error;
  }
};

export const updateBasicDetails = async (details) => {
  try {
    const response = await axiosProfileInstance.post(
      "/api/user/profile/update",
      {
        first_name: details.first_name,
        last_name: details.last_name,
        date_of_birth: details.date_of_birth,
        gender: details.gender,
        aris_voice: details.aris_voice,
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Failed to update basic details:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// export const uploadaudio = async (audioFile) => {
//   try {
//     // Create a FormData object to send the file
//     const formData = new FormData();

//     // Append the audio file to the FormData
//     // Ensure the key matches what the backend expects
//     formData.append("file", audioFile);

//     // Use axiosProfileInstance or your configured axios instance
//     const response = await axiosProfileInstance.post("/api/user/upload-audio", formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     });

//     return response.data;
//   } catch (error) {
//     console.error("Failed to upload audio:", error.response?.data || error.message);
//     throw error;
//   }
// };

export const uploadaudio = async ({ originalAudio, responseAudio }) => {
  try {
    const formData = new FormData();
    formData.append("user_audio", originalAudio);
    formData.append("ai_audio", responseAudio);

    const response = await axiosProfileInstance.post(
      "/api/user/upload-audio",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Failed to upload audio:",
      error.response?.data || error.message
    );
    throw error;
  }
};

import React, { useState, useEffect } from 'react'
import { ReactComponent as LeftArrow } from "../../../../../Assets/images/arrow-left.svg";
import { ReactComponent as RIghtArrow } from "../../../../../Assets/images/arrow-right.svg";
import { ReactComponent as CloseIcon } from "../../../../../Assets/icon/close.svg";
import { ReactComponent as CheckRed } from "../../../../../Assets/icon/check-red.svg";

const TimeSchedule = ({setPage, setStartTime, setEndTime, startTime, endTime, onClose, selectedDate}) => {

    const [error, setError] = useState("");
    const [timeOptions, setTimeOptions] = useState([]);
    const [endTimeOptions, setEndTimeOptions] = useState([]);

    useEffect(() => {
        const generateTimeOptions = () => {
            const options = [];
            for (let hour = 0; hour < 24; hour++) {
                for (let minute = 0; minute < 60; minute += 15) {
                    const time = new Date();
                    time.setHours(hour);
                    time.setMinutes(minute);
                    options.push(time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
                }
            }
            return options;
        };
    
        const currentTime = new Date();
        const currentMinutes = currentTime.getMinutes();
        const nextQuarterHour = new Date(currentTime.setMinutes(currentMinutes + (15 - (currentMinutes % 15))));
    
        if (selectedDate.toDateString() === new Date().toDateString()) {
            const filteredOptions = generateTimeOptions().filter(time => {
                const [hours, minutes] = time.split(':');
                const period = time.slice(-2);
                const optionTime = new Date();
                optionTime.setHours(period === 'PM' && hours !== '12' ? parseInt(hours) + 12 : parseInt(hours));
                optionTime.setMinutes(parseInt(minutes));
                return optionTime >= nextQuarterHour;
            });
            setTimeOptions(filteredOptions);
            setEndTimeOptions(filteredOptions); // Set initial end time options
    
            // Set initial startTime and endTime
            const initialStartTime = filteredOptions[0];
            const initialEndTime = filteredOptions[1];
            setStartTime(initialStartTime);
            setEndTime(initialEndTime);
            setError(!validateTimeDifference(initialStartTime, initialEndTime)); // Validate initial times
        } else {
            const options = generateTimeOptions();
            setTimeOptions(options);
            setEndTimeOptions(options); // Set initial end time options
    
            // Set initial startTime and endTime
            const initialStartTime = options[0];
            const initialEndTime = options[1];
            setStartTime(initialStartTime);
            setEndTime(initialEndTime);
            setError(!validateTimeDifference(initialStartTime, initialEndTime)); // Validate initial times
        }
    }, [selectedDate]);
    
    const handleStartTimeChange = (e) => {
        const newStartTime = e.target.value;
        setStartTime(newStartTime);
    
        const filteredEndTimeOptions = timeOptions.filter(time => {
            const [startHours, startMinutes] = newStartTime.split(':');
            const startPeriod = newStartTime.slice(-2);
            const startTimeInMinutes = (parseInt(startHours) % 12) * 60 + parseInt(startMinutes) + (startPeriod === 'PM' ? 720 : 0);
    
            const [endHours, endMinutes] = time.split(':');
            const endPeriod = time.slice(-2);
            const endTimeInMinutes = (parseInt(endHours) % 12) * 60 + parseInt(endMinutes) + (endPeriod === 'PM' ? 720 : 0);
    
            return endTimeInMinutes > startTimeInMinutes;
        });
    
        setEndTimeOptions(filteredEndTimeOptions);
        const newEndTime = filteredEndTimeOptions[0];
        setEndTime(newEndTime); // Set the end time to the first available option
        setError(!validateTimeDifference(newStartTime, newEndTime)); // Validate new times
    };
    
    const handleEndTimeChange = (e) => {
        const newEndTime = e.target.value;
        setEndTime(newEndTime);
        setError(!validateTimeDifference(startTime, newEndTime));
    };

    const validateTimeDifference = (start, end) => {
        const convertTimeToMinutes = (time) => {
            const [hours, minutes] = time.split(':');
            const period = time.slice(-2);
            let totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
            
            if (period === 'PM' && hours !== '12') {
                totalMinutes += 12 * 60;
            }
            if (period === 'AM' && hours === '12') {
                totalMinutes -= 12 * 60;
            }
            return totalMinutes;
        };
        
        const startMinutes = convertTimeToMinutes(start);
        const endMinutes = convertTimeToMinutes(end);
        const diffMinutes = endMinutes - startMinutes;
        
        return diffMinutes <= 60 && diffMinutes > 0;
    };

    console.log("startTime", startTime);
    console.log("endTime", endTime);

    return (
        <div className="session-schedule-container">
            <div className="session-schedule-header">
                <LeftArrow style={{cursor:'pointer'}} onClick={() => setPage('date')} />
                <CloseIcon style={{cursor:'pointer'}} onClick={onClose}/>
            </div>
            <p className="schedule-title">Pick your time</p>
            <div className="time-selector">
                <div className="coaching-session-scheduler-time-dropdown-container">
                    <select
                        className="time-dropdown"
                        value={startTime}
                        onChange={(e) => handleStartTimeChange(e)}
                    >
                    {timeOptions.map((time, index) => (
                        <option key={index} value={time}>
                        {time}
                        </option>
                    ))}
                    </select>
                </div>
                <span className="separator">-</span>
                <div className="coaching-session-scheduler-time-dropdown-container">
                    <select
                        className="time-dropdown"
                        value={endTime}
                        onChange={(e) => handleEndTimeChange(e)}
                    >
                        {endTimeOptions.map((time, index) => (
                            <option key={index} value={time}>
                            {time}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {error && 
                <div className='time-error-container'>
                    <CheckRed />
                    You don't have enough time left on your account
                </div>
            }
            <div
                className="continue-button-container"
                onClick={() => {
                    console.log("selectedTime", startTime, endTime)
                    if(error) {
                        setPage('coachingPlan');
                    } else {
                        setPage('schedule');
                    }
                }
            }>
                <span>{error ? 'Purchase more time' : 'Continue'}</span>
                <RIghtArrow />
            </div>
        </div>
    )
}

export default TimeSchedule
import React, { useEffect, useState } from "react";
import { useHeader } from "../../Providers/HeaderContextProvider";
import PotraitHeader from "./PotraitHeader";
import "./style.css";
import TabSelection from "./TabSelection";
import Overview from "./Overview";
import Personality from "./Personality";
import Processing from "./Processing";
import DecisionMaking from "./DecisionMaking";
import ConflictManagement from "./ConflictManagement";
import Motivation from "./Motivation";
import FundamentalNeeds from "./FundamentalNeeds";
// import { response, response2 } from "./constants";
import { getPortraits, getPortraitsData } from "../../Services/portraits";

function ArisPotraits() {
  const { setHeaderContent } = useHeader();
  const [analysis, setanalysis] = useState({});
  const [response2, setresponse2] = useState({});

  useEffect(() => {
    setHeaderContent(false);
    getPortraits().then((e) => {
      setanalysis(e?.analysis_metrics);
      setresponse2(e?.user_portrait?.portraitMatrix);
    });
    // getPortraitsData()
  }, []);

  const [activeCategoryId, setactiveCategoryId] = useState(1);

  const changeTab = (activeCategoryId) => {
    setactiveCategoryId(activeCategoryId);
  };

  const analysis1 = {
    sdb: 3,

    processing: {
      Intuitive: 10,

      Cognitive: 60,

      Concrete: 90,

      Orderly: 70,

      Internal: 30,

      Affective: 40,

      Spontaneous: 30,

      External: 70,
    },

    conflictManagement: {
      Collaborate: 27,

      Compromise: 23,

      Compete: 23,

      Avoid: 20,

      Accommodate: 7,
    },

    learningStyle: {
      Analytical: 1,

      Global: 0,
    },

    motivationWhy: {
      Compliance: 0,

      Affiliation: 50,

      Power: 100,

      AttainmentOfGoals: 60,

      RecognitionForEffort: 40,

      Activity: 50,
    },

    motivationHow: {
      TaskCompletion: 75,

      ExchangeOfIdeas: 21,

      Consistency: 29,

      AffirmedByOthers: 75,

      SelfAffirmed: 25,

      ReceiveDirection: 79,

      Freedom: 71,

      PrefersProcess: 25,
    },

    motivationWhat: {
      Hygiene: 0,

      Accomplishment: 0,
    },

    personality: {
      Primary: {
        personalityType: "Refiner",

        segmentScore: 3455,

        segmentGraph: [
          0.43,

          0.57,

          0.71,

          0.71,
        ],

        valid: true,
      },

      UnderPressure: {
        personalityType: "Ambassador",

        segmentScore: 4543,

        segmentGraph: [
          0.57,

          0.71,

          0.57,

          0.43,
        ],

        valid: true,
      },

      PerceivedByOthers: {
        personalityType: "Ambassador",

        segmentScore: 4554,

        segmentGraph: [
          0.57,

          0.71,

          0.71,

          0.57,
        ],

        valid: true,
      },
    },

    fundamentalNeeds: {
      Control: 35,

      Significance: 27,

      Security: 38,
    },

    decisionMaking: {
      Outward: 67,

      Rapid: 33,

      Careful: 67,

      Inward: 33,
    },

    traitPriorities: {
      ConflictManagement: ["Collaborate", "Compromise"],

      FundamentalNeeds: ["Security", "Control"],
    },

    completedCategories: [
      "MotivationWhat",

      "ConflictManagement",

      "FundamentalNeeds",

      "LearningStyle",

      "Accuracy",

      "MotivationWhy",

      "Personality",

      "MotivationHow",

      "DecisionMaking",

      "Motivation",

      "Processing",

      "UnderPresssure",
    ],

    portraitType: "SPAssessmentQuestion",
  };

  const overViewButtonClick = () => {
    setactiveCategoryId(2);
  };
  const renderTabBasedOnTabID = () => {
    switch (activeCategoryId) {
      case 1:
        return <Overview onButtonClick={overViewButtonClick} />;
      case 2:
        return (
          <Personality
            chartData={analysis?.personality || analysis1?.personality}
            primary={response2?.Personality}
            underPressure={response2?.UnderPresssure}
          />
        );
      case 3:
        return (
          <Processing
            chartData={analysis?.processing || analysis1?.processing}
            otherData={response2?.Processing}
          />
        );
      case 4:
        return (
          <DecisionMaking
            chartData={analysis?.decisionMaking || analysis1?.decisionMaking}
            otherData={response2?.DecisionMaking}
          />
        );
      case 5:
        return (
          <ConflictManagement
            chartData={
              analysis?.conflictManagement || analysis1?.conflictManagement
            }
            otherData={response2?.ConflictManagement}
            traitPriorities={analysis?.traitPriorities?.ConflictManagement}
          />
        );
      case 6:
        return (
          <Motivation
            chartData={{
              why: analysis?.motivationWhy || analysis1?.motivationWhy,
              how: analysis?.motivationHow || analysis1?.motivationHow,
            }}
            otherData={response2?.MotivationWhy}
          />
        );
      case 7:
        return (
          <FundamentalNeeds
            chartData={
              analysis?.fundamentalNeeds || analysis1?.fundamentalNeeds
            }
            otherData={response2?.FundamentalNeeds}
          />
        );
      default:
        return <Overview />;
    }
  };
  console.log(activeCategoryId, "activeCategoryId");
  console.log(response2, "response2");
  return (
    <div className="potrait-hero ">
      {/* <div className="gradient-background-2 "></div> */}

      <div className="combined-gradient-background "></div>
      <div className="combined-gradient-background-2 "></div>
      <div className="combined-gradient-background-3 "></div>
      <div className="combined-gradient-background-4 "></div>

      <PotraitHeader />
      <div className="potraits-container">
        <div className="potrait-cntr-container">
          <div className="potraits-typo-1 potraits-head-txt pot-head">
            Deepen trust and improve communication with your friends, family,
            and partners.
          </div>
          <div className="potraits-body-merger">
            <TabSelection
              selectedValue={activeCategoryId}
              onTabChange={changeTab}
            />

            {renderTabBasedOnTabID()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArisPotraits;

export const convertToNewFormat = async (messages) => {
  const result = {
    user_msg: {
      content: {},
      timestamp: getCurrentTimestamp(),
      content_type: "generic",
    },
    system_msg: {
      content: {},
      timestamp: getCurrentTimestamp(),
      content_type: "generic",
    },
  };

  messages.forEach((msg, index) => {
    if (msg.role === "user") {
      result.user_msg.content[index] = msg.content;
    } else if (msg.role === "system") {
      result.system_msg.content[index - 1] = msg.content;
    }
  });

  return result;
};

export const getCurrentTimestamp = () => {
  const date = new Date();
  return date.toISOString();
};

export const convertMessages = async (inputArray) => {
  if (inputArray.length === 0)
    return [
      { role: "system", content: "No Chat History Found On this Session" },
    ];
  // Initialize an empty array for the output
  const outputArray = [];

  // Loop through the input array
  await inputArray.forEach((item) => {
    // Extract role and content
    const { role, content } = item;

    // Check if content has a property "0" and push the structured object to output array
    if (content && content[0] !== undefined) {
      outputArray.push({
        role,
        content: content[0],
      });
    }
  });

  return outputArray;
};

export default convertMessages;

import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import { isMobile } from "react-device-detect";
const RadarGraph = ({
  personalityGraphdata,
  isUnderPressure = false,
  showTwo = false,
}) => {
  const chartRef = useRef();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const width = isMobile
      ? 250
      : screenWidth > 1240
      ? 350
      : screenWidth > 1840
      ? 350
      : 300; // Square background dimensions
    const height = isMobile
      ? 250
      : screenWidth > 1240
      ? 350
      : screenWidth > 1840
      ? 300
      : 300;
    const radius = Math.min(width, height) / 2 - 50;

    //personality graph data
    const data = isUnderPressure
      ? [
          {
            axis: "Big Picture Oriented",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[0] || 0,
          },
          {
            axis: "People Driven",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[1] || 0,
          },
          {
            axis: "Detail Oriented",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[2] || 0,
          },
          {
            axis: "Results Driven",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[3] || 0,
          },
        ]
      : [
          {
            axis: "Big Picture Oriented",
            value: personalityGraphdata?.Primary?.segmentGraph[0] || 0,
          },
          {
            axis: "People Driven",
            value: personalityGraphdata?.Primary?.segmentGraph[1] || 0,
          },
          {
            axis: "Detail Oriented",
            value: personalityGraphdata?.Primary?.segmentGraph[2] || 0,
          },
          {
            axis: "Results Driven",
            value: personalityGraphdata?.Primary?.segmentGraph[3] || 0,
          },
        ];

    // Data for the second radar - underpressure graph

    const data2 = showTwo
      ? [
          {
            axis: "Big Picture Oriented",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[0] || 0,
          },
          {
            axis: "People Driven",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[1] || 0,
          },
          {
            axis: "Detail Oriented",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[2] || 0,
          },
          {
            axis: "Results Driven",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[3] || 0,
          },
        ]
      : [];
    const radarSizeMultiplier = 2;
    const angleSlice = (2 * Math.PI) / data.length;
    const rScale = d3
      .scaleLinear()
      .range([0, radius * radarSizeMultiplier]) // Multiply radius for scaling
      .domain([0, 1]);

    // Clear previous graph
    d3.select(chartRef.current).selectAll("*").remove();

    // Create SVG
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Square background

    const rectWidth = width * 0.625;
    const rectHeight = height * 0.625;
    svg
      .append("rect")
      .attr("x", -width / 2)
      .attr("y", -height / 2)
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "rgba(0, 0, 0, 0.04"); // Light background

    // Square background inner
    svg
      .append("rect")
      .attr("x", -(width - width * 0.7))
      .attr("y", -(height - height * 0.7))

      .attr("width", width * 0.625)
      .attr("height", height * 0.625)
      .attr("fill", "rgba(0, 0, 0, 0.15)"); // Light background

    // Add gridlines
    const gridLevels = 5;
    // for (let i = 1; i <= gridLevels; i++) {
    //   svg
    //     .append("circle")
    //     .attr("cx", 0)
    //     .attr("cy", 0)
    //     .attr("r", (radius / gridLevels) * i)
    //     .style("fill", "none")
    //     .style("stroke", "#ddd");
    // }

    // Draw axes and legends
    data.forEach((d, i) => {
      const x = rScale(1) * Math.cos(angleSlice * i - Math.PI / 2);
      const y = rScale(1) * Math.sin(angleSlice * i - Math.PI / 2);

      // Axes

      // Legends (outer positioning)
      //   const legendX = rScale(1.4) * Math.cos(angleSlice * i - Math.PI / 2);
      //   const legendY = rScale(1.4) * Math.sin(angleSlice * i - Math.PI / 2);
      //   svg
      //     .append("text")
      //     .attr("x", 10)
      //     .attr("y", 20)
      //     // .attr("dy", "0.35em")
      //     .style("text-anchor", "start")
      //     .style("font-size", "12px")
      //     .text(d.axis);
    });

    // Outer corner legends
    const cornerLabels = [
      { label: "POWERFUL", x: -width / 2 + 20, y: -height / 2 + 20 },
      { label: "VERSATILE", x: width / 2 - 60, y: -height / 2 + 20 },
      { label: "PRECISE", x: -width / 2 + 20, y: height / 2 - 20 },
      { label: "AMIABLE", x: width / 2 - 60, y: height / 2 - 20 },
    ];
    // cornerLabels.forEach(({ label, x, y }) => {
    //   svg
    //     .append("text")
    //     .attr("x", x)
    //     .attr("y", y)
    //     .style("font-size", "14px")
    //     .style("font-weight", "bold")
    //     .style("text-anchor", "start")
    //     .text(label);
    // });

    // Radar area
    const rotationOffset = Math.PI / 4;
    const radarLine = d3
      .lineRadial()
      .radius((d) => rScale(d.value))
      .angle((d, i) => i * angleSlice - rotationOffset);

    //
    const defs = svg.append("defs");
    //
    // svg
    //   .append("path")
    //   .datum(data2)
    //   .attr("d", radarLine)
    //   .style("fill", "url(#gradient2)")

    //   .style("fill-opacity", 0.7);
    // Gradient for the second radar
    const gradient2 = defs
      .append("linearGradient")
      .attr("id", "gradient2")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "100%");
    gradient2.append("stop").attr("offset", "0%").attr("stop-color", "#42A4FF");
    gradient2
      .append("stop")
      .attr("offset", "63.65%")
      .attr("stop-color", "#7B05D8");

    //
    // svg
    //   .append("path")
    //   .datum(data)
    //   .attr("d", radarLine)
    //   .style("fill", "url(#gradient)")
    //   .style("fill-opacity", 0.7);
    //   .style("stroke", "#0078ff");
    //   .style("stroke-width", 2);

    // Gradient fill

    const gradient = defs
      .append("linearGradient")
      .attr("id", "gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%") // vertical gradient
      .attr("gradientUnits", "userSpaceOnUse");

    gradient.append("stop").attr("offset", "0%").attr("stop-color", "#5fe8c7");
    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#4AE1BD");

    // Helper to animate radar area
    const animateRadar = (data, gradientId, opacity, strokeColor) => {
      const initialData = data.map((d) => ({ ...d, value: 0 }));

      // Append radar area (start from 0 values)
      const path = svg
        .append("path")
        .datum(initialData)
        .attr("d", radarLine)
        .style("fill", `url(#${gradientId})`)
        .style("fill-opacity", opacity)
        .style("stroke", strokeColor)
        .style("stroke-width", 2);

      // Transition to actual values
      path
        .datum(data)
        .transition()
        .duration(1000) // Animation duration (1 second)
        .ease(d3.easeCubicInOut)
        .attr("d", radarLine);
    };

    // Animate both radars
    animateRadar(data2, "gradient2", 1);
    animateRadar(data, "gradient", 0.8);

    //axis
    svg
      .append("line")
      .attr("x1", -width)
      .attr("y1", -height)
      .attr("x2", width)
      .attr("y2", height)
      .style("stroke", "#fff")
      .attr("stroke-width", "1px");

    svg
      .append("line")
      .attr("x1", width)
      .attr("y1", -height)
      .attr("x2", -width)
      .attr("y2", height)
      .style("stroke", "#fff")
      .attr("stroke-width", "1px"); // Thicker line

    // Add text at center of each side
    // Add text at center of each side with two lines
    // const sideText = [
    //   { text: ["Big Picture", "Oriented"], x: 0, y: -radius * 1.1 },
    //   { text: ["People", "Driven"], x: radius * 1.1, y: 0 },
    //   { text: ["Detail", "Oriented"], x: 0, y: radius * 1.1 },
    //   { text: ["Results", "Driven"], x: -radius * 1.1, y: 0 },
    // ];

    // sideText.forEach((side) => {
    //   const textElement = svg
    //     .append("text")
    //     .attr("x", side.x)
    //     .attr("y", side.y)
    //     .style("text-anchor", "middle")
    //     .style("font-size", "1.0625rem")
    //     .style("font-weight", "400")
    //     .style("font-family", "Urbanist")

    //     //
    //     .style("fill", "rgba(19, 19, 22, 0.80)");

    //   side.text.forEach((line, i) => {
    //     textElement
    //       .append("tspan")
    //       .attr("x", side.x)
    //       .attr("dy", i === 0 ? "0em" : "1.2em") // Position lines
    //       .text(line);
    //   });
    // });
  }, []);

  return (
    <div>
      <div ref={chartRef} />
      <div>{personalityGraphdata?.personalityType}</div>
    </div>
  );
};

export default RadarGraph;

import React from "react";
import "./style.css";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { minWidth } from "@mui/system";

const ChatModal = ({
  isOpen,
  onClose,
  width,
  height,
  children,
  isCloseNeed = false,
  padding,
  isInsight=false,
}) => {
  if (!isOpen) return null; // If modal is not open, render nothing
  const onOverLayClick = (e) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <div className="modal-overlay" >
      <div
        className={isInsight?"chat-modal-content insight-modal-container":"chat-modal-content"}
        style={{
          width: width || "70%",
    
          height: height || "50%",
          padding: padding || "10px",
        }}
      >
        {isCloseNeed && (
          <button className="close-button" onClick={onClose}>
           <CloseIcon />
          </button>
        )}

        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default ChatModal;

import React from "react";

function Personality6() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 107"
      fill="none"
    >
      <g clip-path="url(#clip0_2161_30168)">
        <path
          d="M38.5274 38.2325L99.9186 10.7666L73.7003 73.5184L12.4609 98.2242L38.5274 38.2325Z"
          fill="#31C5AB"
        />
        <path
          d="M38.5274 38.2325L99.9186 10.7666M38.5274 38.2325L12.4609 98.2242M38.5274 38.2325L73.7003 73.5184M99.9186 10.7666L73.7003 73.5184M99.9186 10.7666L12.4609 98.2242M73.7003 73.5184L12.4609 98.2242"
          stroke="white"
          stroke-opacity="0.7"
          stroke-width="0.211394"
          stroke-miterlimit="10"
        />
        <path
          d="M99.9209 10.7666L12.6133 98.0742L73.7899 73.5184L99.9209 10.7666Z"
          fill="#45D9BF"
        />
        <g filter="url(#filter0_f_2161_30168)">
          <path
            d="M15.7844 86.3881L18.0906 93.4751H25.5534L19.5158 97.8551L21.822 104.942L15.7844 100.562L9.74692 104.942L12.0531 97.8551L6.01553 93.4751H13.4783L15.7844 86.3881Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter1_f_2161_30168)">
          <path
            d="M56.0973 38.7578L59.9992 50.7559H72.6262L62.4108 58.1711L66.3127 70.1691L56.0973 62.7539L45.8818 70.1691L49.7838 58.1711L39.5684 50.7559H52.1953L56.0973 38.7578Z"
            fill="white"
            fill-opacity="0.4"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter2_f_2161_30168)">
          <path
            d="M90.1681 10.7666L92.0369 16.5237H98.0843L93.1918 20.0818L95.0606 25.839L90.1681 22.2809L85.2757 25.839L87.1444 20.0818L82.252 16.5237H88.2994L90.1681 10.7666Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30168"
          x="-1.66962"
          y="78.7029"
          width="34.9076"
          height="33.9242"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30168"
          />
        </filter>
        <filter
          id="filter1_f_2161_30168"
          x="31.8831"
          y="31.0726"
          width="48.4291"
          height="46.7816"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30168"
          />
        </filter>
        <filter
          id="filter2_f_2161_30168"
          x="74.5667"
          y="3.08135"
          width="31.2025"
          height="30.4428"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30168"
          />
        </filter>
        <clipPath id="clip0_2161_30168">
          <rect
            width="105.697"
            height="105.697"
            fill="white"
            transform="translate(0.609375 0.802734)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Personality6;

import React from "react";
import "./style.css";
import { isSafari } from "react-device-detect";

function CircularLoader() {
  return (
    <div className="as-circular-container ">
      <video className="responsive-video" autoPlay loop muted>
        <source
          src={
            isSafari
              ? "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/sphere-ios.mov"
              : process.env.REACT_APP_SPHERE_LOADER ||
                "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/Sphere_Loader.webm"
          }
          type="video/webm"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default CircularLoader;

import React, { useState, useRef, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Import PhoneInput styles
import { useNavigate } from "react-router-dom";
import { initializeApp, getApps } from "firebase/app";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import {
  loginWithPhone,
  resendSignInOtp,
  verifyLoginOtp,
} from "../../Services/auth";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { getUserProfile } from "../../Services/MyProfile";
import { useHeader } from "../../Providers/HeaderContextProvider";

const PhoneLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill("")); // Initialize as an array of 6 empty strings
  const [errorMessage, setErrorMessage] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false); // State to toggle OTP view
  const [timer, setTimer] = useState(30); // Timer state
  const [isTimerActive, setIsTimerActive] = useState(false); // Timer active flag
  const phoneInputRef = useRef(null); // Ref for PhoneInput
  const [sessionInfo, setSessionInfo] = useState("");
  const navigate = useNavigate();
  const { setHeaderContent, addProfileData } = useHeader();
  const[issending, setIssending]= useState(false);

  const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
    databaseURL: "",
  };

  const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];
  const auth = getAuth(app);

  useEffect(() => {
    let interval;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, timer]);

  const handleSendOtp = (e, isResend) => {
    if(issending)return;
    e.preventDefault();
  
    if (!phoneNumber) {
      setErrorMessage("Please enter a valid phone number.");
      return;
    }
  
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  
    if (!parsedPhoneNumber) {
      setErrorMessage("Invalid phone number.");
      return;
    }
  
    const countryCode = `+${parsedPhoneNumber.countryCallingCode}`;
    const contact = parsedPhoneNumber.nationalNumber;
  
    const parentComponent = document.getElementById("login-form-container");
    let recaptchaContainer = document.getElementById("recaptcha-container");
  
    // Remove the old recaptcha-container if it exists
    if (recaptchaContainer) {
      recaptchaContainer.remove();
    }
  
    // Create a new recaptcha-container with a unique id
    const newRecaptchaContainerId = `recaptcha-container-${Date.now()}`;
    recaptchaContainer = document.createElement("div");
    recaptchaContainer.id = newRecaptchaContainerId;
    parentComponent.appendChild(recaptchaContainer);
  
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      newRecaptchaContainerId,
      {
        size: "invisible",
      }
    );
  
    recaptchaVerifier
      .verify()
    
      .then(async (token) => {
        try {
          setIssending(true);
          const response = isResend
            ? await resendSignInOtp(countryCode, contact, token)
            : await loginWithPhone(countryCode, contact, token);
          console.log("login response", response);
          if (response.payload.error) {
            setErrorMessage(
              response.payload.error.message.split("_").join(" ")
            );
            return;
          } else {
            setSessionInfo(response.payload.sessionInfo);
            setIsOtpSent(true);
            setTimer(30);
            setIsTimerActive(true);
          }
        } catch (error) {
          setErrorMessage(error.message);
        }
        finally{
          setIssending(false);
        }
      })
      .catch((error) => {
        setErrorMessage("Recaptcha failed. Please try again");
      });
  };

  const handleOtpChange = (value, index) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input if value is entered
      if (value !== "" && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }

      // Move focus to the previous input if value is deleted
      if (value === "" && index > 0) {
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleOtpPaste = (e) => {
    const pasteData = e.clipboardData.getData("text").slice(0, otp.length);
    const newOtp = [...otp];
    for (let i = 0; i < pasteData.length; i++) {
      if (/^[0-9]$/.test(pasteData[i])) {
        newOtp[i] = pasteData[i];
      }
    }
    setOtp(newOtp);

    // Move focus to the last filled input
    const lastFilledIndex = pasteData.length - 1;
    if (lastFilledIndex < otp.length) {
      const nextInput = document.getElementById(`otp-input-${lastFilledIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleVerifyOtp = async (e) => {
    if(issending)return;
    e.preventDefault();

    if (otp.join("").length !== 6) {
      setErrorMessage("Please enter a valid 6-digit OTP.");
      return;
    }

    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

    if (!parsedPhoneNumber) {
      setErrorMessage("Invalid phone number.");
      return;
    }

    const contact = parsedPhoneNumber.nationalNumber;

    const joinedOtp = otp.join("");

    try {
      setIssending(true);
      const response = await verifyLoginOtp(contact, joinedOtp, sessionInfo);
      console.log("verify otp response", response);
      if (response.status === 200) {
        await getUserProfile().then((res) => {
          localStorage.setItem("profile", JSON.stringify(res));
          addProfileData(res);
          setHeaderContent(true);
          localStorage.setItem("playOnce", "true");
          navigate("/dashboard");
        });
      }
    } catch (error) {
      console.error("otp verification error", error);
      setErrorMessage(error.message);
    }
    finally{
      setIssending(false);
    }
  };

  return (
    <div className="login-form-container" id="login-form-container">
      {!isOtpSent ? (
        <>
          <form onSubmit={handleSendOtp}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <label htmlFor="phone-number">Mobile Number</label>
              <PhoneInput
                ref={phoneInputRef} // Attach ref to PhoneInput
                id="phone-number"
                international
                defaultCountry="US"
                value={phoneNumber}
                onChange={setPhoneNumber}
                className="phone-input"
                placeholder="Enter phone number"
              />
            </div>

            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

            <button
              type="submit"
              className={`login-form-button ${
                phoneNumber ? "valid" : "invalid"
              }`}
            >
              Send one-time password
            </button>
          </form>
        </>
      ) : (
        // OTP verification form
        <form onSubmit={handleVerifyOtp}>
          <div>
            <label className="login-otp-header">
              Enter your verification code
            </label>
            <div className="login-otp-txt">
              We sent a six-digit confirmation code to your number.{" "}
            </div>
            <div
              className="signup-otp-container login-otp-box-container"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "12px",
              }}
            >
              {otp.map((value, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  maxLength="1"
                  value={value}
                  autoFocus={index === 0}
                  onChange={(e) => handleOtpChange(e.target.value, index)}
                  onPaste={handleOtpPaste}
                  className="signup-otp-box login-otp-box"
                />
              ))}
            </div>
            <div className="login-resend-code-container">
              <div className="login-send-code">
                Didn't get code?
                {isTimerActive ? (
                  <span className="login-resend-code">
                    {" "}
                    Resend code in {timer}s
                  </span>
                ) : (
                  <span
                    className="login-resend-code"
                    onClick={(e) => handleSendOtp(e, true)}
                  >
                    {" "}
                    Resend code
                  </span>
                )}
              </div>
              <div
                className="login-changephone"
                onClick={() => setIsOtpSent(false)}
              >
                Change phone number
              </div>
            </div>
          </div>

          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

          <button type="submit" className="login-form-button valid">
            Verify OTP
          </button>
        </form>
      )}
    </div>
  );
};

export default PhoneLogin;

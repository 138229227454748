    import React, { useState, useEffect } from "react";
    import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
    import rightIcon from "../../Assets/icon/chevron-right.svg";
    import Allset from "../../Components/AllsetComponent";
    import ConfirmationModal from "./ManageMembershipCancel";
    import { ReactComponent as Point } from "../../Assets/icon/Ellipse 88.svg";
    import { ReactComponent as Info } from "../../Assets/icon/Info.svg";
    import "./style.css";
    import CoachingModal from "../Pricing/CoachingModal";
    import { ReactComponent as Plus } from "../../Assets/icon/plus.svg";
    import { ReactComponent as Minus } from "../../Assets/icon/minus.svg";
    import { postreqminutes } from "../../Services/subscription";
    import { createAddon } from "../../Services/subscription";
    import { useSearchParams } from "react-router-dom";

    const BuyMinutes = ({
      isBuyMinutes,
      setisBuyMinutes,
      showdetails,
      metadata,
      formatDate,
      addon,
      priceDetails,
      productId,
      contactDetails,
      getbuymins
    }) => {
      const [searchParams] = useSearchParams();
      const ispurchased = searchParams.get("ispurchased");
      const [isCancelModal, setIsCancelModal] = useState(false);
      const [isAllSet, setIsAllSet] = useState(false);
      const [activeModal, setActiveModal] = useState("");
      const [memberCount, setMemberCount] = useState(0);
      const [selectedMinutes, setSelectedMinutes] = useState(0); // State to hold selected radio value
      const [selectedPlan, setSelectedPlan] = useState({});
    

      const openCancelMembershipModal = () => {
        setisBuyMinutes(false);
        setIsCancelModal(true);
      };

      const closeCancelMembershipModal = () => {
        setIsCancelModal(false);
        setisBuyMinutes(true);
      };

      const handleCancelMembership = () => {
        setIsAllSet(true);
        setIsCancelModal(false);
      };

      const incrementMember = (e) => {
        e.stopPropagation();
        setMemberCount((prev) => prev + 1);
      };

      const decrementMember = (e) => {
        e.stopPropagation();
        if (memberCount > 0) {
          setMemberCount((prev) => prev - 1);
        }
      };

      const handleRadioChange = (e) => {
        setSelectedMinutes(parseInt(e.target.value)); // Set the selected minutes based on the radio button
      };

      const handleSendRequest = () => {
        let temp = {
          subscription_items: [
            { price_id: selectedPlan?.id, product_id: productId },
          ],
          licensed_users: [],
          addon_features_user: [
            { price_id: selectedPlan?.id, product_id: productId, value: "" },
          ],
          self: "true",
          payment_method: "card",
        };
        createAddon(temp).then((res) => {
          console.log(res);
          window.location.href = res?.payload?.checkout_url;
        });
      };

      const handlePlanChange = (price) => {
        // e.stopPropagation();
        setSelectedPlan(price);
        console.log(price, "proceee");
        getbuymins(price)

      };

      console.log(showdetails, "showdetails");

      console.log(productId, priceDetails?.id, "coaching idss in by mins");

      return (
        <>
          {isBuyMinutes && (
            <div className="membershipmodal-overlay">
              <div className="membershipmodal-container">
                <div className="membershipmodal-header" style={{ margin: "0px" }}>
                  <h2 className="membershipmodal-heading">
                    Request Coaching Minutes
                  </h2>
                  <button
                    className="membershipclose-btn"
                    onClick={() => setisBuyMinutes(false)}
                  >
                    <CloseIcon />
                  </button>
                </div>

                <div className="manage-modal-content membership-payment plan-info-membership-container border-buymins">
                  <div className="plan-info-membership-heading-container">
                    <div className="plan-info-membership-heading">
                      {" "}
                      How many minutes would you like to request?
                    </div>
                  </div>
                  <div>
                    <div className="req-radio-container">
                      {priceDetails.map((price, index) => (
                        <div key={index}>
                          <input
                            type="radio"
                            name={price?.metadata?.product_value}
                            value={price?.id}
                            checked={selectedPlan?.id === price?.id}
                            onChange={() => handlePlanChange(price)}
                          />

                          <span className="membership-coaching-minutes-plan-text">
                            {price?.metadata?.product_value} minutes for ${}
                            {price?.metadata?.price_value / 100} (
                            {price?.metadata?.min_value} to{" "}
                            {price?.metadata?.max_value} coaching sessions)
                          </span>
                        </div>
                      ))}
                      {/* <div>
                        <input
                          type="radio"
                          name="duration"
                          value="120"
                          onChange={handleRadioChange}
                        />
                        120 minutes
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="duration"
                          value="480"
                          onChange={handleRadioChange}
                        />
                        480 minutes
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="Req-mins-btn-container">
                  <button
                    className="membership-coaching-minutes-purchase-button req-skip"
                    onClick={() => setisBuyMinutes(false)}
                  >
                    Never Mind
                  </button>
                  <button
                    className="membership-coaching-minutes-purchase-button"
                    onClick={handleSendRequest} // Send the request to API
                  >
                    Purchase now
                  </button>
                </div>
              </div>
            </div>
          )}

          <ConfirmationModal
            isOpen={isCancelModal}
            onClose={closeCancelMembershipModal}
            onConfirm={handleCancelMembership}
            heading="Are you sure you want to cancel membership"
            subheading="You won't be able to get advise from Aris anymore"
            confirmButtonText="Yes, Cancel"
            cancelButtonText="Back"
          />

          {/* {isAllSet && (
            <Allset
              closeModal={() => {
                setIsAllSet(false);
                setisBuyMinutes(false);
              }}
              heading={ispurchased==="confirm" ?`You're all set`:"Oops!"}
              subText={
                ispurchased === "confirm"
                  ? `${priceDetails?.metadata?.product_value} minutes were added to ${contactDetails?.first_name} ${contactDetails?.last_name} account`
                  : "Something went wrong!"
              }
              isFailure={ispurchased === "confirm" ? false : true}
            />
          )} */}
        </>
      );
    };

    export default BuyMinutes;

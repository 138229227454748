import React from "react";
import "./style.css";
import ErrorComponent from "./ErrorComponent";
import { useParams } from "react-router-dom";
import noInternet from "../../Assets/images/noInternet.svg"; //
import serverDown from "../../Assets/images/serverDown.svg"; //
import pageNotFound from "../../Assets/images/pageNotFound.svg"; //

function ErrorScreen() {
  const { error } = useParams();

  const getDetails = () => {
    switch (error) {
      case "no-internet":
        return {
          errHead: "Connect to the internet",
          errSub: "You are offline. Check your connection.",
          icon: noInternet,
        };
      case "server-down":
        return {
          errHead: "Server is down",
          errSub: "Please try again later.",
          icon: serverDown,
        };
      case "page-not-found":
        return {
          errHead: "Page not found",
          errSub:
            "We can't seem to find the page you're looking for. It might have moved or no longer exists.",
          icon: pageNotFound,
        };

      default:
        return {
          errHead: "Something went wrong",
          errSub: "We're working on fixing it. Try again in a moment.",
          icon: serverDown,
        };
    }
  };

  return (
    <div className="error-screen-bg">
      <div className="err-screen-hero">
        <ErrorComponent {...getDetails()} />
      </div>
    </div>
  );
}

export default ErrorScreen;

import React from "react";
import "./style.css";
import graph from "../../Assets/images/Graph6.png";
import PotraitsSummaryWithList from "../../Components/PotraitsSummaryWithList/PotraitsSummaryWithList";
import DonutChart from "../../Components/Charts/DonutChart";
import PotraitsCommonHeadBottom from "./PotraitsCommonHeadBottom";
import TopStrength from "./TopStrength";
function FundamentalNeeds({ chartData, otherData }) {
  const extractPercentage = (title) => {
    if (typeof title === "string") {
      const match = title.match(/\((\d+)%\)/);
      return match ? parseInt(match[1]) : null;
    }
  };

  const percentage = extractPercentage("title(90%)");

  console.log(percentage, "percentage");

  const sorted_array = [
    {
      title: extractPercentage(
        otherData?.portraitMatrixMap?.Significance?.title?.description
      ),
      value: "Significance",
    },
    {
      title: extractPercentage(
        otherData?.portraitMatrixMap?.Control?.title?.description
      ),
      value: "Control",
    },
    {
      title: extractPercentage(
        otherData?.portraitMatrixMap?.Security?.title?.description
      ),
      value: "Security",
    },
  ].sort((a, b) => b.title - a.title);

  console.log(
    sorted_array[0].value,
    otherData?.portraitMatrixMap[sorted_array[0]?.value],
    "sorted_array"
  );

  console.log(
    otherData?.portraitMatrixMap[sorted_array[1]?.value],
    otherData?.portraitMatrixMap[sorted_array[1]?.value]?.topStrengths,
    "topstrengthssecurtiy"
  );

  return (
    <div className="personality-hero-container">
      <div className="potraits-tab-header-txt">Fundamental Needs</div>
      <div className="potraits-graph-summary-cntr">
        {/* <img src={graph} alt="graph" className="potraits-graph-img" /> */}
        <DonutChart chart={chartData} />
        <div className="potraits-separater-line"></div>
        <div className="potraits-graph-summary-txt-cntr">
          <div className="potraits-content-head-txt">Summary</div>
          <div className="potraits-sub-txt">
            {otherData?.summary?.description}
          </div>
        </div>
      </div>

      {sorted_array?.length > 0 && (
        <>
          {sorted_array[0]?.value?.length > 0 && (
            <div className="personality-other-data-container">
              <PotraitsCommonHeadBottom
                heading={
                  otherData?.portraitMatrixMap[sorted_array[0]?.value] &&
                  otherData?.portraitMatrixMap[sorted_array[0]?.value]?.title
                    ?.description
                }
                summary={
                  otherData?.portraitMatrixMap[sorted_array[0]?.value] &&
                  otherData?.portraitMatrixMap[sorted_array[0]?.value]?.bandCopy
                    ?.description
                }
              />

              <TopStrength
                topStrengths={
                  otherData?.portraitMatrixMap[sorted_array[0]?.value] &&
                  otherData?.portraitMatrixMap[sorted_array[0]?.value]
                    ?.topStrengths
                }
                topStrengthsToolTip={
                  otherData?.portraitMatrixMap[sorted_array[0]?.value] &&
                  otherData?.portraitMatrixMap[sorted_array[0]?.value]
                    ?.topStrengthsToolTip
                }
                isBlind={false}
              />
              <TopStrength
                topStrengths={
                  otherData?.portraitMatrixMap[sorted_array[0]?.value] &&
                  otherData?.portraitMatrixMap[sorted_array[0]?.value]
                    ?.potentialBlindSpots
                }
                topStrengthsToolTip={
                  otherData?.portraitMatrixMap[sorted_array[0]?.value] &&
                  otherData?.portraitMatrixMap[sorted_array[0]?.value]
                    ?.potentialBlindSpotsToolTip
                }
                isBlind={true}
              />
            </div>
          )}

          <div className="personality-other-data-container">
            <PotraitsCommonHeadBottom
              heading={
                otherData?.portraitMatrixMap[sorted_array[1]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[1]?.value]?.title
                  ?.description
              }
              summary={
                otherData?.portraitMatrixMap[sorted_array[1]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[1]?.value]?.bandCopy
                  ?.description
              }
            />

            <TopStrength
              topStrengths={
                otherData?.portraitMatrixMap[sorted_array[1]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[1]?.value]
                  ?.topStrengths
              }
              topStrengthsToolTip={
                otherData?.portraitMatrixMap[sorted_array[1]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[1]?.value]?.value
                  ?.topStrengthsToolTip
              }
              isBlind={false}
            />
            <TopStrength
              topStrengths={
                otherData?.portraitMatrixMap[sorted_array[1]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[1]?.value]
                  ?.potentialBlindSpots
              }
              topStrengthsToolTip={
                otherData?.portraitMatrixMap[sorted_array[1]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[1]?.value]?.value
                  ?.potentialBlindSpotsToolTip
              }
              isBlind={true}
            />
          </div>
          <div className="personality-other-data-container">
            <PotraitsCommonHeadBottom
              heading={
                otherData?.portraitMatrixMap[sorted_array[2]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[2]?.value]?.title
                  ?.description
              }
              summary={
                otherData?.portraitMatrixMap[sorted_array[2]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[2]?.value]?.bandCopy
                  ?.description
              }
            />

            <TopStrength
              topStrengths={
                otherData?.portraitMatrixMap[sorted_array[2]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[2]?.value]
                  ?.topStrengths
              }
              topStrengthsToolTip={
                otherData?.portraitMatrixMap[sorted_array[2]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[2]?.value]
                  ?.topStrengthsToolTip
              }
              isBlind={false}
            />
            <TopStrength
              topStrengths={
                otherData?.portraitMatrixMap[sorted_array[2]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[2]?.value]
                  ?.potentialBlindSpots
              }
              topStrengthsToolTip={
                otherData?.portraitMatrixMap[sorted_array[2]?.value] &&
                otherData?.portraitMatrixMap[sorted_array[2]?.value]
                  ?.potentialBlindSpotsToolTip
              }
              isBlind={true}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default FundamentalNeeds;

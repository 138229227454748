import React, { useState, useEffect } from "react";
import {
  fetchPendingRequests,
  acceptRequest,
  denyRequest,
  getsentrequests,
} from "../../Services/Realtionship";
import "./style.css";
import LoadingScreen from "../../Pages/LoadingPage/ContentLoader";
import AcceptRequestForm from "./AcceptPendingreq";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { MoreVertical } from "lucide-react";
import Modal from "./SentReqMenu";

const PendingRequestsModal = ({ onClose, onAccept }) => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [activeTabId, setActiveTabId] = useState(1);
  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const [sentRequests, setSentRequests] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [sentRequestMenu, setSentRequestMenu] = useState(false);
  const [selectedSentRequest, setSelectedSentRequest] = useState(null);

  useEffect(() => {
    fetchRequests();
    fetchPendingRequests().then((res) => {
      console.log(res, "tt1");
      // setPendingRequestCount(res?.length || 0);
    });
  }, [activeTabId]);

  const fetchRequests = async () => {
    try {
      setIsLoading(true);
      setError(null); // Reset error state
      if (activeTabId === 1) {
        const requests = await getsentrequests();
        setSentRequests(requests || []); // Ensure we set an empty array if null/undefined
      } else {
        const requests = await fetchPendingRequests();
        setPendingRequests(requests || []); // Ensure we set an empty array if null/undefined
        setPendingRequestCount(requests?.length || 0);
      }
    } catch (error) {
      console.error("Error fetching requests:", error);
      setError("Failed to fetch requests. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccept = (request) => {
    setSelectedRequest(request);
    setShowForm(true);
  };

  const handleReject = (relationId) => {
    setPendingRequests((prevRequests) =>
      prevRequests.filter((request) => request.id !== relationId)
    );
    denyRequest(relationId)
      .then((res) => fetchRequests())
      .catch((error) => {
        console.error("Failed to deny request:", error);
      });
  };

  const handleSentRequestMenu = (request) => {
    setSelectedSentRequest(request);
    setSentRequestMenu(true);
  };

  const handleCloseMenu = () => {
    setSentRequestMenu(false);
    setSelectedSentRequest(null);
    fetchRequests(); // Refetch in case of changes
  };

  const handleFormClose = () => {
    setShowForm(false);
  };

  const onCloseModal = () => {
    setShowForm(false);
    fetchRequests();
  };

  const onTabChange = (e) => {
    const clickedElement = e.target.closest("[id]");

    if (clickedElement) {
      const tabId = parseInt(clickedElement.id);
      console.log("Tab id", tabId);
      setActiveTabId(tabId);
    }
  };

  const renderSentRequests = () => {
    if (!sentRequests || sentRequests.length === 0) {
      return <p>No requests sent at the moment.</p>;
    }

    console.log("activetab", activeTabId);

    return (
      <>
        {sentRequests?.length > 0 ? (
          <div className="request-list">
            {sentRequests.map((request) => {
              const initials = `${request.firstName?.[0] || ""}${
                request.lastName?.[0] || ""
              }`.toUpperCase();

              return (
                <div className="manage-card">
                  <div className="manage-card-content">
                    {request?.profile_pic_url && !imageError ? (
                      <div className="manage-avatar">
                        <img
                          src={request?.profile_pic_url}
                          alt={request.first_name + request.last_name}
                          className="manage-avatar-image"
                          onError={() => setImageError(true)} // Handle image load failures
                        />
                      </div>
                    ) : (
                      <div className="manage-avatar">
                        {`${request?.first_name?.[0]?.toUpperCase() || ""}${
                          request?.last_name?.[0]?.toUpperCase() || ""
                        }` || ""}
                      </div>
                    )}
                    <div className="manage-info">
                      <div className="manage-name">
                        {`${
                          request?.first_name?.charAt(0).toUpperCase() || ""
                        }${request?.first_name?.slice(1).toLowerCase() || ""} ${
                          request?.last_name?.charAt(0).toUpperCase() || ""
                        }${request?.last_name?.slice(1).toLowerCase() || ""}`}
                      </div>
                      <div className="manage-type">
                        {request?.related_data?.relationship_category}
                      </div>
                    </div>
                  </div>
                  <button
                    className="manage-more-button"
                    onClick={() => {
                      setSentRequestMenu(true);
                      handleSentRequestMenu(request);
                    }}
                  >
                    <MoreVertical size={20} />
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            No sent requests
          </div>
        )}
      </>
    );
  };

  const renderPendingRequests = () => {
    if (!pendingRequests || pendingRequests.length === 0) {
      return <p>No pending requests at the moment.</p>;
    }

    return (
      <div className="request-list">
        {pendingRequests?.map((request) => {
          const initials = `${request.firstName?.[0] || ""}${
            request.lastName?.[0] || ""
          }`.toUpperCase();

          return (
            <div key={request.id} className="request-item">
              <div className="user-info">
                {request.profile_pic_url ? (
                  <img
                    src={request.profile_pic_url}
                    alt={`${request.firstName} ${request.lastName}`}
                    className="profile-pic"
                  />
                ) : (
                  <div className="profile-initials">{initials}</div>
                )}
                <span className="name">
                  {request.firstName + " " + request.lastName}
                </span>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  onClick={() => handleReject(request.id)}
                  className="accept-button"
                  style={{
                    color: "rgba(13, 94, 175, 1)",
                    background: "#fff",
                    border: "1px solid rgba(13, 94, 175, 1)",
                  }}
                >
                  Decline
                </button>
                <button
                  onClick={() => handleAccept(request)}
                  className="accept-button"
                >
                  Accept
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="pending-content">
        <div
          className="insight-tab-switch-content"
          style={{ marginBottom: "20px", boxSizing: "border-box" }}
        >
          <div
            className={`insight-switch-txt pending-txt-switch ${
              activeTabId === 1 ? "insight-tab-active" : ""
            }`}
            id="1"
            onClick={onTabChange}
          >
            Request(s) Sent
          </div>
          <div
            className={`insight-switch-txt pending-txt-switch ${
              activeTabId !== 1
                ? "insight-tab-active active-pending-request"
                : ""
            }`}
            id="2"
            onClick={onTabChange}
          >
            Request(s) Received{" "}
            <span
              className={`pending-relationship-badge pending-badge ${
                activeTabId === 1 ? "pending-badge2" : ""
              }`}
            >
              {pendingRequestCount}
            </span>
          </div>
        </div>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <LoadingScreen />
          </div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : activeTabId === 1 ? (
          renderSentRequests()
        ) : (
          renderPendingRequests()
        )}
      </div>

      {showForm && (
        <>
          <div className="pending-modal-overlay" onClick={handleFormClose} >
          <div className="accept-request-modal">
            <AcceptRequestForm
              request={selectedRequest}
              onClose={onCloseModal}
            />
          </div>
          </div>
        </>
      )}
      {sentRequestMenu && selectedSentRequest && (
        <Modal
          contact={selectedSentRequest}
          onClose={handleCloseMenu}
          onManageClick={() => {
            // Implement manage relationship logic
            console.log("Managing relationship for:", selectedSentRequest);
            handleCloseMenu();
          }}
        />
      )}
    </>
  );
};

export default PendingRequestsModal;

import React from "react";

function Personality15() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 106"
      fill="none"
    >
      <g filter="url(#filter0_f_2161_30229)">
        <path
          d="M19.1946 57.1182L20.6265 61.565H25.2603L21.5115 64.3133L22.9434 68.7601L19.1946 66.0118L15.4458 68.7601L16.8777 64.3133L13.1289 61.565H17.7627L19.1946 57.1182Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_f_2161_30229)">
        <path
          d="M62.7882 46.6284L66.8833 59.4406H80.1351L69.4141 67.3589L73.5092 80.1711L62.7882 72.2527L52.0673 80.1711L56.1623 67.3589L45.4414 59.4406H58.6932L62.7882 46.6284Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter2_f_2161_30229)">
        <path
          d="M76.4656 19.541L78.7577 26.7202H86.1753L80.1743 31.1571L82.4665 38.3363L76.4656 33.8993L70.4646 38.3363L72.7568 31.1571L66.7559 26.7202H74.1734L76.4656 19.541Z"
          fill="white"
        />
      </g>
      <path
        d="M28.3527 10.897L86.6801 19.5708L94.1935 77.8671L12.4727 92.8445L28.3527 10.897Z"
        fill="#4FE3D3"
      />
      <path
        d="M28.3527 10.897L86.6801 19.5708M28.3527 10.897L12.4727 92.8445M28.3527 10.897L94.1935 77.867M86.6801 19.5708L94.1935 77.867M86.6801 19.5708L12.4727 92.8445M94.1935 77.867L12.4727 92.8445"
        stroke="white"
        stroke-opacity="0.7"
        stroke-width="0.211394"
        stroke-miterlimit="10"
      />
      <path
        d="M94.4202 78.0794L86.6811 19.541L12.4727 92.8444L94.4202 78.0794Z"
        fill="#3BCFB5"
      />
      <defs>
        <filter
          id="filter0_f_2161_30229"
          x="5.44366"
          y="49.4329"
          width="27.5014"
          height="27.0126"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30229"
          />
        </filter>
        <filter
          id="filter1_f_2161_30229"
          x="33.5283"
          y="34.7153"
          width="58.5196"
          height="57.3687"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.95657"
            result="effect1_foregroundBlur_2161_30229"
          />
        </filter>
        <filter
          id="filter2_f_2161_30229"
          x="59.0706"
          y="11.8558"
          width="34.7904"
          height="34.1659"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30229"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Personality15;

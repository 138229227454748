import React from 'react'
import { ReactComponent as CloseIcon } from "../../../../../Assets/icon/close.svg";
import { ReactComponent as RIghtArrow } from "../../../../../Assets/images/arrow-right.svg";

const AdditionalCoachingHours = ({onClose, hideCloseIcon = false, hoursCount, setHoursCount}) => {
  return (
    <div className='additional-coaching-container'>
        <div className="additional-coaching-header">
            <p className="additional-coaching-header-title">Additional Coaching</p>
            {!hideCloseIcon && <CloseIcon style={{cursor:'pointer'}} onClick={onClose}/>}
        </div>
        <div className="additional-coaching-body">
            <div className="additional-coaching-plans-container">
                <div className="additional-coaching-plan-container">
                    <input type="checkbox" name="" id="" />
                    <p>1 hour for $44 (4 to 12 coaching sessions)</p>
                </div>
                <div className="additional-coaching-plan-container">
                    <input type="checkbox" name="" id="" />
                    <p>2 hours for $84 (8 to 24 coaching sessions)</p>
                </div>
                <div className="additional-coaching-plan-container">
                    <input type="checkbox" name="" id="" />
                    <p>4 hours for $144 (16 to 48 coaching sessions)</p>
                </div>
                <div className="add-hours-container">
                    <div className="add-hours-increment-container">
                        <div 
                            className="add-hours-button" 
                            onClick={() => {
                                if(hoursCount > 0) {
                                    setHoursCount(hoursCount-1)
                                }
                            }}
                        >
                            -
                        </div>
                        <div className="add-hours-number">{hoursCount}</div>
                        <div className="add-hours-button" onClick={() => setHoursCount(hoursCount+1)}>+</div>
                    </div>
                    <p>{`Add > 4 hours for $36/hr`}</p>
                </div>
            </div>
        </div>
        <div className="additional-coaching-footer">
            <div className="additional-coaching-total-price-container">
                <p className="total-price-text">Total price:</p>
                <p className="total-price-number">{`$0`}</p>
            </div>
            <div className="continue-button-container">
                <span>Purchase more time</span>
                <RIghtArrow />
            </div>
        </div>
    </div>
  )
}

export default AdditionalCoachingHours
// src/service/auth.js

import Cookies from "js-cookie";
import { axiosAuthInstance,axiosLoginInstance } from "../AxiosConfiguration";
import { error } from "jquery";

export const storeTokens = async (response) => {
  if (!response) {
    console.error("idToken or refreshToken is undefined:", response);
  } else {
    const {
      idToken,
      refreshToken,
      localId,
      user_id,
      userId,
      profilePicture,
      expiresIn,
    } = response || {};
    if (idToken && refreshToken) {
      const expiryTime = 3600;
      // Set tokens in cookies if available
      // Cookies.set('idtoken', idToken, { expires: 7, secure: true, sameSite: 'Strict' });  // Cookie will expire in 7 days
      // Cookies.set('refreshtoken', refreshToken, { expires: 7, secure: true, sameSite: 'Strict' });
      Cookies.set("idtoken", idToken, {
        secure: true,
      }); // Cookie will expire in 7 days
      Cookies.set("refreshtoken", refreshToken, {
        secure: true,
      });
      Cookies.set("localId", localId || user_id || userId);
      if (profilePicture) Cookies.set("profilePicture", profilePicture);

      console.log("Tokens stored successfully in cookies");
    } else {
      console.error("idToken or refreshToken is undefined:", {
        idToken,
        refreshToken,
      });
    }
  }
};

export const sendEmail = async (email) => {
  try {
    const response = await axiosAuthInstance.post("/auth/signup", { email });

    if (response.data) {
      // Log the entire response to check the structure
      console.log("Response data:", response.data);
      await storeTokens(response.data.payload);
    }

    return response; // Return the entire response object
  } catch (error) {
    if (error.response) {
      return  error.response.data.message || "An error occurred.";
    } else if (error.request) {
      throw new Error("Server error");
    } else {
      throw new Error("Error: " + error.message);
    }
  }
};

export const resendVerificationLink = async (email) => {
  try {
    const response = await axiosAuthInstance.post(
      "/auth/resend_verification_link",
      { email }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message ||
          "An error occurred while resending the verification link."
      );
    } else if (error.request) {
      throw new Error("No response received from server.");
    } else {
      throw new Error("Error: " + error.message);
    }
  }
};

export const createPassword = async (oobCode, password) => {
  try {
    if (typeof oobCode !== "string" || typeof password !== "string") {
      throw new Error("oobCode and password must be strings");
    }

    const response = await axiosAuthInstance.post("/auth/create_password", {
      oob_code: oobCode,
      password: password,
    });

    const { data, headers } = response;

    if (data === null) {
      console.warn(
        "Server returned null response. This may be expected behavior."
      );
      return { success: true, message: "Password created successfully" };
    }

    // Log response information
    console.log("Response data:", data);
    console.log("Response headers:", headers);

    // Retrieve Device_id from headers
    const deviceId = headers["device_id"];
    if (deviceId) {
      Cookies.set("Device_id", deviceId, {
        expires: 7,
        secure: true,

        sameSite: "Strict",
      });
      console.log("Device ID stored successfully:", deviceId);
    } else {
      console.warn("Device ID not found in response headers");
    }

    // Extract tokens from the response payload
    const { idToken, refreshToken } = data.payload || {};
    if (idToken && refreshToken) {
      // Store tokens in cookies
      Cookies.set("idtoken", idToken, {
        expires: 7,
        secure: true,
        sameSite: "Strict",
      });
      Cookies.set("refreshtoken", refreshToken, {
        expires: 7,
        secure: true,
        sameSite: "Strict",
      });
      console.log("Tokens stored successfully in cookies");
    } else {
      console.error("idToken or refreshToken is undefined:", {
        idToken,
        refreshToken,
      });
    }

    return data;
  } catch (error) {
    if (error.response?.status === 401) {
      alert("Session expired. Please log in again.");
      Cookies.remove("idtoken");
      Cookies.remove("refreshtoken");
      Cookies.remove("Device_id");
      window.location.href = "/login";
    } else {
      console.error("Error creating password:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
      throw error;
    }
  }
};

export const authPhoneNumber = async (country_code, contact) => {
  try {
    const idToken = Cookies.get("idtoken"); // Get token from cookies

    if (!idToken) {
      throw new Error("ID token is missing");
    }

    if (!contact) {
      throw new Error("Contact number is missing");
    }

    console.log("Sending country code and contact:", country_code, contact); // For debugging

    const response = await axiosAuthInstance.post(
      "/auth/contact",
      { country_code, contact },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error submitting phone number:", error);

    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }

    throw error;
  }
};

export const verifyOtp = async (contact, otp) => {
  try {
    const idToken = Cookies.get("idtoken"); // Get token from cookies

    if (!idToken) {
      throw new Error("ID token is missing");
    }

    console.log("OTP Type:", typeof otp);
    console.log("Contact:", contact, "OTP:", otp);

    // Ensure both contact and OTP are strings
    // const otpString = String(otp);
    // const contactString = String(contact);

    if (typeof contact !== "string" || typeof otp !== "string") {
      throw new Error("Contact and OTP must be strings");
    }

    const response = await axiosAuthInstance.post(
      "/auth/verify_otp",
      {
        contact: contact,
        otp: otp,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error verifying OTP:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    throw error;
  }
};


export const resendotp = async (country_code, contact) => {
  try {
    const idToken = Cookies.get("idtoken"); // Get token from cookies

    if (!idToken) {
      throw new Error("ID token is missing");
    }

    const response = await axiosAuthInstance.post(
      "/auth/resend_otp",
      {
        country_code: country_code,
        contact: contact,
   
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error sending otp:", error);

    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }

    throw error;
  }
};

export const submitBasicDetails = async (
  firstName,
  lastName,
  birthday,
  gender,
  aris_voice
) => {
  const idToken = Cookies.get("idtoken");

  try {
    console.log("idToken:", idToken);
    const response = await axiosAuthInstance.post(
      "/auth/basic_details",
      {
        first_name: firstName,
        last_name: lastName,
        date_of_birth: birthday,
        gender: gender,
        aris_voice: aris_voice,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error submitting basic details:", error);
    throw error;
  }
};

export const uploadProfilePhoto = async (formData) => {
  try {
    const idToken = Cookies.get("idtoken"); // Get the ID token from cookies

    const response = await axiosAuthInstance.post(
      "/auth/profile_url",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for form data
          Authorization: `Bearer ${idToken}`, // Include ID token in the Authorization header
        },
      }
    );
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error uploading profile photo:", error);
    throw error; // Rethrow the error for further handling
  }
};


export const loginWithEmail = async (email, password) => {
  try {
    const response = await axiosLoginInstance.post("/auth/sign-in-with-email", {
      email,
      password,
    });

    console.log("Response Headers:", response.headers);

    if (response.status === 200) {
      const deviceId =
        response.headers["Device_id"] || response.headers["device_id"];
      console.log("Device ID:", deviceId);
      
      if (deviceId) {
        Cookies.set("Device_id", deviceId);
        console.log("Device ID set in cookies");
      } else {
        console.error("Device ID not found in headers");
      }

      return response.data;
    }
  } catch (error) {
    console.error(
      "Login with email failed:",
      error.response?.data || error.message
    );

    // Preserve the original error structure
    if (error.response?.data) {
      // If the error has the specific structure we're looking for
      if (error.response.data.status === 400 && 
          error.response.data.message === "Maximum number of devices reached") {
        throw error.response.data;  // Throw the entire error object
      }
      
      // For other structured errors
      throw {
        status: error.response.status,
        message: error.response.data.message || "Incorrect email or password",
        payload: error.response.data.payload || {}
      };
    }

    // For network errors or other unstructured errors
    throw {
      status: error.response?.status || 500,
      message: error.message || "An error occurred",
      payload: {}
    };
  }
};



export const loginWithPhone = async (country_code, contact, recaptchaToken) => {
  try {
    const response = await axiosLoginInstance.post("/auth/sign-in-with-number", {
      country_code, // Send country_code separately
      contact, // Send contact separately
      recaptchaToken,
    });

    console.log("Login with phone response:", response); // Debugging

    if (response.status === 200) {
      return response.data; // Successful login data
    }
  } catch (error) {
    console.error(
      "Login with phone failed:",
      error.response?.data || error.message
    );

    // Preserve the original error structure
    if (error.response?.data) {
      // If the error has the specific structure we're looking for
      if (error.response.data.status === 400 && 
          error.response.data.message === "Maximum number of devices reached") {
        throw error.response.data;  // Throw the entire error object
      }
      
      // For other structured errors
      throw {
        status: error.response.status,
        message: error.response.data.message || "Incorrect email or password",
        payload: error.response.data.payload || {}
      };
    }

    // For network errors or other unstructured errors
    throw {
      status: error.response?.status || 500,
      message: error.message || "An error occurred",
      payload: {}
    };
  }
};

export const resendSignInOtp = async (country_code, contact, recaptchaToken) => {
  try {
    const response = await axiosLoginInstance.post("/auth/resend_sign_in_otp", {
      country_code, // Send country_code separately
      contact, // Send contact separately
      recaptchaToken,
    });

    console.log("Login with phone response:", response); // Debugging

    if (response.status === 200) {
      return response.data; // Successful login data
    }
  } catch (error) {
    console.error(
      "Login with phone failed:",
      error.response?.data || error.message
    );

    // Preserve the original error structure
    if (error.response?.data) {
      // If the error has the specific structure we're looking for
      if (error.response.data.status === 400 && 
          error.response.data.message === "Maximum number of devices reached") {
        throw error.response.data;  // Throw the entire error object
      }
      
      // For other structured errors
      throw {
        status: error.response.status,
        message: error.response.data.message || "Incorrect email or password",
        payload: error.response.data.payload || {}
      };
    }

    // For network errors or other unstructured errors
    throw {
      status: error.response?.status || 500,
      message: error.message || "An error occurred",
      payload: {}
    };
  }
};

export const verifyLoginOtp = async (contact, otp, session_info) => {
  try{
    const response = await axiosLoginInstance.post("/auth/OTP_sign_in", {
      contact,
      otp,
      session_info
    });

    if (response.status === 200) {
      // Extract tokens from the response
      const { idToken, refreshToken, localId } = response.data.payload;
      console.log("Extracted tokens:", { idToken, refreshToken, localId });

      // Store tokens in cookies
      if (idToken) {
        Cookies.set("idtoken", idToken);
      }

      if (refreshToken) {
        Cookies.set("refreshtoken", refreshToken);
      }

      if (localId) {
        Cookies.set("localId", localId);
      }

      // Store Device_id in cookies (if available)
      const deviceId =
        response.headers["Device_id"] || response.headers["device_id"];
      if (deviceId) {
        Cookies.set("Device_id", deviceId);
      }

      return response.data; // Successful login data
    }

  } catch(error) {
    console.error(
      "Login with phone failed:",
      error.response?.data || error.message
    );

    // Preserve the original error structure
    if (error.response?.data) {
      // If the error has the specific structure we're looking for
      if (error.response.data.status === 400 && 
          error.response.data.message === "Maximum number of devices reached") {
        throw error.response.data;  // Throw the entire error object
      }
      
      // For other structured errors
      throw {
        status: error.response.status,
        message: error.response.data.message || "Incorrect email or password",
        payload: error.response.data.payload || {}
      };
    }

    // For network errors or other unstructured errors
    throw {
      status: error.response?.status || 500,
      message: error.message || "An error occurred",
      payload: {}
    };
  }
}


export const sendResetPasswordLink = async (email) => {
  try {
    const response = await axiosAuthInstance.post("/auth/reset_password", {
      email,
    });
    return response.data; // Handle the response as needed
  } catch (error) {
    console.error("Error sending reset password link:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const resetPassword = async (oobcode, newPassword) => {
  try {
    const response = await axiosAuthInstance.post(
      "/auth/verify_reset_password",
      {
        oobcode,
        new_password: newPassword,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error.response || error.message);
    throw error;
  }
};

const clearAllCookies = () => {
  // Get all cookies
  const allCookies = Cookies.get();

  // Loop through all cookies and remove them
  Object.keys(allCookies).forEach((cookie) => {
    Cookies.remove(cookie);
  });
};

// export const signOut = async () => {
//   try {
//     const response = await axiosAuthInstance.get("/auth/signout").then(() => {
//       clearAllCookies();
//     });

//     return response;
//   } catch (error) {
//     console.error("Error resetting password:", error.response || error.message);
//     throw error;
//   }
// };

export const signOut = async () => {
  try {
    // Retrieve Device_id from cookies
    const deviceId = Cookies.get("Device_id");

    // Make the signout request with Device_id in headers
    const response = await axiosAuthInstance.get("/auth/signout", {
      headers: {
        Device_id: deviceId,
      },
    });

    // Clear cookies after signout
    clearAllCookies();
    localStorage.clear();

    return response;
  } catch (error) {
    console.error("Error signing out:", error.response || error.message);
    throw error;
  }
};

// export const refreshAccessToken = async () => {
//   try {
//     const response = await axiosAuthInstance
//       .post("/auth/refresh_token", {
//         refresh_token: Cookies.get("refreshtoken"),
//       })

//       .then(() => {
//         storeTokens(response.data.payload);
//       });

//     return response;
//   } catch (error) {
//     console.error("Error resetting password:", error.response || error.message);
//     throw error;
//   }
// };

export const updateBasicDetails = async (details) => {
  try {
    const response = await axiosAuthInstance.put("/auth/update_basic_details", {
      first_name: details.firstName,
      last_name: details.lastName,
      date_of_birth: details.dateOfBirth,
      gender: details.gender,
      pronoun: details.pronoun,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Failed to update basic details:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const handleFaith = async (faith) => {
  try {
    const response = await axiosAuthInstance.post(`/auth/faith?faith=${faith}`);
    return response.data;
  } catch (error) {
    console.error(
      "Failed to update faith",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const refreshAccessToken = async () => {
  try {
    const deviceId = Cookies.get("Device_id");

    console.log("inside refresh token api call"); // Retrieve device_id from cookies

    const response = await axiosAuthInstance
      .post(
        "/auth/refresh_token",
        {
          refresh_token: Cookies.get("refreshtoken"),
        },
        {
          headers: {
            Device_id: deviceId, // Pass device_id in headers
          },
        }
      )
      .then((response) => {
        console.log("Refresh token response:", response);
        if (response.status === 498) {
          window.location.href = "/";
          return response;
        }
        storeTokens(response.data.payload);
        return response;
      });

    return response;
  } catch (error) {
    console.error(
      "Error refreshing access token:",
      error.response || error.message,
      console.log(error.response)
    );
    throw error;
  }
};

export const setTokens = (accessToken, refreshToken, delay) => {
  const expiryDate = new Date(new Date().getTime() + delay * 1000);

  Cookies.set("access_token", accessToken, { expires: expiryDate });
  Cookies.set("refresh_token", refreshToken, { expires: expiryDate });
};

export const getAccessToken = () => {
  return Cookies.get("access_token");
};

export const getRefreshToken = () => {
  return Cookies.get("refresh_token");
};

export const clearTokens = () => {
  Cookies.remove("idtoken");
  Cookies.remove("refreshtoken");
};


export const manageSession = async (email) => {
  try {
    const response = await axiosAuthInstance.post("/auth/manage_session", { email });
    return response.data;
  } catch (error) {
    console.error("Error deleting session:", error.response || error.message);
    throw error;
  }
};

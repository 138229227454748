import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from "../../../../Assets/icon/close.svg";
import './style.css'

const ActionPlanModal = ({onClose, hideClose=false, isMobile, summaryData}) => {
    const [selectedFilter, setSelectedFilter] = useState('all');

    console.log("summaryData", summaryData)

  return (
    <>
        {summaryData ? <div className='actionplan-modal-container'>
            <div className="actionplan-modal-header">
                <p className="actionplan-modal-header-title">{`Action Plan: ${summaryData.tittle}`}</p>
                {!hideClose && <CloseIcon style={{cursor:'pointer'}} onClick={onClose}/>}
            </div>
            {!isMobile && (
                <>
                    <div className="actionplan-modal-body">
                        <p className="actionplan-modal-body-title">Desired Outcome</p>
                        <li>{summaryData.desired_outcome}</li>
                    </div>
                    <div className="actionplan-modal-body">
                        <p className="actionplan-modal-body-title">Weekly Target</p>
                        <li>{summaryData.weekly_targets}</li>
                    </div>
                </>
            )}
            <div className="actionplan-modal-actions-section">
                <p className="actionplan-modal-body-title">Actions</p>
                <div className="actionplan-filter-button-container">
                    <div 
                        className={`actionplan-filter-button-item ${selectedFilter === 'all' ? 'active-filter' : ''}`}
                        onClick={() => setSelectedFilter('all')}
                    >
                        All
                    </div>
                    <div 
                        className={`actionplan-filter-button-item ${selectedFilter === 'active' ? 'active-filter' : ''}`}
                        onClick={() => setSelectedFilter('active')}
                    >
                        Active
                    </div>
                    <div 
                        className={`actionplan-filter-button-item ${selectedFilter === 'completed' ? 'active-filter' : ''}`}
                        onClick={() => setSelectedFilter('completed')}
                    >
                        Completed
                    </div>
                </div>
                <div className="actionplan-selection-container">
                    {summaryData.action_list.map((item, index) => {
                        return (
                            <div className="actionplan-selection-item">
                                <input type="checkbox" name="" id="" />
                                <p>{item}</p>
                            </div>
                        )
                    })}
                </div>
                <div className="actionplan-modal-body">
                    <p className="actionplan-modal-body-title">Timeframe</p>
                    <li>Start immediately. Aris will track you progress for the next 8 weeks.</li>
                </div>
                <div className="end-actionplan-button-container">
                    End Action Plan
                </div>
            </div>
        </div>
        : <div className="actionplan-modal-container">No data found</div>
        }
    </>
  )
}

export default ActionPlanModal
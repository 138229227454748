import { ReactComponent as CloseIcon } from "../../../../Assets/icon/close.svg";
import './style.css'

const SummaryModal = ({onClose, hideClose=false, summaryData}) => {
  return (
    <>
      {summaryData ? (
        <div className='summary-modal-container'>
          <div className="summary-modal-header">
            <p className="summary-modal-header-title">{summaryData.tittle}</p>
            {!hideClose && <CloseIcon style={{cursor:'pointer'}} onClick={onClose}/>}
          </div>
          <div className="summary-modal-body">
            <p className="summary-modal-body-title">Purpose of Meeting</p>
            <li>{summaryData.meeting_purpose}</li>
          </div>
          <div className="summary-modal-body">
            <p className="summary-modal-body-title">Weekly Target</p>
            {summaryData.weekly_targets.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </div>
          <div className="summary-modal-body">
            <p className="summary-modal-body-title">Timeframe</p>
            <li>{summaryData.timeframe}</li>
          </div>
        </div>
      ) : (
        <div className="no-data-found">No data found</div>
      )}
    </>
  )
}

export default SummaryModal
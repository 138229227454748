import React, { memo, useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const GradientGraphVertical = ({ data, isStory = false }) => {
  const svgRef = useRef();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const bgC = isStory ? "#fff" : "#7E7F83";

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const width = screenWidth < 580 ? 260 : screenWidth > 1840 ? 500 : 400;
    const height = screenWidth < 580 ? 400 : screenWidth > 1840 ? 600 : 500;

    const rectWidth = width * 0.183;
    const rectHeight = height * 0.07;
    const gap = width * 0.02;
    const segmentGap = height * 0.005;
    const rectVerticalGap = height * 0.01;
    const cornerRadius = Math.min(width, height) * 0.01;
    const topMargin = height * 0.01;
    const bottomMargin = height * 0.1;
    const leftMargin = width * 0.03 + width * 0.02;

    svg.attr("width", width).attr("height", height);

    const defs = svg.append("defs");

    data.forEach((item, colIndex) => {
      const { topLabel, bottomLabel, segments } = item;

      segments.forEach((segment, segIndex) => {
        const gradientId = `gradient-segment-${colIndex}-${segIndex}`;
        const gradient = defs
          .append("linearGradient")
          .attr("id", gradientId)
          .attr("x1", "0%")
          .attr("x2", "0%")
          .attr("y1", segIndex === 0 ? "0%" : "100%") // Reversed gradient direction
          .attr("y2", segIndex === 0 ? "100%" : "0%");

        segment.colors.forEach((color, i) => {
          gradient
            .append("stop")
            .attr("offset", `${i * 100}%`)
            .attr("stop-color", color);
        });

        const x = colIndex * (rectWidth + gap + leftMargin);

        // Labels
        if (segIndex === 0) {
          svg
            .append("text")
            .attr("x", x + rectWidth / 2)
            .attr("y", topMargin + 25)
            .attr("text-anchor", "middle")
            .attr("font-size", ".9rem")
            .attr("font-family", "Urbanist")
            .attr("fill", bgC)
            .text(topLabel);
        }

        if (segIndex === 1) {
          svg
            .append("text")
            .attr("x", x + rectWidth / 2)
            .attr("y", height - bottomMargin + 20)
            .attr("text-anchor", "middle")
            .attr("font-size", ".9rem")
            .attr("font-family", "Urbanist")
            .attr("fill", bgC)
            .text(bottomLabel);
        }

        const calcRectFill = (percentage) => {
          const fullRects = Math.floor((percentage / 100) * 5);
          const partialHeight =
            ((percentage / 100) * 5 - fullRects) * rectHeight;
          return { fullRects, partialHeight };
        };

        const fill = calcRectFill(segment.percent);

        const yStartTop =
          topMargin +
          segIndex * (5 * rectHeight + 4 * rectVerticalGap + segmentGap);
        const yStartBottom =
          height -
          bottomMargin -
          (5 * rectHeight + 4 * rectVerticalGap) +
          segIndex * segmentGap;
        const yStart = segIndex === 1 ? yStartTop : yStartBottom;

        // Draw rectangles
        for (let i = 0; i < 5; i++) {
          const y =
            segIndex === 0
              ? yStart + i * (rectHeight + rectVerticalGap)
              : yStart - i * (rectHeight + rectVerticalGap);

          // Background rectangle
          svg
            .append("rect")
            .attr("x", x)
            .attr("y", y)
            .attr("width", rectWidth)
            .attr("height", rectHeight)
            .attr("rx", cornerRadius)
            .attr("ry", cornerRadius)
            .attr("fill", isStory ? "#E6F2E6" : "#8585851A");

          if (
            i < fill.fullRects ||
            (i === fill.fullRects && fill.partialHeight > 0)
          ) {
            const finalHeight =
              i < fill.fullRects ? rectHeight : fill.partialHeight;

            // Create fill rectangle
            const fillRect = svg
              .append("rect")
              .attr("x", x)
              .attr("y", y)
              .attr("width", rectWidth)
              .attr("height", 0)
              .attr("rx", cornerRadius)
              .attr("ry", cornerRadius)
              .attr("fill", `url(#${gradientId})`);

            // Animate fill based on segment direction (reversed)
            if (segIndex === 0) {
              // Top to bottom animation for top segment
              fillRect
                .attr("y", y) // Start from top
                .attr("height", 0)
                .transition()
                .delay(i * 500)
                .duration(1000)
                .ease(d3.easeCubicOut)
                .attr("height", finalHeight); // Grow downward
            } else {
              // Bottom to top animation for bottom segment
              fillRect
                .attr("y", y + rectHeight) // Start from bottom
                .attr("height", 0)
                .transition()
                .delay(i * 500)
                .duration(1000)
                .ease(d3.easeCubicOut)
                .attr("y", y + rectHeight - finalHeight) // Move up while growing
                .attr("height", finalHeight);
            }
          }
        }

        // Percentage text
        svg
          .append("text")
          .attr("x", x + rectWidth / 2)
          .attr(
            "y",
            segIndex === 1 ? yStart + height * 0.045 : yStart + height * 0.045
          )
          .attr("text-anchor", "middle")
          .attr("font-size", "1.125rem")
          .attr("font-weight", "500")
          .attr("fill", "#000")
          .text(`${segment.percent}%`);
      });
    });
  }, [data, screenWidth, isStory]);

  return <svg ref={svgRef} />;
};

export default memo(GradientGraphVertical);

import React from "react";

function Personality11() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 107"
      fill="none"
    >
      <g clip-path="url(#clip0_2161_30202)">
        <path
          d="M70.8197 46.0942L98.7324 101.793L29.5181 88.0513L13.8866 16.9468L70.8197 46.0942Z"
          fill="#4FE3D3"
        />
        <path
          d="M70.8197 46.0942L98.7324 101.793M70.8197 46.0942L13.8866 16.9468M70.8197 46.0942L29.5181 88.0513M98.7324 101.793L29.5181 88.0513M98.7324 101.793L13.8866 16.9468M29.5181 88.0513L13.8866 16.9468"
          stroke="white"
          stroke-opacity="0.7"
          stroke-width="0.211394"
          stroke-miterlimit="10"
        />
        <path
          d="M97.4199 101.793L12.7196 17.0928L28.2056 88.0517L97.4199 101.793Z"
          fill="#31C5AB"
        />
        <g filter="url(#filter0_f_2161_30202)">
          <path
            d="M25.3692 20.1704L18.4939 22.4077L18.4939 29.6476L14.2447 23.7904L7.36939 26.0277L11.6186 20.1704L7.36939 14.3132L14.2447 16.5505L18.4939 10.6933L18.4939 17.9332L25.3692 20.1704Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter1_f_2161_30202)">
          <path
            d="M71.5762 59.2795L59.9364 63.0649L59.9364 75.3148L52.7427 65.4044L41.1029 69.1898L48.2967 59.2795L41.1029 49.3691L52.7427 53.1545L59.9364 43.2441L59.9364 55.494L71.5762 59.2795Z"
            fill="white"
            fill-opacity="0.4"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter2_f_2161_30202)">
          <path
            d="M98.7324 92.3331L93.1472 94.146L93.1472 100.013L89.6954 95.2665L84.1102 97.0794L87.562 92.3331L84.1102 87.5867L89.6954 89.3997L93.1472 84.6533L93.1472 90.5201L98.7324 92.3331Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30202"
          x="-0.316106"
          y="3.00811"
          width="33.3705"
          height="34.3246"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30202"
          />
        </filter>
        <filter
          id="filter1_f_2161_30202"
          x="33.4183"
          y="35.5589"
          width="45.8432"
          height="47.4413"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30202"
          />
        </filter>
        <filter
          id="filter2_f_2161_30202"
          x="76.4241"
          y="76.9681"
          width="29.9935"
          height="30.7299"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30202"
          />
        </filter>
        <clipPath id="clip0_2161_30202">
          <rect
            width="105.697"
            height="105.697"
            fill="white"
            transform="translate(0.693359 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Personality11;

import React, { useState } from "react";
import "./style.css";

import close from "../../Assets/icon/close.svg";

function MobilePopUp({ isPopupVisible, onClose, children, hideClose }) {
  return (
    // <>
    //   <div class="overlay"></div>
    //   <div className="mobile-pop-up-container">
    //     <div className="mobile-pop-up-content"></div>
    //   </div>
    // </>

    <>
      {/* Overlay for background blur */}
      <div
        className={`overlay ${isPopupVisible ? "visible" : ""}`}
        onClick={onClose} // Close popup when clicking the overlay
      ></div>

      {/* Popup container */}
      <div
        className={`mobile-pop-up-container ${isPopupVisible ? "visible" : ""}`}
      >
        <div className="mobile-popup-top-indicator"></div>
        {!hideClose && (
          <div className="mobile-pop-up-container-close" onClick={onClose}>
            <img src={close} alt="close" />
          </div>
        )}

        <div className="mobile-pop-up-content">{children}</div>
        <div className="mobile-popup-top-indicator-2"></div>
      </div>
    </>
  );
}

export default MobilePopUp;

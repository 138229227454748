import React, { useState } from "react";
import "./style.css";
import { ReactComponent as LeftArrow } from "../../Assets/icon/leftarrow.svg";
import { ReactComponent as RightArrow } from "../../Assets/icon/rightarrow.svg";

const Calendar = ({ selectedDate, setSelectedDate }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const handleDateClick = (date) => {
    if (date >= new Date(new Date().setHours(0, 0, 0, 0))) {
      setSelectedDate(date);
    }
  };

  const renderDays = () => {
    const days = [];
    const startDay = startOfMonth.getDay(); // Day of the week (0-6)
    const daysInMonth = endOfMonth.getDate();

    // Fill empty slots before the start of the month
    for (let i = 0; i < startDay; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    // Fill actual days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const current = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );
      const isSelected =
        selectedDate.toDateString() === current.toDateString();
      const isPast = current < new Date(new Date().setHours(0, 0, 0, 0));
      days.push(
        <div
          key={day}
          className={`calendar-day ${isSelected ? "selected" : ""} ${isPast ? "disabled" : ""}`}
          onClick={() => handleDateClick(current)}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <LeftArrow style={{ cursor: 'pointer' }} onClick={handlePrevMonth} />
        <span>
          {currentDate.toLocaleString("default", {
            month: "long",
          })}{" "}
          {currentDate.getFullYear()}
        </span>
        <RightArrow style={{ cursor: 'pointer' }} onClick={handleNextMonth} />
      </div>
      <div className="selected-date-container">
        {selectedDate.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}
      </div>
      <div className="calendar-grid">
        <div className="calendar-day-name">Mo</div>
        <div className="calendar-day-name">Tu</div>
        <div className="calendar-day-name">We</div>
        <div className="calendar-day-name">Th</div>
        <div className="calendar-day-name">Fr</div>
        <div className="calendar-day-name">Sa</div>
        <div className="calendar-day-name">Su</div>
        {renderDays()}
      </div>
    </div>
  );
};

export default Calendar;
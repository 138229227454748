import React, { useEffect, useState } from "react";
import NavigationButton from "../../Components/NavigationButton";
import nextbtn from "../../Assets/images/arrow-right-blue.png";
import { ChevronDown, ChevronUp } from "lucide-react";
import { getFaithPackages } from "../../Services/subscription";
import FaithSelectComponent from "./FaithSelectComponent";
import SearchableDropdown from "../../Components/SearchableDropdown";
import { handleFaith } from "../../Services/auth";
import { useNavigate } from "react-router-dom";

const FaithUpgrade = ({ onBack }) => {
  const navigate = useNavigate();

  const [faithData, setFaithData] = useState(null);
  const [showFaithOptions, setShowFaithOptions] = useState(false);
  const [selectedFaith, setSelectedFaith] = useState("Select your faith");
  const [issending, setIssending] = useState(false);

  const fetchFaithData = async () => {
    if (issending) return;
    try {
      setIssending(true);
      const response = await getFaithPackages();
      setFaithData(response.payload || {});
    } catch (error) {
      setFaithData("Failed to fetch faith data");
      console.error("Error fetching faith data:", error);
    } finally {
      setIssending(false);
    }
  };

  useEffect(() => {
    fetchFaithData();
  }, []);

  const handleFaithUpgrade = async () => {
    try {
      const response = await handleFaith(selectedFaith);
      console.log(response);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error upgrading faith:", error);
    }
  };

  useEffect(() => {
    fetchFaithData();
  }, []);

  return (
    <div className="Fullpage-container-email">
      <div className="gender-pronouns-container">
        <div className="gender-header">
          <div className="faith-upgradation-header-top">
            <NavigationButton
              onClick={onBack}
              direction="left"
              label="Back"
              className="back-button"
            />
            <h2
              className="heading-h2"
              style={{ marginBottom: "2px", marginTop: "0px" }}
            >
              Basic details
            </h2>
            <p className="mobile-para">
              Integrate your faith and belief system
            </p>
          </div>
          <div className="faith-upgrade-field">
            <label className="basic-input-lable">What's your faith?</label>
            <SearchableDropdown
              onBoarding={true}
              setSelectedFaith={setSelectedFaith}
            />
            {/* <div className={`faith-input-container ${showFaithOptions && 'focussed'}`} onClick={() => setShowFaithOptions(!showFaithOptions)}>
                            <p>{selectedFaith}</p>
                            {showFaithOptions ? <ChevronUp /> : <ChevronDown />}
                        </div>
                        {showFaithOptions && <FaithSelectComponent faithData={faithData} setSelectedFaith={setSelectedFaith} setShowFaithOptions={setShowFaithOptions} />} */}
          </div>
        </div>

        <footer className="faith-upgradation-footer">
          <button
            className={`submit-button skip`}
            onClick={() => {
              window.location.href = "/dashboard";
            }}
          >
            Skip
          </button>
          <button
            className={`submit-button ${
              selectedFaith !== "Select your faith" ? "valid" : "invalid"
            }`}
            onClick={() => {
              if (selectedFaith !== "Select your faith") {
                handleFaithUpgrade();
              }
            }}
          >
            Continue
            <img src={nextbtn} alt="nxtbtn" className="nxtbtn" />
          </button>
        </footer>
      </div>
    </div>
  );
};

export default FaithUpgrade;

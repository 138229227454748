import React, { useEffect, useState } from "react";
import arrowLeft from "../../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../Providers/HeaderContextProvider";
import edit from "../../../Assets/images/edit.svg";
import Trash from "../../../Assets/images/Trash.svg";
import "./style.css";
import MobilePopUp from "../../../Components/MobilePopUp/MobilePopUp";
import PhoneSettingsScreendDelete1 from "../PhonePopUp/PhoneSettingsScreendDelete1";
import tickIcon from "../../../Assets/icon/tick-icon.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function MobilePhoneSettingsScreen1() {
  const navigate = useNavigate();
  const { setHeaderContent, profileContent } = useHeader();
  const [deletePhone, setdeletePhone] = useState(false);
  const [userContact, setUserContact] = useState(
    () =>
      `${profileContent.mobile.country_code}${profileContent.mobile.contact}`
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    setHeaderContent(false);

    const isContactUpdated = localStorage.getItem("isContactUpdated", "true");
    if (isContactUpdated === "true") {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        localStorage.removeItem("isContactUpdated");
      }, 3000);
    }
  }, []);

  const onEditClick1 = () => {
    navigate("/settings/phone/2");
  };

  const onDeleteClick = () => {
    setdeletePhone(true);
  };

  const onDeleteClose = () => {
    setdeletePhone(false);
  };
  return (
    <div>
      {" "}
      <header className="termsofuse-header">
        <div className="termsofuse-gradient-background">
          <div className="termsofuse-nav-container">
            <div
              className="termsofuse-logo-container"
              onClick={() => navigate("/settings")}
            >
              <div>
                <h1 className="termsofuse-title">Phone Number</h1>
                <img
                  src={arrowLeft}
                  alt="Back"
                  className="termsofuse-back-button"
                />
              </div>
            </div>

            <div className="termsofuse-space"></div>
            <div className="set-phone-modal-support mobile-sub-txt-set-phone">
              Your number is used to recover your account and receive SMS texts.
            </div>
          </div>
        </div>
      </header>
      <div className="set-phone-int-cnt mobile-set-cnt">
        <div className="set-phone-modal-input-container">
          <div className="set-phone-input-label">Mobile number</div>
          <div className="mobile-input-wrapper-1">
            <PhoneInput
              international
              defaultCountry="US"
              value={userContact}
              disabled={true}
              className="mobile-input"
              style={{ cursor: "not-allowed" }}
            />
            <button className="edit-button" onClick={onEditClick1}>
              <span role="img" aria-label="edit">
                <img src={edit} alt="edit" />
              </span>{" "}
              Edit
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
            marginBottom: "20px",
          }}
          onClick={onDeleteClick}
        >
          <img src={Trash} alt="trash" />{" "}
          <div className="set-phone-alert-txt">Remove phone number</div>
        </div>
      </div>
      <MobilePopUp
        isPopupVisible={deletePhone}
        onClose={onDeleteClose}
        children={<PhoneSettingsScreendDelete1 />}
      />
      {showSuccessMessage && (
        <div className="success-message-box">
          <img src={tickIcon} alt="success" />
          Your number has been changed
        </div>
      )}
    </div>
  );
}

export default MobilePhoneSettingsScreen1;

import React from "react";
import { X } from "lucide-react";
import rightIcon from "../../Assets/icon/chevron-right.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdviceInsight from "../../Pages/RelationShip/RelationShipChatScreen/RelationshipAdiveInsight";

const Modal = ({ contact, onClose, onManageClick }) => {
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  const [adviceInsight, setAdviceInsight] = useState(false);

  const viewProfileHandler = () => {
    navigate("/relationshipprofile", {
      state: {
        contact: contact, // Assuming the contact object has an ID
      },
    });
    onClose(); // Close the modal after navigating
  };

  const getAdviceInsight = () => {
    setAdviceInsight(true);
  };

  const options = [
    { label: "Manage Relationship", onClick: onManageClick },
    { label: "Get Relationship Advice", onClick: getAdviceInsight },
    { label: "View Profile", onClick: viewProfileHandler },
  ];

  return (
    <>
      {!adviceInsight ? ( // Conditionally render the modal content or AdviceInsight
        <div className="manage-overlay" onClick={onClose}>
          <div
            className="manage-modal-card"
            onClick={(e) => e.stopPropagation()}
          >
            <>
              <div className="manage-modal-header">
                {contact.profile_pic_url && !imageError ? (
                  <div className="manage-avatar">
                    <img
                      src={contact.profile_pic_url}
                      alt={contact.first_name + " " + contact.last_name}
                      className="manage-avatar-image"
                      onError={() => setImageError(true)} // Handle image load failures
                    />
                  </div>
                ) : (
                  <div className="manage-avatar">
                    {" "}
                    {`${contact?.first_name?.[0] || ""}${
                      contact?.last_name?.[0] || ""
                    }` || ""}
                  </div>
                )}
                <div className="manage-info">
                  <div className="manage-name">
                    {contact.first_name + " " + contact.last_name}
                  </div>
                  <div className="manage-type">
                    {contact.related_data.relationship_type}
                  </div>
                </div>
                <button onClick={onClose} className="manage-close-button">
                  <X size={20} />
                </button>
              </div>
              <div className="manage-modal-content">
                {options.map((option, index) => (
                  <button
                    key={index}
                    className="manage-option-button"
                    onClick={option.onClick}
                  >
                    {option.label}
                    <img
                      src={rightIcon}
                      alt=""
                      className="manage-rightarrowicon"
                    />
                  </button>
                ))}
              </div>
            </>
          </div>
        </div>
      ) : (
        <div className="manage-overlay" onClick={onClose}>
          <div className="manage-modal" onClick={(e) => e.stopPropagation()}>
            <AdviceInsight
              onClose={() => setAdviceInsight(false)}
              contact={contact} // Pass a callback to close AdviceInsight
            />
          </div>
        </div>
      )}
    </>
  );
};
export default Modal;

import { createBrowserRouter, useLocation, Navigate } from "react-router-dom";

// import { lazy } from "react";
import Assessments from "./Pages/Assessments";
import AssessmentSaveProgress from "./Pages/AssessmentSaveProgress/index";
import AssessmentEncouragement from "./Pages/AssessmentEncouragement/index";
import AssessmentResult from "./Pages/AssessmentResult/index";
import Survey from "./Pages/Survey/index";
import RelationShip from "./Pages/RelationShip/RelationShip";

import SignupPage from "./Pages/Signup";
import LoginPage from "./Pages/Login";
import ForgetPassword from "./Pages/ForgetPassword";
import ResetPassword from "./Pages/ResetPassword";
import TermsofUse from "./Pages/TermsofUse";
import AboutAris from "./Pages/AboutAris";
import ArisPotraits from "./Pages/Potraits";

import ManageRelationship from "./Pages/ManageRelationship";
import MyProfile from "./Pages/MyProfile";
import RelationshipProfile from "./Pages/RelationshipProfile";
import Onboarding from "./Pages/Onboarding";
import CustomerSupport from "./Pages/CustomerSupport/CustomerSupport";
import Settings from "./Pages/Settings";
import MobilePhoneSettingsScreen1 from "./Pages/Settings/MobileScreens/MobilePhoneSettingsScreen1";
import MobilePhoneSettingsScreen2 from "./Pages/Settings/MobileScreens/MobilePhoneSettingsScreen2";
import MobilePhoneSettingsScreen3 from "./Pages/Settings/MobileScreens/MobilePhoneSettingsScreen3";
import EmailSettingPhoneScreen1 from "./Pages/Settings/EmailScreens/EmailSettingPhoneScreen1";
import EmailSettingPhoneScreen2 from "./Pages/Settings/EmailScreens/EmailSettingPhoneScreen2";
import EmailSettingPhoneScreen3 from "./Pages/Settings/EmailScreens/EmailSettingPhoneScreen3";
import NotificationSettings from "./Pages/Settings/Notifications/NotificationSettings";
import Communications from "./Pages/Settings/Communications/Communications";
import Loader from "./Pages/LoadingPage/DefaultLoader";
import ButtonLoader from "./Pages/LoadingPage/ButtonLoader";
import ErrorScreen from "./Pages/ErrorScreens";
import Feedback from "./Pages/FeedBack/Feedback";
import StoryList from "./Pages/ArisStory";
import Topic from "./Pages/ArisStory/Topic";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ArisCoach from "./Pages/Coach";
import NewSurvey from "./Pages/NewSurvey/NewSurvey";
import NewConfirmation from "./Pages/NewConfirmation";
import CoachingHistory from "./Pages/CoachingHistory/CoachingHistory";
import ManageMembership from "./Pages/ManageMembership";
import Success from "./Pages/Pricing/Success";
import Cancel from "./Pages/Pricing/Cancel";

const ProtectedRoute = ({ element: Element }) => {
  const location = useLocation();
  return location.state?.fromValidPage ? (
    <Element />
  ) : (
    <Navigate to="/login" replace />
  );
};

// const Pages = {
//   ASSESSMENTS: import("./Pages/Assessments/index"),
//   ASSESSMENTS_SAVE_PROGRESS: lazy(() =>
//     import("./Pages/AssessmentSaveProgress/index")
//   ),
//   ASSESSMENTS_ENCOURAGEMENT: lazy(() =>
//     import("./Pages/AssessmentEncouragement/index")
//   ),
//   ASSESSMENTS_RESULT: lazy(() => import("./Pages/AssessmentResult/index")),
//   SURVEY: lazy(() => import("./Pages/Survey/index")),
// };

export const router = createBrowserRouter([
  {
    id: "home",
    text: "Login",
    path: "/",
    element: <LoginPage />,
    errorElement: <div>Error</div>,
  },
  {
    id: "assessment",
    text: "Assessment",
    path: "/onboarding",
    element: <Assessments />,
    errorElement: <div>Error</div>,
  },
  {
    id: "assessmentSaveProgress",
    text: "Assessment-Save Progress",
    path: "/assessmentProgress/:menu",
    element: <AssessmentSaveProgress />,
    errorElement: <div>Error</div>,
  },
  {
    id: "assessmentEncouragment",
    text: "Assessment-Encouragement",
    path: "/onboarding/instructions",
    element: <AssessmentEncouragement />,
    errorElement: <div>Error</div>,
  },
  {
    id: "assessmentResult",
    text: "Assessment-Result",
    path: "/assessmentResult",
    element: <AssessmentResult />,
    errorElement: <div>Error</div>,
  },
  {
    id: "survey",
    text: "survey",
    path: "/survey",
    element: <Survey />,
    errorElement: <div>Error</div>,
  },
  {
    id: "new-survey",
    text: "new-survey",
    path: "/onboarding/chat",
    element: <NewSurvey />,
    errorElement: <div>New Survey - Error</div>,
  },
  {
    id: "pop-confirmation",
    text: "pop-confirmation",
    path: "/pop-assessment-confirmation",
    element: <NewConfirmation />,
    errorElement: <div>confirmation- Error</div>,
  },
  {
    id: "payment-success",
    text: "payment-success",
    path: "/payment-success",
    element: <Success />,
    errorElement: <div>payment-success-Error</div>,
  },
  {
    id: "payment-cancel",
    text: "payment-cancel",
    path: "/payment-cancel",
    element: <Cancel />,
    errorElement: <div>payment-cancel-Error</div>,
  },
  {
    id: "relationShip",
    text: "Relationship",
    path: "/chat",
    element: <RelationShip />,
    errorElement: <div>Error</div>,
  },
  {
    id: "about-aris",
    text: "About",
    path: "/about-aris",
    element: <AboutAris />,
    errorElement: <div>About aris Error</div>,
  },

  {
    id: "term-of-use",
    text: "Terms",
    path: "/term-of-use",
    element: <TermsofUse />,
    errorElement: <div>About aris Error</div>,
  },
  {
    id: "customer-support",
    text: "customer-support",
    path: "/customer-support",
    element: <CustomerSupport />,
    errorElement: <div>customer-support Error</div>,
  },
  {
    id: "settings",
    text: "settings",
    path: "/settings",
    element: <Settings />,
    errorElement: <div>settings Error</div>,
  },
  {
    id: "settings-phone1",
    text: "settings",
    path: "/settings/phone/1",
    element: <MobilePhoneSettingsScreen1 />,
    errorElement: <div>settings Error</div>,
  },
  {
    id: "settings-phone2",
    text: "settings",
    path: "/settings/phone/2",
    element: <MobilePhoneSettingsScreen2 />,
    errorElement: <div>settings Error</div>,
  },
  {
    id: "Dashboard",
    text: "Dashboard",
    path: "/dashboard",
    element: <Dashboard />,
    errorElement: <div>dashboard Error</div>,
  },
  {
    id: "settings-phone3",
    text: "settings",
    path: "/settings/phone/3",
    element: <MobilePhoneSettingsScreen3 />,
    errorElement: <div>settings Error</div>,
  },
  {
    id: "settings-mail1",
    text: "settings",
    path: "/settings/email/1",
    element: <EmailSettingPhoneScreen1 />,
    errorElement: <div>settings Error</div>,
  },
  {
    id: "settings-mail2",
    text: "settings",
    path: "/settings/email/2",
    element: <EmailSettingPhoneScreen2 />,
    errorElement: <div>settings Error</div>,
  },
  {
    id: "settings-mail3",
    text: "settings",
    path: "/settings/email/3",
    element: <EmailSettingPhoneScreen3 />,
    errorElement: <div>settings Error</div>,
  },
  {
    id: "settings-notification",
    text: "settings",
    path: "/settings/notification",
    element: <NotificationSettings />,
    errorElement: <div>settings Error</div>,
  },
  {
    id: "settings-communication",
    text: "settings",
    path: "/settings/communication",
    element: <Communications />,
    errorElement: <div>settings Error</div>,
  },
  {
    id: "loader",
    text: "l",
    path: "/loader",
    element: <Loader />,
    errorElement: <div>settings Error</div>,
  },
  {
    id: "potraits",
    text: "Potraits",
    path: "/portraits",
    element: <ArisPotraits />,
    errorElement: <div>potraits-Error</div>,
  },
  {
    id: "error",
    text: "Error",
    path: "/error/:error",
    element: <ErrorScreen />,
    errorElement: <div>Error</div>,
  },
  {
    id: "login",
    text: "Login",
    path: "/login",
    element: <LoginPage />,
    errorElement: <div>Error</div>,
  },
  {
    id: "signup",
    text: "Signup",
    path: "/signup/*",
    element: <SignupPage />,
    errorElement: <div>Error</div>,
  },
  {
    id: "forgetpassword",
    text: "Forget Password",
    path: "/forgetpassword",
    element: <ProtectedRoute element={ForgetPassword} />,
    errorElement: <div>Error</div>,
  },
  {
    id: "resetpassword",
    text: "Reset Password",
    path: "/reset-password",
    element: <ResetPassword />,
    errorElement: <div>Error</div>,
  },
  {
    id: "manageRelationship",
    text: "Manage Relationship",
    path: "/manageRelationship",
    element: <ManageRelationship />,
    errorElement: <div>Error</div>,
  },
  {
    id: "myprofile",
    text: "My Profile",
    path: "/myprofile",
    element: <MyProfile />,
    errorElement: <div>Error</div>,
  },
  {
    id: "onboarding",
    text: "Onboarding",
    path: "/onboarding",
    element: <Onboarding />,
    errorElement: <div>Error</div>,
  },
  {
    id: "feedback",
    text: "feedback",
    path: "/feedback",
    element: <Feedback />,
    errorElement: <div>Error</div>,
  },
  {
    id: "story",
    text: "Story",
    path: "/story",
    element: <StoryList />,
    errorElement: <div>Error</div>,
  },
  {
    id: "topic",
    text: "topic",
    path: "/topic/:category",
    element: <Topic />,
  },
  {
    id: "onboarding-story",
    text: "onboarding-story",
    path: "/onboarding/story/:category",
    element: <Topic />,
  },
  {
    id: "coach",
    text: "coach",
    path: "/coach/:type",
    element: <ArisCoach />,
  },
  {
    id: "relationshipProfile",
    text: "Relationship Profile",
    path: "/relationshipProfile",
    element: <RelationshipProfile />,
    errorElement: <div>Error</div>,
  },
  {
    id: "coachingHistory",
    text: "Coaching History",
    path: "/coachingHistory",
    element: <CoachingHistory />,
    errorElement: <div>Error</div>,
  },
  {
    id: "manageMembership",
    text: "Manage Membership",
    path: "/manageMembership",
    element: <ManageMembership />,
    errorElement: <div>Error</div>,
  },
]);

// // ForgetPasswordLeftSection.jsx
// import React from 'react';
// import group from '../../Assets/images/Group.svg'
// import './style.css';


// const ForgetPasswordLeftSection = () => (
//   <div className="forgetpassword-left-section">
//     <div className='group-3'>
//       <div className='group-2'>
//         <div className='group-1'>
          
//           <div>
//            <img
//         src={group}
//         alt="Left Section Graphic"

//            />
//            </div>
//       </div>
//       </div>
//     </div>
//   </div>
// );

// export default ForgetPasswordLeftSection;




import React, { useEffect, useState } from 'react';
import group from '../../Assets/images/Group.svg'; // Main image
import './style.css';
import Stroke1 from '../../Assets/icon/Stroke1.svg'; // Stroke image

const ForgetPasswordLeftSection = () => {
  const [showStrokes, setShowStrokes] = useState(false);

  // Handle screen size change to toggle strokes visibility
  useEffect(() => {
    const handleResize = () => {
      const isLargeScreen = window.innerWidth >= 1024;
      setShowStrokes(isLargeScreen);
    };

    // Set initial state based on current screen size
    handleResize();

    // Add event listener to monitor window resize
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="forgetpassword-left-section">
      {showStrokes && (
   <>
          <img src={Stroke1} alt="Stroke Layer 1" className="stroke stroke-1" />
          <img src={Stroke1} alt="Stroke Layer 2" className="stroke stroke-2" />
          <img src={Stroke1} alt="Stroke Layer 3" className="stroke stroke-3" />
          </>
      )}

      <div className="group-container">
        <img src={group} alt="Left Section Graphic" className="main-group" />
      </div>
    </div>
  );
};

export default ForgetPasswordLeftSection;
// import React from 'react';
// import '../../App.css';

// const GradientBackground = ({ children }) => {
//   return (
//     <div className="gradient-background">
//       {children}
//     </div>
//   );
// };

// export default GradientBackground;


import React from 'react';
import '../../App.css';

const GradientBackground = ({children}) => {
  return (
    <div id="background">
      <div id="oval1"></div>
      <div id="oval2"></div>
      {children}

    </div>
  );
};

export default GradientBackground;

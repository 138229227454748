import React from "react";
import { MoreVertical } from "lucide-react";
import { useState } from "react";

const ContactCard = ({ contact, onClick }) => {
  const [imageError, setImageError] = useState(false);

  return (
    <div className="manage-card">
      <div className="manage-card-content">
        {contact.profile_pic_url && !imageError ? (
          <div className="manage-avatar">
            <img
              src={contact?.profile_pic_url}
              alt={contact.first_name + contact.last_name}
              className="manage-avatar-image"
              onError={() => setImageError(true)} // Handle image load failures
            />
          </div>
        ) : (
          <div className="manage-avatar">
            {`${contact?.first_name?.[0]?.toUpperCase() || ""}${
              contact?.last_name?.[0]?.toUpperCase() || ""
            }` || ""}
          </div>
        )}
        <div className="manage-info">
          <div className="manage-name">
            {`${contact?.first_name?.charAt(0).toUpperCase() || ""}${
              contact?.first_name?.slice(1).toLowerCase() || ""
            } ${contact?.last_name?.charAt(0).toUpperCase() || ""}${
              contact?.last_name?.slice(1).toLowerCase() || ""
            }`}
          </div>

          <div className="manage-type">
            {contact?.related_data?.relationship_category}
          </div>
        </div>
      </div>
      <button onClick={onClick} className="manage-more-button">
        <MoreVertical size={20} />
      </button>
    </div>
  );
};

export default ContactCard;

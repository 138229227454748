// import React, { useState } from "react";
// import arrowRight from "../../../Assets/images/arrow-right.svg";
// import { useNavigate } from "react-router-dom";
// import { sendUpdatedContact } from "../../../Services/settingsServices";
// import PhoneInput from "react-phone-number-input";
// import "react-phone-number-input/style.css";
// import { parsePhoneNumberFromString } from "libphonenumber-js";

// function PhoneSettingsScreen2({ onUpdateContactClick }) {
//   const [newMobileNumber, setNewMobileNumber] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   const parsePhoneNumber = (phoneNumber) => {
//     const parsed = parsePhoneNumberFromString(phoneNumber);
//     if (!parsed) {
//       throw new Error("Invalid phone number format");
//     }
//     return parsed;
//   };

//   const onUpdateContact = async () => {
//     setIsLoading(true);
//     try {
//       const parsedPhoneNumber = parsePhoneNumber(newMobileNumber); // Assume this utility function parses the phone number
//       const country_code = `+${parsedPhoneNumber.countryCallingCode}`;
//       const contact = parsedPhoneNumber.nationalNumber;

//       const response = await sendUpdatedContact({
//         country_code: country_code,
//         contact: contact,
//       });
//       if (response.status === "success") {
//         sessionStorage.setItem("newcountryCode", country_code);
//         sessionStorage.setItem("newContact", contact);
//         onUpdateContactClick();
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   return (
//     <div className="set-phone-modal-container">
//       <div>
//         <div className="set-phone-modal-head">What’s your mobile number?</div>
//         <div className="set-phone-modal-support">
//           Giving us your number will allow you to access your Aris Portrait even
//           if you lose your account.
//         </div>
//       </div>
//       <div className="set-phone-int-cnt">
//         <div className="set-phone-modal-input-container">
//           <div className="set-phone-input-label">Mobile number</div>
//           <div className="mobile-input-wrapper-1" >
//             <PhoneInput
//               international
//               defaultCountry="US"
//               value={newMobileNumber}
//               onChange={setNewMobileNumber}
//               className="mobile-input"
//             />
//           </div>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             marginBottom: "20px",
//             marginTop: "20px",
//           }}
//         >
//           <button
//             className="set-phone-continue-button"
//             onClick={onUpdateContact}
//             disabled={isLoading}
//             style={{
//               cursor: isLoading ? "not-allowed" : "pointer",
//               opacity: isLoading ? "0.6" : "1",
//             }}
//           >
//             {" "}
//             Continue <img src={arrowRight} alt="edit" />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default PhoneSettingsScreen2;



import React, { useState } from "react";
import arrowRight from "../../../Assets/images/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { sendUpdatedContact } from "../../../Services/settingsServices";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";

function PhoneSettingsScreen2({ onUpdateContactClick, currentPhone }) {
  const [newMobileNumber, setNewMobileNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const parsePhoneNumber = (phoneNumber) => {
    const parsed = parsePhoneNumberFromString(phoneNumber);
    if (!parsed) {
      throw new Error("Invalid phone number format");
    }
    return parsed;
  };

  const onUpdateContact = async () => {
    if (isLoading) return; // Prevent multiple clicks
    setIsLoading(true);
    setErrorMessage(""); // Reset previous errors

    try {
      const parsedPhoneNumber = parsePhoneNumber(newMobileNumber);
      const country_code = `+${parsedPhoneNumber.countryCallingCode}`;
      const contact = parsedPhoneNumber.nationalNumber;

      if (currentPhone === newMobileNumber) {
        setErrorMessage("Please enter a different number.");
        return;
      }

      const response = await sendUpdatedContact({
        country_code: country_code,
        contact: contact,
      });

      if (response.status === "success") {
        setSuccessMessage("Message sent successfully!");
        sessionStorage.setItem("newcountryCode", country_code);
        sessionStorage.setItem("newContact", contact);
        setTimeout(() => {
          onUpdateContactClick();
        }, 2000);
      } else {
        throw new Error(response || "Failed to update contact.");
      }
    } catch (error) {
      setErrorMessage(error.message || "An error occurred.");
      console.error("Error updating contact:", error);
    } finally {
      setIsLoading(false); // Reset button state
    }
  };

  return (
    <div className="set-phone-modal-container">
      <div>
        <div className="set-phone-modal-head">What’s your mobile number?</div>
        <div className="set-phone-modal-support">
          Giving us your number will allow you to access your Aris Portrait even
          if you lose your account.
        </div>
      </div>
      <div className="set-phone-int-cnt">
        <div className="set-phone-modal-input-container">
          <div className="set-phone-input-label">Mobile number</div>
          <div className="mobile-input-wrapper-1">
            <PhoneInput
              international
              defaultCountry="US"
              value={newMobileNumber}
              onChange={setNewMobileNumber}
              className="mobile-input"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button
            className="set-phone-continue-button"
            onClick={onUpdateContact}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? "0.6" : "1",
            }}
          >
            Continue <img src={arrowRight} alt="edit" />
          </button>
        </div>
        {errorMessage && (
          <div
            style={{
              color: "red",
              textAlign: "center",
            }}
          >
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default PhoneSettingsScreen2;

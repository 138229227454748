import React, { useState, useEffect } from 'react'
import "./style.css";
import ChatModal from '../../../Components/ChatModal/ChatModal';
import SendInvite from './modals/sendInvite/SendInvite';
import DateSchedule from './modals/dateSchedule/DateSchedule';
import TimeSchedule from './modals/timeSchedule/TimeSchedule';
import SessionCategory from './modals/session/SessionCategory';
import CalendarSchedule from './modals/calendarSchedule/CalendarSchedule';
import AddToCalendar from './modals/addToCalendar/AddToCalendar';
import AdditionalCoachingHours from './modals/coachingPlan/AdditionalCoachingHours';
import MobilePopUp from '../../../Components/MobilePopUp/MobilePopUp';
import toast, { Toaster } from 'react-hot-toast';
import { rescheduleSession, scheduleSession } from '../../../Services/coaching';

const SessionSelection = ({ onClose, showLiveSessionModal, setShowLiveSessionModal, isReschedule=false, sessionId=null, setShowCalendar}) => {
    const [selectedSession, setSelectedSession] = useState('private');
    const [page,setPage] = useState('choice');
    const [startTime, setStartTime] = useState(new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }).toLowerCase());
    const [endTime, setEndTime] = useState(new Date(Date.now() + 15 * 60000).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }).toLowerCase());    
    const [hoursCount, setHoursCount] = useState(0);
    const [showSendInviteModal, setShowSendInviteModal] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [error, setError] = useState('');
 
    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const handleSessionSchedule = async () => {
        try {
            const [time, period] = startTime.split(' ');
            const [hours, minutes] = time.split(':');
    
            let hour = parseInt(hours);
            if (period.toLowerCase() === 'pm' && hour !== 12) {
                hour += 12;
            } else if (period.toLowerCase() === 'am' && hour === 12) {
                hour = 0;
            }
    
            const year = selectedDate.getFullYear();
            const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
            const day = selectedDate.getDate().toString().padStart(2, '0');
            const dateStr = `${year}-${month}-${day}`;
    
            const formattedDateTime = `${dateStr}T${hour.toString().padStart(2, '0')}:${minutes}:00`;
    
            const payload = isReschedule ? {
                session_id: sessionId,
                scheduled_on: formattedDateTime,
                type: selectedSession
            } : {
                scheduled_on: formattedDateTime,
                type: selectedSession
            };
    
            const response = isReschedule ? await rescheduleSession(payload) : await scheduleSession(payload);
            
            if(response.message === "Network Error") {
                setError('Internal server error');
                return;
            }
            
            setShowLiveSessionModal(false);
            isReschedule && setShowCalendar(false);
            const successMessage = isReschedule ? 'Session rescheduled successfully' : 'Session scheduled successfully';
            toast.success(successMessage);
            
        } catch (error) {
            setError(isReschedule ? 'Failed to reschedule' : 'Failed to schedule');
            console.log(error);
        }
    }
    
  return (
    <>
        {page === 'choice' && (
            <>
                {screenWidth > 480 ? (
                    <ChatModal 
                        isOpen={showLiveSessionModal}
                        onClose={() => setShowLiveSessionModal(false)}
                        children={
                            <SessionCategory
                                onClose={onClose}
                                setPage={setPage}
                                selectedSession={selectedSession}
                                setSelectedSession={setSelectedSession}
                                isReschedule={isReschedule}
                            />
                        }
                        height={'fit-content'}
                        width={'476px'}
                    />
                ) : (
                    <MobilePopUp 
                        isPopupVisible={showLiveSessionModal}
                        onClose={() => setShowLiveSessionModal(false)}
                        children={
                            <SessionCategory
                                onClose={onClose}
                                setPage={setPage}
                                selectedSession={selectedSession}
                                setSelectedSession={setSelectedSession}
                                hideCloseIcon={true}
                                isReschedule={isReschedule}
                            />
                        }
                        hideClose={false}
                    />
                )}
            </>
        )}

        {page === 'date' && 
            <>
                {screenWidth > 480 ? (
                    <ChatModal
                        isOpen={showLiveSessionModal}
                        onClose={() => setShowLiveSessionModal(false)}
                        children={
                            <DateSchedule
                                onClose={onClose} 
                                setPage={setPage} 
                                selectedDate={selectedDate} 
                                setSelectedDate={setSelectedDate} 
                            />
                        }
                        height={'fit-content'}
                        width={'476px'}
                    />
                ) : (
                    <MobilePopUp
                        isPopupVisible={showLiveSessionModal}
                        onClose={() => setShowLiveSessionModal(false)}
                        children={
                            <DateSchedule
                                onClose={onClose} 
                                setPage={setPage} 
                                selectedDate={selectedDate} 
                                setSelectedDate={setSelectedDate} 
                            />
                        }
                        hideClose={true}
                    />
                )}
            </>
        }

        {page === 'time' && 
            <> 
                {screenWidth > 480 ? (
                    <ChatModal 
                        isOpen={showLiveSessionModal}
                        onClose={() => setShowLiveSessionModal(false)}
                        children={
                            <TimeSchedule
                                onClose={onClose}
                                setPage={setPage}
                                startTime={startTime}
                                setStartTime={setStartTime}
                                endTime={endTime}
                                setEndTime={setEndTime}
                                selectedDate={selectedDate}
                            />
                        }
                        height={'fit-content'}
                        width={'476px'}
                    />
                ) : (
                    <MobilePopUp
                        isPopupVisible={showLiveSessionModal}
                        onClose={() => setShowLiveSessionModal(false)}
                        children={
                            <TimeSchedule
                                onClose={onClose}
                                setPage={setPage}
                                startTime={startTime}
                                setStartTime={setStartTime}
                                endTime={endTime}
                                setEndTime={setEndTime}
                            />
                        }
                        hideClose={true}
                    />
                )}
            </>
        }

        {page === 'schedule' && 
            <>
                {screenWidth > 480 ? (
                        <ChatModal 
                            isOpen={showLiveSessionModal}
                            onClose={() => setShowLiveSessionModal(false)}
                            children={
                                <CalendarSchedule
                                    onClose={onClose}
                                    setPage={setPage}
                                    selectedDate={selectedDate}
                                    startTime={startTime}
                                    endTime={endTime}
                                    handleSessionSchedule={handleSessionSchedule}
                                    error={error}
                                />
                            }
                            height={'fit-content'}
                            width={'476px'}
                        />
                    ) : (
                        <MobilePopUp 
                            isPopupVisible={showLiveSessionModal}
                            onClose={() => setShowLiveSessionModal(false)}
                            children={
                                <CalendarSchedule
                                    onClose={onClose}
                                    setPage={setPage}
                                    selectedDate={selectedDate}
                                    startTime={startTime}
                                    endTime={endTime}
                                    hideCloseIcon={true}
                                    error={error}
                                />
                            }
                            hideClose={false}
                        />
                    )
                }
            </> 
        }

        {page === 'coachingPlan' &&
            <>
                {screenWidth > 480 ? 
                    (
                        <ChatModal 
                            isOpen={showLiveSessionModal}
                            onClose={() => setShowLiveSessionModal(false)}
                            children={
                                <AdditionalCoachingHours
                                    onClose={onClose}
                                    hoursCount={hoursCount}
                                    setHoursCount={setHoursCount}
                                />
                            }
                            height={'fit-content'}
                            width={'476px'}
                        />
                    ) : (
                        <MobilePopUp 
                            isPopupVisible={showLiveSessionModal}
                            onClose={() => setShowLiveSessionModal(false)}
                            children={
                                <AdditionalCoachingHours
                                    onClose={onClose}
                                    hoursCount={hoursCount}
                                    setHoursCount={setHoursCount}
                                    hideCloseIcon={true}
                                />
                            }
                            hideClose={false}
                        />
                    )
                }
            </>
        }
           
        {page === 'addToCalendar' && 
            <>
                {screenWidth > 480 ? (
                    <ChatModal 
                        isOpen={showLiveSessionModal}
                        onClose={() => setShowLiveSessionModal(false)}
                        children={
                            <AddToCalendar 
                                onClose={onClose} 
                                setPage={setPage} 
                                selectedSession={selectedSession}
                                // handleInvite={() => toast('')}
                                error={error}
                            />
                        }
                        height={'fit-content'}
                        width={'476px'}
                    />
                ) : (
                    <MobilePopUp 
                        isPopupVisible={showLiveSessionModal}
                        onClose={() => setShowLiveSessionModal(false)}
                        children={
                            <AddToCalendar 
                                onClose={onClose} 
                                setPage={setPage} 
                                hideCloseIcon={true} 
                                selectedSession={selectedSession} 
                                // handleInvite={() => toast('Your Invitation was sent')}
                                error={error}
                            />
                        }
                        hideClose={false}
                    />
                )}
            </>
        }

        {page === 'sendInvite' &&
            <>
                {screenWidth > 480 ? (
                    <ChatModal
                        isOpen={showLiveSessionModal}
                        onClose={() => setShowLiveSessionModal(false)}
                        children={
                            <SendInvite onClose={onClose} handleInvite={() => toast('Your Invitation was sent')} />
                        }
                        height={'602px'}
                        width={'812px'}
                    />
                ) : (
                    <MobilePopUp
                        isPopupVisible={showLiveSessionModal}
                        onClose={() => setShowLiveSessionModal(false)}
                        children={
                            <SendInvite onClose={onClose} hideCloseIcon={true} handleInvite={() => toast('Your Invitation was sent')}/>
                        }
                        hideClose={false}
                    />
                )}
            </>
        }
    </>
  )
}

export default SessionSelection
import React, { useState, useEffect } from "react";
import graph0 from "../../Assets/images/0GraphContainer.svg";
import "./style.css";

function Personality0GraphGlower({ duration }) {
  const [visibleDiv, setVisibleDiv] = useState(1); // Track the currently visible div
  const interval = duration / 4; // Split duration into 4 equal parts

  useEffect(() => {
    const timer = setInterval(() => {
      setVisibleDiv((prev) => (prev < 4 ? prev + 1 : 1)); // Cycle through IDs 1 to 4
    }, interval * 1000);

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [interval]);

  return (
    <div className="container">
      <div className="Personality0GraphGlower-hero-wrapper">
        <img src={graph0} alt="0graph" />

        {/* Background Divs */}
        {visibleDiv === 1 && (
          <div
            id={1}
            style={{
              width: 443.9,
              height: 425.6,
              transform: "rotate(-63.72deg)",
              transformOrigin: "0 0",
              opacity: 0.7,
              background: "#31C6AF",
              boxShadow: "120px 120px 120px",
              borderRadius: 9999,
              position: "fixed",
              top: "40%",
              // left: -140,
              filter: "blur(120px)",
            }}
          />
        )}

        {visibleDiv === 2 && (
          <div
            id={2}
            style={{
              width: 432.02,
              height: 414.2,
              transform: "rotate(45deg)",
              transformOrigin: "0 0",
              opacity: 0.7,
              background: "#31C6AF",
              boxShadow: "120px 120px 120px",
              borderRadius: 9999,
              filter: "blur(120px)",
              position: "fixed",
              top: "-22%",
              // right: -138,
            }}
          />
        )}

        {visibleDiv === 3 && (
          <div
            id={3}
            style={{
              width: 515.78,
              height: 494.51,
              transform: "rotate(45deg)",
              transformOrigin: "0 0",
              opacity: 0.7,
              background: "#31C6AF",
              boxShadow: "120px 120px 120px",
              borderRadius: 9999,
              filter: "blur(120px)",
              position: "fixed",
              bottom: "35%",
              // left: "10px",
            }}
          />
        )}

        {visibleDiv === 4 && (
          <div
            id={4}
            style={{
              width: 515.78,
              height: 494.51,
              transform: "rotate(-63.72deg)",
              transformOrigin: "0 0",
              opacity: 0.7,
              background: "#31C6AF",
              boxShadow: "120px 120px 120px",
              borderRadius: 9999,
              filter: "blur(120px)",
              position: "fixed",
              top: "73%",
              // right: 10,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Personality0GraphGlower;

import React from "react";
import "./style.css";
import Loader from "react-js-loader";

const ContentLoader = () => {
  return (
    <div className="loader-df-container">
      <Loader
        type="spinner-cub"
        bgColor={"#2D50A7"}
        color={"#3D6DEB"}
        // title={"spinner-cub"}
        size={45}
      />
    </div>
  );
};

export default ContentLoader;

import React, { useState, useEffect } from "react";
import "./style.css";
import { ReactComponent as Settings } from "../../Assets/icon/settings.svg";
import arrowLeft from "../../Assets/icon/arrow-narrow-left.svg";
import SettingsContainer from "../SettingsContainer/SettingsContainer";
import ContentLoader from "../../Pages/LoadingPage/ContentLoader";
import reminder_icon from "../../Assets/icon/notification_reminder.svg";
import portrait_icon from "../../Assets/icon/notification_portrait.svg";
import coach_icon from "../../Assets/icon/notification_coach.svg";
import { cancelSession, getSessions } from "../../Services/coaching";
import SessionSelection from "../../Pages/RelationShip/LiveSession/SessionSelection";
import toast from "react-hot-toast";

function Session({ session, handleCancelSession, handleReschedule, activeTab }) {

  const getNotificationIcon = (type) => {
    switch (type) {
      case "coach":
        return coach_icon;
      case "reminder":
        return reminder_icon;
      default:
        return portrait_icon;
    }
  };

  const getRelativeTime = (dateString) => {
    const date = new Date(dateString);
    const monthAndDay = date.toLocaleString('en-US', { 
      month: 'long',
      day: '2-digit'
    });
    const time = date.toLocaleString('en-US', { 
      hour: 'numeric',
      minute: '2-digit',
      hour12: true 
    }).toLowerCase();
    
    return `${monthAndDay}, ${time}`;
  };
  
  return (
    <div className="session-container-hero">
      <div className="session-container-top">
        <div className="session-left">
          <img
            src={getNotificationIcon("coach")}
            alt="icon"
          />
        </div>
        <div className="session-right">
          <div className="notify-cnt-head">
            {
              getRelativeTime(
                activeTab === "upcoming" ? session.scheduled_on : session.completed_at
              )
            }
          </div>
          <div className="notify-cnt-sub">{"Aris Session"}</div>
        </div>
      </div>
      {activeTab === "upcoming" &&
        <div className="session-container-bottom">
          <div 
            className="session-container-bottom-button-container"
            onClick={() => {
              handleReschedule(session._id);
            }}
          >
            Reschedule
          </div>
          <div 
            className="session-container-bottom-button-container cancel" 
            onClick={() => handleCancelSession(session._id)}
          >
            Cancel
          </div>
        </div>
      }
    </div>
  );
}

function ScheduleContainer({ setshowCalendar }) {
  const [showSettings, setShowSettings] = useState(false);
  const [past, setPast] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [activeTab, setActiveTab] = useState("upcoming");
//   const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [scheduledSessions, setScheduledSessions] = useState([]);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchSessions = async () => {
      try{
        const response = await getSessions();
        setScheduledSessions(response.payload.sessions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching sessions:", error);
        setLoading(false);
      }
    }

    fetchSessions();
  },[])

  const handleCancelSession = async (id) => {
    try {
      const response = await cancelSession(id);
      if(response.status_code === 200) {
        setScheduledSessions(scheduledSessions.filter((session) => session._id !== id));
        toast.success("Session cancelled successfully", {
          duration: 3000,
          position: 'top-right',
          style: {
            border: '1px solid #04D9B5',
            background: '#FFFFFF',
            color: '#000000',
            fontSize: '14px',
            fontWeight: 400,
            padding: '8px 12px',
            borderRadius: '8px'
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleReschedule = (id) => {
    setSelectedSessionId(id);
    setShowRescheduleModal(true);
  };

  const renderSessions = (data) => {
    const filteredSessions = data.filter(session => 
      (activeTab === "upcoming" && session.coaching_status === "scheduled") ||
      (activeTab === "past" && session.coaching_status === "completed")
    );
  
    if (!filteredSessions.length && !loading) {
      return (
        <div className="no-notifications">
          No sessions scheduled
        </div>
      );
    }
  
    return filteredSessions.map((session) => (
      <Session
        key={session._id} 
        session={session}
        handleCancelSession={handleCancelSession}
        handleReschedule={handleReschedule}
        activeTab={activeTab}
      />
    ));
  };

  const handleSettingsClick = () => {
    setShowSettings(true);
  };

  const handleBackClick = () => {
    setshowCalendar(false);
  };

  return (
    <>
      {showRescheduleModal && (
        <SessionSelection
          showLiveSessionModal={showRescheduleModal}
          setShowLiveSessionModal={setShowRescheduleModal}
          onClose={() => {
            setShowRescheduleModal(false);
            setSelectedSessionId(null);
          }}
          setShowCalendar={setshowCalendar}
          isReschedule={true}
          sessionId={selectedSessionId}
        />
      )}
      {!showSettings ? (
        <>
          <div></div>
          <div className="session-hero-container">
            <div className="session-head-notification">
              <div className="head-not-txt">Calendar</div>
              <div onClick={handleSettingsClick}>
                <Settings />
              </div>
            </div>
            <div className="not-tab-selection-container">
              <div
                className={`not-tab-container${
                  activeTab === "upcoming" ? "" : "-non-active"
                }`}
                onClick={() => setActiveTab("upcoming")}
              >
                Upcoming
              </div>
              <div
                className={`not-tab-container${
                  activeTab === "past" ? "" : "-non-active"
                }`}
                onClick={() => setActiveTab("past")}
              >
                Past
              </div>
            </div>
            <div className="session-cnt" onScroll={handleScroll}>
              {renderSessions(scheduledSessions)}
              {loading && <ContentLoader />}
            </div>
          </div>

          <div className="notify-mobile">
            <div id="background-in">
              <div id="oval-in-1"></div>
              <div id="oval-in-2"></div>
            </div>
            <header className="termsofuse-header">
              {/* <div className="termsofuse-gradient-background"> */}
              <div className="termsofuse-nav-container">
                <div className="termsofuse-logo-container-notification">
                  <img
                    src={arrowLeft}
                    alt="Back"
                    className="termsofuse-back-button"
                    onClick={handleBackClick}
                  />

                  <div className="cur-pt" onClick={handleSettingsClick}>
                    <Settings />
                  </div>
                </div>
                <h1 className="head-not-txt">Calendar</h1>
                <div className="termsofuse-space"></div>
                <div className="set-phone-modal-support mobile-sub-txt-set-phone"></div>
              </div>
              {/* </div> */}
            </header>
            <div className="not-phone">
              <div className="not-tab-selection-container">
                <div
                  className={`not-tab-container${
                    activeTab === "unread" ? "" : "-non-active"
                  }`}
                  onClick={() => setActiveTab("upcoming")}
                >
                  Upcoming
                </div>
                <div
                  className={`not-tab-container${
                    activeTab === "read" ? "" : "-non-active"
                  }`}
                  onClick={() => setActiveTab("past")}
                >
                  Past
                </div>
              </div>
              <div className="notify-cnt" onScroll={handleScroll}>
                {renderSessions(scheduledSessions)}
                {loading && (
                  <div className="loader-df-container">
                    <ContentLoader />
                  </div>
                )}{" "}
              </div>
            </div>
          </div>
        </>
      ) : (
        <SettingsContainer setShowSettings={setShowSettings} />
      )}
    </>
  );
}

export default ScheduleContainer;
import { axiosSubstanceInstance } from "../AxiosConfiguration";
import { axiosRelationshipInstance } from "../AxiosConfiguration";

export const getFaithPackages = async (faith) => {
  try {
    const response = await axiosSubstanceInstance.get(
      `/api/faiths/get-faiths`,
      {
        params: faith ? { faith } : {},
      }
    );
    return response.data; // Handle the response as needed
  } catch (error) {
    console.error("Error fetching faith packages:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const getProducts = async () => {
  try {
    const response = await axiosSubstanceInstance.get(
      `/api/subscription/get-products`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching faith packages:", error);
    throw error;
  }
};

export const getPricing = async (product_id) => {
  try {
    console.log(product_id, "t2");

    const response = await axiosSubstanceInstance.get(
      `/api/subscription/get-prices`,
      {
        params: { product_id: product_id },
      }
    );
    return response.data; // Handle the response as needed
  } catch (error) {
    console.error("Error fetching faith packages:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const startSubscription = async (data) => {
  try {
    // console.log(product_id, "t2");

    const response = await axiosSubstanceInstance.post(
      `/api/subscription/start`,
      data
    );
    return response.data; // Handle the response as needed
  } catch (error) {
    console.error("Error fetching faith packages:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const getUserSubscriptionDetails = async () => {
  try {
    // You might need to adjust the endpoint path
    const response = await axiosRelationshipInstance.get(
      `/api/user/subscriptions/get/details/subscription`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription details:", error);
    throw error;
  }
};

export const getcarryminutes = async (payload) => {
  console.log("API Call Payload:", payload); // Debugging check
  try {
    const response = await axiosRelationshipInstance.post(
      `/api/user/subscriptions/carry/minutes`,
      payload
    );
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error transferring minutes:", error);
    throw error;
  }
};


export const postreqminutes = async (payload) => {
  console.log("API Call Payload:", payload); // Debugging check
  try {
    const response = await axiosRelationshipInstance.post(
      `/api/user/subscriptions/request/minutes`,
      payload
    );
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error transferring minutes:", error);
    throw error;
  }
};


export const addlicensedusers = async(payload)=>{
  console.log("API Call Payload:", payload); // Debugging check
  try {
    const response = await axiosRelationshipInstance.post(
      `/api/user/subscriptions/create/licensed/users`,
      payload
    );
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error transferring minutes:", error);
    throw error;
  }
};


export const transferuser = async(payload)=>{
  console.log("API Call Payload:", payload); // Debugging check
  try {
    const response = await axiosRelationshipInstance.post(
      `/api/user/subscriptions/remove/licensed/users`,
      payload
    );
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error transferring minutes:", error);
    throw error;
  }
};


export const createAddon = async(payload)=>{
  console.log("API Call Payload:", payload); // Debugging check
  try {
    const response = await axiosRelationshipInstance.post(
      `/api/user/subscriptions/create/user/addons/subscription`,
      payload
    );
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error transferring minutes:", error);
    throw error;
  }
};


export const billingPortal = async(payload)=>{
  console.log("API Call Payload:", payload); // Debugging check
  try {
    const response = await axiosRelationshipInstance.get(
      `/api/user/subscriptions/create-portal-session`,
      payload
    );
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error transferring minutes:", error);
    throw error;
  }
};


import React, { useEffect, useState } from "react";
import "./styles.css";
import { ReactComponent as LeftArrow } from "../../Assets/icon/arrow-narrow-left.svg";
import { ReactComponent as LeftArrowDisabled } from "../../Assets/icon/arrow-narrow-left-disabled.svg";
import CircularProgressBar from "../CircularProgressBar";
import AssessmentCheckBox from "../InputCheckBox";
import AssessmentRadioButton from "../InputRadio";
import DragAndDropList from "../DnD";
import ContentLoader from "../../Pages/LoadingPage/ContentLoader";
import { useNavigate } from "react-router-dom";
import MostLeast from "../DnD/MostLeast";
import DNDMultiContainer from "../DNDMultiContainer";

function AssessmentContainer({
  id,
  question,
  description,
  options,
  type,
  onSubmit,
  onPrev,
  isFirstQuestion,
  progressValue,
  isSurvey,
  answerList,
  title,
  totalCategoryCount,
  currentCategoryCount,
  isQuestLoading,
  isErrorOnProp,
}) {
  const navigate = useNavigate();
  const [answer, setAnswer] = useState(answerList || []);
  const [clicked, setClicked] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (type === null || type === undefined || type === "") {
  //       navigate("/chat");
  //     }
  //   }, 5000);
  // }, []);

  //Based on the question type render the component of input
  const questionReturn = () => {
    switch (type) {
      case "MultipleChoice":
        return (
          <AssessmentRadioButton
            options={options}
            onSubmit={answerChange}
            id={id}
            answerId={answerList}
          />
        );
      case "SingleChoice":
        return (
          <AssessmentRadioButton
            options={options}
            onSubmit={answerChange}
            id={id}
            answerId={answerList}
          />
        );
      case "Rating":
        return (
          <DragAndDropList
            options={options}
            isMostLeast={false}
            onSubmit={answerChange}
            id={id}
            answerId={answerList}
          />
        );
      case "MostLeast":
        return (
          // <MostLeast

          // />
          <DNDMultiContainer
            options={options}
            isMostLeast={false}
            onSubmit={answerChange}
            id={id}
            answerId={answerList}
          />
        );
      default:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <ContentLoader />
          </div>
        );
    }
  };

  const answerChange = (e) => {
    console.log("answer change", e);
    setAnswer(e);
    if (
      (type === "MultipleChoice" || type === "SingleChoice") &&
      e.length > 0
    ) {
      onNextClickBypass(e);
    }
  };

  const onNextClickBypass = (response) => {
    if (
      Array.isArray(response) &&
      response.length > 0 &&
      response[0] !== "" &&
      !response.includes(undefined)
    ) {
      onSubmit(response);
    }
  };
  const onNextClick = () => {
    if (!clicked) {
      if (
        Array.isArray(answer) &&
        answer.length > 0 &&
        !answer.includes(undefined)
      ) {
        setClicked(true);
        onSubmit(answer);
        setTimeout(() => setClicked(false), 1000);
      }
    }
  };

  return (
    <div className="assess-container" key={id}>
      {options?.length === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ContentLoader />
        </div>
      ) : (
        <div className="assess-inner">
          <div className="assess-header-main">
            {!isFirstQuestion ? (
              <>
                {answerList && answerList?.length ? (
                  <LeftArrowDisabled className="hover-error" />
                ) : (
                  <LeftArrow className="hover-pointer" onClick={onPrev} />
                )}
              </>
            ) : (
              <div></div>
            )}

            <div
              className="hover-pointer"
              onClick={() => onSubmit(answer, true)}
            >
              Save & Exit
            </div>
          </div>
          <div className="assess-body">
            {title && (
              <div className="assessment-title">{`Exercise ${currentCategoryCount} of ${totalCategoryCount}`}</div>
            )}
            <div className="assess-question">{question}</div>
            {description && (
              <div className="assess-description">{description}</div>
            )}
            <div
              className={`assess-options-container ${
                type === "Rating" && "flx-row"
              }`}
            >
              {questionReturn()}
            </div>
          </div>
        </div>
      )}
      <div className="flex-center mt-5">
        {isSurvey && answer?.length === 0 && (
          <div className="error-msg">* Please Select One Or More Option </div>
        )}
        {isErrorOnProp ? (
          <div className="error-msg">Something went wrong!! </div>
        ) : (
          <div
            className={`hover-pointer  ${
              (clicked || isQuestLoading) && "not-allowed"
            }`}
            onClick={onNextClick}
          >
            <CircularProgressBar
              value={progressValue}
              disabled={
                (isSurvey && answer?.length === 0) || clicked || isQuestLoading
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AssessmentContainer;

import React from "react";
import "./style.css";
function UserChatComponent({ chat }) {
  let showText = "";
  if (
    typeof chat?.content === "object" &&
    chat?.content !== null &&
    !Array.isArray(chat?.content)
  ) {
    if ((chat.content.type = "initiateRelationship")) {
      showText = chat?.content?.text;
    }
  } else {
    showText = chat?.content;
  }

  console.log("🟩 content: ", chat?.content, typeof chat?.content);

  return (
    <div className="user-chat-container">
      {typeof chat?.content !== "string" ? (
        <div>
          <div>{chat?.content?.text}</div>
          <div className="chat-initiateRelationship-img-cntr">
            {chat?.content?.profile_pic_url ? (
              <img
                src={chat?.content?.profile_pic_url}
                alt={chat?.content?.name}
                className="chat-in-small-pic"
              />
            ) : (
              <div className="chat-contacts-initial-insider">
                {chat?.content?.name?.charAt(0)}
              </div>
            )}

            {chat?.content?.name}
          </div>
        </div>
      ) : (
        <div> {chat?.content}</div>
      )}
    </div>
  );
}

export default UserChatComponent;

import React from "react";

function Personality7() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 107"
      fill="none"
    >
      <g clip-path="url(#clip0_2161_30175)">
        <g filter="url(#filter0_f_2161_30175)">
          <path
            d="M56.6559 32.6162L59.3881 40.9734H68.2297L61.0767 46.1384L63.8089 54.4955L56.6559 49.3305L49.5028 54.4955L52.2351 46.1384L45.082 40.9734H53.9237L56.6559 32.6162Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter1_f_2161_30175)">
          <path
            d="M14.0608 17.291L15.7949 22.6202H21.4068L16.8667 25.9138L18.6009 31.243L14.0608 27.9494L9.52075 31.243L11.2549 25.9138L6.71484 22.6202H12.3267L14.0608 17.291Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <path
          d="M14.123 25.0601L100.372 25.4086L72.2625 83.0929L35.598 90.0639L14.123 25.0601Z"
          fill="#45D9C9"
        />
        <path
          d="M14.123 25.0601L100.372 25.4086M14.123 25.0601L35.598 90.0639M14.123 25.0601L72.2625 83.0929M100.372 25.4086L72.2625 83.0929M100.372 25.4086L35.598 90.0639M72.2625 83.0929L35.598 90.0639"
          stroke="white"
          stroke-opacity="0.7"
          stroke-width="0.211394"
          stroke-miterlimit="10"
        />
        <path
          d="M72.247 83.0879L100.362 25.3773L14.0078 25.0073L72.247 83.0879Z"
          fill="#4FE3D3"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30175"
          x="27.2498"
          y="14.7839"
          width="58.813"
          height="57.5439"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="8.91614"
            result="effect1_foregroundBlur_2161_30175"
          />
        </filter>
        <filter
          id="filter1_f_2161_30175"
          x="-0.970403"
          y="9.60577"
          width="30.0619"
          height="29.3226"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30175"
          />
        </filter>
        <clipPath id="clip0_2161_30175">
          <rect
            width="105.697"
            height="105.697"
            fill="white"
            transform="translate(0.693359 0.802734)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Personality7;

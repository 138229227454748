import axios from "axios";
import { axiosArisChatInstance } from "../AxiosConfiguration";

const ARIS_API_BASE_URL = "https://lab-api.aris.ai";

export const convertTextToSpeech = async (
  text,
  voice = "alloy",
  stream = false
) => {
  try {
    const response = await axios.get(`${ARIS_API_BASE_URL}/ARIS/tts`, {
      params: { text, voice, stream },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    console.error("Text-to-speech conversion failed:", error);
    throw error;
  }
};

export const convertVoicetoName = async (audioFile, verification = false) => {
  try {
    const formData = new FormData();
    console.log("A1");

    formData.append("file", audioFile);

    console.log("A2");
    const response = await axios.post(
      `${ARIS_API_BASE_URL}/ARIS/voice-to-name`,
      formData,
      {
        params: { verification },
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      }
    );
    console.log("A3");

    return response.data;
  } catch (error) {
    console.error("Voice-to-name conversion failed:", error);
    throw error;
  }
};

export const chatCreateRelationShip = async (params) => {
  try {
    const response = await axiosArisChatInstance.post(
      "ARIS/relationship/add",
      params
    );
    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
};

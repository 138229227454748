import React, { useState } from "react";
import "./style.css";
import ChatInput from "../../../Components/ChatInput";
import ChatMic from "../../../Components/ChatMic";
import ChatComponent from "../../../Components/ChatComponent/ChatComponent";
import RelationshipAdiveInsight from "./RelationshipAdiveInsight";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
function RelationShipChat({
  onEnterData,
  chatData,
  chatSessionId,
  onOptionSelected,
  hideChatInput = false,
  isAiLoading,
}) {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [isRecording, setisRecording] = useState(false);

  const startRecording = () => {
    if (!isRecording) {
      SpeechRecognition.startListening();
    } else {
      SpeechRecognition.stopListening();
      if (transcript !== "") {
        onEnterData(transcript);
      }

      resetTranscript();
    }
    setisRecording((prev) => !prev);
  };

  const afterOnPassData = () => {
    SpeechRecognition.stopListening();
    setisRecording(false);
    resetTranscript();
  };

  return (
    <div className="relationship-chat-hero-container">
      <div className="relationShip-chats">
        <ChatComponent
          chatArray={chatData}
          onOptionSelected={onOptionSelected}
          isAiLoading={isAiLoading}
        />
      </div>

      {!hideChatInput && (
        <div className="relationship-input-cntr added-margin ">
          <div className="rl-input">
            <ChatInput
              onChange={onEnterData}
              value={transcript}
              afterOnPassData={afterOnPassData}
            />
          </div>
          <div className="rl-mic" onClick={startRecording}>
            <ChatMic isRecording={isRecording} />
          </div>
        </div>
      )}
    </div>
  );
}

export default RelationShipChat;

import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./style.css";
import { useHeader } from "../../Providers/HeaderContextProvider";
import {ReactComponent as LeftArrow} from "../../Assets/images/arrow-left.svg";
import {ReactComponent as RightArrow} from "../../Assets/icon/rightarrow.svg";
import {ReactComponent as ArisLogo} from "../../Assets/images/aris.svg";
import {ReactComponent as List} from "../../Assets/icon/List.svg";
import {ReactComponent as Speaker} from "../../Assets/icon/SpeakerHigh.svg";
import Mic from "../../Assets/icon/coaching-mic.png";
import VoiceRecorder from "../../Assets/icon/voice-recorder.png";
import Stroke1 from "../../Assets/icon/Stroke1.svg";
import {Tooltip} from "react-tooltip";
import { isSafari, isMobile } from "react-device-detect";
import ChatModal from "../../Components/ChatModal/ChatModal";
import SummaryModal from "./modalComponent/summary/SummaryModal";
import MobilePopUp from "../../Components/MobilePopUp/MobilePopUp";
import ActionPlanModal from "./modalComponent/actionPlan/ActionPlanModal";
import History from "./history";
import { RealtimeClient } from "../../Components/realtime-api-beta/index";
import { WavRecorder, WavStreamPlayer } from "../../Components/wavtools/index";
import SmartHabit from "./modalComponent/smartHabit/SmartHabit";
import DefaultLoader from "../LoadingPage/DefaultLoader";
import { endSession, fetchInstructions, getChat, getOpenAIKey,portaitData,pastHistory, resumeCoaching } from "../../Services/coaching";
import CoachingScroll from "./CoachingScroll";
import Cookies from "js-cookie";

function ArisCoach() {
  
  const { setHeaderContent,profileContent } = useHeader();
  const VideoFile = isSafari
  ? "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/sphere-ios.mov"
  : process.env.REACT_APP_SPHERE_LOADER ||
    "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/Sphere_Loader.webm";

  useEffect(() => {
    setHeaderContent(false);
  }, []);

  const { type } = useParams();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [recordingMode, setRecordingMode] = useState(false);
  const [handsFreeMode, setHandsFreeMode] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showActionPlanModal, setShowActionPlanModal] = useState(false);
  const [showSmartHabitModal, setShowSmartHabitModal] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [completeConversationHistory, setCompleteConversationHistory] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const instructionsRef = useRef(null);
  const sessionIdRef = useRef(null);
  const voiceRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const apiKeyRef = useRef(null);
  const wavRecorderRef = useRef(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef(new WavStreamPlayer({ sampleRate: 24000 }));
  const clientRef = useRef(null);
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const inactivityTimeoutRef = useRef(null);
  const inactivityModalTimeoutRef = useRef(null);
  const [error, setError] = useState(null);
  const [disconnectText, setDisconnectText] = useState(null);
  const [showExitModal, setShowExitModal] = useState(false);
  const prevChatRef = useRef(null);
  const [arisDisconnected, setArisDisconnected] = useState(false);
  const [timer, setTimer] = useState(0);
  const [showTimeoutContainer, setShowTimeoutContainer] = useState(false);
  const [tag, setTag] = useState(null);
  const [endSessionData, setEndSessionData] = useState(null);
  const [audiosrc, setAudiosrc] = useState(null);
  const [isLeavingPage, setIsLeavingPage] = useState(false);
  const pauseRef = useRef(false);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);
  const textRef = useRef(null);
  const timerActiveRef = useRef(true);

  //user activity tracking
  const resetInactivityTimer = () => {
    if (arisDisconnected) {
      return;
    }
  
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    if (inactivityModalTimeoutRef.current) {
      clearTimeout(inactivityModalTimeoutRef.current);
    }
  
    inactivityTimeoutRef.current = setTimeout(() => {
      setShowInactivityModal(true);
      inactivityModalTimeoutRef.current = setTimeout(() => {
        setShowInactivityModal(false);
        disconnectConversation(true, false);
      }, 3* 60 * 1000);
      
    }, 5 * 60 * 1000);
  };
  
  const fetchResumeCoaching = async () => {
      try {
        const response = await resumeCoaching();
        if(response.payload){
          instructionsRef.current = response.payload.instruction;
          sessionIdRef.current = response.payload._id;
          const voice = response.payload.aris_voice;
          voice === "female" ? voiceRef.current = "shimmer" : voiceRef.current = "echo"
          setTimer(response.payload.minutes * 60);
          return response.payload;
        } else {
          setError(response.message);
          setLoading(false);
          return null;
        }
      } catch (error) {
        setError("Server is not responding. Please try again later.")
        setLoading(false);
        console.error("Error fetching resume coaching:", error);
      }
  };

  const handleInstructions = async () => {
    try {
      const response = await fetchInstructions(type);
      if(response.payload){
        instructionsRef.current = response.payload.instruction;
        sessionIdRef.current = response.payload.data._id;
        const voice = response.payload.aris_voice;
        voice === "female" ? voiceRef.current = "shimmer" : voiceRef.current = "echo"
        setTimer(response.payload.minutes * 60);
        return response.payload.data
      } else {
        setError(response.message);
        setLoading(false);
        return null;
      }
    } catch (error) {
      setError("Server is not responding. Please try again later.");
      setLoading(false);
      console.error("Error fetching instructions:", error.message);
    }
  }

  const handleUserActivity = () => {
    if(!arisDisconnected){
      resetInactivityTimer();
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("scroll", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    resetInactivityTimer();

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("scroll", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
    };
  }, []);

  useEffect(() => {
    const fetchChat = async (lastChat) => {
      handleUserActivity();
      const payload = {
        "session_id": sessionIdRef.current,
        "chat": [lastChat] // Send only the last chat object
      }
      try {
        await getChat(payload);
        prevChatRef.current = lastChat;
      } catch (error) {
        console.error("Error fetching chat:", error);
      }
    }

    if(sessionIdRef.current && completeConversationHistory.length > 0){
      const lastChat = completeConversationHistory[completeConversationHistory.length - 1]
      if(prevChatRef.current === null) {
        fetchChat(lastChat);
      } else {
        if(
          (JSON.stringify(prevChatRef.current) !== JSON.stringify(lastChat)) && 
          (lastChat.content !== '')
        ) {
          fetchChat(lastChat);
        }
      }
    }

  }, [sessionIdRef.current,completeConversationHistory])

  useEffect(() => {
    const fetchAndSetApiKey = async () => {
      try {
        const response = await getOpenAIKey();
        apiKeyRef.current = await decryptApiKey(response);
        if (apiKeyRef.current) {
          const response = type === 'resume' ? await fetchResumeCoaching() : await handleInstructions();
          if(response !== null){
            clientRef.current = new RealtimeClient({
              apiKey: apiKeyRef.current,
              dangerouslyAllowAPIKeyInBrowser: true,
              model:"gpt-4o-realtime-preview-2024-12-17",
              audio: {
                sampleRate: 24000
              }
            });
            connectConversation();
            setLoading(false);
          }
        }
      } catch (error) {
        console.error('Error fetching OpenAI key:', error);
      }
    };
    
    fetchAndSetApiKey();
  }, []);

  const beforeUnloadHandler = (e) => {
    if (!arisDisconnected) {
      e.preventDefault();
      setIsLeavingPage(true);
      e.returnValue = true;
    }
  };
  
  useEffect(() => {
    
    const unloadHandler = () => {
      if (!arisDisconnected) {
        const remainingMins = parseFloat((timer / 60).toFixed(1));
        const token = Cookies.get("idtoken");
        
        // Create URL with query parameters
        const url = new URL('https://5ckgu55hjegpqrfsy6hzh5wo2u0mtopt.lambda-url.us-east-1.on.aws/api/coaching/end_session');
        url.searchParams.append('session_id', sessionIdRef.current);
        url.searchParams.append('time', remainingMins);
        url.searchParams.append('status', false); // Using string 'false' for URL params
        
        fetch(url.toString(), {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          keepalive: true
        })
      }
    };
    
    window.addEventListener("beforeunload", beforeUnloadHandler);
    window.addEventListener("unload", unloadHandler);
  
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      window.removeEventListener("unload", unloadHandler);
    };
  }, [isLeavingPage]);

  useEffect(() => {
    if (completeConversationHistory.filter(message => message.role === 'assistant').slice(-1)[0]?.content && timer > 0 && timerActiveRef.current) {
      const interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            disconnectConversation(true, false);
            return 0;
          }
          if (prevTimer === 30) {
            setShowTimeoutContainer(true);
          }
          return prevTimer - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }

  }, [completeConversationHistory, timer]);

  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    await setupTools(client);
    setItems(client.conversation.getItems());
    await wavRecorder.begin();
    await wavStreamPlayer.connect();
    try{
      const response = await client.connect();
      if(response){
        setLoading(false);

        client.sendUserMessageContent([
          {
            type: `input_text`,
            text: `Hello!`,
          },
        ]);
        if (client.getTurnDetectionType() === 'server_vad') {
          await wavRecorder.record((data) => client.appendInputAudio(data.mono));
        }
      }
    } catch (error) {
      console.error('Error connecting to the conversation:', error);
      setError('Error connecting to Aris. Please try again later.');
    }
  }, []);

  const setupTools = async (client) => {
    // Remove existing tool if it exists
    try {
      const existingTools = client.getTools?.() || [];
      if (existingTools.some(tool => tool.name === 'get_user_prism')) {
        await client.removeTool('get_user_prism');
      }
    } catch (toolError) {
      console.warn('Error checking/removing existing tool:', toolError);
    }
  
    // Add the user prism tool
    await client.addTool({
      type: "function",
      name: 'get_user_prism',
      description: 'Use this function whenever the user mentions a name or discusses a topic related to someone...',
      parameters: {
        type: 'object',
        properties: {
          name: {
            type: 'array',
            items: {
              type: "string"
            },
            description: 'list of user IDs of users mentioned in the conversation... ',
          },
          query: {
            type: 'string',
            description: 'The specific topic or query about the person...',
          },
        },
        required: ['name', 'query'],
      },
    }, handleUserPrismRequest);
  
    await client.addTool({
      type: "function",
      name: "get_past_history",
      description: "Retrieve information about past incidents or discussions related to a specific context provided by the user currently or right now. This function is particularly useful for recalling previously discussed topics or tracking user-related history. It is mandotory to check if the current one is disscued previoulsy before answering it ",
      parameters: {
        type: "object",
        properties: {
          user: {
            type: "string",
            description: "The name of the user. Provide the name as a single string.",
          },
          query: {
            type: "string",
            description: "A string describing the specific context or topic that the user is inquiring about or discussing.",
          }
        },
        required: ["user", "query"]
      }
    }, handlePastHistoryRequest);
  };
  
  const handleUserPrismRequest = async ({ query }) => {
    try {
      const response = await portaitData(profileContent._id,query);
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      return await response.json();
    } catch (fetchError) {
      throw new Error('Failed to process user prism request');
    }
  };
  
  const handlePastHistoryRequest = async ({  query }) => {
    try {
      const response = await pastHistory(profileContent._id,query);
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      return await response.json();
    } catch (fetchError) {
      throw new Error('Failed to process user prism request');
    }
  };

  const changeTurnEndType = async (value) => {
    const client = clientRef.current;
    if (!client) return;
    
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  };

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;
    if (!client) return;
    const instruction = instructionsRef.current;

    client.updateSession({ instructions: instruction });
    client.updateSession({ input_audio_transcription: { model: "whisper-1" } });
    client.updateSession({ voice: voiceRef.current});
    
    client.on('error', (event) => {
      console.error('Client error:', event);
      if (event.type === 'server_error' || event.type === 'connection_error') {
        setError('Connection to Aris was interrupted. Please try again later.');
      }
    });

    client.on('conversation.interrupted', async () => {
      // audioRef.current.pause();
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      textRef.current = "";
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
        pauseRef.current = true;
      }
    });

    client.on("conversation.updated", async ({ item, delta }) => {

      console.log("items", delta);

      if (item.role === "assistant" && delta?.audio) {
        console.log("inside delta audio");
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        textRef.current = item.formatted.transcript;
      } else if(delta?.text) {
        textRef.current = item.formatted.transcript
      }

      // if(item.role === "assistant" && delta?.transcript) {
      //   textRef.current += delta.transcript;
      // }

      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
        if(item.role === 'assistant') {
          setAudiosrc(wavFile.url);
        }
      }

      if(item.status === "completed" && item.formatted.audio?.length) {
        const history = client.conversation.getItems()
        .filter(item => 
          item.type === 'message' && 
          item.status === 'completed' &&
          (item.role !== 'user' || (item.formatted.transcript || item.formatted.text))
        )
        .map(item => ({
          role: item.role,
          content: item.formatted.transcript || item.formatted.text,
        }));

        if (history.length > 0) {
          setCompleteConversationHistory(history);
        }
      }
    });
    
    return () => {
      client.reset();
    };
  }, [apiKeyRef.current]);
  
  const disconnectConversation = useCallback(async (leave=false, status) => {
    timerActiveRef.current = false;
    setLoading(true);
    const remainingMins = parseFloat((timer / 60).toFixed(1));
    if(error === null) {
      const client = clientRef.current;
      const wavRecorder = wavRecorderRef.current;
      try{
        await client.disconnect();
        await wavRecorder.end();
        setArisDisconnected(true);
        const response = await endSession(sessionIdRef.current, status, remainingMins);
        setTag(response.payload.tag);
        setEndSessionData(response.payload);
        setLoading(false);
        setDisconnectText("Thank you, I will summarize this meeting and save it to your history");
        if(handsFreeMode) {
          setHandsFreeMode(false);
        }
        if(leave) {
          navigate('/chat');
        }
      } catch (error) {
        setLoading(false);
        setError("Error while ending the session. you're current session will be in progress");
        console.error("Error disconnecting conversation:", error);
      }
    } else {
      setLoading(false);
      navigate('/chat');
    }
  }, [error, timer]);
 
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
 
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleListClick = () => {
    setSidebarOpen(true);
  };
  
  const handleMicStart = async () => {
    setIsRecording(true);
    setRecordingMode(true);

    const client = clientRef.current;
    await wavRecorderRef.current.record((data) => {
      client.appendInputAudio(data.mono);
    });
  };
  
  const handleMicStop = async () => {
    setIsRecording(false);
    setRecordingMode(false);
    await wavRecorderRef.current.pause();
    clientRef.current.createResponse();
  };

  useEffect(() => {
    if(handsFreeMode) {
      changeTurnEndType('server_vad');
    } else {
      changeTurnEndType('none');
    }
  }, [handsFreeMode]);

  function shiftDecrypt(text, shiftKey = 16) {
    return text.split('').map(char => {
      if (/[a-zA-Z0-9]/.test(char)) {
        if (/[a-zA-Z]/.test(char)) {
          let base = char === char.toUpperCase() ? 'A'.charCodeAt(0) : 'a'.charCodeAt(0);
          let num = (char.charCodeAt(0) - base - shiftKey) % 26;
          if (num < 0) num += 26;
          return String.fromCharCode(base + num);
        } else {
          let num = (parseInt(char) - shiftKey) % 10;
          if (num < 0) num += 10;
          return num.toString();
        }
      }
      return char;
    }).join('');
  }
   
  function decryptApiKey(encryptedKey, shiftKey = 16) {
    try {
      let encryptedText = atob(encryptedKey);
      let decryptedSaltedKey = shiftDecrypt(encryptedText, shiftKey);
      return decryptedSaltedKey.slice(16); // Remove first 16 characters
    } catch (error) {
      console.error("Failed to decrypt API key:", error);
      return null;
    }
  }

  const handleLeave = () => {
    if (arisDisconnected) {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      navigate('/chat');
    } else {
      setShowExitModal(false);
      disconnectConversation(true, false);
    }
  };

  return (
    <div className="coach-banner">
      {showSummaryModal && !isMobile && (
        <ChatModal
          isOpen={showSummaryModal}
          onClose={() => setShowSummaryModal(false)}
          children={<SummaryModal onClose={() => setShowSummaryModal(false)} summaryData={endSessionData} />}
          height={'fit-content'}
          width={'476px'}
        />
      )}
      {showSummaryModal && isMobile && (
        <MobilePopUp
          isPopupVisible={showSummaryModal}
          onClose={() => setShowSummaryModal(false)}
          children={<SummaryModal hideClose={true} onClose={() => setShowSummaryModal(false)} summaryData={endSessionData} />}
          hideClose={false}
        />
      )}
      {showActionPlanModal && !isMobile && (
        <ChatModal
          isOpen={showActionPlanModal}
          onClose={() => setShowActionPlanModal(false)}
          children={<ActionPlanModal onClose={() => setShowActionPlanModal(false)} summaryData={endSessionData} />}
          height={'fit-content'}
          width={'476px'}
        />
      )}
      {showActionPlanModal && isMobile && (
        <MobilePopUp
          isPopupVisible={showActionPlanModal}
          onClose={() => setShowActionPlanModal(false)}
          children={<ActionPlanModal hideClose={true} isMobile={isMobile} onClose={() => setShowActionPlanModal(false)} summaryData={endSessionData} />}
          hideClose={false}
        />
      )}
      {(showSmartHabitModal && !isMobile) && (
        <ChatModal
          isOpen={showSmartHabitModal}
          onClose={() => setShowSmartHabitModal(false)}
          children={<SmartHabit onClose={() => setShowSmartHabitModal(false)} summaryData={endSessionData} />}
          height={'fit-content'}
          width={'476px'}
        />
      )}
      {showActionPlanModal && isMobile && (
        <MobilePopUp
          isPopupVisible={showSmartHabitModal}
          onClose={() => setShowSmartHabitModal(false)}
          children={<SmartHabit hideClose={true} isMobile={isMobile} onClose={() => setShowSmartHabitModal(false)} summaryData={endSessionData} />}
          hideClose={false}
        />
      )}
      {sidebarOpen && screenWidth > 480 && (
        <>
          <div className="sidebar-overlay" onClick={() => setSidebarOpen(false)} />
          <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
            <History 
              onClose={() => setSidebarOpen(false)} 
              sessionID={sessionIdRef.current} 
              client={clientRef.current}
              wavRecorder={wavRecorderRef.current}
            />
          </div>
        </>
      )}
      {sidebarOpen && screenWidth <= 480 && (
        <MobilePopUp
          isPopupVisible={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          children={
            <History 
              onClose={() => setSidebarOpen(false)} 
              sessionID={sessionIdRef.current} 
              client={clientRef.current}
              wavRecorder={wavRecorderRef.current}
            />
          }
          hideClose={true}
        />
      )}
      {showInactivityModal && (
        <div className="inactivity-modal">
          <div className="inactivity-modal-content">
            <h2>Seems like you are away</h2>
            <p>Would you like to continue?</p>
            <div className="inactivity-modal-buttons">
              <button onClick={() => setShowInactivityModal(false)}>Continue</button>
              <button 
                onClick={() => {
                  if(!arisDisconnected) {
                    disconnectConversation(false, true);
                  } else {
                    navigate('/chat');
                  }
                }}
              >
                End Session
              </button>
            </div>
          </div>
        </div>
      )}
      {showExitModal && (
        <ChatModal
          onClose={() => setShowExitModal(false)}
          width={"300px"}
          height={"auto"}
          padding={"0px"}
          isOpen={showExitModal}
          children={
            <div>
              <div className="logout-modal-container">
                <div className="logout-modal-header text-center">Exit coaching</div>
                <div className="logout-modal-body text-center">Are you sure you want to leave Aris?</div>
              </div>
              <div className="logout-modal-button-container">
                <div
                  className="logout-cancel"
                  onClick={() => setShowExitModal(false)}
                >
                  Cancel
                </div>
                <div
                  className="logout-cancel logout-btn"
                  onClick={() => handleLeave()}
                >
                  Leave
                </div>
              </div>
            </div>
          }
        />
      )}
      {loading ? <DefaultLoader /> : (
        <>
          <div className="coaching-header">
            <div className="coaching-back-button" onClick={() => setShowExitModal(true)}>
              <LeftArrow />
            </div>
            <div className="coaching-aris-logo">
              <ArisLogo />
            </div>
            <div className="coaching-options-button" onClick={handleListClick}>
              <List />
            </div>
          </div>
          <div className="coaching-main-container">
            <div className="coaching-main-container-top">
              {showTimeoutContainer && (
                <div className="coaching-timeout-container">
                  <div className="coaching-timeout-text">
                    <p className="coaching-timeleft">
                      {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}
                    </p>
                    <p>Your time limit is running out Add more time now</p>
                  </div>
                  <div className="coaching-timeout-icon">
                    <RightArrow />
                  </div>
                </div>
              )}
            </div>
            <div className="coaching-video-container">
              <div className="coaching-video-container-below">
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  webkit-playsinline="true"
                  className="relationship-gif"
                  src={VideoFile}
                  onClick={() => {
                    if(!arisDisconnected){
                      disconnectConversation(false, true);
                    }
                  }}
                />
                <div className="coaching-video-container-background" />
              </div>
            </div>
            <div className="coaching-main-container-text">
              {/* {(audiosrc && !arisDisconnected) && <audio ref={audioRef} src={audiosrc} autoPlay muted={isMuted} />} */}
              <div className="coaching-llm-text-container">
                {error ? <p className="coaching-error-message">{error}</p> 
                : disconnectText ? <p className="coaching-error-message">{disconnectText}</p> 
                : 
                  <CoachingScroll
                    audioSrc={audiosrc}
                    text={
                      textRef.current
                      // completeConversationHistory.filter(message => message.role === 'assistant').slice(-1)[0]?.content
                    }
                    textRef={textRef}
                  />
                }
              </div>
              {/* {completeConversationHistory.length > 0 && 
                <div className="coaching-llm-text-container">
                  {error ? <p className="coaching-error-message">{error}</p> 
                  : disconnectText ? <p className="coaching-error-message">{disconnectText}</p> 
                  : 
                    <CoachingScroll
                      audioSrc={audiosrc}
                      text={
                        textRef.current
                        // completeConversationHistory.filter(message => message.role === 'assistant').slice(-1)[0]?.content
                      }
                      textRef={textRef}
                    />
                  }
                </div>
              } */}
              {disconnectText &&
                <div 
                  className={`coaching-tag-button-container ${(showExitModal || sidebarOpen) && 'exit'}`}
                  onClick={() => {
                    if(tag === "action_item") {
                      setShowActionPlanModal(true)
                    } else if(tag === "smart_habit") {
                      setShowSmartHabitModal(true)
                    } else {
                      setShowSummaryModal(true)
                    }
                  }}
                >
                  {tag === "action_item" ? "See my action plan" : tag === "smart_habit" ? "See my smart habit" : "See the summary"}
                </div>
              }
            </div>
            <div className={`coaching-speech-controls-container ${recordingMode && 'recording'}`}>
              <div className="coaching-speech-controls">
                {(recordingMode || handsFreeMode) && <div className="coaching-stroke-container">
                  <img src={Stroke1} alt="Stroke Layer 1" className="coaching-stroke-image1" draggable='false' />
                  <img src={Stroke1} alt="Stroke Layer 2" className="coaching-stroke-image2" draggable='false' />
                  <img src={Stroke1} alt="Stroke Layer 3" className="coaching-stroke-image3" draggable='false' />
                  <img src={Stroke1} alt="Stroke Layer 4" className="coaching-stroke-image4" draggable='false' />
                </div>}
                  <div className="coaching-speech-controls-top">
                    <div className="coaching-speaker-container" onClick={() => setIsMuted(!isMuted)}>
                      <Speaker className={isMuted ? 'muted' : ''} />
                    </div>
                    <div className="coaching-mic-container">
                      <div className={`coaching-mic ${recordingMode ? 'recording' : ''}`}
                        {...(!handsFreeMode && {
                          onMouseDown: handleMicStart,
                          onMouseUp: handleMicStop,
                          onTouchStart: handleMicStart, 
                          onTouchEnd: handleMicStop
                        })}
                      >
                        <img
                          src={handsFreeMode ? VoiceRecorder : Mic} 
                          alt="Mic" 
                          width={32}
                          height={32}
                          draggable={false}
                        />
                      </div>
                    </div>
                    <div className="coaching-hands-free-container" id='tooltip-anchor'
                      onClick={() => {
                        setHandsFreeMode(!handsFreeMode);
                        setRecordingMode(false);
                      }}
                    >
                      <img src={handsFreeMode ? Mic : VoiceRecorder} alt="Mic" width={24} height={24} />
                    </div>
                  </div>
                  <div className="coaching-speech-controls-bottom">
                    <div className={`speak-text ${recordingMode && 'recording'} ${handsFreeMode && 'handsmode'}`}>Press & hold to speak</div>
                    {handsFreeMode && <div className="speak-text">Speak naturally</div>}
                  </div>
              </div>
              <div className="coaching-speech-controls-background" />
            </div>
          </div>
          <Tooltip
            anchorSelect="#tooltip-anchor"
            place={screenWidth <= 488 ? 'top-end' : 'top'}
            border={'2px solid #D9D9D9'}
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: '8px',
              color: '#000000',
              zIndex: 999999,
              display: !handsFreeMode ? 'block' : 'none',
            }}
          >
            <p style={{textAlign: 'center', fontWeight: 600, fontSize: '16px'}}>Hands-free mode</p>
            <p style={{textAlign: 'center', fontWeight: 400, fontSize: '12px', lineHeight: '14.4px'}}>Say “Hey Aris” to talk naturally </p>
            <p style={{textAlign: 'center', fontWeight: 400, fontSize: '12px'}}>without holding the mic button.</p>
          </Tooltip>
        </>
      )}
    </div>
  );
}

export default ArisCoach;
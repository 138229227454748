import React from "react";

function Personality4() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 106"
      fill="none"
    >
      <g clip-path="url(#clip0_2161_30154)">
        <path
          d="M8.28711 19.7718L96.1349 11.9048L58.8795 71.885L16.1541 94.5079L8.28711 19.7718Z"
          fill="#31C5AB"
        />
        <path
          d="M8.28711 19.7718L96.1349 11.9048M8.28711 19.7718L16.1541 94.5079M8.28711 19.7718L58.8795 71.885M96.1349 11.9048L58.8795 71.885M96.1349 11.9048L16.1541 94.5079M58.8795 71.885L16.1541 94.5079"
          stroke="white"
          stroke-opacity="0.7"
          stroke-width="0.211394"
          stroke-miterlimit="10"
        />
        <g filter="url(#filter0_f_2161_30154)">
          <path
            d="M47.8988 52.9531L50.9309 62.2275H60.743L52.8049 67.9594L55.837 77.2338L47.8988 71.5019L39.9607 77.2338L42.9928 67.9594L35.0547 62.2275H44.8667L47.8988 52.9531Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter1_f_2161_30154)">
          <path
            d="M14.1854 15.9553L16.1099 21.8694H22.3377L17.2993 25.5245L19.2238 31.4386L14.1854 27.7835L9.14708 31.4386L11.0716 25.5245L6.03321 21.8694H12.261L14.1854 15.9553Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter2_f_2161_30154)">
          <path
            d="M65.9628 45.5635L67.8872 51.4776H74.115L69.0766 55.1327L71.0011 61.0468L65.9628 57.3917L60.9244 61.0468L62.8489 55.1327L57.8105 51.4776H64.0383L65.9628 45.5635Z"
            fill="white"
          />
        </g>
        <path
          d="M96.1364 11.9048L15.5 94.5079L58.9005 71.8601L96.1364 11.9048Z"
          fill="#3BCFB5"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30154"
          x="17.2224"
          y="35.1209"
          width="61.352"
          height="59.9453"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="8.91614"
            result="effect1_foregroundBlur_2161_30154"
          />
        </filter>
        <filter
          id="filter1_f_2161_30154"
          x="-1.65204"
          y="8.26983"
          width="31.6752"
          height="30.8539"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30154"
          />
        </filter>
        <filter
          id="filter2_f_2161_30154"
          x="50.1253"
          y="37.8782"
          width="31.6752"
          height="30.8539"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30154"
          />
        </filter>
        <clipPath id="clip0_2161_30154">
          <rect
            width="105.697"
            height="105.697"
            fill="white"
            transform="translate(0.779297 0.105469)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Personality4;

export function reorderObject(obj) {
  const order = [
    "AttainmentOfGoals",
    "RecognitionForEffort",
    "Power",
    "Compliance",
    "Activity",
    "Affiliation",
  ];

  const reordered = {};
  order.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      reordered[key] = obj[key];
    }
  });

  return reordered;
}

export function convertKeysToNormalString(obj) {
  return Object.entries(obj).map(([key, value]) => {
    let convertedValue = key
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space before uppercase letters
      .replace(/^./, (char) => char.toUpperCase()); // Capitalize the first letter

    // If the convertedValue is "Power", rename it to "Influence"
    if (convertedValue === "Power") {
      convertedValue = "Influence";
    }

    return {
      name: key,
      convertedValue: convertedValue,
      value: value,
    };
  });
}

export function transformData(inputArray) {
  const result = [];
  const staticColors = ["#80CEED", "#82E3CC"];

  // Split the array into odd-index and even-index groups
  for (let i = 0; i < inputArray.length; i += 2) {
    const left = inputArray[i];
    const right = inputArray[i + 1];

    result.push({
      leftPercent: left?.value || 0,
      rightPercent: right?.value || 0,
      leftColor: staticColors,
      rightColor: staticColors,
      leftLabel: left?.convertedValue || "",
      rightLabel: right?.convertedValue || "",
    });
  }

  return result;
}

export function extractPercentages(input) {
  const regex = /\((\d+)%\)/g; // Matches numbers inside parentheses followed by a %
  const percentages = [];
  let match;

  while ((match = regex.exec(input)) !== null) {
    percentages.push(Number(match[1])); // Extract the number and convert to a number
  }

  return percentages && percentages.length !== 0 ? percentages : [0, 0]; // Returns an array of percentages
}

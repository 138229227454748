import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ReactComponent as CloseIcon } from "../../../../../Assets/icon/close.svg";
import { ReactComponent as PlusIcon } from "../../../../../Assets/icon/plus-gray.svg";
import { ReactComponent as CheckIcon } from "../../../../../Assets/icon/Check-white.svg";
import searchIcon from "../../../../../Assets/images/searchIcon.png"
import { getRelationships } from "../../../../../Services/Realtionship"
import "./style.css"

const SendInvite = ({onClose, hideCloseIcon, handleInvite}) => {

  const [activeFilter, setActiveFilter] = useState('all');
  const [gridLoading, setGridLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const isFetching = useRef(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [added, setAdded] = useState(false);

  const fetchRelationships = useCallback(async () => {
    if (isFetching.current) return;
    isFetching.current = true;

    setGridLoading(true);
    try {
      const relationshipsData = await getRelationships(
        activeFilter === "all" ? "" : activeFilter
      );
      console.log("Fetched relationships:", relationshipsData);
      setContacts(relationshipsData);
    } catch (error) {
      console.error("Failed to fetch relationships:", error);
      setContacts([]);
    } finally {
      isFetching.current = false;
      setGridLoading(false);
    }
  }, [activeFilter]);

  useEffect(() => {
    fetchRelationships();
  },[fetchRelationships]);

  const handleToggleContact = (email) => {
    setSelectedContacts((prevContacts) =>
      prevContacts.includes(email)
        ? prevContacts.filter((contactEmail) => contactEmail !== email)
        : [...prevContacts, email]
    );
  };

  const getInitials = (firstName, lastName) => {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
  };

  return (
    <div className='coaching-send-invite-container'>
      <div className="coaching-send-invite-header">
        <p>Send invitation to your session partner</p>
        {!hideCloseIcon && <CloseIcon style={{cursor:'pointer'}} onClick={onClose}/>}
      </div>
      <div className="coaching-send-invite-search-bar">
        <span><img src={searchIcon} alt='search' /></span>
        <input
          type="text" 
          placeholder='Search by name' 
        />
      </div>
      <div className="coaching-send-invite-filter-chips-container">
        {['all', 'dating', 'marriage', 'family', 'friends', 'workplace','roommates', 'other'].map(filter => ( // 
          <div key={filter} className={`chip-item ${activeFilter === filter && 'active'}`} onClick={() => setActiveFilter(filter)}>
            {filter.charAt(0).toUpperCase() + filter.slice(1)}
          </div>
        ))}
      </div>
      <div className="coaching-send-invite-profiles-container">
        {contacts.length > 0 ? (
          contacts.map((contact) => (
            <div key={contact.email} className="coaching-send-invite-profile-item">
              <div className="coaching-send-invite-profile-item-left">
                <div className="coaching-send-invite-profile-item-image">
                  {contact.profile_pic_url ? (
                    <img src={contact.profile_pic_url} alt='profile' />
                  ) : (
                    <div className="initials">
                      {getInitials(contact.first_name, contact.last_name)}
                    </div>
                  )}
                </div>
                <div className="coaching-send-invite-profile-item-details">
                  <p className='coaching-send-invite-profile-item-name'>{contact.first_name + " " + contact.last_name}</p>
                  <p className='coaching-send-invite-profile-item-relationship'>{contact.related_data.relationship_category}</p>
                </div>
              </div>
              <div className="coaching-send-invite-profile-item-right">
                <div 
                  className={`coaching-send-invite-add-button-conatiner ${selectedContacts.includes(contact.email) && 'added'}`} 
                  onClick={() => handleToggleContact(contact.email)}
                >
                  {selectedContacts.includes(contact.email) ? (
                    <>
                      Added
                      <CheckIcon />
                    </>
                  ) : (
                    <>
                      Add
                      <PlusIcon />
                    </>
                  )}
                </div>
              </div>
            </div>
          ))) : (<></>)
        }
      </div>
      <div className="coaching-send-invite-footer">
        <div className="coaching-send-invite-footer-button-conatiner">Skip</div>
        <div 
          className="coaching-send-invite-footer-button-conatiner" 
          onClick={() => {
            onClose();
            handleInvite();
          }}
        >
          Invite
        </div>
      </div>
    </div>
  )
}

export default SendInvite
import React from "react";

function WellBeingSVG({
  color = "white",
  width = "1.6rem",
  height = "1.6rem",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19073 6.52994C8.19073 6.59994 8.19073 6.65994 8.19073 6.72994C8.14073 6.67994 8.09073 6.63994 8.04073 6.59994C7.31073 5.99994 6.47073 5.56994 5.60073 5.32994C5.56073 4.32994 5.76073 3.33994 6.15073 2.43994C7.09073 2.69994 7.99073 3.16994 8.74073 3.83994C8.38073 4.65994 8.18073 5.57994 8.18073 6.53994L8.19073 6.52994Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8192 6.52996C12.8192 8.55996 11.9192 10.38 10.4992 11.6C9.12922 10.42 8.24922 8.67996 8.19922 6.72996C8.19922 6.65996 8.19922 6.59996 8.19922 6.52996C8.19922 5.56996 8.39922 4.64996 8.75922 3.82996C9.15922 2.90996 9.75922 2.09996 10.5092 1.45996C11.9292 2.66996 12.8292 4.49996 12.8292 6.52996H12.8192Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5002 11.5998C8.66016 11.9198 6.69016 11.4598 5.12016 10.1698C3.55016 8.88981 2.71016 7.04981 2.66016 5.16981C3.63016 4.99981 4.65016 5.04981 5.61016 5.31981C6.48016 5.55981 7.32016 5.98981 8.05016 6.58981C8.10016 6.62981 8.15016 6.66981 8.20016 6.71981C8.25016 8.66981 9.13016 10.3998 10.5002 11.5898V11.5998Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8098 6.52994C12.8098 6.59994 12.8098 6.65994 12.8098 6.72994C12.8598 6.67994 12.9098 6.63994 12.9598 6.59994C13.6898 5.99994 14.5298 5.56994 15.3998 5.32994C15.4398 4.32994 15.2398 3.33994 14.8498 2.43994C13.9098 2.69994 13.0098 3.16994 12.2598 3.83994C12.6198 4.65994 12.8198 5.57994 12.8198 6.53994L12.8098 6.52994Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 11.5998C12.34 11.9198 14.31 11.4598 15.88 10.1698C17.45 8.88981 18.29 7.04981 18.34 5.16981C17.37 4.99981 16.35 5.04981 15.39 5.31981C14.52 5.55981 13.68 5.98981 12.95 6.58981C12.9 6.62981 12.85 6.66981 12.8 6.71981C12.75 8.66981 11.87 10.3998 10.5 11.5898V11.5998Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0413 18.8498L15.9612 14.9198C15.1612 14.1698 14.1113 13.7598 13.0213 13.7598H6.61125C6.02125 13.7598 5.53125 14.2398 5.53125 14.8398C5.53125 15.4298 6.01125 15.9198 6.61125 15.9198H10.2412"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6708 20.56L12.8008 18.69H7.62078C6.72078 18.69 5.86078 18.33 5.22078 17.7L1.49078 13.97C1.07078 13.55 1.07078 12.88 1.49078 12.46H1.50078C1.92078 12.03 2.59078 12.03 3.01078 12.45L5.40078 14.84"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default WellBeingSVG;

import React, { useState } from "react";
import "./style.css";
import { useEffect } from "react";
function ChatInput({ onChange, value, afterOnPassData }) {
  const [chatInput, setchatInput] = useState(value);

  useEffect(() => {
    setchatInput(value);
  }, [value]);

  const handleOnChange = (e) => {
    setchatInput(e.target.value);
  };

  // Function to be called when Enter is pressed
  const handleEnterPress = async (event) => {
    if (event.key === "Enter") {
      await onChange(chatInput);
      setchatInput("");
      afterOnPassData();
    }
  };

  console.log("🟩 value: ", value);

  return (
    <input
      type="text"
      placeholder="Start typing..."
      onChange={handleOnChange}
      value={value || chatInput}
      onKeyDown={handleEnterPress}
      className="chat-input"
    />
  );
}

export default ChatInput;

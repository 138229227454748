import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { ChevronDown, ChevronUp } from "lucide-react";
import { ReactComponent as Plus } from "../../Assets/icon/plus.svg";
import { ReactComponent as Minus } from "../../Assets/icon/minus.svg";
import Allset from "../AllsetComponent";
import "./style.css";
import { createAddon, getcarryminutes } from "../../Services/subscription";
import { ElderlyWoman } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

const AddCoachingMinutes = ({
  contactDetails,
  onClose,
  productId,
  priceDetails,
  minutes,
  user_type,
  onTransferMinutesComplete
}) => {

  const [searchParams] = useSearchParams();
  const ispurchased = searchParams.get("ispurchased");
  const [activeSection, setActiveSection] = useState("transfer");
  const [selectedPlan, setSelectedPlan] = useState({});
  const [memberCount, setMemberCount] = useState(0);
  const [isAllsetOpen, setIsAllsetOpen] = useState(false);
  const [showMainModal, setShowMainModal] = useState(true);
  const [error, seterror] = useState("");

  // Reset states when component unmounts or when flow completes
  const resetStates = () => {
    setActiveSection("transfer");
    setSelectedPlan("60");
    setMemberCount(0);
    setShowMainModal(true);
    setIsAllsetOpen(false);
  };

  const toggleSection = (e, section) => {
    e.stopPropagation();
    setActiveSection(activeSection === section ? null : section);
  };

  const incrementMember = (e) => {
    e.stopPropagation();
    setMemberCount((prev) => prev + 1);
  };

  const decrementMember = (e) => {
    e.stopPropagation();
    if (memberCount > 0) {
      setMemberCount((prev) => prev - 1);
    }
  };

  const handlePlanChange = (price) => {
    // e.stopPropagation();
    setSelectedPlan(price);
    console.log(price, "proceee");
  };

  const handleAddon = () => {
    let storer = {contactDetails:contactDetails.first_name+" "+contactDetails.last_name, productvalue:selectedPlan?.metadata?.product_value}
    localStorage.setItem("addCoachingMinsSuccessMode",JSON.stringify(storer))
    let temp = {
      subscription_items: [
        { price_id: selectedPlan?.id, product_id: productId },
      ],
      licensed_users: [
        {
          user_id: contactDetails?.user_id,
          addon_features: [
            {
              price_id: selectedPlan?.id,
              product_id: productId,
              value: selectedPlan?.metadata?.product_value,
            },
          ],
        },
      ],
      addon_features_user: [],
      self: "false",
      payment_method: "card",
    };
  
    createAddon(temp)
      .then((res) => {
        console.log(res);
        window.location.href = res?.payload?.checkout_url;
      })
      .catch((error) => {
        console.error("Purchase failed:", error);
        seterror(error?.response?.data?.message);  // Show error message like in transfer
      });
  };
  

  const handleButtonClick = () => {
    console.log("contactDetails Data:", contactDetails); // Debugging check

    if (activeSection === "transfer") {

      let transfermins = {contactDetails:contactDetails.first_name+" "+contactDetails.last_name, minutes:minutes}
      
      const payload = {
        to_user_id: contactDetails?.user_id, // Ensure contactDetails ID is being retrieved
        minutes: memberCount,
      };

      console.log("Sending Payload:", payload); // Debugging check

      getcarryminutes(payload)
        .then((res) => {
          localStorage.setItem("transfermins",JSON.stringify(transfermins))
      localStorage.setItem("successMode","transfer")
          console.log("Transfer successful:", res);
          setShowMainModal(false);
          setIsAllsetOpen(true);
          onTransferMinutesComplete()
        })
        .catch((error) => {
          console.error("Transfer failed:", error);
          seterror(error?.response?.data?.message);
        });
    } else {
      handleAddon();
    }
  };

  // Modified close handler for Allset component
  const handleAllsetClose = () => {
    setIsAllsetOpen(false);
    resetStates();
    onClose(); // Close the entire modal and return to parent
  };

  // const plans = [
  //   {
  //     minutes: "60",
  //     price: "44",
  //     sessions: "4 to 12 coaching sessions",
  //   },
  //   {
  //     minutes: "120",
  //     price: "84",
  //     sessions: "8 to 24 coaching sessions",
  //   },
  //   {
  //     minutes: "480",
  //     price: "144",
  //     sessions: "16 to 48 coaching sessions",
  //   },
  // ];

  const getButtonText = () => {
    switch (activeSection) {
      case "transfer":
        return "Transfer now";
      case "purchase":
        return "Purchase now";
      default:
        return "Purchase now";
    }
  };

  useEffect(() => {
    setTimeout(() => {
      seterror("");
    }, 3000);
  }, [error]);

  // Reset states when component unmounts
  useEffect(() => {
    return () => {
      resetStates();
    };
  }, []);

  console.log(ispurchased,"ispurchased", ispurchased === "confirm");
  console.log(selectedPlan, "selectedPlan");

  return (
    <>
      {showMainModal && (
        <div className="membershipmodal-overlay">
          <div className="membershipmodal-container">
            <div className="membershipmodal-header membership-coaching-minutes-header-container">
              <div className="membership-coaching-minutes-header">
                Add Coaching Minutes
              </div>
              <button className="membershipclose-btn" onClick={onClose}>
                <CloseIcon />
              </button>
            </div>

            <div className="membership-coaching-minutes-content">
              {/* Transfer Minutes Section */}
              <div
                className={`membership-coaching-minutes-section ${
                  activeSection === "transfer" ? "active" : ""
                }`}
              >
                <div className="membership-coaching-minutes-toggle">
                  <button
                    className="membership-toggle-button"
                    onClick={(e) => toggleSection(e, "transfer")}
                  >
                    <div className="membership-coaching-minutes-toggle-header">
                      <span>Transfer your minutes</span>
                      <div style={{ cursor: "pointer" }}>
                        {activeSection === "transfer" ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        )}
                      </div>
                    </div>
                  </button>

                  {activeSection === "transfer" && (
                    <div
                      className="membership-coaching-minutes-transfer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <p className="membership-coaching-minutes-transfer-txt">
                        You have{" "}
                        <span className="membership-coaching-minutes-transfer-minutes-txt">
                          {minutes} minutes
                        </span>{" "}
                        left in your account
                      </p>
                      <div className="membership-coaching-minutes-transfer-input">
                        <div className="membership-coaching-minutes-custom-hours">
                          <button
                            className="membership-coaching-minutes-custom-button"
                            onClick={decrementMember}
                          >
                            <Minus size={20} />
                          </button>
                          <span>{memberCount}</span>
                          <button
                            className="membership-coaching-minutes-custom-button"
                            onClick={incrementMember}
                          >
                            <Plus size={20} />
                          </button>
                        </div>
                        <span className="membership-choose-minutes-txt">
                          Choose how many minutes you wish to transfer
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Purchase Minutes Section */}
              <div
                className={`membership-coaching-minutes-section ${
                  activeSection === "purchase" ? "active" : ""
                }`}
              >
                <div className="membership-coaching-minutes-toggle">
                  <button
                    className="membership-toggle-button"
                    onClick={(e) => toggleSection(e, "purchase")}
                  >
                    <div className="membership-coaching-minutes-toggle-header">
                      <span>Purchase additional minutes</span>
                      <div
                        onClick={(e) => toggleSection(e, "purchase")}
                        style={{ cursor: "pointer" }}
                      >
                        {activeSection === "purchase" ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        )}
                      </div>
                    </div>

                    {activeSection === "purchase" && (
                      <div
                        className="membership-coaching-minutes-purchase"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {priceDetails.map((price) => (
                          <label
                            key={price.minutes}
                            className="membership-coaching-minutes-plan"
                          >
                            <input
                              type="radio"
                              name={price?.metadata?.product_value}
                              value={price?.id}
                              checked={selectedPlan?.id === price?.id}
                              onChange={() => handlePlanChange(price)}
                            />
                            <span className="membership-coaching-minutes-plan-text">
                              {price?.metadata?.product_value} minutes for $
                              {price?.metadata?.price_value / 100} (
                              {price?.metadata?.min_value} to{" "}
                              {price?.metadata?.max_value} coaching sessions)
                            </span>
                          </label>
                        ))}
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>

            {error && (
              <div className="error-message" style={{ paddingBottom: "6px" }}>
                {error}
              </div>
            )}
            <button
              className="membership-coaching-minutes-purchase-button"
              onClick={handleButtonClick}
            >
              {getButtonText()}
            </button>
          </div>
        </div>
      )}

      {/* {isAllsetOpen && (
        <Allset
        heading={
          ispurchased === "confirm"
            ? activeSection === "transfer"
              ? "Minutes sent!"
              : "You’re all set."
            : "Oops!"
        }
        
          closeModal={handleAllsetClose}
          subText={
            ispurchased === "confirm"
              ? `${priceDetails?.metadata?.product_value} minutes were added to ${contactDetails?.first_name} ${contactDetails?.last_name} account`
              : "Something went wrong!"
          }
          isFailure={ispurchased === "confirm" ? false : true}
        />
      )} */}
    </>
  );
};

export default AddCoachingMinutes;

import React, { useState } from "react";
import right from "../../../Assets/images/chevron-right.svg";

function PhoneSettingsScreenAddNewPhoneInitial() {
  const [removeNumber, setremoveNumber] = useState(false);
  return (
    <div className="set-phone-modal-container">
      <div>
        <div className="set-phone-modal-head">Phone Number</div>
        <div className="set-phone-modal-support">
          Your number is used to recover your account and receive SMS texts.
        </div>
      </div>
      <div className="set-phone-int-cnt">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <div
            className="set-accordian-menu  wider"
            // key={_menu?.id}
            // onClick={() => onSettingClick(_menu?.id)}
          >
            <div className="set-left-section">
              <div className="set-name">Set phone number</div>
            </div>
            <div className="set-right-menu">
              <img src={right} alt="right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhoneSettingsScreenAddNewPhoneInitial;

import React from "react";

function RelationShipAdviceSVG({
  color = "black",
  width = "1.4rem",
  height = "1.4rem",
}) {
  return (
    <div>
      {" "}
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Layer 1">
          <g id="Group">
            <path
              id="Vector"
              d="M5.55651 6.46702C7.05688 6.46702 8.27318 5.25073 8.27318 3.75036C8.27318 2.24998 7.05688 1.03369 5.55651 1.03369C4.05614 1.03369 2.83984 2.24998 2.83984 3.75036C2.83984 5.25073 4.05614 6.46702 5.55651 6.46702Z"
              //   fill={color}
              stroke={color}
              strokeWidth="0.833333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M12.4393 6.46702C13.9397 6.46702 15.156 5.25073 15.156 3.75036C15.156 2.24998 13.9397 1.03369 12.4393 1.03369C10.9389 1.03369 9.72266 2.24998 9.72266 3.75036C9.72266 5.25073 10.9389 6.46702 12.4393 6.46702Z"
              //   fill={color}
              stroke={color}
              strokeWidth="0.833333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M10.5904 6.86685C11.357 6.51685 12.2404 6.37518 13.157 6.52518C15.357 6.86685 16.907 8.90018 16.907 11.1252V11.1502C16.907 11.9668 16.2487 12.6252 15.432 12.6252H14.3654V14.9752L12.0154 12.6252H2.55703C1.74036 12.6252 1.08203 11.9668 1.08203 11.1502C1.08203 8.74185 2.88203 6.60851 5.29036 6.47518C6.0487 6.43351 6.76536 6.57518 7.40703 6.86685"
              //   fill={color}
              stroke={color}
              strokeWidth="0.833333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_4"
              d="M10.79 8.34189C11.1566 8.70856 11.1566 9.30023 10.79 9.65856L8.99831 11.4502L7.20664 9.65856C6.83997 9.29189 6.83997 8.70023 7.20664 8.34189C7.57331 7.97523 8.16497 7.97523 8.52331 8.34189L8.98997 8.80856L9.45664 8.34189C9.82331 7.97523 10.415 7.97523 10.7733 8.34189H10.79Z"
              //   fill={color}
              stroke={color}
              strokeWidth="0.833333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default RelationShipAdviceSVG;

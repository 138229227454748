import React from 'react'
import { ReactComponent as CloseIcon } from "../../../../Assets/icon/close.svg";
import './style.css'

const SmartHabit = ({onClose, hideClose=false, summaryData}) => {
  return (
    <>
        {summaryData ? (
            <div className='summary-modal-container'>
                <div className="smart-habit-modal-header">
                    <p className="summary-modal-header-title">{`SMART Habit: ${summaryData?.tittle}`}</p>
                    {!hideClose && <CloseIcon style={{cursor:'pointer'}} onClick={onClose}/>}
                </div>
                {summaryData?.actions.map((item, index) => (
                    <div className="actionplan-selection-item" style={{cursor: 'default'}} key={index}>
                        <input type="checkbox" name="" id="" />
                        <p className='smart-habbit-input-text'>{item}</p>
                    </div>
                ))}
                <div className="summary-modal-body">
                    <p className="summary-modal-body-title">Timeframe</p>
                    <li>{summaryData?.time_frame}</li>
                </div>
            </div>
        ) : (
            <div className="no-data-found">No data found</div>
        )}
    </>
  )
}

export default SmartHabit
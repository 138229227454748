import React from "react";

function Personality14() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 106"
      fill="none"
    >
      <path
        d="M18.6883 12.04L68.8633 34.0519L95.9849 89.251L12.5859 90.2669L18.6883 12.04Z"
        fill="#45D9BF"
      />
      <path
        d="M18.6883 12.04L68.8633 34.0519M18.6883 12.04L12.5859 90.2669M18.6883 12.04L95.9849 89.251M68.8633 34.0519L95.9849 89.251M68.8633 34.0519L12.5859 90.2669M95.9849 89.251L12.5859 90.2669"
        stroke="white"
        stroke-opacity="0.7"
        stroke-width="0.211394"
        stroke-miterlimit="10"
      />
      <path
        d="M12.6953 90.1595L95.771 89.2566L18.4358 11.9326L12.6953 90.1595Z"
        fill="#3BCFB5"
      />
      <g filter="url(#filter0_f_2161_30222)">
        <path
          d="M19.6945 79.062L21.3726 84.2318H26.803L22.4097 87.4269L24.0878 92.5967L19.6945 89.4016L15.3012 92.5967L16.9793 87.4269L12.5859 84.2318H18.0164L19.6945 79.062Z"
          fill="white"
        />
      </g>
      <g opacity="0.6" filter="url(#filter1_f_2161_30222)">
        <path
          d="M63.1961 28.4185L65.8844 36.6797H74.5837L67.5458 41.7854L70.234 50.0466L63.1961 44.9409L56.1582 50.0466L58.8465 41.7854L51.8086 36.6797H60.5079L63.1961 28.4185Z"
          fill="white"
        />
      </g>
      <g opacity="0.4" filter="url(#filter2_f_2161_30222)">
        <path
          d="M39.4156 44.7974L44.0705 59.0789H59.134L46.9474 67.9053L51.6022 82.1868L39.4156 73.3604L27.229 82.1868L31.8839 67.9053L19.6973 59.0789H34.7607L39.4156 44.7974Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30222"
          x="4.90069"
          y="71.3768"
          width="29.5873"
          height="28.9052"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30222"
          />
        </filter>
        <filter
          id="filter1_f_2161_30222"
          x="44.1233"
          y="20.7332"
          width="38.1459"
          height="36.9984"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30222"
          />
        </filter>
        <filter
          id="filter2_f_2161_30222"
          x="6.09297"
          y="31.1931"
          width="66.6461"
          height="64.5982"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6.80215"
            result="effect1_foregroundBlur_2161_30222"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Personality14;

// import React, { useState } from 'react';
// import ForgetPasswordLeftSection from '../../components/ForgetPasswordLeftSection';
// import InputField from '../../components/InputField';
// import './style.css';
// import { Link, useNavigate } from 'react-router-dom';
// import { sendResetPasswordLink } from '../../service/auth'; // Import the API function

// const ForgetPassword = () => {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState(''); // State for email input
//   const [message, setMessage] = useState(''); // Success message state
//   const [error, setError] = useState(''); // Error state for validation
//   const [apiError, setApiError] = useState(''); // API error handling

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//     setError(''); // Reset error on input change
//     setApiError(''); // Reset API error if any
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault(); // Prevent form reload

//     if (!email) {
//       setError('Email is required.'); // Validation error if email is empty
//       return;
//     }

//     try {
//       const response = await sendResetPasswordLink(email); // Call the API
//       if (response) {
//         setMessage('Reset password link sent! Please check your email.');
//         navigate('/login'); // Navigate to login on success
//       }
//     } catch (err) {
//       setApiError('Failed to send reset password link. Please try again.'); // Handle API error
//     }
//   };

//   return (
//     <div className="forget-password-container">
//       {/* Left Section */}
//       <ForgetPasswordLeftSection />

//       {/* Right Section */}
//       <div className="forgetpassword-right-section">
//         <div className="forgetpassword-right-section-content">
//           <div>
//             <h1>Forget Password</h1>
//             <InputField
//               label="Email"
//               type="email"
//               placeholder="Enter your email"
//               value={email}
//               onChange={handleEmailChange}
//               className="forgetpassword-input"
//             />
//             <small>Must be a valid email address.</small>
//           </div>

//           {/* Display validation and API error messages */}
//           {error && <p className="error-message">{error}</p>}
//           {apiError && <p className="error-message">{apiError}</p>}
//           {message && <p className="success-message">{message}</p>}

//           <div>
//             <button
//               type="submit"
//               className="forgetpassword-login-button"
//               onClick={handleSubmit}
//               disabled={!email} // Disable button if email is empty
//             >
//               Send reset password link
//             </button>
//             <div style={{ display: 'flex', justifyContent: 'center' }}>
//               <Link to="/signup" className="login-page-create-account">
//                 Create Account?
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ForgetPassword;




import React, { useEffect, useState } from 'react';
import ForgetPasswordLeftSection from '../../Components/ForgetPasswordLeftSection';
import InputField from '../../Components/InputField';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import { sendResetPasswordLink } from '../../Services/auth'; // Import the API function
import { ReactComponent  as CloseIcon } from '../../Assets/icon/close.svg';
import { useHeader } from '../../Providers/HeaderContextProvider';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(''); // State for email input
  const [message, setMessage] = useState(''); // Success message state
  const [error, setError] = useState(''); // Validation error state
  const [apiError, setApiError] = useState(''); // API error state
  const { setHeaderContent } = useHeader();

  useEffect(() => {
    setHeaderContent(false);
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(''); // Reset validation error on input change
    setApiError(''); // Reset API error if any
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form reload
  
    if (!email) {
      setError('Email is required.'); // Show error if email is empty
      return;
    }
  
    try {
      const response = await sendResetPasswordLink(email); // Call the API
      if (response) {
        setMessage('Reset password link sent! Please check your email.');
        setTimeout(() => {
          navigate('/login'); // Navigate to login after 2 seconds
        }, 2000); // 2 seconds delay
      }
    } catch (err) {
      setApiError('Failed to send reset password link. Please try again.'); // Handle API error
    }
  };
  

  return (
    <div className="forget-password-container">
      {/* Left Section */}
      <ForgetPasswordLeftSection />

      {/* Right Section */}
      <div className="forgetpassword-right-section">
        <div className="forgetpassword-right-section-content">
          <div>
             <CloseIcon className='forgetpassword-close-icon'  style={{
                border: '1px solid var(--gray-light-hover, #E4E4E7)', 
                borderRadius: '50%', 
                padding: '5px', 
                boxSizing: 'border-box'
              }} onClick={() => navigate('/login')} />
          <h2 className='forget-passwordh2'>Forgot Password</h2>

          <div>

            <InputField
              label="Email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              className="forgetpassword-input"
              autoFocus={true}
            />
            <small>Must be a valid email address.</small>
          </div>
          {error && <p className="error-message">{error}</p>}
          {apiError && <p className="error-message">{apiError}</p>}
          {message && <p className="success-message">{message}</p>}
          </div>

          {/* Display validation or API error messages */}
        
         <div>
       


          <button
            type="submit"
            className="forgetpassword-login-button"
            onClick={handleSubmit}
             // Disable button if email is empty
          >
            Send reset password link
          </button>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Link to="/signup" className="login-page-create-account">
              Create account
            </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;

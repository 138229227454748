import React from "react";
import arisChatIcon from "../../Assets/images/arisChatIcon.png";
import ReactMarkdown from "react-markdown";
import removeMarkdown from "remove-markdown";
import { isSafari } from "react-device-detect";

function AiChatComponent({ chat, isSurvey, onOptionSelected, isAiLoading }) {
  const VideoFile = isSafari
    ? "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/sphere-ios.mov"
    : process.env.REACT_APP_SPHERE_LOADER ||
      "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/Sphere_Loader.webm";

  const onOptionSelectedFunction = (item) => {
    onOptionSelected(item);
    // console.log("Option selected:", item);
  };

  const markdownToString = (markdown) => {
    return removeMarkdown(markdown).trim();
  };

  console.log(isAiLoading, chat, "isAiLoading");
  return isSurvey ? (
    <>
      <div className="ai-chat-hero-container">
        <video
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          className="aris-chat-icon"
          src={VideoFile}
        />
        {/* <img src={arisChatIcon} alt="arisChatIcon" className="aris-chat-icon" /> */}
        {/* {loading ?<>} */}
        <div className="ai-chat-container">
          {chat?.content === "" ? (
            <div className="loader-chat-wait"></div>
          ) : (
            <ReactMarkdown
              components={{
                h1: "h3",
                h2: "h4",
              }}
            >
              {chat?.content?.desc?.replace(/\n/g, "\n\n") || ""}
            </ReactMarkdown>
            // <>{chat?.content}</>
          )}
        </div>
      </div>

      <div className="ai-chat-multi-option-container">
        {chat?.content?.options?.map((item, index) => (
          <div
            onClick={() =>
              onOptionSelectedFunction({
                ...item,
                question_id: chat?.question_id,
              })
            }
            className={`ai-chat-multi-option-label ${
              item?.type === "link" ? "ai-chat-multi-option-label-link" : ""
            }`}
            key={index}
          >
            {item?.label}
          </div>
        ))}
      </div>

      {(chat?.message_type === "cta" ||
        chat?.message_type === "actionable") && (
        <div className="ai-chat-multi-option-container ai-chat-option">
          <div
            onClick={() =>
              onOptionSelectedFunction({
                label: markdownToString(chat?.action_text),
                question_id: chat?.question_id,
                type: "CTA",
                startAssessment: chat?.startAssessment,
                nextCategoryId: chat?.nextCategoryId,
                userStory: chat?.userStory,
                load_chat: chat?.load_chat,
              })
            }
            className={`ai-chat-multi-option-label ai-chat-option ${
              true ? "ai-chat-multi-option-label-link" : ""
            }`}
          >
            <ReactMarkdown
              components={{
                h1: "h3",
                h2: "h4",
              }}
            >
              {chat?.action_text}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </>
  ) : (
    <>
      <div className="ai-chat-hero-container">
        <video
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          className="aris-chat-icon"
          src={VideoFile}
        />
        {/* <img src={arisChatIcon} alt="arisChatIcon" className="aris-chat-icon" /> */}
        {/* {loading ?<>} */}
        <div className="ai-chat-container">
          {chat?.content === "" ? (
            <div className="loader-chat-wait"></div>
          ) : (
            <ReactMarkdown
              components={{
                h1: "h3",
                h2: "h4",
              }}
            >
              {chat?.content?.replace(/\n/g, "\n\n") || ""}
            </ReactMarkdown>
            // <>{chat?.content}</>
          )}
        </div>
      </div>
      {chat?.options?.length > 0 && (
        <div className="ai-chat-multi-option-container">
          {chat?.options?.map((item, index) => (
            <div
              onClick={() =>
                onOptionSelectedFunction({
                  type: "relationshipAdvice",
                  text: item,
                })
              }
              className={`ai-chat-multi-option-label ${
                item?.type === "link" ? "ai-chat-multi-option-label-link" : ""
              }`}
              key={index}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default AiChatComponent;

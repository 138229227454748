import React, { useState } from "react";
import "./style.css";
import PotraitsSummaryWithList from "../../Components/PotraitsSummaryWithList/PotraitsSummaryWithList";

import {
  convertKeysToNormalString,
  reorderObject,
  transformData,
} from "../../Utils/CommonFunctions/graph";
import PotraitsCommonHeadBottom from "./PotraitsCommonHeadBottom";
import TopStrength from "./TopStrength";
import GradientGraph from "../../Components/PotraitsGraph/motivationGraph";

function Motivation({ chartData, otherData }) {
  const [motivationType, setMotivationType] = useState(1);
  console.log(chartData?.why, "klklkl");
  const convertedDataToMap = convertKeysToNormalString(
    motivationType === 1 ? reorderObject(chartData?.why) : chartData?.how
  );

  console.log(convertedDataToMap, "convertedDataToMap");
  const data2 = transformData(convertedDataToMap);

  return (
    <div className="personality-hero-container">
      <div className="potraits-tab-header-txt">Motivation</div>
      <div className="potraits-graph-summary-cntr">
        {/* <div className="potraits-graph-tab">
          <div
            className={
              motivationType === 1 ? " active-graph-tab" : "in-active-graph-tab"
            }
            onClick={() => setMotivationType(1)}
          >
            <div className="Label">Why</div>
          </div>
          <div
            className={
              motivationType === 2 ? " active-graph-tab" : "in-active-graph-tab"
            }
            onClick={() => setMotivationType(2)}
          >
            <div className="Label">How</div>
          </div>
        </div> */}
        <GradientGraph data={data2} />
        <div className="potraits-separater-line"></div>
        <div className="potraits-graph-summary-txt-cntr">
          <div className="potraits-content-head-txt">Summary</div>
          <div className="potraits-sub-txt align-text-left">
            {otherData?.summary}
          </div>
        </div>
      </div>
      {/* <div className="potraits-percent-summary-hero-cntr">
        <PotraitsSummaryWithList />
        <PotraitsSummaryWithList />
      </div> */}
      <div className="personality-other-data-container">
        <PotraitsCommonHeadBottom
          heading={otherData?.portraitMatrixMap?.AttainmentGoalsRecognitionEffort?.title?.replace(
            /\n/g,
            " - "
          )}
          summary={
            otherData?.portraitMatrixMap?.AttainmentGoalsRecognitionEffort
              ?.bandCopy
          }
        />

        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.AttainmentGoalsRecognitionEffort
              ?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.AttainmentGoalsRecognitionEffort
              ?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.AttainmentGoalsRecognitionEffort
              ?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.AttainmentGoalsRecognitionEffort
              ?.topStrengthsToolTip
          }
          isBlind={true}
        />
      </div>
      <div className="personality-other-data-container">
        <PotraitsCommonHeadBottom
          heading={otherData?.portraitMatrixMap?.ActivityAffiliation?.title?.replace(
            /\n/g,
            " - "
          )}
          summary={otherData?.portraitMatrixMap?.ActivityAffiliation?.bandCopy}
        />

        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.ActivityAffiliation?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.ActivityAffiliation?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.ActivityAffiliation
              ?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.ActivityAffiliation?.topStrengthsToolTip
          }
          isBlind={true}
        />
      </div>
      <div className="personality-other-data-container">
        <PotraitsCommonHeadBottom
          heading={otherData?.portraitMatrixMap?.PowerCompliance?.title?.replace(
            /\n/g,
            " - "
          )}
          summary={otherData?.portraitMatrixMap?.PowerCompliance?.bandCopy}
        />

        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.PowerCompliance?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.PowerCompliance?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.PowerCompliance?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.PowerCompliance?.topStrengthsToolTip
          }
          isBlind={true}
        />
      </div>
    </div>
  );
}

export default Motivation;

import React, { useRef, useEffect, useState, memo } from "react";
import * as d3 from "d3";

const BarChartTest = ({ data, isStory }) => {
  const chartRef = useRef();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    drawChart();
  }, [data]);

  const drawChart = () => {
    const width = screenWidth < 580 ? 310 : screenWidth > 1840 ? 500 : 500;
    const height = screenWidth < 580 ? 250 : 400;
    // const barHeight = 66;
    // const margin = { top: 20, right: 150, bottom: 20, left: 50 };
    // const rectWidth = (width - 4 * 8) / 5;

    // Dynamic calculations
    const margin = {
      top: height * 0.05, // 5% of canvas height
      right: 0, // 25% of canvas width
      bottom: 0, // 5% of canvas height
      left: width * 0.1, // 10% of canvas width
    };

    const barHeight = screenWidth < 580 ? height * 0.15 : height * 0.15; // 15% of canvas height
    const gap = width * 0.015; // Horizontal gap between bars (1.5% of canvas width)
    const numBars = 5; // Total number of bars
    const rectWidth = (width - 4 * 8) / 5;
    // (width - margin.left - margin.right - (numBars - 1) * gap) /
    // numBars;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Define color gradients
    const gradients = [
      { id: "gradientRed", colorStart: "#F6B7B0", colorEnd: "#EC7356" },
      { id: "gradientYellow", colorStart: "#F6B7B0", colorEnd: "#F4C908" },
    ];

    // Add gradients to defs
    const defs = svg.append("defs");
    gradients.forEach(({ id, colorStart, colorEnd }) => {
      const gradient = defs
        .append("linearGradient")
        .attr("id", id)
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0%");

      gradient
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", colorStart);

      gradient
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", colorEnd);
    });

    // Draw rows of 5 small rectangles with partial fills
    data.forEach((d, i) => {
      const gradientId = !d?.isSecondary ? gradients[0].id : gradients[1].id;

      // Calculate the number of fully filled rectangles and the fractional part for partial fill
      const numFullRects = Math.floor(d.value / 20); // Each rect represents 20%
      const partialFill = (d.value % 20) / 20; // Fractional fill for the next rect

      for (let j = 0; j < 5; j++) {
        const rectGroup = svg.append("g");

        // Placeholder for background rectangles
        rectGroup
          .append("rect")
          .attr("x", j * (rectWidth + 4))
          .attr("y", i * (barHeight + 15))
          .attr("width", rectWidth)
          .attr("height", barHeight)
          .attr("fill", isStory ? "#3557BF" : "#F3F3F3")
          .attr("rx", 5)
          .attr("ry", 5);

        // Animated filled rectangles
        const fillWidth =
          j < numFullRects
            ? rectWidth
            : j === numFullRects
            ? rectWidth * partialFill
            : 0;

        rectGroup
          .append("rect")
          .attr("x", j * (rectWidth + 4))
          .attr("y", i * (barHeight + 15))
          .attr("width", 0) // Start with 0 width
          .attr("height", barHeight)
          .attr("fill", `url(#${gradientId})`)
          .attr("rx", 5)
          .attr("ry", 5)
          .transition()
          .delay(j * 200) // Delay each rectangle's animation
          .duration(1000) // Animate over 1 second
          .ease(d3.easeCubicOut) // Smooth easing function
          .attr("width", fillWidth); // Animate to calculated width
      }

      // Percentage label on the left
      svg
        .append("text")
        .attr("x", -margin.left + width * 0.001)
        .attr("y", i * (barHeight + 14) + barHeight / 3)
        .attr("dy", "1.125em")
        .attr("text-anchor", "start")
        .attr("fill", isStory ? "#fff" : "#000")
        .style("font-weight", screenWidth < 580 ? "normal" : "bold")
        .attr("font-family", "Urbanist")
        .text(`${d.value}%`);

      // Category label on the right
      svg
        .append("text")
        .attr("x", width - (gap + 15))

        .attr("y", i * (barHeight + 15) + barHeight / 3)
        .attr("dy", "1.125rem")
        .attr("text-anchor", "end")
        .attr("fill", isStory ? "#FFF" : "#7E7F83")
        .text(d.category);
    });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div ref={chartRef}></div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "6px",
            color: isStory && "#fff",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <circle
              cx="6.5"
              cy="6.5"
              r="6.5"
              fill="url(#paint0_linear_2164_77742)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2164_77742"
                x1="11.4408"
                y1="14.05"
                x2="1.48321"
                y2="14.0899"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F6B7B0" />
                <stop offset="1" stopColor="#EC7356" />
              </linearGradient>
            </defs>
          </svg>
          Primary
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "6px",
            color: isStory && "#fff",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <circle
              cx="6.5"
              cy="6.5"
              r="6.5"
              fill="url(#paint0_linear_2164_77745)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2164_77745"
                x1="11.4408"
                y1="14.05"
                x2="1.48321"
                y2="14.0899"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F4C908" />
                <stop offset="1" stopColor="#F6B7B0" />
              </linearGradient>
            </defs>
          </svg>
          Secondary
        </div>
      </div>
    </div>
  );
};

export default memo(BarChartTest);

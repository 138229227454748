import React, { useState } from "react";
import { ReactComponent as Point } from "../../Assets/icon/li_dot.svg";
import "./style.css";

// Modal Component
const CoachingModal = ({
  isOpen,
  onClose,
  title,
  description,
  points,
  footer,
  data,
}) => {
  if (!isOpen) return null;
  return (
    <div className="pricing-modal-overlay" onClick={onClose}>
      <div className="pricing-modal" onClick={(e) => e.stopPropagation()}>
        <div className="pricing-modal-header">
          <h2>{title}</h2>
          <button onClick={onClose} className="pricing-close-button">
            ✕
          </button>
        </div>
        <div className="pricing-modal-content">
          <p className="pricing-modal-description">{description}</p>
          <ul className="pricing-modal-points">
            {points.map((point, index) => (
              <div key={index} className="pricing-modal-point">
                <Point className="pricing-point" />
                {point}
              </div>
            ))}
          </ul>
          {footer && <p className="pricing-modal-footer">{footer}</p>}
        </div>
      </div>
    </div>
  );
};

export default CoachingModal;

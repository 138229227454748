import React, { useEffect, useState, useCallback, useRef } from "react";
import "./style.css";
import InitialChat from "./InitialChat";
import RelationShipChat from "./RelationShipChatScreen/RelationShipChat";
import {
  chatArisApi,
  chatArisApiRelationShip,
  createMsg,
  getChatHistory,
  initiateChatSession,
  onChatEnd,
} from "../../Services/Chat";
import {
  convertMessages,
  convertToNewFormat,
} from "../../Utils/CommonFunctions/chats";
import { useHeader } from "../../Providers/HeaderContextProvider";
import RelationshipHeader from "./RelationshipHeader";
import ChatHistory from "./ChatHistory";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import BlueSvgBackground from "../../Components/BlueSvgBackground";
import { ReactComponent as Mic } from "../../Assets/icon/Microphone-coaching.svg";
import { ReactComponent as DisabledMic } from "../../Assets/icon/coaching-microphone-gray.svg";
import SessionSelection from "./LiveSession/SessionSelection";
import { Tooltip } from "react-tooltip";
import { Toaster } from "react-hot-toast";
import { ReactComponent as CheckGreen } from "../../Assets/icon/check-green.svg";
import {
  addRelationship,
  fetchInsightContent,
  getRelationshipAdvice,
  getRelationshipProfile,
} from "../../Services/Realtionship";
import { chatCreateRelationShip } from "../../Services/arisAI";
import EditRelationShipModal from "./RelationShipChatScreen/EditRelationShipModal";
import AddRelationShipChatModal from "./AddRelationShipChatModal";
import { getSessions } from "../../Services/coaching";

function RelationShip() {
  const [searchParams] = useSearchParams();
  const adviceTitle = searchParams.get("adviceTitle");
  const relationId = searchParams.get("relationId");

  const { headerContent, setHeaderContent, profileContent } = useHeader();
  // const [chatSessionId, setchatSessionId] = useState(null);
  const [chatData, setchatData] = useState([]);
  const [chatDataHistory, setchatDataHistory] = useState([]);
  const [showHistoryMenu, setshowHistoryMenu] = useState(false);
  const [showLiveSessionModal, setshowLiveSessionModal] = useState(false);
  const chatDataHistoryRef = useRef(chatDataHistory);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const [adviceList, setadviceList] = useState();
  // const [realtionShipFirst, setRealtionShipFirst] = useState(false);
  const [relationShipHistory, setRelationShipHistory] = useState([]);
  const [relationShipTopicSelected, setRelationShipTopicSelected] =
    useState("");
  const [instructionAndRelation, setInstructionAndRelation] = useState({});

  const [showEditRelationship, setshowEditRelationship] = useState(false);
  const [collectedFormDataRelationship, setcollectedFormDataRelationship] =
    useState({});
  const chatRelationShipAdvice = useRef("");
  const adviceList = useRef({});
  const isAddRelationProcess = useRef(false);

  const chatInstructionRelation = useRef({});
  const chatSessionId = useRef("");
  const collectedRelationShipFormData = useRef({});
  const [latestInProgressSession, setLatestInProgressSession] = useState(false);
  const [enableCoaching, setEnableCoaching] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    //
    if (relationId) {
      getRelationshipProfile(relationId).then((e) => {
        adviceList.current = e;
        pushInData({
          type: "initiateRelationship",
          subType: "directAdvice",
          text: "I need relationship advice for ",
          name: e?.first_name + " " + e?.last_name,
          profile_pic_url: e?.profile_pic_url,
          contact_relational_data: e?.related_data,
          relation_id: e?._id,
        });
      });
    }
    if (adviceTitle) chatRelationShipAdvice.current = adviceTitle;

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Keep ref updated with the latest chatDataHistory
  useEffect(() => {
    chatDataHistoryRef.current = chatDataHistory;
  }, [chatDataHistory]);

  const navigate = useNavigate();

  useEffect(() => {
    setHeaderContent(chatData.length === 0);
  }, [chatData]);

  const onAddNewContactWithChat = (data, history) => {
    if (data?.length === 0) {
      setchatData((prev) => [...prev, { role: "system", content: "" }]);
    } else {
      setchatData((prev) => [
        ...prev,
        { role: "user", content: data },
        { role: "system", content: "" },
      ]);
    }
    let updatedHistory = [...history, { role: "user", content: data }];
    let tmp = {
      name: profileContent?.first_name,
      history: updatedHistory,
    };
    chatCreateRelationShip(tmp).then((res) => {
      if (res?.is_conversation_done) {
        setshowEditRelationship(true);
        setcollectedFormDataRelationship(res?.form);
        collectedRelationShipFormData.current = res?.form;
      } else {
        setchatData((prevItems) => {
          if (prevItems.length === 0) return prevItems;
          const newItems = [...prevItems];
          newItems[newItems.length - 1] = {
            ...newItems[newItems.length - 1],
            content:
              res?.query?.length > 0
                ? res?.query
                : "Something went wrong- Aris",
          };
          return newItems;
        });
        setchatDataHistory((prev) => [
          ...prev,
          { role: "user", content: data },
          { role: "system", content: res?.query || "" },
        ]);
      }
    });
  };

  const addNewRelationship = (contactData, data = "") => {
    // addRelationship(contactData)
    //   .then((resAdd) => {
    //     console.log(resAdd, "added Relationship1111");
    //     setchatData((prevItems) => {
    //       if (prevItems.length === 0) return prevItems;
    //       const newItems = [...prevItems];
    //       newItems[newItems.length - 1] = {
    //         ...newItems[newItems.length - 1],
    //         content:
    //           resAdd?.message ||
    //           "Something went wrong unable to add relationship - Aris",
    //       };
    //       return newItems;
    //     });
    //     setchatDataHistory((prev) => [
    //       ...prev,
    //       { role: "user", content: data },
    //       { role: "system", content: resAdd?.message || "" },
    //     ]);
    //     isAddRelationProcess.current = false;
    //   })
    //   .catch((err) => {
    //     setchatData((prevItems) => {
    //       if (prevItems.length === 0) return prevItems;
    //       const newItems = [...prevItems];
    //       newItems[newItems.length - 1] = {
    //         ...newItems[newItems.length - 1],
    //         content: "Something went wrong unable to add relationship - Aris",
    //       };
    //       return newItems;
    //     });
    //     setchatDataHistory((prev) => [
    //       ...prev,
    //       { role: "user", content: data },
    //       { role: "system", content: "" },
    //     ]);
    //   });
  };

  const pushInData = useCallback(async (data) => {
    if (showEditRelationship) {
      setshowEditRelationship(false);
      isAddRelationProcess.current = false;
    }
    console.log(chatSessionId.current, data, "chatSessionId111222333");

    let txtToPass;
    if (chatSessionId.current === "" || chatSessionId.current === null) {
      console.log(chatSessionId.current, "chatSessionId");
      await initiateChatSession(
        typeof data === "object" ? data?.text + data?.name : data
      ).then(async (chatIDRres) => {
        console.log(chatIDRres?.payload?.chat_id, "chatidreshjjj");
        // setchatSessionId(chatIDRres?.payload?.chat_id);
        chatSessionId.current = chatIDRres?.payload?.chat_id;
        if (typeof data === "object" && data !== null && !Array.isArray(data)) {
          if (isAddRelationProcess.current) {
            console.log("here111111");
            onAddNewContactWithChat(data, chatDataHistoryRef.current);
          } else if ((data.type = "initiateRelationship")) {
            if (data.subType === "directAdvice") {
              txtToPass = data?.text + data?.name;
              if (
                chatSessionId.current !== "" ||
                chatSessionId.current !== null
              ) {
                setchatData((prev) => [
                  ...prev,
                  { role: "user", content: data },
                ]);
                callArisReltionShipApi("");
              }
            } else if (data.subType === "addNewContact") {
              console.log("here111222222");

              isAddRelationProcess.current = true;
              // let tmp = {
              //   name: profileContent?.first_name,
              //   history: chatDataHistoryRef.current,
              // };
              // chatCreateRelationShip(tmp).then((res) => {
              //   setchatData((prev) => [
              //     ...prev,
              //     {
              //       role: "system",
              //       content: res?.query,
              //     },
              //   ]);
              // });
              onAddNewContactWithChat("", chatDataHistoryRef.current);
            } else {
              adviceList.current = data;
              await getRelationshipAdvice(
                data?.contact_relational_data?.relationship_category,
                data?.contact_relational_data?.relationship_type
              ).then(async (res) => {
                // await callArisReltionShipApi(txtToPass, "");
                txtToPass = data?.text + data?.name;
                if (
                  chatSessionId.current !== "" ||
                  chatSessionId.current !== null
                ) {
                  setchatData((prev) => [
                    ...prev,
                    { role: "user", content: data },
                    {
                      role: "system",
                      content: `What kind of advice would you like for your ${data?.contact_relational_data?.relationship_type}, ${data?.name}?`,
                      options: res?.advice,
                    },
                  ]);
                }
              });
            }
          }
        } else {
          if (isAddRelationProcess.current) {
            console.log("here1113333333");

            onAddNewContactWithChat(data, chatDataHistoryRef.current);
          } else if (chatRelationShipAdvice.current !== "") {
            txtToPass = data;
            if (
              chatSessionId.current !== "" ||
              chatSessionId.current !== null
            ) {
              setchatData((prev) => [...prev, { role: "user", content: data }]);
              callArisReltionShipApi(data || "");
            }
          } else {
            txtToPass = data;
            if (
              chatIDRres?.payload?.chat_id !== "" ||
              chatIDRres?.payload?.chat_id !== null
            ) {
              setchatData((prev) => [
                ...prev,
                { role: "user", content: data },
                { role: "system", content: "" },
              ]);
              console.log("txt", txtToPass);
              callAris(txtToPass);
            }
          }
        }
      });
    } else {
      console.log(data, isAddRelationProcess.current, "txtToPass444444");
      if (typeof data === "object" && data !== null && !Array.isArray(data)) {
        if (isAddRelationProcess.current) {
          console.log("here111444444");

          onAddNewContactWithChat(data, chatDataHistoryRef.current);
        } else if ((data.type = "initiateRelationship")) {
          if (data.subType === "directAdvice") {
            txtToPass = data?.text + data?.name;
            if (
              chatSessionId.current !== "" ||
              chatSessionId.current !== null
            ) {
              setchatData((prev) => [...prev, { role: "user", content: data }]);
              callArisReltionShipApi("");
            }
          } else if (data.subType === "addNewContact") {
            console.log("here1115555555");

            isAddRelationProcess.current = true;

            onAddNewContactWithChat("", chatDataHistoryRef.current);
          } else {
            adviceList.current = data;
            await getRelationshipAdvice(
              data?.contact_relational_data?.relationship_category,
              data?.contact_relational_data?.relationship_type
            ).then(async (res) => {
              // await callArisReltionShipApi(txtToPass, "");
              txtToPass = data?.text + data?.name;
              if (
                chatSessionId.current !== "" ||
                chatSessionId.current !== null
              ) {
                setchatData((prev) => [
                  ...prev,
                  { role: "user", content: data },
                  {
                    role: "system",
                    content: `What kind of advice would you like for your ${data?.contact_relational_data?.relationship_type}, ${data?.name}?`,
                    options: res?.advice,
                  },
                ]);
              }
            });
          }
        }
      } else {
        if (isAddRelationProcess.current) {
          console.log("here11166666");

          onAddNewContactWithChat(data, chatDataHistoryRef.current);
        } else if (chatRelationShipAdvice.current !== "") {
          txtToPass = data;
          if (chatSessionId.current !== "" || chatSessionId.current !== null) {
            setchatData((prev) => [...prev, { role: "user", content: data }]);
            callArisReltionShipApi(data || "");
          }
        } else {
          txtToPass = data;
          if (chatSessionId.current !== "" || chatSessionId.current !== null) {
            setchatData((prev) => [
              ...prev,
              { role: "user", content: data },
              { role: "system", content: "" },
            ]);

            callAris(txtToPass);
          }
        }
      }
    }
  }, []);

  // const newChatInitiated = useCallback((e) => {
  //   setchatSessionId(e);
  // }, []);

  const onChatBackButtonClick = () => {
    onChatEnd(chatSessionId.current).then((res) => {
      chatSessionId.current = "";
      isAddRelationProcess.current = false;
      navigate(0);
    });

    //chat

    // setchatData([]);
    // setchatDataHistory([]);
    // setchatSessionId(null);
    // setInstructionAndRelation();
  };

  const onhistoryButtonClick = useCallback(() => {
    setshowHistoryMenu((prev) => !prev);
  }, []);

  const onHistorySessionClick = useCallback(async (sessionId) => {
    await loadChatHistory(sessionId?.chat_id);
    // setchatSessionId(sessionId?.chat_id);
    chatSessionId.current = sessionId?.chat_id;
    setshowHistoryMenu(false);
  }, []);

  const loadChatHistory = async (sessionId) => {
    const res = await getChatHistory(sessionId);
    const chatHistory = await convertMessages(res?.payload?.chat_history);
    setchatData(chatHistory);
  };

  const callAris = async (query) => {
    console.log(
      instructionAndRelation,
      chatInstructionRelation.current,
      "instruction23333"
    );
    // Update the state
    if (
      chatInstructionRelation.current?.instruction &&
      chatInstructionRelation.current?.relation_data
    ) {
      console.log(query, "txt222222");

      const res = await chatArisApi(
        profileContent?._id,
        query,
        chatDataHistoryRef.current,
        chatInstructionRelation.current?.instruction || {},
        chatInstructionRelation.current?.relation_data || []
      );
      setchatDataHistory((prev) => [
        ...prev,
        { role: "user", content: query },
        { role: "system", content: res || "No response from Aris" },
      ]);

      if (res.length !== 0) {
        const msg = await convertToNewFormat([
          { role: "user", content: query },
          { role: "system", content: res || "No response from Aris" },
        ]);
        await createMsg(chatSessionId.current, msg);
        setchatData((prevItems) => {
          if (prevItems.length === 0) return prevItems;
          const newItems = [...prevItems];
          newItems[newItems.length - 1] = {
            ...newItems[newItems.length - 1],
            content: res?.length > 0 ? res : "Something went wrong- Aris",
          };
          return newItems;
        });
      }
    } else {
      setchatData((prevItems) => {
        if (prevItems.length === 0) return prevItems;
        const newItems = [...prevItems];
        newItems[newItems.length - 1] = {
          ...newItems[newItems.length - 1],
          content: "Something went wrong- Aris",
        };
        return newItems;
      });
    }
  };

  useEffect(() => {
    if (Cookies.get("idtoken") === undefined) {
      navigate("/");
    }
  }, []);

  const callArisReltionShipApi = async (query, topic) => {
    let tmp = {
      name: profileContent?.first_name,
      relationship: adviceTitle
        ? adviceList.current?.related_data?.relationship_category
        : adviceList.current?.contact_relational_data?.relationship_category,
      relation: adviceTitle
        ? adviceList.current?.related_data?.relationship_type
        : adviceList.current?.contact_relational_data?.relationship_type,
      query: query || "",
      history: chatDataHistoryRef.current,
      partner_name: adviceTitle
        ? adviceList.current?.first_name + " " + adviceList.current?.last_name
        : adviceList.current?.name,
      topic: topic?.length > 0 ? topic : chatRelationShipAdvice.current || "",
      u1_Pstatus: adviceTitle
        ? adviceList.current?.related_data?.u1_status
        : adviceList.current?.contact_relational_data?.u1_status,
      u2_Pstatus: adviceTitle
        ? adviceList.current?.related_data?.u2_status
        : adviceList.current?.contact_relational_data?.u2_status,
      stream: true,
      belief_system: {},
      relationship_data: adviceTitle
        ? adviceList.current?.related_data
        : adviceList.current?.contact_relational_data,
    };
    console.log(tmp, "optionsSelected2222111111");
    if (!query) {
      setchatData((prev) => [
        ...prev,
        { role: "user", content: topic },
        { role: "system", content: "" },
      ]);
      // setchatData((prev) => [...prev, { role: "system", content: "" }]);
      let data = await fetchInsightContent(
        "advice",
        adviceList.current?.relation_id || relationId,
        chatRelationShipAdvice.current,
        false,
        false
      );
      console.log(data, data[chatRelationShipAdvice.current], "insightData");
      ////
      if (data) {
        const msg = await convertToNewFormat([
          { role: "user", content: query },
          {
            role: "system",
            content:
              data[chatRelationShipAdvice.current] || "No response from Aris",
          },
        ]);
        await createMsg(chatSessionId.current, msg);
        setchatData((prevItems) => {
          if (prevItems.length === 0) return prevItems;
          const newItems = [...prevItems];
          newItems[newItems.length - 1] = {
            ...newItems[newItems.length - 1],
            content: data[chatRelationShipAdvice.current],
          };
          return newItems;
        });
      } else {
        setchatData((prevItems) => {
          if (prevItems.length === 0) return prevItems;
          const newItems = [...prevItems];
          newItems[newItems.length - 1] = {
            ...newItems[newItems.length - 1],
            content: "Sorry, Something Went Wrong !!",
          };
          return newItems;
        });
      }
    } else {
      setchatData((prev) => [...prev, { role: "system", content: "" }]);

      const res = await chatArisApiRelationShip(tmp);
      setRelationShipHistory(res?.history);
      setchatDataHistory((prev) => [
        ...prev,
        { role: "user", content: query },
        {
          role: "system",
          content: res?.response?.question || "No response from Aris",
        },
      ]);
      if (res?.response?.question?.length !== 0) {
        const msg = await convertToNewFormat([
          { role: "user", content: query },
          {
            role: "system",
            content: res?.response?.question || "No response from Aris",
          },
        ]);
        await createMsg(chatSessionId.current, msg);
        setchatData((prevItems) => {
          if (prevItems.length === 0) return prevItems;
          const newItems = [...prevItems];
          newItems[newItems.length - 1] = {
            ...newItems[newItems.length - 1],
            content:
              res?.response?.question ||
              "Sorry, Something Went Wrong !! - Aris",
          };
          return newItems;
        });
      } else {
        setchatData((prevItems) => {
          if (prevItems.length === 0) return prevItems;
          const newItems = [...prevItems];
          newItems[newItems.length - 1] = {
            ...newItems[newItems.length - 1],
            content: "Sorry, Something Went Wrong !!",
          };
          return newItems;
        });
      }
      if (res?.response?.is_next_conversation_is_advice) {
        setchatData((prev) => [...prev, { role: "system", content: "" }]);
        let data = await fetchInsightContent(
          "advice",
          adviceList.current?.relation_id || relationId,
          chatRelationShipAdvice.current,
          false,
          false
        );
        console.log(data, data[chatRelationShipAdvice.current], "insightData");
        ////
        if (data) {
          const msg = await convertToNewFormat([
            { role: "user", content: query },
            {
              role: "system",
              content:
                data[chatRelationShipAdvice.current] || "No response from Aris",
            },
          ]);
          await createMsg(chatSessionId.current, msg);
          setchatData((prevItems) => {
            if (prevItems.length === 0) return prevItems;
            const newItems = [...prevItems];
            newItems[newItems.length - 1] = {
              ...newItems[newItems.length - 1],
              content: data[chatRelationShipAdvice.current],
            };
            return newItems;
          });
        } else {
          setchatData((prevItems) => {
            if (prevItems.length === 0) return prevItems;
            const newItems = [...prevItems];
            newItems[newItems.length - 1] = {
              ...newItems[newItems.length - 1],
              content: "Sorry, Something Went Wrong !!",
            };
            return newItems;
          });
        }
      }
    }
  };

  const onAddRealtionClose = (e) => {
    setshowEditRelationship(false);
  };

  const onOptionSelected = (e) => {
    chatRelationShipAdvice.current = e?.text;
    setRelationShipTopicSelected(e?.text);
    // console.log(e, adviceList.current, "optionsSelected2222");

    callArisReltionShipApi("", e?.text || "");
    // console.log(e, "optionsSelected2222");
  };

  const chatInstruction = (instruction) => {
    console.log(instruction, "instruction111");
    chatInstructionRelation.current = instruction;
    setInstructionAndRelation(instruction);
  };

  const onAddedRelationShip = (data) => {
    isAddRelationProcess.current = false;
    setchatData((prevItems) => {
      if (prevItems.length === 0) return prevItems;
      const newItems = [...prevItems];
      newItems[newItems.length - 1] = {
        ...newItems[newItems.length - 1],
        content: "Relationship added succesfully",
      };
      return newItems;
    });
    pushInData(data);
    onAddRealtionClose(false);
    setshowEditRelationship(false);
  };

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await getSessions();
        const mins = response.payload.minutes;
        if (mins > 0) {
          setEnableCoaching(true);
        }
        const inProgressSessions = response.payload.sessions.filter(
          (session) => session.coaching_status === "in_progress"
        );
        if (inProgressSessions.length > 0 && mins > 0) {
          setLatestInProgressSession(true);
        }
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    };

    fetchSessions();
  }, []);

  console.log("chatID", isAddRelationProcess.current, chatSessionId.current);
  return (
    <>
      {!headerContent && (
        <RelationshipHeader
          onBackClick={onChatBackButtonClick}
          onHistoryClick={onhistoryButtonClick}
          showHistoryMenu={showHistoryMenu}
        />
      )}

      {showHistoryMenu && (
        <ChatHistory
          historyMenuClose={onhistoryButtonClick}
          onHistorySessionClick={onHistorySessionClick}
        />
      )}

      {showLiveSessionModal && (
        <SessionSelection
          showLiveSessionModal={showLiveSessionModal}
          setShowLiveSessionModal={setshowLiveSessionModal}
          onClose={() => setshowLiveSessionModal(false)}
        />
      )}
      {showEditRelationship && (
        <AddRelationShipChatModal
          title={"Add new Relationship"}
          onClose={onAddRealtionClose}
          contact={collectedFormDataRelationship}
          onAddClick={addNewRelationship}
          onAddedRelationShip={onAddedRelationShip}
        />
      )}
      <div>
        <BlueSvgBackground>
          <div
            className={
              "relationship-hero-container" +
              (chatData.length !== 0 ? " align-down" : "")
            }
          >
            <div className="relationship-container">
              {chatData.length !== 0 ? (
                <RelationShipChat
                  onEnterData={pushInData}
                  chatData={chatData}
                  awaitingResponse={false}
                  chatSessionId={chatSessionId}
                  onOptionSelected={onOptionSelected}
                />
              ) : (
                <InitialChat
                  onEnterData={pushInData}
                  // chatId={newChatInitiated}
                  chatInstruction={chatInstruction}
                  latestInProgressSession={latestInProgressSession}
                />
              )}
            </div>
          </div>

          {enableCoaching ? (
            <div
              className={`live-session-button`}
              onClick={() => {
                if (enableCoaching) {
                  setshowLiveSessionModal(true);
                }
              }}
            >
              <Mic width={20} height={20} />
              <span>Start live session</span>
            </div>
          ) : (
            <div
              className={`live-session-button disabled`}
              data-tooltip-id="tooltip-anchor"
            >
              <DisabledMic width={20} height={20} />
              <span>Start live session</span>
            </div>
          )}
        </BlueSvgBackground>
        <Tooltip
          id="tooltip-anchor"
          place={screenWidth <= 488 ? "top-end" : "top"}
          border={"2px solid #D9D9D9"}
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            color: "#000000",
            zIndex: 999999,
          }}
        >
          <p style={{ textAlign: "center", fontWeight: 600, fontSize: "16px" }}>
            Free trial ended
          </p>
          <p
            style={{
              textAlign: "center",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "14.4px",
              color: "#4674FE",
            }}
          >
            Renew subscription
          </p>
        </Tooltip>
        <Toaster
          position={screenWidth <= 488 ? "bottom-center" : "top-right"}
          toastOptions={{
            duration: 3000,
            style: {
              border: "1px solid #04D9B5",
              background: "#FFFFFF",
              color: "#000000",
              fontSize: "14px",
              fontWeight: 400,
              padding: "8px 12px",
              borderRadius: "8px",
              marginTop: "3rem",
            },
            icon: <CheckGreen width={20} height={20} />,
          }}
        />
      </div>
    </>
  );
}

export default RelationShip;

import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import rightIcon from "../../Assets/icon/chevron-right.svg";
import AddCoachingMinutes from "./AddCoachingMinutes";
import ConfirmationModal from "../../Pages/ManageMembership/ManageMembershipCancel";
import Allset from "../AllsetComponent";
import "./style.css";
import { transferuser } from "../../Services/subscription";
// import { set } from "react-datepicker/dist/date_utils";

const CoachingMinutesModal = ({
  contactDetails,
  onClose,
  ispurchased,
  productId,
  priceDetails,
  user_type,
  minutes=minutes,
  onTransferMinutesComplete
}) => {
  const [imageError, setImageError] = useState(false);
  const [activeModal, setActiveModal] = useState("main");
  const [isTransferOwnership, setIsTransferOwnership] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [showCoachingModal, setShowCoachingModal] = useState(true);
  const [isAllSet, setIsAllSet] = useState(false);
  const [isAllSetromove, setIsAllSetremove] = useState(false);
  const [updatedMinutes, setUpdatedMinutes] = useState(
    contactDetails?.coaching_minutes?.value || 0
  );
  const [selectedMinutes, setSelectedMinutes] = useState(0); // State to hold selected radio value

  const handleAddCoachingMinutes = () => {
    setActiveModal("addMinutes");
    console.log(contactDetails, "contactDetailst");
  };

  const handleTrasnferOwnership = () => {
    setShowCoachingModal(false);
    setIsTransferOwnership(true);
  };

  const handleCloseAddMinutes = () => {
    setActiveModal("main");
  };

  const handleMainClose = () => {
    setActiveModal("main");
    onClose();
  };

  const handleFlowComplete = () => {
    setActiveModal("main");
  };

  const closeCancelMembershipModal = () => {
    setIsCancelModal(false);
    onClose();
  };

  const handleCancelMembership = () => {
    setIsCancelModal(false);
    setIsAllSetremove(true);
  };

  const handleTransferMembership = async () => {
    try {
      const payload = {
        user_id: contactDetails?.user_id, // Ensure this is the correct user ID
        action: 0,
      };

      await transferuser(payload).then((res)=>{
        localStorage.setItem("successMode", "transferOwnership");

      });

      setIsTransferOwnership(false);
      setIsAllSet(true);
    } catch (error) {
      console.error("Failed to transfer ownership:", error);
    }
  };

  const handleRemoveAccount = async () => {
    try {
      const payload = {
        user_id: contactDetails?.user_id,
        action: 1,
      };
      await transferuser(payload).then((res)=>{
        localStorage.setItem("successMode", "removeUser");

      });
;

      setShowCoachingModal(false);
      setIsCancelModal(true);
    } catch (error) {
      console.error("Failed to remove account:", error);
    }
  };

  const handleAllsetClose = () => {
    setIsAllSet(false);
    setIsAllSetremove(false);
    onClose();
  };

  const options = [
    {
      label: "Add coaching minutes",
      subHeading:
        "You can purchase additional coaching minutes or transfer your existing ones",
      onClick: handleAddCoachingMinutes,
    },
    {
      label: "Transfer ownership of the account",
      subHeading:
        "Once the transfer is complete, you will no longer be able to manage the account or make changes to the subscription",
      onClick: handleTrasnferOwnership,
    },
    {
      label: "Remove account",
      subHeading:
        "Removing this user will stop future billing for this license and disconnect you from this person.",
      onClick: handleRemoveAccount,
    },
  ];

  // Return AddCoachingMinutes if it's active
  if (activeModal === "addMinutes") {
    return (
      <AddCoachingMinutes
        contactDetails={contactDetails}
        onClose={handleCloseAddMinutes}
        onFlowComplete={handleFlowComplete}
        productId={productId}
        priceDetails={priceDetails}
        minutes={minutes}
        onTransferMinutesComplete={onTransferMinutesComplete}
        user_type={user_type}
      />
    );
  }

  console.log(priceDetails, "coachpricedetils");

  return (
    <>
      {showCoachingModal && (
        <div className="membershipmodal-overlay">
          <div className="membershipmodal-container">
            <div className="membershipmodal-header">
              <div className="coaching-profile-container">
                <div className="coaching-avatar">
                  {contactDetails?.profile_pic_url && !imageError ? (
                    <img
                      src={contactDetails.profile_pic_url}
                      alt={`${contactDetails.first_name} ${contactDetails.last_name}`}
                      className="coaching-avatar-image"
                      onError={() => setImageError(true)}
                    />
                  ) : (
                    <div className="coaching-avatar-initials">
                      {`${
                        contactDetails?.first_name?.[0]?.toUpperCase() || ""
                      }${contactDetails?.last_name?.[0]?.toUpperCase() || ""}`}
                    </div>
                  )}
                </div>

                <div className="coaching-details">
                  <h3 className="coaching-name">
                    {`${contactDetails?.first_name} ${contactDetails?.last_name}`}
                  </h3>
                  <p className="coaching-minutes">
                    {contactDetails?.coaching_minutes?.value || 0} coaching
                    minutes
                  </p>
                </div>
              </div>
              <button className="membershipclose-btn" onClick={handleMainClose}>
                <CloseIcon />
              </button>
            </div>

            <div
              className="membershipmodal-body"
              style={{
                border: "none",
                boxShadow: "none",
                padding: "0px",
                margin: "0px",
              }}
            >
              <div style={{ width: "100%" }}>
                {options.map((option, index) => (
                  <div key={index} className="option-container">
                    <div
                      className="manage-option-button membership-btn-txt"
                      style={{ boxSizing: "border-box", gap: "3px" }}
                      onClick={option.onClick}
                    >
                      <div>
                        <p className="option-lable">{option.label}</p>
                        <p className="option-subheading">{option.subHeading}</p>
                      </div>
                      <img
                        src={rightIcon}
                        alt=""
                        className="manage-rightarrowicon"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {isCancelModal && (
        <ConfirmationModal
          isOpen={isCancelModal}
          onClose={closeCancelMembershipModal}
          onConfirm={handleCancelMembership}
          heading="Are you sure you want to remove this account form your plan?"
          subheading="You won’t be able to manage this account anymore"
          confirmButtonText="Yes, Remove"
          cancelButtonText="Cancel"
        />
      )}

      {isTransferOwnership && (
        <ConfirmationModal
          isOpen={isTransferOwnership}
          onClose={closeCancelMembershipModal}
          onConfirm={handleTransferMembership}
          heading="Are you sure you want to transfer the ownership of the account?"
          subheading="You won’t be able to manage this account anymore"
          confirmButtonText="Yes, Transfer"
          cancelButtonText="Cancel"
        />
      )}
      {/* {isAllSet && (
        <Allset
          closeModal={handleAllsetClose}
          heading={ispurchased==="confirm" ?`You're all set`:"Oops!"}
          subText={
            ispurchased === "confirm"
              ? `The ownership of the account was transferred to ${contactDetails?.first_name} ${contactDetails?.last_name}`
              : "Something went wrong!"
          }
          isFailure={ispurchased === "confirm" ? false : true}
        />
      )}

      {isAllSetromove && (
        <Allset
        heading={ispurchased==="confirm" ?`You're all set`:"Oops!"}
          closeModal={handleAllsetClose}
          subText={
            ispurchased === "confirm"
              ? `The sub member ${contactDetails?.first_name} ${contactDetails?.last_name} was removed from your account.`
              : "Something went wrong!"
          }
          isFailure={ispurchased === "confirm" ? false : true}
          
        />
      )} */}
    </>
  );
};

export default CoachingMinutesModal;


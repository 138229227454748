import React from "react";

function Personality16() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 106"
      fill="none"
    >
      <g clip-path="url(#clip0_2161_30236)">
        <path
          d="M14.6758 18.9609L92.224 12.543L97.3036 101.381L35.5021 68.9531L14.6758 18.9609Z"
          fill="#45D9BF"
        />
        <path
          d="M14.6758 18.9609L92.224 12.543M14.6758 18.9609L35.5021 68.9531M14.6758 18.9609L97.3036 101.381M92.224 12.543L97.3036 101.381M92.224 12.543L35.5021 68.9531M97.3036 101.381L35.5021 68.9531"
          stroke="white"
          stroke-opacity="0.7"
          stroke-width="0.211394"
          stroke-miterlimit="10"
        />
        <path
          d="M92.2355 13.2397L97.2413 101.254L14.7402 19.0693L92.2355 13.2397Z"
          fill="#4FE3D3"
        />
        <g opacity="0.6" filter="url(#filter0_f_2161_30236)">
          <path
            d="M92.5789 10.1348L95.7406 19.8511H105.972L97.6947 25.8561L100.856 35.5725L92.5789 29.5674L84.3013 35.5725L87.4631 25.8561L79.1855 19.8511H89.4171L92.5789 10.1348Z"
            fill="white"
          />
        </g>
        <g opacity="0.6" filter="url(#filter1_f_2161_30236)">
          <path
            d="M36.0554 55.7578L39.2172 65.4741H49.4488L41.1712 71.4792L44.333 81.1955L36.0554 75.1905L27.7779 81.1955L30.9396 71.4792L22.6621 65.4741H32.8937L36.0554 55.7578Z"
            fill="white"
          />
        </g>
        <g opacity="0.6" filter="url(#filter2_f_2161_30236)">
          <path
            d="M18.8592 10.0083L21.7611 18.9635H31.152L23.5546 24.4981L26.4566 33.4533L18.8592 27.9187L11.2618 33.4533L14.1638 24.4981L6.56641 18.9635H15.9573L18.8592 10.0083Z"
            fill="white"
          />
        </g>
        <g opacity="0.4" filter="url(#filter3_f_2161_30236)">
          <path
            d="M55.9904 37.5083L61.4652 54.3053H79.1819L64.8488 64.6865L70.3236 81.4835L55.9904 71.1024L41.6572 81.4835L47.132 64.6865L32.7988 54.3053H50.5156L55.9904 37.5083Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30236"
          x="71.5003"
          y="2.44952"
          width="42.1576"
          height="40.808"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30236"
          />
        </filter>
        <filter
          id="filter1_f_2161_30236"
          x="14.9769"
          y="48.0726"
          width="42.1576"
          height="40.808"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30236"
          />
        </filter>
        <filter
          id="filter2_f_2161_30236"
          x="-1.11884"
          y="2.32305"
          width="39.9564"
          height="38.8153"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30236"
          />
        </filter>
        <filter
          id="filter3_f_2161_30236"
          x="19.1945"
          y="23.904"
          width="73.5914"
          height="71.1837"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6.80215"
            result="effect1_foregroundBlur_2161_30236"
          />
        </filter>
        <clipPath id="clip0_2161_30236">
          <rect
            width="105.697"
            height="105.697"
            fill="white"
            transform="translate(0.779297 0.197266)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Personality16;

import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import placeholderImage from "../../Assets/images/placeholder.png";
import InputField from "../../Components/InputField/index.jsx";
import nextbtn from "../../Assets/images/arrow-right-blue.png";
import trash from "../../Assets/images/trash.png";
import Cookies from "js-cookie";
import Webcam from "react-webcam";
import { uploadProfilePhoto } from "../../Services/auth";
import { ReactComponent as Calender } from "../../Assets/icon/calendar.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { ReactComponent as Camera } from "../../Assets/icon/camera-01.svg";
import NavigationButton from "../../Components/NavigationButton/index.jsx";

const NAME_REGEX = /^[A-Za-z\s]+$/;

const BasicDetailsPage = ({ onNext, onBack, userFirstName, userLastName }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState(null); // Store as Date object
  const [photo, setPhoto] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    first_name: "",
    last_name: "",
  });
  const [photoRestriction, setphotoRestriction] = useState(false);
  const webcamRef = useRef(null);
  const input2Ref = useRef(null);
  const datePickerRef = useRef(null); // Create a ref for the DatePicker

  useEffect(() => {
    setTimeout(() => {
      if (photoRestriction) {
        setphotoRestriction(false);
      }
    }, 3000);
  }, [photoRestriction]);

  const validateField = (field, value) => {
    const errors = { ...validationErrors };

    if (field === "first_name") {
      if (!value) {
        errors.first_name = "First name is required";
      } else if (value !== value?.trim()) {
        errors.first_name = "Name should not begin or end with spaces";
      } else if (!NAME_REGEX.test(value)) {
        errors.first_name = "Name should only contain letters";
      } else {
        errors.first_name = "";
      }
    }

    if (field === "last_name") {
      if (!value) {
        errors.last_name = "Last name is required"; // Add this error for empty last name
      } else if (value !== value?.trim()) {
        errors.last_name = "Name should not begin or end with spaces";
      } else if (!NAME_REGEX.test(value)) {
        errors.last_name = "Name should only contain letters";
      } else {
        errors.last_name = "";
      }
    }

    setValidationErrors(errors);
    return errors;
  };

  const handleDateChange = (date) => {
    setBirthday(date); // Store the Date object directly
  };

  const formatDateForCookie = (date) => {
    if (!date) return "";
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];

    // Check file size (1 MB = 1,048,576 bytes)
    const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB

    if (!file) {
      return;
    }

    if (file.size > maxSizeInBytes) {
      setphotoRestriction(true);
      // Optional: You can customize this alert or use a more user-friendly notification
      //   alert("File is too large. Please upload a file smaller than 1 MB.");
      // Clear the file input
      event.target.value = null;
      return;
    }

    // If file passes size check, set the photo
    setPhoto(file);
  };

  const openCamera = () => {
    setIsCameraOpen(true);
  };

  const capturePhoto = () => {
    if (webcamRef.current) {
      const capturedPhoto = webcamRef.current.getScreenshot();
      if (capturedPhoto) {
        const file = dataURLToFile(capturedPhoto, "profile_photo.jpg");
        setPhoto(file);
        setIsCameraOpen(false);
      } else {
        console.error("Failed to capture photo.");
      }
    }
  };

  const dataURLToFile = (dataURL, filename) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const openDatePicker = () => {
    // The react-datepicker doesn't have a setFocus method
    // Let's try to find the input element and focus it directly
    if (datePickerRef.current) {
      const dateInput = datePickerRef.current.input;
      if (dateInput) {
        dateInput.focus();
      }
    }
  };

  const handleBasicFunc = async () => {
    // Validate both fields before proceeding
    const firstNameErrors = validateField("first_name", firstName);
    const lastNameErrors = validateField("last_name", lastName);

    // Check if there are any validation errors
    if (firstNameErrors.first_name || lastNameErrors.last_name) {
      return; // Don't proceed if there are validation errors
    }

    Cookies.set("firstName", firstName);
    Cookies.set("lastName", lastName);
    Cookies.set("birthday", formatDateForCookie(birthday)); // Format date when saving to cookie

    if (photo) {
      const formData = new FormData();
      formData.append("file", photo);

      try {
        await uploadProfilePhoto(formData);
      } catch (error) {
        console.error("Error uploading photo:", error);
      }
    }

    // Proceed to the next step regardless of whether a photo was uploaded
    onNext();
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
    // Validate while typing
    validateField("first_name", value);
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
    // Validate while typing
    validateField("last_name", value);
  };

  const handleFirstNameBlur = () => {
    validateField("first_name", firstName);
  };

  const handleLastNameBlur = () => {
    validateField("last_name", lastName);
  };

  const isFormValid =
    firstName &&
    lastName &&
    birthday &&
    !validationErrors.first_name &&
    !validationErrors.last_name;

  const handleKeyDown = (e, nextInputRef, fieldName, value) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault();

      // Validate the current field
      const errors = validateField(fieldName, value);

      // Only move to next field if current field is valid
      const isFieldValid =
        fieldName === "last_name" ? !errors.last_name : !errors.first_name;

      if (isFieldValid) {
        // For DatePicker
        if (nextInputRef === datePickerRef) {
          const dateInput = document.querySelector(
            ".date-picker-container input"
          );
          if (dateInput) dateInput.focus();
        }
        // For other inputs
        else if (nextInputRef && nextInputRef.current) {
          nextInputRef.current.focus();
        }
      }
    }
  };

  useEffect(() => {
    if (userFirstName) {
      setFirstName(userFirstName);
      validateField("first_name", userFirstName);
    }
    if (userLastName) {
      setLastName(userLastName);
      validateField("last_name", userLastName);
    }
  }, [userFirstName, userLastName]);

  return (
    <div className="Fullpage-container-email">
      <div className="email-container">
        <div className="basic-header-container">
          <div>
            <NavigationButton
              onClick={onBack}
              direction="left"
              label="Back"
              className="back-button"
            />
            <h2
              className="heading-h2"
              style={{ marginBottom: "6px", marginTop: "0px" }}
            >
              Basic details
            </h2>
            <p className="mobile-para">Let us know more about you.</p>
          </div>

          <div className="basic-photo-section">
            <div className="basic-photo-preview">
              <img
                src={photo ? URL.createObjectURL(photo) : placeholderImage}
                alt="Profile"
                className="basic-profile-image"
              />
              {photo ? (
                <div className="basic-photo-container">
                  <label htmlFor="upload-photo" className="basic-replace">
                    Replace photo
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handlePhotoUpload}
                    hidden
                    id="upload-photo"
                  />
                  <button
                    className="basic-replace"
                    onClick={() => setPhoto(null)}
                  >
                    <img src={trash} alt="trash" className="nxtbtn" />
                  </button>
                </div>
              ) : (
                <div className="basic-photo-upload-container">
                  <div className="basic-profile-pic-btn">
                    <button className="basic-take-photo" onClick={openCamera}>
                      Take photo
                    </button>
                    <label
                      htmlFor="upload-photo"
                      className="basic-upload-button"
                    >
                      Upload photo
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handlePhotoUpload}
                      hidden
                      id="upload-photo"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          {isCameraOpen && (
            <div className="camera-modal-overlay">
              <div className="camera-modal">
                <button
                  onClick={() => setIsCameraOpen(false)}
                  className="close-camera-button"
                >
                  <CloseIcon />
                </button>
                <h3 className="camera-title">Take a photo</h3>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  className="webcam"
                  videoConstraints={{
                    width: 1280,
                    height: 720,
                    facingMode: "user",
                  }}
                />
                <p className="camera-suggestion">
                  Hold your device straight out in front of you. Make sure your
                  entire face is showing.
                </p>
                <button onClick={capturePhoto} className="capture-button">
                  <Camera />
                  Take a photo
                </button>
              </div>
            </div>
          )}

          <div className="basic-input-container">
            <div className="responsive-grid">
              <div>
                <label className="basic-input-lable">First Name</label>
                <InputField
                  type="text"
                  placeholder="Enter your first name"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  onBlur={handleFirstNameBlur}
                  className={`basic-custom-input ${
                    validationErrors.first_name ? "" : ""
                  }`}
                  autoFocus={true}
                  onKeyDown={(e) =>
                    handleKeyDown(e, input2Ref, "first_name", firstName)
                  }
                />
                {validationErrors.first_name && (
                  <p className="error-message name-align-left">
                    {validationErrors.first_name}
                  </p>
                )}
              </div>
              <div>
                <label className="basic-input-lable">Last Name</label>
                <InputField
                  ref={input2Ref}
                  type="text"
                  placeholder="Enter your last name"
                  value={lastName}
                  onChange={handleLastNameChange}
                  onBlur={handleLastNameBlur}
                  className={`basic-custom-input ${
                    validationErrors.last_name ? "" : ""
                  }`}
                  onKeyDown={(e) =>
                    handleKeyDown(e, datePickerRef, "last_name", lastName)
                  }
                />
                {validationErrors.last_name && (
                  <p className="error-message name-align-left">
                    {validationErrors.last_name}
                  </p>
                )}
              </div>
              <div className="birthday-field">
                <label className="basic-input-lable">
                  Set Your Birthday Date
                </label>
                <div className="date-picker-container">
                  <DatePicker
                    selected={birthday}
                    onChange={handleDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="mm/dd/yyyy"
                    className="date-picker-container-input"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={new Date()}
                    ref={datePickerRef}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <div onClick={openDatePicker} style={{ cursor: "pointer" }}>
                    <Calender />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {photoRestriction && (
            <div style={{ color: "red", textAlign: "center" }}>
              File is too large. Please upload a file smaller than 1 MB.
            </div>
          )}
        </div>
        <button
          className={`submit-button ${isFormValid ? "valid" : "invalid"}`}
          onClick={isFormValid ? handleBasicFunc : null}
          disabled={!isFormValid}
        >
          Continue
          <img src={nextbtn} alt="nxtbtn" className="nxtbtn" />
        </button>
      </div>
    </div>
  );
};

export default BasicDetailsPage;

import React from "react";
import "./style.css";
import Stroke1 from "../../Assets/icon/Stroke1.svg"; // Stroke image

//noInternet

function ErrorIcon({ icon }) {
  return (
    <>
      <div className="err-icon-h">
        <img src={Stroke1} alt="Stroke Layer 1" className="stroke-1-err" />
        <img src={Stroke1} alt="Stroke Layer 2" className="stroke-2-err" />
        <img src={Stroke1} alt="Stroke Layer 2" className="stroke-3-err" />
        <img src={Stroke1} alt="Stroke Layer 2" className="stroke-4-err" />
        <div className="err-icon-container">
          <img src={icon} alt="Stroke Layer 2" className="err-icon-class" />
        </div>
      </div>
    </>
  );
}

function ErrorComponent({ errHead, errSub, icon }) {
  return (
    <div className="err-in-container">
      <div className="err-icon">
        <ErrorIcon icon={icon} />
      </div>
      <div className="err-txt">
        <div className="error-head">{errHead}</div>
        <div className="error-sub">{errSub}</div>
      </div>
      <div className="error-sub">Retry</div>
    </div>
  );
}

export default ErrorComponent;

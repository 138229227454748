import React, { useState, useEffect } from "react";
import arrowRight from "../../../Assets/images/arrow-right.svg";
import { resendEmail } from "../../../Services/settingsServices";


function EmailSettingsScreen3() {
  const updatedEmail = sessionStorage.getItem("updatedEmail");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendCount, setResendCount] = useState(10);

  const resendFunction = () => {
    for (let i = 10; i > 0; i--) {
      setTimeout(() => {
        setResendCount(i);
      }, (10-i)*1000);
    }
  };
  

  const onResendEmail = async () => {
    setIsLoading(true);
    setResendDisabled(true);
    resendFunction(); // Start countdown after clicking
    try {
      const response = await resendEmail({
        email: updatedEmail,
      });
      if (response.status === "success") {
        setSuccessMessage("Email sent successfully!");
        console.log("Email sent successfully");
      }
    } catch (error) {
      setErrorMessage("Error sending email. Please try again later.");
      console.error("Error:", error);
    }

    setTimeout(() => {
      setResendDisabled(false);
      setResendCount(10);
    }, 10000);
  };

  return (
    <div className="set-phone-modal-container">
      <div>
        <div className="set-phone-modal-head">Email verification </div>
        <div className="set-phone-modal-support">
          Please check your inbox ({updatedEmail}) for the confirmation email.
        </div>
      </div>
      <div className="set-phone-int-cnt" style={{ marginBottom: "10px" }}>
        <div
          className="otp-typo-cnt"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >

          <div className="otp-get-code" >Didn't get the confirmation email?</div>

          {/* <div className="otp-resend">Resend code</div> */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button
            className="set-phone-continue-button" 
            onClick={() => {
              if(!resendDisabled) {
                onResendEmail();
              }
            }}
            style={{
              cursor: resendDisabled ? "not-allowed" : "pointer",
              opacity: resendDisabled ? "0.6" : "1",
            }}
          >
            {" "}
            Resend Email <img src={arrowRight} alt="edit" /> {resendDisabled ? `(${resendCount}s)` : ""}
          </button>
        </div>
      </div>
      {successMessage && (
        <div
          style={{
            color: "green",
            textAlign: "center",
          }}
        >
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div
          style={{
            color: "red",
            textAlign: "center",
          }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
}

export default EmailSettingsScreen3;
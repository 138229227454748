import { ReactComponent as Backbutton } from "../../../../Assets/icon/backbutton.svg";
import ReactMarkdown from "react-markdown";
import InfoTooltip from "./InfoTooltip";

export function FundamentalNeeds({ data, onBack, profilePic1, profilePic2, initials1, initials2, name1, name2, tooltip, user1Metrics, user2Metrics }) {

  const sortMetrics = (metrics) => {
    return Object.entries(metrics)
      .sort(([, a], [, b]) => b - a)
      .map(([key, value]) => ({ key, value }));
  };

  const sortedUser1Metrics = sortMetrics(user1Metrics);
  const sortedUser2Metrics = sortMetrics(user2Metrics);

  return (
    <div className="Processing-container">
      <div className="Processing-header">
        <button className="backbutton-insights" onClick={onBack}><Backbutton /> Back</button>

        <div className="Processing-txt">Fundamental Needs</div>
        <div><InfoTooltip content={tooltip} /></div>
      </div>

      <div className="insight-progress-container" style={{padding:"16px"}}>
        <div className="Fundamental-container">
          <div className="Fundamental-txt-left">
            <div className="fundamental-profile">
              {" "}
              {profilePic1 ? (
                <img
                  src={profilePic1}
                  alt={initials1 || "Profile"}
                  style={{ width: "24px", height: "24px",borderRadius:"50%" }}
                  onError={(e) => {
                    e.target.style.display = "none";
                    e.target.nextElementSibling.style.display = "flex";
                  }}
                />
              ) : null}

              {(!profilePic1 || profilePic1 === "") && (
                <div
                  className="profile-initials"
                  style={{ width: "24px", height: "24px",borderRadius:"50%",fontSize:"10px" }}
                >
                  {initials1 || ""}
                </div>
              )}

              {name1}
            </div>
            {sortedUser1Metrics.map((metric, index) => (
              <div
                className="fundamental-txt"
                key={metric.key}
                style={index === sortedUser1Metrics.length - 1 ? { color: "rgba(48, 48, 48, 0.50)" } : {}}
              >
                {metric.value}% {metric.key}
              </div>
            ))}
          </div>

          <div className="Fundamental-txt-right">
            <div className="fundamental-profile">
              {" "}
              {profilePic2 ? (
                <img
                  src={profilePic2}
                  alt={initials2 || "Profile"}
                  style={{ width: "24px", height: "24px",borderRadius:"50%" }}
                  onError={(e) => {
                    e.target.style.display = "none";
                    e.target.nextElementSibling.style.display = "flex";
                  }}
                />
              ) : null}

              {(!profilePic2 || profilePic2 === "") && (
                <div
                  className="profile-initials"
                  style={{ width: "24px", height: "24px",borderRadius:"50%",fontSize:"10px" }}
                >
                  {initials2 || ""}
                </div>
              )}

              {name2}
            </div>
            {sortedUser2Metrics.map((metric, index) => (
              <div
                className="fundamental-txt"
                key={metric.key}
                style={index === sortedUser2Metrics.length - 1 ? { color: "rgba(48, 48, 48, 0.50)" } : {}}
              >
                {metric.value}% {metric.key}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div style={{ textAlign: "left" }}>
        <ReactMarkdown
          components={{
            h1: "h3",
            h2: "h4",
          }}
        >
          {data?.replace(/\n/g, "\n\n") || ""}
        </ReactMarkdown>
      </div>
    </div>
  );
}
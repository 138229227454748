// import React, { useState,useEffect } from "react";
// import "./style.css";
// import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
// import { Close } from "@mui/icons-material";
// import { ReactComponent as Plus } from "../../Assets/icon/plus.svg";
// import { ReactComponent as Minus } from "../../Assets/icon/minus.svg";

// const AddMembersModal = ({ isAddOpen, setisAddOpen, setisInviteOpen ,memberContIncrement}) => {
//   const [memberCount, setMemberCount] = useState(1);
//   const pricePerAccount = 20;

//   const handleIncrease = () => setMemberCount(memberCount + 1);
//   const handleDecrease = () =>
//     memberCount > 1 && setMemberCount(memberCount - 1);
//   const totalPrice = memberCount * pricePerAccount;

//   const handlePurchase = () => {
//     setisAddOpen(false); // Close AddMembersModal
//     setisInviteOpen(true); // Open InvitetoAris modal
//   };

//   useEffect(() => {
//     memberContIncrement(memberCount);
//   }, [memberCount])

//   return (
//     <div>
//       {isAddOpen && (
//         <div className="membershipmodal-overlay">
//           <div className="membershipmodal-container">
//             <div className="membershipmodal-header">
//               <h2 className="membershipmodal-heading">Add More Members</h2>
//               <button
//                 className="membershipclose-btn"
//                 onClick={() => setisAddOpen(false)}
//               >
//                 <CloseIcon />
//               </button>
//             </div>
//             <div className="membershipmodal-body">
//               <div className="membership-count-container">
//                 <div className="pricing-counter-amount">
//                   <div className="pricing-member-counter">
//                     <button
//                       className="pricing-counter-button"
//                       onClick={handleDecrease}
//                     >
//                       <Minus />
//                     </button>
//                     <span className="pricing-counter-value">{memberCount}</span>
//                     <button
//                       className="pricing-counter-button"
//                       onClick={handleIncrease}
//                     >
//                       <Plus />
//                     </button>
//                   </div>
//                 </div>
//                 <div className="membership-price">
//                   $20{" "}
//                   <span className="membership-price-text">
//                     /month per account
//                   </span>
//                 </div>
//               </div>
//               <p className="membershipdescription">
//                 Empower your partner, family, friends, or teammates with Aris.
//               </p>
//               <div className="membershipmodal-list">
//                 <div className="dot">•</div>
//                 <div className="membershipmodal-list-txt">
//                   Add licenses for family, friends, or team members to thrive
//                   together.
//                 </div>
//               </div>
//               <div className="membershipmodal-list">
//                 <div className="dot">•</div>
//                 <div className="membershipmodal-list-txt">
//                   Billed on the same price and payment terms as your primary
//                   account.
//                 </div>
//               </div>
//             </div>
//             <div className="membershipmodal-footer">
//               <div className="membershiptotal-price">
//                 <span className="membership-total-price-txt">Total price:</span>
//                 <span className="membership-total-price">${totalPrice}</span>
//               </div>
//               <button
//                 className="membershippurchase-btn"
//                 onClick={handlePurchase}
//               >
//                 Purchase now
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AddMembersModal;

import React, { useState, useEffect } from "react";
import "./style.css";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { ReactComponent as Plus } from "../../Assets/icon/plus.svg";
import { ReactComponent as Minus } from "../../Assets/icon/minus.svg";

const AddMembersModal = ({
  isAddOpen,
  setisAddOpen,
  setisInviteOpen,
  memberContIncrement,
  secondary_price,
  subscriptionData
}) => {
  const [memberCount, setMemberCount] = useState(1);
  const [currentMemberIndex, setCurrentMemberIndex] = useState(0);
  const [inviteComplete, setInviteComplete] = useState(false);
  const pricePerAccount = secondary_price;

  const handleIncrease = () => setMemberCount(memberCount + 1);
  const handleDecrease = () =>
    memberCount > 1 && setMemberCount(memberCount - 1);
  const totalPrice = memberCount * pricePerAccount;

  // Reset flow when modal is opened/closed
  useEffect(() => {
    if (isAddOpen) {
      setCurrentMemberIndex(0);
      setInviteComplete(false);
    }
  }, [isAddOpen]);

  // Pass member count to parent component
  useEffect(() => {
    memberContIncrement(memberCount);
  }, [memberCount, memberContIncrement]);

  // This function starts the invitation flow
  const handlePurchase = () => {
    setisAddOpen(false); // Close AddMembersModal
    startInviteFlow();
  };

  // Start the invite flow for the first member
  const startInviteFlow = () => {
    setCurrentMemberIndex(0);
    setisInviteOpen(true); // Open InvitetoAris modal for the first member
  };

  // This function should be called when each invite is completed
  // It can be passed to InvitetoAris as a callback
  const handleInviteComplete = () => {
    // Move to the next member
    const nextIndex = currentMemberIndex + 1;
    setCurrentMemberIndex(nextIndex);

    if (nextIndex < memberCount) {
      // There are more members to invite, keep the invite modal open
      // The InvitetoAris component should refresh with new empty fields
      // You'll need to modify InvitetoAris to accept the current index
      setisInviteOpen(true);
    } else {
      // All members have been invited
      setisInviteOpen(false);
      setInviteComplete(true);
      // You can trigger any final action here (e.g., show a success message)
    }
  };

  console.log("subscriptionData", subscriptionData);

  return (
    <div>
      {isAddOpen && (
        <div className="membershipmodal-overlay">
          <div className="membershipmodal-container">
            <div className="membershipmodal-header">
              <h2 className="membershipmodal-heading">Add More Members</h2>
              <button
                className="membershipclose-btn"
                onClick={() => setisAddOpen(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="membershipmodal-body">
              <div className="membership-count-container">
                <div className="pricing-counter-amount">
                  <div className="pricing-member-counter">
                    <button
                      className="pricing-counter-button"
                      onClick={handleDecrease}
                    >
                      <Minus />
                    </button>
                    <span className="pricing-counter-value">{memberCount}</span>
                    <button
                      className="pricing-counter-button"
                      onClick={handleIncrease}
                    >
                      <Plus />
                    </button>
                  </div>
                </div>
                <div className="membership-price">
                  ${secondary_price}{" "}
                  <span className="membership-price-text-1">
                    {`/${subscriptionData.membership_type} per account`}
                  </span>
                </div>
              </div>
              <p className="membershipdescription">
                Empower your partner, family, friends, or teammates with Aris.
              </p>
              <div className="membershipmodal-list">
                <div className="dot">•</div>
                <div className="membershipmodal-list-txt">
                  Add licenses for family, friends, or team members to thrive
                  together.
                </div>
              </div>
              <div className="membershipmodal-list">
                <div className="dot">•</div>
                <div className="membershipmodal-list-txt">
                  Billed on the same price and payment terms as your primary
                  account.
                </div>
              </div>
            </div>
            <div className="membershipmodal-footer">
              <div className="membershiptotal-price">
                <span className="membership-total-price-txt">Total price:</span>
                <span className="membership-total-price">${totalPrice}</span>
              </div>
              <button
                className="membershippurchase-btn"
                onClick={handlePurchase}
              >
                Purchase now
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddMembersModal;

import axios from "axios"
import { axiosAssessmentInstance,axiosArisChatInstance } from "../AxiosConfiguration"

export const getOpenAIKey = async () => {
    try {
        const response = await axios.post('https://lab-api.aris.ai/ARIS/encrypt/voice', {} , {
            headers: {
                'Authorization': 'Bearer ALqt8T93LBDcsDfFBfTI79V2ADMr5LUJHu7rPFsOgmBmyRJQf9UPblA2W40Rii2awFEagGtOFid74TuXbJrJSo8LCsbelVThaX81jzZArDSa4Ol2wjUirUM'
            }
        })
        return response.data.encrypted_key
    } catch (error) {
        console.log(error)
    }
}

export const getSessions = async () => {
    try {
        const response = await axiosAssessmentInstance.get('/api/coaching/get_sessions');
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const getInstructions = async () => {
    try {
        const response = await axiosAssessmentInstance.post('/api/coaching/instructions');
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const startCoach = async (session_id) => {
    try {
        const response = await axiosAssessmentInstance.post(`/api/coaching/start_coach?session_id=${session_id}`);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const getChat = async (data) => {
    try {
        const response = await axiosAssessmentInstance.put('/api/coaching/get_chat', data);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const scheduleSession = async (data) => {
    try {
        const response = await axiosAssessmentInstance.post('/api/coaching/schedule/coach', data);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const cancelSession = async (session_id) => {
    try {
        const response = await axiosAssessmentInstance.post(`/api/coaching/cancel_session?session_id=${session_id}`);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const rescheduleSession = async (data) => {
    try{
        const response = await axiosAssessmentInstance.put('/api/coaching/update_coach_schedule', data);
        return response.data
    } catch (error) {
        return error
    }
}

export const fetchInstructions = async (type) => {
    try {
        const response = await axiosAssessmentInstance.get(`/api/coaching/instructions?type=${type}`);
        return response.data
    } catch (error) {
        if(error.status === 500) {
            return {
                message: "Server error. Please try again later."
            }
        } else {
            return {
                message: "Something went wrong. Please try again later."
            }
        }
    }
}

export const endSession = async (session_id, status, time) => {
    try {
        const response = await axiosAssessmentInstance.post(`/api/coaching/end_session`, {} , {
            params: {
                session_id: session_id,
                time: time,
                status: status
            }
        });
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const fetchHistory = async () => {
    try {
        const response = await axiosAssessmentInstance.get('/api/coaching/coaching_history');
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const getHabits = async () => {
    try {
        const response = await axiosAssessmentInstance.get('/api/coaching/get_habits');
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const updateHabits = async () => {
    try {
        const response = await axiosAssessmentInstance.get('/api/coaching/update_habits');
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const portaitData = async (userID,query) => {
    try {
       
        const response = await axiosArisChatInstance.post('/ARIS/get_portrait_data',{user_ids:[userID],query:query});
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const pastHistory = async (userID,query) => {
    try {
        const response = await axiosArisChatInstance.post('/ARIS/get_past_history_data',{user_id:userID,query:query});
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const resumeCoaching = async () => {
    try {
        const response = await axiosAssessmentInstance.get('/api/coaching/resume/coach');
        return response.data
    } catch (error) {
        console.error(error);
    }
}
import React, { useRef } from "react";
import UserChatComponent from "./UserChatComponent";
import AiChatComponent from "./AiChatComponent";

function ChatComponent({ chatArray, onOptionSelected, isAiLoading }) {
  const endRef = useRef(null);
  console.log(chatArray, "chatArray");
  setTimeout(() => {
    if (endRef.current) endRef.current.scrollIntoView({ behavior: "smooth" });
  }, 0);
  return (
    <div className="chat-container">
      {chatArray?.map((chat, index) => {
        return (
          <div
            key={index + 1}
            className={chat?.role === "user" ? "right" : "left"}
          >
            {chat?.role === "user" ? (
              <UserChatComponent chat={chat} />
            ) : (
              <AiChatComponent
                chat={chat}
                isSurvey={chat?.isSurvey}
                onOptionSelected={onOptionSelected}
                isAiLoading={isAiLoading}
              />
            )}
          </div>
        );
      })}
      <div ref={endRef} style={{ height: 0 }} key={"current"}></div>
    </div>
  );
}

export default ChatComponent;

import { axiosAssessmentInstance } from "../AxiosConfiguration";

export const fetchTopicData = async (category, screen) => {
  try {
    const response = await axiosAssessmentInstance.get(
      `/api/user/portrait/story`,
      {
        params: {
          category: category,
          screen: screen,
        },
      }
    );
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const fetchProgressData = async () => {
  try {
    const response = await axiosAssessmentInstance.get(
      `/api/user/get/user/story/progress`
    );
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

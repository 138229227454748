import { axiosAssessmentInstance } from "../AxiosConfiguration";
import Cookies from "js-cookie";

export const getNotificationWebhook = async () => {
  try {
    const response = await axiosAssessmentInstance.get(
      `/api/notification/get`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: {
          webhook: true,
        },
      }
    );
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during webhook API call:", error);
    return error;
  }
};

export const getNotification = async ({ is_read, page = 1 }) => {
  if (Cookies.get("idtoken")) {
    try {
      const response = await axiosAssessmentInstance.get(
        `/api/notification/in-app`,
        {
          params: {
            is_read,
            page,
          },
        }
      );
      return response?.data?.payload;
    } catch (error) {
      console.error("Error during API call:", error);
      throw error;
    }
  }
};

///api/notification/mark/read
export const markAsReadNotification = async (id) => {
  try {
    const response = await axiosAssessmentInstance.post(
      `/api/notification/mark/read`,
      { notification_id: id }
    );
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

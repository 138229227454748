import React, { memo, useEffect, useRef, useState } from "react";
import { ReactComponent as Plus } from "../../Assets/icon/plus.svg";
import { ReactComponent as Minus } from "../../Assets/icon/minus.svg";
import rightarrow from "../../Assets/images/arrow-right.png";
import PaymentFailure from "../../Assets/icon/paymentFailure.svg";
import leftarrow from "../../Assets/images/arrow-left.png";
import Vipcodepopup from "./Vipcodepopup";
import { ReactComponent as Point } from "../../Assets/icon/li_dot.svg";
import { ReactComponent as Info } from "../../Assets/icon/Info.svg";
import CoachingModal from "./CoachingModal.jsx";
import NavigationButton from "../../Components/NavigationButton";
import { ReactComponent as UpArrow } from "../../Assets/icon/chevron-up.svg";
import { ReactComponent as DownArrow } from "../../Assets/icon/chevron-down.svg";
import { ReactComponent as InfoRef } from "../../Assets/icon/Info-ref.svg";
import "./style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import Invitetoaris from "../RelationshipProfile/Invitetoaris.jsx";
import SearchableDropdown from "../../Components/SearchableDropdown/index.jsx";
import {
  getPricing,
  getProducts,
  startSubscription,
} from "../../Services/subscription.js";
import Allset from "../../Components/AllsetComponent/index.jsx";
import MainProduct from "./MainProduct.jsx";
import FaithContainer from "./Faith.jsx";
import ContentLoader from "../LoadingPage/ContentLoader.jsx";

const SubscriptionPlan = ({ onNext, onBack }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fromPayment = searchParams.get("stripeType");
  const [showFaith, setShowFaith] = useState(false);
  const [selectedFaithOption, setSelectedFaithOption] = useState("primary");
  const [primaryChecked, setPrimaryChecked] = useState(true);
  const [allAccountsChecked, setAllAccountsChecked] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setError] = useState(false);
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [products, setProducts] = useState([]);
  const [productLoading, setproductLoading] = useState(true);
  const [showPaymentPopUp, setShowPaymentPopUp] = useState(fromPayment);
  const[issending, setIssending]= useState(false);

  // Use both state and ref for selected price
  const [selectedPrice, setSelectedPrice] = useState(null);
  const selectedPriceRef = useRef(null);
  const [listedMembers, setlistedMaembers] = useState(0);
  const [selectedFaith, setselectedFaith] = useState("");
  const [selectedFaithID, setselectedFaithID] = useState("");
  const [selectedAddonUser, setselectedAddonUser] = useState([]);
  const [isFaithToSubUser, setisFaithToSubUser] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
    setError(false);
    setIsValidCoupon(false);
  };

  const handleInviteSubmit = (data) => {
    console.log(data);
    // Handle submission
    setShowInvite(true);
  };

  const handleApplyCoupon = () => {
    if (couponCode === "ARIS-2025") {
      setIsValidCoupon(true);
      setError(false);
    } else {
      setError(true);
      setIsValidCoupon(false);
    }
  };

  const handleOptionClick = (option) => {
    if (option === "primary") {
      setPrimaryChecked(true);
      setAllAccountsChecked(false);
    } else {
      setPrimaryChecked(false);
      setAllAccountsChecked(true);
    }
  };

  const rearrangePlans = (products) => {
    return products.sort((a, b) => {
      const getOrder = (planType) => {
        if (planType === "main") return 1;
        if (planType === "addon") return 2;
        return 3;
      };

      const planA = a.metadata?.plan_type || "";
      const planB = b.metadata?.plan_type || "";

      return getOrder(planA) - getOrder(planB);
    });
  };

  const fetchProducts = async () => {
    try {
      const res = await getProducts();
      console.log(res, "pre111");
      const pricingPromises = res?.payload?.map(async (product, index) => {
        console.log(product?.metadata?.product_type, index);
        if (
          product?.metadata?.product_type === "all" ||
          product?.metadata?.product_type === "pre"
        ) {
          const pricingResponse = await getPricing(product?.id);
          return {
            ...product,
            pricingDetails: [...pricingResponse?.payload],
          };
        }
      });
      const resolvedPricingData = await Promise.all(pricingPromises);
      console.log(resolvedPricingData, "resolvedPricingData");
      setProducts(rearrangePlans(resolvedPricingData));
      setproductLoading(false);
    } catch (err) {
      console.error(err, "product error");
      setproductLoading(false);
    }
  };

  const handlePriceSelect = (price) => {
    // Update both state and ref
    setSelectedPrice(price);
    selectedPriceRef.current = price;
  };
  const onSubScriptionClick = () => {
    const currentPrice = selectedPriceRef.current;
    if(issending)return;
    if (!currentPrice) return;

    const subscription_items = [
      {
        price_id: currentPrice.id,
        product_id: products?.find(
          (product) => product?.metadata?.plan_type === "main"
        )?.id,
      },
    ];
    let licensed_users = [];
    let faithProductSelectedID = products?.find(
      (product) => product?.metadata?.plan_type === "addon"
    )?.id;
    let faithDetails = {
      price_id: selectedFaithID?.id,
      product_id: faithProductSelectedID,
      // value: selectedFaith,
    };
    if (listedMembers > 0) {
      subscription_items.push({
        price_id:
          currentPrice?.recurring?.interval === "year"
            ? products
                ?.find((product) => product?.metadata?.plan_type === "main")
                ?.pricingDetails?.find(
                  (prod) =>
                    prod?.metadata?.price_type === "sub-users" &&
                    prod?.recurring?.interval === "year"
                )?.id
            : products
                ?.find((product) => product?.metadata?.plan_type === "main")
                ?.pricingDetails?.find(
                  (prod) =>
                    prod?.metadata?.price_type === "sub-users" &&
                    prod?.recurring?.interval === "month"
                )?.id,
        product_id: products?.find(
          (product) => product?.metadata?.plan_type === "main"
        )?.id,
      });

      licensed_users = selectedAddonUser?.map((phone) => {
        return {
          email: phone?.email,
          mobile: phone?.mobile,
          addon_features:
            isFaithToSubUser && selectedFaith
              ? [
                  {
                    price_id: selectedFaithID?.id,
                    product_id: faithProductSelectedID,
                    value: "",
                  },
                ]
              : [],
        };
      });
    }

    if (selectedFaith !== "") {
      subscription_items.push(faithDetails);
      //
    }

    console.log(subscription_items, "subscription_items");

    let reqBody = {
      subscription_items: subscription_items,

      // licensed_users_count: listedMembers,
      licensed_users: licensed_users,
      payment_method: "card",
    };
    if (selectedFaith !== "") {
      reqBody.addon_features_user = [
        {
          price_id: selectedFaithID?.id,
          product_id: products?.find(
            (product) => product?.metadata?.plan_type === "addon"
          )?.id,
          value: selectedFaith,
        },
      ];
    }

    // console.log(reqBody, "reqBody");
    setIssending(true);
    startSubscription(reqBody)
      .then((res) => {
        window.location.href = res?.payload?.checkout_url;
      })
      .catch((err) => console.log(err, "error")).finally(setIssending(false));
  };
  const setOptionSelected = (e) => {
    console.log("test1111", e);
    setselectedFaith(e);
  };

  const closeFunctionCall = () => {
    if (showPaymentPopUp === "confirm") {
      navigate("/onboarding/chat");
      // onNext();
    } else {
      setShowPaymentPopUp("");
    }
  };

  const memberContIncrement = (t) => {
    setlistedMaembers(t);
  };

  const selectedFaithPriceId = (id) => {
    let filter = id?.find(
      (prod) => prod?.recurring?.interval === selectedPrice?.recurring?.interval
    );
    setselectedFaithID(filter);
  };

  const onEmailPhoneSubmit = (e) => {
    setselectedAddonUser(e);
    console.log("phoneEmail", e);
  };

  const faithToAllAddedUser = (value) => {
    setisFaithToSubUser(value);
  };

  console.log(
    // products,
    // selectedFaith,
    // selectedFaithID,
    // selectedPrice?.recurring?.interval,
    // selectedFaithID,
    selectedAddonUser,
    "productsList"
  );

  return (
    <div className="Fullpage-container-email">
      {productLoading ? (
        <ContentLoader />
      ) : (
        <div className="email-container">
          <div className="pricing-header">
            <NavigationButton
              onClick={onBack}
              direction="left"
              label="Back"
              className="back-button"
            />
            <h1>Build Your Personal Aris Plan</h1>
            <p>
              Experience everything that Aris has to offer for 3 days before
              your subscription begins.
            </p>
          </div>

          {products?.map((product) => {
            if (product?.metadata?.plan_type === "main") {
              return (
                <MainProduct
                  key={product.id}
                  product={product}
                  onPriceSelect={handlePriceSelect}
                  handleInviteSubmit={handleInviteSubmit}
                  memberContIncrement={memberContIncrement}
                />
              );
            } else if (product?.metadata?.plan_type === "addon") {
              return (
                <FaithContainer
                  key={product.id}
                  product={product}
                  selectedFaith={selectedFaith}
                  setOptionSelected={setOptionSelected}
                  selectedPricingReccursion={selectedPrice?.recurring?.interval}
                  selectedFaithPriceId={selectedFaithPriceId}
                  faithToAllAddedUser={faithToAllAddedUser}
                />
              );
            }
            return null;
          })}

          <h3 className="pricing-referalcodeh3">
            Do you have a referral code?
          </h3>
          <div
            className={`pricing-referalcode-container ${
              couponError ? "error-border" : ""
            }`}
          >
            <div className="pricing-referalcode-input">
              <input
                type="text"
                value={couponCode}
                onChange={handleCouponChange}
                placeholder="Enter your code here"
                className="pricing-referalcode-p"
              />
              <button
                className="pricing-apply-button"
                style={{ color: couponCode ? "#000" : "#666" }}
                onClick={handleApplyCoupon}
              >
                Apply
              </button>
            </div>
          </div>

          <div className="pricing-referalcode-info">
            {!couponError && <InfoRef />}
            <p className="pricing-referalcode-p" style={{ fontSize: "12px" }}>
              {couponError
                ? 'This code is only valid for the "Pay upfront" option.'
                : "If you have a code, enter it now to get started with your special offer"}
            </p>
          </div>

          {isValidCoupon && (
            <div>
              <div
                className="pricing-total-price"
                style={{ paddingBottom: "0px" }}
              >
                <span className="pricing-total-price-text-offer">
                  Aris (Pay upfront 20%)
                </span>
                <span className="pricing-total-price-amount">$240</span>
              </div>
              <div className="pricing-total-price">
                <span className="pricing-total-price-text-offer">
                  Referral code discount (10%):
                </span>
                <span className="pricing-total-price-amount">-$24</span>
              </div>
              <hr
                style={{
                  height: "1px",
                  background: "var(--gray-light-hover, #E4E4E7)",
                  border: "none",
                }}
              />
            </div>
          )}

          <div className="pricing-total-price">
            <span className="pricing-total-price-text">Total price:</span>
            <span className="pricing-total-price-amount">
              $
              {(selectedPrice?.unit_amount || 0) / 100 +
                (listedMembers > 0
                  ? selectedPrice?.recurring?.interval === "year"
                    ? products
                        ?.find(
                          (product) => product?.metadata?.plan_type === "main"
                        )
                        ?.pricingDetails?.find(
                          (prod) =>
                            prod?.metadata?.price_type === "sub-users" &&
                            prod?.recurring?.interval === "year"
                        )?.unit_amount / 100
                    : products
                        ?.find(
                          (product) => product?.metadata?.plan_type === "main"
                        )
                        ?.pricingDetails?.find(
                          (prod) =>
                            prod?.metadata?.price_type === "sub-users" &&
                            prod?.recurring?.interval === "month"
                        )?.unit_amount / 100
                  : 0) +
                (selectedFaith !== "" ? selectedFaithID?.unit_amount / 100 : 0)}
            </span>
          </div>

          <button
            className="pricing-subscribe-button"
            onClick={onSubScriptionClick}
          >
            <span>Try free and subscribe</span>
            <img src={rightarrow} alt="rightarrow" width="24px" height="24px" />
          </button>

          <div>
            <p className="pricing-para-footer">
              Your subscription will automatically start after your 3 day trial,
              unless you cancel.
            </p>
          </div>
        </div>
      )}

      {showInvite && (
        <Invitetoaris
          onClose={() => setShowInvite(false)}
          onSubmit={handleInviteSubmit}
          onEmailPhoneSubmit={onEmailPhoneSubmit}
          listedMembers={listedMembers}
          isOnBoarding={true}
        />
      )}

      {(showPaymentPopUp === "confirm" || showPaymentPopUp === "cancel") && (
        <Allset // heading={"Payment confirmed"} // subText={
          // "Your 1-year Aris membership begins today. Thanks for joining us." // } closeModal={closeFunctionCall}
          isFailure={showPaymentPopUp === "confirm" ? false : true}
          heading={
            showPaymentPopUp === "confirm"
              ? "Payment confirmed"
              : "We couldn't process your payment."
          }
          subText={
            showPaymentPopUp === "confirm"
              ? "Your 1-year Aris membership begins today. Thanks for joining us."
              : "Please check your payment information and try again."
          }
          buttonText={
            showPaymentPopUp === "confirm" ? "Done" : "Update payment method"
          }
          buttonClick={closeFunctionCall}
          closeModal={closeFunctionCall}
        />
      )}
    </div>
  );
};

export default SubscriptionPlan;

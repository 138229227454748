// import { jwtDecode } from "jwt-decode";
import {
  axiosArisChatInstance,
  axiosChatInstance,
} from "../AxiosConfiguration";
import Cookies from "js-cookie";

let isFetching = false;

export const chatArisApi = async (
  user_id,
  query,
  chatDataHistory,
  instruction,
  relationData
) => {
  try {
    //init Params
    const params = {
      query: query,
      user_id: user_id,
      chat_history: chatDataHistory,
      relations: relationData,
      instruction: instruction,
    };

    const response = await axiosArisChatInstance.post(
      "ARIS/coaching/chat",
      params
    );
    //return API response
    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const chatArisApiRelationShip = async (params) => {
  try {
    const response = await axiosArisChatInstance.post(
      "ARIS/relationship/chat",
      params
    );
    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const chatArisApiInitiate = async (userId) => {
  try {
    const response = await axiosArisChatInstance.post(
      "/ARIS/coaching/instruction/chat",
      {
        user_id: userId,
        callback_url:
          process.env.REACT_APP_BASE_URL_ASSESSMENT +
          "/api/status/fetch/user/portrait",
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const initiateChatSession = async (
  // title,
  topic,
  related_relation_ids
) => {
  if (isFetching) return;
  isFetching = true;
  try {
    //init Params
    const params = {
      user_id: Cookies.get("localId"),
      topic: topic,
    };

    // if (title) params.title = title;
    if (related_relation_ids && related_relation_ids?.length !== 0)
      params.related_relation_ids = related_relation_ids;
    //api call to send chat data
    const response = await axiosChatInstance.post(
      "api/chat/create/record",
      params
    );
    //return API response
    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  } finally {
    isFetching = false; // Remove the request from the set
  }
};

export const createMsg = async (chat_session_id, msg_body) => {
  console.log(chat_session_id, "chat_session_id");
  try {
    //init Params
    const params = {
      user_id: Cookies.get("localId"),
      chat_session_id: chat_session_id, // chat_session_id,
      msg_body: msg_body,
    };

    //api call to send chat data
    const response = await axiosChatInstance.post(
      "api/chat/create/message",
      params
    );
    //return API response
    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const getChatSessionList = async (fetch = "all", limit = 20) => {
  try {
    let user_id = Cookies.get("localId");
    //api call to send chat data
    const response = await axiosChatInstance.get(
      `api/chat/history/records/details?user_id=${user_id}&fetch=${fetch}`
    );
    //&limit=${limit}
    //return API response
    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const getChatHistory = async (
  chat_session_id,
  fetch = "all",
  limit = 20
) => {
  try {
    let user_id = Cookies.get("localId");
    //api call to send chat data
    const response = await axiosChatInstance.get(
      `api/chat/history?user_id=${user_id}&chat_session_id=${chat_session_id}&fetch=${fetch}`
    );
    //&limit=${limit}
    //return API response
    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const getChatinitialVoice = async () => {
  try {
    const response = await axiosChatInstance.get(`/api/static/initial_voice`);
    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
};

export const onChatEnd = async (chat_session_id) => {
  try {
    const response = await axiosChatInstance.delete(
      `/api/chat/end/chat?chat_session_id=${chat_session_id}`
    );

    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
};

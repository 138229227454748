import React from "react";
import "./style.css";
import PercentailWithHead from "../PotraitsPercentailWithHead/PercentailWithHead";
function PotraitsSummaryWithList({
  isList = true,
  percentail,
  ptxt,
  summary,
  summary2,
  list = "",
}) {
  return (
    <div className="PotraitsSummaryWithList">
      <PercentailWithHead percentail={percentail} txt={ptxt} />
      <div className="potraits-separater-line"></div>

      {/* <div className="potraits-sub-txt txt-left-alg">{summary}</div>
      <div className="potraits-sub-txt txt-left-alg">{summary}</div> */}

      {/* {!isList && ( */}
      <div>
        {list?.split("\n")?.map((_l, index) => (
          <div key={index} className="bullet-line-container">
            <div>•</div>
     <div className="processing-bullet-txt">  {_l}</div>
           </div>
        ))}
      </div>
      {/* )} */}
    </div>
  );
}

export default PotraitsSummaryWithList;

import React, { useEffect, useState } from "react";
import arrowLeft from "../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../Providers/HeaderContextProvider";
import { ReactComponent as Email } from "../../Assets/images/mail.svg";
import { ReactComponent as Phone } from "../../Assets/images/phone.svg";
import tickIcon from "../../Assets/icon/tick-icon.svg";
import GradientBackground from "../../Components/AddRelationshipHeaderGradient";

import "./style.css";
import { sendMessage } from "../../Services/customerSupport";
function CustomerSupport() {
  const navigate = useNavigate();
  const { setHeaderContent } = useHeader();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [resMessage, setresMessage] = useState("");

  const [msg, setmsg] = useState("");
  useEffect(() => {
    setHeaderContent(false);
  }, []);

  useEffect(() => {
    if (showSuccessMessage === true) {
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
    }
  }, [showSuccessMessage]);

  const onMsgSend = async () => {
    await sendMessage(msg).then((res) => {
      if (res?.status === "success") {
        setresMessage(res?.message);
        setShowSuccessMessage(true);
        setmsg("");
      }
    });
  };

  const txtChange = (e) => {
    setmsg(e.target.value);
  };
  return (
    <div className="cust-sup-hero">
      <GradientBackground>
        <header className="termsofuse-header">
          <div className="termsofuse-gradient-background">
            <div className="termsofuse-nav-container">
              <div
                className="termsofuse-logo-container"
                onClick={() => navigate(-1)}
              >
                <img
                  src={arrowLeft}
                  alt="Back"
                  className="termsofuse-back-button"
                />
              </div>
              <h1 className="termsofuse-title customer-support-title">Customer Support</h1>
              <div className="termsofuse-space"></div>
            </div>
          </div>
        </header>
        <main className="customer-support-main">
          <section className="cs-hero-container">
            <div className="cs-container">
              <heading className="cs-heading">Contact us at:</heading>
              <div className="cs-logo-txt">
                <Email />{" "}
                <div className="cs-txt-1 cs-mail-clr">support@aris.ai</div>
              </div>
              <div className="cs-logo-txt">
                <Phone /> <div className="cs-txt-1">1-855-755-SURE (7873)</div>
              </div>
            </div>
          </section>
          <div className="cs-divider">
            <span className="divider-line"></span>
            <span className="divider-text">or</span>
            <span className="divider-line"></span>
          </div>
          <section className="cs-hero-container">
            <div className="cs-container">
              <div className="cs-support-container">
                <heading className="cs-heading cs-support-heading">Have questions?</heading>
                <div className="cs-support-txt">
                  Let us know and we will touch as soon as possible.
                </div>
                <div className="mobile-cs-support-text">
                  Have questions? Let us know and we will touch as soon as possible.
                </div>
              </div>
              <div className="cs-input-container">
                <textarea
                  placeholder="Message"
                  className="cs-txt-area"
                  onChange={txtChange}
                  value={msg}
                />
                <div className="cs-assist-txt">
                  This is a hint text to help user.
                </div>
              </div>
            </div>
          </section>
        </main>

        <section className="cs-hero-container cs-btn-cntr">
          <button className="cs-submit-button" onClick={onMsgSend}>
            Send Message
          </button>
        </section>
        {showSuccessMessage && (
          <div className="success-message-box">
            <img src={tickIcon} alt="success" />
            {resMessage}
          </div>
        )}
        <div className="end-cls"></div>
      </GradientBackground>
    </div>
  );
}

export default CustomerSupport;

// import { jwtDecode } from "jwt-decode";
import { axiosProfileInstance } from "../AxiosConfiguration";

export const getOnBoardingPageNo = async () => {
  try {
    const response = await axiosProfileInstance.get(
      "/api/status/onboarding-progress"
    );
    if (response.data && response.data.status === "success") {
      return response.data.payload; // Return the payload from the response
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

export const updateOnBoardingPageNo = async (num) => {
  try {
    const response = await axiosProfileInstance.post(
      "/api/status/onboarding-progress/update",
      { screen_num: num }
    );
    if (response.data && response.data.status === "success") {
      return response.data.payload;
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error fetching user profile summary:", error);
    throw error;
  }
};

import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { ReactComponent as Appicon } from "../../Assets/icon/appicon5.svg";
import "./style.css";

const MicPermissionModal = ({ onPermissionDenied }) => {
  const [showGuidanceText, setShowGuidanceText] = useState(false);

  const checkMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      window.location.reload();
      localStorage.setItem("isMicAllowed1", true);
    } catch (error) {
      setShowGuidanceText(true);
    }
  };

  return (
    <div className="mic-permission-modal-backdrop">
      <div className="mic-permission-modal">
        <div className="mic-permission-content">
          <div className="mic-permission-content-header">
            <div>
              <Appicon />
            </div>
            <div>
              <h2>"Aris" Would Like to Access The Microphone</h2>
              <p>
                Enable microphone access to record audio and to talk to Aris.
              </p>
              {showGuidanceText && (
                <p className="guidance-text">
                  Please click "OK" after enabling the microphone
                </p>
              )}
            </div>
          </div>
          <div className="mic-permission-buttons-container">
            <button
              className="mic-permission-button"
              onClick={checkMicrophoneAccess}
            >
              OK
            </button>
            <button
              className="mic-permission-button"
              onClick={() => {
                onPermissionDenied();
              }}
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MicPermissionModal;

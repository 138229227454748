import React from "react";
import "./style.css";
import chatLoading from "../../Assets/loaders/sphere.webm";

function LoadingPage() {
  return (
    <div className="loading-page-container">
      {" "}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="relationship-gif"
        src={
          "https://aristotle-dev.s3.us-east-1.amazonaws.com/static/Sphere%20AI_Responding.webm?response-content-disposition=inline&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEL3%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIAdC%2BZdaGHShWxJFfojJEc4ayU0gACWfpqcY378LAjRdAiBMDrAfM5BKGzfh8nX9q4SLEGyZhk%2B1PPgKiqjtXi5wySreAwhGEAAaDDk3NTA1MDE1MzQzNiIMpH0P1vJnE9fcM50fKrsDxGvBpBUqzIIGO4TRVVpFqUtqAKWrINJzOmOIVKg6%2BDbFyeVmQ9KVLVW79QoqPFcMbmpxTYger3DzeZ8Vpg0U3kT08GbcC4QcyQLHUQ0yJGpjPiAnvvJmuEcrYKeBSzwF4o8%2Fb%2B%2FiCM1tq4Gk99N3Yz%2F8AE28m88BbdMmjfv1xAYuZwykBO2mpoV0T8R1aiOCkwR7hU8zQcQu3QCV7NUyYFbq8BTlu%2BoFFG9NsQZC8J49IKDoIhHRfwRrIEIRnfdynL1%2Fpfb49j8dFQ7ur6v2mlOxtZtJ%2FzwmpXypXbJBbHzkP%2FEibi%2BvVBznICqkP2M8owDydPkYuwBH4mqi89je3LAl7lwvM6j2PF%2BEUZ%2BomNsPBz6PGqWKceH%2BFCVeTL9TcdONBwTmiuYmycr2RJbBpjpuYhndvdYhRW7kjyanxB%2BdyRjw2HJmMvjd4QEF7Eft%2BlDAxbaPedEK3%2FqoApMayL4u5aHta9FZ6ZOhBQNV2HjyjmO4lLKexqVrsZ1lekGdqN4p1jhTwGoiKieNfkRHFFrGbf7ALM%2BTS6cxKot5TNK2sPDpmGKPNYkXSdyM3pqARAZWKLssDh%2BCsg4wh5OyuQY65QIRV4ShbdgufnHs3jLaLh65iXTwylRucSYE5E4s%2FHzj2F48bT240lmlrhrxDZeSJTnHjrEVQA4WxGq3OAJ6yfHExtSRSQP7PjxTm7lV4EO3iyImwyDm4eFe%2B403kbsKMhkl7tG%2FytemM%2BBF%2BOB44NB%2BsY%2BJgw6tEo6WWON%2F3FoNwoIJ44LURl0BxZqGPOokZ7Jpr6i0FxUhplBvrhoTHImscXXJ7OjNbUn5M%2FIJ3njrhrzZa%2FXVZKRrDxTn7sEh1y2vYhyv2dn4zQhhMBiCbpw69KlalvwIHFrrBpsh7DADhappAx7kVRVaG53xYB%2FSJpDwc6jQOJj3IJb3WVD3FKinwXgvSq1Spgcg1KlJ%2B4HHtyDv8FSKgfQ%2Fc6yAt2FN1J3Wyrh0U9vkbLZDDwQ3dCQ6RH9o0m41YFOgLX8nURmTR25YrWKXkc4q%2BNf%2BU6fkal3b0YdRlICdf0e8TWNIRzNIg6GGVyM%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA6GBMETXOJMAK2CTL%2F20241107%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241107T122155Z&X-Amz-Expires=43200&X-Amz-SignedHeaders=host&X-Amz-Signature=5f4420737f2fff390eb740286ec5afec8d01a39f9f35eb440f52935a5bf5b7a7"
        }
      />
    </div>
  );
}

export default LoadingPage;

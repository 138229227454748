import React from "react";

function Personality12() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 107"
      fill="none"
    >
      <g clip-path="url(#clip0_2161_30209)">
        <path
          d="M56.7188 33.7374L104.718 2.45557L103.527 91.3624L9.67969 96.6849L56.7188 33.7374Z"
          fill="#4FE3C9"
        />
        <path
          d="M56.7188 33.7374L104.718 2.45557M56.7188 33.7374L9.67969 96.6849M56.7188 33.7374L103.527 91.3624M104.718 2.45557L103.527 91.3624M104.718 2.45557L9.67969 96.6849M103.527 91.3624L9.67969 96.6849"
          stroke="white"
          stroke-opacity="0.7"
          stroke-width="0.211394"
          stroke-miterlimit="10"
        />
        <g filter="url(#filter0_f_2161_30209)">
          <path
            d="M24.1044 71.4756L26.3639 78.4269H33.6756L27.7603 82.7231L30.0198 89.6744L24.1044 85.3782L18.1891 89.6744L20.4485 82.7231L14.5332 78.4269H21.845L24.1044 71.4756Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter1_f_2161_30209)">
          <path
            d="M63.7362 32.3809L68.2551 46.2835H82.8786L71.048 54.8758L75.5669 68.7785L63.7362 60.1861L51.9055 68.7785L56.4244 54.8758L44.5938 46.2835H59.2173L63.7362 32.3809Z"
            fill="white"
            fill-opacity="0.4"
          />
        </g>
        <g filter="url(#filter2_f_2161_30209)">
          <path
            d="M94.4257 11.7563L97.151 20.1525H105.97L98.8354 25.3415L101.561 33.7377L94.4257 28.5486L87.2906 33.7377L90.0159 25.3415L82.8809 20.1525H91.7003L94.4257 11.7563Z"
            fill="white"
          />
        </g>
        <path
          d="M103.693 91.1978L104.719 2.38721L9.07422 96.4143L103.693 91.1978Z"
          fill="#45D9BF"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30209"
          x="6.84796"
          y="63.7903"
          width="34.5131"
          height="33.5692"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30209"
          />
        </filter>
        <filter
          id="filter1_f_2161_30209"
          x="36.9085"
          y="24.6956"
          width="53.6557"
          height="51.768"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30209"
          />
        </filter>
        <filter
          id="filter2_f_2161_30209"
          x="75.1956"
          y="4.0711"
          width="38.4603"
          height="37.3519"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30209"
          />
        </filter>
        <clipPath id="clip0_2161_30209">
          <rect
            width="105.697"
            height="105.697"
            fill="white"
            transform="translate(0.779297 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Personality12;

import React, { useState } from "react";
import { X } from "lucide-react";
import rightIcon from "../../Assets/icon/chevron-right.svg";
import { useNavigate } from "react-router-dom";
import AdviceInsight from "../../Pages/RelationShip/RelationShipChatScreen/RelationshipAdiveInsight";
import Editsentreq from "./Editsentreq";
import {
  deleteRelationship,
  resendInvite as resendInviteAPI,
} from "../../Services/Realtionship";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { ReactComponent as Check } from "../../Assets/icon/check.svg";


const Modal = ({ contact, onClose }) => {
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  const [editRelationship, setEditRelationship] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showRecentInviteNotification, setShowRecentInviteNotification] = useState(false);

  const handleResendInvite = async () => {
    try {
      // Your resend invite API call here
      
      // Show notification
      setShowRecentInviteNotification(true);
      
      // Hide notification after 2 seconds
      setTimeout(() => {
        setShowRecentInviteNotification(false);
        onClose();
      }, 2000);


    } catch (error) {
      console.error("Failed to resend invite:", error);
    }
  };

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose(); // Close the parent modal when canceling delete
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteRelationship(contact._id);
      console.log("Relationship deleted successfully");
      setIsModalOpen(false);
      onClose(); // Close the parent modal after successful deletion
    } catch (error) {
      console.error("Error deleting relationship:", error);
    }
  };

  const handleEditRelationship = () => {
    setEditRelationship(true);
  };

  const handleBack = () => {
    setEditRelationship(false);
    onClose(); // Close the parent modal when going back from edit
  };

  const handleFormClose = () => {
    setEditRelationship(false);
    onClose(); // Close the parent modal when closing the edit form
  };

  const options = [
    { 
      label: "Manage Relationship", 
      onClick: handleEditRelationship 
    },
    { 
      label: "Resend Invite", 
      onClick: handleResendInvite 
    },
    { 
      label: "Delete Invite", 
      onClick: handleDeleteClick, 
      color: "rgba(240, 68, 56, 1)" 
    },
  ];

  const relationshipType =
    contact?.user1_to_user2?.relationship_type ||
    contact?.related_data?.relationship_type ||
    "N/A";

  return (
    <>
      {!editRelationship ? (
        <div className="manage-overlay sendreqedit-container" onClick={onClose}>
          <div className="manage-modal-card" onClick={(e) => e.stopPropagation()}>
            <div className="manage-modal-header">
              {contact?.profile_pic_url && !imageError ? (
                <div className="manage-avatar">
                  <img
                    src={contact?.profile_pic_url}
                    alt={`${contact?.first_name} ${contact?.last_name}`}
                    className="manage-avatar-image"
                    onError={() => setImageError(true)}
                  />
                </div>
              ) : (
                <div className="manage-avatar">
                  {`${contact?.first_name?.[0] || ""}${
                    contact?.last_name?.[0] || ""
                  }`}
                </div>
              )}
              <div className="manage-info">
                <div className="manage-name">
                  {contact?.first_name + " " + contact?.last_name}
                </div>
                <div className="manage-type">{relationshipType}</div>
              </div>
              <button onClick={onClose} className="manage-close-button">
                <X size={20} />
              </button>
            </div>
            <div className="manage-modal-content">
              {options.map((option, index) => (
                <button
                  key={index}
                  className="manage-option-button"
                  onClick={option.onClick}
                  style={{ color: option.color || "#000" }}
                >
                  {option.label}
                  <img src={rightIcon} alt="" className="manage-rightarrowicon" />
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="pending-modal-overlay" onClick={handleFormClose}>
          <div className="accept-request-modal edit-bg-sent" onClick={(e) => e.stopPropagation()}>
            <Editsentreq contact={contact} onBack={handleBack} />
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="dlt-modal-overlay">
          <div className="dlt-modal-content">
            <div className="dlt-modal-content-header">
              <h3>Are you sure you want to delete this relationship?</h3>
              <button className="dlt-modal-close-btn" onClick={handleCloseModal}>
                <CloseIcon />
              </button>
            </div>
            <p className="dlt-modal-content-para">
              You won't be able to get advice on this person anymore.
            </p>
            <div className="dlt-modal-actions">
              <button className="dlt-modal-cancel-btn" onClick={handleCloseModal}>
                Cancel
              </button>
              <button
                className="dlt-modal-confirm-btn"
                onClick={handleConfirmDelete}
              >
                Yes, Remove
              </button>
            </div>
          </div>
        </div>
      )}
       {showRecentInviteNotification && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="delete-notification">
            <Check />
            Resent Invite
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
import React, { useEffect, useLayoutEffect } from "react";
import FullScreenBackground from "../../Components/BlueSvgBackground";
import { isSafari } from "react-device-detect";
import confetti from "../../Assets/images/confetti.svg";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../Providers/HeaderContextProvider";
function NewConfirmation() {
  const navigate = useNavigate();
  const { setHeaderContent, setHeaderContentRightSideContent } = useHeader();

  useLayoutEffect(() => {
    setHeaderContent(true);
    setHeaderContentRightSideContent(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      navigate("/dashboard");
    }, 3000);
  }, []);

  return (
    <div className="img-cnt">
      <img src={confetti} alt="pop" className="confetti" />
      <FullScreenBackground>
        <div className="onboarding-container">
          <div className="onboarding-content">
            <video className="responsive-video" autoPlay loop muted>
              <source
                src={
                  isSafari
                    ? "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/sphere-ios.mov"
                    : process.env.REACT_APP_SPHERE_LOADER ||
                      "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/Sphere_Loader.webm"
                }
                type="video/webm"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </FullScreenBackground>
    </div>
  );
}

export default NewConfirmation;

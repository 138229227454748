import React from "react";
import "./style.css";
import Loader from "react-js-loader";
import Stroke1 from "../../Assets/icon/Stroke1.svg"; // Stroke image

const DefaultLoader = () => {
  return (
    <div className="loader-er">
      <img src={Stroke1} alt="Stroke Layer 1" className="stroke-1-loader" />
      <img src={Stroke1} alt="Stroke Layer 2" className="stroke-2-loader" />
      <img src={Stroke1} alt="Stroke Layer 2" className="stroke-3-loader" />
      <img src={Stroke1} alt="Stroke Layer 2" className="stroke-4-loader" />
      <img src={Stroke1} alt="Stroke Layer 2" className="stroke-5-loader" />

      {/* <div className="stroke-1"></div>
      <div className="stroke-2"></div>
      <div className="stroke-3"></div> */}
      <div className="loader-df-container">
        <Loader
          type="spinner-cub"
          bgColor={"#2D50A7"}
          color={"#3D6DEB"}
          // title={"spinner-cub"}
          size={45}
        />
      </div>
    </div>
  );
};

export default DefaultLoader;

import React from "react";
import "./styles.css";

function PotraitsConflictManagement({
  superHeading = "Attitude",
  superTxt = "Collaborating",
  listArr = [
    {
      heading: "Attitude",
      content: "I can win and you can win too!",
    },
    {
      heading: "Posture",
      content: "High Assertiveness / High Cooperativeness",
    },
    {
      heading: "Goal/Strategy",
      content:
        "Concerned with resolving issues and not creating more problems.Gathers information, looks for alternatives, open to dialogue, welcomes varied views and potential disagreement—anything to get the best possible outcome.",
    },
    {
      heading: "Positive Impact",
      content:
        "Looking for agreements, and navigating around disagreements, can create a significant amount of commitment between parties and strengthen the relationship by building trust and confidence.",
    },
    {
      heading: "Interaction",
      content:
        "Very reluctant to enter into a tense dialogue or to gather uncomfortable information.",
    },
    {
      heading: "Over use",
      content:
        "Seeking the need for full commitments from everyone to get to a true win/win, can take a long time. Effective collaboration is a learned skill. When Collaboration is the preferred style, but this skillset is missing, it can lead to frustration and discouragement, especially when others don't embrace the same approach.",
    },
  ],
}) {
  return (
    <div className="potraits-conflict-management-details">
      <div className="potraits-conflict-management-heading-container">
        <div className="potraits-conflict-management-details-super-heading">
          Primary
        </div>
        <div className="potraits-conflict-management-details-super-sub-txt">
          {superTxt}
        </div>
      </div>
      {listArr.map((_l, index) => (
        <div className="potraits-conflict-management-sub-container">
          <div className="potraits-conflict-management-details-heading">
            {_l.label}
          </div>
          <div className="potraits-conflict-management-details-sub-txt">
            {" "}
            {_l.text}
          </div>
        </div>
      ))}
    </div>
  );
}

export default PotraitsConflictManagement;

import React, {
  memo,
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import chatLoading from "../../Assets/loaders/sphere.webm";
import ChatOptions from "../../Components/ChatOptions";
import ChatInput from "../../Components/ChatInput";
import ChatMic from "../../Components/ChatMic";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import "./style.css";
import RelationShipAdviceSVG from "../../Components/ChatSvg/relationShipAdviceSVG";
import CareerAdviceSVG from "../../Components/ChatSvg/careerAdviceSVG";
import MindSetGuidanceSVG from "../../Components/ChatSvg/mindSetGuidanceSVG";
import WellBeingSVG from "../../Components/ChatSvg/wellBeingSVG";
import MySelfSVG from "../../Components/ChatSvg/mySelfSVG";
import ResumeCoachingSVG from "../../Components/ChatSvg/ResumeCoachingSVG";
import ContactSelectionModal from "./ContactSelectionModal";
import CareerAdviceModal from "./CareerAdviceModal";
import {
  chatArisApiInitiate,
  getAudio,
  getChatinitialVoice,
  initiateChatSession,
} from "../../Services/Chat";
import { startQuestions } from "../../Services/Assessment";
import { useNavigate } from "react-router-dom";
import { isSafari } from "react-device-detect";
import DefaultLoader from "../LoadingPage/DefaultLoader";

import { useHeader } from "../../Providers/HeaderContextProvider";
import { getSessions, resumeCoaching } from "../../Services/coaching";

const SVGIcons = {
  relationship: memo(RelationShipAdviceSVG),
  career: memo(CareerAdviceSVG),
  mindset: memo(MindSetGuidanceSVG),
  wellbeing: memo(WellBeingSVG),
  myself: memo(MySelfSVG),
  resume: memo(ResumeCoachingSVG),
};

function InitialChat({
  onEnterData,
  chatId,
  chatInstruction,
  latestInProgressSession,
}) {
  const navigate = useNavigate();
  const { setHeaderContent, setHeaderContentRightSideContent, profileContent } =
    useHeader();

  const [selectedChatType, setSelectedChatType] = useState(0);
  const [showRelationShipContacts, setShowRelationShipContacts] =
    useState(false);
  const [showCareerAdviceModal, setShowCareerAdviceModal] = useState(false);
  const [selectedRelationShipName, setSelectedRelationShipName] = useState("");
  const [chatType, setchatType] = useState(2);

  const [isRecording, setisRecording] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  useLayoutEffect(() => {
    setHeaderContent(true);
    setHeaderContentRightSideContent(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (profileContent?._id)
          chatArisApiInitiate(profileContent?._id).then((e) => {
            console.log(e, "instruction");
            chatInstruction(e);
          });
      } catch (error) {
        console.error("Error fetching data - profile", error);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const playAudio = async () => {
  //     console.log("audio");
  //     try {
  //       // Audio source URL
  //       const audioSrc =
  //         "https://aris-static-app.s3.us-east-1.amazonaws.com/assets/initial_voice.mp3";

  //       // Create a new audio object
  //       const audio = new Audio(audioSrc);

  //       // Wait for user interaction (click, scroll, etc.)
  //       const handleUserInteraction = () => {
  //         document.removeEventListener("click", handleUserInteraction); // Remove the listener
  //         audio
  //           .play()
  //           .then(() => {
  //             console.log("Audio is playing...");
  //           })
  //           .catch((error) => {
  //             console.error("Error playing audio:", error);
  //           });

  //         // Cleanup when audio finishes
  //         audio.onended = () => {
  //           console.log("Audio playback finished");
  //         };
  //       };

  //       // Add an event listener for the first user interaction
  //       document.addEventListener("click", handleUserInteraction);
  //     } catch (error) {
  //       console.error("Error in playAudio:", error);
  //     }
  //   };

  //   playAudio();
  // }, []);

  // useEffect(() => {
  //   initiateChatSession().then((res) => {
  //     chatId(res?.payload?.chat_id);
  //     localStorage.setItem("chatId", res?.payload?.chat_id);
  //   });
  // }, [chatId]);

  // Empty dependency array ensures this runs once
  useEffect(() => {
    startQuestions()
      .then(async (res) => {
        console.log("response from assessment", res);
        if (res?.categoryID !== null) {
          navigate(`/onboarding/chat`);
          setisLoading(false);
        } else {
          setisLoading(false);
          if (localStorage.getItem("playOnce") === "true") {
            const audioSrc = await getChatinitialVoice().then(
              (res) => res?.payload?.url
            );

            const audio = new Audio(audioSrc);
            localStorage.setItem("playOnce", "false");
            // Play the audio
            audio
              .play()
              .then(() => {
                console.log("Audio is playing...");
              })
              .catch((error) => {
                console.error("Error playing audio:", error);
              });

            // Optional: Handle audio end
            audio.onended = () => {
              console.log("Audio playback finished");

              // Set "playOnce" to false so it doesn't play again on reload
              localStorage.setItem("playOnce", "false");
            };
          }
        }
      })
      .catch((error) => {
        console.log("error from assessment", error);
      });
  }, []);

  // useEffect(() => {
  //   // Check if the "playOnce" item exists in localStorage, otherwise set it to true initially
  //   if (localStorage.getItem("playOnce") === "true") {
  //     // console.log(
  //     //   localStorage.getItem("playOnce").json(),
  //     //   typeof localStorage.getItem("playOnce").json(),
  //     //   "kko"
  //     // );
  //     const audioSrc =
  //       "https://aris-static-app.s3.us-east-1.amazonaws.com/assets/initial_voice.mp3";
  //     const audio = new Audio(audioSrc);
  //     localStorage.setItem("playOnce", "false");
  //     // Play the audio
  //     audio
  //       .play()
  //       .then(() => {
  //         console.log("Audio is playing...");
  //       })
  //       .catch((error) => {
  //         console.error("Error playing audio:", error);
  //       });

  //     // Optional: Handle audio end
  //     audio.onended = () => {
  //       console.log("Audio playback finished");

  //       // Set "playOnce" to false so it doesn't play again on reload
  //       localStorage.setItem("playOnce", "false");
  //     };
  //   }
  // }, []);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const VideoFile = isSafari
    ? "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/sphere-ios.mov"
    : process.env.REACT_APP_SPHERE_LOADER ||
      "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/Sphere_Loader.webm";

  const options = latestInProgressSession
    ? [
        { id: 1, icon: <SVGIcons.relationship />, txt: "Relationship advice" },
        {
          id: 6,
          icon: <SVGIcons.resume />,
          txt: "Resume last coaching session",
        },
        { id: 2, icon: <SVGIcons.career />, txt: "Career advice" },
        { id: 3, icon: <SVGIcons.mindset />, txt: "Mindset guidance" },
        {
          id: 4,
          icon: <SVGIcons.wellbeing />,
          txt: "I want help with my well-being",
        },
        {
          id: 5,
          icon: <SVGIcons.myself />,
          txt: "I want to learn more about myself",
        },
      ]
    : [
        { id: 1, icon: <SVGIcons.relationship />, txt: "Relationship advice" },
        { id: 2, icon: <SVGIcons.career />, txt: "Career advice" },
        { id: 3, icon: <SVGIcons.mindset />, txt: "Mindset guidance" },
        {
          id: 4,
          icon: <SVGIcons.wellbeing />,
          txt: "I want help with my well-being",
        },
        {
          id: 5,
          icon: <SVGIcons.myself />,
          txt: "I want to learn more about myself",
        },
      ];

  const onChatTypeSelection = useCallback(
    (typeID) => {
      if (typeID === 1) setShowRelationShipContacts(true);
      if (typeID === 2) {
        setShowCareerAdviceModal(true);
        setchatType(2);
      }
      if (typeID === 3) {
        setShowCareerAdviceModal(true);
        setchatType(3);
      }
      if (typeID === 4) {
        setShowCareerAdviceModal(true);
        setchatType(4);
      }
      if (typeID === 5) onEnterData("I want to learn more about myself");

      if (typeID !== selectedChatType) {
        setSelectedChatType(typeID);
      }
      if (typeID === 6) {
        window.location.href = "/coach/resume";
      }
    },
    [selectedChatType]
  );

  console.log(selectedChatType, "selectedChatType");

  const handleCareerGoalSelection = (goalTitle) => {
    onEnterData(goalTitle);
    setShowCareerAdviceModal(false); // Close modal after selection
  };

  const onContactSelection = (contact) => {
    console.log(contact, "aiio");
    if (contact?.type === "addNewContact") {
      onEnterData({
        type: "initiateRelationship",
        subType: "addNewContact",
        text: "Add new relationShip",
      });
    } else {
      setSelectedRelationShipName(contact);
      onEnterData({
        type: "initiateRelationship",
        text: "I need relationship advice for ",
        name: contact?.first_name + " " + contact?.last_name,
        profile_pic_url: contact?.profile_pic_url,
        contact_relational_data: contact?.related_data,
        relation_id: contact?._id,
      });
    }
  };

  const onCloseCarrierAdvise = () => {
    setShowCareerAdviceModal(false);
    setSelectedChatType(0);
  };

  const onCloseRelationshipAdvise = () => {
    setShowRelationShipContacts(false);
    setSelectedChatType(0);
  };

  const handlePlayAudio = () => {
    if ("speechSynthesis" in window) {
      const speech = new SpeechSynthesisUtterance(
        "Hi, I'm Aris. How can I help you?"
      ); // Create a speech object
      // Get available voices
      const voices = window.speechSynthesis.getVoices();

      // // Select a female voice (you can refine this further based on language)
      // const femaleVoice = voices.find(voice =>
      //   voice.name.toLowerCase().includes("female") || voice.name.toLowerCase().includes("woman") ||
      //   voice.gender === "female" // Use voice.gender if supported by the browser
      // );

      speech.voice = voices[4];

      window.speechSynthesis.speak(speech); // Play the speech
    } else {
      alert("Sorry, your browser does not support text-to-speech.");
    }
  };

  const startRecording = () => {
    if (!isRecording) {
      SpeechRecognition.startListening();
    } else {
      SpeechRecognition.stopListening();
      if (transcript !== "") {
        onEnterData(transcript);
      }

      resetTranscript();
    }
    setisRecording((prev) => !prev);
  };

  console.log(transcript, "transcript");

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const afterOnPassData = () => {
    SpeechRecognition.stopListening();
    setisRecording((prev) => !prev);
    resetTranscript();
  };

  if (isLoading) {
    return <DefaultLoader />;
  }
  // console.log(chatInstruction.current, "playOnce");
  return (
    <>
      <div className="relationship-gif-txt-cmb-cntr">
        <div className="relationship-gif-cntr">
          <video
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            className="relationship-gif"
            src={VideoFile}
          />
        </div>
        <div className="relationship-txt-cntr">
          <div>Hi, I'm Aris.</div>
          <div>How can I help you?</div>
        </div>
      </div>
      <div className="relationship-option-cntr">
        {options.map((option) => (
          <ChatOptions
            key={option.id}
            id={option.id}
            icon={option.icon}
            txt={option.txt}
            onClick={onChatTypeSelection}
            isActiveID={selectedChatType === option.id}
          />
        ))}
      </div>
      <div className="relationship-input-cntr">
        <div className="rl-input">
          <ChatInput
            onChange={onEnterData}
            value={transcript}
            afterOnPassData={afterOnPassData}
          />
        </div>
        <div className="rl-mic" onClick={startRecording}>
          <ChatMic isRecording={isRecording} />
        </div>
      </div>
      <ContactSelectionModal
        isOpen={showRelationShipContacts}
        onClose={onCloseRelationshipAdvise}
        onContactSelection={onContactSelection}
      />
      <CareerAdviceModal
        isOpen={showCareerAdviceModal}
        onClose={onCloseCarrierAdvise}
        onSelectGoal={handleCareerGoalSelection}
        type={chatType}
      />
    </>
  );
}

export default memo(InitialChat);

import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import rightIcon from "../../Assets/icon/chevron-right.svg";
import Allset from "../../Components/AllsetComponent";
import ConfirmationModal from "./ManageMembershipCancel";
import { billingPortal } from "../../Services/subscription";
import "./style.css";
import BuyMinutes from "./BuyMinutes";
import { useSearchParams } from "react-router-dom";

const AnnualMembershipList = ({
  isBuyMinutes,
  setisBuyMinutes,
  isAnnualList,
  setisAnnualList,
  products,
  subscriptionData,
}) => {
  const [searchParams] = useSearchParams();
  const ispurchased = searchParams.get("ispurchased");
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isAllSet, setIsAllSet] = useState(false);
  const [isReqMin, setisReqMin] = useState(false);

  useEffect(() => {
    console.log("isReqMin updated:", isReqMin);
  }, [isReqMin]);

  const openCancelMembershipModal = () => {
    setisAnnualList(false);
    setIsCancelModal(true);
  };

  const closeCancelMembershipModal = () => {
    setIsCancelModal(false);
    setisAnnualList(true);
  };

  const handleCancelMembership = () => {
    setIsAllSet(true);
    setIsCancelModal(false);
  };

  const handleStripe = () => {
    billingPortal().then((res) => {
      console.log(res);
      window.location.href = res?.payload?.checkout_url;
    });
  };

  const openBuyminutes = () => {
    setisReqMin(true);
    console.log(isReqMin);
  };

  const options = [
    { label: "Manage payment method", onClick: handleStripe },
    { label: "Change payment plan", onClick: handleStripe },
    { label: "Purchase additional minutes", onClick: openBuyminutes },
    { label: "Cancel membership", onClick: openCancelMembershipModal },
  ];

  return (
    <>
      {isAnnualList && (
        <div className="membershipmodal-overlay">
          <div className="membershipmodal-container">
            <div className="membershipmodal-header" style={{ margin: "0px" }}>
              <h2 className="membershipmodal-heading">Aris</h2>
              <button
                className="membershipclose-btn"
                onClick={() => setisAnnualList(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <div
              className="membership-arist2"
              style={{ textAlign: "left", margin: "0px" }}
            >
              Annual membership
            </div>
            <div className="manage-modal-content membership-payment">
              {options.map((option, index) => (
                <button
                  key={index}
                  className="manage-option-button membership-btn-txt"
                  onClick={option.onClick}
                >
                  {option.label}
                  <img
                    src={rightIcon}
                    alt=""
                    className="manage-rightarrowicon"
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      <ConfirmationModal
        isOpen={isCancelModal}
        onClose={closeCancelMembershipModal}
        onConfirm={handleCancelMembership}
        heading="Are you sure you want to cancel membership"
        subText="You won't be able to get advise from Aris anymore"
        confirmButtonText="Yes, Cancel"
        cancelButtonText="Back"
      />

      {/* {isAllSet && (
        <Allset
          closeModal={() => {
            setIsAllSet(false);
            setisAnnualList(false);
          }}
          subText={
            ispurchased === "confirm"
              ? "Your subscription for Aris was cancelled"
              : "Something went wrong!"
          }
          heading={ispurchased === "confirm" ? "You're All set": "Oops!"}
          isFailure={ispurchased === "confirm" ? false : true}
        />
      )} */}

      {isReqMin && (
        <BuyMinutes
          isBuyMinutes={isReqMin}
          setisBuyMinutes={setisReqMin}
          productId={
            products?.find(
              (product) =>
                product?.metadata?.plan_type === "addon" &&
                product?.name === "Coaching Minutes"
            )?.id
          }
          priceDetails={
            products?.find(
              (product) =>
                product?.metadata?.plan_type === "addon" &&
                product?.name === "Coaching Minutes"
            )?.pricingDetails || []
          }
          user_type={subscriptionData?.user_type}
        />
      )}
    </>
  );
};

export default AnnualMembershipList;

import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import rightIcon from "../../Assets/icon/chevron-right.svg";
import Allset from "../../Components/AllsetComponent";
import ConfirmationModal from "./ManageMembershipCancel";
import { ReactComponent as Point } from "../../Assets/icon/Ellipse 88.svg";
import { ReactComponent as Info } from "../../Assets/icon/Info.svg";
import "./style.css";
import CoachingModal from "../Pricing/CoachingModal";
import { ReactComponent as Plus } from "../../Assets/icon/plus.svg";
import { ReactComponent as Minus } from "../../Assets/icon/minus.svg";
import { postreqminutes } from "../../Services/subscription";
import { useSearchParams } from "react-router-dom";

const RequestMinutes = ({
  Reqcoachingminutes,
  setsetReqcoachingminutes,
  showdetails,
  metadata,
  formatDate,
  addon,
  priceDetails,
  productId,
  isReqMinSuccess,
}) => {
  const [searchParams] = useSearchParams();
  const ispurchased = searchParams.get("ispurchased");
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isAllSet, setIsAllSet] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [memberCount, setMemberCount] = useState(0);
  const [selectedMinutes, setSelectedMinutes] = useState(0); // State to hold selected radio value
  const [iserror, setiserror] = useState("");

  useEffect(() => {
    setTimeout(() => setiserror(""), 2000);
  }, [iserror]);

  const openCancelMembershipModal = () => {
    setsetReqcoachingminutes(false);
    setIsCancelModal(true);
  };

  const closeCancelMembershipModal = () => {
    setIsCancelModal(false);
    setsetReqcoachingminutes(true);
  };

  const handleCancelMembership = () => {
    setIsAllSet(true);
    setIsCancelModal(false);
  };

  const incrementMember = (e) => {
    e.stopPropagation();
    setMemberCount((prev) => prev + 1);
  };

  const decrementMember = (e) => {
    e.stopPropagation();
    if (memberCount > 0) {
      setMemberCount((prev) => prev - 1);
    }
  };

  const handleRadioChange = (e) => {
    setSelectedMinutes(parseInt(e.target.value)); // Set the selected minutes based on the radio button
  };

  const handleSendRequest = () => {
    localStorage.setItem("successMode", "reqMins");
    const totalMinutes = selectedMinutes + memberCount; // Combine the selected minutes and modified minutes
    const payload = { requested_minutes: totalMinutes };

    postreqminutes(payload)
      .then((res) => {
        console.log("Req res", res);

        isReqMinSuccess();
      })
      .catch((error) => {
        console.log("Req mins error", error);
        setiserror(error.response.data.message);
      }); // Call the API with the updated payload
  };

  console.log(showdetails, "showdetails");

  console.log(productId, priceDetails, "coaching idss");

  return (
    <>
      {Reqcoachingminutes && (
        <div className="membershipmodal-overlay">
          <div className="membershipmodal-container">
            <div className="membershipmodal-header" style={{ margin: "0px" }}>
              <h2 className="membershipmodal-heading">
                Request Coaching Minutes
              </h2>
              <button
                className="membershipclose-btn"
                onClick={() => setsetReqcoachingminutes(false)}
              >
                <CloseIcon />
              </button>
            </div>

            <div className="manage-modal-content membership-payment plan-info-membership-container ">
              <div className="plan-info-membership-heading-container">
                <div className="plan-info-membership-heading">
                  {" "}
                  How many minutes would you like to request?
                </div>
              </div>
              <div>
                <div className="req-radio-container">
                  {priceDetails?.map((price, index) => (
                    <div key={index}>
                      <input
                        type="radio"
                        name="duration"
                        value="60"
                        onChange={handleRadioChange}
                      />
                      {price?.metadata?.product_value} minutes
                    </div>
                  ))}
                  {/* <div>
                    <input
                      type="radio"
                      name="duration"
                      value="120"
                      onChange={handleRadioChange}
                    />
                    120 minutes
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="duration"
                      value="480"
                      onChange={handleRadioChange}
                    />
                    480 minutes
                  </div> */}
                </div>
              </div>
            </div>
            <div>{iserror && <p className="error-message">{iserror}</p>}</div>
            <div className="Req-mins-btn-container">
              <button
                className="membership-coaching-minutes-purchase-button req-skip"
                onClick={() => setsetReqcoachingminutes(false)}
              >
                Skip
              </button>
              <button
                className="membership-coaching-minutes-purchase-button"
                onClick={handleSendRequest} // Send the request to API
              >
                Send request
              </button>
            </div>
          </div>
        </div>
      )}

      <ConfirmationModal
        isOpen={isCancelModal}
        onClose={closeCancelMembershipModal}
        onConfirm={handleCancelMembership}
        heading="Are you sure you want to cancel membership"
        subheading="You won't be able to get advise from Aris anymore"
        confirmButtonText="Yes, Cancel"
        cancelButtonText="Back"
      />

      {/* {isAllSet && (
        <Allset
          closeModal={() => {
            setIsAllSet(false);
            setsetReqcoachingminutes(false);
          }}
          heading={ispurchased === "confirm" ? "You're All set": "Oops!"}
          subText={ispurchased === "confirm" ? "The Primary account holder will receive your request": "Something went wrong."}
          isFailure={ispurchased === "confirm" ? false : true}

        />
      )} */}
    </>
  );
};

export default RequestMinutes;

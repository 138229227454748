import {
  axiosAssessmentInstance,
  axiosAuthInstance,
} from "../AxiosConfiguration";
import Cookies from "js-cookie";

export const updateNotificationSettings = async (data) => {
  try {
    const response = await axiosAssessmentInstance.post(
      `/api/notification/settings/update`,
      data
    );
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const getNotificationSettings = async () => {
  try {
    const response = await axiosAssessmentInstance.get(
      `/api/notification/settings`
    );
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const updateEmailSettings = async (data) => {
  try {
    const response = await axiosAssessmentInstance.put(
      `/api/user/update_email`,
      data
    );

    if (response.data.status_code === 200) {
      // Store Device_id in cookies
      const deviceId =
        response.headers["Device_id"] || response.headers["device_id"];
      if (deviceId) {
        Cookies.set("Device_id", deviceId);
        console.log("Device ID set in cookies");
      } else {
        console.error("Device ID not found in headers");
      }

      return response?.data;
    }
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const sendUpdatedEmail = async (data) => {
  try {
    const response = await axiosAssessmentInstance.post(
      `/api/user/verify_email`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("idtoken")}`,
          Device_id: Cookies.get("Device_id"),
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const resendEmail = async (data) => {
  try {
    const response = await axiosAssessmentInstance.post(
      `/api/user/resend_email_verification`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("idtoken")}`,
          Device_id: Cookies.get("Device_id"),
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

{
  /* Phone number updation */
}

export const sendUpdatedContact = async (data) => {
  try {
    const response = await axiosAssessmentInstance.put(
      `/api/user/update_contact`,
      data
    );
    return response?.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error.response?.data?.message || error.message;
  }
};

export const checkOTP = async (data) => {
  try {
    const response = await axiosAssessmentInstance.post(
      `/api/user/verify_otp`,
      data
    );
    return response?.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const resendOTP = async (data) => {
  try {
    const response = await axiosAssessmentInstance.put(
      `/api/user/resend_otp`,
      data
    );
    return response?.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

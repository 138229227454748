import React from "react";
import "./style.css";
import { ReactComponent as Mic } from "../../Assets/images/mic.svg";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";

// frontend\frontend\src\Assets\images\mic.svg
function ChatMic({ isRecording }) {
  return (
    <div className="mic-cntr">
      {isRecording ? (
        <StopCircleOutlinedIcon sx={{ color: "white" }} fill="#fff" />
      ) : (
        <Mic className="mic-svg" />
      )}
    </div>
  );
}

export default ChatMic;

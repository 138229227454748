import React from "react";

const FullScreenBackground = ({ children }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100vh",
        background: `url('data:image/svg+xml;utf8,${encodeURIComponent(
          svgContent
        )}') no-repeat center center / cover`,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          height: "100dvh",
        }}
      >
        {children}
      </div>
    </div>
  );
};

const svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1631 1047" fill="none">
<g clip-path="url(#clip0_8794_801)">
<rect width="1631" height="100%" fill="#4377FC"/>
<ellipse cx="646.485" cy="82.3285" rx="964.328" ry="826.883" transform="rotate(-22.2047 646.485 82.3285)" fill="url(#paint0_angular_8794_801)"/>
<ellipse cx="815.59" cy="388.817" rx="964.328" ry="1157.92" transform="rotate(-22.2047 815.59 388.817)" fill="#4377FC"/>
<g opacity="0.7" filter="url(#filter0_f_8794_801)">
<path d="M1379.14 242.197C1334.09 287.248 1272.22 311.787 1207.14 310.417C1142.06 309.046 1079.1 281.879 1032.11 234.891C985.126 187.904 957.959 124.944 956.589 59.8636C955.218 -5.21708 979.758 -67.0879 1024.81 -112.138L1201.98 65.0296L1379.14 242.197Z" fill="#31C6AF"/>
</g>
<g opacity="0.7" filter="url(#filter1_f_8794_801)">
<path d="M453.047 240.166C540.186 283.202 605.925 360.579 635.802 455.277C665.679 549.974 657.247 654.234 612.36 745.121C567.474 836.008 489.81 906.077 396.454 939.913C303.098 973.749 201.697 968.581 114.558 925.545L283.803 582.856L453.047 240.166Z" fill="#31C6AF"/>
</g>
</g>
<defs>
<filter id="filter0_f_8794_801" x="836.534" y="-32.138" width="662.609" height="662.609" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="60" result="effect1_foregroundBlur_8794_801"/>
</filter>
<filter id="filter1_f_8794_801" x="-5.44202" y="120.166" width="778.831" height="961.855" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="60" result="effect1_foregroundBlur_8794_801"/>
</filter>
<radialGradient id="paint0_angular_8794_801" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(646.485 82.329) rotate(98.0932) scale(2801.62 4746.04)">
<stop stop-color="#01C1A4"/>
<stop offset="1" stop-color="#1C4DDD"/>
</radialGradient>
<clipPath id="clip0_8794_801">
<rect width="1631" height="1047" fill="white"/>
</clipPath>
</defs>
</svg>`;

export default FullScreenBackground;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import arrowLeft from "../../Assets/icon/arrow-narrow-left.svg";
import { useHeader } from "../../Providers/HeaderContextProvider";
import { getAboutAris } from "../../Services/aboutAris";
import navigateTop from "../../Assets/icon/navigate-top.svg";
import navigateBottom from "../../Assets/icon/navigate-bottom.svg";
import GradientBackground from "../../Components/AddRelationshipHeaderGradient";
function TermsofUse() {
  const navigate = useNavigate();
  const { setHeaderContent } = useHeader();
  const [termsofuseData, setTermsofuseData] = useState(null);

  useEffect(() => {
    setHeaderContent(false);
    const fetchTermsofUseData = async () => {
      try {
        const field = "terms_of_use";
        const response = await getAboutAris(field);
        if (response) {
          setTermsofuseData(response);
        }
      } catch (error) {
        console.error("Error fetching about data:", error);
      }
    };

    fetchTermsofUseData();
  }, []);

  const [showScrollDown, setShowScrollDown] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 100;
      setShowScrollDown(scrollPosition < threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="termsofuse-app">
      <GradientBackground>
        <header className="termsofuse-header">
          <div className="termsofuse-gradient-background ">
            <div className="termsofuse-nav-container">
              <div
                className="termsofuse-logo-container"
                style={{ padding: "20px" }}
                onClick={() => navigate("/dashboard")}
              >
                <img
                  src={arrowLeft}
                  alt="Back"
                  className="termsofuse-back-button"
                />
              </div>
              <h1 className="termsofuse-title">Terms of Use</h1>
              <div className="termsofuse-space"></div>
            </div>
            <div className="termsofuse-last-updated">
              Last updated - {termsofuseData?.last_updated}
            </div>
          </div>
        </header>
        <div className="termsofuse-content">{termsofuseData?.content}</div>
        <div className="termsofuse-navigate-icon">
          <div onClick={scrollToTop}>
            {" "}
            <img src={navigateTop} alt="Navigate top" />
          </div>
        </div>
        {showScrollDown && (
          <div className="termsofuse-navigate-bottom">
            <div onClick={scrollToBottom}>
              <img src={navigateBottom} alt="Navigate bottom" />
            </div>
          </div>
        )}
      </GradientBackground>
    </div>
  );
}
export default TermsofUse;

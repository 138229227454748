import React from 'react';
import './style.css';
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { ReactComponent as TshirtIcon } from "../../Assets/icon/t-shirt.svg";
import { ReactComponent as CrownIcon } from "../../Assets/icon/Crown.svg";
import { ReactComponent as Rightarrow} from "../../Assets/icon/arrow-narrow-right-grey.svg"

const ReferralPopup = ({ onClose }) => {
  return (
    <div className="referral-container">
      <div className="referral-popup">
        <div className="popup-header">
          
          <h2 className='popup-header-h2'>Refer a friend</h2>
          <button className="popup-close-button" onClick={onClose}><CloseIcon /></button>
         
        </div>

        <div className="popup-content">
          <h1 className='popup-content-h1'>Share the love, reap the rewards</h1>
          
          <p className="description">
            Do you enjoy using our app? Why not share it with your friends and family? 
            Refer 10 people for some great rewards:
          </p>

          <div className="rewards-section">
            <h3 className='rewards-section-h3'>Referral bonus:</h3>
            
            <div className="reward-item">
              <span className="reward-icon"><TshirtIcon /></span>
              <span>Get free merchandise</span>
            </div>

            <div className="reward-item premium">
              <span className="reward-icon"><CrownIcon /></span>
              <span>Get free month of premium</span>
            </div>
          </div>

          <div className="referrals-section">
            <div className="referral-header">
                <div >
              <span className='referral-header-txt'>Referrals </span><span>5/10</span>  </div>
              <button className="show-all" onClick={onClose}>Show all <Rightarrow /></button>

            
            </div>

            <div className="referral-avatars">
              {[
                { initials: 'YL', name: 'Yari Landstar' },
                { initials: 'SC', name: 'Sarah Chen', verified: true },
                { initials: 'AB', name: 'Alex Brown', verified: true },
                { initials: 'MB', name: 'Mike Black' }
              ].map((user, index) => (
                <div className="avatar" key={index}>
                  <div className="avatar-circle">
                    {user.initials}
                    {user.verified && <div className="verified-badge" />}
                  </div>
                  <span className="avatar-name">{user.name}</span>
                </div>
              ))}
            </div>
          </div>

          <button className="invite-button">
            Invite friends and family
          </button>

          <button className="popup-learn-more">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferralPopup;
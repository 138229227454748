import React from 'react';

const SelectField = ({
  label,
  name,
  value,
  onChange,
  options,
  optional = false,
  className = ''
}) => {
  return (
    <div className={`form-group ${className}`}>
      <label>
        {label} {optional && <span className="optional">(optional)</span>}
      </label>
      <select
        name={name}
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
import React from "react";

function Personality8() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 107"
      fill="none"
    >
      <path
        d="M21.3965 14.748L68.0166 50.8521L88.3578 81.9336L25.33 96.3685L21.3965 14.748Z"
        fill="#3BCFB5"
      />
      <path
        d="M21.3965 14.748L68.0166 50.8521M21.3965 14.748L25.33 96.3685M21.3965 14.748L88.3578 81.9336M68.0166 50.8521L88.3578 81.9336M68.0166 50.8521L25.33 96.3685M88.3578 81.9336L25.33 96.3685"
        stroke="white"
        stroke-opacity="0.7"
        stroke-width="0.211394"
        stroke-miterlimit="10"
      />
      <path
        d="M25.33 97.0245L88.3586 81.8413L21.3965 14.4214L25.33 97.0245Z"
        fill="#27BBA1"
      />
      <g filter="url(#filter0_f_2161_30181)">
        <path
          d="M27.57 34.5151L29.6014 40.7578H36.1751L30.8568 44.616L32.8882 50.8587L27.57 47.0005L22.2517 50.8587L24.2831 44.616L18.9648 40.7578H25.5386L27.57 34.5151Z"
          fill="white"
        >
          <animate
            attributeName="opacity"
            values="0.5;1;0.5"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
      <g filter="url(#filter1_f_2161_30181)">
        <path
          d="M54.4973 53.8174L57.9344 64.3861H69.0571L60.0586 70.9179L63.4957 81.4866L54.4973 74.9548L45.4988 81.4866L48.9359 70.9179L39.9375 64.3861H51.0602L54.4973 53.8174Z"
          fill="white"
          fill-opacity="0.4"
        >
          <animate
            attributeName="opacity"
            values="0.5;1;0.5"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
      <g opacity="0.6" filter="url(#filter2_f_2161_30181)">
        <path
          d="M69.0043 42.6602L70.6505 47.7314H75.9774L71.6678 50.8656L73.3139 55.9369L69.0043 52.8027L64.6947 55.9369L66.3409 50.8656L62.0312 47.7314H67.3582L69.0043 42.6602Z"
          fill="white"
        >
          <animate
            attributeName="opacity"
            values="0.5;1;0.5"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30181"
          x="11.2796"
          y="26.8299"
          width="32.5814"
          height="31.7142"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30181"
          />
        </filter>
        <filter
          id="filter1_f_2161_30181"
          x="32.2523"
          y="46.1321"
          width="44.4896"
          height="43.0394"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30181"
          />
        </filter>
        <filter
          id="filter2_f_2161_30181"
          x="54.346"
          y="34.9749"
          width="29.3158"
          height="28.6473"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30181"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Personality8;

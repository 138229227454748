import React from "react";
import "./style.css";
function PercentailWithHead({ percentail = "40%", txt = "Test" }) {
  return (
    <div className="potrait-percentail-with-head-container">
      <div className="potraits-pwh-percent">{percentail}</div>
      <div className="potraits-pwh-txt">{txt}</div>
    </div>
  );
}

export default PercentailWithHead;

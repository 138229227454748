import React, { memo, useEffect, useRef, useState } from "react";
import { ReactComponent as Point } from "../../Assets/icon/li_dot.svg";
import { ReactComponent as Info } from "../../Assets/icon/Info.svg";
import { ReactComponent as UpArrow } from "../../Assets/icon/chevron-up.svg";
import { ReactComponent as DownArrow } from "../../Assets/icon/chevron-down.svg";
import CoachingModal from "./CoachingModal";

const MainProduct = memo(
  ({ product, onPriceSelect, handleInviteSubmit, memberContIncrement }) => {
    const [activeTabId, setActiveTabId] = useState(1);
    const [showMembers, setShowMembers] = useState(false);
    const [memberCount, setMemberCount] = useState(0);
    const [activeModal, setActiveModal] = useState(null);
    const currentPriceRef = useRef(null);

    const primary_monthly = product?.pricingDetails?.find(
      (price) =>
        price?.metadata?.price_type === "primary-users" &&
        price?.recurring?.interval === "month"
    );

    const primary_Yearly = product?.pricingDetails?.find(
      (price) =>
        price?.metadata?.price_type === "primary-users" &&
        price?.recurring?.interval === "year"
    );

    const secondary_monthly = product?.pricingDetails?.find(
      (price) =>
        price?.metadata?.price_type === "sub-users" &&
        price?.recurring?.interval === "month"
    );

    const secondary_yearly = product?.pricingDetails?.find(
      (price) =>
        price?.metadata?.price_type === "sub-users" &&
        price?.recurring?.interval === "year"
    );

    const addOnPoints = JSON.parse(product?.metadata?.addon_point_list);

    useEffect(() => {
      const selectedPrice =
        activeTabId === 1 ? primary_monthly : primary_Yearly;
      currentPriceRef.current = selectedPrice;
      onPriceSelect(selectedPrice);
    }, [activeTabId, primary_monthly, primary_Yearly]);

    const handleTabClick = (newTabId) => {
      setActiveTabId(newTabId);
    };

    const incrementMember = () => {
      setMemberCount((prev) => prev + 1);
    };

    const decrementMember = () => {
      if (memberCount > 0) {
        setMemberCount((prev) => prev - 1);
      }
    };

    useEffect(() => {
      memberContIncrement(memberCount);
    }, [memberCount]);

    console.log(product, "final");
    return (
      <>
        <div className="pricing-card-selected">
          <h2 className="pricing-heading">
            {product?.name} {product?.description}
          </h2>

          <div className="pricing-life-coach-container-row">
            <div>
              <p className="pricing-life-coach-container-para">
                1 year membership
              </p>
              <p className="pricing-life-coach-container-para2">
                20% off billed annually
              </p>
            </div>
            <div className="pricing-life-coach-container-price">
              <h2 className="pricing-original-offer">
                $
                {activeTabId === 1
                  ? (primary_monthly?.unit_amount || 0) / 100
                  : (primary_Yearly?.unit_amount || 0) / 100}
              </h2>
              <p> /month </p>
            </div>
          </div>

          <p className="pricing-life-coach-plans">Plan includes:</p>
          <div className="pricing-life-coach-plans-container">
            {product?.marketing_features?.map((topic, index) => {
              const parsedData = JSON.parse(topic.name);
              return (
                <>
                  <div
                    key={index}
                    className="pricing-life-coach-plan-points"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveModal(parsedData.key);
                    }}
                  >
                    <Point className="pricing-point" />
                    {parsedData?.title}
                    <Info />
                  </div>
                  <CoachingModal
                    // key={topic.id}
                    isOpen={activeModal === parsedData.key}
                    onClose={() => setActiveModal(null)}
                    title={parsedData?.title}
                    {...JSON.parse(product?.metadata[parsedData.key])}
                  />
                </>
              );
            })}
          </div>

          <div
            className="insight-tab-switch-content"
            style={{ marginBottom: "20px", boxSizing: "border-box" }}
          >
            <div
              className={`insight-switch-txt ${
                activeTabId === 1 ? "insight-tab-active" : ""
              }`}
              onClick={() => handleTabClick(1)}
            >
              Pay month to month
            </div>
            <div
              type="button"
              className={`insight-switch-txt ${
                activeTabId === 2 ? "insight-tab-active" : ""
              }`}
              onClick={() => handleTabClick(2)}
            >
              Pay upfront
              <div className="pricing-save-container" id="1">
                <p
                  className={`pricing-save ${
                    activeTabId === 2 ? "dis-color" : "pricing-save"
                  }`}
                >
                  Save 10%
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="pricing-additional-cards margin-restricted-bottom">
          <div className="pricing-toggle-card">
            <div
              className="pricing-toggle-header"
              onClick={() => setShowMembers(!showMembers)}
            >
              <div className="pricing-toggle-title">
                <h3>Add More Members</h3>
              </div>
              <button className="pricing-toggle-button">
                {showMembers ? <UpArrow /> : <DownArrow />}
              </button>
            </div>

            {showMembers && (
              <div className="pricing-toggle-content">
                <div className="pricing-counter-amount">
                  <div className="pricing-member-counter">
                    <button
                      className="pricing-counter-button"
                      onClick={decrementMember}
                    >
                      −
                    </button>
                    <span className="pricing-counter-value">{memberCount}</span>
                    <button
                      className="pricing-counter-button"
                      onClick={incrementMember}
                    >
                      +
                    </button>
                  </div>
                  <div>
                    <span className="pricing-toggle-price">
                      $
                      {activeTabId === 1
                        ? (secondary_monthly?.unit_amount || 0) / 100
                        : (secondary_yearly?.unit_amount || 0) / 100}
                      <span className="pricing-toggle-price-txt">
                        /month per account
                      </span>
                    </span>
                  </div>
                </div>
                <p className="pricing-toggle-description">
                  {product?.metadata?.addon_description}
                </p>
                <ul className="pricing-toggle-list">
                  {/* <li>Add licenses for family, friends, or team members to thrive together.</li>
              <li>Billed on the same price and payment terms as your primary account.</li> */}
                  {addOnPoints?.map((point, index) => (
                    <div className="pricing-point-list" key={index}>
                      <div>
                        <Point />
                      </div>
                      <p>{point}</p>
                      <div></div>
                    </div>
                  ))}
                </ul>
                {memberCount > 0 && (
                  <button
                    className="pricing-send-invite"
                    onClick={handleInviteSubmit}
                  >
                    {" "}
                    Send invitation
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

export default MainProduct;

import React, { useEffect, useRef, useState } from "react";
import "./style.css";

const CoachingScroll = ({ text }) => {
  const scrollContainerRef = useRef(null);
  const scrollStartTimeRef = useRef(null);
  const animationFrameRef = useRef(null);
  const [previousText, setPreviousText] = useState("");

  const getScrollDuration = (text) => {
    const wordsPerMinute = 150;
    const words = text.split(" ").length;
    return (words / wordsPerMinute) * 60 * 1000;
  };

  const scrollText = () => {
    if (!scrollContainerRef.current) return;

    const container = scrollContainerRef.current;
    const currentTime = Date.now();
    const elapsed = currentTime - scrollStartTimeRef.current;
    const duration = getScrollDuration(text);

    if (elapsed >= duration) {
      container.scrollTop = container.scrollHeight - container.clientHeight;
      return;
    }

    const progress = elapsed / duration;
    const targetScrollTop = container.scrollHeight - container.clientHeight;

    // Smooth easing function for better scrolling
    const easeInOutQuad = (t) =>
      t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;

    container.scrollTop = targetScrollTop * easeInOutQuad(progress);

    animationFrameRef.current = requestAnimationFrame(scrollText);
  };

  useEffect(() => {
    if (!text || text === previousText) return;

    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }

    scrollStartTimeRef.current = Date.now();
    animationFrameRef.current = requestAnimationFrame(scrollText);
    setPreviousText(text);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [text]);

  const cleanText = text?.replace(/^null/, "") || "";

  return (
    <div className="scroll-wrapper" style={{ width: "100%" }}>
      <div
        className="scroll-container"
        ref={scrollContainerRef}
        style={{
          overflow: "hidden",
          height: "57px",
          position: "relative",
        }}
      >
        <div
          className="scroll-content"
          style={{
            lineHeight: "2rem",
            whiteSpace: "pre-wrap",
            color: "#fff",
            fontFamily: "Urbanist",
            fontSize: "1.25rem",
            fontWeight: 500,
          }}
        >
          {cleanText}
        </div>
      </div>
    </div>
  );
};

export default CoachingScroll;
import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ReactComponent as SubmitArrow } from "../../Assets/icon/forward-submit-arrow.svg";

function CircularProgressBar({ value, disabled }) {
  return (
    <CircularProgressbarWithChildren
      value={value}
      strokeWidth={7}
      className={`assess-footer ${disabled && "disabled-progress"}`}
      styles={buildStyles({
        strokeLinecap: "butt",
        pathColor: disabled ? "#27407980" : "#274079",
        trailColor: disabled ? "#dcdcdc80" : "#F7F7F7",
      })}
    >
      <SubmitArrow />
    </CircularProgressbarWithChildren>
  );
}

export default CircularProgressBar;

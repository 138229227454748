// API call for Assessment related screens

import { axiosAssessmentInstance } from "../AxiosConfiguration";
import Cookies from "js-cookie";

const getCategoryId = async (data, is_Survey) => {
  const isSurveyQuest = data.find(
    (item) => item.isSurvey === true && item.status !== "completed"
  );
  // Check for the first object with status "in_progress"
  const inProgress = data.find(
    (item) => item.status === "in_progress" && !item.isSurvey
  );
  if (is_Survey) {
    if (isSurveyQuest?.status === "upcoming") {
      return {
        categoryID: isSurveyQuest.categoryId,
        create: 1,
        type: "survey",
      };
    } else if (isSurveyQuest?.status === "in_progress") {
      return {
        categoryID: isSurveyQuest.categoryId,
        create: 0,
        type: "survey",
      };
    } else {
      return { categoryID: null, create: 0, type: "survey" };
    }
  }
  if (inProgress) {
    return { categoryID: inProgress.categoryId, create: 0 };
  }

  // If no object is "in_progress", find the first with status "upcoming"
  const upcoming = data.find(
    (item) => item.status === "upcoming" && !item.isSurvey
  );

  if (upcoming) {
    return { categoryID: upcoming.categoryId, create: 1 };
  }

  // Return null or any default value if no such object is found
  return { categoryID: null, create: 0 };
};

const getCategoryIdByOrderID = async (data, orderID) => {
  // If no object is "in_progress", find the first with status "upcoming"
  const upcoming = data.find((item) => item.orderNum === orderID + 1);

  if (upcoming) {
    return { categoryID: upcoming.categoryId, create: 1 };
  }

  // Return null or any default value if no such object is found
  return null;
};

const getUserQuestionInitialStart = async (data) => {
  // Check for the first object with status "in_progress"
  const inProgress = data.find((item) => item.status === "in_progress");

  if (inProgress) {
    return "in_progress";
  }

  // If no object is "in_progress", find the first with status "upcoming"
  const upcoming = data.find((item) => item.status === "upcoming");

  if (upcoming) {
    return "upcoming";
  }

  // Return null or any default value if no such object is found
  return null;
};

export const startPageQuestionDetails = async (is_Survey = false) => {
  try {
    //init Params

    console.log(localStorage.getItem("userId"), Cookies.get("localId"), "jjj");
    const params = {};
    // params.user_id = Cookies.get("localId") || localStorage.getItem("userId");
    params.fetch_type = "all";

    //api call to get categoryID
    const response = await axiosAssessmentInstance.get("/api/status/test", {
      params: params,
    });

    //function call to get catId based on the array
    const status = await getUserQuestionInitialStart(response?.data?.payload);

    return status;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};
export const startQuestions = async (is_Survey = false) => {
  try {
    //init Params
    const params = {};
    // params.user_id = Cookies.get("localId");
    params.fetch_type = "all";
    params.is_survey = is_Survey;

    //api call to get categoryID
    const response = await axiosAssessmentInstance.get("/api/status/test", {
      params: params,
    });

    //function call to get catId based on the array
    const { categoryID, create, type } = await getCategoryId(
      response?.data?.payload,
      is_Survey
    );

    console.log(categoryID, create, type, "create");
    if (categoryID === null) return { categoryID: null };

    const testData = {
      item_id: categoryID,
      item_type: "category",
      // user_id: Cookies.get("localId"),
      is_survey: is_Survey,
    };
    if (create === 1) {
      testData.create = 1;
    }
    //API call to get question info of the test
    const questionInfo = await axiosAssessmentInstance.post(
      "/api/initiate/test",
      testData
    );

    console.log(questionInfo.data, "start");
    return questionInfo.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

//API to get Next Question data
export const nextQuestions = async (data = {}) => {
  try {
    const response = await axiosAssessmentInstance.post("/api/next", {
      ...data,
      // user_id: Cookies.get("localId"),
    });
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

//API to get Prev Question data

export const prevQuestions = async (data = {}) => {
  try {
    const response = await axiosAssessmentInstance.post("/api/prev", {
      ...data,
      // user_id: Cookies.get("localId"),
    });
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const startNextTest = async (orderID, is_Survey = false) => {
  try {
    //init Params
    const params = {};
    // params.user_id = Cookies.get("localId");
    params.fetch_type = "all";

    //api call to get categoryID
    const response = await axiosAssessmentInstance.get("/api/status/test", {
      params: params,
    });

    //function call to get catId based on the array
    const { categoryID, create } = await getCategoryIdByOrderID(
      response?.data?.payload,
      orderID
    );

    console.log(create, "create");

    const testData = {
      item_id: categoryID,
      item_type: "category",
      // user_id: Cookies.get("localId"),
      is_survey: is_Survey,
    };
    if (create === 1) {
      testData.create = 1;
    }
    //API call to get question info of the test
    const questionInfo = await axiosAssessmentInstance.post(
      "/api/initiate/test",
      testData
    );

    console.log(questionInfo.data, "start");
    return questionInfo.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const startNextTestbyCategoryId = async (
  categoryID,
  is_Survey = true
) => {
  try {
    const testData = {
      item_id: categoryID,
      item_type: "category",
      // user_id: Cookies.get("localId"),
      is_survey: is_Survey,
      create: 1,
    };
    //API call to get question info of the test
    const questionInfo = await axiosAssessmentInstance.post(
      "/api/initiate/test",
      testData
    );

    console.log(questionInfo.data, "start");
    return questionInfo.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

import React, { useRef, useLayoutEffect, Fragment } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { Box, minHeight, minWidth } from "@mui/system";
import { Typography } from "@mui/material";

export const GraphDecisionInfo = (props) => {
  const element = useRef();
  const { isMobile, isPdf, data, cid, isGraphOnly = true, isStory } = props;

  const setAttr = (ele, obj) => {
    if (obj && ele) {
      for (let key in obj) {
        ele.attr(key, obj[key]);
      }
    }
    return ele;
  };

  const generateD3Chart = (_data, element) => {
    const _dmData = _data;
    const defaultOptions = {
      width: $(element?.current).width(),
      height: $(element?.current).width(),
      viewBox:
        "0 0 " +
        $(element?.current).width() +
        " " +
        $(element?.current).width(),
      preserveAspectRatio: "none",
    };

    let graphId = "#" + $(element?.current).attr("id");
    let d3GraphWrap = d3.select(graphId);
    let d3Graph = d3GraphWrap.insert("svg", "Box.on-graph-labels");

    d3Graph = setAttr(d3GraphWrap.select("svg"), defaultOptions);

    const gradient = d3Graph.append("defs").append("linearGradient");
    gradient
      .attr("id", "gradientLinear")
      .attr("x1", "0%")
      .attr("x2", "100%")
      .attr("y1", "0%")
      .attr("y2", "0%");

    gradient.append("stop").attr("offset", "0%").attr("stop-color", "#F4C908");
    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#FCB0A8");

    var gWidth = $(element?.current).find("svg").width(),
      gHeight = $(element?.current).find("svg").width(),
      xMid = gWidth / 2,
      yMid = gHeight / 2;

    /* The Grey Background Rectangle */
    setAttr(d3Graph.append("rect"), {
      fill: "#F8F8F8",
      x: 0,
      y: 0,
      width: "100%",
      height: "100%",
    });

    /* Animate Decision Making Rect */
    const dPointX = (gWidth / 2 / 100) * _dmData.Rapid;
    const dPointY = (gHeight / 2 / 100) * _dmData.Inward;

    const rect = d3Graph
      .append("rect")
      .attr("fill", "url(#gradientLinear)")
      .attr("x", xMid) // Start at the center
      .attr("y", yMid) // Start at the center
      .attr("width", 0) // Start width 0
      .attr("height", 0) // Start height 0
      .attr("rx", 5)
      .attr("ry", 5);

    // Animate attributes

    rect
      .transition()
      .duration(2000) // 1 second
      .attr("x", dPointX) // Target x position
      .attr("y", dPointY) // Target y position
      .attr("width", gWidth / 2) // Target width
      .attr("height", gHeight / 2); // Target height

    /* Add Grid and Axis Lines as Before */
    setAttr(d3Graph.append("line"), {
      x1: xMid,
      y1: 0,
      x2: xMid,
      y2: gHeight,
      "stroke-width": 1,
      stroke: "#13131666",
    });

    setAttr(d3Graph.append("line"), {
      x1: 0,
      y1: yMid,
      x2: gWidth,
      y2: yMid,
      "stroke-width": 1,
      stroke: "#13131666",
    });

    const gridLines = 20,
      ySpacing = gHeight / gridLines,
      xSpacing = gWidth / gridLines;

    for (var i = 0; i < gridLines; i++) {
      if (i > 0 && i < gridLines && i !== 10) {
        setAttr(d3Graph.append("rect"), {
          fill: "#13131666",
          x: i % 5 === 0 ? xMid - 8 : xMid - 5,
          y: ySpacing * i,
          width: i % 5 === 0 ? 16 : 10,
          height: 1,
        });
        setAttr(d3Graph.append("rect"), {
          fill: "#13131666",
          x: xSpacing * i,
          y: i % 5 === 0 ? yMid - 8 : yMid - 5,
          width: 1,
          height: i % 5 === 0 ? 16 : 10,
        });
      }
    }
  };

  useLayoutEffect(() => {
    if (element.current) {
      generateD3Chart(data, element);
    }
  }, []);

  return (
    <div
      style={{
        height: "320px",
        width: isMobile ? "260px" : "310px",
      }}
      id={"decisionMaking"}
      ref={element}
    ></div>
  );
};

import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import { isMobile } from "react-device-detect";
const PersonalityGraphNoBG = ({
  personalityGraphdata = {
    Primary: {
      personalityType: "Navigator",
      segmentScore: 6316,
      segmentGraph: [0.86, 0.43, 0.14, 0.86],
      valid: true,
    },
    UnderPressure: {
      personalityType: "Visionary",
      segmentScore: 7324,
      segmentGraph: [1, 0.43, 0.29, 0.57],
      valid: true,
    },
  },
  isUnderPressure = false,
  showTwo = false,
}) => {
  const chartRef = useRef();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize); // Cleanup the event listener on component unmount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const width = isMobile
      ? 320
      : screenWidth > 1240
      ? 300
      : screenWidth > 1840
      ? 700
      : 300; // Square background dimensions
    const height = isMobile
      ? 320
      : screenWidth > 1240
      ? 300
      : screenWidth > 1840
      ? 700
      : 300;
    const radius = Math.min(width, height) / 2 - 50; //personality graph data

    const data = isUnderPressure
      ? [
          {
            axis: "Big Picture Oriented",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[0] || 0,
          },
          {
            axis: "People Driven",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[1] || 0,
          },
          {
            axis: "Detail Oriented",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[2] || 0,
          },
          {
            axis: "Results Driven",
            value: personalityGraphdata?.UnderPressure?.segmentGraph[3] || 0,
          },
        ]
      : [
          {
            axis: "Big Picture Oriented",
            value: personalityGraphdata?.Primary?.segmentGraph[0] || 0,
          },
          {
            axis: "People Driven",
            value: personalityGraphdata?.Primary?.segmentGraph[1] || 0,
          },
          {
            axis: "Detail Oriented",
            value: personalityGraphdata?.Primary?.segmentGraph[2] || 0,
          },
          {
            axis: "Results Driven",
            value: personalityGraphdata?.Primary?.segmentGraph[3] || 0,
          },
        ];

    const radarSizeMultiplier = 2;
    const angleSlice = (2 * Math.PI) / data.length;
    const rScale = d3
      .scaleLinear()
      .range([0, radius * radarSizeMultiplier]) // Multiply radius for scaling
      .domain([0, 1]); // Clear previous graph

    d3.select(chartRef.current).selectAll("*").remove(); // Create SVG

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`); // Draw axes and legends

    data.forEach((d, i) => {
      const x = rScale(1) * Math.cos(angleSlice * i - Math.PI / 2);
      const y = rScale(1) * Math.sin(angleSlice * i - Math.PI / 2); // Axes // Legends (outer positioning) //   const legendX = rScale(1.4) * Math.cos(angleSlice * i - Math.PI / 2); //   const legendY = rScale(1.4) * Math.sin(angleSlice * i - Math.PI / 2); //   svg //     .append("text") //     .attr("x", 10) //     .attr("y", 20) //     // .attr("dy", "0.35em") //     .style("text-anchor", "start") //     .style("font-size", "12px") //     .text(d.axis);
    }); // Radar area

    const rotationOffset = Math.PI / 4;
    const radarLine = d3
      .lineRadial()
      .radius((d) => rScale(d.value))
      .angle((d, i) => i * angleSlice - rotationOffset); //

    const defs = svg.append("defs"); // Helper to animate radar area

    const animateRadar = (data, gradientId, opacity, strokeColor) => {
      const initialData = data.map((d) => ({ ...d, value: 0 })); // Append radar area (start from 0 values)

      const path = svg
        .append("path")
        .datum(initialData)
        .attr("d", radarLine)
        .style("fill", `#fff`)
        .style("fill-opacity", opacity)
        .style("stroke", strokeColor)
        .style("stroke-width", 2); // Transition to actual values

      path
        .datum(data)
        .transition()
        .duration(1000) // Animation duration (1 second)
        .ease(d3.easeCubicInOut)
        .attr("d", radarLine);
    }; // Animate both radars

    animateRadar(data, "gradient", 0.8); //axis

    // svg
    //   .append("line")
    //   .attr("x1", -width)
    //   .attr("y1", -height)
    //   .attr("x2", width)
    //   .attr("y2", height)
    //   .style("stroke", "#fff")
    //   .attr("stroke-width", "1px");

    // svg
    //   .append("line")
    //   .attr("x1", width)
    //   .attr("y1", -height)
    //   .attr("x2", -width)
    //   .attr("y2", height)
    //   .style("stroke", "#fff")
    //   .attr("stroke-width", "1px"); // Thicker line
  }, []);

  return <div ref={chartRef} />;
};

export default PersonalityGraphNoBG;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "../../Components/ProgressBar";
import EmailPage from "./EmailPage";
import PasswordPage from "./PasswordPage";
import MobilePage from "./MobilePage";
import BasicDetailsPage from "./BasicDetailsPage";
import GenderPronounsPage from "./GenderPronounsPage";
import PlanSelection from "../Pricing";
import QRpage from "./QRpage";
import PlanPage from "./PlanPage";
import logo from "../../Assets/images/logo.png";
import { useHeader } from "../../Providers/HeaderContextProvider";
import GradientBackground from "../../Components/AddRelationshipHeaderGradient";
import FaithUpgrade from "./FaithUpgrade";
import Cookies from "js-cookie";
import { useLayoutEffect } from "react";

const SignupPage = () => {
  const { setHeaderContent } = useHeader();
  const location = useLocation();
  const navigate = useNavigate();
  const totalSteps = 7;
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");
  const oobCode = queryParams.get("oobCode");

  const getInitialStep = () => {
    const queryParams = new URLSearchParams(location.search);
    const stepFromURL = queryParams.get("step");
    return stepFromURL ? parseInt(stepFromURL, 10) : 1;
  };

  const [step, setStep] = useState(getInitialStep);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  console.log(mode, "oobCode");
  const idToken = Cookies.get("idtoken");
  console.log("step", step);

  useEffect(() => {
    if (
      idToken &&
      mode !== "verifyMobile" &&
      mode !== "verifyEmail" &&
      mode !== "verifyBasicDetails" &&
      mode !== "verifyGenderPronouns" &&
      mode !== "verifySubscription" &&
      mode !== "verifyFaith"
    ) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    console.log("Current pathname:", location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    setHeaderContent(false);
  }, [setHeaderContent]);

  useEffect(() => {
    // Reset to Step 1 if the path is exactly "/signup"
    if (location.pathname === "/signup" && !location.search.includes("step=")) {
      setStep(1);
      localStorage.setItem("signupStep", "1"); // Ensure localStorage is updated
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (location.pathname === "/dashboard" || idToken) return;

    if (step === 1 && mode === "verifyEmail" && oobCode) {
      setStep(2); // Navigate to Password Creation step (Step 2)
    } else if (mode === "resetPassword") {
      // Redirect to Reset Password page if mode=resetPassword is found
      navigate("/reset-password" + location.search);
    }
  }, [location, step]);

  useEffect(() => {
    if (location.pathname === "/dashboard" || idToken) return;
    // Update the URL with the current step
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("step", step.toString());
    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });

    // Save the current step to localStorage
    localStorage.setItem("signupStep", step);
  }, [step, location.pathname, location.search]);

  const handleNext = (faith = false, user_type = "") => {
    if (faith) {
      setStep(7);
    } else if (user_type === "sub-user") {
      navigate("/chat");
    } else {
      setStep((prevStep) => Math.min(prevStep + 1, totalSteps));
    }
  };

  const handleBack = () => {
    if (step === 7) {
      setStep(5);
    } else {
      setStep((prevStep) => Math.max(prevStep - 1, 1));
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <div style={{ height: "20dvh" }}>
        <GradientBackground style={{ height: "20dvh" }}>
          <img src={logo} alt="Logo" className="logo" />
          <ProgressBar step={step} totalSteps={totalSteps} />
        </GradientBackground>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {step === 1 && <EmailPage onNext={handleNext} />}
        {step === 2 && (
          <PasswordPage
            oobCode={oobCode}
            onNext={handleNext}
            onBack={handleBack}
          />
        )}
        {step === 3 && (
          <MobilePage
            onNext={handleNext}
            onBack={handleBack}
            setFirstName={setFirstName}
            setLastName={setLastName}
          />
        )}
        {step === 4 && (
          <BasicDetailsPage
            onNext={handleNext}
            onBack={handleBack}
            userFirstName={firstName}
            userLastName={lastName}
          />
        )}
        {step === 5 && (
          <GenderPronounsPage onNext={handleNext} onBack={handleBack} />
        )}
        {step === 6 && (
          <PlanSelection onNext={handleNext} onBack={handleBack} />
        )}
        {/* {step === 7 && <QRpage onBack={handleBack} />} */}
        {step === 7 && <FaithUpgrade onBack={handleBack} />}
      </div>
    </div>
  );
};

export default SignupPage;

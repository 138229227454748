import React from "react";

function PotraitsCommonHeadBottom({ heading, summary }) {
  return (
    <div className="potraits-common-head-bottom-hero">
      <heading className="potraits-common-head-bottom-heading">
        {heading}
      </heading>
      <section className="potraits-common-head-bottom-section">
        {summary}
      </section>
    </div>
  );
}

export default PotraitsCommonHeadBottom;

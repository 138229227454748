import React, { useState, useEffect } from "react";
import { ReactComponent as Userplus } from "../../Assets/icon/user-plus-01.svg";
import VoiceRecorder from "../../Components/VoiceRecoder";
import InputField from "../../Components/AddRelationshipInput";
import SelectField from "../../Components/AddRelationshipSelect";
import nextbtn from "../../Assets/images/arrow-right.png";
import placeholderImage from "../../Assets/images/placeholder.png";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  addRelationship,
  uploadFiles,
  fetchCategoriesAndTypes,
} from "../../Services/Realtionship";
import "./style.css";

// Email validation regex
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const NAME_REGEX = /^[A-Za-z]+$/;

const AddRelationship = ({ onClose, onRelationshipAdded }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    nickname: "",
    relationship_type: "",
    relationship_length: "",
    relationship_category: "",
    relationship_status: "",
    relationship_goals: "",
    email: "",
    country_code: "",
    contact: "",
    photo: null,
    pronunciation: "",
  });

  const [categories, setCategories] = useState([]);
  const [relationTypes, setRelationTypes] = useState([]);
  const [relationLengths, setRelationLengths] = useState([]);
  const [relationStatuses, setRelationStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [photoRestriction, setphotoRestriction] = useState(false);

  // Fetch initial data on mount
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const categoriesData = await fetchCategoriesAndTypes();
        setCategories(Array.isArray(categoriesData) ? categoriesData : []);

        const lengthsData = await fetchCategoriesAndTypes("length");
        setRelationLengths(Array.isArray(lengthsData) ? lengthsData : []);

        const statusesData = await fetchCategoriesAndTypes("status");
        setRelationStatuses(Array.isArray(statusesData) ? statusesData : []);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
    fetchInitialData();
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const errors = {};
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "first_name") {
      if (!value) {
        errors.first_name = "First name is required";
      } else if (value !== value?.trim()) {
        errors.first_name = "Name should not begin or end with spaces";
      } else if (!NAME_REGEX.test(value)) {
        errors.first_name = "Name should only contain letters";
      } else if (value) {
        errors.first_name = "";
      }
    }

    if (name === "last_name") {
      if (value) {
        if (value !== value?.trim()) {
          errors.last_name = "Name should not begin or end with spaces";
        } else if (!NAME_REGEX.test(value)) {
          errors.last_name = "Name should only contain letters";
        } else if (value) {
          errors.last_name = "";
        }
      }
    }

    // Last name validation (optional field)

    setValidationErrors({
      ...validationErrors,
      ...errors,
    });

    if (name === "relationship_category" && value) {
      try {
        const response = await fetchCategoriesAndTypes(value);
        if (Array.isArray(response)) {
          setRelationTypes(response);
          setFormData((prev) => ({
            ...prev,
            relationship_type: "", // Reset type when category changes
          }));
        } else {
          console.error("Unexpected response structure:", response);
          setRelationTypes([]);
        }
      } catch (error) {
        console.error("Error fetching relationship types:", error);
      }
    }
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];

    // Check file size (1 MB = 1,048,576 bytes)
    const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB

    if (!file) {
      return;
    }

    if (file.size > maxSizeInBytes) {
      setphotoRestriction(true);
      // Optional: You can customize this alert or use a more user-friendly notification
      //   alert("File is too large. Please upload a file smaller than 1 MB.");
      // Clear the file input
      event.target.value = null;
      return;
    }

    if (file) {
      setFormData((prev) => ({
        ...prev,
        photo: file,
      }));
    }
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      contact: value || "", // Allow empty strings for optional fields
    }));
  };

  const isFormFilled = () =>
    formData.first_name &&
    formData.relationship_type &&
    formData.relationship_length &&
    formData.relationship_category &&
    formData.relationship_status;

  const validateForm = () => {
    const errors = {};

    // Name validations

    // Required field validations
    if (!formData.relationship_type) {
      errors.relationship_type = "Relationship type is required";
    }
    if (!formData.relationship_length) {
      errors.relationship_length = "Relationship length is required";
    }
    if (!formData.relationship_category) {
      errors.relationship_category = "Relationship category is required";
    }
    if (!formData.relationship_status) {
      errors.relationship_status = "Relationship status is required";
    }

    // Email validation (if provided)
    if (formData.email) {
      if (formData.email !== formData.email?.trim()) {
        errors.email = "Email should not contain leading or trailing spaces";
      } else if (!EMAIL_REGEX.test(formData.email)) {
        errors.email = "Please enter a valid email address";
      }
    }

    // Phone number validation
    if (formData.contact) {
      const parsedPhoneNumber = parsePhoneNumberFromString(formData.contact);
      if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
        errors.contact = "Please enter a valid phone number";
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before submission
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Parse phone number to get country code and national number
      const parsedPhoneNumber = formData.contact
        ? parsePhoneNumberFromString(formData.contact)
        : null;

      const countryCode = parsedPhoneNumber
        ? `+${parsedPhoneNumber.countryCallingCode}`
        : null;
      const contact = parsedPhoneNumber
        ? parsedPhoneNumber.nationalNumber
        : null;

      // Construct payload with separated country code and contact
      const payload = {
        ...formData,
        country_code: countryCode,
        contact: contact,
      };

      console.log("Payload to API:", payload);
      const response = await addRelationship(payload);
      console.log("API Response:", response);

      // Directly check response and set error
      if (response.status === "failed" || response.status_code === 400) {
        setError(response.message);
        return;
      }

      const relationId = response.payload?._id;
      if (!relationId) {
        setError("Relationship ID missing");
        return;
      }

      if (formData.photo || formData.pronunciation) {
        await uploadFiles(relationId, formData.photo, formData.pronunciation);
      }

      onRelationshipAdded();
      onClose();
    } catch (error) {
      console.error("Error:", error);
      setError(
        error?.response?.data?.message ||
          "There was an issue with the form submission. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relationship-modal-content">
      <form onSubmit={handleSubmit}>
        <div className="upload-photo">
          <input
            type="file"
            id="photo-upload"
            accept="image/*"
            onChange={handlePhotoUpload}
            style={{ display: "none" }}
          />
          <label htmlFor="photo-upload" className="upload-photo-label">
            <div className="upload-icon">
              <img
                src={
                  formData.photo
                    ? URL.createObjectURL(formData.photo)
                    : placeholderImage
                }
                alt="Profile"
                className="basic-profile-image"
              />
            </div>
            <span>Upload photo</span>
          </label>
        </div>

        <div className="form-grid">
          <div>
            <InputField
              label="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              placeholder="Enter first name"
              error={validationErrors.first_name}
            />
            {validationErrors.first_name && (
              <p className="error-message name-align-left">
                {validationErrors.first_name}
              </p>
            )}
          </div>

          <div>
            <InputField
              label="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              placeholder="Enter last name"
              optional
              error={validationErrors.last_name}
            />
            {validationErrors.last_name && (
              <p className="error-message name-align-left">
                {validationErrors.last_name}
              </p>
            )}
          </div>

          <div className="form-group full-width">
            <label>
              Name pronunciation <span className="optional">(optional)</span>
            </label>
            <VoiceRecorder
              onRecordingComplete={(blob, url) => {
                setFormData((prev) => ({
                  ...prev,
                  pronunciation: url,
                }));
              }}
            />
          </div>

          <SelectField
            label="Relationship Category"
            name="relationship_category"
            value={formData.relationship_category}
            onChange={handleInputChange}
            options={[
              { value: "", label: "Select Category" },
              ...categories.map((category) => ({
                value: category,
                label: category,
              })),
            ]}
          />

          <SelectField
            label="Relationship Length"
            name="relationship_length"
            value={formData.relationship_length}
            onChange={handleInputChange}
            options={[
              { value: "", label: "Select Length" },
              ...relationLengths.map((length) => ({
                value: length,
                label: length,
              })),
            ]}
          />

          <SelectField
            label="Relationship Type"
            name="relationship_type"
            value={formData.relationship_type}
            onChange={handleInputChange}
            options={[
              { value: "", label: "Select Type" },
              ...relationTypes.map((type) => ({ value: type, label: type })),
            ]}
            disabled={!formData.relationship_category}
          />

          <SelectField
            label="Relationship Status"
            name="relationship_status"
            value={formData.relationship_status}
            onChange={handleInputChange}
            options={[
              { value: "", label: "Select Status" },
              ...relationStatuses.map((status) => ({
                value: status,
                label: status,
              })),
            ]}
          />

          <div className="form-group full-width">
            <label>
              Relationship Goals <span className="optional">(optional)</span>
            </label>
            <textarea
              name="relationship_goals"
              value={formData.relationship_goals}
              onChange={handleInputChange}
              rows="4"
            />
          </div>

          <InputField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Enter email"
            optional
            error={validationErrors.email}
          />

          <div className="form-group-mobile">
            <div style={{ marginBottom: "6px" }}>
              <label htmlFor="" className="mobile-label-addrelation">
                Mobile Number{" "}
              </label>
              <span className="optional">(optional)</span>
            </div>
            <PhoneInput
              id="phone-number"
              international
              defaultCountry="US"
              value={formData.contact}
              onChange={handlePhoneChange}
              className={`addrelationship-phoneinput ${
                validationErrors.contact ? "error" : ""
              }`}
              placeholder="Enter phone number"
              autoFocus={false}
            />
            {validationErrors.contact && (
              <p className="error-message">{validationErrors.contact}</p>
            )}
          </div>
        </div>

        <div className="modal-actions">
          <button
            type="submit"
            className={`add-person-btn ${isFormFilled() ? "filled" : ""}`}
            disabled={!isFormFilled() || loading}
          >
            {loading ? "Submitting..." : "Add Relationship"}
            <img src={nextbtn} alt="nextbtn" className="nxtbtn" />
          </button>
          <button type="button" className="cancel-btn" onClick={onClose}>
            Cancel
          </button>
        </div>
        {validationErrors.general && (
          <p className="error-message mt-10">{validationErrors.general}</p>
        )}
        {photoRestriction && (
          <div style={{ color: "red", textAlign: "center" }}>
            File is too large. Please upload a file smaller than 1 MB.
          </div>
        )}
        {error && <p className="error-message mt-10">{error}</p>}
      </form>
    </div>
  );
};

export default AddRelationship;

import React, { useEffect, useState } from "react";
import { ReactComponent as UpArrow } from "../../Assets/icon/chevron-up.svg";
import { ReactComponent as DownArrow } from "../../Assets/icon/chevron-down.svg";
import SearchableDropdown from "../../Components/SearchableDropdown";
import { ReactComponent as Point } from "../../Assets/icon/li_dot.svg";

const FaithContainer = ({
  product,
  setOptionSelected,
  selectedPricingReccursion,
  selectedFaithPriceId,
  faithToAllAddedUser,
}) => {
  const [showFaith, setShowFaith] = useState(false);
  const [selectedFaith, setselectedFaith] = useState("");
  const [primaryChecked, setPrimaryChecked] = useState(true);
  const [allAccountsChecked, setAllAccountsChecked] = useState(false);

  const priceSelected = product?.pricingDetails?.find(
    (prod) => prod?.recurring?.interval === selectedPricingReccursion
  );

  useEffect(() => {
    const priceSelected = product?.pricingDetails;
    selectedFaithPriceId(priceSelected);
  }, [selectedPricingReccursion]);

  useEffect(() => {
    faithToAllAddedUser(allAccountsChecked);
  }, [allAccountsChecked]);

  const onOptionSelected = (e) => {
    console.log("test", e);
    setselectedFaith(e);
    setOptionSelected(e);
  };

  const handleOptionClick = (option) => {
    // if (option === "primary") {
    //   setPrimaryChecked(true);
    //   setAllAccountsChecked(false);
    // } else {
    //   setPrimaryChecked(false);
    //   setAllAccountsChecked(true);
    // }

    setAllAccountsChecked((prev) => !prev);
  };
  console.log(allAccountsChecked, "priceSelected");
  return (
    <div className="pricing-additional-cards margin-restricted-top">
      {" "}
      <div className="pricing-toggle-card">
        <div
          className="pricing-toggle-header"
          onClick={() => setShowFaith(!showFaith)}
        >
          <div className="pricing-toggle-title">
            <h3>Integrate Your Faith and Belief System</h3>
          </div>
          <button className="pricing-toggle-button">
            {showFaith ? <UpArrow /> : <DownArrow />}
          </button>
        </div>

        {showFaith && (
          <div className="pricing-toggle-content">
            <div className="pricing-radio-group">
              <div className="pricing-toggle-price-container">
                <span className="pricing-premium">Premium Option</span>
                <span className="pricing-toggle-price">
                  ${priceSelected?.unit_amount / 100}
                  <span className="pricing-toggle-price-txt">
                    {" "}
                    /month per account
                  </span>
                </span>
              </div>

              <div className="pricing-dropdown-option-container">
                <SearchableDropdown
                  onBoarding={true}
                  setSelectedFaith={onOptionSelected}
                />
              </div>

              <div
                className="pricing-radio-option"
                onClick={() => handleOptionClick("all")}
              >
                <span>Add to all my accounts</span>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={allAccountsChecked}
                    onChange={() => handleOptionClick("all")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <p className="pricing-toggle-description">
              Enhance your Aris experience with personalized, faith-aligned
              coaching
            </p>
            <ul className="pricing-toggle-list">
              {product?.marketing_features?.map((feature) => (
                <div className="pricing-point-list">
                  <div>
                    <Point />
                  </div>
                  <p>{feature?.name}</p>
                  <div></div>
                </div>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default FaithContainer;

import React from 'react'
import { ReactComponent as CloseIcon } from "../../../../../Assets/icon/close.svg";

const SessionCategory = ({onClose, hideCloseIcon = false, selectedSession, setSelectedSession, setPage, isReschedule}) => {

    console.log("isReschedule", isReschedule);
  return (
    <div className='session-selection-container'>
        <div className='session-selection-header'>
            <p>Aris Live Session</p>
            {!hideCloseIcon && <CloseIcon style={{cursor:'pointer'}} onClick={onClose}/>}
        </div>
        <div className='session-buttons-container'>
            <div className={`session-button ${selectedSession === 'private' && 'selected'}`} onClick={() => setSelectedSession('private')}>
                Private Session
            </div>
            <div className={`session-button ${selectedSession === 'group' && 'selected'}`} onClick={() => setSelectedSession('group')}>
                Group Session
            </div>
        </div>
        <div className="buttons-container">
            {!isReschedule && <div className="start-button" onClick={() => window.location.pathname = `/coach/${selectedSession}`}>Start now</div>}
            <div className="schedule-button" onClick={() => setPage('date')}>Schedule for later</div>
        </div>
    </div>
  )
}

export default SessionCategory
export const chooseLottieFile = {
  v: "5.9.0",
  fr: 60,
  ip: 0,
  op: 180,
  w: 480,
  h: 350,
  nm: "03_choose",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "cursor",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 10,
              s: [551, 376.375, 0],
              to: [-246, -3, 0],
              ti: [0.5, 80.25, 0],
            },
            { t: 40, s: [67, 173.875, 0], h: 1 },
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 90,
              s: [67, 173.875, 0],
              to: [0.5, 80.25, 0],
              ti: [-246, -3, 0],
            },
            { t: 120, s: [551, 376.375, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [94, 10.875, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 40,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 50,
              s: [90, 90, 100],
            },
            { t: 60, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [94, 10.875],
                    [94, 33.625],
                    [100.062, 29],
                    [105, 39.125],
                    [109.625, 36.875],
                    [104.062, 26.75],
                    [112.375, 25.875],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 10,
      op: 310,
      st: 10,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "checkbox",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 175, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.392, 0.391],
                    [0, 0.55],
                    [0, 0],
                    [-0.392, 0.391],
                    [-0.55, 0],
                    [0, 0],
                    [-0.392, -0.392],
                    [0, -0.55],
                    [0, 0],
                    [0.392, -0.392],
                    [0.55, 0],
                  ],
                  o: [
                    [-0.55, 0],
                    [-0.392, -0.392],
                    [0, 0],
                    [0, -0.55],
                    [0.392, -0.392],
                    [0, 0],
                    [0.55, 0],
                    [0.392, 0.391],
                    [0, 0],
                    [0, 0.55],
                    [-0.392, 0.391],
                    [0, 0],
                  ],
                  v: [
                    [-183, 9],
                    [-184.412, 8.413],
                    [-185, 7],
                    [-185, -7],
                    [-184.412, -8.412],
                    [-183, -9],
                    [-169, -9],
                    [-167.588, -8.412],
                    [-167, -7],
                    [-167, 7],
                    [-167.588, 8.413],
                    [-169, 9],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-177.4, 4.2],
                    [-170.35, -2.85],
                    [-171.75, -4.25],
                    [-177.4, 1.4],
                    [-180.25, -1.45],
                    [-181.65, -0.05],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.4], y: [1] },
                    o: { x: [0.6], y: [0] },
                    t: 40,
                    s: [0],
                  },
                  {
                    i: { x: [0.4], y: [1] },
                    o: { x: [0.157], y: [0] },
                    t: 60,
                    s: [100],
                  },
                  {
                    i: { x: [0.4], y: [1] },
                    o: { x: [0.6], y: [0] },
                    t: 150,
                    s: [100],
                  },
                  { t: 170, s: [0] },
                ],
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.51, 0.89, 0.8, 0.5, 0.506, 0.849, 0.865, 1, 0.502,
                    0.808, 0.929,
                  ],
                  ix: 9,
                },
              },
              s: { a: 0, k: [-164, 13], ix: 5 },
              e: { a: 0, k: [-184.062, -9.187], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Layer 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [203, 175, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-3.866, 0],
                    [0, 0],
                    [0, -3.866],
                    [0, 0],
                    [3.866, 0],
                    [0, 0],
                    [0, 3.866],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [3.866, 0],
                    [0, 0],
                    [0, 3.866],
                    [0, 0],
                    [-3.866, 0],
                    [0, 0],
                    [0, -3.866],
                  ],
                  v: [
                    [-96, -7],
                    [96, -7],
                    [103, 0],
                    [103, 0],
                    [96, 7],
                    [-96, 7],
                    [-103, 0],
                    [-103, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.4], y: [1] },
                    o: { x: [0.6], y: [0] },
                    t: 40,
                    s: [0.929411828518, 0.929411828518, 0.937254965305, 1],
                  },
                  {
                    i: { x: [0.4], y: [1] },
                    o: { x: [0.182], y: [0] },
                    t: 60,
                    s: [1, 1, 1, 1],
                  },
                  {
                    i: { x: [0.4], y: [1] },
                    o: { x: [0.6], y: [0] },
                    t: 150,
                    s: [1, 1, 1, 1],
                  },
                  {
                    t: 170,
                    s: [0.929411828518, 0.929411828518, 0.937254965305, 1],
                  },
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 40,
      op: 340,
      st: 40,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "button_gradient",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.4], y: [1] }, o: { x: [0.6], y: [0] }, t: 40, s: [0] },
            {
              i: { x: [0.4], y: [1] },
              o: { x: [0.157], y: [0] },
              t: 60,
              s: [100],
            },
            {
              i: { x: [0.4], y: [1] },
              o: { x: [0.6], y: [0] },
              t: 150,
              s: [100],
            },
            { t: 170, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 175, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-6.627, 0],
                    [0, 0],
                    [0, -6.627],
                    [0, 0],
                    [6.627, 0],
                    [0, 0],
                    [0, 6.627],
                  ],
                  o: [
                    [0, -6.627],
                    [0, 0],
                    [6.627, 0],
                    [0, 0],
                    [0, 6.627],
                    [0, 0],
                    [-6.627, 0],
                    [0, 0],
                  ],
                  v: [
                    [-208, -20],
                    [-196, -32],
                    [196, -32],
                    [208, -20],
                    [208, 20],
                    [196, 32],
                    [-196, 32],
                    [-208, 20],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0.23, 0.737, 0.961, 0.655, 0.615, 0.663, 0.961, 0.767, 1,
                    0.588, 0.961, 0.878,
                  ],
                  ix: 9,
                },
              },
              s: { a: 0, k: [1, 7], ix: 5 },
              e: { a: 0, k: [-10.995, -60.012], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 40,
      op: 340,
      st: 40,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "buttons",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 95, 0], ix: 2, l: 2 },
        a: { a: 0, k: [240, 95, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.829, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.829, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [423.5, 103.64], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [416.5, 103.64], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.829, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.829, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [423.5, 95], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [416.5, 95], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.829, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.829, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [423.5, 86.36], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [416.5, 86.36], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [420, 95], ix: 2 },
              a: { a: 0, k: [420, 95], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 4",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                      ],
                      v: [
                        [-96, -7],
                        [96, -7],
                        [103, 0],
                        [103, 0],
                        [96, 7],
                        [-96, 7],
                        [-103, 0],
                        [-103, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [203, 95], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 50, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 3",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-183, -73],
                        [-169, -73],
                        [-169, -87],
                        [-183, -87],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.392, 0.392],
                        [0, 0.55],
                        [0, 0],
                        [-0.392, 0.392],
                        [-0.55, 0],
                        [0, 0],
                        [-0.392, -0.392],
                        [0, -0.55],
                        [0, 0],
                        [0.392, -0.392],
                        [0.55, 0],
                      ],
                      o: [
                        [-0.55, 0],
                        [-0.392, -0.392],
                        [0, 0],
                        [0, -0.55],
                        [0.392, -0.392],
                        [0, 0],
                        [0.55, 0],
                        [0.392, 0.392],
                        [0, 0],
                        [0, 0.55],
                        [-0.392, 0.392],
                        [0, 0],
                      ],
                      v: [
                        [-183, -71],
                        [-184.412, -71.588],
                        [-185, -73],
                        [-185, -87],
                        [-184.412, -88.413],
                        [-183, -89],
                        [-169, -89],
                        [-167.588, -88.413],
                        [-167, -87],
                        [-167, -73],
                        [-167.588, -71.588],
                        [-169, -71],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [240, 175], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 2",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                      ],
                      o: [
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                      ],
                      v: [
                        [-208, -20],
                        [-196, -32],
                        [196, -32],
                        [208, -20],
                        [208, 20],
                        [196, 32],
                        [-196, 32],
                        [-208, 20],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [240, 95], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 1",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "rp",
          c: { a: 0, k: 3, ix: 1 },
          o: { a: 0, k: 0, ix: 2 },
          m: 1,
          ix: 5,
          tr: {
            ty: "tr",
            p: { a: 0, k: [0, 80], ix: 2 },
            a: { a: 0, k: [0, 0], ix: 1 },
            s: { a: 0, k: [100, 100], ix: 3 },
            r: { a: 0, k: 0, ix: 4 },
            so: { a: 0, k: 100, ix: 5 },
            eo: { a: 0, k: 100, ix: 6 },
            nm: "Transform",
          },
          nm: "Repeater 1",
          mn: "ADBE Vector Filter - Repeater",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

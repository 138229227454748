import React, { useState } from 'react'
import Calendar from '../../../../../Components/Calendar/index';
import { ReactComponent as LeftArrow } from "../../../../../Assets/images/arrow-left.svg";
import { ReactComponent as RIghtArrow } from "../../../../../Assets/images/arrow-right.svg";
import { ReactComponent as CloseIcon } from "../../../../../Assets/icon/close.svg";

const DateSchedule = ({setPage, onClose, selectedDate, setSelectedDate }) => {

  return (
    <div className="session-schedule-container">
        <div className="session-schedule-header">
            <LeftArrow style={{cursor:'pointer'}} onClick={() => setPage('choice')} />
            <CloseIcon style={{cursor:'pointer'}} onClick={onClose}/>
        </div>
        <p className="schedule-title">Choose the date</p>
        <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
        />
        <div 
            className="continue-button-container" 
            onClick={() => {
                setPage('time')
            }}
        >
            <span>Continue</span>
            <RIghtArrow />
        </div>
    </div>
  )
}

export default DateSchedule
import React, { useEffect, useState } from "react";
import Stroke1 from "../../Assets/icon/Stroke1.svg"; // Stroke image
import { useHeader } from "../../Providers/HeaderContextProvider";

function Overview({ onButtonClick }) {
  const { profileContent } = useHeader();

  return (
    <>
      <div className="overview-container-hero">
        <div className="overview-image-container"></div>
        <img src={Stroke1} alt="Stroke Layer 1" className="stroke-1-ov" />
        <img src={Stroke1} alt="Stroke Layer 2" className="stroke-2-ov" />
        {/* <div className="stroke-1"></div>
      <div className="stroke-2"></div>
      <div className="stroke-3"></div> */}
        {profileContent?.profile_pic_url ? (
          <img
            src={profileContent?.profile_pic_url}
            alt="overview"
            className="overview-image"
          />
        ) : (
          <div className="overview-image badged-background">
            {profileContent
              ? profileContent?.first_name?.charAt(0) +
                profileContent?.last_name?.charAt(0)
              : ""}
          </div>
        )}
      </div>

      <div className="overview-name">Know Thyself...</div>
      <div className="overview-text-container">
        Your Aris Portrait is a gateway to self-discovery and mastery. Your
        Portrait holds the insights that can reveal an exciting journey of
        personal growth. The real value of this information rests in what you
        decide to do with it.
      </div>
      <div className="overview-button-container">
        <button class="mobile-only-button" onClick={onButtonClick}>
          Next:Personality
        </button>
      </div>
    </>
  );
}

export default Overview;

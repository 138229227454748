import React, { memo, useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const GradientGraph = ({ data, isStory }) => {
  const svgRef = useRef();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const width = screenWidth < 580 ? 280 : screenWidth > 1840 ? 600 : 500;
    const height = data.length * 100;
    const cornerRadius = 6;
    const topMargin = 30;
    const rectHeight = width * 0.11;
    const rectWidth = width * 0.08;
    const gap = Math.max(2, width * 0.005);
    const gapBetweenSections = rectWidth * 0.4;

    const totalGraphWidth =
      5 * rectWidth + 4 * gap + gapBetweenSections + 5 * rectWidth + 4 * gap;
    const graphStartX = (width - totalGraphWidth) / 2;

    svg.attr("width", width).attr("height", height);

    const defs = svg.append("defs");

    data.forEach((item, index) => {
      const {
        leftPercent,
        rightPercent,
        leftColor,
        rightColor,
        leftLabel,
        rightLabel,
      } = item;

      // Create gradients
      const gradientLeftId = `gradient-left-${index}`;
      defs
        .append("linearGradient")
        .attr("id", gradientLeftId)
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0%")
        .attr("y2", "0%")
        .selectAll("stop")
        .data(leftColor)
        .enter()
        .append("stop")
        .attr("offset", (d, i) => `${i * 100}%`)
        .attr("stop-color", (d) => d);

      const gradientRightId = `gradient-right-${index}`;
      defs
        .append("linearGradient")
        .attr("id", gradientRightId)
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0%")
        .attr("y2", "0%")
        .selectAll("stop")
        .data(rightColor)
        .enter()
        .append("stop")
        .attr("offset", (d, i) => `${i * 100}%`)
        .attr("stop-color", (d) => d);

      const y = index * 100 + topMargin;

      // Add Labels
      svg
        .append("text")
        .attr("x", graphStartX)
        .attr("y", y - 10)
        .attr("text-anchor", "start")
        .attr("font-size", screenWidth < 580 ? ".8rem" : "1rem")
        .attr("font-family", "Urbanist")
        .attr("fill", isStory ? "#F3F3F3" : "#7E7F83")
        .text(leftLabel);

      svg
        .append("text")
        .attr("x", graphStartX + totalGraphWidth)
        .attr("y", y - 10)
        .attr("text-anchor", "end")
        .attr("font-size", screenWidth < 580 ? ".8rem" : "1rem")
        .attr("font-family", "Urbanist")
        .attr("fill", isStory ? "#F3F3F3" : "#7E7F83")
        .text(rightLabel);

      const calcRectFill = (percentage) => {
        const fullRects = Math.floor((percentage / 100) * 5);
        const partialWidth = ((percentage / 100) * 5 - fullRects) * rectWidth;
        return { fullRects, partialWidth };
      };

      const leftFill = calcRectFill(leftPercent);
      const rightFill = calcRectFill(rightPercent);

      // Create a container for animation clipping
      const clipId = `clip-${index}`;
      const clipPathLeft = defs.append("clipPath").attr("id", `${clipId}-left`);

      const clipPathRight = defs
        .append("clipPath")
        .attr("id", `${clipId}-right`);

      // Left Rectangles
      for (let i = 0; i < 5; i++) {
        const x = graphStartX + (5 - i - 1) * (rectWidth + gap);

        // Background rectangles
        svg
          .append("rect")
          .attr("x", x)
          .attr("y", y)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("rx", cornerRadius)
          .attr("ry", cornerRadius)
          .attr("fill", isStory ? "#F3F3F3" : "#8585851A");

        if (i < leftFill.fullRects) {
          // Fully filled rectangles
          svg
            .append("rect")
            .attr("x", x)
            .attr("y", y)
            .attr("width", rectWidth)
            .attr("height", rectHeight)
            .attr("rx", cornerRadius)
            .attr("ry", cornerRadius)
            .attr("fill", `url(#${gradientLeftId})`)
            .style("clip-path", `url(#${clipId}-left)`);
        } else if (i === leftFill.fullRects && leftFill.partialWidth > 0) {
          // Partially filled rectangle - aligned to right edge
          svg
            .append("rect")
            .attr("x", x + (rectWidth - leftFill.partialWidth))
            .attr("y", y)
            .attr("width", leftFill.partialWidth)
            .attr("height", rectHeight)
            .attr("rx", cornerRadius)
            .attr("ry", cornerRadius)
            .attr("fill", `url(#${gradientLeftId})`)
            .style("clip-path", `url(#${clipId}-left)`);
        }

        // Percentage text
        if (i === 0) {
          svg
            .append("text")
            .attr("x", x + rectWidth / 2)
            .attr("y", y + rectHeight / 2)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .attr("font-size", screenWidth < 580 ? ".7rem" : "1.1rem")
            .attr("font-family", "Urbanist")
            .attr("fill", "#000")
            .attr("font-weight", "500")
            .text(leftPercent + "%");
        }
      }

      // Right Rectangles
      const rightStartX =
        graphStartX + 5 * (rectWidth + gap) + gapBetweenSections;

      for (let i = 0; i < 5; i++) {
        const x = rightStartX + i * (rectWidth + gap);

        // Background rectangles
        svg
          .append("rect")
          .attr("x", x)
          .attr("y", y)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("rx", cornerRadius)
          .attr("ry", cornerRadius)
          .attr("fill", isStory ? "#F3F3F3" : "#8585851A");

        if (i < rightFill.fullRects) {
          // Fully filled rectangles
          svg
            .append("rect")
            .attr("x", x)
            .attr("y", y)
            .attr("width", rectWidth)
            .attr("height", rectHeight)
            .attr("rx", cornerRadius)
            .attr("ry", cornerRadius)
            .attr("fill", `url(#${gradientRightId})`)
            .style("clip-path", `url(#${clipId}-right)`);
        } else if (i === rightFill.fullRects && rightFill.partialWidth > 0) {
          // Partially filled rectangle
          svg
            .append("rect")
            .attr("x", x)
            .attr("y", y)
            .attr("width", rightFill.partialWidth)
            .attr("height", rectHeight)
            .attr("rx", cornerRadius)
            .attr("ry", cornerRadius)
            .attr("fill", `url(#${gradientRightId})`)
            .style("clip-path", `url(#${clipId}-right)`);
        }

        // Percentage text
        if (i === 0) {
          svg
            .append("text")
            .attr("x", x + rectWidth / 2)
            .attr("y", y + rectHeight / 2)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .attr("font-size", screenWidth < 580 ? ".8rem" : "1.1rem")
            .attr("font-family", "Urbanist")
            .attr("fill", "#000")
            .attr("font-weight", "500")
            .text(rightPercent + "%");
        }
      }

      // Animation timing configuration
      const animationDuration = 2500;
      const animationEasing = d3.easeCubicInOut;

      // Animate the left side from right to left
      const leftClipRect = clipPathLeft
        .append("rect")
        .attr("x", graphStartX + 5 * (rectWidth + gap)) // Start from the rightmost position
        .attr("y", y)
        .attr("width", 0)
        .attr("height", rectHeight);

      leftClipRect
        .transition()
        .duration(animationDuration)
        .ease(animationEasing)
        .attr("x", graphStartX) // Move to the left
        .attr("width", 5 * (rectWidth + gap));

      // Animate the right side
      const rightClipRect = clipPathRight
        .append("rect")
        .attr("x", rightStartX)
        .attr("y", y)
        .attr("width", 0)
        .attr("height", rectHeight);

      rightClipRect
        .transition()
        .duration(animationDuration)
        .ease(animationEasing)
        .attr("width", 5 * (rectWidth + gap));
    });
  }, [data, screenWidth, isStory]);

  return <svg ref={svgRef} style={{ overflow: "visible" }} />;
};

export default memo(GradientGraph);

import React, { useState, useRef } from "react";
import "./style.css";
import "../../App.css";
import nextbtn from "../../Assets/images/arrow-right-blue.png";
import InputField from "../../Components/InputField";
import { sendEmail, resendVerificationLink } from "../../Services/auth";
import checkmark from '../../Assets/images/check-circle.svg';
import { ReactComponent as Tick } from '../../Assets/icon/Check-white.svg';
import { ReactComponent as CloseIcon } from '../../Assets/icon/close.svg';

const EmailPage = ({ onNext }) => {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");
  const [resendMessage, setResendMessage] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const requestInProgress = useRef(false);
  const [isChecked, setIsChecked] = useState(false);
  const[issending, setIssending]= useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setApiMessage("");
    setError("");
    setShowModal(false);
    requestInProgress.current = false;
  };

  const handleResendEmail = async () => {
    if (requestInProgress.current || !isValidEmail(email) || !isChecked || issending) {
      return;
    }
    console.log("email , checked", email, isChecked);
    try {
      requestInProgress.current = true;
      await resendVerificationLink(email);
      setResendMessage("Verification email resent. Please check your inbox.");
      setError("");
    } catch (error) {
      setResendMessage(
        "Failed to resend verification email. Please try again."
      );
    } finally {
      requestInProgress.current = false;
    }
  };

  const handleEmailSubmit = async () => {

    if (requestInProgress.current || !isValidEmail(email) || !isChecked) {
      console.log("error inn email submit");
    } else {
      console.log("success !!!!")
    }
    
    try {
      requestInProgress.current = true;
      setError("");
      setApiMessage("");
      setIssending(true);
      const response = await sendEmail(email);
      console.log("Response from sendEmail:", response);
      if (response.status === 200) {
        setShowModal(false);
        setEmailSent(true);
      } else if (response.status === 226) {
        setApiMessage("This email already exists. Please login.");
      } else {
        setError(response);
      }
    } catch (error) {
        setShowModal(false);
        setError(error.message);
    } finally {
      requestInProgress.current = false;
      setIssending(false);
    }
  };

  return (
    <div className="Fullpage-container-email">
      <div className={emailSent ? "email-sent-container" : "email-container"}>
        {!emailSent ? (
          <>
            <div className="email-header">
              <div className="email-header-container">
                <h2 className="heading-h2">What's your email?</h2>
              </div>
              <div>
                <p className="sign-up-para-title">Your email</p>
                <div className="signup-email-container">
                  <InputField
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    autoFocus={true}

                  />
                  {isValidEmail(email) && (
                    <img src={checkmark} alt="valid" className="email-checkmark-icon" />
                  )}
                </div>
                {error && <p style={{ color: "red", fontFamily: "Urbanist" }}>{error}</p>}
                {apiMessage && <p style={{ color: "red" }}>{apiMessage}</p>}
              </div>
            </div>

            <div>
              <div className="aggrement-policy-container">
                <input 
                  type="checkbox" 
                  name="" 
                  id="aggrement-policy-checkbox"
                  isChecked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                <p>
                  By clicking here, you acknowledge that you have read and understood, and agree to Aris's 
                  <span className="agreement-span" onClick={() => setShowModal(true)}> User Agreement</span> and <span className="agreement-span" onClick={() => setShowModal(true)}>Privacy Policy.</span>
                </p>
              </div>
              <button
                className={`submit-button ${isValidEmail(email) && isChecked ? "valid" : "invalid"}`}
                onClick={handleEmailSubmit}
                disabled={!isValidEmail(email) || !isChecked || requestInProgress.current}
              >
                Send an email
                <img src={nextbtn} alt="nxtbtn" className="nxtbtn" />
              </button>
            </div>

            {showModal && (
              <div className="email-modal">
                <div className="email-modal-content">
                  <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                    <div></div>
                    <h2 className="agree-heading">
                      User Agreement and Privacy Policy
                    </h2>
                    <CloseIcon onClick={() => setShowModal(false)} cursor={'pointer'} />
                  </div>
                  <div className="email-modal-main-content">
                    <p>SurePeople permits you ("you", "User", "Public User", or "Registered User") to browse the SurePeople Website, take the Prism® psychometric assessment for your own use or to be used by one of our client companies, who may be employers or consultants (each a "Client") and offers you, as an individual and/or these Clients access to certain data, tools and services accessible through the Site, including, but not limited to, psychometric assessments, 360 assessments, surveys, learning/development action plans, content and other services (the "Service").</p>
                    <p>Below we explain how SurePeople collects, uses, discloses, safeguards and provides accessibility to your information collected from the SurePeople Website as a Public User or Registered User.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rutrum tortor nec mi porttitor blandit. Morbi vel mi a dolor feugiat rhoncus. Sed aliquet, metus at ornare luctus, sapien elit laoreet nunc, a tempus erat lectus non ligula. Cras accumsan gravida ante, sed porta tortor maximus quis. Suspendisse mattis augue eget est pulvinar, auctor euismod quam laoreet. Nullam mi sem, tincidunt a ornare eu, iaculis id odio. Donec at risus egestas, dictum libero eget, ultricies nulla. Sed ut consequat sem. Nam pellentesque, ipsum ac faucibus posuere, lacus eros laoreet enim, cursus feugiat nisi sapien a augue.</p>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="email-sent-message">
            <h2 className="signup-verify-email">Verify your email</h2>
            <p className="signup-verify-email-para">
              We just sent an email to {email}. Please check your inbox.
            </p>
            <p className="signup-verify-email-para"
              style={{ fontSize: "15px", marginTop: "0px", display: "inline" }}
            >
              Didn't receive the email?
            </p>
            <button onClick={handleResendEmail} className="signup-resendmail">
              Resend email
            </button>
            {resendMessage && (
              <p
                style={{
                  color: resendMessage.includes("Failed") ? "red" : "green",
                }}
              >
                {resendMessage}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailPage;

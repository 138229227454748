import React, { useEffect, useState } from "react";
import graph from "../../Assets/images/Graph.png";
import videoTmp from "../../Assets/images/VideoPotraits.png";
import { ReactComponent as DownShowMore } from "../../Assets/images/chevron-down.svg";
import { ReactComponent as UpShowMore } from "../../Assets/images/chevron-up.svg";

import PercentailWithHead from "../../Components/PotraitsPercentailWithHead/PercentailWithHead";
import ChatContacts from "../../Components/ChatContacts";

import TopStrength from "./TopStrength";
import ShowMoreLess from "./ShowMoreLess";
import RadarGraph from "../../Components/PotraitsGraph/PersonalityGraph";

function Personality({ chartData, primary, underPressure }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [personalityType, setPersonalityType] = useState(1);
  const [showMore1, setshowMore1] = useState(false);

  const primaryData = primary;
  const underPressureData = underPressure;

  const showMore1Click = () => {
    setshowMore1((prev) => !prev);
  };

  function prepareEudaimoniaData(data) {
    if (!data?.growthPathToEudaimonia?.content) {
      return null;
    }

    // Extract the content array
    const contentArray = data.growthPathToEudaimonia.content;

    // Create a new object with description property that contains all descriptions
    const processedData = {
      ...data.growthPathToEudaimonia,
      description: contentArray.map((item) => item.description).join("\n\n"),
    };

    return processedData;
  }

  function prepareRelationshipsData(primaryData, key) {
    console.log("Processing relationships data:", primaryData?.[key]);

    if (!primaryData?.[key]) {
      return null;
    }

    // Create a new object based on the original data
    const processedData = {
      ...primaryData[key],
      formattedContent: [], // This will store our processed content items
    };

    // Process the main content array (sections like "How Others See You")
    if (processedData.content && Array.isArray(processedData.content)) {
      processedData.content.forEach((section, sectionIndex) => {
        console.log(`Processing section ${sectionIndex}:`, section);

        // Add section title to our formatted content
        processedData.formattedContent.push({
          isSectionTitle: true,
          title: section.title,
          description: null,
          descriptionType: null,
          iconType: null,
        });

        if (section.content && Array.isArray(section.content)) {
          console.log(
            `Section ${sectionIndex} has ${section.content.length} content items`
          );

          section.content.forEach((contentItem, itemIndex) => {
            console.log(
              `Processing content item ${itemIndex} in section ${sectionIndex}:`,
              contentItem
            );

            if (contentItem.description) {
              console.log(
                `Found description in section ${sectionIndex}, item ${itemIndex}:`,
                contentItem.description
              );

              const formattedItem = {
                description: contentItem.description,
                descriptionType: contentItem.descriptionType,
                title: contentItem.title,
                iconType:
                  contentItem.descriptionType === "BULLETS_POSTIVE"
                    ? "tick"
                    : contentItem.descriptionType === "BULLETS_NEGATIVE"
                    ? "warning"
                    : null,
              };
              processedData.formattedContent.push(formattedItem);
            }

            // Check for deeper nesting
            if (contentItem.content && Array.isArray(contentItem.content)) {
              console.log(
                `Item ${itemIndex} in section ${sectionIndex} has nested content with ${contentItem.content.length} items`
              );

              contentItem.content.forEach((nestedItem, nestedIndex) => {
                console.log(
                  `Processing nested item ${nestedIndex} in section ${sectionIndex}, item ${itemIndex}:`,
                  nestedItem
                );

                if (nestedItem.description) {
                  console.log(
                    `Found description in nested item ${nestedIndex}:`,
                    nestedItem.description
                  );

                  const formattedNestedItem = {
                    description: nestedItem.description,
                    descriptionType: nestedItem.descriptionType,
                    title: nestedItem.title,
                    iconType:
                      nestedItem.descriptionType === "BULLETS_POSTIVE"
                        ? "tick"
                        : nestedItem.descriptionType === "BULLETS_NEGATIVE"
                        ? "warning"
                        : null,
                  };
                  processedData.formattedContent.push(formattedNestedItem);
                }
              });
            }
          });
        }
      });
    }

    console.log("Processed data:", processedData);
    return processedData;
  }

  console.log(screenWidth, "screenWidth");
  console.log(primaryData, "primaryData");

  console.log(underPressureData, "underPressureData");
  console.log(primaryData, "primaryData");

  console.log(
    prepareRelationshipsData(underPressureData, "whenItComesToRelationships"),
    "growthpathtoeudaimonia"
  );

  return (
    <div className="personality-hero-container">
      <div className="potraits-tab-header-txt">Personality</div>
      <div className="potraits-graph-tab mt-12">
        <div
          className={
            personalityType === 1 ? " active-graph-tab" : "in-active-graph-tab"
          }
          onClick={() => setPersonalityType(1)}
        >
          <div className="Label">Primary</div>
        </div>
        <div
          className={
            personalityType === 2 ? " active-graph-tab" : "in-active-graph-tab"
          }
          onClick={() => setPersonalityType(2)}
        >
          <div className="Label">Under Pressure</div>
        </div>
      </div>

      {personalityType === 1 ? (
        <>
          <div className="personality-head-cntr">
            <div className="personality-txt-normal personality-upper-head">
              Personality Archetype:
            </div>
            <div className="potraits-sub-txt-adjusted">
              {chartData?.Primary?.personalityType}
            </div>
          </div>
          {/* <img src={graph} alt="graph" className="graph-tmp-personality" /> */}
          <div
            className="personality-graph-txt-emiter"
            style={{
              width:
                screenWidth > 1840
                  ? "810px"
                  : screenWidth > 1240
                  ? "485px"
                  : screenWidth > 400
                  ? "480px"
                  : "380px",
            }}
          >
            <div className="personality-txt-normal personality-graph-txt">
              <div>POWERFUL</div>
              <div>VERSATILE</div>
            </div>
            <RadarGraph personalityGraphdata={chartData} showTwo={false} />
            <div className="personality-txt-normal personality-graph-txt">
              <div>PRECISE</div>
              <div>AMIABLE</div>
            </div>
          </div>
          {/* <div className="potraits-explanation-cntr">
        <div className="potraits-bordered-container">
          <PercentailWithHead
            percentail={primaryData?.card1?.percentage + "%"}
            txt={primaryData?.card1?.label}
          />
          <div className="potraits-details-explanation-txt">
            {primaryData?.card1?.text?.para1}
          </div>
        </div>
        <div className="potraits-bordered-container">
          <PercentailWithHead
            percentail={primaryData?.card2?.percentage + "%"}
            txt={primaryData?.card2?.label}
          />
          <div className="potraits-details-explanation-txt">
            {primaryData?.card2?.text?.para1}
          </div>
        </div>
      </div> */}
          {/* <img
        src={primaryData?.archetype_video}
        alt="video-archtype"
        className="video-tmp-personality"
      /> */}
          <div className="personality-summary">
            <div className="summary-head">
              {/* As an {chartData?.Primary?.personalityType || ""}... */}
              {primaryData?.whoAreYouAtCore?.title}
            </div>
            <div className="summary-txt">
              {primaryData?.whoAreYouAtCore.description
                .split("\n")
                .map((line, index) => {
                  if (showMore1) {
                    return (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    );
                  } else {
                    if (index === 0) {
                      return (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      );
                    }
                  }
                })}
              {/* {primaryData?.summary} */}
            </div>
          </div>
          <div
            className="potraits-show-more-container"
            onClick={showMore1Click}
          >
            {showMore1 ? (
              <>
                Show Less <UpShowMore />
              </>
            ) : (
              <>
                Show More <DownShowMore />
              </>
            )}
          </div>
          <video
            // src={primaryData?.archetypeVideo}
            src="https://www.youtube.com/watch?v=0X_80q4_G0k"
            alt="video-archtype"
            className="video-tmp-personality"
            controls
          />
          {/* <div className="potraits-instructors-cntr">
        <div className="potraits-tab-header-txt">Other Instructors </div>
        <div className="instructors-container">
          <ChatContacts initial={"YL"} name={"Yari Landstar"} />
          <ChatContacts initial={"YL"} name={"Yari Landstar"} />
          <ChatContacts initial={"YL"} name={"Yari Landstar"} />
          <ChatContacts initial={"YL"} name={"Yari Landstar"} />
          <ChatContacts initial={"YL"} name={"Yari Landstar"} />
          <ChatContacts initial={"YL"} name={"Yari Landstar"} />
          <ChatContacts initial={"YL"} name={"Yari Landstar"} />
        </div>
      </div> */}
          <TopStrength
            topStrengths={primaryData?.topStrengths}
            topStrengthsToolTip={primaryData?.topStrengthsToolTip}
            isBlind={false}
          />
          <TopStrength
            topStrengths={primaryData?.potentialBlindSpots}
            topStrengthsToolTip={primaryData?.potentialBlindSpotsToolTip}
            isBlind={true}
          />

          {/* <ShowMoreLess
            primarHeading={"When It Comes To You"}
            primaryData={primaryData?.whenItComesToYou}
          /> */}

          <ShowMoreLess
            primarHeading={
              primaryData?.navigateChallengesAndOpportunities?.title
            }
            primaryData={primaryData?.navigateChallengesAndOpportunities}
          />

          {/* <ShowMoreLess
            primarHeading={"When it comes to your relationships"}
            primaryData={primaryData?.whenItComesToRelationships}
          /> */}

          <ShowMoreLess
            primarHeading={primaryData?.whenItComesToRelationships?.title}
            primaryData={prepareRelationshipsData(
              primaryData,
              "whenItComesToRelationships"
            )}
          />
          <ShowMoreLess
            primarHeading={primaryData?.howNavigationTeamsLeadership?.title}
            primaryData={prepareRelationshipsData(
              primaryData,
              "howNavigationTeamsLeadership"
            )}
          />

          <ShowMoreLess
            primarHeading={primaryData?.growthPathToEudaimonia?.title}
            primaryData={prepareEudaimoniaData(primaryData)}
          />
        </>
      ) : (
        <>
          {" "}
          <div className="personality-head-cntr">
            <div className="personality-txt-normal personality-upper-head">
              Under Pressure Archetype:
            </div>
            <div className="potraits-sub-txt-adjusted">
              {chartData?.UnderPressure?.personalityType}
            </div>

            <div className="summary-txt mt-16">
              Power is the strongest dimension. It denotes a natural drive and
              designates a take-charge individual. You are not easily
              intimidated and apply the pressure necessary to get results.
            </div>
          </div>
          <div className="summary-txt m-16">
            {underPressureData?.archetype_summary}
          </div>
          <div
            className="personality-graph-txt-emiter"
            style={{
              width:
                screenWidth > 1840
                  ? "810px"
                  : screenWidth > 1240
                  ? "485px"
                  : screenWidth > 400
                  ? "480px"
                  : "380px",
            }}
          >
            <div className="personality-txt-normal personality-graph-txt">
              <div>POWERFUL</div>
              <div>VERSATILE</div>
            </div>
            <RadarGraph personalityGraphdata={chartData} showTwo={true} />
            <div className="personality-txt-normal personality-graph-txt">
              <div>PRECISE</div>
              <div>AMIABLE</div>
            </div>
          </div>
          <div className="personality-summary">
            <div className="summary-head">
              As an {chartData?.UnderPressure?.personalityType || ""}...
            </div>
            <div className="summary-txt">
              {underPressureData?.summary?.description
                .split("\n")
                .map((line, index) => {
                  if (showMore1) {
                    return (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    );
                  } else {
                    if (index === 0) {
                      return (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      );
                    }
                  }
                })}
              {/* {primaryData?.summary} */}
            </div>
          </div>
          <div
            className="potraits-show-more-container"
            onClick={showMore1Click}
          >
            {showMore1 ? (
              <>
                Show Less <UpShowMore />
              </>
            ) : (
              <>
                Show More <DownShowMore />
              </>
            )}
          </div>
          <video
            src={underPressureData?.archetypeVideo}
            alt="video-archtype"
            className="video-tmp-personality"
            controls
          />
          <TopStrength
            topStrengths={underPressureData?.topStrengths}
            topStrengthsToolTip={underPressureData?.topStrengthsToolTip}
            isBlind={false}
          />
          <TopStrength
            topStrengths={underPressureData?.potentialBlindSpots}
            topStrengthsToolTip={underPressureData?.potentialBlindSpotsToolTip}
            isBlind={true}
          />
          <ShowMoreLess
            primarHeading={"When It Comes To You"}
            underPressureData={underPressureData?.whenItComesToYou}
          />
          <ShowMoreLess
            primarHeading={"When it comes to your relationships"}
            underPressureData={prepareRelationshipsData(
              underPressureData,
              "whenItComesToRelationships"
            )}
            type={4}
          />
        </>
      )}
    </div>
  );
}

export default Personality;

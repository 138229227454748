import { RouterProvider } from "react-router-dom";
import { router } from "./AppRoutes";
import HeaderComponent from "./Components/HeaderComponent";
import { useHeader } from "./Providers/HeaderContextProvider";

function Layout() {
  const { headerContent, headerContentRightSideContent, profileContent } =
    useHeader();

  return (
    <>
      {headerContent && (
        <HeaderComponent
          headerContentRightSideContent={headerContentRightSideContent}
          profileContent={profileContent}
        />
      )}
      <RouterProvider router={router} />
    </>
  );
}

export default Layout;

import React, { useEffect, useState } from "react";
import arrowLeft from "../../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../Providers/HeaderContextProvider";
import "./style.css";
import ToggleButton from "../../../Components/ToggleButton/ToggleButton";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../../../Services/settingsServices";
import GradientBackground from "../../../Components/AddRelationshipHeaderGradient";

function NotificationSettings() {
  const navigate = useNavigate();
  const { setHeaderContent } = useHeader();
  const [notificationSettings, setnotificationSettings] = useState({
    general: false,
    invite: false,
    coach: false,
  });
  useEffect(() => {
    setHeaderContent(false);
    getNotificationSettings().then((e) => {
      console.log(e);
      setnotificationSettings({
        general: e?.general,
        invite: e?.invite,
        coach: e?.coach,
      });
    });
  }, []);

  const onToggleSelection = (name) => {
    console.log(name);

    setnotificationSettings((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));

    updateNotificationSettings({
      [name]: !notificationSettings[name],
    });
  };

  console.log(notificationSettings, "notSet");

  return (
    <div>
      <GradientBackground>
        <header className="termsofuse-header">
          <div className="termsofuse-gradient-background">
            <div className="termsofuse-nav-container">
              <div
                className="termsofuse-logo-container"
                onClick={() => navigate(-1)}
              >
                <img
                  src={arrowLeft}
                  alt="Back"
                  className="termsofuse-back-button"
                />
              </div>
              <h1 className="termsofuse-title">
                Notifications <span className="hide-on-desktop">Settings</span>
              </h1>
              <div className="termsofuse-space"></div>
            </div>
            <div className="termsofuse-last-subtext">Push notifications</div>
          </div>
        </header>
        <div className="notification-set-hero-container">
          <div className="notification-set-accord">
            <div className="notification-set-accord-title-container">
              <div className="not-set-head">General communications</div>
              <div className="not-set-sub">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div>
            <div>
              <ToggleButton
                isChecked={notificationSettings.general}
                onChange={() => onToggleSelection("general")}
                name={"general"}
              />
            </div>
          </div>
          <div className="notification-set-accord">
            <div className="notification-set-accord-title-container">
              <div className="not-set-head">Connection requests</div>
              <div className="not-set-sub">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div>
            <div>
              <ToggleButton
                isChecked={notificationSettings.invite}
                onChange={() => onToggleSelection("invite")}
                name={"invite"}
              />
            </div>
          </div>
          <div className="notification-set-accord">
            <div className="notification-set-accord-title-container">
              <div className="not-set-head">Life coach follow-ups</div>
              <div className="not-set-sub">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div>
            <div>
              <ToggleButton
                isChecked={notificationSettings.coach}
                onChange={() => onToggleSelection("coach")}
                name={"coach"}
              />
            </div>
          </div>
        </div>
      </GradientBackground>
    </div>
  );
}

export default NotificationSettings;

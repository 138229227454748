import React from 'react'
import { ReactComponent as CloseIcon } from "../../../../../Assets/icon/close.svg";

const CalendarSchedule = ({setPage, onClose, selectedDate, startTime, endTime, hideCloseIcon = false, handleSessionSchedule, error}) => {
  return (
    <div className="session-schedule-container">
        <div className="session-schedule-header">
            <div className='scheduled-title'>
                Great!
                <div>Your session with the topic</div>
                <div>is scheduled</div>
            </div>
            {!hideCloseIcon && <CloseIcon style={{cursor:'pointer'}} onClick={onClose}/>}
        </div>
        <div className="scheduled-text">
            <div className="date-text">
                {selectedDate.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                })},
            </div>
            <div className="time-text">
                {startTime} - {endTime}
            </div>
            <div className='scheduled-description'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas est libero, bibendum nec ligula in, sollicitudin venenatis dui
            </div>
        </div>
        <div className="buttons-container">
            <div className="start-button">Add to calendar</div>
            <div className="schedule-button" onClick={() => handleSessionSchedule()}>Done</div>
        </div>
        {error && <p style={{color: 'red', textAlign: 'center'}} className='error'>{error}</p>}
    </div>
  )
}

export default CalendarSchedule
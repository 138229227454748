import React from "react";

function Personality1({ width = 70, height = 70 }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 81 82"
        fill="none"
      >
        <g clip-path="url(#clip0_2047_75195)">
          <path
            d="M12.8066 16.5176L5.0625 59.3618L69.8066 75.2734L12.8066 16.5176Z"
            fill="#4FE3D3"
          />
          <path
            d="M13.3071 16.7734L56.3071 8.77344L70.3071 75.7734L5.10352 59.4046L13.3071 16.7734Z"
            fill="#45D9BF"
          />
          <path
            d="M13.3071 16.7734L56.3071 8.77344M13.3071 16.7734L5.10352 59.4046M13.3071 16.7734L70.3071 75.7734M56.3071 8.77344L70.3071 75.7734M56.3071 8.77344L5.10352 59.4046M70.3071 75.7734L5.10352 59.4046"
            stroke="white"
            stroke-opacity="0.7"
            stroke-width="0.161227"
            stroke-miterlimit="10"
          />
          <g filter="url(#filter0_f_2047_75195)">
            <path
              d="M25.4175 27.7793L28.3862 36.8628H37.9932L30.221 42.4767L33.1897 51.5603L25.4175 45.9463L17.6453 51.5603L20.614 42.4767L12.8418 36.8628H22.4488L25.4175 27.7793Z"
              fill="white"
            >
              <animate
                attributeName="opacity"
                values="0.5;1;0.5"
                dur="2s"
                repeatCount="indefinite"
              />
            </path>
          </g>
          <g filter="url(#filter1_f_2047_75195)">
            <path
              d="M42.7492 11.748L45.2195 19.3136H53.2133L46.7462 23.9894L49.2164 31.555L42.7492 26.8792L36.2821 31.555L38.7523 23.9894L32.2852 19.3136H40.279L42.7492 11.748Z"
              fill="white"
            >
              <animate
                attributeName="opacity"
                values="0.5;1;0.5"
                dur="1.5s"
                repeatCount="indefinite"
              />
            </path>
          </g>
          <g filter="url(#filter2_f_2047_75195)">
            <path
              d="M30.6063 49.9199L31.838 53.6923H35.8239L32.5992 56.0238L33.831 59.7961L30.6063 57.4647L27.3816 59.7961L28.6133 56.0238L25.3887 53.6923H29.3746L30.6063 49.9199Z"
              fill="white"
            >
              <animate
                attributeName="opacity"
                values="0.5;1;0.5"
                dur="1.8s"
                repeatCount="indefinite"
              />
            </path>
          </g>
          <g filter="url(#filter3_f_2047_75195)">
            <path
              d="M63.5613 41.6113L64.5975 44.7849H67.9507L65.2379 46.7463L66.2741 49.9199L63.5613 47.9585L60.8485 49.9199L61.8847 46.7463L59.1719 44.7849H62.5251L63.5613 41.6113Z"
              fill="white"
            >
              <animate
                attributeName="opacity"
                values="0.5;1;0.5"
                dur="1.2s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f_2047_75195"
            x="0.0726252"
            y="15.0101"
            width="50.6907"
            height="49.3196"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="6.38459"
              result="effect1_foregroundBlur_2047_75195"
            />
          </filter>
          <filter
            id="filter1_f_2047_75195"
            x="18.6848"
            y="-1.85232"
            width="48.1285"
            height="47.0074"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="6.80018"
              result="effect1_foregroundBlur_2047_75195"
            />
          </filter>
          <filter
            id="filter2_f_2047_75195"
            x="15.3829"
            y="39.9142"
            width="30.447"
            height="29.8875"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="5.00287"
              result="effect1_foregroundBlur_2047_75195"
            />
          </filter>
          <filter
            id="filter3_f_2047_75195"
            x="52.3904"
            y="34.8299"
            width="22.3422"
            height="21.8715"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="3.39072"
              result="effect1_foregroundBlur_2047_75195"
            />
          </filter>
          <clipPath id="clip0_2047_75195">
            <rect
              width="80.6135"
              height="80.6135"
              fill="white"
              transform="translate(0.306641 0.773438)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default Personality1;

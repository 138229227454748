import React from "react";

function Personality13() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 106"
      fill="none"
    >
      <path
        d="M17.3672 16.9243L82.4694 33.3138L81.9145 81.7101H33.9564L17.3672 16.9243Z"
        fill="#4FE3D3"
      />
      <path
        d="M17.3672 16.9243L82.4694 33.3138M17.3672 16.9243L33.9564 81.7101M17.3672 16.9243L81.9145 81.7101M82.4694 33.3138L81.9145 81.7101M82.4694 33.3138L33.9564 81.7101M81.9145 81.7101H33.9564"
        stroke="white"
        stroke-opacity="0.7"
        stroke-width="0.211394"
        stroke-miterlimit="10"
      />
      <path
        d="M82.4682 33.3131L81.8931 81.5571L16.9102 16.3628L82.4682 33.3131Z"
        fill="#45D9BF"
      />
      <g opacity="0.6" filter="url(#filter0_f_2161_30216)">
        <path
          d="M78.5612 22.8242L81.1982 30.928H89.7317L82.8279 35.9364L85.4649 44.0402L78.5612 39.0318L71.6574 44.0402L74.2944 35.9364L67.3906 30.928H75.9241L78.5612 22.8242Z"
          fill="white"
        />
      </g>
      <g opacity="0.6" filter="url(#filter1_f_2161_30216)">
        <path
          d="M19.9519 9.71289L22.3722 17.1818H30.2045L23.868 21.7979L26.2883 29.2669L19.9519 24.6508L13.6154 29.2669L16.0357 21.7979L9.69922 17.1818H17.5315L19.9519 9.71289Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30216"
          x="59.7054"
          y="15.139"
          width="37.7123"
          height="36.5863"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30216"
          />
        </filter>
        <filter
          id="filter1_f_2161_30216"
          x="2.01397"
          y="2.02764"
          width="35.8764"
          height="34.9247"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30216"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Personality13;

import { axiosAssessmentInstance } from "../AxiosConfiguration";

export const getAboutAris = async (field) => {
  try {
    const response = await axiosAssessmentInstance.get(
      `/api/static/about_aris?field=${field}`
    );
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

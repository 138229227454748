import React, { useEffect, useState } from "react";
import arrowLeft from "../../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../Providers/HeaderContextProvider";
import arrowRight from "../../../Assets/images/arrow-right.svg";
import { resendEmail } from "../../../Services/settingsServices";

import "./style.css";
function EmailSettingPhoneScreen3() {
  const navigate = useNavigate();
  const { setHeaderContent } = useHeader();
  const updatedEmail = sessionStorage.getItem("updatedEmail");

  useEffect(() => {
    setHeaderContent(false);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendCount, setResendCount] = useState(10);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  
  const resendFunction = () => {
    for (let i = 10; i > 0; i--) {
      setTimeout(() => {
        setResendCount(i);
      }, (10-i)*1000);
    }
  };

  const onResendEmail = async () => {
    setIsLoading(true);
    setResendDisabled(true);
    resendFunction();
    try {
      const response = await resendEmail({
        email: updatedEmail,
      });
      if (response.status === "success") {
        setSuccessMessage("Email sent successfully!");
        console.log("Email sent successfully");
      }
    } catch (error) {
      setErrorMessage("Error sending email. Please try again later.");
      console.error("Error:", error);
    }

    setTimeout(() => {
      setResendDisabled(false);
      setResendCount(10);
    }, 10000);
  };

  return (
    <div>
      {" "}
      <header className="termsofuse-header">
        <div className="termsofuse-gradient-background">
          <div className="termsofuse-nav-container">
            <div
              className="termsofuse-logo-container"
              onClick={() => navigate("/settings/email/2")}
            >
              <img
                src={arrowLeft}
                alt="Back"
                className="termsofuse-back-button"
              />
            </div>
            <h1 className="termsofuse-title">Email verification</h1>
            <div className="termsofuse-space"></div>
            <div className="set-phone-modal-support mobile-sub-txt-set-phone">
              Please check your inbox ({updatedEmail}) for the confirmation
              email.
            </div>
          </div>
        </div>
      </header>
      <div
        className="set-phone-int-cnt mobile-set-cnt"
        style={{ marginBottom: "10px" }}
      >
        <div
          className="otp-typo-cnt"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="otp-get-code">Didn’t get a email?</div>
          {/* <div className="otp-resend">Resend code</div> */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button
            className="set-phone-continue-button"
            onClick={onResendEmail}
            disabled={isLoading}
            style={{
              cursor: resendDisabled ? "not-allowed" : "pointer",
              opacity: resendDisabled ? "0.6" : "1",
            }}
          >
            {" "}
            Resend Email <img src={arrowRight} alt="edit" /> {resendDisabled ? `(${resendCount}s)` : ""}
          </button>
        </div>
        {errorMessage && (
          <div
            style={{
              color: "red",
              textAlign: "center",
            }}
          >
            {errorMessage}
          </div>
        )}
        {successMessage && (
          <div
            style={{
              color: "green",
              textAlign: "center",
            }}
          >
            {successMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailSettingPhoneScreen3;

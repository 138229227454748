import React from "react";
import { ReactComponent as LeftArrow } from "../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";

function PotraitHeader() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // Goes back to the previous page
  };
  return (
    <div className="potraits-header-hero-cntr">
      <div className="combined-gradient-background "></div>
      <div className="combined-gradient-background-2 "></div>
      <div className="combined-gradient-background-3 "></div>
      <div className="combined-gradient-background-4 "></div>
      <div className="combi-pot-head">
        <LeftArrow className="hover-pointer arrow-bg" onClick={handleGoBack} />
        <div className="potraits-header-txt">Aris Portrait</div>
        <div></div>
      </div>
    </div>
  );
}

export default PotraitHeader;

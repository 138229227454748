// LeftSection.js
import React from 'react';
import CarouselPage from '../../Components/Carousel';  // Import the CarouselPage component
import './style.css';  // Optional: Import styles if necessary

const LeftSection = () => {
    return (
        <div className="left-section">
            <div className="carousel-content">
                <CarouselPage /> {/* Render the Carousel component here */}
            </div>
        </div>
    );
};

export default LeftSection;

import React, { useEffect, useState } from "react";
import "./style.css";
import graph from "../../Assets/images/Graph3.png";
import PotraitsSummaryWithList from "../../Components/PotraitsSummaryWithList/PotraitsSummaryWithList";
import { GraphDecisionInfo } from "../../Components/Graphs/DecisionInfoGraph";
import PotraitsCommonHeadBottom from "./PotraitsCommonHeadBottom";
import ShowMoreLess from "./ShowMoreLess";
import TopStrength from "./TopStrength";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
function DecisionMaking({ chartData, otherData }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log("otherdata", otherData);

  // jwtDecode;
  // let token = Cookies.get("idtoken" || "") || "";
  // let nameFromToken = token ? jwtDecode(token)?.name : "name";
  return (
    <div className="personality-hero-container">
      <div className="potraits-tab-header-txt">Decision Making</div>
      <div className="potraits-graph-decision-making">
        {/* <img src={graph} alt="graph" className="potraits-graph-img" /> */}
        <div className="des-graph-hero-cntr">
          <div>
            <div className="des-graph-percent">{chartData?.Outward + "%"}</div>
            <div className="des-graph-sub">Outward</div>
          </div>
          <div className="des-graph-inside-cntr">
            <div>
              <div className="des-graph-percent">
                {chartData?.Careful + "%"}
              </div>
              <div className="des-graph-sub">Careful</div>
            </div>
            <GraphDecisionInfo data={chartData} isMobile={screenWidth < 580} />
            <div>
              <div className="des-graph-percent">{chartData?.Rapid + "%"}</div>
              <div className="des-graph-sub">Rapid</div>
            </div>
          </div>

          <div>
            <div className="des-graph-percent">{chartData?.Inward + "%"}</div>
            <div className="des-graph-sub">Inward</div>
          </div>
        </div>

        <div className="potraits-separater-line"></div>
        <div className="potraits-graph-summary-txt-cntr">
          <div className="potraits-content-head-txt">Summary</div>
          <div className="potraits-sub-txt">
            {otherData?.summary?.description}
          </div>
        </div>
      </div>
      <div className="personality-other-data-container">
        <PotraitsCommonHeadBottom
          heading={otherData?.portraitMatrixMap?.InwardOutward?.title?.description?.replace(
            /\n/g,
            " - "
          )}
          summary={
            otherData?.portraitMatrixMap?.InwardOutward?.bandCopy?.description
          }
        />
        <ShowMoreLess
          primarHeading={"Inward Traits"}
          // primaryData={primaryData?.whenItComesToYou}
          secondaryData={
            otherData?.portraitMatrixMap?.InwardOutward?.traits?.Inward
              ?.description
          }
          descriptionType={
            otherData?.portraitMatrixMap?.InwardOutward?.traits?.Inward
              ?.descriptionType
          }
          type={2}
        />
        <ShowMoreLess
          primarHeading={"Outward Traits"}
          // primaryData={primaryData?.whenItComesToYou}
          secondaryData={
            otherData?.portraitMatrixMap?.InwardOutward?.traits?.Outward
              ?.description
          }
          descriptionType={
            otherData?.portraitMatrixMap?.InwardOutward?.traits?.Outward
              ?.descriptionType
          }
          type={2}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.InwardOutward?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.InwardOutward?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.InwardOutward?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.InwardOutward
              ?.potentialBlindSpotsToolTip
          }
          isBlind={true}
        />
      </div>
      <div className="personality-other-data-container">
        <PotraitsCommonHeadBottom
          heading={otherData?.portraitMatrixMap?.RapidCareful?.title?.description?.replace(
            /\n/g,
            " - "
          )}
          summary={
            otherData?.portraitMatrixMap?.RapidCareful?.traits?.Rapid
              ?.description
          }
        />

        <ShowMoreLess
          primarHeading={"Careful Traits"}
          // primaryData={primaryData?.whenItComesToYou}
          secondaryData={
            otherData?.portraitMatrixMap?.RapidCareful?.traits?.Careful
              ?.description
          }
          descriptionType={
            otherData?.portraitMatrixMap?.RapidCareful?.traits?.Rapid
              ?.descriptionType
          }
          type={2}
        />
        <ShowMoreLess
          primarHeading={"Rapid Traits"}
          // primaryData={primaryData?.whenItComesToYou}
          secondaryData={
            otherData?.portraitMatrixMap?.RapidCareful?.traits?.Rapid
              ?.description
          }
          descriptionType={
            otherData?.portraitMatrixMap?.RapidCareful?.traits?.Careful
              ?.descriptionType
          }
          type={2}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.RapidCareful?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.RapidCareful?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.RapidCareful?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.RapidCareful
              ?.potentialBlindSpotsToolTip
          }
          isBlind={true}
      
        />
      </div>

      {/* <div className="potraits-percent-summary-hero-cntr">
        <PotraitsSummaryWithList
          isList={false}
          percentail={otherData?.card1?.percentage + "%"}
          ptxt={otherData?.card1?.label}
          summary={otherData?.card1?.text?.para1}
          summary2={otherData?.card1?.text?.para2}
        />
        <PotraitsSummaryWithList
          isList={false}
          percentail={otherData?.card2?.percentage + "%"}
          ptxt={otherData?.card2?.label}
          summary={otherData?.card2?.text?.para1}
          summary2={otherData?.card1?.text?.para2}
        />
      </div> */}
    </div>
  );
}

export default DecisionMaking;

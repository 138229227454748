import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GradientBackground from "../../Components/AddRelationshipHeaderGradient";
import TopBar from "../../Components/AddRelationshipTopBar";
import CircularProgressBarProfile from "../../Components/CircularProgressBarProfile";
import { getRelationshipProfile } from "../../Services/Realtionship";
import trophy from "../../Assets/icon/Trophy.svg";
import { ReactComponent as Relationship_advisor } from "../../Assets/icon/relationship-advisor.svg";
import { ReactComponent as UpArrow } from "../../Assets/icon/chevron-up.svg";
import { ReactComponent as DownArrow } from "../../Assets/icon/chevron-down.svg";
import { ReactComponent as RigthArrow } from "../../Assets/icon/chevron-right.svg";
import EditRelationship from "../../Components/EditRelationship";
import { useHeader } from "../../Providers/HeaderContextProvider";
import chaticon from "../../Assets/icon/chatvector.svg";
import networkicon from "../../Assets/icon/networkVector.svg";
import planticon from "../../Assets/icon/plantVector.svg";
import LoadingScreen from "../LoadingPage/DefaultLoader";
import { ReactComponent as JoinAris } from "../../Assets/icon/TileIcon.svg";
import Invitetoaris from "./Invitetoaris";
import AdviceInsight from "../../Pages/RelationShip/RelationShipChatScreen/RelationshipAdiveInsight";

import "./style.css";

const RelationshipProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [contact, setContact] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openSections, setOpenSections] = useState({});
  const [relationshipProfile, setRelationshipProfile] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth >= 480);
  const [profileSummary, setProfileSummary] = useState({ topStrengths: [] });
  const [sectionssum, setSectionssum] = useState([]);
  const { setHeaderContent } = useHeader();
  const [summaryError, setSummaryError] = useState(false);
  const [togglevalue, settogglevalue] = useState("primary");
  const [Personalitytype, setPersonalityType] = useState("");
  //  const [adviceInsight, setAdviceInsight] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [adviceInsight, setAdviceInsight] = useState(false);

  // Add a new state for tracking insight type
  const [insightType, setInsightType] = useState("advice");

  const showInsightsAndAdvice =
    contact?.primary_personality && contact?.under_pressure_personality;

  console.log("location contact", location?.state?.contact);
  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const getAdviceInsight = (type) => {
    // type will be either 'advice' or 'insights'
    setInsightType(type);
    setAdviceInsight(true);
  };

  // Add a function to close the advice insight
  const closeAdviceInsight = () => {
    setAdviceInsight(false);
  };

  const sections = [
    {
      title: "How I Show Up To Others",
      key: "HowIShowUpToOthers",
      toggleable: true,
    },
    {
      title: "How I Make Decisions",
      key: "HowIMakeDecisions",
      toggleable: false,
    },
    {
      title: "What Motivates Me",
      key: "WhatMotivatesMe",
      toggleable: false,
    },
    {
      title: "How I Process Information and Experiences",
      key: "HowIProcessesInformationandExperiences",
      toggleable: false,
    },
    {
      title: "How I Approach Conflict",
      key: "HowIApproachesConflict",
      toggleable: false,
    },
    {
      title: "My Primary Needs",
      key: "MyPrimaryNeeds",
      toggleable: false,
    },
  ];

  useEffect(() => {
    setHeaderContent(false);
  });

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopView(window.innerWidth >= 480);
      // console.log("Current Width:", window.innerWidth, "isDesktopView:", window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchContactProfile = async () => {
      try {
        const user2_relationId = location.state?.contact;

        const relationshipProfileId =
          user2_relationId?._id || user2_relationId?.id || "";

        if (relationshipProfileId !== "") {
          const relationships = await getRelationshipProfile(
            relationshipProfileId
          );
          //console.log("relationship profile", relationships);
          setContact(relationships);
          setProfileSummary(relationships.summary);
          // const matchedRelationship = relationships.find(
          //   relationship => relationship._id === relationshipProfileId
          // );

          // if (matchedRelationship) {
          //   setContact(matchedRelationship);
          // }
        }
      } catch (error) {
        console.error("Error fetching relationship profile:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContactProfile();
  }, [location.state?.contact, contact?._id, contact?.id]);

  console.log("Contact", contact);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!contact) {
    return (
      <GradientBackground>
        <TopBar showBackButton={true} onBackClick={() => navigate(-1)} />
        <div className="myprofile-profile-card">
          <div className="loading-container">
            <p>Profile not found.</p>
          </div>
        </div>
        {/* <AdviceInsight
          contact={contact}
          type={insightType}
          onClose={closeAdviceInsight}
        /> */}
      </GradientBackground>
    );
  }

  const openEditMode = () => {
    setIsEditMode(true);
  };

  const closeEditMode = async () => {
    setIsEditMode(false);

    // Re-fetch the relationship profile to update data
    try {
      const user2_relationId = location.state?.contact;
      const relationshipProfileId =
        user2_relationId?._id || user2_relationId?.id || "";

      if (relationshipProfileId !== "") {
        const updatedRelationships = await getRelationshipProfile(
          relationshipProfileId
        );
        setContact(updatedRelationships);
        setProfileSummary(updatedRelationships.summary);
        console.log("Updated relationship profile fetched successfully.");
      }
    } catch (error) {
      console.error("Error fetching updated relationship profile:", error);
    }
  };

  const handleBackClick = () => {
    if (isEditMode) {
      setIsEditMode(false);
    } else {
      navigate(-1);
    }
  };

  if (isEditMode) {
    return (
      <GradientBackground>
        <TopBar
          title="Edit Relationship"
          showBackButton={true}
          onBackClick={handleBackClick}
        />
        <EditRelationship
          contact={contact}
          onBack={closeEditMode}
          navigateBack={true}
        />
      </GradientBackground>
    );
  }

  const getContent = (section, toggle) => {
    console.log("Profile Summary:", profileSummary);
    console.log("Summary_primary", profileSummary.summary[`${section.title}`]);
    console.log("Section", section.key);

    if (!profileSummary) return null;

    if (section.key === "HowIShowUpToOthers") {
      return toggle === "primary"
        ? profileSummary?.summary[`${section.title}`][0]
        : profileSummary?.summary[`${section.title}`][1];
    }
    return profileSummary?.summary[`${section.title}`];
  };

  const strengthIcons = [chaticon, networkicon, planticon];
  const strengthColors = [
    "linear-gradient(45deg, #BCF5A7 23%, #96F5E0 100%), #82E3CC",
    "linear-gradient(45deg, #8AD8EB 23%, #82E1CC 100%), linear-gradient(45deg, #3FCDFF 0%, #CFB2FF 77%), #82E3CC",
    "linear-gradient(45deg, #85DFFF 0%, #CFB2FF 77%)",
  ];

  const handleInviteSubmit = (data) => {
    console.log(data);
    // Handle submission
    setShowInvite(true);
  };

  // console.log("type", type);
  return (
    <>
      {isDesktopView ? (
        <GradientBackground>
          <TopBar
            title={
              isDesktopView
                ? "Profile"
                : contact.related_data.relationship_type
            }
            titleStyle={
              !isDesktopView
                ? { color: "white", fontSize: "16px", fontWeight: "lighter" }
                : undefined
            }
            showBackButton={true}
            onBackClick={handleBackClick}
            edit={
              !isDesktopView ? (
                <button
                  onClick={openEditMode}
                  style={{
                    margin: "0px",
                    background: "inherit",
                    border: "none",
                    color: "white",
                  }}
                >
                  Edit
                </button>
              ) : undefined
            }
          />

          <div className="myprofile-profile-card">
            <div className="myprofile-profile-header">
              <div className="profile-profile-header-content">
                <div></div>

                <div className="profile-contact-type">
                  {contact.related_data.relationship_type}
                </div>

                <div>
                  <button
                    onClick={openEditMode}
                    style={{
                      margin: "0px",
                      background: "inherit",
                      border: "none",
                      color: "rgba(13, 94, 175, 1)",
                      fontFamily: "Urbanist",
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
              <div className="myprofile-container">
                <div className="status-circle-container">
                  <div className="status-circle-progress">
                    <CircularProgressBarProfile
                      value={contact?.profile_completion_percent}
                      disabled={false}
                    />
                  </div>

                  {contact.profile_pic_url ? (
                    <img
                      src={contact.profile_pic_url}
                      alt="Profile"
                      className="status-circle-profile-image"
                      onError={(e) => {
                        e.target.style.display = "none";
                      }}
                    />
                  ) : (
                    <div
                      className="status-circle-profile-image"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background:
                          "linear-gradient(45deg, #9eecff 23%, #a0ffea)",
                        color: "#005f6d",
                        fontWeight: "thin",
                        fontSize: "2rem",
                        borderRadius: "50%",
                        width: "128px",
                        height: "128px",
                      }}
                    >
                      {`${(contact?.first_name?.[0] || "").toUpperCase()}${(
                        contact?.last_name?.[0] || ""
                      ).toUpperCase()}` || ""}
                    </div>
                  )}
                </div>
              </div>

              <h2>{`${
                contact.first_name + " " + contact.last_name || "name"
              }`}</h2>
              {contact.primary_personality && (
                <p className="myprofile-subtitle">
                  <span
                    className="myprofile-personality-type"
                    style={{ color: "black" }}
                  >
                    {" "}
                    Personality Type:
                  </span>{" "}
                  {contact.primary_personality || "Not specified"}
                </p>
              )}
              {contact.badge_level && (
                <div className="myprofile-badge">
                  <img src={trophy} alt="Trophy" />
                  {contact?.badge_level || "Not specified"}
                </div>
              )}
            </div>
            {profileSummary?.myStrengths &&
              profileSummary.myStrengths.length > 0 && (
                <section className="myprofile-strengths-section">
                  <h3 className="myprofileh3" style={{ marginTop: "0px" }}>
                    My Strengths
                  </h3>
                  <div className="myprofile-strengths-container">
                    {profileSummary.myStrengths.map((strength, index) => (
                      <div
                        key={index}
                        className="myprofile-strength-card"
                        style={{ background: strengthColors[index] }}
                      >
                        <div className="myprofile-strength-icon">
                          <img
                            src={strengthIcons[index]}
                            alt={`icon-${index}`}
                          />
                        </div>
                        <div className="myprofile-strength-text">
                          {strength}
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              )}
            {showInsightsAndAdvice && (
              <section className="myprofile-new-section">
                <div className="myprofile-card-container">
                  <div
                    className="myprofile-card"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      background: "#F7F7F7",
                      border: "none",
                    }}
                  >
                    <div
                      className="myprofile-icon-wrapper"
                      style={{
                        borderRadius: "12px",
                        background:
                          "var(--tile-icon-gradient-01, linear-gradient(320deg, #82E3CC -28.48%, #80CEED 94.28%))",
                        marginBottom: "0px",
                      }}
                    >
                      <div className="profile-image-container">
                        <Relationship_advisor />
                      </div>
                    </div>

                    <div
                      className="myprofile-card-content"
                      onClick={() => getAdviceInsight("advice")}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h4 style={{ marginLeft: "10px" }}>
                            Relationship Advice
                          </h4>
                        </div>
                        <RigthArrow
                          style={{
                            padding: "5px",
                            background: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="myprofile-card"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      background: "#F7F7F7",
                      border: "none",
                    }}
                  >
                    <div
                      className="myprofile-icon-wrapper"
                      style={{
                        borderRadius: "12px",
                        background:
                          "var(--tile-icon-gradient-01, linear-gradient(320deg, #82E3CC -28.48%, #80CEED 94.28%))",
                        marginBottom: "0px",
                      }}
                    >
                      <div className="profile-image-container-2">
                        <Relationship_advisor />
                      </div>
                    </div>
                    <div
                      className="myprofile-card-content"
                      onClick={() => getAdviceInsight("insights")}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h4 style={{ marginLeft: "10px" }}>
                            Relationship Insights
                          </h4>
                        </div>
                        <RigthArrow
                          style={{
                            padding: "5px",
                            background: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section>
              {contact.relation_status === "nonExist" && (
                <button
                  className="join-aris-btn"
                  onClick={() => setShowInvite(true)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <JoinAris />
                    <p>Send Invite to join Aris</p>
                  </div>
                  <RigthArrow
                    style={{
                      padding: "5px",
                      background: "white",
                      borderRadius: "50%",
                    }}
                  />
                </button>
              )}
              {showInvite && (
                <Invitetoaris
                  onClose={() => setShowInvite(false)}
                  relationId={contact._id || contact.id}
                  onSubmit={handleInviteSubmit}
                  name={contact.first_name + " " + contact.last_name}
                />
              )}
            </section>

            {/* <section className="myprofile-summary-section">
          <h3>Summary</h3>
          <div className="myprofile-traits-container">
            <span className="myprofile-trait-tag">
              
              <p style={{ margin: '0px' }}>generating...</p>
            </span>
            <span className="myprofile-trait-tag">
             
              <p style={{ margin: '0px' }}>generating...</p>
            </span>
          </div>

          <section className="myprofile-details-section">
            {sections.map((section, index) => (
              <div
                key={index}
                className={`myprofile-detail-item ${openSections[index] ? 'expanded' : 'collapsed'}`}
              >
                <div
                  className="myprofile-detail-header"
                  onClick={() => toggleSection(index)}
                >
                  <h4>{section.title}</h4>
                  {openSections[index]
                    ? <UpArrow className='arrowbg' />
                    : <DownArrow className='arrowbg' />
                  }
                </div>

                {openSections[index] && (
                  <div className="myprofile-detail-content">
                    <p>{section.content}</p>

                    {index === 0 && (
                      <div className="myprofile-toggle-container">
                        <span>Primary</span>
                        <label className="myprofile-switch">
                          <input type="checkbox" className="myprofile-checkbox" />
                          <span className="myprofile-slider"></span>
                        </label>
                        <span>Under Pressure</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </section>
        </section> */}

            {!summaryError &&
              profileSummary?.summary &&
              Object.keys(profileSummary.summary).length > 0 && (
                <section className="myprofile-summary-section">
                  <h3 className="myprofileh3">Summary</h3>
                  <div className="myprofile-traits-container">
                    {profileSummary?.topStrengths?.length > 0
                      ? profileSummary.topStrengths.map((trait, index) => (
                          <span key={index} className="myprofile-trait-tag">
                            {trait}
                          </span>
                        ))
                      : null}
                  </div>

                  <section className="myprofile-details-section">
                    {sections.map((section, index) => (
                      <div
                        key={index}
                        className={`myprofile-detail-item ${
                          openSections[section.key] ? "expanded" : "collapsed"
                        }`}
                      >
                        <div
                          className="myprofile-detail-header"
                          onClick={() => toggleSection(section.key)}
                        >
                          <h4>{section.title}</h4>
                          {openSections[section.key] ? (
                            <UpArrow className="arrowbg" />
                          ) : (
                            <DownArrow className="arrowbg" />
                          )}
                        </div>

                        {openSections[section.key] && (
                          <div className="myprofile-detail-content">
                            {section.key === "HowIShowUpToOthers" ? (
                              <>
                                <p className="summary-content-profile">{getContent(section, togglevalue)}</p>
                                <div className="myprofile-toggle-container">
                                  <span>Primary</span>
                                  <label className="myprofile-switch">
                                    <input
                                      type="checkbox"
                                      className="myprofile-checkbox"
                                      onChange={(e) =>
                                        settogglevalue(
                                          e.target.checked
                                            ? "pressure"
                                            : "primary"
                                        )
                                      }
                                    />
                                    <span className="myprofile-slider"></span>
                                  </label>
                                  <span>Under Pressure</span>
                                </div>
                              </>
                            ) : (
                              <p>{getContent(section)}</p>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </section>
                </section>
              )}
                  {adviceInsight && (
                    <div className="manage-overlay">
                    <div className="manage-modal">
            <AdviceInsight
              contact={contact}
              selectedTab={insightType === "insights" ? 2 : 1} // Convert type to number
              onClose={closeAdviceInsight}
              isOpen={adviceInsight}
            />
            </div>
            </div>
          )}
          </div>
      
        </GradientBackground>
      ) : (
        <div className="mobile-background">
          <TopBar
            title={
              isDesktopView
                ? "Profile"
                : contact.related_data.relationship_type
            }
            titleStyle={
              !isDesktopView
                ? { color: "white", fontSize: "16px", fontWeight: "lighter" }
                : undefined
            }
            showBackButton={true}
            onBackClick={handleBackClick}
            edit={
              !isDesktopView ? (
                <button
                  onClick={openEditMode}
                  style={{
                    margin: "0px",
                    background: "inherit",
                    border: "none",
                    color: "white",
                  }}
                >
                  Edit
                </button>
              ) : undefined
            }
          />
          <div className="myprofile-profile-header">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div></div>
              {isDesktopView ? (
                <button
                  onClick={openEditMode}
                  style={{
                    margin: "0px",
                    background: "inherit",
                    border: "none",
                    color: "rgba(13, 94, 175, 1)",
                  }}
                >
                  Edit
                </button>
              ) : null}
            </div>
            <div className="myprofile-container">
              <div className="status-circle-container">
                <div className="status-circle-progress">
                  <CircularProgressBarProfile
                    value={contact.profile_completion_percent}
                    disabled={false}
                  />
                </div>

                {contact.profile_pic_url ? (
                  <img
                    src={contact.profile_pic_url}
                    alt="Profile"
                    className="status-circle-profile-image"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                ) : (
                  <div
                    className="status-circle-profile-image"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background:
                        "linear-gradient(45deg, #9eecff 23%, #a0ffea)",
                      color: "#005f6d",
                      fontWeight: "thin",
                      fontSize: "2rem",
                      borderRadius: "50%",
                      width: "128px",
                      height: "128px",
                    }}
                  >
                    {`${(contact?.first_name?.[0] || "").toUpperCase()}${(
                      contact?.last_name?.[0] || ""
                    ).toUpperCase()}` || ""}
                  </div>
                )}
              </div>
            </div>
            <h2>{`${contact.first_name + " " + contact.last_name}`}</h2>
            {contact.primary_personality && (
              <p className="myprofile-subtitle">
                <span className="myprofile-personality-type">
                  {" "}
                  Personality Type:
                </span>{" "}
                {contact.primary_personality || "Not specified"}
              </p>
            )}
            {contact.badge_level && (
              <div className="myprofile-badge">
                <img src={trophy} alt="Trophy" />
                {contact?.badge_level || "Not specified"}
              </div>
            )}
          </div>
          <div className="myprofile-profile-card">
            {profileSummary?.myStrengths &&
              profileSummary.myStrengths.length > 0 && (
                <section className="myprofile-strengths-section">
                  <h3 className="myprofileh3" style={{ marginTop: "0px" }}>
                    My Strengths
                  </h3>
                  <div className="myprofile-strengths-container">
                    {profileSummary.myStrengths.map((strength, index) => (
                      <div
                        key={index}
                        className="myprofile-strength-card"
                        style={{ background: strengthColors[index] }}
                      >
                        <div className="myprofile-strength-icon">
                          <img
                            src={strengthIcons[index]}
                            alt={`icon-${index}`}
                          />
                        </div>
                        <div className="myprofile-strength-text">
                          {strength}
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              )}
            {showInsightsAndAdvice && (
              <section className="myprofile-new-section">
                <div className="myprofile-card-container">
                  <div
                    className="myprofile-card"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      background: "rgb(247, 247, 247)",
                      border: "none",
                    }}
                  >
                    <div
                      className="myprofile-icon-wrapper"
                      style={{
                        borderRadius: "12px",
                        background:
                          "var(--tile-icon-gradient-01, linear-gradient(320deg, #82E3CC -28.48%, #80CEED 94.28%))",
                        marginBottom: "0px",
                      }}
                    >
                      <div className="profile-image-container">
                        <Relationship_advisor />
                      </div>
                    </div>

                    <div
                      className="myprofile-card-content"
                      onClick={() => getAdviceInsight("advice")}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          background: "#F7F7F7",
                          border: "none",
                        }}
                      >
                        <div>
                          <h4 style={{ marginLeft: "10px" }}>
                            Relationship Advice
                          </h4>
                        </div>
                        <RigthArrow
                          style={{
                            padding: "5px",
                            background: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="myprofile-card"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      background: "#F7F7F7",
                      border: "none",
                    }}
                  >
                    <div
                      className="myprofile-icon-wrapper"
                      style={{
                        borderRadius: "12px",
                        background:
                          "var(--tile-icon-gradient-01, linear-gradient(320deg, #82E3CC -28.48%, #80CEED 94.28%))",
                        marginBottom: "0px",
                      }}
                    >
                      <div className="profile-image-container-2">
                        <Relationship_advisor />
                      </div>
                    </div>
                    <div
                      className="myprofile-card-content"
                      onClick={() => getAdviceInsight("insights")}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          background: "#F7F7F7",
                        }}
                      >
                        <div>
                          <h4 style={{ marginLeft: "10px" }}>
                            Relationship Insights
                          </h4>
                        </div>
                        <RigthArrow
                          style={{
                            padding: "5px",
                            background: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section>
              {contact.relation_status === "nonExist" && (
                <button
                  className="join-aris-btn"
                  onClick={() => setShowInvite(true)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <JoinAris />
                    <p>Send Invite to join Aris</p>
                  </div>
                  <RigthArrow
                    style={{
                      padding: "5px",
                      background: "white",
                      borderRadius: "50%",
                    }}
                  />
                </button>
              )}
              {showInvite && (
                <Invitetoaris
                  onClose={() => setShowInvite(false)}
                  relationId={contact._id || contact.id}
                  onSubmit={handleInviteSubmit}
                  name={contact.first_name + " " + contact.last_name}
                />
              )}
            </section>

            {!summaryError &&
              profileSummary?.summary &&
              Object.keys(profileSummary.summary).length > 0 && (
                <section className="myprofile-summary-section">
                  <h3 className="myprofileh3">Summary</h3>
                  <div className="myprofile-traits-container">
                    {profileSummary?.topStrengths?.length > 0 ? (
                      profileSummary.topStrengths.map((trait, index) => (
                        <span key={index} className="myprofile-trait-tag">
                          {trait}
                        </span>
                      ))
                    ) : (
                      <p>No strengths available.</p>
                    )}
                  </div>

                  <section className="myprofile-details-section">
                    {sections.map((section, index) => (
                      <div
                        key={index}
                        className={`myprofile-detail-item ${
                          openSections[index] ? "expanded" : "collapsed"
                        }`}
                      >
                        <div
                          className="myprofile-detail-header"
                          onClick={() => toggleSection(index)}
                        >
                          <h4>{section.title}</h4>
                          {openSections[index] ? (
                            <UpArrow className="arrowbg" />
                          ) : (
                            <DownArrow className="arrowbg" />
                          )}
                        </div>

                        {openSections[index] && (
                          <div className="myprofile-detail-content">
                            {section.key === "HowIShowUpToOthers" ? (
                              <>
                                <p className="summary-content-profile">{getContent(section, togglevalue)}</p>
                                <div className="myprofile-toggle-container">
                                  <span>Primary</span>
                                  <label className="myprofile-switch">
                                    <input
                                      type="checkbox"
                                      className="myprofile-checkbox"
                                      onChange={(e) => {
                                        const content = e.target.checked
                                          ? "pressure"
                                          : "primary";
                                        settogglevalue(content);
                                      }}
                                    />
                                    <span className="myprofile-slider"></span>
                                  </label>
                                  <span>Under Pressure</span>
                                </div>
                              </>
                            ) : (
                              <p>{getContent(section)}</p>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </section>
                </section>
              )}
          </div>
          {adviceInsight && (
               <div className="manage-overlay">
                    <div className="manage-modal">
        <AdviceInsight
          contact={contact}
          selectedTab={insightType === "insights" ? 2 : 1} // Convert type to number
          onClose={closeAdviceInsight}
          isOpen={adviceInsight}
          
          
        />
        </div>
        </div>
      )}
        </div>
      )}

      
    </>
  );
};

export default RelationshipProfile;

import React, { useState, useEffect } from "react";

const ChangingProgressProvider = ({ values, interval = 1000, children }) => {
  const [valuesIndex, setValuesIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setValuesIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        return nextIndex < values.length ? nextIndex : prevIndex; // Hold on the last value
      });
    }, interval);

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [values, interval]);

  return children(values[valuesIndex]);
};

export default ChangingProgressProvider;

import React, { useState, useEffect } from 'react';
import ForgetPasswordLeftSection from '../../Components/ForgetPasswordLeftSection';
import PasswordInput from '../../Components/PasswordInput';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { resetPassword } from '../../Services/auth'; // Import the reset password API
import checkmark from '../../Assets/images/check-circle.svg';
import { ReactComponent as CloseIcon } from '../../Assets/icon/close.svg';
import './style.css';
import { useHeader } from '../../Providers/HeaderContextProvider';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // Get URL query parameters
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [linkError, setLinkError] = useState('');
  const [oobCode, setOobCode] = useState('');
  const { setHeaderContent } = useHeader();

  useEffect(() => {
    setHeaderContent(false);
    const mode = searchParams.get('mode');
    const code = searchParams.get('oobCode');

    if (mode === 'resetPassword' && code) {
      setOobCode(code); // Store the oobCode
    } else {
      setLinkError('Invalid or expired link. Please try again.');
    }
  }, [searchParams]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const validatePassword = (password) => ({
    hasMinLength: password.length >= 8,
    hasNumber: /\d/.test(password),
    hasUpperCase: /[A-Z]/.test(password),
    hasLowerCase: /[a-z]/.test(password),
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    const criteria = validatePassword(password);

    if (Object.values(criteria).every(Boolean)) {
      try {
        await resetPassword(oobCode, password);
        setSuccessMessage('Password reset successfull.');
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } catch (error) {
        setErrorMessage('Failed to reset password. Please try again.');
      }
    } else {
      setErrorMessage('Password does not meet the required criteria.');
    }
  };

  return (
    <div className="resetpassword-container">
      <ForgetPasswordLeftSection />
      <div className="resetpassword-right-section">
        <CloseIcon className='forgetpassword-close-icon' style={{
          border: '1px solid var(--gray-light-hover, #E4E4E7)',
          borderRadius: '50%',
          padding: '5px',
          boxSizing: 'border-box'
        }} onClick={() => navigate('/login')} />
        <div className="resetpassword-right-section-content">
          <div>
            <h2 className='Resetpasswordh2'>Reset Password</h2>
            <PasswordInput
              value={password}
              placeholder="Create your password"
              onChange={handlePasswordChange}
              error={errorMessage}
              submitted={submitted}
            />
            {submitted && errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="password-criteria">
              <div className={`criteria-item ${validatePassword(password).hasMinLength ? 'valid' : ''}`}>
                {validatePassword(password).hasMinLength && <img src={checkmark} alt="check" className="signup-checkmark" />}
                At least 8 characters long
              </div>
              <div className={`criteria-item ${validatePassword(password).hasNumber ? 'valid' : ''}`}>
                {validatePassword(password).hasNumber && <img src={checkmark} alt="check" className="signup-checkmark" />}
                Include a number
              </div>
              <div className={`criteria-item ${validatePassword(password).hasUpperCase ? 'valid' : ''}`}>
                {validatePassword(password).hasUpperCase && <img src={checkmark} alt="check" className="signup-checkmark" />}
                Include an uppercase letter
              </div>
              <div className={`criteria-item ${validatePassword(password).hasLowerCase ? 'valid' : ''}`}>
                {validatePassword(password).hasLowerCase && <img src={checkmark} alt="check" className="signup-checkmark" />}
                Include a lowercase letter
              </div>
              <div className={`criteria-item ${validatePassword(password).hasSpecialChar ? 'valid' : ''}`}>
                {validatePassword(password).hasSpecialChar && <img src={checkmark} alt="check" className="signup-checkmark" />}
                Include a special character
              </div>
              </div>
            </div>
            <div>
              {successMessage && <p className="success-message">{successMessage}</p>}
            <button type="submit" className="resetpassword-login-button" onClick={handleSubmit}>Reset Password</button>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link to="/signup" className="login-page-create-account">Create Account?</Link>
            </div>
          </div>
        </div>


        {linkError && <p className="error-message">{linkError}</p>}
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useState, useEffect } from "react";
import "./carousel.css";
import { FirstContainer, SecondContainer, ThirdContainer, FourthContainer } from "../Carousel_container";
import right from "../../Assets/icon/rightarrow.svg";
import left from "../../Assets/icon/leftarrow.svg";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const components = [<FirstContainer />, <SecondContainer />, <ThirdContainer />, <FourthContainer />];

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, 3000]);

  const goToNext = () => {
    const isLastSlide = currentIndex === components.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    
    if (isLastSlide) {
      // First set to last slide without animation
      setCurrentIndex(components.length - 1);
      // Then quickly set to first slide with animation
      setTimeout(() => {
        setCurrentIndex(newIndex);
      }, 0);
    } else {
      setCurrentIndex(newIndex);
    }
  };

  const goToPrev = () => {
  const isFirstSlide = currentIndex === 0;
  const newIndex = isFirstSlide ? components.length - 1 : currentIndex - 1;
  
  if (isFirstSlide) {
    // First set to first slide without animation
    setCurrentIndex(0);
    // Then quickly set to last slide with animation
    setTimeout(() => {
      setCurrentIndex(newIndex);
    }, 0);
  } else {
    setCurrentIndex(newIndex);
  }
};

  const goToIndex = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel-container">
      <div className="carousel-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {components.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${index === currentIndex ? "active" : ""}`}
          >
            {item}
            {index === 0 && ( <>
              <p className="caraousel-title">Discover Your True Self</p>
              <p className="caraousel-description">Uncover your unique strengths and accelerate your potential.</p>
            </>)}
            {index === 1 && ( <> 
              <p className="caraousel-title">Understand Others</p>
              <p className="caraousel-description">With Aris, gain deeper insights into those around you.</p>
            </>)}
            {index === 2 && ( <>
               <p className="caraousel-title">Build Deeper Connections</p>
               <p className="caraousel-description">Forge stronger bonds that lead to meaningful relationships in every aspect of your life.</p>
            </>)}
            {index === 3 && ( <>
              <p className="caraousel-title">Welcome to Aris</p>
              <p className="caraousel-description">Prepare to dive into a world of insights and illuminate your personal journey.</p>
            </>)}
          </div>
        ))}
      </div>

      <div className="carousel-indicators">
        {components.map((_, index) => (
          <div
            key={index}
            className={`indicator ${index === currentIndex ? "active" : ""}`}
            onClick={() => goToIndex(index)}
          />
        ))}
      </div>

      <div className="carousel-navigation">
        <button className="prev-btn" disabled={currentIndex === 0} onClick={goToPrev}>
          <img src={left} alt="prev" />
        </button>
        <button className="next-btn" disabled={currentIndex === components.length - 1} onClick={goToNext}>
          <img src={right} alt="prev" />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
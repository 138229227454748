import React, { useEffect, useState } from "react";
import arrowLeft from "../../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../Providers/HeaderContextProvider";
import "./style.css";
import ToggleButton from "../../../Components/ToggleButton/ToggleButton";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../../../Services/settingsServices";
import GradientBackground from "../../../Components/AddRelationshipHeaderGradient";

function Communications() {
  const navigate = useNavigate();
  const { setHeaderContent } = useHeader();
  const [notificationSettings, setnotificationSettings] = useState({
    email_comm: false,
  });
  useEffect(() => {
    setHeaderContent(false);
    getNotificationSettings().then((e) => {
      console.log(e);
      setnotificationSettings({
        email_comm: e?.email_comm,
      });
    });
  }, []);

  const onToggleSelection = (name) => {
    console.log(name);

    setnotificationSettings((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));

    updateNotificationSettings({
      [name]: !notificationSettings[name],
    });
  };

  console.log(notificationSettings, "notSet");

  return (
    <div>
      <GradientBackground>
      <header className="termsofuse-header">
        <div className="termsofuse-gradient-background">
          <div className="termsofuse-nav-container">
            <div
              className="termsofuse-logo-container"
              onClick={() => navigate(-1)}
            >
              <img
                src={arrowLeft}
                alt="Back"
                className="termsofuse-back-button"
              />
            </div>
            <h1 className="termsofuse-title">Communications</h1>
            <div className="termsofuse-space"></div>
          </div>
          <div className="termsofuse-last-subtext">Email</div>
        </div>
      </header>
      <div className="notification-set-hero-container">
        <div className="notification-set-accord">
          <div className="notification-set-accord-title-container">
            <div className="not-set-head">Week in review emails</div>
            <div className="not-set-sub">
              Lessons, content, insights connected to your Aris Portrait.
            </div>
          </div>
          <div>
            <ToggleButton
              isChecked={notificationSettings.email_comm}
              onChange={() => onToggleSelection("email_comm")}
              name={"email_comm"}
            />
          </div>
        </div>
      </div>
      </GradientBackground>
    </div>
  );
}

export default Communications;

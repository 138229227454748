import ToggleButton from "../../Components/ToggleButton/ToggleButton";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../../Services/settingsServices";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../Providers/HeaderContextProvider";
import React, { useEffect, useState } from "react";
import "./style.css";
import { ReactComponent as Settings } from "../../Assets/icon/x-close.svg";
import arrowLeft from "../../Assets/icon/arrow-narrow-left.svg";
import GradientBackground from "../../Components/AddRelationshipHeaderGradient";
function SettingsContainer({ setShowSettings }) {
  const [notificationSettings, setnotificationSettings] = useState({
    general: false,
    invite: false,
    coach: false,
  });

  useEffect(() => {
    getNotificationSettings().then((e) => {
      console.log(e);
      setnotificationSettings({
        general: e?.general,
        invite: e?.invite,
        coach: e?.coach,
      });
    });
  }, []);

  const onToggleSelection = (name) => {
    console.log(name);

    setnotificationSettings((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));

    updateNotificationSettings({
      [name]: !notificationSettings[name],
    });
  };

  const handleClose = () => {
    setShowSettings(false);
  };

  return (
    <>
      <div className="notification-set-hero-container-settings">
        <div className="head-notification">
          <div className="head-not-txt">Settings</div>
          <div className="close-x-icon-settings" onClick={handleClose}>
            <Settings />
          </div>
        </div>
        <div className="notification-set-accord-settings">
          <div className="notification-set-accord-title-container">
            <div className="not-set-head">General communications</div>
            <div className="not-set-sub">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </div>
          </div>
          <div className="toggle-wrapper">
            <ToggleButton
              isChecked={notificationSettings.general}
              onChange={() => onToggleSelection("general")}
              name={"general"}
            />
          </div>
        </div>
        <div className="notification-set-accord-settings">
          <div className="notification-set-accord-title-container">
            <div className="not-set-head">Connection requests</div>
            <div className="not-set-sub">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </div>
          </div>
          <div className="toggle-wrapper">
            <ToggleButton
              isChecked={notificationSettings.invite}
              onChange={() => onToggleSelection("invite")}
              name={"invite"}
            />
          </div>
        </div>
        <div className="notification-set-accord-settings">
          <div className="notification-set-accord-title-container">
            <div className="not-set-head">Life coach follow-ups</div>
            <div className="not-set-sub">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </div>
          </div>
          <div className="toggle-wrapper">
            <ToggleButton
              isChecked={notificationSettings.coach}
              onChange={() => onToggleSelection("coach")}
              name={"coach"}
            />
          </div>
        </div>
      </div>

      <div className="settings-mobile">
        <GradientBackground />
        <header className="termsofuse-header">
          {/* <div className="termsofuse-gradient-background"> */}
          <div className="termsofuse-nav-container">
            <div className="termsofuse-logo-container">
              <img
                src={arrowLeft}
                alt="Back"
                className="termsofuse-back-button"
                onClick={handleClose}
              />
            </div>
            <h1 className="head-not-txt">Notifications Settings</h1>
            <div className="termsofuse-space"></div>
            {/* <div className="not-set-head alg-left">Push Notifications</div> */}
          </div>
          {/* </div> */}
        </header>
        <div className="settings-phone">
          <div className="not-set-head alg-left">Push Notifications</div>
          <div className="notification-set-accord-settings">
            <div className="notification-set-accord-title-container">
              <div className="not-set-head">General communications</div>
              <div className="not-set-sub">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div>
            <div className="toggle-wrapper">
              <ToggleButton
                isChecked={notificationSettings.general}
                onChange={() => onToggleSelection("general")}
                name={"general"}
              />
            </div>
          </div>

          <div className="notification-set-accord-settings">
            <div className="notification-set-accord-title-container">
              <div className="not-set-head">Connection requests</div>
              <div className="not-set-sub">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div>
            <div className="toggle-wrapper">
              <ToggleButton
                isChecked={notificationSettings.invite}
                onChange={() => onToggleSelection("invite")}
                name={"invite"}
              />
            </div>
          </div>

          <div className="notification-set-accord-settings">
            <div className="notification-set-accord-title-container">
              <div className="not-set-head">Life coach follow-ups</div>
              <div className="not-set-sub">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div>
            <div className="toggle-wrapper">
              <ToggleButton
                isChecked={notificationSettings.coach}
                onChange={() => onToggleSelection("coach")}
                name={"coach"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingsContainer;

// import React, { useState, useEffect } from "react";
// import people from "../../../../Assets/images/people.png";
// import { ReactComponent as Info } from "../../../../Assets/icon/Info-ref.svg";
// import { ReactComponent as Backbutton } from "../../../../Assets/icon/backbutton.svg";
// import ReactMarkdown from "react-markdown";
// import InfoTooltip from "./InfoTooltip";

// export function Processing({
//   data,
//   onBack,
//   profilePic1,
//   profilePic2,
//   initials1,
//   initials2,
//   name1,
//   name2,
//   tooltip,
//   user1Metrics,
//   user2Metrics,
// }) {
//   // State to manage animated positions for both users
//   const [positions, setPositions] = useState({
//     internal: { user1: "50%", user2: "50%" },
//     Intuitive: { user1: "50%", user2: "50%" },
//     Emotion: { user1: "50%", user2: "50%" },
//     Spontaneous: { user1: "50%", user2: "50%" },
//   });

//   // Function to determine closeness of values
//   const isClose = (value1, value2) => {
//     return Math.abs(value1 - value2) < 5;
//   };

//   // Metrics we are using
//   const metrics = [
//     { key: "internal", labels: ["External", "Internal"] },
//     { key: "Intuitive", labels: ["Concrete", "Intuitive"] },
//     { key: "Emotion", labels: ["Logic", "Emotion"] },
//     { key: "Spontaneous", labels: ["Orderly", "Spontaneous"] },
//   ];

//   // Update positions when metrics change
//   useEffect(() => {
//     if (user1Metrics || user2Metrics) {
//       setTimeout(() => {
//         setPositions({
//           internal: {
//             user1: user1Metrics ? `${100 - user1Metrics.internal}%` : "50%",
//             user2: user2Metrics ? `${100 - user2Metrics.internal}%` : "50%",
//           },
//           Intuitive: {
//             user1: user1Metrics ? `${100 - user1Metrics.Intuitive}%` : "50%",
//             user2: user2Metrics ? `${100 - user2Metrics.Intuitive}%` : "50%",
//           },
//           Emotion: {
//             user1: user1Metrics ? `${100 - user1Metrics.Emotion}%` : "50%",
//             user2: user2Metrics ? `${100 - user2Metrics.Emotion}%` : "50%",
//           },
//           Spontaneous: {
//             user1: user1Metrics ? `${100 - user1Metrics.Spontaneous}%` : "50%",
//             user2: user2Metrics ? `${100 - user2Metrics.Spontaneous}%` : "50%",
//           },
//         });
//       }, 300);
//     }
//   }, [user1Metrics, user2Metrics]);

//   return (
//     <div className="Processing-container">
//       <div className="Processing-header">
//         <button className="backbutton-insights" onClick={onBack}>
//           <Backbutton /> Back
//         </button>
//         <div className="Processing-txt">Processing</div>
//         <div>
//           <InfoTooltip content={tooltip} />
//         </div>
//       </div>

//       {/* Looping through all metrics */}
//       {metrics.map(({ key, labels }) => (
//         <div key={key} className="insight-progress-container">
//           <div className="insight-progress-info-container">
//             <div>{labels[0]}</div>
//             <div>{labels[1]}</div>
//           </div>
//           <div className="insight-progress-line"></div>
//           <div className="insight-progress-line-inner"></div>

//           {[20, 40, 60, 80].map((left) => (
//             <div
//               key={left}
//               className="insight-progress-line-inner"
//               style={{ height: "10%", left: `${left}%`, transform: "translateY(420%)" }}
//             ></div>
//           ))}

//           {/* User 1 Position */}
//           {user1Metrics && (
//             <div
//               className="insight-person-container"
//               style={{
//                 left: positions[key].user1,
//                 transition: "left 1s ease-in-out",
//               }}
//             >
//               {profilePic1 ? (
//                 <img
//                   src={profilePic1}
//                   alt={initials1 || "Profile"}
//                   style={{ width: "24px", height: "24px", fontSize: "10px" }}
//                   onError={(e) => {
//                     e.target.style.display = "none";
//                     e.target.nextElementSibling.style.display = "flex";
//                   }}
//                 />
//               ) : (
//                 <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>
//                   {initials1 || ""}
//                 </div>
//               )}
//               {!isClose(user1Metrics[key], user2Metrics?.[key]) && name1}
//             </div>
//           )}

//           {/* User 2 Position */}
//           {user2Metrics && (
//             <div
//               className="insight-person-container"
//               style={{
//                 left: positions[key].user2,
//                 transition: "left 1s ease-in-out",
//               }}
//             >
//               {profilePic2 ? (
//                 <img
//                   src={profilePic2}
//                   alt={initials2 || "Profile"}
//                   style={{ width: "24px", height: "24px", fontSize: "10px" }}
//                   onError={(e) => {
//                     e.target.style.display = "none";
//                     e.target.nextElementSibling.style.display = "flex";
//                   }}
//                 />
//               ) : (
//                 <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>
//                   {initials2 || ""}
//                 </div>
//               )}
//               {!isClose(user1Metrics?.[key], user2Metrics[key]) && name2}
//             </div>
//           )}
//         </div>
//       ))}

//       {/* Personality Content */}
//       <div className="personality-content">
//         <div style={{ textAlign: "left" }}>
//           <ReactMarkdown
//             components={{
//               h1: "h3",
//               h2: "h4",
//             }}
//           >
//             {data?.replace(/\n/g, "\n\n") || ""}
//           </ReactMarkdown>
//         </div>
//       </div>
//     </div>
//   );
// }



import React, { useState, useEffect } from "react";
import people from "../../../../Assets/images/people.png";
import { ReactComponent as Info } from "../../../../Assets/icon/Info-ref.svg";
import { ReactComponent as Backbutton } from "../../../../Assets/icon/backbutton.svg";
import ReactMarkdown from "react-markdown";
import InfoTooltip from "./InfoTooltip";

export function Processing({
  data,
  onBack,
  profilePic1,
  profilePic2,
  initials1,
  initials2,
  name1,
  name2,
  tooltip,
  user1Metrics,
  user2Metrics,
}) {
  // State to manage animated positions for both users
  const [positions, setPositions] = useState({
    internal: { user1: "50%", user2: "50%" },
    Intuitive: { user1: "50%", user2: "50%" },
    Emotion: { user1: "50%", user2: "50%" },
    Spontaneous: { user1: "50%", user2: "50%" },
  });

  // Function to determine closeness of values
  const isClose = (value1, value2) => {
    return Math.abs(value1 - value2) <=10; // Increased threshold for better detection
  };

  // Function to get combined name display
  const getCombinedNameDisplay = () => {
    return `${name1} & ${name2}`;
  };

  // Metrics we are using
  const metrics = [
    { key: "internal", labels: ["External", "Internal"] },
    { key: "Intuitive", labels: ["Concrete", "Intuitive"] },
    { key: "Emotion", labels: ["Logic", "Emotion"] },
    { key: "Spontaneous", labels: ["Orderly", "Spontaneous"] },
  ];

  // Update positions when metrics change
  useEffect(() => {
    if (user1Metrics || user2Metrics) {
      setTimeout(() => {
        setPositions({
          internal: {
            user1: user1Metrics ? `${100 - user1Metrics.internal}%` : "50%",
            user2: user2Metrics ? `${100 - user2Metrics.internal}%` : "50%",
          },
          Intuitive: {
            user1: user1Metrics ? `${100 - user1Metrics.Intuitive}%` : "50%",
            user2: user2Metrics ? `${100 - user2Metrics.Intuitive}%` : "50%",
          },
          Emotion: {
            user1: user1Metrics ? `${100 - user1Metrics.Emotion}%` : "50%",
            user2: user2Metrics ? `${100 - user2Metrics.Emotion}%` : "50%",
          },
          Spontaneous: {
            user1: user1Metrics ? `${100 - user1Metrics.Spontaneous}%` : "50%",
            user2: user2Metrics ? `${100 - user2Metrics.Spontaneous}%` : "50%",
          },
        });
      }, 300);
    }
  }, [user1Metrics, user2Metrics]);

  // Function to calculate midpoint position
  const getMidpointPosition = (metric) => {
    if (!user1Metrics || !user2Metrics) return "50%";
    
    const pos1 = parseFloat(positions[metric].user1);
    const pos2 = parseFloat(positions[metric].user2);
    return `${(pos1 + pos2) / 2}%`;
  };

  return (
    <div className="Processing-container">
      <div className="Processing-header">
        <button className="backbutton-insights" onClick={onBack}>
          <Backbutton /> Back
        </button>
        <div className="Processing-txt">Processing</div>
        <div>
          <InfoTooltip content={tooltip} />
        </div>
      </div>

      {/* Looping through all metrics */}
      {metrics.map(({ key, labels }) => (
        <div key={key} className="insight-progress-container">
          <div className="insight-progress-info-container">
            <div>{labels[0]}</div>
            <div>{labels[1]}</div>
          </div>
          <div className="insight-progress-line"></div>
          <div className="insight-progress-line-inner"></div>

          {[20, 40, 60, 80].map((left) => (
            <div
              key={left}
              className="insight-progress-line-inner"
              style={{ height: "10%", left: `${left}%`, transform: "translateY(420%)" }}
            ></div>
          ))}

          {/* Combined display when profiles are close */}
          {user1Metrics && user2Metrics && isClose(user1Metrics[key], user2Metrics[key]) && (
            <div
              className="insight-person-container combined-names"
              style={{
                left: getMidpointPosition(key),
                transition: "left 1s ease-in-out",
                zIndex: 10
              }}
            >
              <div className="combined-profile-pics" style={{ display: "flex", gap: "2px" }}>
                {profilePic1 ? (
                  <img
                    src={profilePic1}
                    alt={initials1 || "Profile"}
                    style={{ width: "24px", height: "24px", fontSize: "10px" }}
                  />
                ) : (
                  <div
                    className="profile-initials"
                    style={{ width: "24px", height: "24px", fontSize: "10px" }}
                  >
                    {initials1 || ""}
                  </div>
                )}
                
                {profilePic2 ? (
                  <img
                    src={profilePic2}
                    alt={initials2 || "Profile"}
                    style={{ width: "24px", height: "24px", fontSize: "10px" }}
                  />
                ) : (
                  <div
                    className="profile-initials"
                    style={{ width: "24px", height: "24px", fontSize: "10px" }}
                  >
                    {initials2 || ""}
                  </div>
                )}
              </div>
              {getCombinedNameDisplay()}
            </div>
          )}

          {/* User 1 Position (only shown when not close) */}
          {user1Metrics && !isClose(user1Metrics[key], user2Metrics?.[key]) && (
            <div
              className="insight-person-container"
              style={{
                left: positions[key].user1,
                transition: "left 1s ease-in-out",
              }}
            >
              {profilePic1 ? (
                <img
                  src={profilePic1}
                  alt={initials1 || "Profile"}
                  style={{ width: "24px", height: "24px", fontSize: "10px" }}
                  onError={(e) => {
                    e.target.style.display = "none";
                    e.target.nextElementSibling.style.display = "flex";
                  }}
                />
              ) : (
                <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>
                  {initials1 || ""}
                </div>
              )}
              {name1}
            </div>
          )}

          {/* User 2 Position (only shown when not close) */}
          {user2Metrics && !isClose(user1Metrics?.[key], user2Metrics[key]) && (
            <div
              className="insight-person-container"
              style={{
                left: positions[key].user2,
                transition: "left 1s ease-in-out",
              }}
            >
              {profilePic2 ? (
                <img
                  src={profilePic2}
                  alt={initials2 || "Profile"}
                  style={{ width: "24px", height: "24px", fontSize: "10px" }}
                  onError={(e) => {
                    e.target.style.display = "none";
                    e.target.nextElementSibling.style.display = "flex";
                  }}
                />
              ) : (
                <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>
                  {initials2 || ""}
                </div>
              )}
              {name2}
            </div>
          )}
        </div>
      ))}

      {/* Personality Content */}
      <div className="personality-content">
        <div style={{ textAlign: "left" }}>
          <ReactMarkdown
            components={{
              h1: "h3",
              h2: "h4",
            }}
          >
            {data?.replace(/\n/g, "\n\n") || ""}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
}
import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import rightIcon from "../../Assets/icon/chevron-right.svg";
import Allset from "../../Components/AllsetComponent";
import ConfirmationModal from "./ManageMembershipCancel";
import { ReactComponent as Point } from "../../Assets/icon/Ellipse 88.svg";
import { ReactComponent as Info } from "../../Assets/icon/Info.svg";
import "./style.css";
import CoachingModal from "../Pricing/CoachingModal";
import FaithContainer from "../../Pages/Pricing/Faith";
import SearchableDropdown from "../../Components/SearchableDropdown";
import { createAddon } from "../../Services/subscription";
import { useSearchParams } from "react-router-dom";

const FaithandBelief = ({
  isFaithandBelief,
  setisFaithandBelief,
  showdetails,
  metadata,
  formatDate,
  addon,
  faithProductSelectedID,
  productId,
  priceId,
  user_type,
  subscriptionData
}) => {
  const [searchParams] = useSearchParams();
  const ispurchased = searchParams.get("ispurchased");
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isAllSet, setIsAllSet] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [selectedFaithID, setselectedFaithID] = useState("");
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [isFaithToSubUser, setisFaithToSubUser] = useState(false);
  const [selectedFaith, setselectedFaith] = useState("");
  const [allAccountsChecked, setAllAccountsChecked] = useState(false);

  const optionArray = metadata?.addon_point_list
    ? JSON.parse(metadata.addon_point_list)
    : [];

  const setOptionSelected = (e) => {
    console.log("test1111", e);
    setselectedFaith(e);
  };

  const selectedFaithPriceId = (id) => {
    let filter = id?.find(
      (prod) => prod?.recurring?.interval === selectedPrice?.recurring?.interval
    );
    setselectedFaithID(filter);
  };

  const faithToAllAddedUser = (value) => {
    setisFaithToSubUser(value);
  };

  const onOptionSelected = (e) => {
    console.log("test", e);
    setselectedFaith(e);
    setOptionSelected(e);
  };

  const handleOptionClick = (option) => {
    setAllAccountsChecked((prev) => !prev);
  };

  const openCancelMembershipModal = () => {
    setisFaithandBelief(false);
    setIsCancelModal(true);
  };

  const closeCancelMembershipModal = () => {
    setIsCancelModal(false);
    setisFaithandBelief(true);
  };

  const handleCancelMembership = () => {
    setIsAllSet(true);
    setIsCancelModal(false);
  };

  const handleAddon = () => {
    let temp = {
      subscription_items: [{ price_id: priceId, product_id: productId }],
      licensed_users:[],
      addon_features_user: [
        { price_id: priceId, product_id: productId, value: selectedFaith },
      ],
      self: user_type === "primary" ? "true" : "false",
      payment_method: "card",
    };
    createAddon(temp).then((res) => {
      console.log(res);
      window.location.href = res?.payload?.checkout_url;
    });
  };

  console.log(showdetails, "showdetails");
  console.log(productId, priceId, "idsssssssss");
  return (
    <>
      {isFaithandBelief && (
        <div className="membershipmodal-overlay">
          <div className="membershipmodal-container">
            <div className="membershipmodal-header" style={{ margin: "0px" }}>
              <h2 className="membershipmodal-heading">
                {metadata?.addon_title}
              </h2>
              <button
                className="membershipclose-btn"
                onClick={() => setisFaithandBelief(false)}
              >
                <CloseIcon />
              </button>
            </div>

            <div className="pricing-toggle-content manage-faith">
              <div className="pricing-radio-group">
                <div className="pricing-toggle-price-container">
                  <span className="pricing-premium">Premium Option</span>
                  <span className="pricing-toggle-price">
                    ${faithProductSelectedID}
                    <span className="pricing-toggle-price-txt">
                      {" "}
                      /month per account
                    </span>
                  </span>
                </div>

                <div className="pricing-dropdown-option-container">
                  <SearchableDropdown
                    onBoarding={true}
                    setSelectedFaith={onOptionSelected}
                    isFullWidth={true}
                  />
                </div>

                <div
                  className="pricing-radio-option"
                  onClick={() => handleOptionClick("all")}
                >
                  <span>Add to main account</span>
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      checked={allAccountsChecked}
                      onChange={() => handleOptionClick("all")}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <p className="pricing-toggle-description manage-left-txt">
                {metadata?.addon_description}
              </p>
              <ul className="pricing-toggle-list">
                {optionArray.length > 0 && (
                  <div className="pricing-point-container">
                    {optionArray.map((point, index) => (
                      <div className="pricing-point-list" key={index}>
                        <div className="pricing-point-icon">
                          <Point />
                        </div>
                        <p className="pricing-point-text">{point}</p>
                      </div>
                    ))}
                  </div>
                )}
              </ul>
            </div>
            <button
              className="membershippurchase-btn"
              style={{ marginTop: "24px" }}
              onClick={handleAddon}
            >
              Purchase now
            </button>
          </div>
        </div>
      )}

      <ConfirmationModal
        isOpen={isCancelModal}
        onClose={closeCancelMembershipModal}
        onConfirm={handleCancelMembership}
        heading="Are you sure you want to cancel membership"
        subheading="You won't be able to get advise from Aris anymore"
        confirmButtonText="Yes, Cancel"
        cancelButtonText="Back"
      />

      {/* {isAllSet && (
        <Allset
          closeModal={() => {
            setIsAllSet(false);
            setisFaithandBelief(false);
          }}
          heading={ispurchased==="confirm" ?`You're all set`:"Oops!"}
          subText={
            ispurchased === "confirm"
              ?"Faith purchased successfully"
              : "Something went wrong!"
          }
          isFailure={ispurchased === "confirm" ? false : true}
          
        />
      )} */}
    </>
  );
};

export default FaithandBelief;



import React, { memo, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import deepEqual from "deep-equal"; // You can use a deep comparison library like deep-equal

const GradientGraphVertical = ({ data }) => {
  const svgRef = useRef();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const bgC = "#fff";

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous SVG content

    const width = screenWidth < 580 ? 280 : screenWidth > 1840 ? 700 : 400; // Canvas width
    const height = screenWidth < 580 ? 400 : screenWidth > 1840 ? 800 : 500; // Canvas height

    // Dynamic calculations
    const rectWidth = width * 0.183; // Bar width is 10% of canvas width
    const rectHeight = height * 0.07; // Bar height is 5% of canvas height
    const gap = width * 0.02; // Horizontal gap is 2% of canvas width
    const segmentGap = height * 0.005; // Vertical gap between segments is 0.5% of canvas height
    const rectVerticalGap = height * 0.01; // Gap between bars within a segment is 1% of canvas height
    const cornerRadius = Math.min(width, height) * 0.01; // Corner radius is 1% of the smaller dimension
    const topMargin = height * 0.01; // Top margin is 5% of canvas height
    const bottomMargin = height * 0.1; // Bottom margin is 10% of canvas height
    const leftMargin = width * 0.03 + width * 0.02; // Left margin is 3% of canvas width

    svg.attr("width", width).attr("height", height);

    // Create Gradients
    const defs = svg.append("defs");

    data.forEach((item, colIndex) => {
      const { topLabel, bottomLabel, segments } = item;

      segments.forEach((segment, segIndex) => {
        const gradientId = `gradient-segment-${colIndex}-${segIndex}`;
        const gradient = defs
          .append("linearGradient")
          .attr("id", gradientId)
          .attr("x1", "0%")
          .attr("x2", "0%")
          .attr("y1", "0%")
          .attr("y2", "100%");

        // Initialize gradient stops with no color (transparent)
        segment.colors.forEach((color, i) => {
          gradient
            .append("stop")
            .attr("offset", `${i * 100}%`)
            .attr("stop-color", color)
            .attr("stop-opacity", 0); // Start transparent
        });

        const x = colIndex * (rectWidth + gap + leftMargin);

        // Add Top Label
        if (segIndex === 0) {
          svg
            .append("text")
            .attr("x", x + rectWidth / 2)
            .attr("y", topMargin + 15)
            .attr("text-anchor", "middle")
            .attr("font-size", ".9rem")
            .attr("font-family", "Urbanist")
            .attr("fill", bgC)
            .text(topLabel);
        }

        // Add Bottom Label
        if (segIndex === 1) {
          svg
            .append("text")
            .attr("x", x + rectWidth / 2)
            .attr("y", height - bottomMargin + 20)
            .attr("text-anchor", "middle")
            .attr("font-size", ".9rem")
            .attr("font-family", "Urbanist")
            .attr("fill", bgC)
            .text(bottomLabel);
        }

        // Helper to calculate filled rectangles and partial height
        const calcRectFill = (percentage) => {
          const fullRects = Math.floor((percentage / 100) * 5); // Full rectangles
          const partialHeight =
            ((percentage / 100) * 5 - fullRects) * rectHeight; // Partial rectangle height
          return { fullRects, partialHeight };
        };

        // Calculate Fill for Current Segment
        const fill = calcRectFill(segment.percent);

        // Adjust y-position for segments
        const yStartTop =
          topMargin +
          segIndex * (5 * rectHeight + 4 * rectVerticalGap + segmentGap);
        const yStartBottom =
          height -
          bottomMargin -
          (5 * rectHeight + 4 * rectVerticalGap) +
          segIndex * segmentGap;

        const yStart = segIndex === 1 ? yStartTop : yStartBottom;

        // Draw Rectangles for Each Segment
        for (let i = 0; i < 5; i++) {
          const y =
            segIndex === 0
              ? yStart + i * (rectHeight + rectVerticalGap) // Top segment: Bottom to Top
              : yStart - i * (rectHeight + rectVerticalGap); // Bottom segment: Top to Bottom

          svg
            .append("rect")
            .attr("x", x)
            .attr("y", y)
            .attr("width", rectWidth)
            .attr("height", rectHeight)
            .attr("rx", cornerRadius)
            .attr("ry", cornerRadius)
            .attr(
              "fill",
              i < fill.fullRects ? `url(#${gradientId})` : "#E6F2E6"
            );

          // Add Partial Fill
          if (i === fill.fullRects && fill.partialHeight > 0) {
            svg
              .append("rect")
              .attr("x", x)
              .attr(
                "y",
                segIndex === 0
                  ? y // Top segment: Partial fill starts from top of the rect
                  : y + (rectHeight - fill.partialHeight) // Bottom segment: Partial fill starts from bottom of the rect
              )
              .attr("width", rectWidth)
              .attr("height", fill.partialHeight)
              .attr("rx", cornerRadius)
              .attr("ry", cornerRadius)
              .attr("fill", `url(#${gradientId})`);
          }

          // Animate Gradient Fill with Delayed Transition
          gradient
            .selectAll("stop")
            .transition()
            .delay(i * 500) // Delay increases with rectangle index
            .duration(3000)
            .ease(d3.easeCubicOut)
            .attr("stop-opacity", 1); // Gradually fill gradient
        }

        // Add Percentage Text
        svg
          .append("text")
          .attr("x", x + rectWidth / 2)
          .attr(
            "y",
            segIndex === 1
              ? yStart + height * 0.045 // Top segment: Text position above the first rectangle
              : yStart + height * 0.045 // Bottom segment: Text position below
          )
          .attr("text-anchor", "middle")
          .attr("font-size", "1.125rem")
          .attr("font-weight", "500")
          .attr("fill", "#000")
          .text(`${segment.percent || 0}%`);
      });
    });
  }, [data]);

  console.log(data, "progressData");

  return <svg ref={svgRef} />;
};

const areEqual = (prevProps, nextProps) => {
  return deepEqual(prevProps.data, nextProps.data); // Only re-render if the `data` prop actually changes
};

export default memo(GradientGraphVertical, areEqual);

import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import nextbtn from "../../Assets/images/arrow-right.png";
import defaultProfile from "../../Assets/images/placeholder.png";
import {
  
  deleteRelationship,
  uploadFiles,
  fetchCategoriesAndTypes,
} from "../../Services/Realtionship";
import { acceptRequest, denyRequest } from "../../Services/Realtionship";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";

const AcceptPendingRequest = ({
  request,
  onAccept,
  onReject,
  onBack,
  onUpdateContact,
  onDeleteContact,
  navigateBack,
  onClose,
}) => {
  const fileInputRef = useRef(null);
  const initialFormData = {
    first_name: request?.firstName,
    last_name: request?.lastName,
    relationship_type: request?.relationshipType,
    relationship_length: request?.relationshipLength,
    relationship_category: request?.relationshipCategory,
    relationship_status: request?.relationshipStatus,
    email: request?.email || null,
    nickname: request?.nickname || null,
    contact: request?.contact || null,
    profile_pic_url: request?.profilePic || null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(
    initialFormData.profile_pic_url
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [relationTypes, setRelationTypes] = useState([]);
  const [relationLengths, setRelationLengths] = useState([]);
  const [relationStatuses, setRelationStatuses] = useState([]);
  const [isEditable, setIsEditable] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const categoriesData = await fetchCategoriesAndTypes();
        setCategories(Array.isArray(categoriesData) ? categoriesData : []);

        const lengthsData = await fetchCategoriesAndTypes("length");
        setRelationLengths(Array.isArray(lengthsData) ? lengthsData : []);

        const statusesData = await fetchCategoriesAndTypes("status");
        setRelationStatuses(Array.isArray(statusesData) ? statusesData : []);

        if (initialFormData.relationship_category) {
          const types = await fetchCategoriesAndTypes(
            initialFormData.relationship_category
          );
          setRelationTypes(Array.isArray(types) ? types : []);
        }

        if (request?.relation_status !== "pending") {
          setIsEditable(false);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
    fetchInitialData();
  }, []);

  const isFormValid = () => {
    return (
      formData.relationship_category !== "" &&
      formData.relationship_type !== "" &&
      formData.relationship_length !== "" &&
      formData.relationship_status !== "" &&
      formData.relationship_category !== "Select Category" &&
      formData.relationship_type !== "Select Type" &&
      formData.relationship_length !== "Select Length" &&
      formData.relationship_status !== "Select Status"
    );
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "relationship_category") {
      try {
        const response = await fetchCategoriesAndTypes(value);
        if (Array.isArray(response)) {
          setRelationTypes(response);
          setFormData((prev) => ({
            ...prev,
            relationship_type: "",
          }));
        }
      } catch (error) {
        console.error("Error fetching relationship types:", error);
      }
    }
  };

  const handlePhoneChange = (value) => {
    const phoneNumber = parsePhoneNumberFromString(value);
    if (phoneNumber) {
      setFormData((prev) => ({
        ...prev,
        country_code: "+" + phoneNumber.countryCallingCode,
        contact: phoneNumber.nationalNumber,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        contact: null, // Set to null if there's no valid phone number
      }));
    }
  };

  const handleImageClick = () => {
    if (isEditable) {
      fileInputRef.current?.click();
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      alert("Please upload a valid image file (JPEG, PNG)");
      return;
    }

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      alert("File size should be less than 5MB");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => setPreviewImage(reader.result);
    reader.readAsDataURL(file);

    const uploadResponse = await uploadFiles(request._id, file, null);
    if (uploadResponse?.image_url) {
      setFormData((prev) => ({
        ...prev,
        profile_pic_url: uploadResponse.image_url,
      }));
    }
    setSelectedFile(file);
  };

  const handleRejectOrDelete = async () => {
    try {
      if (request?.relation_status === "pending") {
        await denyRequest(request._id);
        onReject?.(request._id);
      } else {
        await deleteRelationship(request._id);
        onDeleteContact?.(request._id);
      }

      if (navigateBack) {
        navigate("/myprofile");
      }
      setIsModalOpen(false);
      onBack();
    } catch (error) {
      console.error("Error rejecting/deleting relationship:", error);
    }
  };

  const renderProfileContent = () => {
    if (previewImage) {
      return (
        <div className="edit-profile-image">
          <img
            src={previewImage}
            alt={request.first_name + request.last_name}
            className="profile-picture"
            onError={(e) => {
              setPreviewImage(null);
              e.target.onerror = null;
            }}
          />
        </div>
      );
    } else if (request.initials) {
      return <div className="edit-profile-initials">{request.initials}</div>;
    }
    return (
      <img
        src={defaultProfile}
        alt="default profile"
        className="default-profile"
      />
    );
  };

  const handleAcceptRelation = async () => {
    try {
      const fullContact =
        formData.contact && formData.country_code
          ? `${formData.country_code}${formData.contact}`
          : "";

      const relationshipData = {
        // id: request.id,
        first_name: formData.first_name,
        last_name: formData.last_name,
        relationship_type: formData.relationship_type,
        relationship_length: formData.relationship_length,
        relationship_category: formData.relationship_category,
        relationship_status: formData.relationship_status,
        relationshup_goals: formData.relationshup_goal,
        email: formData.email || null,
        nickname: formData.nickname || null,
        contact: fullContact || null,
        profile_pic_url: formData.profile_pic_url,
      };

      console.log("Sending data:", relationshipData, request);
      await acceptRequest(request.id, relationshipData).then((response) => {
        onClose();
      });
      onAccept?.(request.id);

      onBack();
    } catch (error) {
      console.error("Error accepting the relationship request:", error);
    }
  };

  const ClosetheModal = () => {
    onClose(); // Pass this as prop from parent
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
         
      <div className="edit-container" style={{ width: "100%" }}>
      <div className="sendreq-close-container">
      <CloseIcon
          style={{
         
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        </div>
        <div className="edit-profile-section">
          <div
            className="edit-profile-image-container"
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          >
            {renderProfileContent()}

            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>

        <form className="edit-form">
          <div className="form-grid">
            <div className="edit-form-field">
              <label>First Name</label>
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
                disabled={!isEditable}
                style={{ backgroundColor: !isEditable ? "#f0f0f0" : "" }}
              />
            </div>
            <div className="edit-form-field">
              <label>Last Name</label>
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                disabled={!isEditable}
                style={{ backgroundColor: !isEditable ? "#f0f0f0" : "" }}
              />
            </div>

            <div className="form-group">
              <label>Relationship Category</label>
              <select
                name="relationship_category"
                value={formData.relationship_category}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Relationship Type</label>
              <select
                name="relationship_type"
                value={formData.relationship_type}
                onChange={handleInputChange}
                disabled={!formData.relationship_category}
              >
                <option value="">Select Type</option>
                {relationTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Relationship Length</label>
              <select
                name="relationship_length"
                value={formData.relationship_length}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Length</option>
                {relationLengths.map((length) => (
                  <option key={length} value={length}>
                    {length}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Relationship Status</label>
              <select
                name="relationship_status"
                value={formData.relationship_status}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Status</option>
                {relationStatuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                disabled={!isEditable}
                style={{ backgroundColor: !isEditable ? "#f0f0f0" : "" }}
              />
            </div>

            <div className="form-group-mobile">
              <div style={{ marginBottom: "6px" }}>
                <label htmlFor="" className="mobile-label-addrelation">
                  Mobile Number{" "}
                </label>
                <span className="optional">(optional)</span>
              </div>
              <PhoneInput
                id="phone-number"
                international
                defaultCountry="US"
                value={
                  formData.contact
                    ? `+${formData.country_code}${formData.contact}`
                    : ""
                }
                onChange={handlePhoneChange}
                className="addrelationship-phoneinput phone-bg"
                placeholder="Enter phone number"
                autoFocus={false}
                disabled={!isEditable}
                style={{
                  backgroundColor: !isEditable ? "#f0f0f0" : "",
                  cursor: !isEditable ? "not-allowed" : "",
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "left",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <button
              type="button"
              onClick={handleAcceptRelation}
              className={`edit-person-btn ${isFormValid() ? "filled" : "filled"}`}
              // disabled={!isFormValid()}
            >
              Accept Request
              <img src={nextbtn} alt="next" className="nxtbtn" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AcceptPendingRequest;

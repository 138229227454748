import React from 'react';
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  heading,
  subheading,
  confirmButtonText = "Yes, Confirm",
  cancelButtonText = "Back"
}) => {
  if (!isOpen) return null;

  return (
    <div className="membershipmodal-overlay">
      <div className="membershipmodal-container">
        <div className="membershipmodal-header" style={{ margin: "0px", textAlign: "left" }}>
          <h2 className="membershipmodal-heading">{heading}</h2>
          <button className="membershipclose-btn" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="delete-modal-body">
          <p className="membership-delete-modal-text">{subheading}</p>
          <div className="membership-delete-modal-buttons">
            <button
              className="membership-delete-modal-cancel-button"
              onClick={onClose}
            >
              {cancelButtonText}
            </button>
            <button
              className="membership-delete-modal-cancel-button membership-delete-modal-confirm-button"
              onClick={onConfirm}
            >
              {confirmButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
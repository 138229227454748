import React, { useEffect, useState } from "react";
import arrowLeft from "../../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../Providers/HeaderContextProvider";
import arrowRight from "../../../Assets/images/arrow-right.svg";
import { sendUpdatedContact } from "../../../Services/settingsServices";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import "./style.css";
import { ContentCutOutlined } from "@mui/icons-material";
function MobilePhoneSettingsScreen2() {
  const navigate = useNavigate();
  const { setHeaderContent } = useHeader();
  const [isLoading, setIsLoading] = useState(false);
  const [newMobileNumber, setNewMobileNumber] = useState("");
  useEffect(() => {
    setHeaderContent(false);
  }, []);

    const parsePhoneNumber = (newMobileNumber) => {
      const parsed = parsePhoneNumberFromString(newMobileNumber);
      if (!parsed) {
        throw new Error("Invalid phone number format");
      }
      return parsed;
    };


  const onUpdateContact = async () => {
    setIsLoading(true);
    try {

      const parsedPhoneNumber = parsePhoneNumber(newMobileNumber); 
      const country_code = `+${parsedPhoneNumber.countryCallingCode}`;
      const contact = parsedPhoneNumber.nationalNumber;
      const response = await sendUpdatedContact({
        country_code: country_code,
        contact: contact,
      });
      if (response.status === "success") {
        sessionStorage.setItem("newcountryCode", country_code);
        sessionStorage.setItem("newContact", contact);
    navigate("/settings/phone/3");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {" "}
      <header className="termsofuse-header">
        <div className="termsofuse-gradient-background">
          <div className="termsofuse-nav-container">
            <div
              className="termsofuse-logo-container" 
              onClick={() => navigate(-1)}
            >
              <img
                src={arrowLeft}
                alt="Back"
                className="termsofuse-back-button"
              />
            </div>
            <h1 className="termsofuse-title">What’s your mobile number?</h1>
            <div className="termsofuse-space"></div>
            <div className="set-phone-modal-support mobile-sub-txt-set-phone">
              Giving us your number will allow you to access your Aris Portrait
              even if you lose your account.
            </div>
          </div>
        </div>
      </header>
      <div className="set-phone-int-cnt mobile-set-cnt ">
        <div className="set-phone-modal-input-container mt-mobile-view">
          <div className="set-phone-input-label">Mobile number</div>
          <div className="mobile-input-wrapper-1">
          <PhoneInput
              international
              defaultCountry="US"
              value={newMobileNumber}
              onChange={setNewMobileNumber}
              className="mobile-input"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button
            className="set-phone-continue-button"
            onClick={onUpdateContact}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? "0.6" : "1",
            }}
          >
            {" "}
            Continue <img src={arrowRight} alt="edit" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobilePhoneSettingsScreen2;

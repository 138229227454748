import React, { useEffect, useState } from "react";
import "./styles.css";
import AssessmentContainer from "../../Components/AssesmentContainer";
import {
  nextQuestions,
  prevQuestions,
  startNextTest,
  startNextTestbyCategoryId,
  startQuestions,
} from "../../Services/Assessment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRandomValue } from "../../Utils/CommonFunctions/assessment";
import { useHeader } from "../../Providers/HeaderContextProvider";

function Assessments() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setHeaderContent } = useHeader();
  const categoryId = searchParams.get("categoryId");
  const [isQuestLoading, setisQuestLoading] = useState(false);
  const [isErrorOnProp, setisErrorOnProp] = useState(false);

  useEffect(() => {
    setHeaderContent(false);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (isErrorOnProp) setisErrorOnProp(false);
    }, 2000);
  }, [isErrorOnProp]);
  useEffect(() => {
    localStorage.setItem("isEncouragementMessageShown", "true");
    // startQuestions()
    startNextTestbyCategoryId(categoryId, false)
      .then((res) => {
        // console.log("response from assessment", res);
        setcurrentQuestionInfo(res);
      })
      .catch((error) => {
        console.log("error from assessment", error);
      });
  }, []);

  const [currentQuestionInfo, setcurrentQuestionInfo] = useState({});
  const [
    numberOfSequentialQuestionanswered,
    setnumberOfSequentialQuestionanswered,
  ] = useState(0);

  //function to submit current question's answer and call next question
  const callNextQuestion = (answer, isExist = false) => {
    if (
      currentQuestionInfo?.categoryId === "" ||
      currentQuestionInfo?.categoryId === undefined ||
      currentQuestionInfo?.categoryId === null ||
      currentQuestionInfo?.currQuestion?.id === "" ||
      currentQuestionInfo?.currQuestion?.id === undefined ||
      currentQuestionInfo?.currQuestion?.id === null
    ) {
      setisErrorOnProp(true);
      return;
    }
    setisQuestLoading(true);
    if (answer.length > 0 || isExist === true) {
      const nextQuestionData = {
        item_id: currentQuestionInfo?.categoryId,
        item_type: "category",
        question_id: currentQuestionInfo?.currQuestion?.id,
        answer_id:
          Array.isArray(answer) && answer.includes(undefined) ? [] : answer,
        skip_question: false,
        exit: isExist ? true : false,
        is_survey: false,
        ques_arr_limit: 10,
      };
      if (
        currentQuestionInfo?.currQuestion?.number ===
        currentQuestionInfo?.totalQuestions
      ) {
        nextQuestions({ ...nextQuestionData, isExist: true })
          .then(async (res) => {
            setcurrentQuestionInfo();
            if (res?.isLastResponse || res?.nextCategoryId) {
              navigate(`/onboarding/chat`);
              setisQuestLoading(false);
            }
          })
          .catch((error) => {
            console.log(error, "error from next question");
            setisQuestLoading(false);

            // navigate(`/assessmentProgress/${"save"}`);
            navigate(`/onboarding/chat`);
          });
      } else {
        nextQuestions(nextQuestionData)
          .then((res) => {
            // console.log("response from next question", res);
            if (isExist) {
              onSaveExitClick("assessment");
            }
            setcurrentQuestionInfo(res);
            setisQuestLoading(false);

            setnumberOfSequentialQuestionanswered((prev) => prev + 1);
          })
          .catch((error) => {
            console.log(error, "error from next question");
            // navigate(`/assessmentProgress/${"save"}`);
            navigate(`/onboarding/chat`);
            setisQuestLoading(false);
          });
      }
    }

    // console.log(answer, isExist, "nextTest");
  };

  // console.log(currentQuestionInfo?.categoryName, "currentQuestionInfo");

  //function to call previous question
  const callPrevQuestions = (isExist = false) => {
    if (
      currentQuestionInfo?.categoryId === "" ||
      currentQuestionInfo?.categoryId === undefined ||
      currentQuestionInfo?.categoryId === null ||
      currentQuestionInfo?.currQuestion?.id === "" ||
      currentQuestionInfo?.currQuestion?.id === undefined ||
      currentQuestionInfo?.currQuestion?.id === null
    ) {
      setisErrorOnProp(true);
      return;
    }
    setisQuestLoading(true);

    const prevQuestionData = {
      item_id: currentQuestionInfo?.categoryId,
      item_type: "category",
      curr_question_num: currentQuestionInfo?.currQuestion?.number,
      is_survey: false,
    };
    prevQuestions(prevQuestionData)
      .then((res) => {
        // console.log("response from prev question", res);
        setcurrentQuestionInfo(res);
        setisQuestLoading(false);
      })
      .catch((error) => {
        console.log(error, "error from previous question");
        setisQuestLoading(false);
      })
      .finally(setisQuestLoading(false));
  };

  //function to call when user clicks on save and exit button
  const onSaveExitClick = (txt) => {
    navigate(`/onboarding/chat`);

    // navigate(`/assessmentProgress/${txt}`);
  };

  // //condition to render encouragement message after 10 questions answered sequentially
  // const isEncouragementMessageShown =
  //   localStorage.getItem("isEncouragementMessageShown") === "true";
  // if (
  //   (numberOfSequentialQuestionanswered === 10 ||
  //     currentQuestionInfo?.currQuestion?.number ===
  //       currentQuestionInfo?.totalQuestions - 2) &&
  //   isEncouragementMessageShown
  // ) {
  //   if (
  //     currentQuestionInfo?.currQuestion?.number ===
  //     currentQuestionInfo?.totalQuestions - 2
  //   ) {
  //     // navigate(`/assessmentEncouragement/90`);
  //   } else {
  //     const encouragementScreenNo = getRandomValue([20, 40, 60]);
  //     navigate(`/assessmentEncouragement/${encouragementScreenNo}`);
  //     localStorage.setItem("isEncouragementMessageShown", "false");
  //   }
  // }

  // console.log(
  //   "✅ currentQuestionInfo    ",
  //   nextCategoryId,
  //   currentQuestionInfo
  // );

  return (
    <div className="assessment-hero-container">
      <div className="supressed-bg">
        <AssessmentContainer
          id={currentQuestionInfo?.currQuestion?.id}
          question={currentQuestionInfo?.instructions}
          description={currentQuestionInfo?.currQuestion?.description}
          options={currentQuestionInfo?.currQuestion?.optionsList}
          type={currentQuestionInfo?.currQuestion?.type}
          onSubmit={callNextQuestion}
          onPrev={callPrevQuestions}
          isFirstQuestion={currentQuestionInfo?.currQuestion?.number === 1}
          progressValue={currentQuestionInfo?.completionStatus}
          isSurvey={false}
          answerList={currentQuestionInfo?.answer}
          title={currentQuestionInfo?.categoryName}
          currentCategoryCount={
            currentQuestionInfo?.currQuestion?.categoryNumber
          }
          totalCategoryCount={
            currentQuestionInfo?.currQuestion?.categoryTotalCount
          }
          isQuestLoading={isQuestLoading}
          isErrorOnProp={isErrorOnProp}
        />
      </div>
    </div>
  );
}

export default Assessments;

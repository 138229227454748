import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../../Assets/icon/close.svg";
import { ReactComponent as RigthArrow } from "../../../Assets/icon/chevron-right.svg";
import people from "../../../Assets/images/people.png";

import ContentLoader from "../../LoadingPage/ContentLoader";
import {
  fetchInsightContent,
  fetchInsightMetrics,
  getRelationshipAdvice,
  getRelationshipInsightModel,
  getRelationshipProfile,
  getRelationships,
} from "../../../Services/Realtionship";
import { Personality } from "./InsightComponents/Personality";
import { Processing } from "./InsightComponents/Processing";
import { DecisionMaking } from "./InsightComponents/DecisionMaking";
import { Motivation } from "./InsightComponents/Motivation";
import { ConflictManagement } from "./InsightComponents/ConflictManagement";
import { FundamentalNeeds } from "./InsightComponents/FundamentalNeeds";
import { positions, width } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../Providers/HeaderContextProvider";

function RelationshipAdiveInsight({ onClose, contact, selectedTab }) {
  const { profileContent } = useHeader();

  const [activeTabId, setActiveTabId] = useState(1);
  const [listOfAdviceInsights, setlistOfAdviceInsights] = useState([]);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [togglevalue, setTogglevalue] = useState("primary");
  const [showMain, setShowMain] = useState(true);
  const [sectionData, setSectionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Added loading state
  const [profilePic1, setProfilePic1] = useState(null);
  const [initials1, setInitials1] = useState("");
  const [name1, setName1] = useState("");

  const [profilePic2, setProfilePic2] = useState(null); // For getRelationshipProfile()
  const [initials2, setInitials2] = useState("");
  const [name2, setName2] = useState("");
  const [user1Pressure, setUser1Pressure] = useState(false);
  const [user2Pressure, setUser2Pressure] = useState(false);
  const [user1Metrics, setUser1Metrics] = useState(null);
  const [user2Metrics, setUser2Metrics] = useState(null);

  // Function to handle pressure updates

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch User Profile (Profile 1)

    const firstName = profileContent.first_name || "";
    const lastName = profileContent.last_name || "";

    setName1(`${firstName} ${lastName}`);
    if (profileContent) {
      if (profileContent.profile_pic_url) {
        setProfilePic1(profileContent.profile_pic_url);
      } else {
        setInitials1(
          `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
        );
        setName1(`${firstName} ${lastName}`);
      }
    }
  }, []); // Runs only once when the page loads

  useEffect(() => {
    // Fetch User Profile (Profile 1)
    getRelationshipProfile(contact._id).then((res) => {
      const firstName = res.first_name || "";
      const lastName = res.last_name || "";
      setName2(`${firstName} ${lastName}`);
      if (res) {
        if (res.profile_pic_url) {
          setProfilePic2(res.profile_pic_url);
        } else {
          setInitials2(
            `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
          );
          setName2(`${firstName} ${lastName}`);
        }
      }
    });
  }, []); // Runs only once when the page loads

  useEffect(() => {
    if (activeTabId === 2) {
      getRelationshipInsightModel().then((res) => {
        if (res) setlistOfAdviceInsights(res?.insights);
      });
    } else {
      getRelationshipAdvice(
        contact?.related_data?.relationship_category,
        contact?.related_data?.relationship_type
      ).then((res) => {
        if (res?.advice) setlistOfAdviceInsights(res?.advice);
      });
    }
  }, [activeTabId, contact]); // Runs when activeTabId or contact changes

  const handleSectionClick = async (
    index,
    isUser1 = null,
    newStatus = null
  ) => {
    try {
      setIsLoading(true);

      // If the function is triggered from pressure toggle
      if (isUser1 !== null && newStatus !== null) {
        if (isUser1) {
          setUser1Pressure(newStatus);
        } else {
          setUser2Pressure(newStatus);
        }
        return; // Exit early to avoid fetching unnecessary data
      }

      setSelectedSectionIndex(index);
      const section = listOfAdviceInsights[index];

      // Fetch section data
      const sectionContent = await fetchInsightContent(
        activeTabId === 1 ? "advice" : "insight",
        contact._id,
        section,
        user1Pressure,
        user2Pressure
      );

      console.log("sectionContent", sectionContent);
      setSectionData(sectionContent[section]);
      setShowMain(false);
    } catch (error) {
      console.error("Error fetching section content:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleFetchMetrics = async () => {
      try {
        const response = await fetchInsightMetrics(contact._id);
        setUser1Metrics(response.user1);
        setUser2Metrics(response.user2);
      } catch (error) {
        console.error(error);
      }
    };

    handleFetchMetrics();
  }, [contact]);

  const handleBack = () => {
    setShowMain(true);
    setSectionData(null);
  };

  const tooltips = {
    personality:
      "Your personality describes how you present yourself and how others might perceive you.",
    processing:
      "Processing reveals how you consume and assess information in order to approach people, activities and causes in your life.",
    decision:
      "The Decision Making module measures your unique style and rationale for making decisions.",
    conflict:
      "These five conflict styles represent the multiple ways you can engage in conflict. Even though you can take any of these approaches depending on the situation, there are generally two that you rely on most.",
    fundamental:
      "There are three fundamental needs that everyone experiences in life: security, control, and significance.",
  };

  const renderComponent = () => {
    const commonProps = {
      data: sectionData,
      onBack: handleBack,
      profilePic1,
      profilePic2,
      initials1,
      initials2,
      name1,
      name2,
      relation_id: contact._id,
    };

    switch (selectedSectionIndex) {
      case 0:
        return (
          <Personality
            {...commonProps}
            tooltip={tooltips.personality}
            user1Metrics={user1Metrics && user1Metrics.personality}
            user2Metrics={user2Metrics && user2Metrics.personality}
          />
        );
      case 1:
        return (
          <Processing
            {...commonProps}
            tooltip={tooltips.processing}
            user1Metrics={user1Metrics && user1Metrics.processing}
            user2Metrics={user2Metrics && user2Metrics.processing}
          />
        );
      case 2:
        return (
          <DecisionMaking
            {...commonProps}
            tooltip={tooltips.decision}
            user1Metrics={user1Metrics && user1Metrics.decisionMaking}
            user2Metrics={user2Metrics && user2Metrics.decisionMaking}
          />
        );
      case 3:
        return (
          <ConflictManagement
            {...commonProps}
            tooltip={tooltips.conflict}
            user1Metrics={user1Metrics && user1Metrics.conflictManagement}
            user2Metrics={user2Metrics && user2Metrics.conflictManagement}
          />
        );
      case 4:
        return (
          <FundamentalNeeds
            {...commonProps}
            tooltip={tooltips.fundamental}
            user1Metrics={user1Metrics && user1Metrics.fundamentalNeeds}
            user2Metrics={user2Metrics && user2Metrics.fundamentalNeeds}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="insight-hero-container">
      <div className="insight-header">
        <CloseIcon onClick={onClose} cursor={"pointer"} />
      </div>
      <div
        className={`insight-tab-switch-content ${
          user2Metrics === null && "metrics-null"
        }`}
      >
        <div
          className={`insight-switch-txt${
            activeTabId === 1 ? " insight-tab-active" : ""
          }`}
          id="1"
          onClick={() => setActiveTabId(1)}
        >
          Advice
        </div>
        {user2Metrics && (
          <div
            className={`insight-switch-txt${
              activeTabId === 2 ? " insight-tab-active" : ""
            }`}
            id="2"
            onClick={() => setActiveTabId(2)}
          >
            Insights
          </div>
        )}
      </div>
      <div className="insight-content-container">
        {isLoading ? (
          <div className="content-loader-wrapper">
            <ContentLoader />
          </div>
        ) : showMain ? (
          <div>
            <div className="insight-people-container">
              <div className="insight-people">
                {profilePic1 ? (
                  <img
                    src={profilePic1}
                    alt={initials1 || "Profile"}
                    style={{ width: "40px", height: "40px" }}
                    onError={(e) => {
                      e.target.style.display = "none";
                      e.target.nextElementSibling.style.display = "flex";
                    }}
                  />
                ) : null}

                {(!profilePic1 || profilePic1 === "") && (
                  <div
                    className="profile-initials"
                    style={{ width: "40px", height: "40px" }}
                  >
                    {initials1 || ""}
                  </div>
                )}

                {name1}
                {activeTabId === 1 && ( // Only show when activeTabId is 1 (Advice tab)
                  <>
                    <p className="set-pressure">Set as under pressure</p>
                    <label className="myprofile-switch">
                      <input
                        type="checkbox"
                        className="myprofile-checkbox"
                        checked={user1Pressure}
                        onChange={(e) => {
                          const newStatus = e.target.checked;
                          handleSectionClick(null, true, newStatus); // Pass user1 status update
                        }}
                      />
                      <span className="myprofile-slider"></span>
                    </label>
                  </>
                )}
              </div>
              <div className="insight-people">
                {profilePic2 ? (
                  <img
                    src={profilePic2}
                    alt={initials2 || "Profile"}
                    style={{ width: "40px", height: "40px" }}
                    onError={(e) => {
                      e.target.style.display = "none";
                      e.target.nextElementSibling.style.display = "flex";
                    }}
                  />
                ) : null}

                {(!profilePic2 || profilePic2 === "") && (
                  <div
                    className="profile-initials"
                    style={{ width: "40px", height: "40px" }}
                  >
                    {initials2 || ""}
                  </div>
                )}

                {name2}
                {activeTabId === 1 && (
                  <>
                    <p className="set-pressure">Set as under pressure</p>
                    <label className="myprofile-switch">
                      <input
                        type="checkbox"
                        className="myprofile-checkbox"
                        checked={user2Pressure}
                        onChange={(e) => {
                          const newStatus = e.target.checked;
                          handleSectionClick(null, false, newStatus); // Pass user2 status update
                        }}
                      />
                      <span className="myprofile-slider"></span>
                    </label>
                  </>
                )}
              </div>
            </div>

            <div className="insight-content">
              <section className="myprofile-details-section">
                {listOfAdviceInsights.map((section, index) => (
                  <div
                    key={index}
                    className="myprofile-detail-item"
                    onClick={() => {
                      if (activeTabId === 1) {
                        const queryParams = new URLSearchParams();

                        queryParams.append("adviceTitle", section);
                        queryParams.append("relationId", contact?._id); // Add relationId
                        window.location.href = `/chat?${queryParams.toString()}`;
                      } else {
                        handleSectionClick(index);
                      }
                    }}
                    style={{
                      color: "var(--Base-Black, #000)",
                      background: "var(--Greek-Blue-Light, #E7EFF7)",
                    }}
                  >
                    <div className="myprofile-detail-header">
                      <h4>{section}</h4>
                      <RigthArrow className="arrowbg" />
                    </div>
                  </div>
                ))}
              </section>
            </div>
          </div>
        ) : (
          <div>{renderComponent()}</div>
        )}
      </div>
    </div>
  );
}

export default RelationshipAdiveInsight;

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { ReactComponent as LeftArrow } from "../../Assets/icon/arrow-narrow-left.svg";
import GradientBackground from "../../Components/AddRelationshipHeaderGradient";
import editicon from "../../Assets/icon/image-edit-Icon.svg";
import CircularProgressBarProfile from "../../Components/CircularProgressBarProfile";
import trophy from "../../Assets/icon/Trophy.svg";
import chaticon from "../../Assets/icon/chatvector.svg";
import networkicon from "../../Assets/icon/networkVector.svg";
import planticon from "../../Assets/icon/plantVector.svg";
import { ReactComponent as UpArrow } from "../../Assets/icon/chevron-up.svg";
import { ReactComponent as DownArrow } from "../../Assets/icon/chevron-down.svg";
import { ReactComponent as RigthArrow } from "../../Assets/icon/chevron-right.svg";
import profilecircle from "../../Assets/icon/Ellipse 86.svg";
import profiletick from "../../Assets/icon/profiletick.svg";
import profile from "../../Assets/icon/user-03.svg";
import { useHeader } from "../../Providers/HeaderContextProvider";
import {
  getUserProfile,
  getUserProfileSummary,
} from "../../Services/MyProfile"; // Import the new API function
import EditPhotoModal from "../../Components/UpdateProfileModal";
import TopBar from "../../Components/AddRelationshipTopBar";
import { getRelationships } from "../../Services/Realtionship";
import LoadingScreen from "../LoadingPage/DefaultLoader";
import Addrelationship from "../AddRelationship";
import { ReactComponent as UserPlus } from "../../Assets/images/UserPlus.svg";
import { ReactComponent as ActionPlan } from "../../Assets/icon/action_plans.svg";

const Profile = () => {
  const { setHeaderContent, addProfileData } = useHeader();

  useLayoutEffect(() => {
    setHeaderContent(false);
  }, []);

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const navigate = useNavigate();
  const [openSections, setOpenSections] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [relationships, setRelationships] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [profileSummary, setProfileSummary] = useState(null);

  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth >= 768);
  const [summaryError, setSummaryError] = useState(false);
  const [togglevalue, settogglevalue] = useState("primary");
  const [showAddRelationship, setShowAddRelationship] = useState(false);
  const [profileDetailsLoading, setprofileDetailsLoading] = useState(true);

  const sections = [
    {
      title: "How I Show Up To Others",
      key: "HowIShowUpToOthers",
      toggleable: true,
    },
    {
      title: "How I Make Decisions",
      key: "HowIMakeDecisions",
      toggleable: false,
    },
    // {
    //   title: "What Motivates Me",
    //   key: "WhatMotivatesMe",
    //   toggleable: false,
    // },
    {
      title: "How I Process Information and Experiences",
      key: "HowIProcessesInformationandExperiences",
      toggleable: false,
    },
    {
      title: "How I Approach Conflict",
      key: "HowIApproachesConflict",
      toggleable: false,
    },
    {
      title: "My Primary Needs",
      key: "MyPrimaryNeeds",
      toggleable: false,
    },
  ];

  const routoToAddReq = () => {
    const queryParams = new URLSearchParams();
    const optionalParam1 = "true";

    queryParams.append("isAdd", optionalParam1);

    navigate(`/manageRelationship?${queryParams.toString()}`);
  };

  const isCompleted = userProfile?.profile_completion_percent >= 100;

  // Map the icons to the strength texts
  const strengthIcons = [chaticon, networkicon, planticon];

  const strengthColors = [
    "linear-gradient(45deg, #BCF5A7 23%, #96F5E0 100%), #82E3CC",
    "linear-gradient(45deg, #8AD8EB 23%, #82E1CC 100%), linear-gradient(45deg, #3FCDFF 0%, #CFB2FF 77%), #82E3CC",
    "linear-gradient(45deg, #85DFFF 0%, #CFB2FF 77%)",
  ];

  const openEditModal = () => {
    setIsEditModalOpen(true);
    document.body.classList.add("no-scroll");
  };

  const closeEditModal = async () => {
    setprofileDetailsLoading(true);
    setIsEditModalOpen(false);
    document.body.classList.remove("no-scroll");
    // console.log("change 11");
    // Fetch the updated user profile data
    try {
      const updatedProfile = await getUserProfile();
      // localStorage.setItem("profile", JSON.stringify(updatedProfile));
      // setProfileContent();
      setUserProfile(updatedProfile);
      setprofileDetailsLoading(false);

      console.log("User profile updated successfully.");
    } catch (error) {
      setprofileDetailsLoading(false);

      console.error("Error fetching updated user profile:", error);
    }
  };

  const handleProfileUpdate = (updatedProfile) => {
    localStorage.setItem("profile", JSON.stringify(updatedProfile));
    addProfileData(updatedProfile);
    setUserProfile(updatedProfile);
  };

  const goToAssessmentPage = () => {
    navigate("/onboarding");
  };

  const gotoPortraitPage = () => {
    navigate("/portraits");
  };

  const goToArisstory = () => {
    navigate("/story");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopView(window.innerWidth >= 480);
      // console.log("Current Width:", window.innerWidth, "isDesktopView:", window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setprofileDetailsLoading(true);

      try {
        const [profileData, relationshipsData] = await Promise.all([
          getUserProfile(),
          getRelationships(),
        ]);

        setUserProfile(profileData);
        setRelationships(relationshipsData);
        setprofileDetailsLoading(false);

        // setProfileContent(profileData);

        try {
          const summaryData = await getUserProfileSummary();
          console.log("Summary Data:", summaryData);
          setProfileSummary(summaryData);
          setSummaryError(false);
        } catch (summaryError) {
          console.error("Error fetching summary", summaryError);
          setSummaryError(true);
        }
      } catch (error) {
        setprofileDetailsLoading(false);

        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  const handleManageClick = () => {
    navigate("/manageRelationship");
  };
  const scrollRef = useRef(null);

  const handleWheel = (event) => {
    if (scrollRef.current) {
      event.preventDefault();
      scrollRef.current.scrollLeft += event.deltaY; // Scrolls horizontally
    }
  };
  if (profileDetailsLoading) {
    return <LoadingScreen />;
  }

  const getContent = (section, toggle) => {
    console.log("Profile Summary:", profileSummary);
    console.log("Summary_primary", profileSummary.summary[`${section.title}`]);
    console.log("Section", section.key);

    if (!profileSummary) return null;

    if (section.key === "HowIShowUpToOthers") {
      return toggle === "primary"
        ? profileSummary.summary[`${section.title}`][0]
        : profileSummary.summary[`${section.title}`][1];
    }
    return profileSummary.summary[`${section.title}`];
  };

  const handleRelationshipClick = (person) => {
    navigate("/relationshipprofile", {
      state: {
        contact: person,
      },
    });
  };
  console.log("profile_picture", userProfile?.profilePicture);
  console.log("name", relationships);

  return (
    <>
      {isDesktopView ? (
        <GradientBackground>
          <TopBar
            title="My Profile"
            showBackButton={true} // Ensure there's no back button
            rightIcon={null} // Ensure there's no right icon
            onBackClick={() => {
              navigate("/dashboard"); // Go back to the previous page
            }}
          />
          <div className="myprofile-profile-card">
            <div className="myprofile-profile-header">
              <div className="myprofile-container">
                <div className="status-circle-container">
                  <div className="status-circle-progress">
                    <CircularProgressBarProfile
                      value={userProfile?.profile_completion_percent}
                      disabled={false}
                    />
                  </div>

                  {userProfile?.profile_pic_url ? (
                    <img
                      src={userProfile.profile_pic_url}
                      alt="Profile"
                      className="status-circle-profile-image"
                      onError={(e) => {
                        console.log("Image failed to load");
                        e.target.style.display = "none";
                      }}
                    />
                  ) : (
                    <div className="status-circle-profile-image">
                      {`${(userProfile?.first_name?.[0] || "").toUpperCase()}${(
                        userProfile?.last_name?.[0] || ""
                      ).toUpperCase()}`}
                    </div>
                  )}

                  <div className="myprofile-edit-icon" onClick={openEditModal}>
                    <img src={editicon} alt="Edit" />
                  </div>
                  {isEditModalOpen && userProfile && (
                    <EditPhotoModal
                      isOpen={isEditModalOpen}
                      onClose={closeEditModal}
                      profileData={userProfile}
                      onProfileUpdate={handleProfileUpdate}
                      id={userProfile._id}
                    />
                  )}
                </div>
                <div className="myprofile-status">
                  {userProfile?.profile_completion_percent}% Complete
                </div>
              </div>
              <>
                <h2>{`${userProfile?.first_name || ""} ${
                  userProfile?.last_name || ""
                }`}</h2>
                {userProfile?.primary_personality &&
                  userProfile?.primary_personality !== "null" &&
                  userProfile?.primary_personality !== "generating..." && (
                    <p className="myprofile-subtitle">
                      <span
                        className="myprofile-personality-type"
                        style={{ color: "black" }}
                      >
                        {" "}
                        Personality Type:
                      </span>{" "}
                      {userProfile?.primary_personality}
                    </p>
                  )}
              </>
              {userProfile?.badge_level && (
                <div className="myprofile-badge">
                  <img src={trophy} alt="Trophy" />
                  {userProfile?.badge_level || "Not specified"}
                </div>
              )}
            </div>

            {userProfile?.profile_strength &&
              userProfile?.profile_strength?.length > 0 && (
                <section className="myprofile-strengths-section">
                  <h3 className="myprofileh3" style={{ margin: "0px" }}>
                    My Strengths
                  </h3>
                  <div
                    className="myprofile-strengths-container"
                    ref={scrollRef}
                    onWheel={handleWheel}
                  >
                    {userProfile?.profile_strength?.map((strength, index) => (
                      <div
                        key={index}
                        className="myprofile-strength-card"
                        style={{ background: strengthColors[index] }}
                      >
                        <div className="myprofile-strength-icon">
                          <img
                            src={strengthIcons[index]}
                            alt={`icon-${index}`}
                          />
                        </div>
                        <div className="myprofile-strength-text">
                          {strength.text}
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              )}

            <section className="myprofile-new-section">
              <div
                className="myprofile-card-container"
                style={{
                  display:
                    userProfile?.profile_completion_percent >= 100
                      ? "flex"
                      : "block",
                  justifyContent: "space-between",
                  textAlign: "left",
                }}
              >
                {userProfile?.profile_completion_percent >= 100 && (
                  <div className="myprofile-card">
                    <div
                      className="myprofile-icon-wrapper"
                      style={{
                        borderRadius: "12px",
                        background:
                          "var(--tile-icon-gradient-01, linear-gradient(320deg, #82E3CC -28.48%, #80CEED 94.28%))",
                      }}
                    >
                      <div className="profile-image-container">
                        <img
                          src={profile}
                          alt="overlayImage"
                          className="overlay-image"
                        />
                        <img
                          src={profilecircle}
                          alt="My Aris Portrait"
                          className="base-image"
                        />
                      </div>
                    </div>
                    <div
                      className="myprofile-card-content"
                      onClick={gotoPortraitPage}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h4>My Aris Portrait</h4>
                          <p>Understand your psychological traits.</p>
                        </div>
                        <RigthArrow />
                      </div>
                    </div>
                  </div>
                )}

                {/* <div
                  className="myprofile-card"
                  style={{
                    width:
                      userProfile?.profile_completion_percent < 100
                        ? "100%"
                        : "",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    className="myprofile-icon-wrapper"
                    style={{
                      borderRadius: "12px",
                      background:
                        "var(--tile-icon-gradient-02, linear-gradient(45deg, #85DFFF 0%, #CFB2FF 77%))",
                    }}
                  >
                    <div className="profile-image-container-2">
                      <img src={profiletick} alt="" className="base-image" />
                    </div>
                  </div>
                  <div
                    className="myprofile-card-content"
                    onClick={isCompleted ? goToArisstory : goToAssessmentPage}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h4>
                          {isCompleted
                            ? "Aris Story"
                            : "Self-knowledge Assessment"}
                        </h4>
                        {!isCompleted ? (
                          <p>
                            {userProfile?.profile_completion_percent}% complete
                          </p>
                        ) : (
                          <p>View Aris Story</p>
                        )}
                      </div>
                      <RigthArrow />
                    </div>
                  </div>
                </div> */}
                {isCompleted && (
                  <div className="myprofile-card">
                    <div
                      className="myprofile-icon-wrapper"
                      style={{
                        borderRadius: "12px",
                        background:
                          "var(--tile-icon-gradient-01, linear-gradient(320deg, #82E3CC -28.48%, #80CEED 94.28%))",
                      }}
                    >
                      <div className="profile-image-container">
                        <ActionPlan />
                      </div>
                    </div>
                    <div
                      className="myprofile-card-content"
                      onClick={() => navigate("/coachingHistory")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h4>My Action Plans</h4>
                          <p>View Action Plans</p>
                        </div>
                        <RigthArrow />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>

            <section className="myprofile-relationships-section">
              <div className="myprofile-section-header">
                <h3 className="myprofileh3">My Relationships</h3>
                <button
                  className="myprofile-manage-button"
                  onClick={handleManageClick}
                >
                  Manage
                </button>
              </div>
              <div className="myprofile-relationships-container">
                {relationships?.length > 0 ? (
                  relationships.map((person, index) => (
                    <div key={index} className="myprofile-relationship-card">
                      <div
                        className="myprofile-relationship-avatar"
                        style={{ backgroundColor: person.color }}
                        onClick={() => handleRelationshipClick(person)}
                      >
                        {person.profile_pic_url ? (
                          <img
                            src={person.profile_pic_url}
                            alt={`${person.first_name} ${person.last_name}`}
                            className="myprofile-relationship-image"
                          />
                        ) : (
                          person.first_name?.charAt(0)?.toUpperCase() +
                          person.last_name?.charAt(0)?.toUpperCase()
                        )}
                      </div>
                      <div className="myprofile-relationship-info">
                        <h4>
                          {person.first_name
                            ? `${person.first_name
                                .charAt(0)
                                .toUpperCase()}${person.first_name
                                .slice(1)
                                .toLowerCase()}`
                            : ""}
                        </h4>
                        <h4>
                          {person.last_name
                            ? `${person.last_name
                                .charAt(0)
                                .toUpperCase()}${person.last_name
                                .slice(1)
                                .toLowerCase()}`
                            : ""}
                        </h4>
                        <p>{person.relationship}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="myprofile-relationship-card">
                    <div
                      className="myprofile-relationship-avatar"
                      style={{ background: "rgba(13, 94, 175, 1)" }}
                      onClick={routoToAddReq}
                    >
                      <UserPlus />
                    </div>
                    <div className="myprofile-relationship-info">
                      <p>Add Relationship</p>
                    </div>
                  </div>
                )}
              </div>
            </section>
            {showAddRelationship && (
              <Addrelationship
                isOpen={showAddRelationship}
                onClose={() => setShowAddRelationship(false)}
              />
            )}

            {!summaryError && profileSummary && (
              <section className="myprofile-summary-section">
                <h3 className="myprofileh3">Summary</h3>
                <div className="myprofile-traits-container">
                  {profileSummary.topStrengths.map((trait, index) => (
                    <span key={index} className="myprofile-trait-tag">
                      {trait}
                    </span>
                  ))}
                </div>

                <section className="myprofile-details-section">
                  {sections.map((section, index) => (
                    <div
                      key={index}
                      className={`myprofile-detail-item ${
                        openSections[index] ? "expanded" : "collapsed"
                      }`}
                    >
                      <div
                        className="myprofile-detail-header"
                        onClick={() => toggleSection(index)}
                      >
                        <h4>{section.title}</h4>
                        {openSections[index] ? (
                          <UpArrow className="arrowbg" />
                        ) : (
                          <DownArrow className="arrowbg" />
                        )}
                      </div>

                      {openSections[index] && (
                        <div className="myprofile-detail-content">
                          {section.key === "HowIShowUpToOthers" ? (
                            <>
                              <p className="summary-content-profile">
                                {getContent(section, togglevalue)}
                              </p>
                              <div className="myprofile-toggle-container">
                                <span>Primary</span>
                                <label className="myprofile-switch">
                                  <input
                                    type="checkbox"
                                    className="myprofile-checkbox"
                                    onChange={(e) => {
                                      const content = e.target.checked
                                        ? "pressure"
                                        : "primary";
                                      settogglevalue(content);
                                    }}
                                  />
                                  <span className="myprofile-slider"></span>
                                </label>
                                <span>Under Pressure</span>
                              </div>
                            </>
                          ) : (
                            <p>{getContent(section)}</p>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </section>
              </section>
            )}
          </div>
        </GradientBackground>
      ) : (
        <div className="mobile-background">
          <TopBar
            showBackButton={true} // Ensure there's no back button
            rightIcon={null} // Ensure there's no right icon
            onBackClick={() => {
              navigate("/dashboard"); // Go back to the previous page
            }}
            useRegularArrow={true} // Ensures the regular arrow is used
          />

          <div className="myprofile-profile-header">
            <div className="myprofile-container">
              <div className="status-circle-container">
                <div className="status-circle-progress">
                  <CircularProgressBarProfile
                    value={userProfile?.profile_completion_percent}
                    disabled={false}
                  />
                </div>

                {userProfile?.profile_pic_url ? (
                  <img
                    src={userProfile.profile_pic_url}
                    alt="Profile"
                    className="status-circle-profile-image"
                    onError={(e) => {
                      console.log("Image failed to load");
                      e.target.style.display = "none";
                    }}
                  />
                ) : (
                  <div className="status-circle-profile-image">
                    {`${(userProfile?.first_name?.[0] || "").toUpperCase()}${(
                      userProfile?.last_name?.[0] || ""
                    ).toUpperCase()}`}
                  </div>
                )}

                <div className="myprofile-edit-icon" onClick={openEditModal}>
                  <img src={editicon} alt="Edit" />
                </div>
                {isEditModalOpen && userProfile && (
                  <EditPhotoModal
                    isOpen={isEditModalOpen}
                    onClose={closeEditModal}
                    profileData={userProfile}
                    onProfileUpdate={handleProfileUpdate}
                    id={userProfile._id}
                  />
                )}
              </div>
              <div className="myprofile-status">
                {userProfile?.profile_completion_percent}% Complete
              </div>
            </div>
            <h2>{`${userProfile?.first_name || ""} ${
              userProfile?.last_name || ""
            }`}</h2>
            {userProfile.primary_personality &&
              userProfile.primary_personality !== "null" &&
              userProfile.primary_personality !== "generating..." && (
                <p className="myprofile-subtitle">
                  <span className="myprofile-personality-type">
                    {" "}
                    Personality Type:
                  </span>{" "}
                  {userProfile.primary_personality}
                </p>
              )}
            {userProfile.badge_level && (
              <div className="myprofile-badge">
                <img src={trophy} alt="Trophy" />
                {userProfile?.badge_level || "Not specified"}
              </div>
            )}
          </div>

          <div className="myprofile-profile-card">
            {/* Strengths Section */}
            {userProfile?.profile_strength &&
              userProfile.profile_strength.length > 0 && (
                <section className="myprofile-strengths-section">
                  <h3 className="myprofileh3" style={{ margin: "0px" }}>
                    My Strengths
                  </h3>
                  <div
                    className="myprofile-strengths-container"
                    ref={scrollRef}
                    onWheel={handleWheel}
                  >
                    {userProfile.profile_strength.map((strength, index) => (
                      <div
                        key={index}
                        className="myprofile-strength-card"
                        style={{ background: strengthColors[index] }}
                      >
                        <div className="myprofile-strength-icon">
                          <img
                            src={strengthIcons[index]}
                            alt={`icon-${index}`}
                          />
                        </div>
                        <div className="myprofile-strength-text">
                          {strength.text}
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              )}

            <section className="myprofile-new-section">
              <div
                className={`myprofile-card-container ${
                  userProfile?.profile_completion_percent < 100
                    ? "single-card"
                    : ""
                }`}
              >
                {/* My Aris Portrait Card - Only show if completion is 100% */}
                {userProfile?.profile_completion_percent >= 100 && (
                  <div className="myprofile-card">
                    <div
                      className="myprofile-icon-wrapper"
                      style={{
                        borderRadius: "12px",
                        background:
                          "var(--tile-icon-gradient-01, linear-gradient(320deg, #82E3CC -28.48%, #80CEED 94.28%))",
                      }}
                    >
                      <div className="profile-image-container">
                        <img
                          src={profile}
                          alt="overlayImage"
                          className="overlay-image"
                        />
                        <img
                          src={profilecircle}
                          alt="My Aris Portrait"
                          className="base-image"
                        />
                      </div>
                    </div>
                    <div
                      className="myprofile-card-content"
                      onClick={gotoPortraitPage}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h4>My Aris Portrait</h4>
                          <p>Understand your psychological traits.</p>
                        </div>
                        <RigthArrow />
                      </div>
                    </div>
                  </div>
                )}

                {/* Assessment/Story Card */}
                {/* <div className={`myprofile-card ${userProfile?.profile_completion_percent !== 100 ? 'full-width' : ''}`}> */}
                <div
                  className={`myprofile-card ${
                    userProfile?.profile_completion_percent < 100
                      ? "full-width"
                      : ""
                  }`}
                >
                  <div
                    className="myprofile-icon-wrapper"
                    style={{
                      borderRadius: "12px",
                      background:
                        "var(--tile-icon-gradient-02, linear-gradient(45deg, #85DFFF 0%, #CFB2FF 77%))",
                    }}
                    y
                  >
                    <div className="profile-image-container-2">
                      <img src={profiletick} alt="" className="base-image" />
                    </div>
                  </div>
                  <div
                    className="myprofile-card-content"
                    onClick={isCompleted ? goToArisstory : goToAssessmentPage}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h4>
                          {isCompleted
                            ? "Aris Story"
                            : "Self-knowledge Assessment"}
                        </h4>
                        <div>
                          {!isCompleted ? (
                            <p>
                              {userProfile?.profile_completion_percent}%
                              complete
                            </p>
                          ) : (
                            <p>View Aris Story</p>
                          )}
                        </div>
                      </div>
                      <RigthArrow />
                    </div>
                  </div>
                </div>
                {isCompleted && (
                  <div className="myprofile-card">
                    <div
                      className="myprofile-icon-wrapper"
                      style={{
                        borderRadius: "12px",
                        background:
                          "var(--tile-icon-gradient-01, linear-gradient(320deg, #82E3CC -28.48%, #80CEED 94.28%))",
                      }}
                    >
                      <div className="profile-image-container">
                        <ActionPlan />
                      </div>
                    </div>
                    <div
                      className="myprofile-card-content"
                      onClick={() => navigate("/coachingHistory")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h4>My Action Plans</h4>
                          <p>View Action Plans</p>
                        </div>
                        <RigthArrow />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
            {/* Relationships Section */}
            <section className="myprofile-relationships-section">
              <div className="myprofile-section-header">
                <h3 className="myprofileh3">My Relationships</h3>
                <button
                  className="myprofile-manage-button"
                  onClick={handleManageClick}
                >
                  Manage
                </button>
              </div>
              <div className="myprofile-relationships-container">
                {relationships?.length > 0 ? (
                  relationships.map((person, index) => (
                    <div key={index} className="myprofile-relationship-card">
                      <div
                        className="myprofile-relationship-avatar"
                        style={{ backgroundColor: person.color }}
                        onClick={() => handleRelationshipClick(person)}
                      >
                        {person.profile_pic_url ? (
                          <img
                            src={person.profile_pic_url}
                            alt={`${person.first_name} ${person.last_name}`}
                            className="myprofile-relationship-image"
                          />
                        ) : (
                          person.first_name?.charAt(0)?.toUpperCase() +
                          person.last_name?.charAt(0)?.toUpperCase()
                        )}
                      </div>
                      <div className="myprofile-relationship-info">
                        <h4>
                          {person.first_name
                            ? `${person.first_name
                                .charAt(0)
                                .toUpperCase()}${person.first_name
                                .slice(1)
                                .toLowerCase()}`
                            : ""}
                        </h4>
                        <h4>
                          {person.last_name
                            ? `${person.last_name
                                .charAt(0)
                                .toUpperCase()}${person.last_name
                                .slice(1)
                                .toLowerCase()}`
                            : ""}
                        </h4>
                        <p>{person.relationship}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="myprofile-relationship-card">
                    <div
                      className="myprofile-relationship-avatar"
                      style={{ background: "rgba(13, 94, 175, 1)" }}
                      onClick={routoToAddReq}
                    >
                      <UserPlus />
                    </div>
                    <div className="myprofile-relationship-info">
                      <p>Add Relationship</p>
                    </div>
                  </div>
                )}
              </div>
            </section>
            {showAddRelationship && (
              <Addrelationship
                isOpen={showAddRelationship}
                onClose={() => setShowAddRelationship(false)}
              />
            )}

            {/* Summary Section */}
            {/* <section className="myprofile-summary-section">
                <h3>Summary</h3>
                <div className="myprofile-traits-container">
                  <span className="myprofile-trait-tag">
                    {userProfile?.primary_personality}
                  </span>
                  <span className="myprofile-trait-tag">
                    {userProfile?.under_pressure_personality}
                  </span>
                </div>
 
 
                <section className="myprofile-details-section">
                  {sections.map((section, index) => (
                    <div
                      key={index}
                      className={`myprofile-detail-item ${openSections[index] ? "expanded" : "collapsed"
                        }`}
                    >
                      <div
                        className="myprofile-detail-header"
                        onClick={() => toggleSection(index)}
                      >
                        <h4>{section.title}</h4>
                        {openSections[index] ? (
                          <UpArrow className="arrowbg" />
                        ) : (
                          <DownArrow className="arrowbg" />
                        )}
                      </div>
 
                      {openSections[index] && (
                        <div className="myprofile-detail-content">
                          <p>{section.content}</p>
 
                          {index === 0 && (
                            <div className="myprofile-toggle-container">
                              <span>Primary</span>
                              <label className="myprofile-switch">
                                <input
                                  type="checkbox"
                                  className="myprofile-checkbox"
                                />
                                <span className="myprofile-slider"></span>
                              </label>
                              <span>Under Pressure</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </section>
              </section> */}

            {!summaryError && profileSummary && (
              <section className="myprofile-summary-section">
                <h3 className="myprofileh3">Summary</h3>
                <div className="myprofile-traits-container">
                  {profileSummary.topStrengths.map((trait, index) => (
                    <span key={index} className="myprofile-trait-tag">
                      {trait}
                    </span>
                  ))}
                </div>

                <section className="myprofile-details-section">
                  {sections.map((section, index) => (
                    <div
                      key={index}
                      className={`myprofile-detail-item ${
                        openSections[index] ? "expanded" : "collapsed"
                      }`}
                    >
                      <div
                        className="myprofile-detail-header"
                        onClick={() => toggleSection(index)}
                      >
                        <h4>{section.title}</h4>
                        {openSections[index] ? (
                          <UpArrow className="arrowbg" />
                        ) : (
                          <DownArrow className="arrowbg" />
                        )}
                      </div>

                      {openSections[index] && (
                        <div className="myprofile-detail-content">
                          {section.key === "HowIShowUpToOthers" ? (
                            <>
                              <p className="summary-content-profile">
                                {getContent(section, togglevalue)}
                              </p>
                              <div className="myprofile-toggle-container">
                                <span>Primary</span>
                                <label className="myprofile-switch">
                                  <input
                                    type="checkbox"
                                    className="myprofile-checkbox"
                                    onChange={(e) => {
                                      const content = e.target.checked
                                        ? "pressure"
                                        : "primary";
                                      settogglevalue(content);
                                    }}
                                  />
                                  <span className="myprofile-slider"></span>
                                </label>
                                <span>Under Pressure</span>
                              </div>
                            </>
                          ) : (
                            <p>{getContent(section)}</p>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </section>
              </section>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;

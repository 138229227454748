import React, { useEffect, useState } from "react";
import "./styles.css";
import AssessmentContainer from "../../Components/AssesmentContainer";
import {
  nextQuestions,
  prevQuestions,
  startNextTest,
  startNextTestbyCategoryId,
  startQuestions,
} from "../../Services/Assessment";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../LoadingPage";
import { useHeader } from "../../Providers/HeaderContextProvider";

function Survey() {
  const { setHeaderContent } = useHeader();

  useEffect(() => {
    setHeaderContent(false);
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    setloading(true);
    startQuestions(true)
      .then((res) => {
        console.log(res, "responseFromSurvey");
        if (res?.categoryID === null) {
          navigate("/dashboard");
        }
        console.log("response from assessment", res);
        setcurrentQuestionInfo(res);
        setloading(false);
      })
      .catch((error) => {
        console.log("error from assessment", error);
        setloading(false);
      })
      .finally(() => {
        setloading(false);
      });
  }, []);

  const [currentQuestionInfo, setcurrentQuestionInfo] = useState({});
  const [loading, setloading] = useState(false);

  //function to submit current question's answer and call next question
  const callNextQuestion = (answer, isExist = false) => {
    console.log(answer, isExist, "nextTest");
    const nextQuestionData = {
      item_id: currentQuestionInfo?.categoryId,

      item_type: "category",
      question_id: currentQuestionInfo?.currQuestion?.id,
      answer_id: answer,
      skip_question: false,
      exit: isExist ? true : false,
      is_survey: true,
      ques_arr_limit: 10,
    };
    if (
      currentQuestionInfo?.currQuestion?.number ===
      currentQuestionInfo?.totalQuestions
    ) {
      navigate(`/assessmentEncouragement/100`);
    }
    nextQuestions(nextQuestionData)
      .then((res) => {
        console.log("response from next question", res);
        if (isExist) {
          onSaveExitClick("survey");
        }
        setcurrentQuestionInfo(res);
      })
      .catch((error) => console.log(error, "error from next question"));
  };

  //function to call previous question
  const callPrevQuestions = (isExist = false) => {
    const prevQuestionData = {
      item_id: currentQuestionInfo?.categoryId,
      item_type: "category",
      curr_question_num: currentQuestionInfo?.currQuestion?.number,
      is_survey: true,
    };
    prevQuestions(prevQuestionData)
      .then((res) => {
        console.log("response from prev question", res);
        setcurrentQuestionInfo(res);
      })
      .catch((error) => console.log(error, "error from previous question"));
  };

  const onSaveExitClick = (txt) => {
    navigate(`/assessmentProgress/${txt}`);
  };

  console.log(
    currentQuestionInfo?.currQuestion?.number,
    currentQuestionInfo?.totalQuestions,
    "total"
  );
  if (loading) return <LoadingPage />;
  return (
    <div className="assessment-hero-container">
      <div className="supressed-bg">
        <AssessmentContainer
          id={currentQuestionInfo?.currQuestion?.id}
          question={currentQuestionInfo?.instructions}
          description={currentQuestionInfo?.currQuestion?.description}
          options={currentQuestionInfo?.currQuestion?.optionsList}
          type={currentQuestionInfo?.currQuestion?.type}
          onSubmit={(answer, exit) => callNextQuestion(answer, exit)}
          onPrev={callPrevQuestions}
          isFirstQuestion={currentQuestionInfo?.number === 1}
          progressValue={currentQuestionInfo?.completionStatus}
          isSurvey={true}
        />
      </div>
    </div>
  );
}

export default Survey;

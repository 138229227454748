import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  useDroppable,
  rectIntersection,
  DragOverlay,
  defaultDropAnimation,
} from "@dnd-kit/core";
import {
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./style.css";

import defaultAnnouncements from "./defaultAnnouncements";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";

const DataContext = createContext({});

const DNDMultiContainer = ({ onSubmit, answerId, options }) => {
  const initialState2 =
    answerId?.length > 0
      ? {
          columns: [
            { items: options.filter((op) => op.id === answerId[0]) },
            { items: options.filter((op) => op.id === answerId[1]) },
            {
              items: options.filter(
                (op) => op.id !== answerId[0] && op.id !== answerId[1]
              ),
            },
          ],
        }
      : {
          columns: [
            { items: [] },
            { items: [] },
            {
              items: [...options],
            },
          ],
        };

  const [data, setData] = useState(initialState2);
  const [items, setItems] = useState({
    column_0: data.columns[0].items,
    column_1: data.columns[1].items,
    column_2: data.columns[2].items,
  });
  const [draggingColumn, setDraggingColumn] = useState(null);
  const [activeId, setActiveId] = useState(null);
  // Add a new state to track which column to target next on double-click
  const [nextTargetColumn, setNextTargetColumn] = useState("column_0");

  useEffect(() => {
    let mostLeast = [items?.column_0[0]?.id, items?.column_1[0]?.id];
    onSubmit(mostLeast);
  }, [items]);

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        items,
        setItems,
        draggingColumn,
        setDraggingColumn,
        activeId,
        setActiveId,
        nextTargetColumn,
        setNextTargetColumn,
      }}
    >
      <div className="tw-flex tw-flex-col">
        <Columns />
      </div>
    </DataContext.Provider>
  );
};

const Column = ({ id, items, isMostLeast, label }) => {
  const { setNodeRef } = useDroppable({ id });
  const { draggingColumn } = useContext(DataContext);

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <div
        ref={setNodeRef}
        className={`${
          isMostLeast
            ? `most-least-dnd-cont ${
                items?.length === 0 ? " bg-dull" : "solid-border"
              }`
            : "dnd-main-cnt"
        }`}
        data-column-id={id}
      >
        {isMostLeast && <div className="dnd-most-least-txt">{label}</div>}
        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: isMostLeast ? "90%" : "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              {items.map((item) => (
                <ColumnItem
                  key={`item${item.id}`}
                  item={item}
                  isMostLeast={isMostLeast}
                  columnId={id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </SortableContext>
  );
};

// A component for both normal display and overlay
const ItemContent = ({ item, isMostLeast, isDragging, onDoubleClick }) => (
  <>
    <div
      className={`dnd-opt-head ${isMostLeast && "reduced-head-size"}`}
      onDoubleClick={onDoubleClick}
    >
      <div className="dnd-opt-head-title">{item.text}</div>
      {!isDragging && (
        <div className={`dnd-desc ${isMostLeast && "dnd-decs-insider"}`}>
          {item.description}
        </div>
      )}
    </div>
    <DragIndicatorIcon style={{ position: "absolute", right: 8 }} />
  </>
);

const ColumnItem = (props) => {
  const { item, isMostLeast, columnId } = props;
  const { activeId, setItems, nextTargetColumn, setNextTargetColumn } =
    useContext(DataContext);

  const isActive = activeId === item.id;

  // Handle double-click for items in column_2
  const handleDoubleClick = (e) => {
    e.stopPropagation();

    if (columnId === "column_2") {
      // Use the current target column from context
      const targetColumn = nextTargetColumn;

      setItems((prev) => {
        const newItems = {
          ...prev,
          column_0: [...prev.column_0],
          column_1: [...prev.column_1],
          column_2: [...prev.column_2],
        };

        const itemIndex = newItems.column_2.findIndex((i) => i.id === item.id);
        if (itemIndex === -1) return prev;

        // Check if the target column already has an item
        if (newItems[targetColumn].length > 0) {
          // Move the existing item back to column_2 before replacing
          const existingItem = newItems[targetColumn].shift();
          newItems.column_2.push(existingItem);
        }

        // Move the new item to the target column
        const [movedItem] = newItems.column_2.splice(itemIndex, 1);
        newItems[targetColumn].push(movedItem);

        return newItems;
      });

      // Toggle the target column for next time
      setNextTargetColumn(
        targetColumn === "column_0" ? "column_1" : "column_0"
      );
    }
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: item.id,
    data: {
      ...item,
      columnId: columnId,
      isMostLeast,
    },
  });

  const style = {
    transition,
    width: "90%",
    height: "auto",
    // When actively dragging, make the original item invisible but keep space
    opacity: isActive ? 0 : 1,
    position: "relative",
    // Only apply transform when not actively dragging
    // This prevents the shadow item from moving
    transform: isActive ? undefined : CSS.Transform.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`drag-element-container ${isDragging ? "dragging" : ""}`}
    >
      <ItemContent
        item={item}
        isMostLeast={isMostLeast}
        isDragging={isDragging}
        onDoubleClick={handleDoubleClick}
      />
    </div>
  );
};

const Columns = () => {
  const { items, setItems, setDraggingColumn, activeId, setActiveId } =
    useContext(DataContext);

  const parentRef = useRef(null);
  const [boundingRect, setBoundingRect] = useState(null);

  useEffect(() => {
    if (parentRef.current) {
      setBoundingRect(parentRef.current.getBoundingClientRect());
    }

    // Update the bounding rect on window resize
    const handleResize = () => {
      if (parentRef.current) {
        setBoundingRect(parentRef.current.getBoundingClientRect());
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Find the active item data across all columns
  const getActiveItem = () => {
    if (!activeId) return null;

    for (const containerId in items) {
      const foundItem = items[containerId].find((item) => item.id === activeId);
      if (foundItem) {
        return {
          ...foundItem,
          columnId: containerId,
          isMostLeast: containerId === "column_0" || containerId === "column_1",
        };
      }
    }
    return null;
  };

  const activeItem = getActiveItem();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Increased delay to give time for double-click
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = ({ active }) => {
    if (!active) return;

    setActiveId(active.id);
    document.body.classList.add("is-dragging");

    const activeContainer =
      active?.data?.current?.columnId ||
      active?.data?.current?.sortable?.containerId;

    setDraggingColumn(activeContainer);
  };

  const handleDragOver = ({ active, over }) => {
    // We can add handling here if needed for visual feedback
  };

  const handleDragEnd = ({ active, over }) => {
    if (!active || !over) {
      // Clean up
      setActiveId(null);
      document.body.classList.remove("is-dragging");
      setDraggingColumn(null);
      return;
    }

    // Clear states
    setActiveId(null);
    document.body.classList.remove("is-dragging");
    setDraggingColumn(null);

    const activeId = active.id;

    // Get containers
    const activeContainer =
      active?.data?.current?.columnId ||
      active?.data?.current?.sortable?.containerId;

    let overContainer;
    if (over.data?.current?.columnId) {
      overContainer = over.data.current.columnId;
    } else if (over.data?.current?.sortable?.containerId) {
      overContainer = over.data.current.sortable.containerId;
    } else {
      overContainer = over.id;
    }

    if (!activeContainer || !overContainer) return;

    // Handle the drop
    setItems((prev) => {
      if (activeContainer === overContainer) {
        // Same container sorting
        const overId = over.id;
        const containerItems = [...prev[activeContainer]];
        const activeIndex = containerItems.findIndex(
          (item) => item.id === activeId
        );
        const overIndex = containerItems.findIndex(
          (item) => item.id === overId
        );

        if (activeIndex !== -1 && overIndex !== -1) {
          const [movedItem] = containerItems.splice(activeIndex, 1);
          containerItems.splice(overIndex, 0, movedItem);
        }

        return { ...prev, [activeContainer]: containerItems };
      }
      // Moving between containers
      else {
        const newItems = { ...prev };
        const activeIndex = newItems[activeContainer].findIndex(
          (item) => item.id === activeId
        );

        if (activeIndex === -1) return prev;

        // Move to Most or Least columns
        if (overContainer === "column_0" || overContainer === "column_1") {
          if (newItems[overContainer].length >= 1) {
            // Move existing item back to column_2
            const existingItem = newItems[overContainer].shift();
            newItems["column_2"].push(existingItem);
          }

          // Move item to target container
          const [movedItem] = newItems[activeContainer].splice(activeIndex, 1);
          newItems[overContainer].push(movedItem);
        }
        // Move to main column
        else if (overContainer === "column_2") {
          const [movedItem] = newItems[activeContainer].splice(activeIndex, 1);
          newItems[overContainer].push(movedItem);
        }

        return newItems;
      }
    });
  };

  const handleDragCancel = () => {
    setActiveId(null);
    document.body.classList.remove("is-dragging");
    setDraggingColumn(null);
  };

  // Customize drop animation
  const dropAnimation = {
    ...defaultDropAnimation,
    duration: 200,
    easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      collisionDetection={rectIntersection}
      announcements={defaultAnnouncements}
      sensors={sensors}
      modifiers={[restrictToFirstScrollableAncestor]}
    >
      <div
        id={"parent-div-dnd"}
        ref={parentRef}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
          position: "relative", // Important to set relative to limit dragging inside
          overflow: "hidden", // To prevent any overflow
        }}
      >
        <Column
          id="column_0"
          items={items.column_0}
          isMostLeast={true}
          label={"Most"}
        />
        <Column
          id="column_1"
          items={items.column_1}
          isMostLeast={true}
          label={"Least"}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2px",
        }}
      >
        <Column id="column_2" items={items.column_2} isMostLeast={false} />
      </div>

      {/* Modified overlay for better cursor tracking */}
      <DragOverlay
        dropAnimation={dropAnimation}
        adjustScale={false}
        zIndex={1000}
      >
        {activeItem && (
          <div
            className="drag-element-container dragging"
            style={{
              width: "150px",
              backgroundColor: "white",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              cursor: "grabbing",
              opacity: 0.9,
              transform: "translate(-50%, -50%)", // This centers the element on the cursor
            }}
          >
            <ItemContent
              item={activeItem}
              isMostLeast={activeItem.isMostLeast}
              isDragging={true}
            />
          </div>
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default DNDMultiContainer;

// import React, { useEffect, useState } from "react";
// import people from "../../../../Assets/images/people.png";
// import ReactMarkdown from "react-markdown";
// import { ReactComponent as Backbutton } from "../../../../Assets/icon/backbutton.svg";
// import {
//   fetchInsightContent,
//   fetchInsightMetrics,
// } from "../../../../Services/Realtionship";
// import InfoTooltip from "./InfoTooltip";

// export function Personality({
//   data,
//   onBack,
//   profilePic1,
//   profilePic2,
//   initials1,
//   initials2,
//   name1,
//   name2,
//   relation_id,
//   tooltip,
//   user1Metrics,
//   user2Metrics,
// }) {
//   const [user1Pressure, setUser1Pressure] = useState(false);
//   const [user2Pressure, setUser2Pressure] = useState(false);
//   const [insightContent, setInsightContent] = useState(data);

//   const [user1Position, setUser1Position] = useState(0);
//   const [user2Position, setUser2Position] = useState(0);

//   useEffect(() => {
//     let start = 50; // Start from 50%
//     let final1 = user1Pressure
//       ? 100 - user1PressureMetrics.people_driven
//       : 100 - user1PrimaryMetrics.people_driven;
//     let final2 = user2Pressure
//       ? 100 - user2PressureMetrics.people_driven
//       : 100 - user2PrimaryMetrics.people_driven;
  
//     const duration = 1000; // 1 second animation
//     const frameRate = 60;
//     const frameDuration = duration / frameRate;
//     let step1 = (final1 - start) / frameRate;
//     let step2 = (final2 - start) / frameRate;
//     let count = 0;
  
//     setUser1Position(50); // Start from 50%
//     setUser2Position(50); // Start from 50%
  
//     const animate = () => {
//       if (count < frameRate) {
//         setUser1Position((prev) => prev + step1);
//         setUser2Position((prev) => prev + step2);
//         count++;
//         setTimeout(animate, frameDuration);
//       } else {
//         setUser1Position(final1);
//         setUser2Position(final2);
//       }
//     };
  
//     animate();
//   }, [user1Pressure, user2Pressure, user1Metrics, user2Metrics]);
  

//   const handleFetchContent = async (u1_status, u2_status) => {
//     const response = await fetchInsightContent(
//       "insight",
//       relation_id,
//       "Personality Module",
//       u1_status,
//       u2_status
//     );
//     setInsightContent(response || "Failed to fetch content.");
//   };

//   const isClose = (value1, value2) => Math.abs(value1 - value2) < 10;

//   const user1PrimaryMetrics = user1Metrics && user1Metrics.primary;
//   const user2PrimaryMetrics = user2Metrics && user2Metrics.primary;
//   const user1PressureMetrics = user1Metrics && user1Metrics.underpressure;
//   const user2PressureMetrics = user2Metrics && user2Metrics.underpressure;

//   return (
//     <div className="personality-container">
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <button className="backbutton-insights" onClick={onBack}>
//           <Backbutton /> Back
//         </button>
//         <div className="personality-heading">Personality</div>
//         <div>
//           <InfoTooltip content={tooltip} />
//         </div>
//       </div>

//       <div className="insight-people-container">
//         {/* User 1 */}
//         <div className="insight-people">
//           {profilePic1 ? (
//             <img
//               src={profilePic1}
//               alt={initials1 || "Profile"}
//               style={{ width: "40px", height: "40px" }}
//             />
//           ) : (
//             <div
//               className="profile-initials"
//               style={{ width: "40px", height: "40px" }}
//             >
//               {initials1 || ""}
//             </div>
//           )}
//           {name1}
//           <p className="set-pressure">Set as under pressure</p>
//           <label className="myprofile-switch">
//             <input
//               type="checkbox"
//               className="myprofile-checkbox"
//               checked={user1Pressure}
//               onChange={(e) => {
//                 const newStatus = e.target.checked;
//                 setUser1Pressure(newStatus);
//                 handleFetchContent(newStatus, user2Pressure);
//               }}
//             />
//             <span className="myprofile-slider"></span>
//           </label>
//         </div>

//         {/* User 2 */}
//         <div className="insight-people">
//           {profilePic2 ? (
//             <img
//               src={profilePic2}
//               alt={initials2 || "Profile"}
//               style={{ width: "40px", height: "40px" }}
//             />
//           ) : (
//             <div
//               className="profile-initials"
//               style={{ width: "40px", height: "40px" }}
//             >
//               {initials2 || ""}
//             </div>
//           )}
//           {name2}
//           <p className="set-pressure">Set as under pressure</p>
//           <label className="myprofile-switch">
//             <input
//               type="checkbox"
//               className="myprofile-checkbox"
//               checked={user2Pressure}
//               onChange={(e) => {
//                 const newStatus = e.target.checked;
//                 setUser2Pressure(newStatus);
//                 handleFetchContent(user1Pressure, newStatus);
//               }}
//             />
//             <span className="myprofile-slider"></span>
//           </label>
//         </div>
//       </div>

//       <div className="insight-progress-container">
//         <div className="insight-progress-info-container">
//           <div>Big Picture</div>
//           <div>Detail Oriented</div>
//         </div>
//         <div className="insight-progress-line"></div>
//         <div className="insight-progress-line-inner"></div>
//         <div
//           className="insight-progress-line-inner"
//           style={{
//             height: "10%",
//             left: "20%",
//             transform: "translateY(420%)",
//           }}
//         ></div>
//         <div
//           className="insight-progress-line-inner"
//           style={{
//             height: "10%",
//             left: "40%",
//             transform: "translateY(420%)",
//           }}
//         ></div>
//         <div
//           className="insight-progress-line-inner"
//           style={{
//             height: "10%",
//             left: "60%",
//             transform: "translateY(420%)",
//           }}
//         ></div>
//         <div
//           className="insight-progress-line-inner"
//           style={{ left: "80%" }}
//         ></div>

//         {user1Metrics && (
//           <div
//             className="insight-person-container"
//             style={{ left: `${user1Position}%`, transition: "left 1s ease-out" }}
//           >
//             {profilePic1 ? (
//               <img
//                 src={profilePic1}
//                 alt={initials1 || "Profile"}
//                 style={{ width: "24px", height: "24px", fontSize: "10px" }}
//                 onError={(e) => {
//                   e.target.style.display = "none";
//                   e.target.nextElementSibling.style.display = "flex";
//                 }}
//               />
//             ) : null}

//             {(!profilePic1 || profilePic1 === "") && (
//               <div
//                 className="profile-initials"
//                 style={{ width: "24px", height: "24px", fontSize: "10px" }}
//               >
//                 {initials1 || ""}
//               </div>
//             )}

//             {!isClose(
//               user1Pressure
//                 ? user1PressureMetrics.detail_oriented
//                 : user1PrimaryMetrics.detail_oriented,
//               user2Metrics &&
//                 (user2Pressure
//                   ? user2PressureMetrics.detail_oriented
//                   : user2PrimaryMetrics.detail_oriented)
//             ) && name1}
//           </div>
//         )}
//         {user2Metrics !== null && (
//           <div
//             className="insight-person-container"
//             style={{ left: `${user2Position}%`, transition: "left 1s ease-out" }}
//           >
//             {profilePic2 ? (
//               <img
//                 src={profilePic2}
//                 alt={initials2 || "Profile"}
//                 style={{ width: "24px", height: "24px", fontSize: "10px" }}
//                 onError={(e) => {
//                   e.target.style.display = "none";
//                   e.target.nextElementSibling.style.display = "flex";
//                 }}
//               />
//             ) : null}

//             {(!profilePic2 || profilePic2 === "") && (
//               <div
//                 className="profile-initials"
//                 style={{ width: "24px", height: "24px", fontSize: "10px" }}
//               >
//                 {initials2 || ""}
//               </div>
//             )}

//             {!isClose(
//               user1Pressure
//                 ? user1PressureMetrics.detail_oriented
//                 : user1PrimaryMetrics.detail_oriented,
//               user2Metrics &&
//                 (user2Pressure
//                   ? user2PressureMetrics.detail_oriented
//                   : user2PrimaryMetrics.detail_oriented)
//             ) && name2}
//           </div>
//         )}
//       </div>


//       <div className="insight-progress-container">
//         <div className="insight-progress-info-container">
//           <div>Results Driven</div>
//           <div>People Driven</div>
//         </div>
//         <div className="insight-progress-line"></div>
//         <div className="insight-progress-line"></div>
//         <div className="insight-progress-line-inner"></div>
//         <div
//           className="insight-progress-line-inner"
//           style={{
//             height: "10%",
//             left: "20%",
//             transform: "translateY(420%)",
//           }}
//         ></div>
//         <div
//           className="insight-progress-line-inner"
//           style={{
//             height: "10%",
//             left: "40%",
//             transform: "translateY(420%)",
//           }}
//         ></div>
//         <div
//           className="insight-progress-line-inner"
//           style={{
//             height: "10%",
//             left: "60%",
//             transform: "translateY(420%)",
//           }}
//         ></div>
//         <div
//           className="insight-progress-line-inner"
//           style={{ left: "80%" }}
//         ></div>


//         {/* User 1 Animated Position */}
//         {user1Metrics && (
//           <div
//             className="insight-person-container"
//             style={{
//               left: `${user1Position}%`,
//               transition: "left 1s ease-out",
//             }}
//           >
//             {profilePic1 ? (
//               <img
//                 src={profilePic1}
//                 alt={initials1 || "Profile"}
//                 style={{ width: "24px", height: "24px", fontSize: "10px" }}
//                 onError={(e) => {
//                   e.target.style.display = "none";
//                   e.target.nextElementSibling.style.display = "flex";
//                 }}
//               />
//             ) : (
//               <div
//                 className="profile-initials"
//                 style={{ width: "24px", height: "24px", fontSize: "10px" }}
//               >
//                 {initials1 || ""}
//               </div>
//             )}
//             {name1}
//           </div>
//         )}

//         {/* User 2 Animated Position */}
//         {user2Metrics && (
//           <div
//             className="insight-person-container"
//             style={{
//               left: `${user2Position}%`,
//               transition: "left 1s ease-out",
//             }}
//           >
//             {profilePic2 ? (
//               <img
//                 src={profilePic2}
//                 alt={initials2 || "Profile"}
//                 style={{ width: "24px", height: "24px", fontSize: "10px" }}
//                 onError={(e) => {
//                   e.target.style.display = "none";
//                   e.target.nextElementSibling.style.display = "flex";
//                 }}
//               />
//             ) : (
//               <div
//                 className="profile-initials"
//                 style={{ width: "24px", height: "24px", fontSize: "10px" }}
//               >
//                 {initials2 || ""}
//               </div>
//             )}
//             {name2}
//           </div>
//         )}
//       </div>

//       <div>
//         <div className="personality-content">
//           <div style={{ textAlign: "left" }}>
//             <ReactMarkdown
//               components={{
//                 h1: "h3",
//                 h2: "h4",
//               }}
//             >
//               {data?.replace(/\n/g, "\n\n") || ""}
//             </ReactMarkdown>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }


import React, { useEffect, useState } from "react";
import people from "../../../../Assets/images/people.png";
import ReactMarkdown from "react-markdown";
import { ReactComponent as Backbutton } from "../../../../Assets/icon/backbutton.svg";
import {
  fetchInsightContent,
  fetchInsightMetrics,
} from "../../../../Services/Realtionship";
import InfoTooltip from "./InfoTooltip";

export function Personality({
  data,
  onBack,
  profilePic1,
  profilePic2,
  initials1,
  initials2,
  name1,
  name2,
  relation_id,
  tooltip,
  user1Metrics,
  user2Metrics,
}) {
  const [user1Pressure, setUser1Pressure] = useState(false);
  const [user2Pressure, setUser2Pressure] = useState(false);
  const [insightContent, setInsightContent] = useState(data);

  const [user1Position, setUser1Position] = useState(0);
  const [user2Position, setUser2Position] = useState(0);

  useEffect(() => {
    let start = 50; // Start from 50%
    let final1 = user1Pressure
      ? 100 - user1PressureMetrics.people_driven
      : 100 - user1PrimaryMetrics.people_driven;
    let final2 = user2Pressure
      ? 100 - user2PressureMetrics.people_driven
      : 100 - user2PrimaryMetrics.people_driven;
  
    const duration = 1000; // 1 second animation
    const frameRate = 60;
    const frameDuration = duration / frameRate;
    let step1 = (final1 - start) / frameRate;
    let step2 = (final2 - start) / frameRate;
    let count = 0;
  
    setUser1Position(50); // Start from 50%
    setUser2Position(50); // Start from 50%
  
    const animate = () => {
      if (count < frameRate) {
        setUser1Position((prev) => prev + step1);
        setUser2Position((prev) => prev + step2);
        count++;
        setTimeout(animate, frameDuration);
      } else {
        setUser1Position(final1);
        setUser2Position(final2);
      }
    };
  
    animate();
  }, [user1Pressure, user2Pressure, user1Metrics, user2Metrics]);
  

  const handleFetchContent = async (u1_status, u2_status) => {
    const response = await fetchInsightContent(
      "insight",
      relation_id,
      "Personality Module",
      u1_status,
      u2_status
    );
    setInsightContent(response || "Failed to fetch content.");
  };

  const isClose = (value1, value2) => Math.abs(value1 - value2) <= 10;

  const user1PrimaryMetrics = user1Metrics && user1Metrics.primary;
  const user2PrimaryMetrics = user2Metrics && user2Metrics.primary;
  const user1PressureMetrics = user1Metrics && user1Metrics.underpressure;
  const user2PressureMetrics = user2Metrics && user2Metrics.underpressure;

  // Function to check if positions are close
  const arePositionsClose = () => Math.abs(user1Position - user2Position) <=10;

  // Create combined name when positions are close
  const getCombinedNameDisplay = () => {
    return `${name1} & ${name2}`;
  };

  return (
    <div className="personality-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button className="backbutton-insights" onClick={onBack}>
          <Backbutton /> Back
        </button>
        <div className="personality-heading">Personality</div>
        <div>
          <InfoTooltip content={tooltip} />
        </div>
      </div>

      <div className="insight-people-container">
        {/* User 1 */}
        <div className="insight-people">
          {profilePic1 ? (
            <img
              src={profilePic1}
              alt={initials1 || "Profile"}
              style={{ width: "40px", height: "40px" }}
            />
          ) : (
            <div
              className="profile-initials"
              style={{ width: "40px", height: "40px" }}
            >
              {initials1 || ""}
            </div>
          )}
          {name1}
          <p className="set-pressure">Set as under pressure</p>
          <label className="myprofile-switch">
            <input
              type="checkbox"
              className="myprofile-checkbox"
              checked={user1Pressure}
              onChange={(e) => {
                const newStatus = e.target.checked;
                setUser1Pressure(newStatus);
                handleFetchContent(newStatus, user2Pressure);
              }}
            />
            <span className="myprofile-slider"></span>
          </label>
        </div>

        {/* User 2 */}
        <div className="insight-people">
          {profilePic2 ? (
            <img
              src={profilePic2}
              alt={initials2 || "Profile"}
              style={{ width: "40px", height: "40px" }}
            />
          ) : (
            <div
              className="profile-initials"
              style={{ width: "40px", height: "40px" }}
            >
              {initials2 || ""}
            </div>
          )}
          {name2}
          <p className="set-pressure">Set as under pressure</p>
          <label className="myprofile-switch">
            <input
              type="checkbox"
              className="myprofile-checkbox"
              checked={user2Pressure}
              onChange={(e) => {
                const newStatus = e.target.checked;
                setUser2Pressure(newStatus);
                handleFetchContent(user1Pressure, newStatus);
              }}
            />
            <span className="myprofile-slider"></span>
          </label>
        </div>
      </div>

      <div className="insight-progress-container">
        <div className="insight-progress-info-container">
          <div>Big Picture</div>
          <div>Detail Oriented</div>
        </div>
        <div className="insight-progress-line"></div>
        <div className="insight-progress-line-inner"></div>
        <div
          className="insight-progress-line-inner"
          style={{
            height: "10%",
            left: "20%",
            transform: "translateY(420%)",
          }}
        ></div>
        <div
          className="insight-progress-line-inner"
          style={{
            height: "10%",
            left: "40%",
            transform: "translateY(420%)",
          }}
        ></div>
        <div
          className="insight-progress-line-inner"
          style={{
            height: "10%",
            left: "60%",
            transform: "translateY(420%)",
          }}
        ></div>
        <div
          className="insight-progress-line-inner"
          style={{ left: "80%" }}
        ></div>

        {/* Combined name indicator when profiles are close */}
        {user1Metrics && user2Metrics && isClose(
          user1Pressure
            ? user1PressureMetrics.detail_oriented
            : user1PrimaryMetrics.detail_oriented,
          user2Pressure
            ? user2PressureMetrics.detail_oriented
            : user2PrimaryMetrics.detail_oriented
        ) && (
          <div
            className="insight-person-container combined-names"
            style={{ 
              left: `${(user1Position + user2Position) / 2}%`, 
              transition: "left 1s ease-out",
              zIndex: 10
            }}
          >
            <div className="combined-profile-pics" style={{ display: "flex", gap: "2px" }}>
              {profilePic1 ? (
                <img
                  src={profilePic1}
                  alt={initials1 || "Profile"}
                  style={{ width: "24px", height: "24px", fontSize: "10px" }}
                />
              ) : (
                <div
                  className="profile-initials"
                  style={{ width: "24px", height: "24px", fontSize: "10px" }}
                >
                  {initials1 || ""}
                </div>
              )}
              
              {profilePic2 ? (
                <img
                  src={profilePic2}
                  alt={initials2 || "Profile"}
                  style={{ width: "24px", height: "24px", fontSize: "10px" }}
                />
              ) : (
                <div
                  className="profile-initials"
                  style={{ width: "24px", height: "24px", fontSize: "10px" }}
                >
                  {initials2 || ""}
                </div>
              )}
            </div>
            {getCombinedNameDisplay()}
          </div>
        )}

        {/* User 1 profile (only show if not close to user 2) */}
        {user1Metrics && !isClose(
          user1Pressure
            ? user1PressureMetrics.detail_oriented
            : user1PrimaryMetrics.detail_oriented,
          user2Metrics &&
            (user2Pressure
              ? user2PressureMetrics.detail_oriented
              : user2PrimaryMetrics.detail_oriented)
        ) && (
          <div
            className="insight-person-container"
            style={{ left: `${user1Position}%`, transition: "left 1s ease-out" }}
          >
            {profilePic1 ? (
              <img
                src={profilePic1}
                alt={initials1 || "Profile"}
                style={{ width: "24px", height: "24px", fontSize: "10px" }}
                onError={(e) => {
                  e.target.style.display = "none";
                  e.target.nextElementSibling.style.display = "flex";
                }}
              />
            ) : (
              <div
                className="profile-initials"
                style={{ width: "24px", height: "24px", fontSize: "10px" }}
              >
                {initials1 || ""}
              </div>
            )}
            {name1}
          </div>
        )}

        {/* User 2 profile (only show if not close to user 1) */}
        {user2Metrics && !isClose(
          user1Pressure
            ? user1PressureMetrics.detail_oriented
            : user1PrimaryMetrics.detail_oriented,
          user2Metrics &&
            (user2Pressure
              ? user2PressureMetrics.detail_oriented
              : user2PrimaryMetrics.detail_oriented)
        ) && (
          <div
            className="insight-person-container"
            style={{ left: `${user2Position}%`, transition: "left 1s ease-out" }}
          >
            {profilePic2 ? (
              <img
                src={profilePic2}
                alt={initials2 || "Profile"}
                style={{ width: "24px", height: "24px", fontSize: "10px" }}
                onError={(e) => {
                  e.target.style.display = "none";
                  e.target.nextElementSibling.style.display = "flex";
                }}
              />
            ) : (
              <div
                className="profile-initials"
                style={{ width: "24px", height: "24px", fontSize: "10px" }}
              >
                {initials2 || ""}
              </div>
            )}
            {name2}
          </div>
        )}
      </div>


      <div className="insight-progress-container">
        <div className="insight-progress-info-container">
          <div>Results Driven</div>
          <div>People Driven</div>
        </div>
        <div className="insight-progress-line"></div>
        <div className="insight-progress-line-inner"></div>
        <div
          className="insight-progress-line-inner"
          style={{
            height: "10%",
            left: "20%",
            transform: "translateY(420%)",
          }}
        ></div>
        <div
          className="insight-progress-line-inner"
          style={{
            height: "10%",
            left: "40%",
            transform: "translateY(420%)",
          }}
        ></div>
        <div
          className="insight-progress-line-inner"
          style={{
            height: "10%",
            left: "60%",
            transform: "translateY(420%)",
          }}
        ></div>
        <div
          className="insight-progress-line-inner"
          style={{ left: "80%" }}
        ></div>

        {/* Combined name indicator when profiles are close */}
        {user1Metrics && user2Metrics && arePositionsClose() && (
          <div
            className="insight-person-container combined-names"
            style={{ 
              left: `${(user1Position + user2Position) / 2}%`, 
              transition: "left 1s ease-out",
              zIndex: 10
            }}
          >
            <div className="combined-profile-pics" style={{ display: "flex", gap: "2px" }}>
              {profilePic1 ? (
                <img
                  src={profilePic1}
                  alt={initials1 || "Profile"}
                  style={{ width: "24px", height: "24px", fontSize: "10px" }}
                />
              ) : (
                <div
                  className="profile-initials"
                  style={{ width: "24px", height: "24px", fontSize: "10px" }}
                >
                  {initials1 || ""}
                </div>
              )}
              
              {profilePic2 ? (
                <img
                  src={profilePic2}
                  alt={initials2 || "Profile"}
                  style={{ width: "24px", height: "24px", fontSize: "10px" }}
                />
              ) : (
                <div
                  className="profile-initials"
                  style={{ width: "24px", height: "24px", fontSize: "10px" }}
                >
                  {initials2 || ""}
                </div>
              )}
            </div>
            {getCombinedNameDisplay()}
          </div>
        )}

        {/* User 1 profile (only show if not close to user 2) */}
        {user1Metrics && !arePositionsClose() && (
          <div
            className="insight-person-container"
            style={{
              left: `${user1Position}%`,
              transition: "left 1s ease-out",
            }}
          >
            {profilePic1 ? (
              <img
                src={profilePic1}
                alt={initials1 || "Profile"}
                style={{ width: "24px", height: "24px", fontSize: "10px" }}
                onError={(e) => {
                  e.target.style.display = "none";
                  e.target.nextElementSibling.style.display = "flex";
                }}
              />
            ) : (
              <div
                className="profile-initials"
                style={{ width: "24px", height: "24px", fontSize: "10px" }}
              >
                {initials1 || ""}
              </div>
            )}
            {name1}
          </div>
        )}

        {/* User 2 profile (only show if not close to user 1) */}
        {user2Metrics && !arePositionsClose() && (
          <div
            className="insight-person-container"
            style={{
              left: `${user2Position}%`,
              transition: "left 1s ease-out",
            }}
          >
            {profilePic2 ? (
              <img
                src={profilePic2}
                alt={initials2 || "Profile"}
                style={{ width: "24px", height: "24px", fontSize: "10px" }}
                onError={(e) => {
                  e.target.style.display = "none";
                  e.target.nextElementSibling.style.display = "flex";
                }}
              />
            ) : (
              <div
                className="profile-initials"
                style={{ width: "24px", height: "24px", fontSize: "10px" }}
              >
                {initials2 || ""}
              </div>
            )}
            {name2}
          </div>
        )}
      </div>

      <div>
        <div className="personality-content">
          <div style={{ textAlign: "left" }}>
            <ReactMarkdown
              components={{
                h1: "h3",
                h2: "h4",
              }}
            >
              {data?.replace(/\n/g, "\n\n") || ""}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
}
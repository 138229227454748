import React, { useEffect, useState } from "react";
import GlitteringPolygon from "../../Components/Charts/GlitteringPolygon";
import Personality1 from "../../Components/StoryAnimations/Personality1";
import Personality2 from "../../Components/StoryAnimations/Personality2";
import Personality3 from "../../Components/StoryAnimations/Personality3";
import Personality4 from "../../Components/StoryAnimations/Personality4";
import Personality5 from "../../Components/StoryAnimations/Personality5";
import Personality6 from "../../Components/StoryAnimations/Personality6";
import Personality7 from "../../Components/StoryAnimations/Personality7";
import Personality8 from "../../Components/StoryAnimations/Personality8";
import Personality9 from "../../Components/StoryAnimations/Personality9";
import Personality10 from "../../Components/StoryAnimations/Personality10";
import Personality11 from "../../Components/StoryAnimations/Personality11";
import Personality12 from "../../Components/StoryAnimations/Personality12";
import Personality13 from "../../Components/StoryAnimations/Personality13";
import Personality14 from "../../Components/StoryAnimations/Personality14";
import Personality15 from "../../Components/StoryAnimations/Personality15";
import Personality16 from "../../Components/StoryAnimations/Personality16";
import Personality0GraphGlower from "../../Components/Personality0GraphGlower/Personality0GraphGlower";

function GlitterPolyGraph({ duration }) {
  const [showFirstPart, setShowFirstPart] = useState(true);

  useEffect(() => {
    const firstPartDuration = (duration * 50) / 100;
    const timer = setTimeout(() => {
      setShowFirstPart(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, [duration]);

  const animationDrafts = [
    { id: 1, animation: <Personality1 /> },
    { id: 2, animation: <Personality2 /> },
    { id: 3, animation: <Personality3 /> },
    { id: 4, animation: <Personality4 /> },
    { id: 5, animation: <Personality5 /> },
    { id: 6, animation: <Personality6 /> },
    { id: 7, animation: <Personality7 /> },
    { id: 8, animation: <Personality8 /> },
    { id: 9, animation: <Personality9 /> },
    { id: 10, animation: <Personality10 /> },
    { id: 11, animation: <Personality11 /> },
    { id: 12, animation: <Personality12 /> },
    { id: 13, animation: <Personality13 /> },
    { id: 14, animation: <Personality14 /> },
    { id: 15, animation: <Personality15 /> },
    { id: 16, animation: <Personality16 /> },
  ];

  return (
    <div>
      {showFirstPart ? (
        <div className="image-grid-story">
          {animationDrafts?.map((anim) => (
            <React.Fragment key={anim.id}>{anim.animation}</React.Fragment>
          ))}
        </div>
      ) : (
        <Personality0GraphGlower duration={(duration * 70) / 100} />
      )}
    </div>
  );
}

export default GlitterPolyGraph;

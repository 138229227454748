import React, { useEffect, useState } from "react";
import "./style.css";
import PotraitsSummaryWithList from "../../Components/PotraitsSummaryWithList/PotraitsSummaryWithList";

import PotraitsCommonHeadBottom from "./PotraitsCommonHeadBottom";
import TopStrength from "./TopStrength";
import { extractPercentages } from "../../Utils/CommonFunctions/graph";
import GradientGraphVertical from "../../Components/PotraitsGraph/ProcessingGraph";

// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";

function Processing({ chartData, otherData }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    console.log("personality-re-rendering");

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const data3 = [
    {
      topLabel: "External",
      bottomLabel: "Internal",
      segments: [
        { percent: chartData?.Internal || 0, colors: ["#6ECFB8", "#A8EE93"] },
        { percent: chartData?.External || 0, colors: ["#6ECFB8", "#A8EE93"] },
      ],
    },
    {
      topLabel: "Concrete",
      bottomLabel: "Intuitive",
      segments: [
        { percent: chartData?.Intuitive || 0, colors: ["#6ECFB8", "#A8EE93"] },
        { percent: chartData?.Concrete || 0, colors: ["#6ECFB8", "#A8EE93"] },
      ],
    },
    {
      topLabel: "Logic",
      bottomLabel: "Emotion",
      segments: [
        {
          percent: chartData?.Emotion || 0,
          colors: ["#6ECFB8", "#A8EE93"],
        },
        {
          percent: chartData?.Logic || 0,
          colors: ["#6ECFB8", "#A8EE93"],
        },
      ],
    },
    {
      topLabel: "Orderly",
      bottomLabel: "Spontaneous",
      segments: [
        {
          percent: chartData?.Spontaneous || 0,
          colors: ["#6ECFB8", "#A8EE93"],
        },
        { percent: chartData?.Orderly || 0, colors: ["#6ECFB8", "#A8EE93"] },
      ],
    },
  ];

  // jwtDecode;
  // let token = Cookies.get("idtoken" || "") || "";
  // let nameFromToken = token ? jwtDecode(token)?.name : "name";
  console.log(
    chartData,
    extractPercentages(
      otherData?.portraitMatrixMap?.OrderlySpontaneous?.title
    )?.[0] + "%",
    "chartData"
  );
  return (
    <div className="personality-hero-container">
      <div className="potraits-tab-header-txt">Processing</div>
      <div className="potraits-graph-summary-cntr">
        {/* <img src={graph} alt="graph" className="potraits-graph-img" /> */}
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Urbanist",
            color: "#7E7F83",
            fontSize: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: screenWidth < 580 ? 325 : screenWidth > 1840 ? 500 : 400,
              marginRight: "16px",
            }}
          >
            <div>100%</div>
            <div>0%</div>
            <div>100%</div>
          </div>
          <GradientGraphVertical data={data3} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: screenWidth < 580 ? 325 : screenWidth > 1840 ? 500 : 400,
            }}
          >
            <div>100%</div>
            <div>0%</div>
            <div>100%</div>
          </div>
        </div>
        {/* <div className="potraits-separater-line"></div> */}
        <div className="potraits-graph-summary-txt-cntr">
          <div className="potraits-content-head-txt">Summary</div>
          <div className="potraits-sub-txt">{otherData?.summary?.description}</div>
        </div>
      </div>
      <div className="personality-other-data-container">
        <PotraitsCommonHeadBottom
          heading={otherData?.portraitMatrixMap?.ExternalInternal?.title?.description?.replace(
            /\n/g,
            " - "
          )}
          summary={otherData?.portraitMatrixMap?.ExternalInternal?.bandCopy?.description}
        />
        <div className="potraits-percent-summary-hero-cntr">
          <PotraitsSummaryWithList
            percentail={
              extractPercentages(
                otherData?.portraitMatrixMap?.ExternalInternal?.title?.description
              )?.[0] + "%"
            }
            ptxt={"Internal"}
            // summary={otherData?.card1?.text?.para1}
            list={
              otherData?.portraitMatrixMap?.ExternalInternal?.traits?.Internal?.description
            }
          />
          <PotraitsSummaryWithList
            percentail={
              extractPercentages(
                otherData?.portraitMatrixMap?.ExternalInternal?.title?.description
              )?.[1] + "%"
            }
            ptxt={"External"}
            // summary={otherData?.card1?.text?.para1}
            list={
              otherData?.portraitMatrixMap?.ExternalInternal?.traits?.External?.description
            }
          />
{/* 
          <PotraitsSummaryWithList
          percentail={otherData?.card3?.percentage + "%"}
          ptxt={otherData?.card3?.label}
          summary={otherData?.card3?.text?.para1}
          list={otherData?.card3?.text?.options?.items}
        /> */}
        </div>
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.ExternalInternal?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.ExternalInternal?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.ExternalInternal?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.ExternalInternal?.potentialBlindSpotsToolTip
          }
          isBlind={true}
        />
      </div>
      <div className="personality-other-data-container">
        <PotraitsCommonHeadBottom
          heading={otherData?.portraitMatrixMap?.OrderlySpontaneous?.title?.description?.replace(
            /\n/g,
            " - "
          )}
          summary={otherData?.portraitMatrixMap?.OrderlySpontaneous?.bandCopy?.description}
        />
        <div className="potraits-percent-summary-hero-cntr">
          <PotraitsSummaryWithList
            percentail={
              extractPercentages(
                otherData?.portraitMatrixMap?.OrderlySpontaneous?.title?.description
              )?.[1] + "%"
            }
            ptxt={"Spontaneous"}
            // summary={otherData?.card1?.text?.para1}
            list={
              otherData?.portraitMatrixMap?.OrderlySpontaneous?.traits
                ?.Spontaneous?.description
            }
          />
          <PotraitsSummaryWithList
            percentail={
              extractPercentages(
                otherData?.portraitMatrixMap?.OrderlySpontaneous?.title?.description
              )?.[0] + "%"
            }
            ptxt={"Orderly"}
            // summary={otherData?.card1?.text?.para1}
            list={
              otherData?.portraitMatrixMap?.OrderlySpontaneous?.traits?.Orderly?.description
            }
          />

          {/* <PotraitsSummaryWithList
          percentail={otherData?.card3?.percentage + "%"}
          ptxt={otherData?.card3?.label}
          summary={otherData?.card3?.text?.para1}
          list={otherData?.card3?.text?.options?.items}
        /> */}
        </div>
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.OrderlySpontaneous?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.OrderlySpontaneous?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.OrderlySpontaneous
              ?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.OrderlySpontaneous
              ?.potentialBlindSpotsToolTip
          }
          isBlind={true}
        />
      </div>
      <div className="personality-other-data-container">
        <PotraitsCommonHeadBottom
          heading={otherData?.portraitMatrixMap?.AffectiveCognitive?.title?.description?.replace(
            /\n/g,
            " - "
          )}
          summary={otherData?.portraitMatrixMap?.AffectiveCognitive?.bandCopy?.description}
        />
        <div className="potraits-percent-summary-hero-cntr">
          <PotraitsSummaryWithList
            percentail={
              extractPercentages(
                otherData?.portraitMatrixMap?.AffectiveCognitive?.title?.description
              )?.[0] + "%"
            }
            ptxt={"Logic"}
            // summary={otherData?.card1?.text?.para1}
            list={
              otherData?.portraitMatrixMap?.AffectiveCognitive?.traits
                ?.Affective?.description
            }
          />
          <PotraitsSummaryWithList
            percentail={
              extractPercentages(
                otherData?.portraitMatrixMap?.AffectiveCognitive?.title?.description
              )?.[1] + "%"
            }
            ptxt={"Emotion"}
            // summary={otherData?.card1?.text?.para1}
            list={
              otherData?.portraitMatrixMap?.AffectiveCognitive?.traits
                ?.Cognitive?.description
            }
          />

          {/* <PotraitsSummaryWithList
          percentail={otherData?.card3?.percentage + "%"}
          ptxt={otherData?.card3?.label}
          summary={otherData?.card3?.text?.para1}
          list={otherData?.card3?.text?.options?.items}
        /> */}
        </div>
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.AffectiveCognitive?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.AffectiveCognitive?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.AffectiveCognitive
              ?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.AffectiveCognitive
              ?.potentialBlindSpotsToolTip
          }
          isBlind={true}
        />
      </div>
      <div className="personality-other-data-container">
        <PotraitsCommonHeadBottom
          heading={otherData?.portraitMatrixMap?.ConcreteIntuitive?.title?.description?.replace(
            /\n/g,
            " - "
          )}
          summary={otherData?.portraitMatrixMap?.ConcreteIntuitive?.bandCopy?.description}
        />
        <div className="potraits-percent-summary-hero-cntr">
          <PotraitsSummaryWithList
            percentail={
              extractPercentages(
                otherData?.portraitMatrixMap?.ConcreteIntuitive?.title?.description
              )?.[0] + "%"
            }
            ptxt={"Concrete"}
            // summary={otherData?.card1?.text?.para1}
            list={
              otherData?.portraitMatrixMap?.ConcreteIntuitive?.traits?.Concrete?.description
            }
          />
          <PotraitsSummaryWithList
            percentail={
              extractPercentages(
                otherData?.portraitMatrixMap?.ConcreteIntuitive?.title?.description
              )?.[1] + "%"
            }
            ptxt={"Intuitive"}
            // summary={otherData?.card1?.text?.para1}
            list={
              otherData?.portraitMatrixMap?.ConcreteIntuitive?.traits?.Intuitive?.description
            }
          />

          {/* <PotraitsSummaryWithList
          percentail={otherData?.card3?.percentage + "%"}
          ptxt={otherData?.card3?.label}
          summary={otherData?.card3?.text?.para1}
          list={otherData?.card3?.text?.options?.items}
        /> */}
        </div>
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.ConcreteIntuitive?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.ConcreteIntuitive?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap?.ConcreteIntuitive?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap?.ConcreteIntuitive
              ?.potentialBlindSpotsToolTip
          }
          isBlind={true}
        />
      </div>
    </div>
  );
}

export default Processing;

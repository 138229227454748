import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import rightIcon from "../../Assets/icon/chevron-right.svg";
import Allset from "../../Components/AllsetComponent";
import ConfirmationModal from "./ManageMembershipCancel";
import "./style.css";
import { useSearchParams } from "react-router-dom";

const AnnualMembershipList = ({ isTakeOwnership, setisTakeOwnership ,contact}) => {

  const [searchParams] = useSearchParams();
  const ispurchased = searchParams.get("ispurchased");
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isAllSet, setIsAllSet] = useState(false);

  const openCancelMembershipModal = () => {
    setisTakeOwnership(false);
    setIsCancelModal(true);
  };

  const closeCancelMembershipModal = () => {
    setIsCancelModal(false);
    setisTakeOwnership(true);
  };

  const handleCancelMembership = () => {
    setIsAllSet(true);
    setIsCancelModal(false);
  };

  const options = [
    {
      label: "Take ownership of my account",
      sublabel:
        "Become the primary account holder. You'll take over subscription management and all future payments.",
    },

    { label: "Delete account", onClick: openCancelMembershipModal },
  ];

  return (
    <>
      {isTakeOwnership && (
        <div className="membershipmodal-overlay">
          <div className="membershipmodal-container">
            <div className="membershipmodal-header" style={{ margin: "0px" }}>
              <h2 className="membershipmodal-heading">Aris</h2>
              <button
                className="membershipclose-btn"
                onClick={() => setisTakeOwnership(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <div
              className="membership-arist2"
              style={{ textAlign: "left", margin: "0px" }}
            >
              Annual membership
            </div>
            <div className="manage-modal-content membership-payment">
              {options.map((option, index) => (
                <button
                  key={index}
                  className="manage-option-button membership-btn-txt"
                  onClick={option.onClick}
                >
                  <div>
                    <div>{option.label}</div>
                    <div className="takeownership-sublabel">
                      {option.sublabel}
                    </div>
                  </div>
                  <img
                    src={rightIcon}
                    alt=""
                    className="manage-rightarrowicon"
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      <ConfirmationModal
        isOpen={isCancelModal}
        onClose={closeCancelMembershipModal}
        onConfirm={handleCancelMembership}
        heading="Are you sure you want to take over ownership of the account?"
        subheading="By taking over account ownership, you'll become the primary account holder. This includes full responsibility for managing the subscription and handling all future payments. You will be asked to provide payment information to complete this transfer."
        confirmButtonText="Yes, Proceed"
        cancelButtonText="Back"
      />

      {/* {isAllSet && (
        <Allset
          closeModal={() => {
            setIsAllSet(false);
            setisTakeOwnership(false);
          }}
          heading={ispurchased === "confirm" ? "You're All set": "Oops!"}
          subText={ispurchased ==="confirm "?`The request was sent to ${contact.first_name}`:"Something went wrong!"}
          isFailure={ispurchased === "confirm" ? false : true}
        />
      )} */}
    </>
  );
};

export default AnnualMembershipList;

import React from "react";
import "./style.css";
function ChatContacts({ img, initial, name, onClick }) {
  return (
    <div className="chat-contacts-hero-container" onClick={onClick}>
      {img ? (
        <img src={img} alt="user-img" className="chat-contact-img" />
      ) : (
        <div className="chat-contacts-initial">{initial}</div>
      )}
      <div className="chat-contacts-name">{name}</div>
    </div>
  );
}

export default ChatContacts;

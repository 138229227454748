import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import nextbtn from "../../Assets/images/arrow-right.png";
import defaultProfile from "../../Assets/images/placeholder.png";
import {
  editRelationship,
  deleteRelationship,
  uploadFiles,
  fetchCategoriesAndTypes,
} from "../../Services/Realtionship";
import PhoneInput from "react-phone-number-input";
import {
  getCountryCallingCode,
  parsePhoneNumberFromString,
  getCountries,
} from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";

const NAME_REGEX = /^[A-Za-z]+$/;

const EditRelationship = ({
  contact,
  onBack,
  onUpdateContact,
  onDeleteContact,
  navigateBack,
  onClose,
}) => {
  console.log("contact_edit", contact);
  const fileInputRef = useRef(null);
  const initialFormData = {
    first_name: contact.first_name || "",
    last_name: contact.last_name || "",
    relationship_type: contact.user1_to_user2?.relationship_type || "",
    relationship_length: contact.user1_to_user2?.relationship_length || "",
    relationship_category: contact.user1_to_user2?.relationship_category || "",
    relationship_status: contact.user1_to_user2?.relationship_status || "",
    email: contact.user1_to_user2?.email || "",
    country_code: contact.user1_to_user2.mobile?.country_code || "",
    contact: contact.user1_to_user2.mobile?.contact || "",
    profile_pic_url: contact.profile_pic_url || "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isEdited, setIsEdited] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(contact.profile_pic_url);
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [relationTypes, setRelationTypes] = useState([]);
  const [relationLengths, setRelationLengths] = useState([]);
  const [relationStatuses, setRelationStatuses] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const categoriesData = await fetchCategoriesAndTypes();
        setCategories(Array.isArray(categoriesData) ? categoriesData : []);

        const lengthsData = await fetchCategoriesAndTypes("length");
        setRelationLengths(Array.isArray(lengthsData) ? lengthsData : []);

        const statusesData = await fetchCategoriesAndTypes("status");
        setRelationStatuses(Array.isArray(statusesData) ? statusesData : []);

        if (initialFormData.relationship_category) {
          const types = await fetchCategoriesAndTypes(
            initialFormData.relationship_category
          );
          setRelationTypes(Array.isArray(types) ? types : []);
        }

        if (contact.relation_status !== "nonExist") {
          setIsEditable(false);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    setIsEdited(
      JSON.stringify(formData) !== JSON.stringify(initialFormData) ||
        selectedFile !== null
    );
  }, [formData, selectedFile]);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const errors = {};
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "first_name") {
      if (!value) {
        errors.first_name = "First name is required";
      } else if (value !== value?.trim()) {
        errors.first_name = "Name should not begin or end with spaces";
      } else if (!NAME_REGEX.test(value)) {
        errors.first_name = "Name should only contain letters";
      } else if (value) {
        errors.first_name = "";
      }
    }

    if (name === "last_name") {
      if (value) {
        if (value !== value?.trim()) {
          errors.last_name = "Name should not begin or end with spaces";
        } else if (!NAME_REGEX.test(value)) {
          errors.last_name = "Name should only contain letters";
        } else if (value) {
          errors.last_name = "";
        }
      }
    }

    // Last name validation (optional field)

    setValidationErrors({
      ...validationErrors,
      ...errors,
    });

    if (name === "relationship_category") {
      try {
        const response = await fetchCategoriesAndTypes(value);
        if (Array.isArray(response)) {
          setRelationTypes(response);
          setFormData((prev) => ({
            ...prev,
            relationship_type: "",
          }));
        }
      } catch (error) {
        console.error("Error fetching relationship types:", error);
      }
    }
  };

  const handlePhoneChange = (value) => {
    if (!value) {
      setFormData((prev) => ({
        ...prev,
        country_code: "",
        contact: "",
      }));
      return;
    }

    const phoneNumber = parsePhoneNumberFromString(value);
    if (phoneNumber) {
      setFormData((prev) => ({
        ...prev,
        country_code: "+" + phoneNumber.countryCallingCode,
        contact: phoneNumber.nationalNumber,
      }));
    }
    console.log("formData", formData);
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      alert("Please upload a valid image file (JPEG, PNG)");
      return;
    }

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      alert("File size should be less than 5MB");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => setPreviewImage(reader.result);
    reader.readAsDataURL(file);

    const uploadResponse = await uploadFiles(contact._id, file, null);
    if (uploadResponse?.image_url) {
      setFormData((prev) => ({
        ...prev,
        profile_pic_url: uploadResponse.image_url,
      }));

      onUpdateContact?.({
        ...contact,
        profile_pic_url: uploadResponse.image_url,
      });
    }
    setSelectedFile(file);
    setIsEdited(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const relationshipData = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        relationship_type: formData.relationship_type,
        relationship_length: formData.relationship_length,
        relationship_category: formData.relationship_category,
        relationship_status: formData.relationship_status,
        email: formData.email || null,
        country_code: formData.country_code || null,
        contact: formData.contact || null,
        profile_pic_url: formData.profile_pic_url,
      };

      await editRelationship(contact._id, relationshipData);
      console.log("relationshipData", relationshipData);

      onUpdateContact?.({
        ...contact,
        ...relationshipData,
      });

      console.log("Relationship updated successfully");
      onBack();
    } catch (error) {
      console.error("Error updating relationship:", error.message);
    }
  };

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteRelationship(contact._id);
      if (navigateBack) {
        navigate("/myprofile");
      }
      console.log("Relationship deleted successfully");

      if (onDeleteContact) {
        onDeleteContact(contact.id);
      }

      setIsModalOpen(false);
      onBack();
    } catch (error) {
      console.error("Error deleting relationship:", error);
    }
  };

  const renderProfileContent = () => {
    if (previewImage) {
      return (
        <div className="edit-profile-image">
          <img
            src={previewImage}
            alt={contact.first_name + contact.last_name}
            className="profile-picture"
            onError={(e) => {
              setPreviewImage(null);
              e.target.onerror = null;
            }}
          />
        </div>
      );
    } else if (contact.initials) {
      return <div className="edit-profile-initials">{contact.initials}</div>;
    }
    return (
      <img
        src={defaultProfile}
        alt="default profile"
        className="default-profile"
      />
    );
  };

  console.log(formData, "type");
  console.log("condition", !isEdited);
  return (
    <div>
      <div className="sendreq-close-container">
        <CloseIcon
          style={{
            textAlign: "right",
            cursor: "pointer",
          }}
          onClick={onBack}
        />
      </div>
      <div>
        <div className="edit-profile-image-container">
          <div style={{ cursor: "pointer" }} onClick={handleImageClick}>
            {renderProfileContent()}
          </div>
          <span
            className="edit-photo-text"
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          >
            Edit photo
          </span>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </div>
      </div>

      <form onSubmit={handleSubmit} className="edit-form">
        <div className="edit-form-grid">
          <div className="edit-form-field">
            <label>First Name</label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              required
              disabled={!isEditable} // Disable based on status
              style={{
                backgroundColor: !isEditable ? "#f0f0f0" : "",
                cursor: !isEditable ? "not-allowed" : "",
              }}
            />
            {validationErrors.first_name && (
              <p className="error-message name-align-left">
                {validationErrors.first_name}
              </p>
            )}
          </div>
          <div className="edit-form-field">
            <label>Last Name (optional)</label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              disabled={!isEditable} // Disable based on status
              style={{
                backgroundColor: !isEditable ? "#f0f0f0" : "",
                cursor: !isEditable ? "not-allowed" : "",
              }}
            />
            {validationErrors.last_name && (
              <p className="error-message name-align-left">
                {validationErrors.last_name}
              </p>
            )}
          </div>

          <div className="form-group ">
            <label>Relationship Category</label>
            <select
              name="relationship_category"
              value={formData.relationship_category}
              onChange={handleInputChange}
            >
              {/* <option value="">{formData.relationship_category}</option> */}
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group ">
            <label>Relationship Length</label>
            <select
              name="relationship_length"
              value={formData.relationship_length}
              onChange={handleInputChange}
            >
              {/* <option value="">{formData.relationship_length}</option> */}
              {relationLengths.map((length) => (
                <option key={length} value={length}>
                  {length}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group ">
            <label>Relationship Type</label>
            <select
              name="relationship_type"
              value={formData.relationship_type}
              onChange={handleInputChange}
              disabled={!formData.relationship_category}
            >
              <option value="">Select Type</option>
              {relationTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Relationship Status</label>
            <select
              name="relationship_status"
              value={formData.relationship_status}
              onChange={handleInputChange}
            >
              {/* <option value="">{formData.relationship_status}</option> */}
              {relationStatuses.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>

          <div className="edit-form-field">
            <label>Email (optional)</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              disabled={!isEditable} // Disable based on status
              style={{
                backgroundColor: !isEditable ? "#f0f0f0" : "",
                cursor: !isEditable ? "not-allowed" : "",
              }}
            />
          </div>
          <div className="form-group-mobile">
            <div style={{ marginBottom: "6px" }}>
              {" "}
              <label htmlFor="" className="mobile-label-addrelation">
                Mobile Number{" "}
              </label>
              <span className="mobile-label-addrelation">(optional)</span>
            </div>
            <PhoneInput
              id="phone-number"
              international
              defaultCountry="US"
              value={formData.country_code + formData.contact}
              onChange={handlePhoneChange}
              className="addrelationship-phoneinput"
              placeholder="Enter phone number"
              autoFocus={false}
              disabled={!isEditable} // Disable based on status
              style={{
                backgroundColor: !isEditable ? "#f0f0f0" : "",
                cursor: !isEditable ? "not-allowed" : "",
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "left",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button
            type="submit"
            className={`edit-person-btn ${
              isEdited && formData.relationship_type !== "" ? "filled" : ""
            }`}
            disabled={!isEdited || formData.relationship_type === ""}
          >
            Update Relationship
            <img src={nextbtn} alt="next" className="nxtbtn" />
          </button>

          <button
            type="button"
            className="edit-delete-button"
            onClick={handleDeleteClick}
          >
            Delete Relationship
          </button>
        </div>
      </form>

      {isModalOpen && (
        <div className="dlt-modal-overlay">
          <div className="dlt-modal-content">
            <div className="dlt-modal-content-header">
              <h3>Are you sure you want to delete this relationship?</h3>
              <button
                className="dlt-modal-close-btn"
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </button>
            </div>
            <p className="dlt-modal-content-para">
              You won't be able to get advice on this person anymore.
            </p>
            <div className="dlt-modal-actions">
              <button
                className="dlt-modal-cancel-btn"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                className="dlt-modal-confirm-btn"
                onClick={handleConfirmDelete}
              >
                Yes, Remove
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditRelationship;

import React, { useEffect, useState } from "react";
import "./style.css";
import Check from "../../Assets/images/Check.png";
import { memo } from "react";

function AssessmentRadioButton({ options, onSubmit, id, answerId }) {
  const [selectedOption, setselectedOption] = useState("");
  const [selectedAnswerIdFromAPI, setselectedAnswerIdFromAPI] = useState("");

  //to pass the selected option to parent component
  useEffect(() => {
    onSubmit([selectedOption]);
  }, [selectedOption]);

  useEffect(() => {
    setselectedOption();
    if (answerId && answerId?.length) {
      const answer = answerId?.toString();
      // setselectedOption(answer)
      setselectedAnswerIdFromAPI(answer);
    }
  }, [id]);

  useEffect(() => {
    if (selectedOption && selectedOption.length > 0)
      setselectedAnswerIdFromAPI("");
  }, [selectedOption]);

  //function to handle the change in radio button
  const handleOptionChange = (element) => {
    setselectedOption(element?.id);
  };

  // console.log(selectedOption, selectedAnswerIdFromAPI, "selectedOption");

  return (
    <div className="assess-options-container ">
      {options?.map((option, index) => (
        <div
          className={
            "assess-options" +
            (selectedOption === option?.id ||
            selectedAnswerIdFromAPI === option?.id
              ? " assess-options-active"
              : "")
          }
          id={option?.id}
          key={option?.id}
          onClick={() => handleOptionChange(option)}
        >
          {option?.text}

          {/* <input
            type={"radio"}
            checked={selectedOption === option}
            value={option}
            onChange={handleOptionChange}
            className="hover-pointer"
          /> */}
          <div
            className={
              "custom-radio hover-pointer" +
              (selectedOption === option?.id ||
              selectedAnswerIdFromAPI === option?.id
                ? " checked"
                : "")
            }
          >
            <img src={Check} alt={"&#10003"} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default memo(AssessmentRadioButton);

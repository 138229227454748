import React, { useRef, useEffect, useState, memo } from "react";
import * as d3 from "d3";

const DonutChart = ({ chart, isStory }) => {
  const svgRef = useRef();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Chart data
    const data = [
      { label: "Significance", value: chart?.Significance, color: "#F4C908" },
      { label: "Security", value: chart?.Security, color: "#F6B7B0" },
      { label: "Control", value: chart?.Control, color: "#EB7452" },
    ];

    const width =
      screenWidth < 400
        ? 320
        : screenWidth < 580
        ? 320
        : screenWidth > 1840
        ? 700
        : 526;
    const height =
      screenWidth < 400
        ? 340
        : screenWidth < 580
        ? 320
        : screenWidth > 1840
        ? 700
        : 526;
    // Dynamically calculate radii as a proportion of the smaller dimension
    const innerRadius = Math.min(width, height) * 0.15; // 20% of the smaller dimension
    const outerRadius = Math.min(width, height) * 0.23; // 32% of the smaller dimension

    // Clear existing SVG contents
    d3.select(svgRef.current).selectAll("*").remove();

    // Set up the SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Set up pie generator
    const pie = d3
      .pie()
      .value((d) => d.value)
      .startAngle(0) // Start at 0 radians
      .endAngle(2 * Math.PI); // End at 2π radians

    const dataReady = pie(data);

    // Set up arc generator
    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    // Draw arcs with animation
    svg
      .selectAll("path")
      .data(dataReady)
      .enter()
      .append("path")
      .attr("fill", (d) => d.data.color)
      // .attr("stroke", "white")
      .style("stroke-width", screenWidth < 400 ? "1.5px" : "2px")
      .transition() // Apply animation
      .duration(1000) // Animation duration (1 second)
      .attrTween("d", function (d) {
        // Interpolate between start and end angles
        const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
        return function (t) {
          return arc(interpolate(t));
        };
      });

    // Add legends positioned outside the chart, near the start of each arc
    svg
      .selectAll("text")
      .data(dataReady)
      .enter()
      .append("text")
      .attr("transform", (d) => {
        const [x, y] = arc.centroid(d);
        const offsetX =
          (outerRadius + 55) *
          Math.cos((d.startAngle + d.endAngle) / 2 - Math.PI / 2);
        const offsetY =
          (outerRadius + 40) *
          Math.sin((d.startAngle + d.endAngle) / 2 - Math.PI / 2);
        return `translate(${offsetX}, ${offsetY})`;
      })
      .style("text-anchor", "middle")
      .style("font-size", "1.4rem")
      .style("font-weight", "bold")
      .style("fill", isStory ? "#F3F3F3" : "#000")
      .text((d) => `${d.data.value}%`)
      .attr("dy", "-0.5em"); // Align percentage value above label

    // Add second line for label text
    svg
      .selectAll(".label")
      .data(dataReady)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("transform", (d) => {
        const [x, y] = arc.centroid(d);
        const offsetX =
          (outerRadius + 55) *
          Math.cos((d.startAngle + d.endAngle) / 2 - Math.PI / 2);
        const offsetY =
          (outerRadius + 40) *
          Math.sin((d.startAngle + d.endAngle) / 2 - Math.PI / 2);
        return `translate(${offsetX}, ${offsetY + 15})`; // Position below the percentage
      })
      .style("text-anchor", "middle")
      .style("font-size", "1.1rem")
      .style("fill", isStory ? "#F3F3F3" : "gray")
      .text((d) => d.data.label);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default memo(DonutChart);

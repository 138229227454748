export const mostLeastLottieFile = {
  v: "5.9.0",
  fr: 60,
  ip: 0,
  op: 180,
  w: 500,
  h: 258,
  nm: "01_Lottie_wo_shadows",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "cursor",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 10,
              s: [551, 280.375, 0],
              to: [-73, 10, 0],
              ti: [0.5, 80.25, 0],
            },
            {
              i: { x: 0.4, y: 0.4 },
              o: { x: 0.6, y: 0.6 },
              t: 40,
              s: [353, 131.375, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 60,
              s: [353, 131.375, 0],
              to: [-143.5, 20.5, 0],
              ti: [0, -0.333, 0],
            },
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.248, y: 0 },
              t: 90,
              s: [175, 99.875, 0],
              to: [0, 0.165, 0],
              ti: [0, -0.333, 0],
            },
            {
              i: { x: 0.546, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 100,
              s: [175, 99.875, 0],
              to: [0, 0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.546, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 130,
              s: [175, 209.375, 0],
              to: [59.25, 0, 0],
              ti: [0, 51.75, 0],
            },
            { t: 160, s: [293.5, 105.875, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [94, 10.875, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 40,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 50,
              s: [90, 90, 100],
            },
            { t: 60, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [94, 10.875],
                    [94, 33.625],
                    [100.062, 29],
                    [105, 39.125],
                    [109.625, 36.875],
                    [104.062, 26.75],
                    [112.375, 25.875],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 10,
      op: 310,
      st: 10,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "button_2_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [70, 102.372, 0], ix: 2, l: 2 },
        a: { a: 0, k: [236, 205.872, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 214.512], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 214.512], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 205.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 205.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 197.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 197.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.6, y: 0 },
                    t: 60,
                    s: [416, 205.872],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 90, s: [373, 205.872] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [416, 205.872], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "dots",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.4, y: 1 },
                        o: { x: 0.6, y: 0 },
                        t: 60,
                        s: [
                          {
                            i: [
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                            ],
                            v: [
                              [-96, -7],
                              [96, -7],
                              [103, 0],
                              [103, 0],
                              [96, 7],
                              [-96, 7],
                              [-103, 0],
                              [-103, 0],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 90,
                        s: [
                          {
                            i: [
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                            ],
                            v: [
                              [76, -7],
                              [157, -7],
                              [164, 0],
                              [164, 0],
                              [157, 7],
                              [76, 7],
                              [69, 0],
                              [69, 0],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [171, 205.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 50, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "line_1",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.4, y: 1 },
                        o: { x: 0.6, y: 0 },
                        t: 60,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                            ],
                            o: [
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                            ],
                            v: [
                              [-208, -20],
                              [-196, -32],
                              [196, -32],
                              [208, -20],
                              [208, 20],
                              [196, 32],
                              [-196, 32],
                              [-208, 20],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 90,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                            ],
                            o: [
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                            ],
                            v: [
                              [-19.5, -20],
                              [-7.5, -32],
                              [148.5, -32],
                              [160.5, -20],
                              [160.5, 20],
                              [148.5, 32],
                              [-7.5, 32],
                              [-19.5, 20],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [236, 205.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "bg",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 90,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "button_2_2",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-11, 13.372, 0], ix: 2, l: 2 },
        a: { a: 0, k: [236, 205.872, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 214.512], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 214.512], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 205.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 205.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 197.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 197.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.6, y: 0 },
                    t: 60,
                    s: [416, 205.872],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 90, s: [373, 205.872] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [416, 205.872], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "dots",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.4, y: 1 },
                        o: { x: 0.6, y: 0 },
                        t: 60,
                        s: [
                          {
                            i: [
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                            ],
                            v: [
                              [-96, -7],
                              [96, -7],
                              [103, 0],
                              [103, 0],
                              [96, 7],
                              [-96, 7],
                              [-103, 0],
                              [-103, 0],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 90,
                        s: [
                          {
                            i: [
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                            ],
                            v: [
                              [76, -7],
                              [157, -7],
                              [164, 0],
                              [164, 0],
                              [157, 7],
                              [76, 7],
                              [69, 0],
                              [69, 0],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [171, 205.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 50, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "line_1",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.4, y: 1 },
                        o: { x: 0.6, y: 0 },
                        t: 60,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                            ],
                            o: [
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                            ],
                            v: [
                              [-208, -20],
                              [-196, -32],
                              [196, -32],
                              [208, -20],
                              [208, 20],
                              [196, 32],
                              [-196, 32],
                              [-208, 20],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 90,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                            ],
                            o: [
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                            ],
                            v: [
                              [-19.5, -20],
                              [-7.5, -32],
                              [148.5, -32],
                              [160.5, -20],
                              [160.5, 20],
                              [148.5, 32],
                              [-7.5, 32],
                              [-19.5, 20],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [236, 205.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "bg",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 60,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "button_2_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [248, 133.872, 0], ix: 2, l: 2 },
        a: { a: 0, k: [236, 205.872, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 214.512], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 214.512], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 205.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 205.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 197.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 197.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [416, 205.872], ix: 2 },
              a: { a: 0, k: [416, 205.872], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 3",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                      ],
                      v: [
                        [-96, -7],
                        [96, -7],
                        [103, 0],
                        [103, 0],
                        [96, 7],
                        [-96, 7],
                        [-103, 0],
                        [-103, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [171, 205.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 50, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 2",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                      ],
                      o: [
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                      ],
                      v: [
                        [-208, -20],
                        [-196, -32],
                        [196, -32],
                        [208, -20],
                        [208, 20],
                        [196, 32],
                        [-196, 32],
                        [-208, 20],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [236, 205.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 1",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "button_1_2",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [167, 7.372, 0], ix: 2, l: 2 },
        a: { a: 0, k: [236, 205.872, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 214.512], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 214.512], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 205.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 205.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 197.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 197.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.6, y: 0 },
                    t: 130,
                    s: [416, 205.872],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 160, s: [288.4, 205.872] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [416, 205.872], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "dots",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.4, y: 1 },
                        o: { x: 0.6, y: 0 },
                        t: 130,
                        s: [
                          {
                            i: [
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                            ],
                            v: [
                              [-96, -7],
                              [96, -7],
                              [103, 0],
                              [103, 0],
                              [96, 7],
                              [-96, 7],
                              [-103, 0],
                              [-103, 0],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 160,
                        s: [
                          {
                            i: [
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [3.866, 0],
                              [0, 0],
                              [0, 3.866],
                              [0, 0],
                              [-3.866, 0],
                              [0, 0],
                              [0, -3.866],
                            ],
                            v: [
                              [-8.25, -7],
                              [72.75, -7],
                              [79.75, 0],
                              [79.75, 0],
                              [72.75, 7],
                              [-8.25, 7],
                              [-15.25, 0],
                              [-15.25, 0],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [171, 205.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 50, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "line_1",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.4, y: 1 },
                        o: { x: 0.6, y: 0 },
                        t: 130,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                            ],
                            o: [
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                            ],
                            v: [
                              [-208, -20],
                              [-196, -32],
                              [196, -32],
                              [208, -20],
                              [208, 20],
                              [196, 32],
                              [-196, 32],
                              [-208, 20],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 160,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                            ],
                            o: [
                              [0, -6.627],
                              [0, 0],
                              [6.627, 0],
                              [0, 0],
                              [0, 6.627],
                              [0, 0],
                              [-6.627, 0],
                              [0, 0],
                            ],
                            v: [
                              [-104.5, -20],
                              [-92.5, -32],
                              [63.5, -32],
                              [75.5, -20],
                              [75.5, 20],
                              [63.5, 32],
                              [-92.5, 32],
                              [-104.5, 20],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [236, 205.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "bg",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 130,
      op: 330,
      st: 30,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "button_1_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [248, 205.872, 0], ix: 2, l: 2 },
        a: { a: 0, k: [236, 205.872, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 214.512], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 214.512], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 205.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 205.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [419.5, 197.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.796],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.796],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [412.5, 197.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [416, 205.872], ix: 2 },
              a: { a: 0, k: [416, 205.872], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "dots",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                      ],
                      v: [
                        [-96, -7],
                        [96, -7],
                        [103, 0],
                        [103, 0],
                        [96, 7],
                        [-96, 7],
                        [-103, 0],
                        [-103, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [171, 205.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 50, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "line_1",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                      ],
                      o: [
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                      ],
                      v: [
                        [-208, -20],
                        [-196, -32],
                        [196, -32],
                        [208, -20],
                        [208, 20],
                        [196, 32],
                        [-196, 32],
                        [-208, 20],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [236, 205.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "bg",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 130,
      st: 30,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "most",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [138.5, 54.872, 0], ix: 2, l: 2 },
        a: { a: 0, k: [126.5, 54.872, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [22.103, -5.6],
                    [22.103, -4.256],
                    [18.375, -4.256],
                    [18.375, 5.6],
                    [17.031, 5.6],
                    [17.031, -4.256],
                    [13.311, -4.256],
                    [13.311, -5.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.725, 0],
                    [0.56, 0.187],
                    [0.421, 0.352],
                    [0.176, 0.485],
                    [0, 0],
                    [-0.288, -0.224],
                    [-0.395, -0.123],
                    [-0.422, 0],
                    [-0.411, 0.155],
                    [-0.256, 0.283],
                    [0, 0.379],
                    [0.266, 0.245],
                    [0.416, 0.144],
                    [0.453, 0.08],
                    [0.613, 0.235],
                    [0.357, 0.427],
                    [0, 0.699],
                    [-0.374, 0.485],
                    [-0.619, 0.267],
                    [-0.715, 0],
                    [-0.555, -0.187],
                    [-0.427, -0.352],
                    [-0.187, -0.507],
                    [0, 0],
                    [0.293, 0.219],
                    [0.394, 0.117],
                    [0.426, 0],
                    [0.41, -0.16],
                    [0.256, -0.283],
                    [0, -0.373],
                    [-0.235, -0.24],
                    [-0.411, -0.117],
                    [-0.512, -0.091],
                    [-0.619, -0.267],
                    [-0.373, -0.437],
                    [0, -0.64],
                    [0.379, -0.485],
                    [0.618, -0.267],
                  ],
                  o: [
                    [-0.603, 0],
                    [-0.56, -0.192],
                    [-0.416, -0.352],
                    [0, 0],
                    [0.107, 0.299],
                    [0.293, 0.219],
                    [0.394, 0.123],
                    [0.48, 0],
                    [0.416, -0.16],
                    [0.256, -0.283],
                    [0, -0.389],
                    [-0.267, -0.251],
                    [-0.416, -0.149],
                    [-0.774, -0.128],
                    [-0.608, -0.24],
                    [-0.352, -0.427],
                    [0, -0.651],
                    [0.378, -0.485],
                    [0.618, -0.267],
                    [0.592, 0],
                    [0.56, 0.181],
                    [0.426, 0.352],
                    [0, 0],
                    [-0.107, -0.304],
                    [-0.288, -0.224],
                    [-0.39, -0.123],
                    [-0.475, -0.005],
                    [-0.411, 0.16],
                    [-0.256, 0.283],
                    [0, 0.448],
                    [0.24, 0.235],
                    [0.41, 0.117],
                    [0.725, 0.123],
                    [0.618, 0.261],
                    [0.379, 0.437],
                    [0, 0.651],
                    [-0.373, 0.485],
                    [-0.619, 0.267],
                  ],
                  v: [
                    [8.154, 5.808],
                    [6.41, 5.528],
                    [4.938, 4.712],
                    [4.05, 3.456],
                    [5.305, 2.984],
                    [5.898, 3.768],
                    [6.93, 4.28],
                    [8.154, 4.464],
                    [9.49, 4.232],
                    [10.498, 3.568],
                    [10.882, 2.576],
                    [10.482, 1.624],
                    [9.458, 1.032],
                    [8.154, 0.688],
                    [6.074, 0.144],
                    [4.626, -0.856],
                    [4.098, -2.544],
                    [4.658, -4.248],
                    [6.154, -5.376],
                    [8.154, -5.776],
                    [9.874, -5.496],
                    [11.354, -4.696],
                    [12.274, -3.408],
                    [11.002, -2.944],
                    [10.402, -3.728],
                    [9.378, -4.24],
                    [8.154, -4.424],
                    [6.826, -4.192],
                    [5.826, -3.528],
                    [5.442, -2.544],
                    [5.794, -1.512],
                    [6.77, -0.984],
                    [8.154, -0.672],
                    [10.17, -0.088],
                    [11.657, 0.96],
                    [12.226, 2.576],
                    [11.657, 4.28],
                    [10.17, 5.408],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.576, 0],
                    [-0.501, 0.235],
                    [-0.378, 0.411],
                    [-0.213, 0.533],
                    [0, 0.608],
                    [0.219, 0.539],
                    [0.384, 0.405],
                    [0.507, 0.235],
                    [0.576, 0],
                    [0.507, -0.235],
                    [0.384, -0.416],
                    [0.219, -0.539],
                    [0, -0.608],
                    [-0.218, -0.539],
                    [-0.378, -0.411],
                    [-0.501, -0.235],
                  ],
                  o: [
                    [0.582, 0],
                    [0.507, -0.235],
                    [0.384, -0.416],
                    [0.219, -0.539],
                    [0, -0.608],
                    [-0.218, -0.544],
                    [-0.378, -0.411],
                    [-0.501, -0.235],
                    [-0.581, 0],
                    [-0.501, 0.235],
                    [-0.378, 0.411],
                    [-0.213, 0.533],
                    [0, 0.613],
                    [0.219, 0.539],
                    [0.384, 0.411],
                    [0.507, 0.229],
                  ],
                  v: [
                    [-2.997, 4.464],
                    [-1.373, 4.112],
                    [-0.045, 3.144],
                    [0.851, 1.72],
                    [1.179, 0],
                    [0.851, -1.72],
                    [-0.053, -3.144],
                    [-1.381, -4.112],
                    [-2.997, -4.464],
                    [-4.629, -4.112],
                    [-5.957, -3.136],
                    [-6.853, -1.712],
                    [-7.173, 0],
                    [-6.845, 1.728],
                    [-5.949, 3.152],
                    [-4.621, 4.12],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 3",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 3,
              ty: "sh",
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.763, 0],
                    [0.667, 0.299],
                    [0.507, 0.533],
                    [0.288, 0.699],
                    [0, 0.8],
                    [-0.288, 0.699],
                    [-0.506, 0.533],
                    [-0.666, 0.299],
                    [-0.757, 0],
                    [-0.666, -0.304],
                    [-0.506, -0.533],
                    [-0.282, -0.704],
                    [0, -0.8],
                    [0.288, -0.704],
                    [0.507, -0.533],
                    [0.667, -0.304],
                  ],
                  o: [
                    [-0.757, 0],
                    [-0.666, -0.304],
                    [-0.506, -0.533],
                    [-0.288, -0.704],
                    [0, -0.8],
                    [0.288, -0.704],
                    [0.507, -0.533],
                    [0.667, -0.304],
                    [0.763, 0],
                    [0.667, 0.299],
                    [0.507, 0.533],
                    [0.288, 0.699],
                    [0, 0.8],
                    [-0.282, 0.699],
                    [-0.506, 0.533],
                    [-0.666, 0.299],
                  ],
                  v: [
                    [-2.997, 5.808],
                    [-5.133, 5.36],
                    [-6.893, 4.104],
                    [-8.085, 2.256],
                    [-8.517, 0],
                    [-8.085, -2.248],
                    [-6.893, -4.104],
                    [-5.133, -5.352],
                    [-2.997, -5.808],
                    [-0.853, -5.352],
                    [0.907, -4.104],
                    [2.091, -2.248],
                    [2.523, 0],
                    [2.091, 2.256],
                    [0.907, 4.104],
                    [-0.853, 5.36],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 4",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 4,
              ty: "sh",
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-22.103, 5.6],
                    [-19.935, -5.6],
                    [-18.903, -5.6],
                    [-15.879, 2.352],
                    [-12.863, -5.6],
                    [-11.831, -5.6],
                    [-9.655, 5.6],
                    [-11.023, 5.6],
                    [-12.599, -2.528],
                    [-15.439, 4.992],
                    [-16.327, 4.992],
                    [-19.167, -2.528],
                    [-20.735, 5.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 5",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [126.563, 55.272], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.6, y: 0 },
                    t: 70,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-6.627, 0],
                          [0, 0],
                          [0, -6.627],
                          [0, 0],
                          [6.627, 0],
                          [0, 0],
                          [0, 6.627],
                        ],
                        o: [
                          [0, -6.627],
                          [0, 0],
                          [6.627, 0],
                          [0, 0],
                          [0, 6.627],
                          [0, 0],
                          [-6.627, 0],
                          [0, 0],
                        ],
                        v: [
                          [-98.5, -23],
                          [-86.5, -35],
                          [86.5, -35],
                          [98.5, -23],
                          [98.5, 23],
                          [86.5, 35],
                          [-86.5, 35],
                          [-98.5, 23],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 90,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-6.627, 0],
                          [0, 0],
                          [0, -6.627],
                          [0, 0],
                          [6.627, 0],
                          [0, 0],
                          [0, 6.627],
                        ],
                        o: [
                          [0, -6.627],
                          [0, 0],
                          [6.627, 0],
                          [0, 0],
                          [0, 6.627],
                          [0, 0],
                          [-6.627, 0],
                          [0, 0],
                        ],
                        v: [
                          [-98.5, -23],
                          [-86.5, -35],
                          [86.5, -35],
                          [98.5, -23],
                          [98.5, 76],
                          [86.5, 88],
                          [-86.5, 88],
                          [-98.5, 76],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0.23, 0.776, 1, 0.694, 0.615, 0.702, 1, 0.806, 1, 0.627, 1,
                    0.918,
                  ],
                  ix: 9,
                },
              },
              s: { a: 0, k: [-98, 35.872], ix: 5 },
              e: { a: 0, k: [100.169, -34.822], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [126.5, 54.872], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "least",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [353, 54.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [341, 54.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [22.338, -5.616],
                    [22.338, -4.272],
                    [18.61, -4.272],
                    [18.61, 5.584],
                    [17.265, 5.584],
                    [17.265, -4.272],
                    [13.545, -4.272],
                    [13.545, -5.616],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.726, 0],
                    [0.56, 0.187],
                    [0.422, 0.352],
                    [0.176, 0.485],
                    [0, 0],
                    [-0.288, -0.224],
                    [-0.394, -0.123],
                    [-0.421, 0],
                    [-0.41, 0.155],
                    [-0.256, 0.283],
                    [0, 0.379],
                    [0.267, 0.245],
                    [0.416, 0.144],
                    [0.454, 0.08],
                    [0.614, 0.235],
                    [0.358, 0.427],
                    [0, 0.699],
                    [-0.373, 0.485],
                    [-0.618, 0.267],
                    [-0.714, 0],
                    [-0.554, -0.187],
                    [-0.426, -0.352],
                    [-0.186, -0.507],
                    [0, 0],
                    [0.294, 0.219],
                    [0.395, 0.117],
                    [0.427, 0],
                    [0.411, -0.16],
                    [0.256, -0.283],
                    [0, -0.373],
                    [-0.234, -0.24],
                    [-0.41, -0.117],
                    [-0.512, -0.091],
                    [-0.618, -0.267],
                    [-0.373, -0.437],
                    [0, -0.64],
                    [0.379, -0.485],
                    [0.619, -0.267],
                  ],
                  o: [
                    [-0.602, 0],
                    [-0.56, -0.192],
                    [-0.416, -0.352],
                    [0, 0],
                    [0.107, 0.299],
                    [0.294, 0.219],
                    [0.395, 0.123],
                    [0.48, 0],
                    [0.416, -0.16],
                    [0.256, -0.283],
                    [0, -0.389],
                    [-0.266, -0.251],
                    [-0.416, -0.149],
                    [-0.773, -0.128],
                    [-0.608, -0.24],
                    [-0.352, -0.427],
                    [0, -0.651],
                    [0.379, -0.485],
                    [0.619, -0.267],
                    [0.592, 0],
                    [0.56, 0.181],
                    [0.427, 0.352],
                    [0, 0],
                    [-0.106, -0.304],
                    [-0.288, -0.224],
                    [-0.389, -0.123],
                    [-0.474, -0.005],
                    [-0.41, 0.16],
                    [-0.256, 0.283],
                    [0, 0.448],
                    [0.24, 0.235],
                    [0.411, 0.117],
                    [0.726, 0.123],
                    [0.619, 0.261],
                    [0.379, 0.437],
                    [0, 0.651],
                    [-0.373, 0.485],
                    [-0.618, 0.267],
                  ],
                  v: [
                    [8.387, 5.792],
                    [6.644, 5.512],
                    [5.171, 4.696],
                    [4.283, 3.44],
                    [5.54, 2.968],
                    [6.131, 3.752],
                    [7.163, 4.264],
                    [8.387, 4.448],
                    [9.723, 4.216],
                    [10.731, 3.552],
                    [11.115, 2.56],
                    [10.715, 1.608],
                    [9.692, 1.016],
                    [8.387, 0.672],
                    [6.308, 0.128],
                    [4.86, -0.872],
                    [4.331, -2.56],
                    [4.891, -4.264],
                    [6.387, -5.392],
                    [8.387, -5.792],
                    [10.107, -5.512],
                    [11.588, -4.712],
                    [12.507, -3.424],
                    [11.235, -2.96],
                    [10.636, -3.744],
                    [9.611, -4.256],
                    [8.387, -4.44],
                    [7.059, -4.208],
                    [6.059, -3.544],
                    [5.675, -2.56],
                    [6.028, -1.528],
                    [7.003, -1],
                    [8.387, -0.688],
                    [10.403, -0.104],
                    [11.891, 0.944],
                    [12.459, 2.56],
                    [11.891, 4.264],
                    [10.403, 5.392],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-3.296, 1.952],
                    [0.968, 1.952],
                    [-1.169, -3.896],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 3",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 3,
              ty: "sh",
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.976, -5.616],
                    [-0.352, -5.616],
                    [3.719, 5.584],
                    [2.295, 5.584],
                    [1.463, 3.296],
                    [-3.792, 3.296],
                    [-4.617, 5.584],
                    [-6.049, 5.584],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 4",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 4,
              ty: "sh",
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-14.213, 5.584],
                    [-14.213, -5.616],
                    [-7.34, -5.616],
                    [-7.34, -4.272],
                    [-12.869, -4.272],
                    [-12.869, -1.088],
                    [-8.34, -1.088],
                    [-8.34, 0.256],
                    [-12.869, 0.256],
                    [-12.869, 4.24],
                    [-7.34, 4.24],
                    [-7.34, 5.584],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 5",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 5,
              ty: "sh",
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-22.338, 5.584],
                    [-22.338, -5.616],
                    [-20.994, -5.616],
                    [-20.994, 4.24],
                    [-15.977, 4.24],
                    [-15.977, 5.584],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 6",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [340.798, 54.916], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 7,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.6, y: 0 },
                    t: 140,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-6.627, 0],
                          [0, 0],
                          [0, -6.627],
                          [0, 0],
                          [6.627, 0],
                          [0, 0],
                          [0, 6.627],
                        ],
                        o: [
                          [0, -6.627],
                          [0, 0],
                          [6.627, 0],
                          [0, 0],
                          [0, 6.627],
                          [0, 0],
                          [-6.627, 0],
                          [0, 0],
                        ],
                        v: [
                          [-103, -23],
                          [-91, -35],
                          [91, -35],
                          [103, -23],
                          [103, 23],
                          [91, 35],
                          [-91, 35],
                          [-103, 23],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 160,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-6.627, 0],
                          [0, 0],
                          [0, -6.627],
                          [0, 0],
                          [6.627, 0],
                          [0, 0],
                          [0, 6.627],
                        ],
                        o: [
                          [0, -6.627],
                          [0, 0],
                          [6.627, 0],
                          [0, 0],
                          [0, 6.627],
                          [0, 0],
                          [-6.627, 0],
                          [0, 0],
                        ],
                        v: [
                          [-103, -23],
                          [-91, -35],
                          [91, -35],
                          [103, -23],
                          [103, 76.25],
                          [91, 88.25],
                          [-91, 88.25],
                          [-103, 76.25],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0.23, 0.737, 0.961, 0.655, 0.615, 0.663, 0.961, 0.767, 1,
                    0.588, 0.961, 0.878,
                  ],
                  ix: 9,
                },
              },
              s: { a: 0, k: [-34, 33.5], ix: 5 },
              e: { a: 0, k: [-127.737, -9.382], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [341, 54.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: "BG",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [250, 129, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "rc",
              d: 1,
              s: { a: 0, k: [476, 258], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 20, ix: 4 },
              nm: "Rectangle Path 1",
              mn: "ADBE Vector Shape - Rect",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Rectangle 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

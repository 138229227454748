import React, { useState, useRef } from "react";
import PhoneInput from "react-phone-number-input";
import "./style.css";
import "../../App.css";
import nextbtn from "../../Assets/images/arrow-right-blue.png";
import alertCircle from "../../Assets/images/alert-circle.svg";
import { authPhoneNumber, verifyOtp, resendotp } from "../../Services/auth.js";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import NavigationButton from "../../Components/NavigationButton";

const MobilePage = ({ onNext, onBack, setFirstName, setLastName }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOtpIncorrect, setIsOtpIncorrect] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isResendSuccess, setIsResendSuccess] = useState(false); 
  const [isResendingOtp, setIsResendingOtp] = useState(false);
  const [showResendButton, setShowResendButton] = useState(true); // New state for controlling button visibility
  const otpRefs = useRef([]);
  const resendTimeout = useRef(null);
  const[issending, setIssending]= useState(false);


  const handleMobileSubmit = async () => {
    console.log(issending,"issending")
    if(issending)return;
    
    if (phoneNumber) {
      try {
        setIssending(true);
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
        const country_code = `+${parsedPhoneNumber.countryCallingCode}`;
        const contact = parsedPhoneNumber.nationalNumber;
        const response = await authPhoneNumber(country_code, contact);
   
        if (response.status === 200) {
          setIsOtpSent(true);
          setErrorMessage("");
        } else {
          setErrorMessage(response.message || "An error occurred.");
          setIsOtpIncorrect(false);
        }
      } catch (error) {
        if (error.response) {
          console.log(error, "phoneNo");
          setErrorMessage(error.response.data?.message || "An error occurred.");
        } else {
          setErrorMessage("Oops! Something went wrong.");
        }
        console.error(error);
      }
      finally{
             setIssending(false);
      }
    } else {
      setErrorMessage("Please enter a valid phone number.");
    }
  };

  const parsePhoneNumber = (phoneNumber) => {
    const parsed = parsePhoneNumberFromString(phoneNumber);
    if (!parsed) {
      throw new Error("Invalid phone number format");
    }
    return parsed;
  };

  const handleOtpChange = (value, index) => {
    if (/^\d*$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;

      if (value.length === 1 && index < otp.length - 1) {
        otpRefs.current[index + 1].focus();
      }
      setOtp(updatedOtp);

      if (isOtpIncorrect) {
        setIsOtpIncorrect(false);
        setErrorMessage("");
        setIsSubmitDisabled(false);
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      const updatedOtp = [...otp];

      if (updatedOtp[index] === "") {
        if (index > 0) {
          otpRefs.current[index - 1].focus();
        }
      } else {
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }

      if (isOtpIncorrect) {
        setIsOtpIncorrect(false);
        setErrorMessage("");
        setIsSubmitDisabled(false);
      }
    }
  };

  const handleOtpSubmit = async () => {
    const otpValue = otp.join("");
    console.log(typeof otpValue, "otpValue");
    if(issending)return;
    if (otpValue?.toString().length === 6) {
      try {
        setIssending(true);
        const response = await verifyOtp(phoneNumber, otpValue);
       
        console.log("Response from OTP submit:", response);
        if(response.payload.first_name !== null) {
          setFirstName(response.payload.first_name);
          setLastName(response.payload.last_name);
        }
        setErrorMessage("");
        setIsOtpIncorrect(false);
        setIsSubmitDisabled(false);
        onNext();
      } catch (error) {
        setIsOtpIncorrect(true);
        setErrorMessage(error?.response?.data?.message);
        setIsSubmitDisabled(true);
      }
      finally{
        setIssending(false);
      }
    } else {
      setErrorMessage("Please enter the full 6-digit OTP.");
    }
  };

  // Completely redesigned resend handler
  const handleResendCode = async () => {
    if (!phoneNumber || isResendingOtp) {
      return;
    }
    
    // Immediately hide the resend button and show "Sending..." text
    setShowResendButton(false);
    setIsResendingOtp(true);
    
    try {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
      const country_code = `+${parsedPhoneNumber.countryCallingCode}`;
      const contact = parsedPhoneNumber.nationalNumber;

      await resendotp(country_code, contact);
      
      // Reset OTP and error states
      setOtp(["", "", "", "", "", ""]);
      setErrorMessage("");
      setIsOtpIncorrect(false);
      setIsSubmitDisabled(false);
      
      // Show success message
      setIsResendSuccess(true);
      if (otpRefs.current[0]) {
        otpRefs.current[0].focus();
      }
      
      // Clear any existing timeouts
      if (resendTimeout.current) {
        clearTimeout(resendTimeout.current);
      }
      
      // Reset states after delay
      resendTimeout.current = setTimeout(() => {
        setIsResendSuccess(false);
        setIsResendingOtp(false);
        setShowResendButton(true);
      }, 2000);
    } catch (error) {
      setErrorMessage("Failed to resend code. Please try again.");
      setIsResendingOtp(false);
      setShowResendButton(true);
      console.error(error);
    }
  };

  // Clean up timeout if component unmounts
  React.useEffect(() => {
    return () => {
      if (resendTimeout.current) {
        clearTimeout(resendTimeout.current);
      }
    };
  }, []);

  const handleBackNavigation = () => {
    if (resendTimeout.current) {
      clearTimeout(resendTimeout.current);
    }
    
    if (isOtpSent) {
      setIsOtpSent(false);
      setErrorMessage("");
      setIsOtpIncorrect(false);
      setIsSubmitDisabled(false);
      setIsResendingOtp(false);
      setShowResendButton(true);
      setOtp(["", "", "", "", "", ""]);
    } else {
      onBack();
    }
  };

  const isOtpComplete = otp.every((digit) => digit !== "");

  // Render the resend button or sending text based on state
  const renderResendOption = () => {
    if (isResendSuccess) {
      return (
        <span style={{ color: "green", padding: "10px", textAlign: "center" }}>
          Code resent successfully!
        </span>
      );
    } else if (isResendingOtp || !showResendButton) {
      return <span style={{ color: "grey", fontWeight: "bold" }}>Sending...</span>;
    } else {
      return (
        <button
          className={`resend-email-link-button ${isResendingOtp ? "disabled" : ""}`}
          onClick={handleResendCode}
          disabled={isResendingOtp} // Disable while resending
        >
          <strong>Resend code</strong>
        </button>
      );
    }
  };
  

  return (
    <div className="Fullpage-container-email">
      <div className="email-container">
        <div className="mobile-header">
          <NavigationButton
            onClick={handleBackNavigation}
            direction="left"
            label="Back"
            className="back-button"
          />
          {!isOtpSent ? (
            <>
              <h2
                className="heading-h2"
                style={{ marginBottom: "2px", marginTop: "0px" }}
              >
                What's your mobile number?
              </h2>
              <p className="mobile-para">
                We will send a temporary sign-in code to your number.
              </p>
              <p className="sign-up-para-title">Mobile</p>
              <div className="phone-input-container">
                <PhoneInput
                  international
                  defaultCountry="US"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  autoFocus
                  className="phone-input"
                />
              </div>

              {errorMessage && (
                <p style={{ color: "red", marginTop: "12px" }}>
                  {errorMessage}
                </p>
              )}
            </>
          ) : (
            <div>
              <h2
                className="heading-h2"
                style={{ marginTop: "2px", marginBottom: "3px" }}
              >
                Verify your mobile number
              </h2>
              <p className="mobile-para">
                We sent a six-digit confirmation code to your number. It will
                expire in 30 minutes, enter it soon! Keep the window open while
                checking for your code.
              </p>
              <div className="signup-otp-container">
                {otp.map((value, index) => (
                  <input
                    key={index}
                    ref={(el) => (otpRefs.current[index] = el)}
                    type="text"
                    maxLength="1"
                    value={value}
                    autoFocus={index === 0}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    className={`signup-otp-box ${
                      isOtpIncorrect ? "error" : ""
                    }`}
                  />
                ))}
              </div>
            </div>
          )}

          {isOtpSent && (
            <div className="signup-no-otp">
              <p className="mobile-para" style={{ margin: "10px 0px" }}>
                Didn't get a code? {renderResendOption()}
              </p>
              <p>
                {!isResendingOtp && showResendButton && (
                  <button
                    className="resend-email-link-button"
                    onClick={() => {
                      setErrorMessage("");
                      setIsOtpSent(false);
                    }}
                  >
                    <strong>Change phone number</strong>
                  </button>
                )}
              </p>
            </div>
          )}

          {isOtpIncorrect && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <img src={alertCircle} alt="Alert" width="16" height="17" />
              <p style={{ fontFamily: "Urbanist", marginLeft: "3px" }}>
                {errorMessage || "Incorrect code. Please try again."}
              </p>
            </div>
          )}
        </div>

        {!isOtpSent ? (
          <button
            className={`submit-button ${phoneNumber ? "valid" : "invalid"}`}
            onClick={handleMobileSubmit}
          >
            Send code
            <img src={nextbtn} alt="next-btn" className="nxtbtn" />
          </button>
        ) : (
          <div>
            <button
              className={`submit-button ${
                isOtpComplete && !isSubmitDisabled ? "valid" : "invalid"
              }`}
              onClick={handleOtpSubmit}
              disabled={!isOtpComplete || isSubmitDisabled}
            >
              Verify and continue
              <img src={nextbtn} alt="nxtbtn" className="nxtbtn" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobilePage;
import React from 'react';

const InputField = ({
  label,
  name,
  type = 'text',
  value,
  onChange,
  placeholder,
  optional = false,
  className = ''
}) => {
  return (
    <div className={`form-group ${className}`}>
      <label>
        {label} {optional && <span className="optional">(optional)</span>}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputField;
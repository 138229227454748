export const convertApiResponse = (apiResponse) => {
  // Ensure input is always treated as an array
  const responses = Array.isArray(apiResponse) ? apiResponse : [apiResponse];

  return responses.map((response) => ({
    role: "system",
    content: {
      desc: response?.message_content || "",
      options: (response.message_options || []).map((option) => ({
        type: option?.type || "nonLink",
        label: option?.text || "",
      })),
    },
    question_id: response?.question_id,
    message_type: response?.message_type,
    action_text: response?.action_text || "",
    startAssessment: response?.startAssessment || "",
    nextCategoryId: response?.nextCategoryId || "",
    isSurvey: true,
    userStory: response?.userStory,
    load_chat: response?.load_chat,
  }));
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import arrowLeft from "../../Assets/icon/arrow-narrow-left.svg";
import navigateTop from "../../Assets/icon/navigate-top.svg";
import arisLogo from "../../Assets/images/aris-logo.svg";
import Card from "../../Components/Card";
import { getAboutAris } from "../../Services/aboutAris";
import { useHeader } from "../../Providers/HeaderContextProvider";
import Stroke1 from "../../Assets/icon/Stroke1.svg";
import GradientBackground from "../../Components/AddRelationshipHeaderGradient";
import { isMobile } from "react-device-detect";

function AboutAris() {
  const [aboutData, setAboutData] = useState(null);
  const { setHeaderContent } = useHeader();
  const navigate = useNavigate();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setHeaderContent(false);
    const fetchAboutData = async () => {
      try {
        const field = "about_aris";
        const response = await getAboutAris(field);
        if (response) {
          setAboutData(response);
        }
      } catch (error) {
        console.error("Error fetching about data:", error);
      }
    };

    fetchAboutData();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="about-aris-app">
      {screenWidth <= 488 && <GradientBackground />}
      <header className="about-aris-header">
        <div className="about-gradient-background">
          <div className="about-aris-nav-container">
            <div
              className="about-aris-logo-container"
              onClick={() => navigate("/dashboard")}
            >
              <img
                src={arrowLeft}
                alt="Back"
                className="about-aris-back-button"
              />
            </div>
            <h1 className="about-aris-title">About Aris</h1>
            <div className="about-aris-space"></div>
          </div>
          <img src={arisLogo} alt="Vision Logo" className="vision-logo" />
        </div>

        <div className="vision">
          <p className="vision-label">Vision</p>
          <div className="vision-content">
            <p className="vision-heading">{aboutData?.vision_statement}</p>
            <p className="vision-text">{aboutData?.tagline}</p>
          </div>
        </div>
      </header>

      <div className="about-aris-content-container">
        <div className="about-aris-section">
          <div className="about-aris-stroke-container">
            <img src={Stroke1} alt="Stroke Layer 1" className="stroke-image1" />
            <img src={Stroke1} alt="Stroke Layer 2" className="stroke-image2" />
            <img src={Stroke1} alt="Stroke Layer 3" className="stroke-image3" />
          </div>
          <div className="about-overview">
            <p className="overview-label">Overview</p>
            <div className="overview-content">
              <p className="overview-heading">{aboutData?.overview_title}</p>
              <p className="overview-text">{aboutData?.overview_description}</p>
              <p className="overview-text">{aboutData?.platform_description}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="about-aris-cards-section">
        {aboutData?.features?.map((feature, index) => (
          <Card
            key={feature.id}
            number={feature.id}
            heading={feature.title}
            text={feature.description}
            gradientColor={
              index === 0
                ? "linear-gradient(to top right, #BDF6A8, #99F6DC)"
                : index === 1
                ? "linear-gradient(to top right, #8EDAFF, #D0B2FF)"
                : "linear-gradient(to top right, #80CEED, #82DDD6)"
            }
          />
        ))}
      </div>
      <div className="about-aris-navigate-icon">
        <div onClick={scrollToTop}>
          {" "}
          <img src={navigateTop} alt="Navigate top" />
        </div>
      </div>
    </div>
  );
}

export default AboutAris;

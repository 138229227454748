import React, { useEffect, useState } from 'react'
import "./style.css"
import { ReactComponent as RigthArrow } from "../../Assets/images/arrow-left.svg";
import { useHeader } from '../../Providers/HeaderContextProvider';
import GradientBackground from '../../Components/AddRelationshipHeaderGradient';
import { useNavigate } from 'react-router-dom';
import { fetchHistory } from '../../Services/coaching';
import ContentLoader from '../LoadingPage/ContentLoader';

const CoachingHistory = () => {
    const { setHeaderContent } = useHeader();
    const [filter, setFilter] = useState('action_plan');
    const [history, setHistory] = useState({});
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    useEffect(() => {
        const getHistory = async () => {
            setLoading(true);
            try{
                const response = await fetchHistory();
                setHistory(response.payload)
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        }
        getHistory();
    }, []);

    useEffect(() => {
        setHeaderContent(false);
    }, [])

    const renderHistoryItems = (items) => {
        return items
            .map((item, index) => (
                <div key={index} className="profile-coaching-history-list-item-content">
                    <div className="profile-coaching-history-list-item-content-left">
                        <div className="profile-coaching-history-list-item-content-title">
                            {item.topic || 'Title'}
                        </div>
                        <div className="profile-coaching-history-list-item-content-description">
                            {item.description || 'No Description'}
                        </div>
                    </div>
                    <div className="profile-coaching-history-list-item-content-right">
                        Show More
                    </div>
                </div>
            ));
    };

    const renderSmartHabitsItems = (items) => {
        return items.map((item, index) => {
            const expirationDate = new Date(item.expiration_time).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
            return (
                <div key={index} className="profile-coaching-history-list-item-content">
                    <div className="profile-coaching-history-list-item-content-left">
                        <div className="profile-coaching-history-list-item-content-title">
                            {item.topic || 'Title'}
                        </div>
                        <div className="profile-coaching-history-list-item-content-description">
                            {item.description || 'No Description'}
                        </div>
                    </div>
                </div>
            );
        });
    };

    const renderItems = () => {
        if (filter === 'smart_habits') {
            const smartHabitsKeys = Object.keys(history.smart_habits).filter((key) => history.smart_habits[key].length > 0);
            if (smartHabitsKeys.length === 0) {
                return <div className="no-items-message">No SMART Habits found</div>;
            }
            return smartHabitsKeys.map((key) => (
                <div key={key} className="profile-coaching-history-list-item">
                    <p className='profile-coaching-history-list-time'>{key}</p>
                    {renderSmartHabitsItems(history.smart_habits[key])}
                </div>
            ));
        } else {
            const filterKeys = Object.keys(history[filter]).filter((key) => history[filter][key].length > 0);
            if (filterKeys.length === 0) {
                return <div className="no-items-message">No {filter.replace('_', ' ')} found</div>;
            }
            return filterKeys.map((key) => (
                <div key={key} className="profile-coaching-history-list-item">
                    <p className='profile-coaching-history-list-time'>{key}</p>
                    {renderHistoryItems(history[filter][key])}
                </div>
            ));
        }
    };

    return (
        <div className='profile-coaching-history-container'>
            {screenWidth > 480 ? <GradientBackground /> : <></>}
            <div className="profile-coaching-history-header">
                <div className="profile-coaching-history-header-content">
                    <div className='profile-coaching-history-header-content-back-button-container' onClick={() => navigate(-1)}>
                        <RigthArrow />
                    </div>
                    <div className='profile-coaching-history-header-content-title'>Coaching history</div>
                    <div></div>
                </div>
                <div className="profile-coaching-history-header-content-text">
                    Track your progress and reflect on your journey
                </div>
            </div>
            <div className="profile-coaching-history-filter-container">
                <div className={`profile-container-history-filter-item ${filter === 'meeting_notes' && 'active'}`} onClick={() => setFilter('meeting_notes')}>
                    No Action Plan
                </div>
                <div className={`profile-container-history-filter-item ${filter === 'action_plan' && 'active'}`} onClick={() => setFilter('action_plan')}>
                    Action Plan
                </div>
                <div className={`profile-container-history-filter-item ${filter === 'smart_habits' && 'active'}`} onClick={() => setFilter('smart_habits')}>
                    SMART Habits
                </div>
            </div>
            <div className="profile-container-history-items-container">
                <div className='filler-div'></div>
                <div className="profile-coaching-history-list-container">
                    {history &&
                        (
                            Object.keys(history).length > 0 ? (
                                renderItems()
                            ) : (
                                <div className="no-items-message">
                                    {loading ? <ContentLoader /> : 'No history'}
                                </div>
                            )
                        )
                    }
                </div>
                <div className='filler-div'></div>
            </div>
        </div>
    )
}

export default CoachingHistory
// InputField.jsx
import React ,{useEffect,useRef,forwardRef} from 'react';
import './style.css'


const InputField =forwardRef( ({ label, type, placeholder, value, onChange, className,  onKeyDown, autoFocus  },ref) => {
  const inputRef = useRef(null);


  
  useEffect(() => {
    if (autoFocus && ref && ref.current) {
      ref.current.focus(); // Automatically focus the input if `autoFocus` is true
    }
  }, [autoFocus, ref]);

  return (
    <div  className={`input-field ${className || ''}`}> {/* Use the provided class name */}
      <label className='input-label'>{label}</label>
      <input className='signup-input'
       ref={ref}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required
        onKeyDown={onKeyDown} // Attach the onKeyDown handler
        autoFocus={autoFocus}
       
       
      />
    </div>
  );
});

export default InputField;

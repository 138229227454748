export const rankLottieFile = {
  v: "5.9.0",
  fr: 60,
  ip: 0,
  op: 180,
  w: 480,
  h: 350,
  nm: "03_rank",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "cursor",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 10,
              s: [551, 280.375, 0],
              to: [-73, 10, 0],
              ti: [0.5, 80.25, 0],
            },
            {
              i: { x: 0.4, y: 0.4 },
              o: { x: 0.6, y: 0.6 },
              t: 40,
              s: [353, 131.375, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 60,
              s: [353, 131.375, 0],
              to: [44, -45.5, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.4, y: 0.4 },
              o: { x: 0.6, y: 0.6 },
              t: 90,
              s: [353, 50.875, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 100,
              s: [353, 50.875, 0],
              to: [0, 114.875, 0],
              ti: [-99, 0, 0],
            },
            { t: 130, s: [551, 280.375, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [94, 10.875, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 40,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 50,
              s: [90, 90, 100],
            },
            { t: 60, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [94, 10.875],
                    [94, 33.625],
                    [100.062, 29],
                    [105, 39.125],
                    [109.625, 36.875],
                    [104.062, 26.75],
                    [112.375, 25.875],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 10,
      op: 310,
      st: 10,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "button_4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [255, 55.2, 0], ix: 2, l: 2 },
        a: { a: 0, k: [255, 55.45, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 64.09], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 64.09], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 46.81], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 46.81], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [393, 55.45], ix: 2 },
              a: { a: 0, k: [393, 55.45], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 4",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                      ],
                      v: [
                        [-96, -7],
                        [96, -7],
                        [103, 0],
                        [103, 0],
                        [96, 7],
                        [-96, 7],
                        [-103, 0],
                        [-103, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [232, 55.45], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 50, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 3",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                      ],
                      o: [
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                      ],
                      v: [
                        [-166, -20],
                        [-154, -32],
                        [154, -32],
                        [166, -20],
                        [166, 20],
                        [154, 32],
                        [-154, 32],
                        [-166, 20],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 3,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 60,
                        s: [0],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 75,
                        s: [100],
                      },
                      { t: 90, s: [0] },
                    ],
                    ix: 4,
                  },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [255, 55.45], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 2",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 90,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "button_3",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-4, 15.45, 0], ix: 2, l: 2 },
        a: { a: 0, k: [255, 55.45, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 64.09], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 64.09], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 46.81], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 46.81], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [393, 55.45], ix: 2 },
              a: { a: 0, k: [393, 55.45], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 4",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                      ],
                      v: [
                        [-96, -7],
                        [96, -7],
                        [103, 0],
                        [103, 0],
                        [96, 7],
                        [-96, 7],
                        [-103, 0],
                        [-103, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [232, 55.45], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 50, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 3",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                      ],
                      o: [
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                      ],
                      v: [
                        [-166, -20],
                        [-154, -32],
                        [154, -32],
                        [166, -20],
                        [166, 20],
                        [154, 32],
                        [-154, 32],
                        [-166, 20],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 3,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 60,
                        s: [0],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 75,
                        s: [100],
                      },
                      { t: 90, s: [0] },
                    ],
                    ix: 4,
                  },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [255, 55.45], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 2",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 60,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "button_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [255, 135.95, 0], ix: 2, l: 2 },
        a: { a: 0, k: [255, 55.45, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 64.09], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 64.09], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 46.81], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 46.81], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [393, 55.45], ix: 2 },
              a: { a: 0, k: [393, 55.45], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 4",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                      ],
                      v: [
                        [-96, -7],
                        [96, -7],
                        [103, 0],
                        [103, 0],
                        [96, 7],
                        [-96, 7],
                        [-103, 0],
                        [-103, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [232, 55.45], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 50, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 3",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                      ],
                      o: [
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                      ],
                      v: [
                        [-166, -20],
                        [-154, -32],
                        [154, -32],
                        [166, -20],
                        [166, 20],
                        [154, 32],
                        [-154, 32],
                        [-166, 20],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 0, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [255, 55.45], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 2",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "button_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 70,
              s: [255, 55.45, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 100, s: [255, 135.95, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [255, 55.45, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 64.09], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 64.09], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [396.5, 46.81], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.828, 0],
                        [0, 0.795],
                        [0.828, 0],
                        [0, -0.795],
                      ],
                      o: [
                        [0.828, 0],
                        [0, -0.795],
                        [-0.828, 0],
                        [0, 0.795],
                      ],
                      v: [
                        [0, 1.44],
                        [1.5, 0],
                        [0, -1.44],
                        [-1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [389.5, 46.81], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [393, 55.45], ix: 2 },
              a: { a: 0, k: [393, 55.45], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 4",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.866, 0],
                        [0, 0],
                        [0, 3.866],
                        [0, 0],
                        [-3.866, 0],
                        [0, 0],
                        [0, -3.866],
                      ],
                      v: [
                        [-96, -7],
                        [96, -7],
                        [103, 0],
                        [103, 0],
                        [96, 7],
                        [-96, 7],
                        [-103, 0],
                        [-103, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [232, 55.45], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 50, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 3",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                      ],
                      o: [
                        [0, -6.627],
                        [0, 0],
                        [6.627, 0],
                        [0, 0],
                        [0, 6.627],
                        [0, 0],
                        [-6.627, 0],
                        [0, 0],
                      ],
                      v: [
                        [-166, -20],
                        [-154, -32],
                        [154, -32],
                        [166, -20],
                        [166, 20],
                        [154, 32],
                        [-154, 32],
                        [-166, 20],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [255, 55.45], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 2",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "buttons",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [255, 255.975, 0], ix: 2, l: 2 },
        a: { a: 0, k: [255, 255.975, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [396.5, 64.09], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [389.5, 64.09], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 2",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [396.5, 55.45], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 3",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [389.5, 55.45], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 4",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [396.5, 46.81], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 5",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [389.5, 46.81], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 6",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 6,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [393, 55.45], ix: 2 },
                  a: { a: 0, k: [393, 55.45], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Layer 4",
              np: 6,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-3.866, 0],
                            [0, 0],
                            [0, -3.866],
                            [0, 0],
                            [3.866, 0],
                            [0, 0],
                            [0, 3.866],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [3.866, 0],
                            [0, 0],
                            [0, 3.866],
                            [0, 0],
                            [-3.866, 0],
                            [0, 0],
                            [0, -3.866],
                          ],
                          v: [
                            [-96, -7],
                            [96, -7],
                            [103, 0],
                            [103, 0],
                            [96, 7],
                            [-96, 7],
                            [-103, 0],
                            [-103, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [232, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 50, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Layer 3",
              np: 1,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-6.627, 0],
                            [0, 0],
                            [0, -6.627],
                            [0, 0],
                            [6.627, 0],
                            [0, 0],
                            [0, 6.627],
                          ],
                          o: [
                            [0, -6.627],
                            [0, 0],
                            [6.627, 0],
                            [0, 0],
                            [0, 6.627],
                            [0, 0],
                            [-6.627, 0],
                            [0, 0],
                          ],
                          v: [
                            [-166, -20],
                            [-154, -32],
                            [154, -32],
                            [166, -20],
                            [166, 20],
                            [154, 32],
                            [-154, 32],
                            [-166, 20],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [255, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Layer 2",
              np: 1,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [255, 296], ix: 2 },
              a: { a: 0, k: [255, 55.45], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "button_4",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [396.5, 64.09], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [389.5, 64.09], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 2",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [396.5, 55.45], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 3",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [389.5, 55.45], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 4",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [396.5, 46.81], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 5",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.828, 0],
                            [0, 0.795],
                            [0.828, 0],
                            [0, -0.795],
                          ],
                          o: [
                            [0.828, 0],
                            [0, -0.795],
                            [-0.828, 0],
                            [0, 0.795],
                          ],
                          v: [
                            [0, 1.44],
                            [1.5, 0],
                            [0, -1.44],
                            [-1.5, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.494117647409, 0.498039215803, 0.51372551918, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [389.5, 46.81], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 6",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 6,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [393, 55.45], ix: 2 },
                  a: { a: 0, k: [393, 55.45], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Layer 4",
              np: 6,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-3.866, 0],
                            [0, 0],
                            [0, -3.866],
                            [0, 0],
                            [3.866, 0],
                            [0, 0],
                            [0, 3.866],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [3.866, 0],
                            [0, 0],
                            [0, 3.866],
                            [0, 0],
                            [-3.866, 0],
                            [0, 0],
                            [0, -3.866],
                          ],
                          v: [
                            [-96, -7],
                            [96, -7],
                            [103, 0],
                            [103, 0],
                            [96, 7],
                            [-96, 7],
                            [-103, 0],
                            [-103, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.89411765337, 0.89411765337, 0.905882358551, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [232, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 50, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Layer 3",
              np: 1,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-6.627, 0],
                            [0, 0],
                            [0, -6.627],
                            [0, 0],
                            [6.627, 0],
                            [0, 0],
                            [0, 6.627],
                          ],
                          o: [
                            [0, -6.627],
                            [0, 0],
                            [6.627, 0],
                            [0, 0],
                            [0, 6.627],
                            [0, 0],
                            [-6.627, 0],
                            [0, 0],
                          ],
                          v: [
                            [-166, -20],
                            [-154, -32],
                            [154, -32],
                            [166, -20],
                            [166, 20],
                            [154, 32],
                            [-154, 32],
                            [-166, 20],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [255, 55.45], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Layer 2",
              np: 1,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [255, 215.95], ix: 2 },
              a: { a: 0, k: [255, 55.45], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "button_3",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "under button",
      sr: 1,
      ks: {
        o: { a: 0, k: 40, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [255, 55.45, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-6.627, 0],
                    [0, 0],
                    [0, -6.627],
                    [0, 0],
                    [6.627, 0],
                    [0, 0],
                    [0, 6.628],
                  ],
                  o: [
                    [0, -6.627],
                    [0, 0],
                    [6.627, 0],
                    [0, 0],
                    [0, 6.628],
                    [0, 0],
                    [-6.627, 0],
                    [0, 0],
                  ],
                  v: [
                    [-166, -20],
                    [-154, -32],
                    [154, -32],
                    [166, -20],
                    [166, 20],
                    [154, 32],
                    [-154, 32],
                    [-166, 20],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.96862745285, 0.96862745285, 0.96862745285, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "rp",
          c: { a: 0, k: 4, ix: 1 },
          o: { a: 0, k: 0, ix: 2 },
          m: 1,
          ix: 2,
          tr: {
            ty: "tr",
            p: { a: 0, k: [0, 80.2], ix: 2 },
            a: { a: 0, k: [0, 0], ix: 1 },
            s: { a: 0, k: [100, 100], ix: 3 },
            r: { a: 0, k: 0, ix: 4 },
            so: { a: 0, k: 100, ix: 5 },
            eo: { a: 0, k: 100, ix: 6 },
            nm: "Transform",
          },
          nm: "Repeater 1",
          mn: "ADBE Vector Filter - Repeater",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "numbers",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [36.806, 175.85, 0], ix: 2, l: 2 },
        a: { a: 0, k: [36.806, 175.85, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-205.284, 122.066],
                        [-202.044, 122.082],
                        [-202.068, 117.138],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-202.044, 126.45],
                        [-202.044, 123.426],
                        [-206.86, 123.426],
                        [-206.86, 122.066],
                        [-202.38, 115.25],
                        [-200.7, 115.25],
                        [-200.7, 122.082],
                        [-199.588, 122.082],
                        [-199.588, 123.426],
                        [-200.7, 123.426],
                        [-200.7, 126.45],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [240, 175], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 5",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.629, 0],
                        [0.528, 0.304],
                        [0.299, 0.512],
                        [0, 0.63],
                        [0, 0],
                        [-0.176, -0.314],
                        [-0.32, -0.186],
                        [-0.437, 0],
                        [-0.325, 0.187],
                        [-0.203, 0.31],
                        [0, 0.379],
                        [0.245, 0.315],
                        [0.432, 0.155],
                        [0.549, 0],
                        [0, 0],
                        [-0.453, 0.315],
                        [0, 0.614],
                        [0.176, 0.272],
                        [0.288, 0.16],
                        [0.325, 0],
                        [0.304, -0.165],
                        [0.192, -0.272],
                        [0, -0.325],
                        [0, 0],
                        [-0.176, 0.379],
                        [-0.309, 0.288],
                        [-0.395, 0.16],
                        [-0.437, 0],
                        [-0.491, -0.282],
                        [-0.299, -0.474],
                        [0, -0.576],
                        [0.261, -0.437],
                        [0.448, -0.176],
                        [-0.267, -0.282],
                        [-0.133, -0.362],
                        [0, -0.4],
                        [0.325, -0.517],
                        [0.533, -0.309],
                      ],
                      o: [
                        [-0.677, 0],
                        [-0.523, -0.309],
                        [-0.299, -0.517],
                        [0, 0],
                        [0, 0.379],
                        [0.176, 0.31],
                        [0.32, 0.187],
                        [0.379, 0],
                        [0.331, -0.186],
                        [0.203, -0.314],
                        [0, -0.474],
                        [-0.24, -0.32],
                        [-0.427, -0.16],
                        [0, 0],
                        [0.757, 0],
                        [0.459, -0.314],
                        [0, -0.325],
                        [-0.176, -0.272],
                        [-0.283, -0.165],
                        [-0.331, 0],
                        [-0.304, 0.16],
                        [-0.192, 0.272],
                        [0, 0],
                        [0, -0.432],
                        [0.181, -0.378],
                        [0.315, -0.288],
                        [0.395, -0.165],
                        [0.571, 0],
                        [0.491, 0.283],
                        [0.304, 0.475],
                        [0, 0.518],
                        [-0.261, 0.438],
                        [0.389, 0.134],
                        [0.267, 0.278],
                        [0.139, 0.363],
                        [0, 0.624],
                        [-0.32, 0.518],
                        [-0.528, 0.304],
                      ],
                      v: [
                        [-0.008, 5.812],
                        [-1.816, 5.356],
                        [-3.048, 4.124],
                        [-3.496, 2.404],
                        [-2.152, 2.404],
                        [-1.888, 3.444],
                        [-1.144, 4.188],
                        [-0.008, 4.468],
                        [1.048, 4.188],
                        [1.848, 3.444],
                        [2.152, 2.404],
                        [1.784, 1.22],
                        [0.776, 0.508],
                        [-0.688, 0.268],
                        [-0.688, -0.812],
                        [1.128, -1.284],
                        [1.816, -2.676],
                        [1.552, -3.572],
                        [0.856, -4.22],
                        [-0.056, -4.468],
                        [-1.008, -4.22],
                        [-1.752, -3.572],
                        [-2.04, -2.676],
                        [-3.384, -2.676],
                        [-3.12, -3.892],
                        [-2.384, -4.892],
                        [-1.32, -5.564],
                        [-0.072, -5.812],
                        [1.52, -5.388],
                        [2.704, -4.252],
                        [3.16, -2.676],
                        [2.768, -1.244],
                        [1.704, -0.324],
                        [2.688, 0.3],
                        [3.288, 1.26],
                        [3.496, 2.404],
                        [3.008, 4.116],
                        [1.728, 5.356],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [36.976, 215.846], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 4",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.512, 0.454],
                        [-0.507, 0.496],
                        [-0.427, 0.491],
                        [-0.261, 0.438],
                        [0, 0.331],
                        [0.192, 0.326],
                        [0.325, 0.198],
                        [0.395, 0],
                        [0.331, -0.197],
                        [0.197, -0.33],
                        [0, -0.394],
                        [0, 0],
                        [-0.32, 0.528],
                        [-0.528, 0.31],
                        [-0.64, 0],
                        [-0.523, -0.314],
                        [-0.315, -0.533],
                        [0, -0.65],
                        [0.181, -0.432],
                        [0.315, -0.453],
                        [0.4, -0.464],
                        [0.443, -0.448],
                        [0.437, -0.41],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.411, -0.368],
                        [0.512, -0.458],
                        [0.512, -0.501],
                        [0.427, -0.49],
                        [0.261, -0.442],
                        [0, -0.394],
                        [-0.192, -0.33],
                        [-0.32, -0.197],
                        [-0.395, 0],
                        [-0.325, 0.198],
                        [-0.192, 0.326],
                        [0, 0],
                        [0, -0.656],
                        [0.32, -0.533],
                        [0.528, -0.314],
                        [0.64, 0],
                        [0.528, 0.315],
                        [0.315, 0.528],
                        [0, 0.374],
                        [-0.181, 0.427],
                        [-0.315, 0.454],
                        [-0.4, 0.459],
                        [-0.443, 0.448],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-3.616, 5.704],
                        [-3.616, 4.512],
                        [-2.232, 3.28],
                        [-0.704, 1.848],
                        [0.704, 0.36],
                        [1.736, -1.032],
                        [2.128, -2.192],
                        [1.84, -3.272],
                        [1.064, -4.064],
                        [-0.008, -4.36],
                        [-1.096, -4.064],
                        [-1.88, -3.272],
                        [-2.168, -2.192],
                        [-3.512, -2.192],
                        [-3.032, -3.968],
                        [-1.76, -5.232],
                        [-0.008, -5.704],
                        [1.736, -5.232],
                        [3, -3.96],
                        [3.472, -2.192],
                        [3.2, -0.984],
                        [2.456, 0.336],
                        [1.384, 1.712],
                        [0.12, 3.072],
                        [-1.2, 4.36],
                        [3.616, 4.36],
                        [3.616, 5.704],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [36.756, 135.746], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 3",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-0.044, 5.6],
                        [-0.044, -4.256],
                        [-1.3, -4.256],
                        [-0.924, -5.6],
                        [1.3, -5.6],
                        [1.3, 5.6],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [36.44, 55.85], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 2",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: "squares",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [37, 55.45, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-6.627, 0],
                    [0, 0],
                    [0, -6.627],
                    [0, 0],
                    [6.627, 0],
                    [0, 0],
                    [0, 6.627],
                  ],
                  o: [
                    [0, -6.627],
                    [0, 0],
                    [6.627, 0],
                    [0, 0],
                    [0, 6.627],
                    [0, 0],
                    [-6.627, 0],
                    [0, 0],
                  ],
                  v: [
                    [-32, -20],
                    [-20, -32],
                    [20, -32],
                    [32, -20],
                    [32, 20],
                    [20, 32],
                    [-20, 32],
                    [-32, 20],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0.23, 0.776, 1, 0.694, 0.615, 0.702, 1, 0.806, 1, 0.627, 1,
                    0.918,
                  ],
                  ix: 9,
                },
              },
              s: { a: 0, k: [-33, 32.95], ix: 5 },
              e: { a: 0, k: [31, -31.049], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "rp",
          c: { a: 0, k: 4, ix: 1 },
          o: { a: 0, k: 0, ix: 2 },
          m: 1,
          ix: 2,
          tr: {
            ty: "tr",
            p: { a: 0, k: [0, 80.2], ix: 2 },
            a: { a: 0, k: [0, 0], ix: 1 },
            s: { a: 0, k: [100, 100], ix: 3 },
            r: { a: 0, k: 0, ix: 4 },
            so: { a: 0, k: 100, ix: 5 },
            eo: { a: 0, k: 100, ix: 6 },
            nm: "Transform",
          },
          nm: "Repeater 1",
          mn: "ADBE Vector Filter - Repeater",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

import React, { useState, useEffect, useRef } from "react";
import InputField from "../../Components/InputField";
import PasswordInput from "../../Components/PasswordInput";
import {
  loginWithEmail,
  storeTokens,
  manageSession,
} from "../../Services/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHeader } from "../../Providers/HeaderContextProvider";
import { updateEmailSettings } from "../../Services/settingsServices";
import { isMobile } from "react-device-detect";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import Cookies from "js-cookie";
import { ReactComponent as Info } from "../../Assets/icon/Info-ref.svg";
import { getUserProfile } from "../../Services/MyProfile";

const EmailPassword = () => {
  const [n_email, setN_Email] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false); // For the pop-up
  const navigate = useNavigate();
  const { setHeaderContent, addProfileData } = useHeader();
  const[issending, setIssending]= useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get("mode");
  const oldEmail = urlParams.get("o_email");
  const newEmail = urlParams.get("n_email");
  console.log(newEmail, "newEmail1");
  const oobCode = urlParams.get("oobCode");
  const passwordInputRef = useRef(null);

  const clearAllCookies = () => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });
  };

  useEffect(() => {
    const handleEmailUpdate = () => {
      if (mode === "emailUpdation" && oldEmail) {
        clearAllCookies();
        const formattedEmail = oldEmail.replace(/\s+/g, "+");
        setEmail(formattedEmail);
      }
      if (mode === "emailUpdation" && newEmail) {
        clearAllCookies();
        const formattedEmail1 = newEmail.replace(/\s+/g, "+");
        setN_Email(formattedEmail1);
        console.log(n_email, "newEmail2");
      }
    };

    handleEmailUpdate();
  }, [mode, oldEmail, newEmail]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!email.trim()) {
        setError("Please enter a valid email.");
        return;
      }
      if (!validateEmail(email)) {
        setError("Please enter a valid email format.");
        return;
      }

      setError("");
      passwordInputRef.current?.focus();
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleManageSession = async () => {
    if( issending)return;
    try {
      // Call the manageSession API with email
      setIssending(true);
      await manageSession(email);

      // After successful session management, retry the login
      const loginRes = await loginWithEmail(email, password);

      // Process the login response
      if (
        loginRes?.status === 400 &&
        loginRes?.message === "Maximum number of devices reached"
      ) {
        setError("Failed to login even after managing sessions.");
        return;
      }

      // If successful, store tokens and navigate
      await storeTokens(loginRes?.payload);
      setHeaderContent(true);
      setShowModal(false); // Hide the modal after successful login
      navigate("/dashboard");
      localStorage.setItem("playOnce", "true");
    } catch (err) {
      console.error("Error in manageSession flow:", err);
      setError("Failed to manage session. Please try again.");
      setShowModal(false); // Hide the modal on error
    }
    finally{setIssending(false)
    }
  };

  const handleLogin = async () => {
    try {
      const res = await loginWithEmail(email, password);

      // Check if response indicates an error
      if (
        res?.status === 400 &&
        res?.message === "Maximum number of devices reached"
      ) {
        setShowModal(true);

        // Call manageSession API with email if necessary
        await manageSession(email);
        return;
      }

      // If successful, proceed with storing tokens and navigation
      await storeTokens(res?.payload).then(async (token) => {
        await getUserProfile().then((res) => {
          localStorage.setItem("profile", JSON.stringify(res));
          addProfileData(res);
          setHeaderContent(true);
          navigate("/dashboard");
          localStorage.setItem("playOnce", "true");
        });
      });
    } catch (err) {
      console.error("Login error:", err);
      if (
        err?.status === 400 &&
        err?.message === "Maximum number of devices reached"
      ) {
        setShowModal(true);
      } else if (err?.message){
        setError(err?.message);
      }
      else {
        setError(
          "Incorrect Email ID and Password combination. Please try again." ||
          "An error occurred."
        );
      }
    }
  };

  const handleSubmit = async () => {
    if (!email || !password) {
      setError("Email and password are required.");
      return;
    }

    if (mode === "emailUpdation" && newEmail) {
      try {
        const loginRes = await loginWithEmail(email, password);

        // Check for 401 unauthorized
        if (loginRes?.status === 401) {
          setError("Invalid email or password.");
          return;
        }

        // Check for maximum devices error
        if (
          loginRes?.status === 400 &&
          loginRes?.message === "Maximum number of devices reached"
        ) {
          setShowModal(true);
          return;
        }

        await storeTokens(loginRes?.payload);

        const updateResponse = await updateEmailSettings({
          o_email: email,
          n_email: n_email,
          idToken: Cookies.get("idtoken"),
          oobCode: oobCode,
        });

        if (updateResponse.status === "success") {
          await storeTokens(updateResponse?.payload);
          if (isMobile) {
            navigate("/settings/email/1?isEmailUpdated=true");
          } else {
            navigate("/settings", {
              state: {
                showEmailSettings: true,
                showSuccessMessage: true,
              },
            });
          }
        }
      } catch (err) {
        console.error("Error:", err);

        // Check for maximum devices error in catch block
        if (
          err?.status === 400 &&
          err?.message === "Maximum number of devices reached"
        ) {
          setShowModal(true);
        } else {
          setError(err?.message || "An error occurred.");
        }
      }
    } else {
      await handleLogin();
    }
  };

  return (
    <div className="login-form-container">
      {mode !== "emailUpdation" && (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
        >
          <label>Email</label>
          <InputField
            style={{ marginBottom: "10px" }}
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus={true}
            onKeyDown={(e) => handleKeyDown(e, passwordInputRef)}
          />
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <label className="login-label">Password</label>
        <PasswordInput
          ref={passwordInputRef}
          value={password}
          onChange={handlePasswordChange}
          error={error}
          placeholder="Enter your password"
        />
        <Link
          to="/forgetpassword"
          className="forgetpassword"
          state={{ fromValidPage: true }}
        >
          Forgot password?
        </Link>
      </div>

      {error && <p className="error-message">{error}</p>}

      <button className="login-form-button" onClick={handleSubmit}>
        Login
      </button>

      {showModal && (
        <div className="login-modal">
          <div className="login-modal-content">
            <div className="managesession-header">
              <p className="managesession-para">
                Maximum number of devices reached.{" "}
              </p>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setShowModal(false)}
              />
            </div>
            <p className="managesession-para" style={{ fontSize: "12px" }}>
              Do you want to continue?
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <button
                className="managesession-cancelbtn"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="managesession-continuebtn"
                onClick={handleManageSession}
              >
                Yes, Continue
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginTop: "16px",
                marginBottom: "10px",
              }}
            >
              <Info />
              <p className="managesession-para" style={{ fontSize: "10px" }}>
                On continuing, your oldest session will be logged out.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailPassword;

import React, { useEffect, useState } from "react";
import arrowLeft from "../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useHeader } from "../../Providers/HeaderContextProvider";
import phone from "../../Assets/images/setting-phone.svg";
import bell from "../../Assets/images/setting-bell.svg";
import mic from "../../Assets/images/setting-mic.svg";
import email from "../../Assets/images/setting-email.svg";
import right from "../../Assets/images/chevron-right.svg";
import { isMobile } from "react-device-detect";

import "./style.css";
import ChatModal from "../../Components/ChatModal/ChatModal";
import PhoneSettingsScreen1 from "./PhonePopUp/PhoneSettingsScreen1";
import PhoneSettingsScreen2 from "./PhonePopUp/PhoneSettingsScreen2";
import PhoneSettingsScreen3 from "./PhonePopUp/PhoneSettingsScreen3";
import PhoneSettingsScreendDelete1 from "./PhonePopUp/PhoneSettingsScreendDelete1";
import PhoneSettingsScreenAddNewPhoneInitial from "./PhonePopUp/PhoneSettingsScreenAddNewPhoneInitial";
import EmailSettingsScreen1 from "./EmailPopUp/EmailSettingsScreen1";
import EmailSettingsScreen2 from "./EmailPopUp/EmailSettingsScreen2";
import EmailSettingsScreen3 from "./EmailPopUp/EmailSettingsScreen3";
import { link } from "d3";
import GradientBackground from "../../Components/AddRelationshipHeaderGradient";
import { getUserProfile } from "../../Services/MyProfile";

function Settings() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setHeaderContent,
    profileContent,

    addProfileData,
  } = useHeader();

  useEffect(() => {
    setHeaderContent(false);
  }, []);

  const [showPhoneSetting, setshowPhoneSetting] = useState(false);
  const [showEmailSetting, setshowEmailSetting] = useState(false);
  const [currentEmailScreen, setCurrentEmailScreen] = useState(1);
  const [showEmailSuccessMessage, setShowEmailSuccessMessage] = useState(false);
  const [showContactSuccessMessage, setShowContactSuccessMessage] =
    useState(false);
  const [currentPhoneScreen, setCurrentPhoneScreen] = useState(1);
  const [currentEmail, setCurrentEmail] = useState(profileContent?.email);
  const [currentPhone, setCurrentPhone] = useState(
    `${profileContent?.mobile?.country_code}${profileContent?.mobile?.contact}`
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.state?.showEmailSettings) {
      setshowEmailSetting(true);
      if (location.state?.showSuccessMessage) {
        setShowEmailSuccessMessage(true);
        setTimeout(() => {
          setShowEmailSuccessMessage(false);
        }, 3000);
      }
    }
  }, [location]);

  const fetchUserProfile = async () => {
    try {
      const profileData = await getUserProfile();
      localStorage.setItem("profile", JSON.stringify(profileData));
      addProfileData(profileData);
      setCurrentEmail(profileData.email);
      setCurrentPhone(
        `${profileData.mobile.country_code}${profileData.mobile.contact}`
      );
    } catch (error) {
      console.error("Error fetching user email:", error);
    }
  };

  const settingsMenu = [
    {
      id: 1,
      icon: phone,
      text: "Phone number",
      link: "/settings/phone/1",
    },
    {
      id: 2,
      icon: email,
      text: "Email",
      link: "/settings/email/1",
    },
    {
      id: 3,
      icon: bell,
      text: "Notifications",
      link: "/settings/notification",
    },
    {
      id: 4,
      icon: mic,
      text: "Communications",
      link: "/settings/communication",
    },
  ];

  const onSettingClick = (id) => {
    switch (id) {
      case 1:
        setshowPhoneSetting(true);
        break;
      case 2:
        setshowEmailSetting(true);
        break;
      default:
        break;
    }
  };

  const routeTo = (route) => {
    navigate(route);
  };

  console.log("current", currentPhoneScreen, showPhoneSetting);

  useEffect(() => {
    console.log("Phone screen:", currentPhoneScreen);
    console.log("Show phone setting:", showPhoneSetting);
  }, [currentPhoneScreen, showPhoneSetting]);

  return (
    <div>
      <GradientBackground>
        <header className="termsofuse-header">
          <div className="termsofuse-gradient-background ">
            <div className="termsofuse-nav-container">
              <div
                className="termsofuse-logo-container "
                onClick={() => navigate("/dashboard")}
              >
                <img
                  src={arrowLeft}
                  alt="Back"
                  className="termsofuse-back-button"
                />
              </div>
              <h1 className="head-not-txt">Settings</h1>
              <div className="termsofuse-space"></div>
            </div>
            <div className="termsofuse-action">Account info</div>
          </div>
        </header>

        <div className="settings-hero-container">
          <div className="set-acc-container">
            {settingsMenu?.map((_menu, index) => (
              <div
                className="set-accordian-menu"
                key={_menu?.id}
                onClick={() => {
                  if ((_menu?.id === 2 || _menu?.id === 1) && isMobile) {
                    console.log("mobile");
                    routeTo(_menu?.link);
                  } else if (
                    (_menu?.id === 2 || _menu?.id === 1) &&
                    !isMobile
                  ) {
                    console.log("desktop");
                    onSettingClick(_menu?.id);
                  } else {
                    routeTo(_menu?.link);
                    onSettingClick(_menu?.id);
                  }
                }}
              >
                <div className="set-left-section">
                  <img src={_menu?.icon} alt={_menu?.text} />

                  <div className="set-name">{_menu?.text}</div>
                </div>
                <div className="set-right-menu">
                  <img src={right} alt="right" />
                </div>
              </div>
            ))}
          </div>
          <ChatModal
            isOpen={showPhoneSetting && currentPhoneScreen === 1}
            isCloseNeed={true}
            children={
              <PhoneSettingsScreen1
                onEditClick={() => setCurrentPhoneScreen(2)}
                showContactSuccessMessage={showContactSuccessMessage}
                currentPhone={currentPhone}
              />
            }
            width={
              screenWidth < 780 ? "85%" : screenWidth < 1220 ? "55%" : "35%"
            }
            height={"auto"}
            padding={"18px"}
            onClose={() => {
              setshowPhoneSetting(false);
              setCurrentPhoneScreen(1);
            }}
          />
          <ChatModal
            isOpen={showPhoneSetting && currentPhoneScreen === 2}
            isCloseNeed={true}
            children={
              <PhoneSettingsScreen2
                onUpdateContactClick={() => setCurrentPhoneScreen(3)}
                currentPhone={currentPhone}
              />
            }
            width={
              screenWidth < 780 ? "85%" : screenWidth < 1220 ? "55%" : "35%"
            }
            height={"auto"}
            padding={"18px"}
            onClose={() => {
              setshowPhoneSetting(false);
              setCurrentPhoneScreen(1);
            }}
          />
          <ChatModal
            isOpen={showPhoneSetting && currentPhoneScreen === 3}
            isCloseNeed={true}
            children={
              <PhoneSettingsScreen3
                onVerifyOTP={() => {
                  fetchUserProfile();
                  setCurrentPhoneScreen(1);
                  setShowContactSuccessMessage(true);
                  setTimeout(() => {
                    setShowContactSuccessMessage(false);
                  }, 3000);
                }}
                onChangePhoneNumber={() => {
                  setCurrentPhoneScreen(1);
                }}
              />
            }
            width={
              screenWidth < 780 ? "85%" : screenWidth < 1220 ? "55%" : "35%"
            }
            height={"auto"}
            padding={"18px"}
            onClose={() => {
              setshowPhoneSetting(false);
              setCurrentPhoneScreen(1);
            }}
          />
          <ChatModal
            isOpen={false}
            isCloseNeed={true}
            children={<PhoneSettingsScreendDelete1 />}
            width={
              screenWidth < 780 ? "85%" : screenWidth < 1220 ? "55%" : "35%"
            }
            height={"auto"}
            padding={"18px"}
            onClose={() => setshowPhoneSetting(false)}
          />
          <ChatModal
            isOpen={false}
            isCloseNeed={true}
            children={<PhoneSettingsScreenAddNewPhoneInitial />}
            width={
              screenWidth < 780 ? "85%" : screenWidth < 1220 ? "55%" : "35%"
            }
            height={"auto"}
            padding={"18px"}
            onClose={() => setshowPhoneSetting(false)}
          />

          <ChatModal
            isOpen={showEmailSetting && currentEmailScreen === 1}
            isCloseNeed={true}
            children={
              <EmailSettingsScreen1
                onEditClick={() => setCurrentEmailScreen(2)}
                showSuccessMessage={showEmailSuccessMessage}
                currentEmail={currentEmail}
              />
            }
            width={
              screenWidth < 780 ? "85%" : screenWidth < 1220 ? "55%" : "35%"
            }
            height={"auto"}
            padding={"18px"}
            onClose={() => {
              setshowEmailSetting(false);
              setCurrentEmailScreen(1);
              setShowEmailSuccessMessage(false);
            }}
          />
          <ChatModal
            isOpen={showEmailSetting && currentEmailScreen === 2}
            isCloseNeed={true}
            children={
              <EmailSettingsScreen2
                onUpdateEmailClick={() => setCurrentEmailScreen(3)}
                currentEmail={currentEmail}
              />
            }
            width={
              screenWidth < 780 ? "85%" : screenWidth < 1220 ? "55%" : "35%"
            }
            height={"auto"}
            padding={"18px"}
            onClose={() => {
              setshowEmailSetting(false);
              setCurrentEmailScreen(1);
            }}
          />
          <ChatModal
            isOpen={showEmailSetting && currentEmailScreen === 3}
            isCloseNeed={true}
            children={<EmailSettingsScreen3 />}
            width={
              screenWidth < 780 ? "85%" : screenWidth < 1220 ? "55%" : "35%"
            }
            height={"auto"}
            padding={"18px"}
            onClose={() => {
              setshowEmailSetting(false);
              setCurrentEmailScreen(1);
            }}
          />
        </div>
      </GradientBackground>
    </div>
  );
}

export default Settings;

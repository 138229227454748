import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { ReactComponent as MenuIcon } from "../../Assets/images/menuIcon.svg";
import { ReactComponent as ArisIcon } from "../../Assets/images/aris.svg";
import { ReactComponent as BellIcon } from "../../Assets/images/bellIcon.svg";
import { ReactComponent as Close } from "../../Assets/images/close.svg";

import people from "../../Assets/images/people.png";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { ReactComponent as Settings } from "../../Assets/images/settings.svg";
import { ReactComponent as MyRelationship } from "../../Assets/images/myRelationship.svg";
import { ReactComponent as ReferAfriend } from "../../Assets/images/referAfriend.svg";
import { ReactComponent as AboutAris } from "../../Assets/images/aboutAris.svg";
import { ReactComponent as Support } from "../../Assets/images/support.svg";
import { ReactComponent as Subscription } from "../../Assets/images/subscription.svg";
import { ReactComponent as TermsOfUse } from "../../Assets/images/termsOfUse.svg";
import { ReactComponent as PrivacyPolicy } from "../../Assets/images/privacyPolicy.svg";
import { ReactComponent as SignOut } from "../../Assets/images/SignOut.svg";
import { ReactComponent as Mic } from "../../Assets/icon/Microphone-coaching.svg";
import { ReactComponent as DisabledMic } from "../../Assets/icon/coaching-microphone-gray.svg";
import { ReactComponent as Calendar } from "../../Assets/icon/calendar-white.svg";
import { signOut } from "../../Services/auth";
import { getNotification } from "../../Services/notificationAris";

import NotificationContainer from "../NotificationContainer/NotificationContainer";

import { getRelationships } from "../../Services/Realtionship";
import SessionSelection from "../../Pages/RelationShip/LiveSession/SessionSelection";
import ReferralPopup from "../../Pages/ReferralPopup";
import ChatModal from "../ChatModal/ChatModal";

import ScheduleContainer from "../ScheduleContainer/ScheduleContainer";
import { useHeader } from "../../Providers/HeaderContextProvider";
import { getSessions } from "../../Services/coaching";

function CircularImage({}) {
  const { profileContent } = useHeader();

  // const [userProfile, setUserProfile] = useState(null);
  console.log(profileContent, "profilecontent");

  return (
    <CircularProgressbarWithChildren
      value={profileContent?.profile_completion_percent || 0}
      strokeWidth={8}
      className={`header-progress`}
      styles={buildStyles({
        strokeLinecap: "butt",
        pathColor: " #BCF5A7",
        textColor: "#f88",
        trailColor: "#274079",
        backgroundColor: "#3e98c7",
      })}
    >
      <div
        onClick={() => {
          console.log("clicked");
          window.location.href = "/myprofile";
        }}
      >
        {profileContent?.profile_pic_url ? (
          <img
            src={profileContent?.profile_pic_url}
            alt="Circular"
            width={32}
            height={32}
            className="circular-image"
          />
        ) : (
          <div
            className="header-profile-circle-image"
            style={{ width: "2.50 rem", height: "2.50 rem" }}
          >
            {`${profileContent?.first_name?.[0]?.toUpperCase() || ""}${
              profileContent?.last_name?.[0]?.toUpperCase() || ""
            }`}
          </div>
        )}
      </div>
    </CircularProgressbarWithChildren>
  );
}

const aboutAris = () => {
  window.location.href = "/about-aris";
};
const termOfUse = () => {
  window.location.href = "/term-of-use";
};

const handleNavigate = () => {
  window.location.href = "/";
};

const signOutFunction = async () => {
  await signOut().then(() => handleNavigate());
};

const handleNavigateMyRelationship = () => {
  window.location.href = "/manageRelationship";
};

const onboarding = () => {
  window.location.href = "/customer-support";
  localStorage.clear();
};

const gotoManageMembership = () => {
  window.location.href = "/manageMembership";
};

const onboardingfunc = () => {
  onboarding();
};

const MyRelationshipfunc = async () => {
  handleNavigateMyRelationship();
};

function MenuComponent({ close, setShowPopup, setShowLogoutPopup }) {
  const [pendingRequestCount, setPendingRequestCount] = useState(0);

  useEffect(() => {
    const loadPendingRequests = async () => {
      try {
        const pendingRequests = await getRelationships();
        setPendingRequestCount(pendingRequests.length || 0);
      } catch (error) {
        console.error("Failed to fetch pending requests", error);
      }
    };

    loadPendingRequests();
  }, []);

  const menuOptions = [
    {
      id: 1,
      name: "Settings",
      link: "/settings",
      icon: <Settings />,
      function: () => (window.location.href = "/settings"),
    },
    {
      id: 2,
      name: "My Relationships",
      link: "/manageRelationship",
      icon: <MyRelationship />,
      function: handleNavigateMyRelationship,
    },
    // {
    //   id: 3,
    //   name: "Refer a friend",
    //   link: null,
    //   icon: <ReferAfriend />,
    //   function: () => setShowPopup(true),
    // },
    {
      id: 4,
      name: "About Aris",
      link: "/about-aris",
      icon: <AboutAris />,
      function: aboutAris,
    },
    {
      id: 5,
      name: "Support",
      link: "/customer-support",
      icon: <Support />,
      function: onboarding,
    },
    {
      id: 6,
      name: "Manage Membership",
      link: "/",
      icon: <Subscription />,
      function: gotoManageMembership,
    },
  ];

  const fixedMenuOptions = [
    {
      id: 1,
      name: "Terms of Use",
      link: "/term-of-use",
      icon: <TermsOfUse />,
      function: termOfUse,
    },
    {
      id: 2,
      name: "Privacy Policy",
      link: "/",
      icon: <PrivacyPolicy />,
      function: () => {},
    },
    {
      id: 3,
      name: "Logout",
      link: "/",
      icon: <SignOut />,
      function: () => setShowLogoutPopup(true),
    },
  ];

  return (
    <div className="menu-hero-container">
      <div className="menu-cust-container">
        <div className="header-option-close-icon" onClick={close}>
          <Close />
        </div>
        {menuOptions.map((item) => (
          <div
            className="menu-txt-container"
            key={item.id}
            onClick={item.function}
          >
            {item.icon}
            <span>{item.name}</span>
            {item.id === 2 && (
              <span className="pending-relationship-badge">
                {pendingRequestCount}
              </span>
            )}
          </div>
        ))}
      </div>

      <div className="menu-cust-container fixed-cnt">
        {fixedMenuOptions.map((item) => (
          <div
            className="menu-txt-container"
            key={item.id}
            onClick={item.function}
          >
            {item.icon} {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}

function HeaderComponent({ headerContentRightSideContent, profileContent }) {
  const [showMenu, setShowMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showLiveSessionModal, setshowLiveSessionModal] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [enableCoaching, setEnableCoaching] = useState(false);

  const calendarRef = useRef(null);
  const notificationRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      getNotification({ is_read: false, page: 1 })
        .then((response) => {
          if (response && Array.isArray(response.content)) {
            setNotificationCount(response.content.length || 0);
          } else {
            console.log(response, "noti");
            setNotificationCount(0);
          }
        })
        .catch((error) => {
          setNotificationCount(0);
          console.error("Error fetching notifications:", error);
        });
    };

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setShowNotification(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await getSessions();
        const mins = response.payload.minutes;
        // const mins = 0;
        if (mins > 0) {
          setEnableCoaching(true);
        }
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    };

    fetchSessions();
  }, []);

  return (
    <div className="header-component-hero-container">
      <ChatModal
        onClose={() => setShowLogoutPopup(false)}
        width={"300px"}
        height={"auto"}
        padding={"0px"}
        isOpen={showLogoutPopup}
        children={
          <div>
            <div className="logout-modal-container">
              <div className="logout-modal-header">Log Out</div>

              <div className="logout-modal-body">
                Are you sure you want to log out?
              </div>
            </div>
            <div className="logout-modal-button-container">
              <div
                className="logout-cancel"
                onClick={() => setShowLogoutPopup(false)}
              >
                Cancel
              </div>
              <div
                className="logout-cancel logout-btn"
                onClick={signOutFunction}
              >
                Log out
              </div>
            </div>
          </div>
        }
      />
      <div className="header-component-left-container">
        <div
          className="header-menu-icon-container"
          onClick={() => {
            setShowNotification(false);
            setShowCalendar(false);
            setShowMenu((prev) => !prev);
          }}
        >
          {!showMenu ? <MenuIcon /> : <Close />}
          {showMenu && (
            <MenuComponent
              close={() => {
                setShowNotification(false);
                setShowMenu((prev) => !prev);
              }}
              setShowPopup={setShowPopup}
              setShowLogoutPopup={setShowLogoutPopup}
            />
          )}
        </div>

        {(screenWidth <= 480 || screenHeight < 720) &&
          headerContentRightSideContent &&
          (enableCoaching ? (
            <div
              className="coaching-button-container"
              onClick={() => setshowLiveSessionModal(true)}
            >
              <Mic className="mic-icon" />
              <span>Start live session</span>
            </div>
          ) : (
            <div
              className={`coaching-button-container disabled`}
              data-tooltip-id="tooltip-anchor"
            >
              <DisabledMic width={20} height={20} />
              <span>Start live session</span>
            </div>
          ))}
      </div>

      <div className="header-aris-icon">
        <ArisIcon />
      </div>
      {headerContentRightSideContent ? (
        <div className="header-notification-container">
          <div
            className="header-menu-icon-container-notification bell-icon-container"
            onClick={() => {
              setShowCalendar(!showCalendar);
              setShowMenu(false);
              setShowNotification(false);
            }}
          >
            <Calendar />
          </div>
          {showCalendar && (
            <div ref={calendarRef}>
              <ScheduleContainer setshowCalendar={setShowCalendar} />
            </div>
          )}
          <div
            className="header-menu-icon-container-notification bell-icon-container"
            onClick={() => {
              setShowMenu(false);
              setShowCalendar(false);
              setShowNotification((prev) => !prev);
            }}
          >
            <BellIcon />
            {notificationCount > 0 && (
              <div className="notification-badge">{notificationCount}</div>
            )}
          </div>
          {showNotification && (
            <div ref={notificationRef}>
              <NotificationContainer
                onRouted={() => setShowNotification(false)}
                setshowNotification={setShowNotification}
                showNotification={showNotification}
              />
            </div>
          )}
          <div className="header-circle-image-container">
            <CircularImage userProfile={profileContent} />
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {showPopup && <ReferralPopup onClose={() => setShowPopup(false)} />}
      {showLiveSessionModal && (
        <SessionSelection
          showLiveSessionModal={showLiveSessionModal}
          setShowLiveSessionModal={setshowLiveSessionModal}
          onClose={() => setshowLiveSessionModal(false)}
        />
      )}
    </div>
  );
}

export default HeaderComponent;

// NavigationButton.jsx
import React from "react";
import "./style.css";
import backBtn from "../../Assets/images/arrow-left.png";

const NavigationButton = ({ onClick }) => {
  return (
    <>
      <div className="back-btn-container">
        <button onClick={onClick} className="back-btn">
          <img src={backBtn} alt="arrow-left" className="arrow-left" />
          Back
        </button>
      </div>
    </>
  );
};

export default NavigationButton;

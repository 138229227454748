import React, { useEffect, useState } from "react";
import edit from "../../../Assets/images/edit.svg";
import tickIcon from "../../../Assets/icon/tick-icon.svg";

function EmailSettingsScreen1({ onEditClick, showSuccessMessage, currentEmail }) {

  return (
    <div className="set-phone-modal-container">
      <div>
        <div className="set-phone-modal-head">Email</div>
        <div className="set-phone-modal-support">
          Your email is used to recover your account.
        </div>
      </div>
      <div className="set-phone-int-cnt" style={{ marginBottom: "10px" }}>
        <div className="set-phone-modal-input-container">
          <div className="set-phone-input-label">Email</div>
          <div className="mobile-input-wrapper">
            <input
              type="text"
              id="mobile-number"
              className="mobile-input"
              placeholder="email"
              value={currentEmail}
              disabled={true}
              style={{ cursor: "not-allowed" }}
              readOnly
            />
            <button className="edit-button" onClick={(e) => {
    e.stopPropagation();
    onEditClick();
  }}>
              <span role="img" aria-label="edit">
                <img src={edit} alt="edit" />
              </span>{" "}
              Edit
            </button>
          </div>
          {showSuccessMessage && (
            <div className="email-settings-success-box">
              <img src={tickIcon} alt="success" />
              Your email has been changed
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailSettingsScreen1;

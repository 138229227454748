// import React from "react";
// import "./style.css"; // Styles for Carousel-container
// import PlaceholderImage1 from '../../assets/Ellipse 39.png'; // You can replace this with your actual image path
// import PlaceholderImage2 from '../../assets/Ellipse 37.png'; // You can replace this with your actual image path
// const CarouselContainer = () => {
//     return (
//         <div className="carousel-container">
//             <img src={PlaceholderImage1} alt="Carousel container visual" className="elipse37" />

//             <div className="ellipse">
//             <img src={PlaceholderImage2} alt="Carousel container visual" className="elipse39" />
//             </div>
           
//         </div>
//     );
// };

// export default CarouselContainer;


import React from "react";
import "./style.css"; // Custom styles for carousel containers
import PlaceholderImage2 from "../../Assets/images/Ellipse 37.png";
import PlaceholderImage1 from "../../Assets/images/Ellipse 39.png";
import PlaceholderImage3 from "../../Assets/images/Ellipse 38.svg";
import PlaceholderImage4 from "../../Assets/images/Ellipse 40.svg";
import PlaceholderImage5 from "../../Assets/images/Ellipse 41.png";
import PlaceholderImage6 from "../../Assets/images/Ellipse 42.png";
import PlaceholderImage7 from "../../Assets/images/Ellipse 43.png";
import TopTriangle from "../../Assets/images/Polygon 4.svg";
import BottomTriangle from "../../Assets/images/Polygon 5.svg";
/** First container */
export const FirstContainer = () => (
  <div className="first-carousel-container">
    <img
      src={PlaceholderImage1}
      alt="Carousel container visual"
      
    />
    <div className="ellipse">
    <div className="first-ellipse">
      <img
        src={PlaceholderImage2}
        alt="Carousel container visual"
       
      />
      </div>
    </div>
  </div>
);

/** Second container */
export const SecondContainer = () => (
  <div className="second-carousel-container">
    <div className="second-box-wrapper">
      <div className="second-filled-box"></div>
      <div className="second-empty-box">
        <img src={PlaceholderImage4} alt="" />

      </div>
      <div className="second-empty-box">
        <img src={PlaceholderImage3} alt="" />
      </div>
      <div className="second-filled2-box"></div>
    </div>
  </div>
);


export const ThirdContainer = () => (
    <div className="third-carousel-container">
      <div className="third-image-wrapper">
        {/* Circle behind the images */}
        <div className="circle"></div>
  

        <div className="third-image-wrapper2">
        {/* Empty div for spacing */}
       
        {/* First Image */}
        <div className="third-image">
          <img src={PlaceholderImage5} alt="Image 1" />
        </div>
        <div className="third-empty"></div>
  
  
        {/* Empty div for spacing */}
        <div className="third-empty"></div>
        {/* Second Image */}
        <div className="third-image">
          <img src={PlaceholderImage6} alt="Image 2" />
        </div>
        </div>
      </div>
    </div>
  );



  export const FourthContainer = () => (
    <div className="fourth-carousel-container">
      <div className="image-triangle-wrapper">
        {/* Left Image */}
        <div className="portrait-image">
          <img src={PlaceholderImage7} alt="Portrait" />
        </div>
  
        {/* Triangle Images */}
        <div className="triangle-group">
          <img src={TopTriangle} alt="Top Triangle" className="triangle top-triangle" />
          <img src={BottomTriangle} alt="Bottom Triangle" className="triangle bottom-triangle" />
        </div>
      </div>
    </div>
  );
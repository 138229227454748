import React from "react";
import ChatModal from "../../Components/ChatModal/ChatModal";
import PhoneSettingsScreen1 from "../Settings/PhonePopUp/PhoneSettingsScreen1";
import "./style.css";
import TabSelectionFeeedback from "./TabSelection";

function Feed3() {
  return (
    <div className="set-phone-modal-container">
      <div>
        <div className="set-phone-modal-head display-center">
          Share your feedback!
        </div>
        <div className="set-phone-modal-support display-center">
          Choose what you'd like to improve:
        </div>
      </div>

      <div className="set-phone-int-cnt">
        <TabSelectionFeeedback />
        <div className="fb-txt-input">Tell us more</div>
        <div className="cs-input-container">
          <textarea className="cs-txt-area txt-area-fb-width" />
          <div className="cs-assist-txt">This is a hint text to help user.</div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginBottom: "20px",
            marginTop: "20px",
            position: "relative",
          }}
        >
          <button className="set-phone-cancel-button"> Done</button>
          <button className="set-phone-submit-button"> Submit</button>
        </div>
      </div>
    </div>
  );
}

function Feedback() {
  return (
    <div>
      <ChatModal
        isOpen={true}
        isCloseNeed={true}
        children={<Feed3 />}
        width={"40%"}
        height={"auto"}
        padding={" 56px 24px 32px 24px"}
        onClose={{}}
      />
    </div>
  );
}

export default Feedback;

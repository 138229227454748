import React, { useEffect } from "react";
import DefaultLoader from "../LoadingPage/DefaultLoader";
import { startQuestions } from "../../Services/Assessment";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../Services/MyProfile";

function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileAndQuestions = async () => {
      try {
        const response = await getUserProfile();
        console.log("response from profile", response);

        const { mobile, first_name, last_name, DOB, gender, aris_voice, user_type, stripe_customer_id, load_faith } = response;

        if (!mobile?.contact) {
          navigate('/signup?step=3&mode=verifyMobile');
          return;
        } else if (!first_name || !last_name || !DOB) {
          navigate('/signup?step=4&mode=verifyBasicDetails');
          return;
        } else if (!gender || !aris_voice) {
          navigate('/signup?step=5&mode=verifyGenderPronouns');
          return;
        } else if (user_type === 'primary' && stripe_customer_id === '') {
          navigate('/signup?step=6&mode=verifySubscription')
        } else if (user_type === 'sub-user' && load_faith === true) {
          navigate('/signup?step=7&mode=verifyFaith')
        } else {
          try {
            const res = await startQuestions();
            console.log("response from assessment", res);
            if (res?.categoryID !== null) {
              navigate(`/onboarding/chat`);
            } else {
              navigate(`/chat`);
            }
          } catch (error) {
            console.log("error from assessment", error);
          }
        }
      } catch (error) {
        console.log("error from profile", error);
      }
    };

    fetchProfileAndQuestions();
  }, []);

  return (
    <div>
      <DefaultLoader />
    </div>
  );
}

export default Dashboard;
import React, { useEffect, useRef, useState } from "react";
import "./style.css";

const ScrollEffect = ({ text, audioSrc, pause }) => {
  const scrollContainerRef = useRef(null);
  const audioRefScroll = useRef(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    // Calculate total scrollable height dynamically based on content
    if (scrollContainerRef.current) {
      setScrollHeight(scrollContainerRef.current.scrollHeight);
    }
  }, [text]);

  useEffect(() => {
    const audio = audioRefScroll.current;

    const handleLoadedMetadata = () => {
      setAudioDuration(audio.duration);
    };

    if (audio) {
      audio.addEventListener("loadedmetadata", handleLoadedMetadata);

      return () => {
        audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      };
    }
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const syncScrollWithAudio = () => {
      if (audioRefScroll.current && scrollContainer && audioDuration > 0) {
        const currentTime = audioRefScroll.current.currentTime;
        const scrollTop =
          (currentTime / audioDuration) *
          (scrollHeight - scrollContainer.clientHeight);
        scrollContainer.scrollTop = scrollTop;
      }
    };

    let frameId;
    const updateScroll = () => {
      syncScrollWithAudio();
      frameId = requestAnimationFrame(updateScroll);
    };

    if (!pause) {
      frameId = requestAnimationFrame(updateScroll);
    }

    return () => {
      cancelAnimationFrame(frameId);
    };
  }, [audioDuration, scrollHeight, pause]);

  useEffect(() => {
    if (pause) {
      audioRefScroll.current.pause();
    } else {
      audioRefScroll.current.play();
    }
  }, [pause]);

  return (
    <div className="scroll-wrapper">
      <audio ref={audioRefScroll} src={audioSrc} autoPlay muted />
      <div
        className="scroll-container"
        ref={scrollContainerRef}
        style={{
          overflow: "hidden",
          height: "70px",
          position: "relative",
        }}
      >
        <div
          className="scroll-content"
          style={{
            lineHeight: "2rem",
            whiteSpace: "pre-wrap",
            color: "#fff",
            fontFamily: "Urbanist",
            fontSize: "1.25rem",
            fontWeight: 500,
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default ScrollEffect;

import React from "react";

function Personality9() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 107"
      fill="none"
    >
      <g filter="url(#filter0_f_2161_30188)">
        <path
          d="M36.361 16.8296L38.3924 23.0723H44.9661L39.6478 26.9305L41.6792 33.1731L36.361 29.3149L31.0427 33.1731L33.0741 26.9305L27.7559 23.0723H34.3296L36.361 16.8296Z"
          fill="white"
        >
          <animate
            attributeName="opacity"
            values="0.5;1;0.5"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
      <g filter="url(#filter1_f_2161_30188)">
        <path
          d="M63.2883 36.1318L66.7254 46.7005H77.8481L68.8497 53.2323L72.2868 63.801L63.2883 57.2692L54.2899 63.801L57.727 53.2323L48.7285 46.7005H59.8512L63.2883 36.1318Z"
          fill="white"
          fill-opacity="0.4"
        >
          <animate
            attributeName="opacity"
            values="0.5;1;0.5"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
      <g filter="url(#filter2_f_2161_30188)">
        <path
          d="M27.7524 53.4023L29.3985 58.4736H34.7255L30.4159 61.6078L32.062 66.6791L27.7524 63.5449L23.4428 66.6791L25.0889 61.6078L20.7793 58.4736H26.1063L27.7524 53.4023Z"
          fill="white"
        >
          <animate
            attributeName="opacity"
            values="0.5;1;0.5"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
      <path
        d="M17.1836 20.9911L79.8479 4.7915L89.5862 93.461L26.186 61.3372L17.1836 20.9911Z"
        fill="#45D9BF"
      />
      <path
        d="M17.1836 20.9911L79.8479 4.7915M17.1836 20.9911L26.186 61.3372M17.1836 20.9911L89.5862 93.461M79.8479 4.7915L89.5862 93.461M79.8479 4.7915L26.186 61.3372M89.5862 93.461L26.186 61.3372"
        stroke="white"
        stroke-opacity="0.7"
        stroke-width="0.211394"
        stroke-miterlimit="10"
      />
      <path
        d="M89.4805 93.2493L79.8479 4.7915L17.1836 21.0649L89.4805 93.2493Z"
        fill="#31C5AB"
      />
      <defs>
        <filter
          id="filter0_f_2161_30188"
          x="20.0706"
          y="9.14434"
          width="32.5814"
          height="31.7142"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30188"
          />
        </filter>
        <filter
          id="filter1_f_2161_30188"
          x="41.0433"
          y="28.4466"
          width="44.4896"
          height="43.0394"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30188"
          />
        </filter>
        <filter
          id="filter2_f_2161_30188"
          x="13.094"
          y="45.7171"
          width="29.3158"
          height="28.6473"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30188"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Personality9;

import React, { useState, useEffect } from "react";
import { ReactComponent as Close } from "../../Assets/icon/close.svg";
import { ReactComponent as Phone } from "../../Assets/icon/Phone.svg";
import { ReactComponent as Email } from "../../Assets/icon/envelope.svg";
import { ReactComponent as Right } from "../../Assets/icon/chevron-right.svg";
import { ReactComponent as RightArrow } from "../../Assets/images/arrow-right.svg";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { addlicensedusers } from "../../Services/subscription";

const InvitePopup = ({
  onClose,
  relationId,
  name,
  onEmailPhoneSubmit,
  listedMembers,
  currentMemberIndex = 0,
  onMemberComplete,
  isOnBoarding,
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  // Store all members' contact details
  const [memberDetails, setMemberDetails] = useState([]);

  // Track current member being edited
  const [currentIndex, setCurrentIndex] = useState(currentMemberIndex);

  // Initialize member details when component mounts
  useEffect(() => {
    const initialMembers = Array(listedMembers)
      .fill()
      .map(() => ({
        phone: "",
        email: "",
        phoneObj: { code: null, contact: null },
      }));
    setMemberDetails(initialMembers);
  }, [listedMembers]);

  // useEffect(()=>{
  //   if(listedMembers===memberDetails?.length){
  //     console.log("checkIsOk")
  //   }
  // },[listedMembers,memberDetails])

  const parsePhoneNumber = (phoneValue) => {
    if (!phoneValue) return { code: null, contact: null };

    try {
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneValue);
      if (!parsedPhoneNumber) return { code: null, contact: null };

      return {
        code: `+${parsedPhoneNumber.countryCallingCode}`,
        contact: parsedPhoneNumber.nationalNumber,
      };
    } catch (error) {
      console.error("Error parsing phone number:", error);
      return { code: null, contact: null };
    }
  };

  const handlePhoneChange = (value) => {
    const parsedPhone = parsePhoneNumber(value);

    const updatedMembers = [...memberDetails];

    // Initialize the current member if it doesn't exist
    if (!updatedMembers[currentIndex]) {
      updatedMembers[currentIndex] = {
        phone: "",
        email: "",
        phoneObj: { code: null, contact: null },
      };
    }

    // Set the phone value and the parsed object
    updatedMembers[currentIndex].phone = value;
    updatedMembers[currentIndex].phoneObj = parsedPhone;

    setMemberDetails(updatedMembers);
  };

  const handleEmailChange = (value) => {
    const updatedMembers = [...memberDetails];

    // Initialize the current member if it doesn't exist
    if (!updatedMembers[currentIndex]) {
      updatedMembers[currentIndex] = {
        phone: "",
        email: "",
        phoneObj: { code: null, contact: null },
      };
    }

    updatedMembers[currentIndex].email = value;
    setMemberDetails(updatedMembers);
  };

  const handleNext = () => {
    // Save current member data
    const currentMember = memberDetails[currentIndex];
    let formattedContact = null;

    if (activeTab === "sms" && currentMember?.phoneObj) {
      const { code, contact } = currentMember.phoneObj;
      formattedContact = {
        phone: code && contact ? { code, contact } : null,
      };
    } else if (activeTab === "email" && currentMember?.email) {
      formattedContact = {
        email: currentMember.email,
      };
    }

    // Pass single member data to parent if needed
    if (onEmailPhoneSubmit && formattedContact) {
      onEmailPhoneSubmit({
        ...formattedContact,
        memberIndex: currentIndex,
      });
    }

    if (currentIndex < listedMembers - 1) {
      // Move to next member
      setCurrentIndex(currentIndex + 1);
      setActiveTab(null); // Reset tab selection for next member

      if (onMemberComplete) {
        onMemberComplete();
      }
    } else {
      // All members completed
      let formattedUserCred = memberDetails?.map((member) => {
        return {
          email: member?.email,
          mobile: {
            code: member?.phoneObj?.code,
            contact: member?.phoneObj?.contact,
          },
        };
      });
      if (isOnBoarding) {
        onEmailPhoneSubmit(formattedUserCred);
      } else {
        addlicensedusers({
          licensed_users: formattedUserCred,
          payment_method: "card",
        }).then((res) => {
          console.log(formattedUserCred, "kjhfgjdhg");

          window.location.href = res?.payload?.checkout_url;
          console.log(res, "kjhfgjdhg");
        });
      }

      console.log(formattedUserCred, "kjhfgjdhg");
      handleSubmitAll();
    }
  };

  const handleSubmitAll = () => {
    setIsSubmitting(true);

    // Format all member details
    const formattedMembers = memberDetails
      .map((member) => {
        const contactInfo = {};

        if (member?.phoneObj) {
          const { code, contact } = member.phoneObj;
          if (code && contact) {
            contactInfo.phone = { code, contact };
          }
        }

        if (member?.email) {
          contactInfo.email = member.email;
        }

        return contactInfo;
      })
      .filter(
        (member) =>
          (member.phone?.code && member.phone?.contact) || member.email
      );

    // Show success message
    setShowSuccess(true);

    // Close modal after delay
    setTimeout(() => {
      onClose();
    }, 2000);

    setIsSubmitting(false);
  };

  const isNextDisabled = () => {
    const currentMember = memberDetails[currentIndex];

    if (!currentMember) return true;

    if (activeTab === "sms") {
      return !(currentMember.phoneObj?.code && currentMember.phoneObj?.contact);
    } else if (activeTab === "email") {
      return !currentMember.email;
    }

    return true; // Disabled if no tab is selected
  };

  console.log(memberDetails, "memberDetails");
  return (
    <div className="join-aris-overlay">
      <div className="join-aris-modal">
        {showSuccess ? (
          <div className="flex items-center justify-center h-full">
            <div className="text-center p-6 bg-green-50 rounded-lg">
              <p className="text-green-700 text-lg font-medium">
                All members added successfully!
              </p>
            </div>
          </div>
        ) : (
          <>
            {!activeTab ? (
              <>
                <div
                  className="join-aris-header"
                  style={{ marginBottom: "0px" }}
                >
                  <h2 className="join-aris-title">
                    <span>{name}</span> to join Aris
                  </h2>

                  <button onClick={onClose} className="join-aris-close-btn">
                    <Close />
                  </button>
                </div>
                <div style={{ marginBottom: "24px" }}>
                  Member {currentIndex + 1} of {listedMembers}: Invite
                </div>

                <div
                  onClick={() => setActiveTab("sms")}
                  className="join-aris-button-flex"
                  style={{ marginBottom: "16px" }}
                >
                  <div className="join-aris-option-btn">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Phone />
                      via Text Message
                    </div>
                    <div>
                      <Right
                        style={{
                          padding: "5px",
                          borderRadius: "50%",
                          background: "#FFF",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => setActiveTab("email")}
                  className="join-aris-button-flex"
                >
                  <div className="join-aris-option-btn">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Email />
                      via email
                    </div>
                    <div>
                      <Right
                        style={{
                          padding: "5px",
                          borderRadius: "50%",
                          background: "#FFF",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : activeTab === "sms" ? (
              <>
                <div
                  className="join-aris-header"
                  style={{ marginBottom: "0px" }}
                >
                  <div>
                    <h2 className="join-aris-title">Notify via Text Message</h2>
                  </div>
                  <button
                    onClick={() => setActiveTab(null)}
                    className="join-aris-close-btn"
                  >
                    <Close />
                  </button>
                </div>
                <div style={{ marginBottom: "24px" }}>
                  Member {currentIndex + 1} of {listedMembers}: Invite
                </div>
                <p
                  className="join-aris-subtitle"
                  style={{ marginBottom: "0px" }}
                >
                  Add mobile number and we will send invitation via text message
                </p>
                <p className="join-aris-input-heading">Mobile Number</p>
                <PhoneInput
                  international
                  defaultCountry="US"
                  value={memberDetails[currentIndex]?.phone || ""}
                  onChange={handlePhoneChange}
                  className="addrelationship-phoneinput"
                  placeholder="Enter phone number"
                />
              </>
            ) : (
              <>
                <div
                  className="join-aris-header"
                  style={{ marginBottom: "0px" }}
                >
                  <div>
                    <h2 className="join-aris-title">Notify via Email</h2>
                  </div>
                  <button
                    onClick={() => setActiveTab(null)}
                    className="join-aris-close-btn"
                  >
                    <Close />
                  </button>
                </div>
                <div style={{ marginBottom: "24px" }}>
                  Member {currentIndex + 1} of {listedMembers}
                </div>
                <p className="join-aris-subtitle">
                  Add email and we will send invitation via email
                </p>
                <p className="join-aris-input-heading">Email</p>
                <input
                  type="email"
                  placeholder="email@example.com"
                  className="join-aris-input"
                  value={memberDetails[currentIndex]?.email || ""}
                  onChange={(e) => handleEmailChange(e.target.value)}
                />
              </>
            )}

            {activeTab && (
              <button
                onClick={handleNext}
                className="join-aris-submit-btn"
                disabled={isNextDisabled()}
                style={{ marginTop: "24px" }}
              >
                {isSubmitting
                  ? "Sending..."
                  : currentIndex + 1 < listedMembers
                  ? "Next Member"
                  : "Complete"}
                <RightArrow />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InvitePopup;

import React from "react";
import "./style.css";
import graph from "../../Assets/images/Graph4.png";
import PotraitsSummaryWithList from "../../Components/PotraitsSummaryWithList/PotraitsSummaryWithList";
import PotraitsConflictManagement from "../../Components/PotraitsConflictManagementDetails/PotraitsConflictManagement";
import BarChartTest from "../../Components/PotraitsGraph/TestGraph";

import PotraitsCommonHeadBottom from "./PotraitsCommonHeadBottom";
import PotraitsCommonHeadBottomSecondary from "./PotraitsCommonHeadBottomSecondary";
import ShowMoreLess from "./ShowMoreLess";
import TopStrength from "./TopStrength";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
function ConflictManagement({ chartData, otherData, traitPriorities }) {
  const data = [
    {
      category: "Collaborating",
      value: chartData?.Collaborate,
      isSecondary: traitPriorities[1] === "Collaborate",
    },
    {
      category: "Accommodating",
      value: chartData?.Accommodate,
      isSecondary: traitPriorities[1] === "Accommodate",
    },
    {
      category: "Compromising",
      value: chartData?.Compromise,
      isSecondary: traitPriorities[1] === "Compromise",
    },
    {
      category: "Avoiding",
      value: chartData?.Avoid,
      isSecondary: traitPriorities[1] === "Avoid",
    },
    {
      category: "Competing",
      value: chartData?.Compete,
      isSecondary: traitPriorities[1] === "Compete",
    },
  ];

  console.log(traitPriorities, "traitPriorities", data);
  console.log(
    "😏",
    otherData?.portraitMatrixMap[traitPriorities[0]]?.title?.description
  );

  // jwtDecode;
  // let token = Cookies.get("idtoken" || "") || "";
  // let nameFromToken = token ? jwtDecode(token)?.name : "name";

  return (
    <div className="personality-hero-container">
      <div className="potraits-tab-header-txt">Conflict management</div>
      <div className="potraits-graph-summary-cntr">
        <BarChartTest data={data} />
        {/* <img src={graph} alt="graph" className="potraits-graph-img" /> */}
        <div className="potraits-separater-line"></div>
        <div className="potraits-graph-summary-txt-cntr">
          <div className="potraits-content-head-txt">Summary</div>
          <div className="potraits-sub-txt">
            {otherData?.summary?.description}
          </div>
        </div>
      </div>
      {/* <div className="potraits-percent-summary-hero-cntr ">
        <PotraitsConflictManagement
          superTxt={otherData?.card1?.label}
          listArr={otherData?.card1?.text?.options?.items}
        />
        <PotraitsConflictManagement
          superTxt={otherData?.card2?.label}
          listArr={otherData?.card2?.text?.options?.items}
        />
      </div>  */}

      <div className="conflict-management-primary">Primary</div>
      <div className="conflict-management-primary-heading">
        {
          otherData?.portraitMatrixMap[
            traitPriorities[0]
          ]?.title?.description?.split("\n")[0]
        }
      </div>
      <div className="conflict-management-primary-para">
        {
          otherData?.portraitMatrixMap[
            traitPriorities[0]
          ]?.title?.description?.split("\n")[1]
        }
      </div>
      <div className="conflict-management-primary-para">
        {
          otherData?.portraitMatrixMap[traitPriorities[0]]?.description
            ?.description
        }
      </div>


      {/* <ShowMoreLess
        primarHeading={
          otherData?.portraitMatrixMap[traitPriorities[0]]?.title?.description
        }
        primaryData={
          otherData?.portraitMatrixMap[traitPriorities[0]]?.title?.description
        }
      />

      <ShowMoreLess
        primarHeading={
          otherData?.portraitMatrixMap[traitPriorities[0]]?.title?.description
        }
        primaryData={
          otherData?.portraitMatrixMap[traitPriorities[0]]?.title?.description
        }
      />

      <ShowMoreLess
        primarHeading={
          otherData?.portraitMatrixMap[traitPriorities[0]]?.title?.description
        }
        primaryData={
          otherData?.portraitMatrixMap[traitPriorities[0]]?.title?.description
        }
      /> */}

      <TopStrength
        topStrengths={
          otherData?.portraitMatrixMap[traitPriorities[0]]?.topStrengths
        }
        topStrengthsToolTip={
          otherData?.portraitMatrixMap[traitPriorities[0]]?.topStrengthsToolTip
        }
        isBlind={false}
      />

      <TopStrength
        topStrengths={
          otherData?.portraitMatrixMap[traitPriorities[0]]?.potentialBlindSpots
        }
        topStrengthsToolTip={
          otherData?.portraitMatrixMap[traitPriorities[0]]
            ?.potentialBlindSpotsToolTip
        }
        isBlind={true}
      />

      <div className="conflict-management-primary">Secondary</div>
      <div className="conflict-management-primary-heading">
        {
          otherData?.portraitMatrixMap[
            traitPriorities[1]
          ]?.title?.description?.split("\n")[0]
        }
      </div>
      <div className="conflict-management-primary-para">
        {
          otherData?.portraitMatrixMap[
            traitPriorities[1]
          ]?.title?.description?.split("\n")[1]
        }
      </div>
      <div className="conflict-management-primary-para">
        {
          otherData?.portraitMatrixMap[traitPriorities[1]]?.description
            ?.description
        }
      </div>

      {/* <ShowMoreLess
        primarHeading={
          otherData?.portraitMatrixMap[traitPriorities[1]]?.title?.description
        }
        primaryData={
          otherData?.portraitMatrixMap[traitPriorities[1]]?.title?.description
        }
      />

      <ShowMoreLess
        primarHeading={
          otherData?.portraitMatrixMap[traitPriorities[1]]?.title?.description
        }
        primaryData={
          otherData?.portraitMatrixMap[traitPriorities[1]]?.title?.description
        }
      />

      <ShowMoreLess
        primarHeading={
          otherData?.portraitMatrixMap[traitPriorities[1]]?.title?.description
        }
        primaryData={
          otherData?.portraitMatrixMap[traitPriorities[1]]?.title?.description
        }
      /> */}

      <TopStrength
        topStrengths={
          otherData?.portraitMatrixMap[traitPriorities[1]]?.topStrengths
        }
        topStrengthsToolTip={
          otherData?.portraitMatrixMap[traitPriorities[1]]?.topStrengthsToolTip
        }
        isBlind={false}
      />

      <TopStrength
        topStrengths={
          otherData?.portraitMatrixMap[traitPriorities[1]]?.potentialBlindSpots
        }
        topStrengthsToolTip={
          otherData?.portraitMatrixMap[traitPriorities[1]]
            ?.potentialBlindSpotsToolTip
        }
        isBlind={true}
      />

      <div className="personality-other-data-container">
        {/* <PotraitsCommonHeadBottomSecondary
          primary={"Primary"}
          heading={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[0]]
              ?.title
          }
          summary={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[0]]
              ?.description
          }
        /> */}
        {/* <ShowMoreLess
          primarHeading={"Goal / Strategy"}
          // primaryData={primaryData?.whenItComesToYou}
          textedData={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[0]]
              ?.goalStrategy
          }
          type={3}
        />
        <ShowMoreLess
          primarHeading={"Positive Impact"}
          // primaryData={primaryData?.whenItComesToYou}
          textedData={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[0]]
              ?.positiveImpact
          }
          type={3}
        />
        <ShowMoreLess
          primarHeading={"Over Use"}
          // primaryData={primaryData?.whenItComesToYou}
          textedData={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[0]]
              ?.overUse
          }
          type={3}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[0]]
              ?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[0]]
              ?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[0]]
              ?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[0]]
              ?.potentialBlindSpotsToolTip
          }
          isBlind={true}
        />
      </div>
      <div className="personality-other-data-container">
        <PotraitsCommonHeadBottomSecondary
          primary={"Secondary"}
          heading={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[1]]
              ?.title
          }
          summary={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[1]]
              ?.description
          }
        />
        <ShowMoreLess
          primarHeading={"Goal / Strategy"}
          // primaryData={primaryData?.whenItComesToYou}
          textedData={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[1]]
              ?.goalStrategy
          }
          type={3}
        />
        <ShowMoreLess
          primarHeading={"Positive Impact"}
          // primaryData={primaryData?.whenItComesToYou}
          textedData={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[1]]
              ?.positiveImpact
          }
          type={3}
        />
        <ShowMoreLess
          primarHeading={"Over Use"}
          // primaryData={primaryData?.whenItComesToYou}
          textedData={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[1]]
              ?.overUse
          }
          type={3}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[1]]
              ?.topStrengths
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[1]]
              ?.topStrengthsToolTip
          }
          isBlind={false}
        />
        <TopStrength
          topStrengths={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[1]]
              ?.potentialBlindSpots
          }
          topStrengthsToolTip={
            otherData?.portraitMatrixMap[traitPriorities && traitPriorities[1]]
              ?.potentialBlindSpotsToolTip
          }
          isBlind={true}
        /> */}
      </div>
    </div>
  );
}

export default ConflictManagement;

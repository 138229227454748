import { axiosAssessmentInstance } from "../AxiosConfiguration";

export const getPortraitsData = async () => {
  try {
    const response = await axiosAssessmentInstance.post(`/api/submit/test`);
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

export const getPortraits = async () => {
  try {
    const response = await axiosAssessmentInstance.post(`/api/getPotrait`, {
      create: 0,
    });
    return response?.data?.payload;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

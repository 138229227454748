// src/axiosInstance.js
import axios from "axios";
import Cookies from "js-cookie";
import {
  getAccessToken,
  getRefreshToken,
  setTokens,
  clearTokens,
  refreshAccessToken,
} from "./Services/auth";

const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_AUTH || "", // Default endpoint
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

let refreshSubscribers = [];
let isRefreshing = false;

const onRefreshed = (token) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

// Response Interceptor for axiosRelationshipInstance
axiosAuthInstance.interceptors.request // Add a request interceptor to inject the Access Token into the Authorization header
  .use(
    (config) => {
      const accessToken = Cookies.get("idtoken");
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
        config.headers["Device_id"] = Cookies.get("Device_id");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

axiosAuthInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    // console.log("inn error", error, error?.status);
    if (error?.status === 498) {
      clearTokens();
      window.location.href = "/";
    }
    const refreshToken = Cookies.get("refreshtoken");
    const { config } = error;
    if (error.response?.status === 401 && !config._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            resolve(axios(config));
          });
        });
      }

      config._retry = true;
      isRefreshing = true;

      try {
        const refreshedResponse = await refreshAccessToken();
        const newIdToken = refreshedResponse.data.payload.idToken;
        Cookies.set("idtoken", newIdToken);
        onRefreshed(newIdToken);

        config.headers["Authorization"] = `Bearer ${newIdToken}`;
        return axios(config);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  }
);

const axiosLoginInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_AUTH || "", // Default endpoint
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const axiosAssessmentInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_ASSESSMENT || "", // Default endpoint
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Add interceptors for request or response if needed
axiosAssessmentInstance.interceptors.request // Add a request interceptor to inject the Access Token into the Authorization header
  .use(
    (config) => {
      const accessToken = Cookies.get("idtoken");
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

axiosAssessmentInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const refreshToken = Cookies.get("refreshtoken");
    const { config } = error;

    console.log("Interceptor triggered. Error status:", error.response?.status);

    if (error.response?.status === 401 && !config._retry) {
      console.log("401 error detected. Starting retry flow...");
      if (isRefreshing) {
        console.log("Refresh in progress. Adding to subscriber queue...");
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            resolve(axios(config));
          });
        });
      }

      config._retry = true;
      isRefreshing = true;

      try {
        const refreshedResponse = await refreshAccessToken();
        const newIdToken = refreshedResponse.data.payload.idToken;
        console.log("Token refreshed successfully:", newIdToken);

        Cookies.set("idtoken", newIdToken);
        onRefreshed(newIdToken);

        config.headers["Authorization"] = `Bearer ${newIdToken}`;
        return axios(config);
      } catch (refreshError) {
        console.error("Error during token refresh:", refreshError);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
        console.log("isRefreshing reset to false.");
      }
    }

    console.log("Returning original error...");
    return Promise.reject(error);
  }
);

//subscription Assessment
const axiosSubstanceInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_SUBSCRIPTION || "", // Default endpoint
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Add interceptors for request or response if needed
axiosSubstanceInstance.interceptors.request // Add a request interceptor to inject the Access Token into the Authorization header
  .use(
    (config) => {
      const accessToken = Cookies.get("idtoken");
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

axiosSubstanceInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const refreshToken = Cookies.get("refreshtoken");
    const { config } = error;

    console.log("Interceptor triggered. Error status:", error.response?.status);

    if (error.response?.status === 401 && !config._retry) {
      console.log("401 error detected. Starting retry flow...");
      if (isRefreshing) {
        console.log("Refresh in progress. Adding to subscriber queue...");
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            resolve(axios(config));
          });
        });
      }

      config._retry = true;
      isRefreshing = true;

      try {
        const refreshedResponse = await refreshAccessToken();
        const newIdToken = refreshedResponse.data.payload.idToken;
        console.log("Token refreshed successfully:", newIdToken);

        Cookies.set("idtoken", newIdToken);
        onRefreshed(newIdToken);

        config.headers["Authorization"] = `Bearer ${newIdToken}`;
        return axios(config);
      } catch (refreshError) {
        console.error("Error during token refresh:", refreshError);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
        console.log("isRefreshing reset to false.");
      }
    }

    console.log("Returning original error...");
    return Promise.reject(error);
  }
);

const axiosArisChatInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_CHAT_ARIS || "", // Default endpoint
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axiosArisChatInstance.interceptors.request // Add a request interceptor to inject the Access Token into the Authorization header

  .use(
    (config) => {
      const accessToken = Cookies.get("idtoken");
      if (!accessToken) {
        window.location.href = "/";
        return Promise.reject("No access token found");
      }

      config.headers[
        "Authorization"
      ] = `Bearer ALqt8T93LBDcsDfFBfTI79V2ADMr5LUJHu7rPFsOgmBmyRJQf9UPblA2W40Rii2awFEagGtOFid74TuXbJrJSo8LCsbelVThaX81jzZArDSa4Ol2wjUirUM`;
      config.headers["Device_id"] = Cookies.get("Device_id");
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

const axiosChatInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_ASSESSMENT || "", // Default endpoint
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
axiosChatInstance.interceptors.request // Add a request interceptor to inject the Access Token into the Authorization header

  .use(
    (config) => {
      const accessToken = Cookies.get("idtoken");
      if (!accessToken) {
        window.location.href = "/";
        return Promise.reject("No access token found");
      }

      config.headers["Authorization"] = `Bearer ${accessToken}`;
      config.headers["Device_id"] = Cookies.get("Device_id");
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

axiosChatInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const refreshToken = Cookies.get("refreshtoken");
    const { config } = error;
    if (error.response?.status === 401 && !config._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            resolve(axios(config));
          });
        });
      }

      config._retry = true;
      isRefreshing = true;

      try {
        const refreshedResponse = await refreshAccessToken();
        const newIdToken = refreshedResponse.data.payload.idToken;
        Cookies.set("idtoken", newIdToken);
        onRefreshed(newIdToken);

        config.headers["Authorization"] = `Bearer ${newIdToken}`;
        return axios(config);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  }
);

const axiosRelationshipInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_ASSESSMENT || "", // Default endpoint
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axiosRelationshipInstance.interceptors.request // Add a request interceptor to inject the Access Token into the Authorization header
  .use(
    (config) => {
      const accessToken = Cookies.get("idtoken");
      if (!accessToken) {
        window.location.href = "/";
        return Promise.reject("No access token found");
      }

      config.headers["Authorization"] = `Bearer ${accessToken}`;
      config.headers["Device_id"] = Cookies.get("Device_id");
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

axiosRelationshipInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    if (error.response?.status === 401 && !config._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            resolve(axios(config));
          });
        });
      }

      config._retry = true;
      isRefreshing = true;

      try {
        const refreshedResponse = await refreshAccessToken();
        const newIdToken = refreshedResponse.data.payload.idToken;
        Cookies.set("idtoken", newIdToken);
        onRefreshed(newIdToken);

        config.headers["Authorization"] = `Bearer ${newIdToken}`;
        return axios(config);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

const axiosProfileInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_ASSESSMENT || "", // Default endpoint
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axiosProfileInstance.interceptors.request // Add a request interceptor to inject the Access Token into the Authorization header
  .use(
    (config) => {
      const accessToken = Cookies.get("idtoken");
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
        config.headers["Device_id"] = Cookies.get("Device_id");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

axiosProfileInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    if (error.response?.status === 401 && !config._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            resolve(axios(config));
          });
        });
      }

      config._retry = true;
      isRefreshing = true;

      try {
        const refreshedResponse = await refreshAccessToken();
        const newIdToken = refreshedResponse.data.payload.idToken;
        Cookies.set("idtoken", newIdToken);
        onRefreshed(newIdToken);

        config.headers["Authorization"] = `Bearer ${newIdToken}`;
        return axios(config);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export {
  axiosAuthInstance,
  axiosAssessmentInstance,
  axiosArisChatInstance,
  axiosChatInstance,
  axiosRelationshipInstance,
  axiosProfileInstance,
  axiosLoginInstance,
  axiosSubstanceInstance,
};

import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/images/closeIcon.svg";
import { getChatSessionList } from "../../Services/Chat";

function ChatHistory({ historyMenuClose, onHistorySessionClick }) {
  const [chatList, setchatList] = useState([]);
  useEffect(() => {
    getChatSessionList().then((res) => {
      console.log(res.payload, "chathistory");
      setchatList(res.payload);
    });
  }, []);
  return (
    <div className="chat-history-container">
      <div className="chat-history-header cur-ptr" onClick={historyMenuClose}>
        <CloseIcon />
      </div>
      <div className="chat-heading">Previous Advice</div>
      <div className="chat-history-list-cntr">
        {chatList?.map((item, index) => (
          <div
            className="chat-history-item"
            key={index}
            onClick={() => onHistorySessionClick(item)}
          >
            {item.topic}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChatHistory;

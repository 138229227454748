import React, { useState, useEffect } from 'react'
import './style.css'
import { ReactComponent as CloseIcon } from "../../../Assets/icon/close.svg";
import { useNavigate } from 'react-router-dom';
import { endSession, fetchHistory } from '../../../Services/coaching';
import ContentLoader from '../../LoadingPage/ContentLoader';

const History = ({ onClose, sessionID, client, wavRecorder }) => {
    const [selectedFilter, setSelectedFilter] = useState('action_plan');
    const navigate = useNavigate();
    const [history, setHistory] = useState({});
    const [loading, setLoading] = useState(false);
    const [navigating, setNavigating] = useState(false);

    useEffect(() => {
        const getHistory = async () => {
            setLoading(true);
            try {
                const response = await fetchHistory();
                console.log(response)
                setHistory(response.payload)
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        }
        getHistory();
    }, [])

    const renderHistoryItems = (items) => {
        return items
            .filter(item => item.description)
            .map((item, index) => (
                <div key={index} className="coaching-history-list-item">
                    <div className='coaching-history-list-heading'>
                        {item.topic || 'Title'}
                    </div>
                </div>
            ));
    };

    const renderSmartHabitsItems = (items) => {
        return items.map((item, index) => {
            return (
                <div key={index} className="coaching-history-list-item">
                    <div className='coaching-history-list-heading'>
                        {item.topic || 'Title'}
                    </div>
                    <div className='coaching-history-list-details'>
                        {item.description || 'No Description'}
                    </div>
                </div>
            );
        });
    };

    const renderItems = () => {
        if (selectedFilter === 'smart_habits') {
            const smartHabitsKeys = Object.keys(history.smart_habits).filter((key) => history.smart_habits[key].length > 0);
            if (smartHabitsKeys.length === 0) {
                return <div className="no-items-message">No SMART Habits found</div>;
            }
            return smartHabitsKeys.map((key) => (
                <div key={key} className='coaching-history-list-item'>
                    <p>{key}</p>
                    {renderSmartHabitsItems(history.smart_habits[key])}
                </div>
            ));
        } else {
            const filterKeys = Object.keys(history[selectedFilter]).filter((key) => history[selectedFilter][key].length > 0);
            if (filterKeys.length === 0) {
                return <div className="no-items-message">No {selectedFilter.replace('_', ' ')} found</div>;
            }
            return filterKeys.map((key) => (
                <div key={key} className='coaching-history-list-item'>
                    <p>{key}</p>
                    {renderHistoryItems(history[selectedFilter][key])}
                </div>
            ));
        }
    };

    const handleNavigate = async () => {
        setNavigating(true);
        try {
            await client.disconnect();
            await wavRecorder.end();
            await endSession(sessionID, false);
        } catch (error) {
            console.error(error);
        } finally {
            setNavigating(false);
            navigate('/myprofile');
        }
    }

    return (
        <div className="coaching-history-container">
            <div className="coaching-history-header">
                <div></div>
                <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
            </div>
            <div className="coaching-history-main-section">
                <p className='coaching-history-main-section-title'>Coaching history</p>
                <div className="coaching-history-filter-button-container">
                    <div
                        className={`coaching-history-filter-button-item ${selectedFilter === 'meeting_notes' ? 'active-filter' : ''}`}
                        onClick={() => setSelectedFilter('meeting_notes')}
                    >
                        No Action Plan
                    </div>
                    <div
                        className={`coaching-history-filter-button-item ${selectedFilter === 'action_plan' ? 'active-filter' : ''}`}
                        onClick={() => setSelectedFilter('action_plan')}
                    >
                        Action Plan
                    </div>
                    <div
                        className={`coaching-history-filter-button-item ${selectedFilter === 'smart_habits' ? 'active-filter' : ''}`}
                        onClick={() => setSelectedFilter('smart_habits')}
                    >
                        SMART Habits
                    </div>
                </div>
                <div className="coaching-history-list-container">
                    {history && (
                        Object.keys(history).length > 0 ? (
                            renderItems()
                        ) : (
                            <div className="no-items-message">
                                {loading ? <ContentLoader /> : 'No history'}
                            </div>
                        )
                    )}
                </div>
            </div>
            <div className="coaching-history-footer">
                <div 
                    className={`open-your-profile-button-container ${navigating && 'navigate'}`} 
                    onClick={() => {
                        if(!navigating) {
                            handleNavigate()
                        }
                    }}
                >
                    {navigating ? 'Navigating' : 'Open in your profile'}
                </div>
            </div>
        </div>
    )
}

export default History
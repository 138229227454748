import React from "react";

function PhoneSettingsScreendDelete1() {
  return (
    <div className="set-phone-modal-container">
      <div>
        <div className="set-phone-modal-head">
          Are you sure you want to delete your number?
        </div>
        <div className="set-phone-modal-support">
          Your mobile number is used to recover your account.
        </div>
      </div>
      <div className="set-phone-int-cnt">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginBottom: "20px",
            marginTop: "20px",
            position: "relative",
          }}
        >
          <button className="set-phone-cancel-button"> Cancel</button>
          <button className="set-phone-remove-button"> Remove</button>
        </div>
      </div>
    </div>
  );
}

export default PhoneSettingsScreendDelete1;

import React from 'react';
import { IoIosArrowDown } from "react-icons/io";

const SelectInput = ({ label, placeholder, value, options, onChange }) => {
    return (
        <div className="gender-select-input">
            <label htmlFor={label}>{label}</label>
            <div style={{ position: 'relative' }}>
                <select 
                    style={{marginTop:"6px"}}
                    id={label}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                >
                    <option value="" disabled>Select {placeholder}</option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <IoIosArrowDown style={{
                    position: 'absolute',
                    right: '12px',
                    top: '40%',
                    transform: 'translateY(-50%)',
                    pointerEvents: 'none',
                    color: '#344054'
                }} />
            </div>
        </div>
    );
};

export default SelectInput;

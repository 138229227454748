// import React, { useEffect, useState } from "react";
// import { ReactComponent as Info } from "../../../../Assets/icon/Info-ref.svg";
// import { ReactComponent as Primary } from "../../../../Assets/icon/Primary-rectangle.svg";
// import { ReactComponent as Secondary } from "../../../../Assets/icon/Pressure-rectangle.svg";
// import { ReactComponent as Backbutton } from "../../../../Assets/icon/backbutton.svg";
// import ReactMarkdown from "react-markdown";
// import InfoTooltip from "./InfoTooltip";

// export function ConflictManagement({ data, onBack, profilePic1, profilePic2, initials1, initials2, name1, name2, tooltip, user1Metrics, user2Metrics }) {
  
//   const isClose = (value1, value2) => Math.abs(value1 - value2) < 5;

//   const conflictStyles = ["Collaborate", "Compete", "Compromise", "Accommodate", "Avoid"];

//   const [positions, setPositions] = useState({});
//   useEffect(() => {
//     if (user1Metrics || user2Metrics) {
//       // Start at 50% for animation
//       setPositions(
//         conflictStyles.reduce((acc, style) => {
//           acc[style] = { user1: "50%", user2: "50%" };
//           return acc;
//         }, {})
//       );
  
//       setTimeout(() => {
//         setPositions(
//           conflictStyles.reduce((acc, style) => {
//             acc[style] = {
//               user1: user1Metrics ? `${user1Metrics[style]}%` : "50%",
//               user2: user2Metrics ? `${user2Metrics[style]}%` : "50%",
//             };
//             return acc;
//           }, {})
//         );
//       }, 100); // Delay to trigger animation
//     }
//   }, [user1Metrics, user2Metrics]);
  

//   return (
//     <div className="Processing-container">
//       <div className="Processing-header">
//         <button className="backbutton-insights" onClick={onBack}><Backbutton /> Back</button>
//         <div className="Processing-txt">Conflict Management</div>
//         <div><InfoTooltip content={tooltip} /></div>
//       </div>

//       <div className="primary-color-container">
//         <div className="primary-color-txt">
//           <Primary /> Primary
//         </div>
//         <div className="primary-color-txt">
//           <Secondary /> Secondary
//         </div>
//       </div>

//       {conflictStyles.map((style) => (
//         <div key={style} className="insight-progress-container">
//           <div className="insight-progress-info-container">
//             <div>{style}</div>
//           </div>
//           <div className="triangle-bg"></div>

//           {[user1Metrics, user2Metrics].map((user, index) => (
//   user && (
//     <div key={index} className="insight-person-container"  style={{
//       left: positions[style]?.[index === 0 ? "user1" : "user2"],
//       transition: "left 0.6s ease-in-out",
//     }}>
//       {(index === 0 ? profilePic1 : profilePic2) ? (
//         <img
//           src={index === 0 ? profilePic1 : profilePic2}
//           alt={index === 0 ? initials1 : initials2}
//           style={{ width: "24px", height: "24px" }}
//           onError={(e) => {
//             e.target.style.display = "none";
//             e.target.nextElementSibling.style.display = "flex";
//           }}
//         />
//       ) : (
//         <div className="profile-initials" style={{ width: "24px", height: "24px",fontSize:"10px" }}>
//           {index === 0 ? initials1 : initials2}
//         </div>
//       )}
//       {!isClose(user1Metrics?.[style], user2Metrics?.[style]) && (index === 0 ? name1 : name2)}
//     </div>
//   )
// ))}

//         </div>
//       ))}

//       <div style={{ textAlign: "left" }}>
//         <ReactMarkdown
//           components={{ h1: "h3", h2: "h4" }}
//         >
//           {data?.replace(/\n/g, "\n\n") || ""}
//         </ReactMarkdown>
//       </div>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import { ReactComponent as Info } from "../../../../Assets/icon/Info-ref.svg";
import { ReactComponent as Primary } from "../../../../Assets/icon/Primary-rectangle.svg";
import { ReactComponent as Secondary } from "../../../../Assets/icon/Pressure-rectangle.svg";
import { ReactComponent as Backbutton } from "../../../../Assets/icon/backbutton.svg";
import ReactMarkdown from "react-markdown";
import InfoTooltip from "./InfoTooltip";

export function ConflictManagement({ data, onBack, profilePic1, profilePic2, initials1, initials2, name1, name2, tooltip, user1Metrics, user2Metrics }) {
  
  const isClose = (value1, value2) => Math.abs(value1 - value2) < 5;

  const conflictStyles = ["Collaborate", "Compete", "Compromise", "Accommodate", "Avoid"];

  const [positions, setPositions] = useState({});
  useEffect(() => {
    if (user1Metrics || user2Metrics) {
      // Start at 50% for animation
      setPositions(
        conflictStyles.reduce((acc, style) => {
          acc[style] = { user1: "50%", user2: "50%" };
          return acc;
        }, {})
      );
  
      setTimeout(() => {
        setPositions(
          conflictStyles.reduce((acc, style) => {
            acc[style] = {
              user1: user1Metrics ? `${user1Metrics[style]}%` : "50%",
              user2: user2Metrics ? `${user2Metrics[style]}%` : "50%",
            };
            return acc;
          }, {})
        );
      }, 100); // Delay to trigger animation
    }
  }, [user1Metrics, user2Metrics]);
  
  return (
    <div className="Processing-container">
      <div className="Processing-header">
        <button className="backbutton-insights" onClick={onBack}><Backbutton /> Back</button>
        <div className="Processing-txt">Conflict Management</div>
        <div><InfoTooltip content={tooltip} /></div>
      </div>

      <div className="primary-color-container">
        <div className="primary-color-txt">
          <Primary /> Primary
        </div>
        <div className="primary-color-txt">
          <Secondary /> Secondary
        </div>
      </div>

      {conflictStyles.map((style) => {
        // Check if metrics are close for this style
        const areClose = user1Metrics && user2Metrics && isClose(user1Metrics[style], user2Metrics[style]);
        
        // Calculate combined position (average of both positions)
        const combinedPosition = areClose && user1Metrics && user2Metrics ? 
          `${(parseFloat(positions[style]?.user1) + parseFloat(positions[style]?.user2)) / 2}%` : null;
        
        return (
          <div key={style} className="insight-progress-container">
            <div className="insight-progress-info-container">
              <div>{style}</div>
            </div>
            <div className="triangle-bg"></div>

            {/* Show combined display when metrics are close */}
            {areClose && (
              <div className="insight-person-container combined-indicators" style={{
                left: combinedPosition,
                transition: "left 0.6s ease-in-out",
              }}>
                <div className="insight-combined-avatars" style={{ display: "flex", gap: "4px" }}>
                  {profilePic1 ? (
                    <img 
                      src={profilePic1}
                      alt={initials1 || "Profile"}
                      style={{ width: "24px", height: "24px", fontSize: "10px" }}
                      onError={(e) => { 
                        e.target.style.display = "none"; 
                        e.target.nextElementSibling.style.display = "flex"; 
                      }}
                    />
                  ) : (
                    <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>
                      {initials1 || ""}
                    </div>
                  )}
                  
                  {profilePic2 ? (
                    <img 
                      src={profilePic2}
                      alt={initials2 || "Profile"}
                      style={{ width: "24px", height: "24px", fontSize: "10px" }}
                      onError={(e) => { 
                        e.target.style.display = "none"; 
                        e.target.nextElementSibling.style.display = "flex"; 
                      }}
                    />
                  ) : (
                    <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>
                      {initials2 || ""}
                    </div>
                  )}
                </div>
                <div className="combined-names">
                  {name1} & {name2}
                </div>
              </div>
            )}

            {/* Show individual indicators when NOT close */}
            {!areClose && [user1Metrics, user2Metrics].map((user, index) => (
              user && (
                <div key={index} className="insight-person-container" style={{
                  left: positions[style]?.[index === 0 ? "user1" : "user2"],
                  transition: "left 0.6s ease-in-out",
                }}>
                  {(index === 0 ? profilePic1 : profilePic2) ? (
                    <img
                      src={index === 0 ? profilePic1 : profilePic2}
                      alt={index === 0 ? initials1 : initials2}
                      style={{ width: "24px", height: "24px" }}
                      onError={(e) => {
                        e.target.style.display = "none";
                        e.target.nextElementSibling.style.display = "flex";
                      }}
                    />
                  ) : (
                    <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>
                      {index === 0 ? initials1 : initials2}
                    </div>
                  )}
                  {index === 0 ? name1 : name2}
                </div>
              )
            ))}
          </div>
        );
      })}

      <div style={{ textAlign: "left" }}>
        <ReactMarkdown
          components={{ h1: "h3", h2: "h4" }}
        >
          {data?.replace(/\n/g, "\n\n") || ""}
        </ReactMarkdown>
      </div>
    </div>
  );
}
import React, { useState, useRef } from 'react';
import './style.css';
import Cookies from 'js-cookie';
import nextbtn from '../../Assets/images/arrow-right-blue.png';
import SelectInput from '../../Components/SelectInput';
import { submitBasicDetails } from '../../Services/auth';
import NavigationButton from '../../Components/NavigationButton';
import { Pause } from 'lucide-react';
import { ReactComponent as Play } from "../../Assets/icon/Play-voice.svg"

const GenderPronounsPage = ({ onNext, onBack, setFaith, faith }) => {
    const [gender, setGender] = useState('');
    const [aris_voice, setAris_voice] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const[issending, setIssending]= useState(false);

    const isFormValid = gender && aris_voice;

    const handleNext = async () => {
        const firstName = Cookies.get('firstName');
        const lastName = Cookies.get('lastName');
        const birthday = Cookies.get('birthday');

        if (firstName && lastName && birthday && gender && aris_voice && !issending) {
            try {
                setIssending(true);
                const response = await submitBasicDetails(firstName, lastName, birthday, gender, aris_voice);
                console.log('API response:', response);
                onNext(response.payload.load_faith, response.payload.user_type);
            } catch (error) {
                console.error('Error while submitting details:', error);
            }
            finally{
                setIssending(false);
            }
        }
    };

    const handlePlayPause = () => {
        if (!audioRef.current) return;

        if (isPlaying) {
            audioRef.current.pause();
        } else {
            if (audioRef.current) {
                audioRef.current.currentTime = 0;
                audioRef.current.play();
            }
        }
        setIsPlaying(!isPlaying);
    };

    const handleVoiceChange = (value) => {
        setAris_voice(value);
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const getAudioSource = () => {
        if (aris_voice === 'Male') {
            return 'https://aris-static-app.s3.us-east-1.amazonaws.com/assets/Aris_Male+Voice_Example+.mp3';
        } else if (aris_voice === 'Female') {
            return 'https://aris-static-app.s3.us-east-1.amazonaws.com/assets/Aris_Female+Voice_Example+.mp3';
        }
        return '';
    };

    const genderOptions = [
        { value: 'female', label: 'Female' },
        { value: 'male', label: 'Male' },
        { value: 'non-binary', label: 'Non-binary' },
        { value: 'prefer-not-to-say', label: 'Prefer not to say' },
        { value: 'other', label: 'Other' }
    ];

    const pronounsOptions = [
        { value: 'Female', label: 'Female' },
        { value: 'Male', label: 'Male' },
    ];

    return (
        <div className="Fullpage-container-email">
            <div className='gender-pronouns-container'>
                <div className='gender-header'>
                    <NavigationButton
                        onClick={onBack}
                        direction='left'
                        label="Back"
                        className="back-button"
                    />
                    <h2 className='heading-h2' style={{ marginBottom: '2px', marginTop: "0px" }}>Basic details</h2>
                    <p className='mobile-para'>Tell us how you want to be addressed.</p>

                    <SelectInput
                        label="What's your gender?"
                        placeholder="your gender"
                        value={gender}
                        options={genderOptions}
                        onChange={setGender}
                    />

                    <div className="voice-preference-container">
                        <div className="voice-select-wrapper">
                            <SelectInput
                                label="What is your voice preference for Aris Life Coach?"
                                placeholder="your voice preference"
                                value={aris_voice}
                                options={pronounsOptions}
                                onChange={handleVoiceChange}
                            />
                        </div>
                        {aris_voice && (
                            <button
                                onClick={handlePlayPause}
                                className="voice-play-button"
                            >
                                {isPlaying ? <Pause size={24} /> : <Play size={24} />}
                            </button>
                        )}
                    </div>

                    <audio
                        ref={audioRef}
                        src={getAudioSource()}
                        onEnded={() => setIsPlaying(false)}
                    />
                </div>

                <button
                    className={`submit-button ${isFormValid ? 'valid' : 'invalid'}`}
                    onClick={isFormValid ? handleNext : null}
                    disabled={!isFormValid}
                >
                    Continue
                    <img src={nextbtn} alt='nxtbtn' className='nxtbtn' />
                </button>
            </div>
        </div>
    );
};

export default GenderPronounsPage;
// components/VoiceRecorder/index.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Mic, Square, Pencil } from 'lucide-react';
import './style.css';

const VoiceRecorder = ({ onRecordingComplete }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [hasRecording, setHasRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [timer, setTimer] = useState(0); // Timer state to track recording time
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const timerRef = useRef(null); // Timer reference to clear it when needed

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/mp4;codecs=mp4a.40.2'
      });
      
      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        chunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(chunksRef.current, { type: 'audio/mp4' });
        const url = URL.createObjectURL(audioBlob);
        setAudioUrl(url);
        setHasRecording(true);
        if (onRecordingComplete) {
          onRecordingComplete(audioBlob, url);
        }
        clearInterval(timerRef.current); // Stop the timer when recording ends
        setTimer(0); // Reset the timer
      };

      mediaRecorder.start();
      setIsRecording(true);
      startTimer(); // Start the timer
    } catch (err) {
      console.error("Error accessing microphone:", err);
      alert("Could not access microphone. Please check permissions.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const handleEdit = () => {
    setHasRecording(false);
    setAudioUrl(null);
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000); // Update the timer every second
  };

  // Convert seconds to MM:SS format
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  useEffect(() => {
    return () => clearInterval(timerRef.current); // Clean up timer on unmount
  }, []);

  return (
    <div className="voice-recorder-container">
      {!hasRecording ? (
        <button
          type="button"
          onClick={isRecording ? stopRecording : startRecording}
          className={`record-button ${isRecording ? 'recording' : ''}`}
        >
          {isRecording ? (
            <>
              <Square className="icon pulse" />
              <span>Recording... {formatTime(timer)}</span> {/* Display timer */}
            </>
          ) : (
            <>
              <Mic className="mic-icon" />
              <span className='mic-rec-button'>Record</span>
            </>
          )}
        </button>
      ) : (
        <div className="recording-complete">
          <audio src={audioUrl} controls className="audio-player" />
          <button
            type="button"
            onClick={handleEdit}
            className="edit-button"
          >
            <Pencil className="icon" />
          </button>
        </div>
      )}
    </div>
  );
};

export default VoiceRecorder;

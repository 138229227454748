import React, { useState, useEffect, useCallback, useRef } from "react";
import { Search } from "lucide-react";
import { ReactComponent as Userplus } from "../../Assets/icon/user-plus-01.svg";
import { ReactComponent as Userpluswhite } from "../../Assets/icon/Userpluswhite.svg";
import { ReactComponent as Trash } from "../../Assets/icon/Trash.svg";
import ContactCard from "../../Components/ManageRelationshipContactcard";
import Modal from "../../Components/ManageRelationshipModal";
import GradientBackground from "../../Components/AddRelationshipHeaderGradient";
import TopBar from "../../Components/AddRelationshipTopBar";
import EditRelationship from "../../Components/EditRelationship";
import AddRelationship from "../AddRelationship";
import { getRelationships } from "../../Services/Realtionship";
import { fetchPendingRequests } from "../../Services/Realtionship";
import { useHeader } from "../../Providers/HeaderContextProvider";
import PendingRequestsModal from "../../Components/PendingRequestsModal";
import EmptyState from "../../Components/AddRelationshipEmptyState";
import LoadingScreen from "../LoadingPage/ContentLoader";
import Loader from "react-js-loader";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./style.css";

const RelationshipsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isAdd = searchParams.get("isAdd");
  const isPendingRequest = searchParams.get("isPendingRequest");
  const fromInvite = searchParams.get("fromInvite");

  const isFetching = useRef(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to handle modal visibility
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showEditView, setShowEditView] = useState(false);
  const [showAddView, setShowAddView] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPendingView, setShowPendingView] = useState(false);
  const { setHeaderContent } = useHeader();
  const [pendingRequests, setPendingRequests] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [showDeleteNotification, setShowDeleteNotification] = useState(false);
  const [gridloading, setGridLoading] = useState(false);

  useEffect(() => {
    if (isAdd === "true") setShowAddView(true);
    if (isPendingRequest === "true") setShowPendingView(true);
  }, []);

  console.log(contacts, searchTerm, selectedCategory, "lklklk");

  const handleManageRelationship = () => {
    setShowModal(false);
    setShowEditView(true); // Open Edit Relationship view
  };

  const confirmDeleteContact = () => {
    handleDeleteContact(contactToDelete); // Use your existing delete logic
    setShowDeleteConfirm(false); // Close the confirmation popup
    setContactToDelete(null); // Clear the selected contact
  };

  // Fetch relationships when component mounts or category changes
  const fetchRelationships = useCallback(async () => {
    if (isFetching.current) return; // Prevent duplicate API calls
    isFetching.current = true; // Mark as fetching

    setGridLoading(true); // Start loading indicator
    try {
      const relationshipsData = await getRelationships(
        selectedCategory === "All" ? "" : selectedCategory
      );
      console.log("Fetched relationships:", relationshipsData); // Debugging log

      setContacts(relationshipsData);
      if (selectedCategory === "All") {
        setAllContacts(relationshipsData);
      }
    } catch (error) {
      console.error("Failed to fetch relationships:", error);
      setContacts([]);
    } finally {
      isFetching.current = false;
      setGridLoading(false);
      setIsLoading(false);
    }
  }, [selectedCategory]);

  const fetchAndSetPendingRequests = useCallback(async () => {
    console.log("Fetching pending requests...");
    // if (pendingRequests.length > 0) return; // Only fetch if not already fetched
    try {
      const requests = await fetchPendingRequests();
      console.log(requests, "Fetching pending requests...");
      const transformedRequests = requests?.map((request) => ({
        id: request._id,
        name: `${request.first_name} ${request.last_name}`,
        initials: `${request.first_name?.charAt(0)}${request.last_name?.charAt(
          0
        )}`,
        profilePic: request.profile_pic,
      }));
      setPendingRequests(transformedRequests);
    } catch (error) {
      setPendingRequests([]);
      console.error("Failed to fetch pending requests:", error);
    }
  }, []);

  useEffect(() => {
    setHeaderContent(false);
    fetchRelationships();
  }, [selectedCategory]);

  useEffect(() => {
    fetchAndSetPendingRequests(); // Ensure this only runs once
  }, []);

  const scrollRef = useRef(null);

  const handleWheel = (event) => {
    if (scrollRef.current) {
      event.preventDefault();
      scrollRef.current.scrollLeft += event.deltaY; // Scrolls horizontally
    }
  };

  const handleAcceptRequest = async (requestId) => {
    try {
      setPendingRequests((prevRequests) =>
        prevRequests?.filter((request) => request.id !== requestId)
      );
      fetchRelationships();
    } catch (error) {
      console.error("Failed to accept request:", error);
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleRejectRequest = async (requestId) => {
    try {
      setPendingRequests((prevRequests) =>
        prevRequests.filter((request) => request.id !== requestId)
      );
    } catch (error) {
      console.error("Failed to reject request:", error);
    }
  };

  const handleUpdateContact = useCallback(
    async (updatedContact) => {
      try {
        // Your update API call here
        setContacts((prevContacts) =>
          prevContacts.map((contact) =>
            contact._id === updatedContact._id ? updatedContact : contact
          )
        );
        setSelectedContact(updatedContact);

        // Call fetchRelationships after the API call
        fetchRelationships();
      } catch (error) {
        console.error("Failed to update contact:", error);
      }
    },
    [fetchRelationships]
  );

  const handleDeleteContact = useCallback(
    async (contactId) => {
      try {
        // Your delete API call here
        setContacts((prevContacts) =>
          prevContacts.filter((contact) => contact.id !== contactId)
        );
        setShowEditView(false);
        setSelectedContact(null);

        setShowDeleteNotification(true);
        setTimeout(() => {
          setShowDeleteNotification(false);
        }, 2000);

        // Call fetchRelationships after the API call
        fetchRelationships();
      } catch (error) {
        console.error("Failed to delete contact:", error);
      }
    },
    [fetchRelationships]
  );

  const handleBackClick = () => {
    if (fromInvite) {
      navigate("/chat");
    } else if (!showEditView && !showAddView && !showPendingView) {
      navigate(-1);
    } else {
      setShowEditView(false);
      setShowAddView(false);
      setShowPendingView(false);
      fetchRelationships();
      fetchAndSetPendingRequests();
    }
  };

  console.log("Cobtacts", contacts);
  console.log("Pending Requests:", pendingRequests);

  // console.log(param1, "popopo");
  return (
    <GradientBackground>
      <div className="manage-container">
        <TopBar
          title={
            showAddView
              ? "Add Relationship"
              : showEditView
              ? "Edit Relationship"
              : showPendingView
              ? "Pending relationships"
              : "My Relationships"
          }
          showBackButton
          onBackClick={handleBackClick}
          rightIcon={
            !showPendingView &&
            !showAddView && (
              <Userplus
                onClick={() => {
                  setSearchTerm("");
                  setShowAddView(true);
                  setShowEditView(false);
                  setShowPendingView(false);
                }}
              />
            )
          }
          smallScreenRightIcon={
            !showPendingView && (
              <Userpluswhite // Replace this with the small version of the icon
                onClick={() => {
                  setSearchTerm("");
                  setShowAddView(true);
                  setShowEditView(false);
                  setShowPendingView(false);
                }}
              />
            )
          }
          className="top-bar-gradient" /* Add specific class if needed */
        />

        {isLoading ? (
          <div className="manage-loading-container">
            <LoadingScreen />
          </div>
        ) : showAddView ? (
          <AddRelationship
            onClose={() => setShowAddView(false)}
            onRelationshipAdded={fetchRelationships}
          />
        ) : showEditView ? (
          <EditRelationship
            contact={selectedContact}
            onBack={() => setShowEditView(false)}
            onUpdateContact={handleUpdateContact}
            onDeleteContact={handleDeleteContact}
          />
        ) : showPendingView ? (
          <PendingRequestsModal
            pendingRequests={pendingRequests}
            onAccept={handleAcceptRequest}
            onReject={handleRejectRequest}
          />
        ) : showAddView ||
          !Array.isArray(allContacts) ||
          allContacts.length === 0 ? ( // Show AddRelationship if contacts are null or empty
          <EmptyState onAddClick={() => setShowAddView(true)} />
        ) : (
          <div className="manage-wrapper">
            <div className="manage-search-container">
              <div className="manage-search-box">
                <Search className="manage-search-icon" size={20} />
                <input
                  type="text"
                  placeholder="Search by name"
                  className="manage-search-input"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div
                className="manage-category-list"
                ref={scrollRef}
                onWheel={handleWheel}
              >
                {[
                  "All",
                  "Dating",
                  "Marriage",
                  "Family",
                  "Friends",
                  "Workplace",
                  "Roommates",
                  "Sports",
                  "Other",
                ].map((category) => (
                  <button
                    key={category}
                    className={`manage-category-button ${
                      selectedCategory === category ? "selected" : ""
                    }`}
                    onClick={() => {
                      console.log(`Category clicked: ${category}`);
                      handleCategorySelect(category);
                    }}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>

            <div className="manage-contacts-container">
              <div className="manage-contacts-header">
                <h4 className="manage-header-title">Pending Relationships</h4>
                <button
                  onClick={() => {
                    setShowPendingView(true);
                    fetchAndSetPendingRequests();
                  }}
                  className="manage-request-count request-count-button"
                >
                  {pendingRequests.length} Request(s)
                </button>
              </div>
              {gridloading ? (
                <Loader
                  type="spinner-cub"
                  bgColor={"#2D50A7"}
                  color={"#3D6DEB"}
                  // title={"spinner-cub"}
                  size={45}
                />
              ) : (
                //         const filteredContacts = contacts.filter((contact) => {
                //   const fullName =
                //     `${contact.first_name} ${contact.last_name}`.toLowerCase();
                //   return fullName.includes(searchTerm.toLowerCase());
                // });
                <div className="manage-contacts-grid">
                  {Array.isArray(contacts) &&
                    contacts
                      ?.filter((contact) => {
                        const fullName =
                          `${contact.first_name} ${contact.last_name}`.toLowerCase();
                        return fullName.includes(searchTerm.toLowerCase());
                      })
                      .map((contact) => (
                        <ContactCard
                          key={contact._id}
                          contact={contact}
                          onClick={() => {
                            setSelectedContact(contact);
                            setShowModal(true); // Open the modal instead of edit view
                          }}
                        />
                      ))}
                </div>
              )}
            </div>
          </div>
        )}

        {showModal && (
          <Modal
            contact={selectedContact}
            onClose={() => setShowModal(false)} // Close modal
            onManageClick={handleManageRelationship} // Manage relationship option
          />
        )}

        {showDeleteNotification && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="delete-notification">
              <Trash />
              Relationship has been removed
            </div>
          </div>
        )}
      </div>
    </GradientBackground>
  );
};

export default RelationshipsPage;

import React, { useEffect, useState } from "react";
import arrowLeft from "../../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useHeader } from "../../../Providers/HeaderContextProvider";
import arrowRight from "../../../Assets/images/arrow-right.svg";
import { sendUpdatedEmail } from "../../../Services/settingsServices";
import "./style.css";
function EmailSettingPhoneScreen2() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setHeaderContent } = useHeader();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const location = useLocation();
  const currentEmail = location.state?.currentEmail;

  useEffect(() => {
    setHeaderContent(false);
  }, []);

  const onUpdateEmail = async () => {
    if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setErrorMessage("Invalid email format.");
      setIsLoading(false);
      return;
    }
    if (email === currentEmail) {
      setErrorMessage("New email cannot be the same as current email.");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const response = await sendUpdatedEmail({
        email: email,
      });
      if (response.status === "success") {
        sessionStorage.setItem("updatedEmail", email);
        setSuccessMessage("Email sent successfully!");
        navigate("/settings/email/3");
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || error?.message || "");
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {" "}
      <header className="termsofuse-header">
        <div className="termsofuse-gradient-background">
          <div className="termsofuse-nav-container">
            <div
              className="termsofuse-logo-container"
              onClick={() => navigate("/settings/email/1")}
            >
              <img
                src={arrowLeft}
                alt="Back"
                className="termsofuse-back-button"
              />
            </div>
            <h1 className="termsofuse-title">Enter new email </h1>
            <div className="termsofuse-space"></div>
            <div className="set-phone-modal-support mobile-sub-txt-set-phone">
              Giving us your email will allow you to access your Aris Portrait
              even if you lose your account.
            </div>
          </div>
        </div>
      </header>
      <div
        className="set-phone-int-cnt mobile-set-cnt"
        style={{ marginBottom: "10px" }}
      >
        <div className="set-phone-modal-input-container">
          <div className="set-phone-input-label">New Email</div>
          <div className="mobile-input-wrapper">
            <input
              type="text"
              id="mobile-number"
              className="mobile-input"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button
            className="set-phone-continue-button"
            onClick={onUpdateEmail}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? "0.6" : "1",
            }}
          >
            {" "}
            Update email <img src={arrowRight} alt="edit" />
          </button>
        </div>
        {errorMessage && (
          <div
            style={{
              color: "red",
              textAlign: "center",
            }}
          >
            {errorMessage}
          </div>
        )}
        {successMessage && (
          <div
            style={{
              color: "green",
              textAlign: "center",
            }}
          >
            {successMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailSettingPhoneScreen2;

import React from "react";

function Personality3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 106"
      fill="none"
    >
      <g clip-path="url(#clip0_2161_30147)">
        <path
          d="M31.5752 27.7046L69.0162 35.7937L99.8315 96.2779H7.88086L31.5752 27.7046Z"
          fill="#4FE3D3"
        />
        <path
          d="M31.5752 27.7046L69.0162 35.7937M31.5752 27.7046L7.88086 96.2779M31.5752 27.7046L99.8315 96.2779M69.0162 35.7937L99.8315 96.2779M69.0162 35.7937L7.88086 96.2779M99.8315 96.2779H7.88086"
          stroke="white"
          stroke-opacity="0.7"
          stroke-width="0.211394"
          stroke-miterlimit="10"
        />
        <path
          d="M31.5117 27.6392L99.8253 96.3424L69.1102 35.6913L31.5117 27.6392Z"
          fill="#4FE3D3"
        />
        <g filter="url(#filter0_f_2161_30147)">
          <path
            d="M49.6107 58.9248L52.8496 68.8445H63.3308L54.8513 74.9752L58.0902 84.895L49.6107 78.7642L41.1312 84.895L44.3701 74.9752L35.8906 68.8445H46.3718L49.6107 58.9248Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter1_f_2161_30147)">
          <path
            d="M26.9586 27.7446L29.6908 36.1018H38.5325L31.3794 41.2668L34.1116 49.624L26.9586 44.4589L19.8056 49.624L22.5378 41.2668L15.3848 36.1018H24.2264L26.9586 27.7446Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter2_f_2161_30147)">
          <path
            d="M70.7171 28.6973L72.4512 34.0265H78.063L73.523 37.3201L75.2571 42.6493L70.7171 39.3557L66.177 42.6493L67.9111 37.3201L63.3711 34.0265H68.9829L70.7171 28.6973Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30147"
          x="18.0584"
          y="41.0925"
          width="63.104"
          height="61.6348"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="8.91614"
            result="effect1_foregroundBlur_2161_30147"
          />
        </filter>
        <filter
          id="filter1_f_2161_30147"
          x="-2.44751"
          y="9.91236"
          width="58.813"
          height="57.5439"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="8.91614"
            result="effect1_foregroundBlur_2161_30147"
          />
        </filter>
        <filter
          id="filter2_f_2161_30147"
          x="55.6858"
          y="21.012"
          width="30.0619"
          height="29.3226"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30147"
          />
        </filter>
        <clipPath id="clip0_2161_30147">
          <rect
            width="105.697"
            height="105.697"
            fill="white"
            transform="translate(0.693359 0.105469)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Personality3;

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import AudioPlayer from "../../Components/AudioPlayer";
import { visualMappings, topics } from "./mappings";
import "./style.css";
import WordList from "./WordList";

import aud from "../../Assets/audio/audio1.mp3";
import aud1 from "../../Assets/audio/audio2.mp3";

import StrengthsBlindSpots from "./StrengthsBlindSpots";
import EaseInEaseOut from "./EaseInEaseOut";
import activeListening from "../../Assets/images/activeListening.svg";
import LogicRational from "../../Assets/images/LogicRational.svg";
import PresentFacts from "../../Assets/images/PresentFacts.svg";
import CircularLoader from "./CircularLoader";
import GradientGraphVertical from "../../Components/StoriesGraph/ProcessingGraph";
import { GraphDecisionInfo } from "../../Components/Graphs/DecisionInfoGraph";
import ProgressPercentageElement from "./ProgressPercentageElement";
import BarChartTest from "../../Components/StoriesGraph/TestGraph";
import GradientGraph from "../../Components/StoriesGraph/motivationGraph";
import {
  convertKeysToNormalString,
  transformData,
} from "../../Utils/CommonFunctions/graph";
import DonutChart from "../../Components/StoriesGraph/DonutChart";
import GlitterPolyGraph from "./GlitterPolyGraph";
import Personality0GraphGlower from "../../Components/Personality0GraphGlower/Personality0GraphGlower";
import { fetchTopicData } from "../../Services/arisStory";
import RadarGraph from "../../Components/StoriesGraph/PersonalityGraph";
import RadarChartManipulator from "../../Components/StoriesGraph/PersonalityOpt";
import PersonalityGraphNoBG from "../../Components/StoriesGraph/PersonalityGraphNoBG";
import ScrollEffect from "./ScrollEffect";
import { useHeader } from "../../Providers/HeaderContextProvider.jsx";

const Topic = () => {
  const { setHeaderContent } = useHeader();
  useLayoutEffect(() => {
    setHeaderContent(true);
  }, []);
  const [searchParams] = useSearchParams();

  const fromOnBoarding = searchParams.get("fromOnBoarding");
  const [currentPage, setCurrentPage] = useState(() => {
    // Retrieve the value from localStorage or default to 1
    const savedPage = localStorage.getItem("selectedPageNo");
    return savedPage ? parseInt(savedPage, 10) : 1;
  });
  const [currentText, setCurrentText] = useState("");

  const [currentWord, setcurrentWord] = useState("");
  const [currentAudio, setcurrentAudio] = useState(null);
  const [currentVisuals, setcurrentVisuals] = useState([]);
  const [currentChart, setcurrentChart] = useState(null);
  const [pauseAudio, setpauseAudio] = useState(false);

  const [duration, setDuration] = useState(null);
  const category = useParams().category;
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const currentWordRef = useRef(null);
  const durationRef = useRef(null);

  useEffect(() => {
    console.log("re-rendering");
  }, []);

  useEffect(() => {
    console.log("re-rendering1111");
    fetchTopicData(category, currentPage).then((response) => {
      const visualsArray =
        response?.[`Screen${currentPage}Visual`]?.split("\n") || [];
      console.log("🟩 currentVisuals111: ", visualsArray);

      setcurrentVisuals([...visualsArray]);
      console.log(response?.[`Screen${currentPage}Visual`], "fromStory");
      setCurrentText(response?.[`Screen${currentPage}`]);
      setcurrentAudio(
        `https://s3.us-east-1.amazonaws.com/public-resources.aris.ai/app/audio/prism_story_surepeople_temp/` +
          response?.[`Screen${currentPage}Audio`] +
          ".mp3?nocache=1"
      );

      setcurrentChart(response?.matrix);
    });
  }, []);

  useEffect(() => {
    if (currentVisuals && Object.keys(currentVisuals).length > 0) {
      setIsReady(true);
      console.log("Updated currentVisuals:", currentVisuals);
    }
  }, [currentVisuals]);

  const words = ["speed", "communication style"];

  const easeInEaseOut = [
    { id: 1, image: activeListening, text: "Active Listening" },
    { id: 2, image: LogicRational, text: "Logic & Rational" },
    { id: 3, image: PresentFacts, text: "Present Facts" },
  ];

  const processingChartData = [
    {
      topLabel: "External",
      bottomLabel: "Internal",
      segments: [
        {
          percent: currentChart?.processing?.Internal,
          colors: ["#6ECFB8", "#A8EE93"],
        },
        {
          percent: currentChart?.processing?.External,
          colors: ["#6ECFB8", "#A8EE93"],
        },
      ],
    },
    {
      topLabel: "Concrete",
      bottomLabel: "Intuitive",
      segments: [
        {
          percent: currentChart?.processing?.Intuitive,
          colors: ["#6ECFB8", "#A8EE93"],
        },
        {
          percent: currentChart?.processing?.Concrete,
          colors: ["#6ECFB8", "#A8EE93"],
        },
      ],
    },
    {
      topLabel: "Logic",
      bottomLabel: "Emotion",
      segments: [
        {
          percent: currentChart?.processing?.Emotion,
          colors: ["#6ECFB8", "#A8EE93"],
        },
        {
          percent: currentChart?.processing?.Logic,
          colors: ["#6ECFB8", "#A8EE93"],
        },
      ],
    },
    {
      topLabel: "Orderly",
      bottomLabel: "Spontaneous",
      segments: [
        {
          percent: currentChart?.processing?.Spontaneous,
          colors: ["#6ECFB8", "#A8EE93"],
        },
        {
          percent: currentChart?.processing?.Orderly,
          colors: ["#6ECFB8", "#A8EE93"],
        },
      ],
    },
  ];

  //ConflictGraph

  const conflictChart = [
    {
      category: "Collaborating",
      value: currentChart?.conflictManagement?.Collaborate,
    },
    {
      category: "Accommodating",
      value: currentChart?.conflictManagement?.Compromise,
    },
    {
      category: "Compromising",
      value: currentChart?.conflictManagement?.Compete,
    },
    { category: "Avoiding", value: currentChart?.conflictManagement?.Avoid },
    {
      category: "Competing",
      value: currentChart?.conflictManagement?.Accommodate,
    },
  ];

  const motivationWhy = currentChart?.motivationWhy || {};
  // {
  //   Compliance: 0,

  //   Affiliation: 50,

  //   Power: 100,

  //   AttainmentOfGoals: 60,

  //   RecognitionForEffort: 40,

  //   Activity: 50,
  // };

  const motivationHow = currentChart?.motivationHow || {};
  // {
  //   TaskCompletion: 75,

  //   ExchangeOfIdeas: 21,

  //   Consistency: 29,

  //   AffirmedByOthers: 75,

  //   SelfAffirmed: 25,

  //   ReceiveDirection: 79,

  //   Freedom: 71,

  //   PrefersProcess: 25,
  // };

  const convertedDataToMap = convertKeysToNormalString(
    currentChart?.motivationHow || {}
  );
  const convertedDataToMap2 = convertKeysToNormalString(
    currentChart?.motivationHow || {}
  );

  const data2 = transformData(convertedDataToMap);
  const data5 = transformData(convertedDataToMap2);

  const generateTimestamps = (currentText) => {
    // Calculate total letter count across all words
    const totalLetterCount = currentText?.reduce(
      (sum, word) => sum + word.length,
      0
    );

    // Calculate the duration per letter
    const letterDuration = durationRef.current / totalLetterCount;

    // Generate timestamps based on letter count of each word
    let currentTime = 0;
    return currentText?.map((word) => {
      const wordDuration = word.length * letterDuration;
      const timestamp = {
        word,
        start: currentTime,
        end: currentTime + wordDuration,
      };
      currentTime += wordDuration; // Update current time for the next word
      return timestamp;
    });
  };

  const onAudioComplete = () => {
    setcurrentVisuals([]);
    setIsReady(false);

    const pageVisuals = visualMappings[category];
    const currentVisual = pageVisuals.find((v) => v.page === currentPage);
    const lastPage = pageVisuals[pageVisuals.length - 1].page;

    console.log("current visual: ", currentVisual);
    console.log("Current Page: ", currentPage);
    console.log("Last Page: ", lastPage);
    if (currentPage === lastPage && fromOnBoarding) {
      navigate(`/onboarding/chat`);
    } else if (currentPage < lastPage) {
      fetchTopicData(category, currentPage + 1).then((response) => {
        const visualsArray =
          response?.[`Screen${currentPage + 1}Visual`]?.split("\n") || [];
        console.log("🟩 currentVisuals111: ", visualsArray);

        setcurrentVisuals([...visualsArray]);
        console.log(response?.[`Screen${currentPage + 1}Visual`], "fromStory");
        setCurrentText(response?.[`Screen${currentPage + 1}`]);
        setcurrentAudio(
          `https://s3.us-east-1.amazonaws.com/public-resources.aris.ai/app/audio/prism_story_surepeople_temp/` +
            response?.[`Screen${currentPage + 1}Audio`] +
            ".mp3"
        );

        setcurrentChart(response?.matrix);
      });
      setCurrentPage((prev) => prev + 1);
    } else {
      const currentTopicIndex = topics.findIndex(
        (topic) => topic.category === category
      );
      if (currentTopicIndex < topics.length - 1) {
        const nextCategory = topics[currentTopicIndex + 1].category;
        navigate(`/topic/${nextCategory}`);
        fetchTopicData(category, 1).then((response) => {
          const visualsArray =
            response?.[`Screen${1}Visual`]?.split("\n") || [];
          console.log("🟩 currentVisuals111: ", visualsArray);

          setcurrentVisuals([...visualsArray]);
          console.log(response?.[`Screen${1}Visual`], "fromStory");
          setCurrentText(response?.[`Screen${1}`]);
          setcurrentAudio(
            `https://s3.us-east-1.amazonaws.com/public-resources.aris.ai/app/audio/prism_story_surepeople_temp/` +
              response?.[`Screen${1}Audio`] +
              ".mp3"
          );

          setcurrentChart(response?.matrix);
        });
        setCurrentPage(1);
      } else {
        if (fromOnBoarding) navigate("/onboarding/chat");
        else navigate("/dashboard");
      }
    }

    setTimeout(() => {}, 1000);
  };

  const steps = [
    { bgType: "Outward", percent: currentChart?.decisionMaking?.Outward || 0 },
    { bgType: "Careful", percent: currentChart?.decisionMaking?.Careful || 0 },
    { bgType: "Rapid", percent: currentChart?.decisionMaking?.Rapid || 0 },
  ];

  // Function to update progress data and call itself recursively

  const audioDuration = 5; // Assume 5 seconds for the audio
  const timestamps = currentText
    ? generateTimestamps(currentText?.split(" "))
    : [];
  function getComponent(key, props = {}) {
    switch (key) {
      case "CircularLoader":
        return <CircularLoader {...props} />;
      case "WordList":
        // return <StrengthsBlindSpots arr={currentVisuals} {...props} />;
        return isReady ? (
          <StrengthsBlindSpots
            arr={currentVisuals}
            isStop={pauseAudio}
            {...props}
          />
        ) : (
          <CircularLoader /> // Fallback while waiting for data
        );
      case "WordListBlind":
        return isReady ? (
          <StrengthsBlindSpots
            arr={currentVisuals}
            isStrength={false}
            isStop={pauseAudio}
            {...props}
          />
        ) : (
          <CircularLoader /> // Fallback while waiting for data
        );
      case "PracticeAreaGraph":
        return isReady ? (
          <EaseInEaseOut
            list={currentVisuals}
            currentWord={currentWordRef.current}
          />
        ) : (
          <CircularLoader /> // Fallback while waiting for data
        );

      case "WordAnimator":
        return isReady ? (
          <WordList
            words={currentVisuals}
            currentWord={currentWordRef.current}
          />
        ) : (
          <CircularLoader /> // Fallback while waiting for data
        );
      case "ProcessingGraph":
        return (
          <GradientGraphVertical data={processingChartData} isStory={true} />
        );
      case "DecisionInfoGraph":
        return (
          <div className="potraits-graph-decision-making no-border">
            <div className="des-graph-hero-cntr">
              <div>
                <div className="des-graph-percent clr-fff">{92 + "%"}</div>
                <div className="des-graph-sub clr-fff">Outward</div>
              </div>
              <div className="des-graph-inside-cntr">
                <div>
                  <div className="des-graph-percent clr-fff">{50 + "%"}</div>
                  <div className="des-graph-sub clr-fff">Careful</div>
                </div>
                <GraphDecisionInfo
                  data={currentChart?.decisionMaking}
                  isStory={true}
                />
                <div>
                  <div className="des-graph-percent clr-fff">{50 + "%"}</div>
                  <div className="des-graph-sub clr-fff">Rapid</div>
                </div>
              </div>
              <div>
                <div className="des-graph-percent clr-fff">{80 + "%"}</div>
                <div className="des-graph-sub clr-fff">Inward</div>
              </div>
            </div>
          </div>
        );
      case "CircularProgressBar":
        return <ProgressPercentageElement steps={steps} />;
      case "CircularProgressBarCareful":
        return (
          <ProgressPercentageElement
            bgType={"Careful"}
            percent={currentChart?.decisionMaking?.Careful || 0} // Use the actual percentage value from your data
          />
        );
      case "CircularProgressBarRapid":
        return (
          <ProgressPercentageElement
            bgType={"Rapid"}
            percent={currentChart?.decisionMaking?.Rapid || 0} // Use the actual percentage value from your data
          />
        );
      case "ConflictGraph":
        return <BarChartTest data={conflictChart} isStory={true} />;
      case "MotivationGraph":
        return <GradientGraph data={data2} />;
      case "MotivationGraphHow":
        return <GradientGraph data={data5} />;
      case "PieChartGraph":
        return <DonutChart chart={currentChart?.fundamentalNeeds} />;
      case "Personality16Graph":
        if (durationRef.current) {
          return <GlitterPolyGraph duration={durationRef.current} />;
        } else {
          return <CircularLoader />;
        }

      case "PersonalityInfoGraphPrimary":
        return (
          <div>
            <div className="personality-graph-txt-emiter_story non-boreder-margin ">
              <div className="personality-txt-normal personality-graph-txt whit-color">
                <div style={{ marginLeft: "-2rem" }}>POWERFUL</div>
                <div style={{ marginRight: "-2rem" }}>VERSATILE</div>
              </div>
              <RadarGraph personalityGraphdata={currentChart?.personality} />
              <div className="personality-txt-normal personality-graph-txt whit-color">
                <div style={{ marginLeft: "-2rem" }}>PRECISE</div>
                <div style={{ marginRight: "-2rem" }}>AMIABLE</div>
              </div>
            </div>
            <div className="personality-type-stories">
              {currentChart?.personality?.Primary?.personalityType}
            </div>
          </div>
        );
      case "PersonalityInfoGraphUnderPressure":
        return (
          <>
            <div className="personality-graph-txt-emiter_story non-boreder-margin ">
              <div className="personality-txt-normal personality-graph-txt whit-color">
                <div style={{ marginLeft: "-2rem" }}>POWERFUL</div>
                <div style={{ marginRight: "-2rem" }}>VERSATILE</div>
              </div>
              <RadarGraph
                personalityGraphdata={currentChart?.personality}
                isUnderPressure={true}
              />
              <div className="personality-txt-normal personality-graph-txt whit-color">
                <div style={{ marginLeft: "-2rem" }}>PRECISE</div>
                <div style={{ marginRight: "-2rem" }}>AMIABLE</div>
              </div>
            </div>
            <div className="personality-type-stories">
              {currentChart?.personality?.UnderPressure?.personalityType}
            </div>
          </>
        );
      case "RadarChartManipulator":
        return <RadarChartManipulator text={currentText} />;
      case "PersonalityGraphNoBG":
        return (
          <PersonalityGraphNoBG
            personalityGraphdata={currentChart?.personality}
          />
        );
      case "Personality0GraphGlower":
        if (durationRef.current) {
          return (
            <Personality0GraphGlower
              duration={durationRef.current}
              isAudioPlaying={!pauseAudio}
            />
          );
        } else {
          return <CircularLoader />;
        }
      default:
        return null; // Return null or a default component if the key doesn't match
    }
  }

  const renderPageVisuals = () => {
    const pageVisuals = visualMappings[category]?.filter(
      (visual) => visual.page === currentPage
    );

    return pageVisuals.map((visual, index) => {
      // const VisualComponent = componentMap[visual.component];
      const [numerator, denominator] = visual.visualOrder.split("/");

      return (
        <div
          key={`${visual.component}-${index}`}
          className={`visual-item visual-order-${numerator}-${denominator}`}
          style={{
            gridArea: `visual-${numerator}`,
          }}
        >
          {getComponent(visual.component, { arr: currentVisuals })}
          {/* {React.cloneElement(componentMap[visual.component], currentVisuals)} */}
        </div>
      );
    });
  };

  const pauseHandle = useCallback(() => {
    setpauseAudio((prev) => !prev);
  }, []);

  console.log("🟩 currentVisuals: ", durationRef.current, duration);
  const onCurrentWordChange = useCallback((e) => {
    currentWordRef.current = e;
    setcurrentWord(e);
  }, []);
  const durationChange = useCallback((e) => {
    durationRef.current = e;
    setDuration(e);
  }, []);

  console.log(fromOnBoarding, "fromOnBoardingmmkk");
  return (
    <div className="story-container">
      <div></div>
      <div className="visual-content">{renderPageVisuals()}</div>

      <div className="comb-txt-audio-container">
        {/* <div className="audio-content">
          <span>{currentText}</span>
        </div> */}
        <ScrollEffect
          text={currentText}
          audioSrc={currentAudio}
          pause={pauseAudio}
        />

        <AudioPlayer
          audioSrc={currentAudio}
          timestamps={timestamps}
          onTextUpdate={onCurrentWordChange}
          duration={durationChange}
          audioCompleted={onAudioComplete}
          paused={pauseHandle}
        />
      </div>
    </div>
  );
};

export default Topic;

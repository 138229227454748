


import React,{useEffect,useState} from "react";
import { ReactComponent as LeftArrow } from "../../Assets/icon/arrow-narrow-left.svg";
import { ReactComponent as SmallScreenLeftArrow } from "../../Assets/icon/arrow-narrow-left-white.svg"; // New left arrow for small screens
import "./style.css";

const TopBar = ({
  title,
  showBackButton,
  onBackClick,
  rightIcon,
  onRightIconClick,
  edit,
  titleStyle,
  smallScreenRightIcon, // New prop for small screen right icon
  useRegularArrow=false, // Ensures the regular arrow is used
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 480);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 480);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`relationship-top-bar ${!showBackButton ? "" : "centered-modal"}`}>
       {showBackButton && (
        (isSmallScreen && !useRegularArrow) ? (
          // Render small screen left arrow icon
          <SmallScreenLeftArrow
            className="relation-hover-pointer left-arrow"
            onClick={onBackClick}
          />
        ) : (
          // Render normal left arrow icon
          <LeftArrow
          className={`relation-hover-pointer left-arrow ${
            useRegularArrow ? "use-regular-arrow" : ""
          }`}
          onClick={onBackClick}
          style={useRegularArrow ? { padding: "10px",background:"#fff",border:"1px solid #E4E4E7",borderRadius:"50%" } : {}}
        />
        )
      )}
      <h1 style={titleStyle} className={showBackButton ? "centered-title" : ""}>
        {title}
      </h1>
      {rightIcon && (
        <div
          className="relation-hover-pointer header-person"
          onClick={onRightIconClick}
        >
          {isSmallScreen ? smallScreenRightIcon : rightIcon}
        </div>
      )}
      {edit && (
        <div onClick={onRightIconClick} style={{ position: "absolute", right: "0px" }}>
          {edit}
        </div>
      )}
    </div>
  );
};

export default TopBar;
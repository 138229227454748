import React from "react";
import { ReactComponent as Strength } from "../../Assets/images/strength.svg";
import { ReactComponent as Blind } from "../../Assets/images/blindSpot.svg";
import { ReactComponent as Info } from "../../Assets/icon/Info-ref.svg";
import InfoTooltip from "../RelationShip/RelationShipChatScreen/InsightComponents/InfoTooltip";

function TopStrength({
  topStrengths = "",
  isBlind = true,
  topStrengthsToolTip = "", // Will come from API
}) {
  const topStrengthsToolTipArr = topStrengthsToolTip?.split("\n");
  console.log(
    topStrengthsToolTip,
    topStrengthsToolTipArr,
    "topStrengthsToolTip"
  );
  
  console.log(topStrengths, "topStrengths");

  return (
    <div className="top-strength-hero-container">
      <div className="head-top-strength">
        {isBlind ? "Potential Blind Spots" : "Top Strengths"}
      </div>
      <div className="top-strength-list-cntr">
        {topStrengths === null && "-"}
        {topStrengths &&
          topStrengths?.description.split("\n")?.map((_list, index) => {
            console.log(
              index,
              topStrengthsToolTip?.split("\n")[index],
              _list,
              "index"
            );
            return (
              <div className="strength-list" key={_list}>
                <div>{isBlind ? <Blind /> : <Strength />}</div>
                {_list}
                <div key={_list}>
                  <InfoTooltip
                    key={topStrengthsToolTipArr[index]}
                    index={index}
                    content={topStrengthsToolTipArr[index] || ""}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TopStrength;

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import "./style.css";
import RelationShipChat from "../RelationShip/RelationShipChatScreen/RelationShipChat";
import topBg from "../../Assets/images/elipse-to-active-bg.svg";
//elipse-top-mobile
import topBgMobile from "../../Assets/images/elipse-top-mobile.svg";
import BlueSvgBackground from "../../Components/BlueSvgBackground";
import {
  getSurveyQuestions,
  submitSurveyAnswer,
} from "../../Services/newSurvey";
import { convertApiResponse } from "../../Utils/CommonFunctions/survey";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../Providers/HeaderContextProvider";
import { getUserProfile } from "../../Services/MyProfile";
function NewSurvey() {
  const { setHeaderContent, setHeaderContentRightSideContent, addProfileData } =
    useHeader();

  const navigate = useNavigate();
  useEffect(() => {
    callNextQuest(true);
  }, []);
  useLayoutEffect(() => {
    setHeaderContent(true);
    setHeaderContentRightSideContent(false);
  }, []);

  const [chatData, setchatData] = useState([]);
  const [lastQuestType, setlastQuestType] = useState(null);
  const [loadingDataFromAPI, setloadingDataFromAPI] = useState(false);
  const [lastQuestID, setlastQuestID] = useState("");

  const pushInData = async (data) => {
    setchatData((prev) => [
      ...prev,
      {
        role: "user",
        content: data,
        isSurvey: true,
      },
    ]);
    // const systemEntries = chatData.filter((item) => item.role === "system");
    // let lastID =
    //   systemEntries.length > 0
    //     ? systemEntries[systemEntries.length - 1]?.question_id
    //     : "empty";
    // console.log(lastQuestID, "llkkjhggf");
    await submitAnswer(lastQuestID, data);
  };

  const callNextQuest = (is_first_render = false) => {
    setchatData((prevChatData) => [
      ...prevChatData,
      {
        role: "system",
        content: "",
        message_type: "",
        isSurvey: true,
      },
    ]);
    getSurveyQuestions(is_first_render)
      .then((res) => {
        // let keyPix = res?.length ===1 ?
        // console.log(res[res?.length - 1]?.question_id, "lloopppp");
        setlastQuestID(res[res?.length - 1]?.question_id);
        let converted = convertApiResponse(res);
        setchatData((prevChatData) => {
          if (prevChatData.length === 0) return prevChatData;
          const newItems = [...prevChatData];
          newItems[newItems.length - 1] = converted[0];

          let convertedSplit = converted.slice(1);
          return [...newItems, ...convertedSplit];
        });
        setloadingDataFromAPI(false);
        setlastQuestType(converted[converted?.length - 1]?.message_type);
        // console.log(
        //   converted[converted?.length - 1]?.question_id,
        //   "lastQuestID"
        // );
        // setlastQuestID(converted[converted?.length - 1]?.question_id);
      })
      .catch((err) => {
        setloadingDataFromAPI(false);
        setchatData((prevChatData) => {
          if (prevChatData.length === 0) return prevChatData;
          const newItems = [...prevChatData];
          newItems[newItems.length - 1].content = {
            desc:
              err?.message ||
              err?.response?.data?.message ||
              "Something Went wrong Please try after some time ...",
            options: [],
          };
          return [...newItems];
        });
        console.log(err?.message, "ttrtr");
      });
  };

  const submitAnswer = async (question_id, label) => {
    console.log(question_id, "onCall");
    await submitSurveyAnswer(question_id, label)
      .then((res) => {
        callNextQuest();
      })
      .catch((err) => console.log(err));
  };

  const optionSelected = async (data) => {
    if (data?.type === "CTA") {
      if (data?.startAssessment) {
        const queryParams = new URLSearchParams();
        const categoryId = data?.nextCategoryId || "";
        queryParams.append("categoryId", categoryId);
        navigate(`/onboarding/instructions?${queryParams.toString()}`);
      } else if (!data?.startAssessment && data?.userStory) {
        const queryParams = new URLSearchParams();
        const fromOnBoarding = true;
        queryParams.append("fromOnBoarding", fromOnBoarding);
        navigate(
          `/onboarding/story/${data?.userStory}?fromOnBoarding=${fromOnBoarding}`
        );
      } else if (data?.load_chat) {
        getUserProfile().then((res) => {
          localStorage.setItem("profile", JSON.stringify(res));
          addProfileData(res);
          navigate("/chat");
        });
      } else {
        callNextQuest();
      }
    } else {
      setchatData((prev) => [
        ...prev,
        { role: "user", content: data?.label || data },
      ]);
      await submitAnswer(data?.question_id, data?.label);
    }
  };

  console.log(chatData, lastQuestType, lastQuestID, "chatData");
  return (
    <div className="new-survey-hero-container">
      {/* <div
        className="survey-bg-mobile-mid"
        style={{
          width: 443.9,
          height: 425.6,
          transform: "rotate(-63.72deg)",
          transformOrigin: "0 0",
          opacity: 0.7,
          background: "#31C6AF",
          boxShadow: "120px 120px 120px ",
          borderRadius: 9999,
          filter: "blur(120px)",
        }}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="376"
        height="570"
        viewBox="0 0 376 570"
        fill="none"
        className="bottom-bg"
      >
        <g opacity="0.7" filter="url(#filter0_f_9266_175079)">
          <path
            d="M55.4405 120.157C142.58 163.192 208.318 240.569 238.195 335.267C268.073 429.964 259.64 534.225 214.754 625.111C169.867 715.998 92.2037 786.067 -1.15242 819.903C-94.5085 853.739 -195.91 848.571 -283.049 805.535L-113.804 462.846L55.4405 120.157Z"
            fill="#31C6AF"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_9266_175079"
            x="-403.049"
            y="0.15654"
            width="778.832"
            height="961.855"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="60"
              result="effect1_foregroundBlur_9266_175079"
            />
          </filter>
        </defs>
      </svg>

      <img src={topBg} alt="top" className={"survey-top-bg-clr"} />
      <img src={topBgMobile} alt="top" className={"survey-top-bg-clr-mobile"} /> */}
      <BlueSvgBackground>
        <div className="relationship-container align-down mb-16 overlay-tech">
          <RelationShipChat
            onEnterData={pushInData}
            chatData={chatData}
            awaitingResponse={false}
            onOptionSelected={optionSelected}
            hideChatInput={
              lastQuestType === "cta" || lastQuestType === "actionable"
            }
            isAiLoading={loadingDataFromAPI}
          />
        </div>
      </BlueSvgBackground>
    </div>
  );
}

export default NewSurvey;

import React, { useState, useCallback, useRef, useEffect } from "react";
import "./style.css";
import { ReactComponent as MicIcon } from "../../Assets/icon/ToolIcon.svg";
import { ReactComponent as GroupShade } from "../../Assets/icon/Group 9080.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import MicPermissionModal from "./Micphonedenied";
import { convertVoicetoName } from "../../Services/arisAI";
import { FaPlay } from "react-icons/fa";
import { IoPause } from "react-icons/io5";
import Stroke1 from "../../Assets/icon/Stroke1.svg"; // Stroke image
import { useNavigate } from "react-router-dom";

const OnboardingNameRecording = ({ setSessionState, onNextStep, setStep }) => {
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const intervalRef = useRef(null);
  const [isMicAccessGranted, setIsMicAccessGranted] = useState(false);
  const [showMicPermissionModal, setShowMicPermissionModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = useRef(null);

  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    const checkMicAccess = async () => {
      try {
        const permissionResult = await navigator.permissions.query({
          name: "microphone",
        });

        if (permissionResult.state === "granted") {
          setIsMicAccessGranted(true);
        } else if (permissionResult.state === "denied") {
          setIsMicAccessGranted(false);
          setShowMicPermissionModal(true);
        } else {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          setIsMicAccessGranted(true);
        }
      } catch (error) {
        setIsMicAccessGranted(false);
        setShowMicPermissionModal(true);
      }
    };

    checkMicAccess();

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const handleMicPermissionDenied = () => {
    setShowMicPermissionModal(false);
    setSessionState(false);
    setStep(8);
  };

  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/mp3",
        });
        setAudioBlob(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setSessionState(true);
      setShowConfirmation(false);

      intervalRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 59) {
            setMinutes((prevMinutes) => prevMinutes + 1);
            return 0;
          }
          return prevTimer + 1;
        });
      }, 1000);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  }, [setSessionState]);

  const stopRecording = useCallback(async () => {
    setIsLoading(true);
    if (intervalRef.current) clearInterval(intervalRef.current);

    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());

      await new Promise((resolve) => {
        mediaRecorderRef.current.onstop = async () => {
          const mp3File = new File(audioChunksRef.current, "recording.mp3", {
            type: "audio/mpeg",
          });

          setAudioBlob(mp3File);

          try {
            const responseBlob = await convertVoicetoName(mp3File);

            const url = URL.createObjectURL(responseBlob);
            setAudioUrl(url);
            setShowConfirmation(true);

            audioRef.current = new Audio(url);
            audioRef.current.onended = () => setIsPlaying(false);
          } catch (error) {
            console.error("Error in voice-to-name conversion:", error);
          }
          resolve();
        };
      });
    }
    setIsLoading(false);
    setIsRecording(false);
  }, []);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, []);

  const closeModal = function () {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    setTimer(0);
    setMinutes(0);
    setSessionState(false);
    setStep(8);
  };

  const confirmRecording = useCallback(async () => {
    try {
      // onNextStep(4, audioBlob, true);
      // setStep(4);
      // setSessionState(false);
      navigate("/onboarding/chat");
    } catch (error) {
      console.error("Error sending recording:", error);
    }
  }, [audioBlob, setSessionState, onNextStep]);

  const redoRecording = useCallback(() => {
    setTimer(0);
    setMinutes(0);
    setShowConfirmation(false);
  }, []);

  const formatTime = () => {
    return `${minutes.toString().padStart(2, "0")}:${timer
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="onboarding-rec-modal-backdrop">
      <div className="onboarding-rec-modal">
        {showMicPermissionModal && (
          <MicPermissionModal
            onPermissionGranted={() => setIsMicAccessGranted(true)}
            onPermissionDenied={handleMicPermissionDenied}
          />
        )}

        <button className="onboarding-rec-close-button" onClick={closeModal}>
          <CloseIcon />
        </button>
        <GroupShade className="onboarding-rec-group-shade" />
        <div className="onboarding-rec-icon-container">
          <div className="onboarding-rec-microphone-icon">
            {/* <div className="stroke"></div> */}
            {/* <img src={Stroke1} alt="Stroke Layer 1" className="stroke-1-ov" />
        <img src={Stroke1} alt="Stroke Layer 2" className="stroke-2-ov" /> */}
            {showConfirmation && audioUrl ? (
              <div className="identifies-s1">
                {isPlaying ? (
                  <div
                    onClick={() => {
                      audioRef.current?.pause();
                      setIsPlaying(false);
                    }}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IoPause className="onboarding-rec-mic-icon" />
                  </div>
                ) : (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();

                      if (audioRef.current && audioUrl) {
                        audioRef.current.currentTime = 0;

                        audioRef.current
                          .play()
                          .then(() => {
                            setIsPlaying(true);
                          })
                          .catch((err) => {
                            console.error("Error playing audio:", err);
                          });
                      } else {
                        console.error("No audioRef or audioUrl found.");
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaPlay className="onboarding-rec-mic-icon" />
                  </div>
                )}
              </div>
            ) : (
              <MicIcon className="onboarding-rec-mic-icon" />
            )}
          </div>
        </div>
        <p className="onboarding-rec-modal-title">
          {showConfirmation
            ? "Did I pronounce that right?"
            : `Say "My name is (your name)"`}
        </p>
        <p className="onboarding-rec-timer">{formatTime()}</p>
        {isRecording ? (
          <>
            <button
              className="onboarding-rec-stop-button"
              onClick={stopRecording}
            >
              {isLoading ? <div className="spinner" /> : "Stop Recording"}
            </button>
            <button className="onboarding-rec-maybe-later" onClick={closeModal}>
              Maybe Later
            </button>
          </>
        ) : showConfirmation ? (
          <>
            <button
              className="onboarding-rec-stop-button"
              onClick={confirmRecording}
            >
              Sounds good!
            </button>
            <button
              className="onboarding-rec-maybe-later"
              onClick={redoRecording}
            >
              Redo
            </button>
          </>
        ) : (
          <>
            <button
              className="onboarding-rec-start-button"
              onClick={startRecording}
            >
              Start recording
            </button>
            <button className="onboarding-rec-maybe-later" onClick={closeModal}>
              Maybe Later
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default OnboardingNameRecording;

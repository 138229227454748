import React from "react";

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ChangingProgressProvider from "./ChangingProgressProvider";
import people from "../../Assets/images/people.png";

function ProgressPercentageElement({ bgType = "Outward", percent = 92 }) {
  const strokeGradient =
    bgType === "Outward"
      ? "url(#gradientId2)"
      : bgType === "Careful"
      ? "url(#gradientId)"
      : "url(#gradientId3)";

  console.log(strokeGradient, "llok");

  return (
    <div>
      <ChangingProgressProvider values={[0, 70]}>
        {(percentage) => (
          <CircularProgressbarWithChildren
            value={percentage}
            strokeWidth={10}
            className={`circular-progress-story header-progress`}
            styles={buildStyles({
              //   strokeLinecap: "butt",
              pathColor: strokeGradient,
              textColor: "#f88",
              trailColor: "#3557bf",
              //   backgroundColor: "#3557BF",
            })}
          >
            <svg style={{ height: 0 }}>
              <defs>
                <linearGradient id="gradientId" gradientTransform="rotate(0)">
                  <stop offset="0%" stopColor="#FF692E" />
                  <stop offset="100%" stopColor="#FFC700" />
                </linearGradient>
                <linearGradient
                  id="gradientId2"
                  gradientTransform="rotate(270)"
                >
                  <stop offset="0%" stopColor="#80D1E8" />
                  <stop offset="100%" stopColor="#82E3CC" />
                </linearGradient>
                <linearGradient
                  id="gradientId3"
                  gradientTransform="rotate(121)"
                >
                  <stop offset="0.55%" stopColor="#A8EE93" />
                  <stop offset="107.13%" stopColor="#FCB0A8" />
                </linearGradient>
              </defs>
            </svg>
            <div
              onClick={() => {
                console.log("clicked");
                window.location.href = "/myprofile";
              }}
            >
              <div className="as-progress-inner-circle">
                <div>{percent}%</div>
                <div>{bgType}</div>
              </div>
            </div>
          </CircularProgressbarWithChildren>
        )}
      </ChangingProgressProvider>
    </div>
  );
}

export default ProgressPercentageElement;

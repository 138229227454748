import React from "react";
import "./style.css";
import activeListening from "../../Assets/images/activeListening.svg";

function EaseInEaseOut({ list, currentWord }) {
  return (
    <div className="as-eieo-hero-wrapper">
      {list?.map((item) => (
        <div
          className={`as-eieo-container ${
            item?.toLowerCase()?.includes(currentWord?.toLowerCase())
              ? "as-eieo-container-active"
              : ""
          }`}
        >
          <img src={activeListening} alt="active" className="as-eieo-img" />
          <div className="as-eieo-text">{item}</div>
        </div>
      ))}
    </div>
  );
}

export default EaseInEaseOut;

// src/services/relationshipService.js
import Cookies from "js-cookie";
import axios from "axios";
import { axiosRelationshipInstance } from "../AxiosConfiguration";

const relationshipUrl = process.env.REACT_APP_BASE_URL_ASSESSMENT;

export const addRelationship = async (formData) => {
  try {
    const idToken = Cookies.get("idtoken"); // Retrieve the idToken from cookies

    const requestData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      nickname: formData.nickname || null, // Include optional fields with defaults
      relationship_type: formData.relationship_type,
      relationship_length: formData.relationship_length,
      relationship_category: formData.relationship_category,
      relationship_status: formData.relationship_status,
      relationship_goals: formData.relationship_goals || null, // Optional goals field
      email: formData.email || null, // Optional email
      country_code: formData.country_code || null,
      contact: formData.contact || null, // Optional contact
    };

    // Make the API call
    const response = await axiosRelationshipInstance.post(
      "/api/relation/add_relationship",
      requestData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`, // Include the idToken in headers
        },
      }
    );

    return response.data; // Return the response payload
  } catch (error) {
    console.error("Error adding relationship:", error);
    throw error;
  }
};

export const fetchCategoriesAndTypes = async (field = "") => {
  try {
    console.log("Fetching categories with field:", field);

    const response = await axios.get(
      `${relationshipUrl}/api/relation/get_categories_and_types`,
      {
        params: field ? { field } : {}, // Send category if provided
      }
    );

    // Return the response directly, assuming the API provides categories and types in the expected format
    return response.data.payload || [];
  } catch (error) {
    console.error(
      "Error fetching categories and types:",
      error.response?.data || error.message
    );
    return []; // Return empty array instead of throwing
  }
};

export const getRelationships = async (category = "") => {
  try {
    console.log("Fetching relationships...");

    // Construct query string only if category exists
    const queryParams = category
      ? `?category=${encodeURIComponent(category)}`
      : "";

    // No need to set headers manually; interceptors handle it
    const response = await axiosRelationshipInstance.get(
      `/api/relation/my_relationship${queryParams}`
    );

    console.log("Fetched relationships:", response.data.payload); // Debugging log
    return response.data.payload;
  } catch (error) {
    console.error(
      "Error fetching relationships:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const fetchPendingRequests = async () => {
  try {
    const response = await axiosRelationshipInstance.get(
      "/api/relation/received_requests"
    );
    const requests = response?.data?.payload || [];

    // Transforming the response payload
    const transformedRequests =
      requests?.length > 0 &&
      requests?.map((request) => ({
        id: request._id,
        firstName: request.creator_first_name || "Unknown", // Separate first name field
        lastName: request.creator_last_name || "", // Separate last name field
        email: request.user1_to_user2.email || "",
        relationshipType: request.user1_to_user2.relationship_type || "Unknown",
        relationshipCategory:
          request.user1_to_user2.relationship_category || "Unknown",
        relationshipLength:
          request.user1_to_user2.relationship_length || "Unknown",
        relationshipStatus:
          request.user1_to_user2.relationship_status || "Unknown",
        relationshipGoals: request.user1_to_user2.relationship_goals || null,
        profilePic: request.creator_profile || null,
        nickname: request.user1_to_user2.nickname || "",
        email: request.creator_email || "",
        contact: request.creator_contact || "",
        createdAt: request.created_at,
        updatedAt: request.updated_at,
      }));

    console.log("Transformed requests:", transformedRequests); // Debugging log

    return transformedRequests; // Return the transformed data
  } catch (error) {
    console.error("Error fetching pending requests:", error);
    throw error;
  }
};

export const acceptRequest = async (relationId, relationshipData) => {
  try {
    // Send the request with the necessary data in the body
    const response = await axiosRelationshipInstance.post(
      `/api/relation/accept_invite/${relationId}`,
      relationshipData
    );

    // Handle the response if needed
    if (response.status === 200) {
      console.log("Request accepted successfully:", response.data);
    }
    return response.data; // Return the response data for further use
  } catch (error) {
    console.error("Error handling accept request:", error);
    throw error; // Rethrow the error to be handled by the calling function
  }
};

// Deny relationship request
export const denyRequest = async (relationId) => {
  try {
    const response = await axiosRelationshipInstance.post(
      `/api/relation/deny_request/${relationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error denying request:", error);
    throw error; // Re-throw error for handling in component
  }
};

export const editRelationship = async (relationshipId, relationshipData) => {
  try {
    const response = await axiosRelationshipInstance.put(
      `/api/relation/edit_relationship/${relationshipId}`,
      relationshipData,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("idtoken")}`, // Ensure idToken is correctly retrieved
        },
      }
    );

    console.log("Relationship edited successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Failed to edit relationship:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const deleteRelationship = async (relationshipId) => {
  try {
    const response = await axiosRelationshipInstance.delete(
      `/api/relation/delete_relationship/${relationshipId}`, // Path without query string
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("idtoken")}`, // Ensure idToken is correctly retrieved
        },
        params: {
          relationship_id: relationshipId, // Pass the relationship_id as a query parameter
        },
      }
    );

    console.log("Relationship deleted successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Failed to delete relationship:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export async function fetchInsightContent(
  field,
  relation_id,
  model,
  u1_status,
  u2_status
) {
  try {
    // Direct axios GET request
    const response = await axiosRelationshipInstance.post(
      `/api/relation/adviceinsight`,
      {
        relation_id: relation_id,
        field: field,
        model: model,
        u1_status: u1_status,
        u2_status: u2_status,
      }
    );

    // Axios already parses JSON, so you can directly return the data
    return response.data.payload;
  } catch (error) {
    console.error("Error fetching insight content:", error);

    // Provide a fallback content
    return "Failed to fetch content.";
  }
}

export async function fetchInsightMetrics(relation_id) {
  try {
    // Direct axios GET request
    const response = await axiosRelationshipInstance.get(`/api/relation/relation/get_metrics?relationship_id=${relation_id}`);
    // Axios already parses JSON, so you can directly return the data
    return response.data.payload;
  } catch (error) {
    console.error("Error fetching insight content:", error);
    // Provide a fallback content
    return "Failed to fetch content.";
  }
}

// Helper function to convert a blob URL to a File object
async function blobUrlToFile(blobUrl, filename, mimeType) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return new File([blob], filename, { type: mimeType });
}

export const uploadFiles = async (relationId, imageFile, mp3File) => {
  try {
    if (imageFile || mp3File) {
      const formData = new FormData();

      // Append the image file if it exists and is already a File object
      if (imageFile instanceof File) {
        formData.append("image", imageFile);
      }

      // Check if mp3File is a blob URL and convert it if necessary
      if (typeof mp3File === "string" && mp3File.startsWith("blob:")) {
        mp3File = await blobUrlToFile(mp3File, "audio.mp3", "audio/mpeg");
      }

      // Append the mp3 file if it exists (now as a File object)
      if (mp3File instanceof File) {
        formData.append("mp3", mp3File);
      }

      // Make the API call to upload the files
      const response = await axiosRelationshipInstance.post(
        `/api/relation/upload-files?relation_id=${relationId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("idtoken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Files uploaded successfully:", response.data);
      return response.data;
    } else {
      console.log("No files to upload");
    }
  } catch (error) {
    console.error(
      "Failed to upload files:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const fetchOnboardingData = async (step, formData = {}) => {
  try {
    console.log("Fetching data for step:", step);

    const response = await axiosRelationshipInstance.post(
      `/api/static/onboarding/${step}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Received data:", response.data.payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error.response || error.message);
    return null;
  }
};

export const getsentrequests = async () => {
  try {
    const response = await axiosRelationshipInstance.get(
      `/api/relation/sent_requests`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("idtoken")}`,
        },
      }
    );
    if (response.data && response.data.status === "success") {
      return response.data.payload; // Return the payload from the response
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

export const getRelationshipProfile = async (relationship_id) => {
  try {
    const response = await axiosRelationshipInstance.get(
      `/api/relation/profile`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("idtoken")}`,
        },
        params: {
          relationship_id: relationship_id,
        },
      }
    );
    if (response.data && response.data.status === "success") {
      return response.data.payload; // Return the payload from the response
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

//https://5ckgu55hjegpqrfsy6hzh5wo2u0mtopt.lambda-url.us-east-1.on.aws/
// https://5ckgu55hjegpqrfsy6hzh5wo2u0mtopt.lambda-url.us-east-1.on.aws/api/relationget_insights
export const getRelationshipAdvice = async (category, type) => {
  try {
    const response = await axiosRelationshipInstance.post(
      `/api/relation/get_advice?category=${category}&type=${type}`
    );
    if (response.data && response.data.status === "success") {
      return response.data.payload; // Return the payload from the response
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

export const getRelationshipInsightModel = async () => {
  try {
    const response = await axiosRelationshipInstance.get(
      `/api/relation/get_insights`
    );
    if (response.data && response.data.status === "success") {
      return response.data.payload; // Return the payload from the response
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

export const sendJoinInvite = async (payload) => {
  try {
    const response = await axiosRelationshipInstance.post(
      "/api/relation/send/join/invite",
      payload
    );
    if (response.data && response.data.status === "success") {
      return response.data;
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error sending invitation:", error);
    throw error;
  }
};


export const resendInvite = async (relation_id) => {
  try {
    const response = await axiosRelationshipInstance.post(
      `/api/relation/resend_invite/${relation_id}`
    );
    if (response.data && response.data.status === "success") {
      return response.data;
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error sending invitation:", error);
    throw error;
  }
};

import React, { useState, useEffect, useRef } from "react";
import VoiceRecorder from "../VoiceRecoder";
import DatePicker from "react-datepicker";
import { updateBasicDetails } from "../../Services/MyProfile";
import { uploadaudio } from "../../Services/MyProfile";
import { convertVoicetoName } from "../../Services/arisAI";
import { uploadProfilePhoto } from "../../Services/auth";
import { ReactComponent as Infocircle } from "../../Assets/icon/info-circle.svg";
import { ReactComponent as Check } from "../../Assets/icon/check.svg";
import placeholder from "../../Assets/images/placeholder.png";
import { ReactComponent as Calender } from "../../Assets/icon/calendar.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { IoIosArrowDown } from "react-icons/io";
import { Pencil, Play, Pause } from "lucide-react";
import { Loader2 } from "lucide-react";
import "./style.css";

const NAME_REGEX = /^[A-Za-z]+$/;

const ProfileModal = ({ onClose, profileData, onProfileUpdate, id }) => {
  console.log("profileData", profileData);

  const [formData, setFormData] = useState({
    first_name: profileData.first_name || "",
    last_name: profileData.last_name || "",
    date_of_birth: profileData.DOB
      ? formatDateForDisplay(new Date(profileData.DOB))
      : "",
    gender: profileData.gender || "",
    aris_voice: profileData.aris_voice || "",
    originalAudio: null,
    responseAudio: null,
    profileImage: null,
  });

  const [birthday, setBirthday] = useState(
    profileData.DOB ? new Date(profileData.DOB) : null
  );
  const [imagePreview, setImagePreview] = useState(
    profileData.profile_pic_url || null
  );
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [isImageModified, setIsImageModified] = useState(false);
  const [isFieldsModified, setIsFieldsModified] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isArisVoicePlaying, setIsArisVoicePlaying] = useState(false);
  const [currentAudioType, setCurrentAudioType] = useState(null);
  const originalAudioRef = useRef(new Audio());
  const responseAudioRef = useRef(new Audio());
  const datePickerRef = useRef(null);
  const initialProfileData = useRef(profileData);
  const [isProcessingAudio, setIsProcessingAudio] = useState(false);
  const [hasExistingAudio, setHasExistingAudio] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    first_name: "",
    last_name: "",
  });
  const audioRef = useRef(null);

  // Function to format date to mm-dd-yyyy
  function formatDateForDisplay(date) {
    return date
      ? `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
          .getDate()
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`
      : "";
  }

  useEffect(() => {
    // Check if there's existing audio from the API
    if (profileData.name_audio_link) {
      setHasExistingAudio(true);
      setFormData((prev) => ({
        ...prev,
        responseAudio: profileData.name_audio_link,
      }));
    } else {
      setHasExistingAudio(false);
    }
  }, [profileData.name_audio_link]);

  useEffect(() => {
    // Clean up audio when component unmounts
    return () => {
      originalAudioRef.current.pause();
      responseAudioRef.current.pause();
    };
  }, []);

  const handleAudioPlay = (audioType) => {
    const audioRef =
      audioType === "original"
        ? originalAudioRef.current
        : responseAudioRef.current;
    const otherAudioRef =
      audioType === "original"
        ? responseAudioRef.current
        : originalAudioRef.current;
    const audioUrl =
      audioType === "original"
        ? formData.originalAudio
        : hasExistingAudio
        ? profileData.name_audio_link
        : formData.responseAudio;

    if (!audioUrl) {
      setShowErrorMessage(true);
      setStatusMessage(
        `${
          audioType === "original" ? "Original" : "Processed"
        } audio is not available`
      );
      setTimeout(() => setShowErrorMessage(false), 3000);
      return;
    }

    try {
      if (isPlaying && currentAudioType === audioType) {
        audioRef.pause();
        setIsPlaying(false);
        setCurrentAudioType(null);
      } else {
        otherAudioRef.pause();
        audioRef.src = audioUrl;

        audioRef.onerror = () => {
          setShowErrorMessage(true);
          setStatusMessage(
            `Error playing ${
              audioType === "original" ? "original" : "processed"
            } audio`
          );
          setTimeout(() => setShowErrorMessage(false), 3000);
          setIsPlaying(false);
          setCurrentAudioType(null);
        };

        audioRef.play().catch((error) => {
          console.error("Error playing audio:", error);
          setShowErrorMessage(true);
          setStatusMessage(`Error playing audio`);
          setTimeout(() => setShowErrorMessage(false), 3000);
          setIsPlaying(false);
          setCurrentAudioType(null);
        });

        setIsPlaying(true);
        setCurrentAudioType(audioType);
      }

      audioRef.onended = () => {
        setIsPlaying(false);
        setCurrentAudioType(null);
      };
    } catch (error) {
      console.error("Error handling audio playback:", error);
      setShowErrorMessage(true);
      setStatusMessage(`Error playing audio`);
      setTimeout(() => setShowErrorMessage(false), 3000);
    }
  };

  const handleEditAudio = () => {
    setFormData((prev) => ({
      ...prev,
      originalAudio: null,
      responseAudio: null,
    }));
    setHasExistingAudio(false);
    setIsEdited(true);
    setIsFieldsModified(true);
  };

  const handleRecordingComplete = async (blob, url) => {
    try {
      // Store the original recording
      setFormData((prev) => ({
        ...prev,
        originalAudio: url,
      }));

      // Set processing state to true
      setIsProcessingAudio(true);

      // Convert the voice using the API
      const response = await convertVoicetoName(blob);

      if (response.status_code === 400) {
        throw new Error(response.detail);
      }

      const responseUrl = URL.createObjectURL(response);

      setFormData((prev) => ({
        ...prev,
        responseAudio: responseUrl,
      }));

      setIsEdited(true);
      setIsFieldsModified(true);
    } catch (error) {
      console.error("Error converting voice:", error);
      setShowErrorMessage(true);

      if (error.response?.data?.detail) {
        setStatusMessage(error.response.data.detail);
      } else if (error.message) {
        setStatusMessage(error.message);
      } else {
        setStatusMessage("Error processing audio. Please try recording again.");
      }

      setFormData((prev) => ({
        ...prev,
        originalAudio: null,
        responseAudio: null,
      }));

      setTimeout(() => setShowErrorMessage(false), 3000);
    } finally {
      setIsProcessingAudio(false);
    }
  };

  const handleSubmit = async () => {
    if (isImageModified && !isFieldsModified) {
      onClose();
      return;
    }

    if (!isEdited) {
      onClose();
      return;
    }

    setLoading(true);
    setStatusMessage("");

    try {
      // Upload both original and response audio if they exist
      if (formData.originalAudio && formData.responseAudio) {
        const originalResponse = await fetch(formData.originalAudio);
        const responseResponse = await fetch(formData.responseAudio);

        const originalBlob = await originalResponse.blob();
        const responseBlob = await responseResponse.blob();

        const originalFile = new File([originalBlob], "original.mp3", {
          type: "audio/mpeg",
        });
        const responseFile = new File([responseBlob], "response.mp3", {
          type: "audio/mpeg",
        });

        // Upload both audio files
        await uploadaudio({
          originalAudio: originalFile,
          responseAudio: responseFile,
        });
      }

      if (isFieldsModified) {
        const basicDetails = {
          first_name: formData.first_name,
          last_name: formData.last_name,
          date_of_birth: birthday
            ? formatDateForDisplay(birthday)
            : formData.date_of_birth,
          gender: formData.gender,
          aris_voice: formData.aris_voice,
          name_audio_link: formData.responseAudio, // Use response audio as the pronunciation
        };

        await updateBasicDetails(basicDetails);

        onProfileUpdate({
          ...profileData,
          ...basicDetails,
          profile_pic_url: imagePreview,
        });
      }
      onClose();
    } catch (error) {
      console.error("Error updating profile:", error);
      setShowErrorMessage(true);
      setStatusMessage("Error updating profile");
      setTimeout(() => setShowErrorMessage(false), 2000);
    } finally {
      setLoading(false);
    }
  };

  // Function to format date to mm-dd-yyyy
  function formatDateForDisplay(date) {
    return date
      ? `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
          .getDate()
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`
      : "";
  }

  useEffect(() => {
    setFormData({
      first_name: profileData.first_name || "",
      last_name: profileData.last_name || "",
      date_of_birth: profileData.DOB
        ? formatDateForDisplay(new Date(profileData.DOB))
        : "",
      gender: profileData.gender || "",
      aris_voice: profileData.aris_voice || "",
      pronunciation: profileData.name_audio_link || "",
      profileImage: null,
    });

    setBirthday(profileData.DOB ? new Date(profileData.DOB) : null);
    setImagePreview(profileData.profile_pic_url || null);
    initialProfileData.current = profileData;
  }, [profileData]);

  const handleInputChange = (field, value) => {
    const errors = { ...validationErrors };

    if (field === "first_name") {
      if (!value) {
        errors.first_name = "First name is required";
      } else if (value !== value?.trim()) {
        errors.first_name = "Name should not begin or end with spaces";
      } else if (!NAME_REGEX.test(value)) {
        errors.first_name = "Name should only contain letters";
      } else if (value) {
        errors.first_name = "";
      }
    }

    if (field === "last_name") {
      if (value) {
        if (value !== value?.trim()) {
          errors.last_name = "Name should not begin or end with spaces";
        } else if (!NAME_REGEX.test(value)) {
          errors.last_name = "Name should only contain letters";
        } else if (value) {
          errors.last_name = "";
        }
      } else {
        errors.last_name = "";
      }
    }

    setValidationErrors(errors);

    if (field === "date_of_birth" && value) {
      value = new Date(value);
    }

    if (field === "aris_voice") {
      setIsArisVoicePlaying(false);
    }

    setFormData((prev) => {
      const updatedData = { ...prev, [field]: value };
      const hasFieldChanges = !isEqual(updatedData, initialProfileData.current);
      setIsFieldsModified(hasFieldChanges);
      setIsEdited(hasFieldChanges || isImageModified);
      return updatedData;
    });
  };

  const hasValidationErrors = () => {
    return Object.values(validationErrors).some((error) => error !== "");
  };

  const isFormValid = () => {
    return formData.first_name !== "" && !hasValidationErrors() && isEdited;
  };

  const handleDateChange = (date) => {
    if (date) {
      // Ensure the date is at the start of the day to avoid timezone issues
      const adjustedDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );

      const formattedDate = formatDateForDisplay(adjustedDate);

      handleInputChange("date_of_birth", formattedDate);
      setBirthday(adjustedDate);
    } else {
      handleInputChange("date_of_birth", "");
      setBirthday(null);
    }
  };

  const handleFileChange = async (file) => {
    if (file) {
      if (!file.type.startsWith("image/")) {
        setShowErrorMessage(true);
        setStatusMessage("Please select an image file");
        setTimeout(() => setShowErrorMessage(false), 2000);
        return;
      }
      if (file.size > 1 * 1024 * 1024) {
        setShowErrorMessage(true);
        setStatusMessage("Image size should be less than 1MB");
        setTimeout(() => setShowErrorMessage(false), 2000);
        return;
      }

      try {
        setUploadingImage(true);
        const imageFormData = new FormData();
        imageFormData.append("file", file);

        await uploadProfilePhoto(imageFormData);

        setImagePreview(URL.createObjectURL(file));
        setFormData((prev) => ({ ...prev, profileImage: file }));
        setIsImageModified(true);
        setIsEdited(true);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 2000);
      } catch (error) {
        console.error("Error uploading image:", error);
        setShowErrorMessage(true);
        setTimeout(() => setShowErrorMessage(false), 2000);
      } finally {
        setUploadingImage(false);
      }
    }
  };

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus(); // Programmatically focus/open the DatePicker
    }
  };
  const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

  const handlePlayPause = () => {
    if (!audioRef.current) return;

    if (isArisVoicePlaying) {
      audioRef.current.pause();
    } else {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      }
    }
    setIsArisVoicePlaying(!isArisVoicePlaying);
  };

  const getAudioSource = () => {
    if (formData.aris_voice === "Male" || formData.aris_voice === "male") {
      return "https://aris-static-app.s3.us-east-1.amazonaws.com/assets/Aris_Male+Voice_Example+.mp3";
    } else if (
      formData.aris_voice === "Female" ||
      formData.aris_voice === "female"
    ) {
      return "https://aris-static-app.s3.us-east-1.amazonaws.com/assets/Aris_Female+Voice_Example+.mp3";
    }
    return "";
  };

  return (
    <div className="uploadImage-modal">
      <div className="uploadImage-modalContent">
        <button className="uploadImage-closeButton" onClick={onClose}>
          <CloseIcon />
        </button>
        <div className="uploadImage-header">
          <div className="uploadImage-avatarWrapper">
            <img
              src={imagePreview || placeholder}
              alt="Profile"
              className="uploadImage-avatar"
              onClick={() =>
                document.getElementById("profileImageInput").click()
              }
            />
            <button
              className="uploadImage-editPhotoButton"
              onClick={() =>
                document.getElementById("profileImageInput").click()
              }
              disabled={uploadingImage}
            >
              {uploadingImage ? "Uploading..." : "Edit Photo"}
            </button>
            <input
              type="file"
              id="profileImageInput"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e.target.files[0])}
            />
          </div>
        </div>
        <div className="uploadImage-formGroup">
          <label>First Name</label>
          <input
            type="text"
            value={formData.first_name}
            onChange={(e) => handleInputChange("first_name", e.target.value)}
            className="uploadImage-input"
          />
          {validationErrors.first_name && (
            <p className="error-message name-align-left">
              {validationErrors.first_name}
            </p>
          )}
        </div>
        <div className="uploadImage-formGroup">
          <label>Last Name</label>
          <input
            type="text"
            value={formData.last_name}
            onChange={(e) => handleInputChange("last_name", e.target.value)}
            className="uploadImage-input"
          />
          {validationErrors.last_name && (
            <p className="error-message name-align-left">
              {validationErrors.last_name}
            </p>
          )}
        </div>

        <div>
          <div className="name-pronunciation-container">
            <label>Name Pronunciation (optional)</label>
            <label className="say-my-name">Say "My name is (your name)"</label>

            {hasExistingAudio || formData.originalAudio ? (
              <div className="audio-controls-container">
                {isProcessingAudio ? (
                  <div className="audio-control">
                    <button className="update-play-button" disabled>
                      <Loader2 className="animate-spin" size={20} />
                    </button>
                  </div>
                ) : hasExistingAudio || formData.responseAudio ? (
                  <div className="audio-control">
                    <div>
                      <button
                        onClick={() => handleAudioPlay("response")}
                        className="update-play-button"
                      >
                        {isPlaying && currentAudioType === "response" ? (
                          <Pause size={20} />
                        ) : (
                          <Play size={20} />
                        )}
                        Recording
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleEditAudio}
                        className="edit-button upload-edit-btn"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <VoiceRecorder
                onRecordingComplete={handleRecordingComplete}
                showEditButton={true}
              />
            )}
          </div>

          <div className="uploadImage-formGroup">
            <label>Date of Birth</label>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: "8px 12px",
                color: "var(--Base-Black, #000)",
                fontFamily: "Urbanist",
                fontSize: "16px",
                fontStyle: "normal",
                boxSizing: "border-box",
                borderRadius: "12px",
                border: "1px solid var(--gray-light-hover, #E4E4E7)",
                background: "var(--Base-White, #FFF)",
              }}
            >
              <DatePicker
                value={formData.date_of_birth}
                selected={birthday} // Bind to the Date object
                onChange={handleDateChange}
                dateFormat="MM-dd-yyyy"
                placeholderText="mm-dd-yyyy"
                className="date-picker-container-input-edit"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={new Date()}
                ref={datePickerRef}
              />

              <div onClick={openDatePicker} style={{ cursor: "pointer" }}>
                <Calender />
              </div>
            </div>
          </div>
          <div className="uploadImage-formGroup">
            <label>Gender</label>
            <div style={{ position: "relative" }}>
              <select
                value={formData.gender}
                onChange={(e) => handleInputChange("gender", e.target.value)}
                className="uploadImage-input"
              >
                <option value="" disabled={true}>
                  Select Gender
                </option>
                <option value="female">Female</option>
                <option value="male">Male</option>
                <option value="other">Other</option>
              </select>
              <IoIosArrowDown
                style={{
                  position: "absolute",
                  right: "12px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                  color: "#344054",
                }}
              />
            </div>
          </div>
          <div className="uploadImage-formGroup aris-voice-container">
            <div className="aris-voice-container-left">
              <label>Voice Preference</label>
              <div style={{ position: "relative" }}>
                <select
                  value={formData.aris_voice}
                  onChange={(e) =>
                    handleInputChange("aris_voice", e.target.value)
                  }
                  className="uploadImage-input"
                >
                  <option value="" disabled={true}>
                    Select your voice preference
                  </option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </select>
                <IoIosArrowDown
                  style={{
                    position: "absolute",
                    right: "12px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                    color: "#344054",
                  }}
                />
              </div>
            </div>
            {formData.aris_voice && (
              <button
                onClick={() => handlePlayPause()}
                className="voice-play-button voice-margin-top"
              >
                {isArisVoicePlaying ? <Pause size={24} /> : <Play size={24} />}
              </button>
            )}
            <audio
              ref={audioRef}
              src={getAudioSource()}
              onEnded={() => setIsArisVoicePlaying(false)}
            />
          </div>
        </div>

        <div className="editpage-popup">
          {showSuccessMessage && (
            <div>
              <div className="editpage-popup-content">
                <Check />
                Your photo has been updated.
              </div>
            </div>
          )}

          {showErrorMessage && (
            <div>
              <div
                className="editpage-popup-content"
                style={{ background: "rgba(240, 68, 56, 1)", color: "#fff" }}
              >
                <Infocircle />
                {statusMessage || "Something went wrong."}
              </div>
            </div>
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className={`uploadImage-updateButton ${
              isFormValid() ? "edited" : ""
            }`}
            onClick={handleSubmit}
            disabled={loading || uploadingImage || !isFormValid()}
          >
            {loading ? "Updating..." : "Update Profile"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;

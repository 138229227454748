
import React, { useState } from 'react';
import EmailPassword from './EmailPassword';
import PhoneLogin from './PhoneLogin';
import { Link } from 'react-router-dom';

const RightSection = () => {
  const [useEmail, setUseEmail] = useState(true); // State to toggle between email/phone

  const toggleLoginMethod = (method) => {
    setUseEmail(method === 'email'); // Toggles the login method
  };

  return (
    <div className="right-section">
      <div className="right-section-content">
      <h2 className="login-h2">Login</h2>

      {/* Buttons to switch between email and phone login */}
      <div className="login-toggle-buttons">
        <button 
          className={`toggle-button ${useEmail ? 'active' : ''}`} 
          onClick={() => toggleLoginMethod('email')}
        style={{padding:'0px 29px'}} >
          Email
        </button>
        <button 
          className={`toggle-button ${!useEmail ? 'active' : ''}`} 
          onClick={() => toggleLoginMethod('phone')}
        >
          Mobile Number
        </button>
      </div>

      {/* Conditional rendering for email or phone login form */}
      {useEmail ? (
        <EmailPassword onNext={() => console.log("Email login submitted")} />
      ) : (
        <PhoneLogin onNext={() => console.log("Phone login submitted")} />
      )}

      
      </div>
      <Link to="/signup" className='login-page-create-account'>Create account</Link>
    </div>
  );
};

export default RightSection;



import { Suspense } from "react";
import { HeaderProvider } from "./Providers/HeaderContextProvider";
import Layout from "./Layout";

function App() {
  return (
    <Suspense fallback={<>Loading...</>}>
      <HeaderProvider>
        <Layout />
      </HeaderProvider>
    </Suspense>
    
  );
}

export default App;
// PasswordInput.jsx
import React, { useState,forwardRef,useEffect } from 'react';
import eyeIcon from '../../Assets/images/eye.svg'; // Import the eye icon
import alertIcon from '../../Assets/images/alert-circle.svg'; // Import the alert icon

const PasswordInput =forwardRef( ({ value, onChange, error, submitted,placeholder,autoFocus }, ref) => {
    const [passwordVisible, setPasswordVisible] = useState(false); // For toggling password visibility

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
        if (autoFocus && ref && ref.current) {
          ref.current.focus(); // Automatically focus the input if `autoFocus` is true
        }
      }, [autoFocus, ref]);

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <input
          ref={ref}
                type={passwordVisible ? "text" : "password"} // Toggle between text and password
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={submitted && error ? 'error-input' : 'signup-input-boxshadow'} // Add class if there's an error
                
                autoFocus={autoFocus}
                required
            />

            {/* Eye icon for password visibility toggle (Only hide when there's an error after submission) */}
            {(!submitted || !error) && (
                <div
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                    }}
                    onClick={togglePasswordVisibility}
                >
                    <img
                        src={eyeIcon}
                        alt="Toggle Password Visibility"
                        width="16"
                        height="17"
                    />
                </div>
            )}

            {/* Show alert icon if the password is invalid after submission */}
            {submitted && error && (
                <img
                    src={alertIcon}
                    alt="Password Validation Error"
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '16px',
                        height: '17px',
                    }}
                />
            )}
        </div>
    );
});

export default PasswordInput;

import React, { createContext, useContext, useEffect, useState } from "react";
import { getUserProfile } from "../Services/MyProfile";

// Create a context for the header
const HeaderContext = createContext();

export const useHeader = () => {
  return useContext(HeaderContext);
};

export const HeaderProvider = ({ children }) => {
  const [headerContent, setHeaderContent] = useState(true);
  const [headerContentRightSideContent, setHeaderContentRightSideContent] =
    useState(true);
  const [profileContent, setprofileContentD] = useState(() => {
    const storedProfile = localStorage.getItem("profile");
    return storedProfile ? JSON.parse(storedProfile) : null;
  });

  const PROFILE_STORAGE_KEY = "profile";

  const fetchProfileData = async () => {
    console.log("Fetching profile data from API...");
    try {
      const response = await getUserProfile();
      if (!response.ok) {
        throw new Error("Failed to fetch profile data");
      }
      const data = await response.json();

      console.log("Profile data fetched:", data);

      // Store in localStorage
      localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(data));

      // Update state
      setprofileContentD(data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    // Call API immediately when component mounts
    fetchProfileData();

    // Set interval to call API every 6 seconds
    const intervalId = setInterval(() => {
      fetchProfileData();
    }, 24 * 60 * 60 * 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const addProfileData = () => {
    setprofileContentD(() => {
      const storedProfile = localStorage.getItem("profile");
      return storedProfile ? JSON.parse(storedProfile) : null;
    });
  };

  return (
    <HeaderContext.Provider
      value={{
        headerContent,
        setHeaderContent,
        headerContentRightSideContent,
        setHeaderContentRightSideContent,
        profileContent,
        addProfileData,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

import React from "react";
import { ReactComponent as ArisIcon } from "../../Assets/images/aris.svg";
import { ReactComponent as ArowLeft } from "../../Assets/images/arrow-left.svg";
import { ReactComponent as HistoryIcon } from "../../Assets/images/ChatTeardropDots.svg";

function RelationshipHeader({ onBackClick, onHistoryClick }) {
  return (
    <div className="header-component-hero-container">
      <div
        className="header-menu-icon-container white-background"
        onClick={onBackClick}
      >
        <ArowLeft />
      </div>
      <div className="header-aris-icon">
        <ArisIcon />
      </div>
      <div className="header-menu-icon-container " onClick={onHistoryClick}>
        <HistoryIcon />
      </div>
    </div>
  );
}

export default RelationshipHeader;

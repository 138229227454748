import React, { useEffect } from "react";
import ChatModal from "../../Components/ChatModal/ChatModal";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import { isMobile } from "react-device-detect";
import searchIcon from "../../Assets/images/searchIcon.png";
import { useState } from "react";
import ChatContacts from "../../Components/ChatContacts";
import { getRelationships } from "../../Services/Realtionship";
import UserPlus from "../../Assets/images/UserPlus.svg";
import { useNavigate } from "react-router-dom";
import MobilePopUp from "../../Components/MobilePopUp/MobilePopUp";
import ContentLoader from "../LoadingPage/ContentLoader";

function ContactSection({ onClose, onContactSelection }) {
  const navigate = useNavigate();
  const [activeCategoryId, setactiveCategoryId] = useState({
    id: 1,
    category: "All",
  });

  const [relations, setRelations] = useState([]);
  const [searchedName, setSearchedName] = useState("");
  const [loadRelation, setloadRelation] = useState(true);

  useEffect(() => {
    getRelationships()
      .then((res) => {
        setRelations(() => {
          if (res.length > 0) {
            if (activeCategoryId?.category === "All") return res;
            res?.filter(
              (r) =>
                r?.user1_to_user2?.relationship_type ===
                activeCategoryId?.category
            );
            // setloadRelation(false);
          }
        });
      })
      .catch((err) => console.log(err))
      .finally((res) => setloadRelation(false));
  }, [activeCategoryId, searchedName]);

  const relationShipCategory = [
    { id: 1, category: "All" },
    { id: 2, category: "Friends" },
    { id: 3, category: "Family" },
    { id: 4, category: "Parents" },
  ];

  const routoToAddReq = () => {
    // const queryParams = new URLSearchParams();
    // const optionalParam1 = "true";
    // queryParams.append("isAdd", optionalParam1);
    // navigate(`/manageRelationship?${queryParams.toString()}`);
    onContactSelection({ type: "addNewContact" });
  };

  const onSearchChange = (e) => {
    setSearchedName(e?.target?.value);
  };

  console.log(relations, searchedName, "relations");
  return (
    <div className="contact-relationship-moadl-hero-container">
      <div className="contact-relationship-modal-head">
        <div className="contact-relationship-modal-title">
          Who would you like relationship advice for?
        </div>
        <div className="contact-relationship-modal-close" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className="contact-relationship-input-cntr">
        <img
          src={searchIcon}
          alt="searchIcon"
          className="contact-relationship-modal-search-icon "
        />
        <input
          type={"text"}
          placeholder={"Search by name"}
          className="contact-relationship-modal-search"
          onChange={onSearchChange}
        />
      </div>
      <div className="contact-relationship-modal-category-cntr">
        {relationShipCategory?.map((category) => (
          <div
            id={category.id}
            key={category.id}
            className={
              "contact-relationship-modal-category-title" +
              (activeCategoryId?.id === category.id
                ? " contact-relationship-modal-category-title-txt-active-category"
                : "")
            }
            onClick={() => setactiveCategoryId(category)}
          >
            {category.category}
          </div>
        ))}
      </div>

      <div className="contact-relationship-modal-contact-list-cntr">
        <div className="chat-contacts-hero-container" onClick={routoToAddReq}>
          <div className="chat-contacts-initial chat-add-req-bg">
            <img src={UserPlus} alt="user" />
          </div>

          <div className="chat-contacts-name">{"Add Relationship"}</div>
        </div>
        {!loadRelation ? (
          relations
            ?.filter(
              (r) =>
                r?.first_name
                  ?.toLowerCase()
                  .includes(searchedName.toLowerCase()) ||
                r?.last_name?.toLowerCase().includes(searchedName.toLowerCase())
            )
            ?.map((rel) => (
              <ChatContacts
                initial={
                  rel?.first_name?.charAt(0)?.toUpperCase() +
                  "" +
                  rel?.last_name?.charAt(0)?.toUpperCase()
                }
                name={rel?.first_name + " " + rel?.last_name}
                img={rel?.profile_pic_url}
                onClick={() => onContactSelection(rel)}
              />
            ))
        ) : (
          <ContentLoader />
        )}
      </div>
    </div>
  );
}
function ContactSelectionModal({ isOpen, onClose, onContactSelection }) {
  return (
    <div>
      {isMobile ? (
        <MobilePopUp
          isPopupVisible={isOpen}
          onClose={onClose}
          hideClose={true}
          children={
            <ContactSection
              onClose={onClose}
              onContactSelection={onContactSelection}
            />
          }
        />
      ) : (
        <ChatModal
          isOpen={isOpen}
          children={
            <ContactSection
              onClose={onClose}
              onContactSelection={onContactSelection}
            />
          }
          width={isMobile ? "98%" : "35%"}
          height={isMobile ? "95%" : "70%"}
          onClose={onClose}
        />
      )}
    </div>
  );
}

export default ContactSelectionModal;

import React, { useEffect, useState } from "react";
import "./style.css";
import { ReactComponent as LeftArrow } from "../../Assets/icon/arrow-narrow-left.svg";
import { ReactComponent as LeftWhiteArrow } from "../../Assets/icon/arrow-narrow-left-white.svg";
import clappingHands from "../../Assets/images/clappingHands.png";
import thumbsUp from "../../Assets/images/thumbsUp.png";
import fire from "../../Assets/images/fire.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Stroke1 from "../../Assets/icon/Stroke1.svg"; // Stroke image
import mostLeast from "../../Assets/images/mostLeast.svg"; // Stroke image
import rank from "../../Assets/images/rank.svg"; // Stroke image
import choose from "../../Assets/images/choose.svg"; // Stroke image
import Lottie from "lottie-react";

import { useHeader } from "../../Providers/HeaderContextProvider";
import {
  startNextTestbyCategoryId,
  startQuestions,
} from "../../Services/Assessment";
import DefaultLoader from "../LoadingPage/DefaultLoader";
import ContentLoader from "../LoadingPage/ContentLoader";
import { mostLeastLottieFile } from "../../Assets/mostLeastLottieFile";
import { chooseLottieFile } from "../../Assets/choose";
import { rankLottieFile } from "../../Assets/rank";

function AssessmentEncouragement() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { setHeaderContent } = useHeader();
  const categoryId = searchParams.get("categoryId");
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setHeaderContent(false);
  }, []);
  useEffect(() => {
    startNextTestbyCategoryId(categoryId, false)
      .then((res) => {
        console.log("response from assessment", res);
        setcurrentQuestionInfo(res);
        setloading(false);
      })
      .catch((error) => {
        console.log("error from assessment", error);
        setloading(false);
      });
  }, []);

  const [currentQuestionInfo, setcurrentQuestionInfo] = useState({});

  // Function to parse query string

  const { number } = useParams();

  //on click of continue button route to assessment screen
  const onContinueClick = () => {
    navigate(`/onboarding?categoryId=${currentQuestionInfo?.categoryId}`);
  };

  //return the data based on the work percentage

  const getDataToRender = (type) => {
    if (type === "MostLeast") {
      return {
        emoji: "clapingHand",
        headTxt: "Most and Least",
        subTxt:
          "Select the word that most describes you and drag it to the Most tab.Select the word that least describes you and drag it to the Least tab. Then, click the bottom button to continue.",
      };
    } else if (type === "Rating") {
      return {
        emoji: "Rank",
        headTxt: "Rank",
        subTxt:
          "Rank these four concepts in order of importance from 1st to last. Select each option and drag it to the appropriate category. Then, click Next to continue.",
      };
    } else {
      return {
        emoji: "clapingHand",
        headTxt: "Choose",
        subTxt: "Choose the option that more accurately describes you.",
      };
    }
  };

  let progree = parseInt(number);
  const { emoji, headTxt, subTxt } = getDataToRender(
    currentQuestionInfo?.currQuestion?.type
  );
  console.log(number, "testInder");
  const getImage = (emojiName) => {
    switch (emojiName) {
      case "MostLeast":
        return mostLeastLottieFile;
      case "Rating":
        return rankLottieFile;
      default:
        return chooseLottieFile;
    }
  };

  const onClickSaveExist = () => {
    navigate("/dashboard");
  };

  const onFinishLaterClick = () => {
    navigate("/dashboard");
  };

  const onBackClick = () => {
    navigate("/onboarding/chat");
  };
  console.log(number, typeof number, currentQuestionInfo, "testInder");
  return (
    <div className="ass-encouragment-hero-container">
      <div className="supressed-bg acc-supressed-bg">
        {/* <div className="out-assess-header">
          <div>
            <LeftWhiteArrow
              className="hover-pointer ass-pointer"
              onClick={onContinueClick}
            />
          </div>
          <div className="hover-pointer ass-pointer" onClick={onClickSaveExist}>
            Save & Exit
          </div>
        </div> */}
        <div className="assess-container-encouragement">
          {loading ? (
            <>
              <ContentLoader />
            </>
          ) : (
            <>
              <div className="ass-content-container-attacher">
                <div className="assess-header">
                  <LeftArrow className="hover-pointer" onClick={onBackClick} />
                  <div className="hover-pointer" onClick={onBackClick}>
                    Save & Exit
                  </div>
                </div>
                <div className="ass-content-container">
                  <div className="overview-container-hero-eng">
                    <div className="overview-image-container-eng"></div>
                    {/* <img
                  src={Stroke1}
                  alt="Stroke Layer 1"
                  className="stroke-1-ov-eg"
                /> */}
                    {/* <img
                  src={Stroke1}
                  alt="Stroke Layer 2"
                  className="stroke-2-ov-eg"
                /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="253"
                      height="253"
                      viewBox="0 0 253 253"
                      fill="none"
                      className="po-ob"
                    >
                      <path
                        opacity="0.1"
                        d="M0.913086 126.723C0.913086 82.0285 24.9073 40.6643 63.641 18.3598C102.395 -3.95645 150.346 -3.95645 189.1 18.3598C227.834 40.6643 251.828 82.0285 251.828 126.723C251.828 171.418 227.834 212.782 189.1 235.087C150.346 257.403 102.395 257.403 63.641 235.087C24.9073 212.782 0.913086 171.418 0.913086 126.723Z"
                        stroke="#04D9B5"
                        stroke-width="1.5"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="196"
                      height="196"
                      viewBox="0 0 196 196"
                      fill="none"
                      className="po-ob"
                    >
                      <path
                        opacity="0.15"
                        d="M0.991211 98.3208C0.991211 63.804 19.5029 31.8665 49.4135 14.6354C79.3373 -2.60334 116.337 -2.60334 146.261 14.6354C176.172 31.8665 194.684 63.804 194.684 98.3208C194.684 132.838 176.172 164.775 146.261 182.006C116.337 199.245 79.3373 199.245 49.4135 182.006C19.5029 164.775 0.991211 132.838 0.991211 98.3208Z"
                        stroke="#04D9B5"
                        stroke-width="2"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="143"
                      height="143"
                      viewBox="0 0 143 143"
                      fill="none"
                      className="po-ob"
                    >
                      <path
                        opacity="0.2"
                        d="M1.9209 71.5434C1.9209 46.682 15.315 23.651 36.8709 11.2564C58.4446 -1.14847 85.2032 -1.14847 106.777 11.2564C128.333 23.651 141.727 46.682 141.727 71.5434C141.727 96.4047 128.333 119.436 106.777 131.83C85.2032 144.235 58.4446 144.235 36.8709 131.83C15.315 119.436 1.9209 96.4047 1.9209 71.5434Z"
                        stroke="#04D9B5"
                        stroke-width="2"
                      />
                    </svg>

                    <div style={{ width: 234, height: 140 }}>
                      <Lottie
                        animationData={getImage(
                          currentQuestionInfo?.currQuestion?.type || ""
                        )}
                        loop={true}
                      />
                    </div>
                  </div>
                  <div className="assess-options-container-eng ">
                    <div className="head-cont">{headTxt}</div>
                    <div className="subTxt">{subTxt}</div>
                  </div>
                </div>
              </div>
              <div className="btn-cntr">
                <div onClick={onContinueClick} className={"btn-tmp-cnt"}>
                  {parseInt(number) === 100 ? "Continue" : "Continue"} &#8594;
                </div>
                {parseInt(number) !== 100 && (
                  <div className="btn-support" onClick={onFinishLaterClick}>
                    Take a break and finish later
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AssessmentEncouragement;

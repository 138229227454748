import React, { useState, useEffect } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ReactComponent as SubmitArrow } from "../../Assets/icon/forward-submit-arrow.svg";

function CircularProgressBarProfile({ value, disabled }) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Check screen width and update state
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 480);
    };

    // Initial check
    handleResize();

    // Add event listener to handle window resizing
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        width: "148px",
        height: "149px",
      }}
    >
      <CircularProgressbarWithChildren
        value={value}
        strokeWidth={4.5}
        className={`assess-footers ${disabled && "disabled-progress"}`}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor: "#BCF5A7", // Normal path color
          trailColor: isSmallScreen ? "rgba(39, 64, 121, 0.5)" :"rgba(223, 223, 223, 1)",
         
        })}
      >
        {/* Add children content here if needed */}
      </CircularProgressbarWithChildren>
    </div>
  );
}

export default CircularProgressBarProfile;

import React, { useState } from "react";
import "./style.css";

function TabSelection({ selectedValue, onTabChange }) {
  const categories = [
    { category: "Overview", id: 1 },
    { category: "Personality", id: 2 },
    { category: "Processing", id: 3 },
    { category: "Decision Making", id: 4 },
    { category: "Conflict management", id: 5 },
    // { category: "Motivation:Why", id: 6 },
    { category: "Fundamental needs", id: 7 },
  ];

  const tabSelection = (activeCategoryId) => {
    onTabChange(activeCategoryId);
  };
  return (
    <div className="potraits-tab-section">
      {categories?.map((category) => (
        <div
          id={category.id}
          key={category.id}
          className={
            "contact-relationship-modal-category-title potrait-tab-selection-txt" +
            (selectedValue === category.id
              ? " contact-relationship-modal-category-title-txt-active-category"
              : "")
          }
          onClick={() => tabSelection(category.id)}
        >
          {category.category}
        </div>
      ))}
    </div>
  );
}

export default TabSelection;

import React from "react";
import "./style.css";
function ChatOptions({ id, icon, txt, onClick, isActiveID }) {
  const cloneElement = React.cloneElement(icon, {
    color: isActiveID ? "black" : "white",
  });

  const splitText = (str) => {
    const words = str.trim().split(/\s+/); // Split string by whitespace into an array of words
    const midIndex = Math.ceil(words.length / 2); // Find the middle index of the words array

    // Split the words array into two parts
    const part1 = words.slice(0, midIndex).join(" "); // Join the first half of the words
    const part2 = words.slice(midIndex).join(" "); // Join the second half of the words

    return [part1, part2]; // Return the two parts
  };

  return (
    <div
      className={
        "chat-option-hero-cntr" +
        (isActiveID
          ? id > 3
            ? " active-chat-option-2"
            : " active-chat-option"
          : "" + (id > 3 ? " chat-option-txt-2" : ""))
      }
      onClick={() => onClick(id)}
    >
      <div className="chat-option-hero-cntr-centr">
        <div style={{ marginTop: "2px" }}>{cloneElement}</div>

        <div
          className={
            "chat-option-txt" + (isActiveID ? " active-chat-option-txt" : "")
          }
        >
          <div>
            {splitText(txt).map((str) => (
              <div>{str}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatOptions;

import React, { useState } from "react";
import "./style.css";

function TabSelectionFeeedback({ selectedValue, onTabChange }) {
  const [activeCategory, setactiveCategory] = useState(0);
  const categories = [
    { category: "Privacy", id: 1 },
    { category: "Cultural Sensitivity", id: 2 },
    { category: "Empathy", id: 3 },
    { category: "Feedback Loop", id: 4 },
    { category: "Interactivity", id: 5 },
    { category: "Personalization", id: 6 },
  ];

  const tabSelection = (activeCategoryId) => {
    setactiveCategory(activeCategoryId);
  };
  return (
    <div className="contact-relationship-modal-category-cntr potraits-tab-section">
      {categories?.map((category) => (
        <div
          id={category.id}
          key={category.id}
          className={
            "contact-relationship-modal-category-title potrait-tab-selection-txt bg-fb" +
            (activeCategory === category.id
              ? " contact-relationship-modal-category-title-txt-active-category bg-fb-active "
              : "")
          }
          onClick={() => tabSelection(category.id)}
        >
          {category.category}
        </div>
      ))}
    </div>
  );
}

export default TabSelectionFeeedback;

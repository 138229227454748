import React, { useState, useEffect } from "react";
import "./style.css";
import { ReactComponent as Settings } from "../../Assets/icon/settings.svg";
import { ReactComponent as Right } from "../../Assets/icon/chevron-right.svg";
import arrowLeft from "../../Assets/icon/arrow-narrow-left.svg";
import SettingsContainer from "../SettingsContainer/SettingsContainer";
import {
  getNotification,
  markAsReadNotification,
} from "../../Services/notificationAris";
import ContentLoader from "../../Pages/LoadingPage/ContentLoader";
import reminder_icon from "../../Assets/icon/notification_reminder.svg";
import portrait_icon from "../../Assets/icon/notification_portrait.svg";
import coach_icon from "../../Assets/icon/notification_coach.svg";
function Notify({ notification,onRouted }) {
  const getNotificationIcon = (type) => {
    switch (type) {
      case "coach":
        return coach_icon;
      case "reminder":
        return reminder_icon;
      default:
        return portrait_icon;
    }
  };

  const getRelativeTime = (dateString) => {
    const now = new Date();
    const past = new Date(dateString);
    const diffInMillis = now - past;

    const minutes = Math.floor(diffInMillis / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} days ago`;
    if (hours > 0) return `${hours} hours ago`;
    return `${minutes} mins ago`;
  };

  const handleClickToRoute = async (e) => {
    onRouted();
    await markAsReadNotification(notification?._id);
    switch (notification?.notification_subtype) {
      case "relationship_request":
        const queryParams = new URLSearchParams();
        const optionalParam1 = "true";
        queryParams.append("isPendingRequest", optionalParam1);
        window.location.href = `/manageRelationship?${queryParams.toString()}`;
        break;
      case "portrait_created":
        window.location.href = "/portraits";

      default:
        break;
    }
  };

  return (
    <div className="notify-container-hero" onClick={handleClickToRoute}>
      <div className="notify-left">
        <img
          src={getNotificationIcon(notification.notification_type)}
          alt="icon"
        />
      </div>
      <div className="notify-mid">
        <div className="notify-cnt-time">
          {notification.notification_type === "invite" &&
            getRelativeTime(notification.created_at)}
        </div>
        <div className="notify-cnt-head">{notification.content.title}</div>
        <div className="notify-cnt-sub">{notification.content.template}</div>
        <div className="notify-cnt-message">
          {notification.content.additional_content.message}
        </div>
      </div>
      <div className="notify-right">
        <Right />
      </div>
    </div>
  );
}

function NotificationContainer({ showNotification, setshowNotification ,onRouted}) {
  const [showSettings, setShowSettings] = useState(false);
  const [readNotifications, setReadNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState("unread");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchNotifications = async (page) => {
    setLoading(true);
    try {
      const response = await getNotification({
        is_read: activeTab === "read",
        page: page,
      });

      if (response && response.content && response.content.length > 0) {
        if (activeTab === "unread") {
          setUnreadNotifications((prev) => [...prev, ...response.content]);
        } else {
          setReadNotifications((prev) => [...prev, ...response.content]);
        }

        setHasMore(page < response.max_page_count);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
      setCurrentPage((prev) => prev + 1);
      fetchNotifications(currentPage + 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    setHasMore(true);
    setUnreadNotifications([]);
    setReadNotifications([]);
    fetchNotifications(1);
  }, [activeTab]);

  const renderNotifications = () => {
    const notifications =
      activeTab === "unread" ? unreadNotifications : readNotifications;
    if (!notifications?.length && !loading) {
      return (
        <div className="no-notifications">
          No notifications for now. You’re all set!
        </div>
      );
    }
    return notifications.map((notification) => (
      <Notify key={notification._id} notification={notification} onRouted={onRouted} />
    ));
  };

  const handleSettingsClick = () => {
    setShowSettings(true);
  };

  const handleBackClick = () => {
    setshowNotification(false);
  };

  return (
    <>
      {!showSettings ? (
        <>
          <div></div>
          <div className="notification-hero-container">
            <div className="head-notification">
              <div className="head-not-txt">Notification</div>
              <div onClick={handleSettingsClick}>
                <Settings />
              </div>
            </div>
            <div className="not-tab-selection-container">
              <div
                className={`not-tab-container${
                  activeTab === "unread" ? "" : "-non-active"
                }`}
                onClick={() => setActiveTab("unread")}
              >
                Unread
              </div>
              <div
                className={`not-tab-container${
                  activeTab === "read" ? "" : "-non-active"
                }`}
                onClick={() => setActiveTab("read")}
              >
                Read
              </div>
            </div>
            <div className="notify-cnt" onScroll={handleScroll}>
              {renderNotifications()}
              {loading && <ContentLoader />}
            </div>
          </div>

          <div className="notify-mobile">
            <div id="background-in">
              <div id="oval-in-1"></div>
              <div id="oval-in-2"></div>
            </div>
            <header className="termsofuse-header">
              {/* <div className="termsofuse-gradient-background"> */}
              <div className="termsofuse-nav-container">
                <div className="termsofuse-logo-container-notification">
                  <img
                    src={arrowLeft}
                    alt="Back"
                    className="termsofuse-back-button"
                    onClick={handleBackClick}
                  />

                  <div className="cur-pt" onClick={handleSettingsClick}>
                    <Settings />
                  </div>
                </div>
                <h1 className="head-not-txt">Notifications</h1>
                <div className="termsofuse-space"></div>
                <div className="set-phone-modal-support mobile-sub-txt-set-phone"></div>
              </div>
              {/* </div> */}
            </header>
            <div className="not-phone">
              <div className="not-tab-selection-container">
                <div
                  className={`not-tab-container${
                    activeTab === "unread" ? "" : "-non-active"
                  }`}
                  onClick={() => setActiveTab("unread")}
                >
                  Unread
                </div>
                <div
                  className={`not-tab-container${
                    activeTab === "read" ? "" : "-non-active"
                  }`}
                  onClick={() => setActiveTab("read")}
                >
                  Read
                </div>
              </div>
              <div className="notify-cnt" onScroll={handleScroll}>
                {renderNotifications()}
                {loading && (
                  <div className="loader-df-container">
                    <ContentLoader />
                  </div>
                )}{" "}
              </div>
            </div>
          </div>
        </>
      ) : (
        <SettingsContainer setShowSettings={setShowSettings} />
      )}
    </>
  );
}

export default NotificationContainer;

import React, { useState, useEffect } from "react";
import edit from "../../../Assets/images/edit.svg";
import tickIcon from "../../../Assets/icon/tick-icon.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function PhoneSettingsScreen1({ onEditClick, showContactSuccessMessage, currentPhone }) {

  return (
    <div className="set-phone-modal-container">
      <div>
        <div className="set-phone-modal-head">Phone Number</div>
        <div className="set-phone-modal-support">
          Your number is used to recover your account and receive SMS texts.page2
        </div>
      </div>
      <div className="set-phone-int-cnt">
        <div className="set-phone-modal-input-container">
          <div className="set-phone-input-label">Mobile number</div>
          <div className="mobile-input-wrapper-1">
            <PhoneInput
              international
              defaultCountry="US"
              value={currentPhone}
              disabled={true}
              className="mobile-input"
              style={{ cursor: "not-allowed" }}
            />
            <button className="edit-button" onClick={(e) => {
    e.stopPropagation();
    onEditClick();
  }}>
              <span role="img" aria-label="edit">
                <img src={edit} alt="edit" />
              </span>{" "}
              Edit
            </button>
          </div>
          {showContactSuccessMessage && (
            <div className="email-settings-success-box">
              <img src={tickIcon} alt="success" />
              Your number has been changed
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
            marginBottom: "20px",
          }}
        >
          {/* <img src={Trash} alt="trash" />{" "}
          <div className="set-phone-alert-txt">Remove phone number</div> */}
        </div>
      </div>
    </div>
  );
}

export default PhoneSettingsScreen1;

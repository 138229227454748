export const topics = [
  {
    category: "introScreen",
    duration: "30 sec",
    label: "Introduction",
  },
  {
    category: "Personality",
    duration: "1:05 min",
    label: "What's my personality archetype?",
  },
  {
    category: "Processing",
    duration: "1:05 min",
    label: "How do I process information?",
  },
  {
    category: "DecisionMaking",
    duration: "1:05 min",
    label: "How do I make decisions?",
  },
  {
    category: "ConflictManagement",
    duration: "1:05 min",
    label: "How do I approach conflict?",
  },
  // {
  //   category: "Motivation",
  //   duration: "1:05 min",
  //   label: "What motivates me?",
  // },
  {
    category: "FundamentalNeeds",
    duration: "35 sec",
    label: "What are my strongest needs?",
  },
];

export const visualMappings = {
  introScreen: [
    {
      page: 1,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },

      // apiInputs: ["values"],
    },
    {
      page: 2,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
    },
    {
      page: 3,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
    },
    {
      page: 4,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
    },
  ],
  Personality: [
    {
      page: 1,
      visualType: "animation",
      visualOrder: "1/2",
      component: "Personality16Graph",
      defaultInputs: {
        colorScheme: "#FF6347",
        animationSpeed: "2s",
      },
      //apiInputs: ["duration", "style"],
    },
    // {
    //   page: 1,
    //   visualType: "animation/image",
    //   visualOrder: "2/2",
    //   component: "PersonalityInfoGraph",
    //   defaultInputs: {
    //     colorScheme: "#FF6347",
    //     // animationSpeed: "2s",
    //   },
    //   apiInputs: ["basic"], // tbd
    // },

    {
      page: 2,
      visualType: "animation",
      component: "PersonalityInfoGraphPrimary",
      visualOrder: "1/1",
      defaultInputs: {
        colorScheme: "#FF6347",
        animationSpeed: "2s",
      },
      apiInputs: ["highlight", "values"], // tbd
    },
    {
      page: 3,
      visualType: "image",
      visualOrder: "1/1",
      component: "RadarChartManipulator",
      defaultInputs: {
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      // apiInputs: ["imageSource", "size"],
    },
    {
      page: 4,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 5,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordList",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      // apiInputs: ["values"],
    },
    {
      page: 6,
      visualType: "animation",
      visualOrder: "1/1",
      component: "RadarChartManipulator",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      apiInputs: ["type-1", "values"],
    },
    {
      page: 7,
      visualType: "animation",
      visualOrder: "1/1",
      component: "PracticeAreaGraph",
      defaultInputs: {
        colorScheme: "#82E3CC",
        // borderStyle: "solid",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 8,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordListBlind",
      defaultInputs: {
        // colorScheme: "#82E3CC",
        // borderStyle: "solid",
      },
      // apiInputs: ["title","values"],
    },
    {
      page: 9,
      visualType: "animation",
      visualOrder: "1/1",
      component: "PersonalityGraphNoBG",
      defaultInputs: {
        colorScheme: "#FF6347",
        animationSpeed: "2s",
      },
      apiInputs: ["graph", "values"], // tbd
    },
    {
      page: 10,
      visualType: "animation",
      component: "PersonalityInfoGraphUnderPressure",
      visualOrder: "1/1",
      defaultInputs: {
        colorScheme: "#FF6347",
        animationSpeed: "2s",
      },
      apiInputs: ["highlight", "title", "values"], // tbd
    },
    {
      page: 11,
      visualType: "animation",
      component: "RadarChartManipulator",
      visualOrder: "1/1",
      defaultInputs: {
        colorScheme: "#FF6347",
        animationSpeed: "2s",
      },
      apiInputs: ["highlight", "title", "values"], // tbd
    },
    {
      page: 12,
      visualType: "animation",
      component: "WordAnimator",
      visualOrder: "1/1",
      defaultInputs: {
        // colorScheme: "#FF6347",
        // animationSpeed: "2s",
      },
      apiInputs: ["values"],
    },
    {
      page: 13,
      visualType: "animation",
      component: "CircularLoader",
      visualOrder: "1/1",
      isLast: true,
      defaultInputs: {
        // colorScheme: "#FF6347",
        // animationSpeed: "2s",
      },
      apiInputs: ["dead"],
    },
  ],
  Processing: [
    {
      page: 1,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      // apiInputs: ["values"],
    },
    {
      page: 2,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordList",
      defaultInputs: {
        colorScheme: "#FFFFFF",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 3,
      visualType: "animation",
      visualOrder: "1/1",
      component: "ProcessingGraph",
      defaultInputs: {
        colorScheme: "#FFFFFF",
        animationSpeed: "2s",
      },
      apiInputs: ["type-vertical", "title", "values"],
    },
    {
      page: 4,
      visualType: "animation",
      visualOrder: "1/1",
      component: "PracticeAreaGraph",
      defaultInputs: {
        colorScheme: "#FFFFFF",
        animationSpeed: "2s",
      },
      apiInputs: ["type-2", "title", "values"],
    },
  ],
  DecisionMaking: [
    {
      page: 1,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      // apiInputs: ["values"],
    },
    {
      page: 2,
      visualType: "animation",
      component: "WordAnimator",
      visualOrder: "1/1",
      defaultInputs: {
        // colorScheme: "#FF6347",
        // animationSpeed: "2s",
      },
      apiInputs: ["values"],
    },
    {
      page: 3,
      visualType: "animation",
      component: "DecisionInfoGraph",
      visualOrder: "1/1",
      defaultInputs: {
        // colorScheme: "#FF6347",
        // animationSpeed: "2s",
      },
      apiInputs: ["values"],
    },

    {
      page: 4,
      visualType: "animation",
      component: "CircularProgressBar",
      visualOrder: "1/1",
      defaultInputs: {
        colorScheme:
          "#80D1E8-#82E3CC, #FF692E-#F9D423-#FFC700, #A8EE93-#FCB0A8",
        // animationSpeed: "2s",
      },
      apiInputs: ["values"],
    },
    {
      page: 5,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordList",
      defaultInputs: {
        colorScheme: "#04D9B5",
        // borderStyle: "solid",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 6,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      // apiInputs: ["values"],
    },
    {
      page: 7,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordListBlind",
      defaultInputs: {
        colorScheme: "#82E3CC",
        // borderStyle: "solid",
      },
      apiInputs: ["title", "values"],
    },
  ],
  ConflictManagement: [
    {
      page: 1,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      // apiInputs: ["values"],
    },
    {
      page: 2,
      visualType: "animation",
      visualOrder: "1/1",
      component: "ConflictGraph",
      defaultInputs: {
        animationSpeed: "2s",
        colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      apiInputs: ["values"],
    },
    {
      page: 3,
      visualType: "animation",
      visualOrder: "1/1",
      component: "ConflictGraph",
      defaultInputs: {
        animationSpeed: "2s",
        colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      apiInputs: ["values"],
    },
    {
      page: 4,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordList",
      defaultInputs: {
        colorScheme: "#04D9B5",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 5,
      visualType: "animation",
      visualOrder: "1/1",
      component: "PracticeAreaGraph",
      defaultInputs: {
        // colorScheme: "#FFFFFF",
      },
      apiInputs: ["type-4", "title", "values"],
    },
    {
      page: 6,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordListBlind",
      defaultInputs: {
        colorScheme: "#04D9B5",
      },
      apiInputs: ["title", "values"],
    },
  ],
  Motivation: [
    {
      page: 1,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      // apiInputs: ["values"],
    },
    {
      page: 2,
      visualType: "animation",
      visualOrder: "1/1",
      component: "MotivationGraph",
      defaultInputs: {
        colorScheme: "#FFFFFF",
        animationSpeed: "2s",
      },
      apiInputs: ["type-horizontal", "title", "values"],
    },
    {
      page: 3,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordList",
      defaultInputs: {
        colorScheme: "#04D9B5",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 4,
      visualType: "animation",
      visualOrder: "1/1",
      component: "PracticeAreaGraph",
      defaultInputs: {
        // colorScheme: "#FFFFFF",
      },
      apiInputs: ["type-3", "title", "values"],
    },
    {
      page: 5,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordListBlind",
      defaultInputs: {
        colorScheme: "#04D9B5",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 6,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      // apiInputs: ["values"],
    },

    {
      page: 7,
      visualType: "animation",
      visualOrder: "1/1",
      component: "MotivationGraphHow",
      defaultInputs: {
        // colorScheme: "#FFFFFF",
      },
      apiInputs: ["type-1", "title", "values"],
    },
    {
      page: 8,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordList",
      defaultInputs: {
        colorScheme: "#04D9B5",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 9,
      visualType: "animation",
      visualOrder: "1/1",
      component: "PracticeAreaGraph",
      defaultInputs: {
        colorScheme: "#04D9B5",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 10,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordListBlind",
      defaultInputs: {
        colorScheme: "#04D9B5",
      },
      apiInputs: ["title", "values"],
    },
  ],
  FundamentalNeeds: [
    {
      page: 1,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      // apiInputs: ["values"],
    },
    {
      page: 2,
      visualType: "animation",
      visualOrder: "1/1",
      component: "PieChartGraph",
      defaultInputs: {
        animationSpeed: "2s",
        colorScheme: "#FFFFFF",
        // borderStyle: "solid",
      },
      apiInputs: ["values"],
    },
    {
      page: 3,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordList",
      defaultInputs: {
        colorScheme: "#04D9B5",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 4,
      visualType: "animation",
      visualOrder: "1/1",
      component: "WordListBlind",
      defaultInputs: {
        colorScheme: "#04D9B5",
      },
      apiInputs: ["title", "values"],
    },
    {
      page: 5,
      visualType: "animation",
      visualOrder: "1/1",
      component: "CircularLoader",
      defaultInputs: {
        animationSpeed: "2s",
        // colorScheme: "#FFFFFF",
      },
      // apiInputs: ["type-4","title","values"],
    },
  ],
};

import React, { useEffect, useState } from "react";
import "./style.css";
import SaveResumeProgressButton from "../../Components/SaveResumeProgressButton";
import progress from "../../Assets/images/progress.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { startPageQuestionDetails } from "../../Services/Assessment";
import { useHeader } from "../../Providers/HeaderContextProvider";
import { isSafari } from "react-device-detect";
import BlueSvgBackground from "../../Components/BlueSvgBackground";

function AssessmentSaveProgress() {
  const [searchParams] = useSearchParams();
  const { menu } = useParams();
  const { setHeaderContent } = useHeader();
  const categoryGroup = searchParams.get("categoryGroup");
  console.log(menu, "menu");
  // const type = "save";
  const [type, settype] = useState(
    menu === "survey"
      ? "save"
      : menu === "assessmentCompleted"
      ? "assessmentCompleted"
      : "assessment"
  );

  // useEffect(() => {
  //   startPageQuestionDetails().then((res) => {
  //     console.log(res, "bolds");
  //     settype(() => {
  //       if (res === "in_progress") settype("save");
  //       else if (res === "upcoming") settype("start");
  //       else settype("start");
  //     });
  //   });
  // }, []);

  const navigate = useNavigate();

  useEffect(() => {
    setHeaderContent(true);
  }, []);

  const onContinueClick = () => {
    if (type === "assessmentCompleted") {
      if (categoryGroup === "fourth") {
        navigate("/story");
      } else {
        navigate("/onboarding");
      }
    } else {
      if (menu === "survey") navigate("/survey");
      else navigate("/onboarding");
    }
    // menu === "survey" ? navigate("/survey") : navigate("/assessment");
  };

  console.log(isSafari, "isSafari");
  return (
    // <div className={"save-progress-hero-container"}>
    <div>
      <BlueSvgBackground>
        <div className="supressed-bg">
          <div className={"save-progress-content"}>
            <div>
              {/* <img
              src={progress}
              alt={"progress"}
              className={"save-progress-img"}
            /> */}{" "}
              <video
                autoPlay
                loop
                muted
                playsInline
                className="relationship-gif"
                src={
                  isSafari
                    ? "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/sphere-ios.mov?nocache=1"
                    : "https://aris-static-app.s3.us-east-1.amazonaws.com/ui-components/Sphere_Loader.webm"
                }
              />
            </div>
            <div className={"save-progress-txt"}>
              {type === "start"
                ? ""
                : type === "save"
                ? "Progress saved"
                : type === "assessment"
                ? "Progress saved"
                : type === "assessmentCompleted"
                ? "Congratulations!"
                : "Welcome back"}
            </div>
            <div className={"save-progress-support-txt"}>
              {type === "start"
                ? "This will take about 40 minutes, but you can do it in 4  parts, and finish it whenever you want."
                : type === "save"
                ? "Come back any time to finish taking your survey."
                : type === "assessment"
                ? "Come back any time to finish taking your assessment."
                : type === "assessmentCompleted"
                ? `You finished the ${
                    categoryGroup || "first"
                  } part of your Aris Portrait.`
                : "Ready to pick up where you left off?"}
            </div>

            {type === "assessmentCompleted" && (
              <div className={"save-progress-support-txt-2"}>
                Let me tell you about how you are wired.
              </div>
            )}
            <SaveResumeProgressButton
              txt={
                type === "start"
                  ? "Let's Go!"
                  : type === "save"
                  ? "Resume survey"
                  : type === "assessmentCompleted"
                  ? "Let's Go!"
                  : "Resume Aris Assessment"
              }
              onClickButton={onContinueClick}
            />

            {type !== "assessmentCompleted" && (
              <div
                className={"save-progress-mini-txt"}
                onClick={() => navigate("/about-aris")}
              >
                {type === "save"
                  ? "Tell me more about Prism"
                  : "Tell me more about Aris"}
              </div>
            )}
          </div>
        </div>
      </BlueSvgBackground>
    </div>
  );
}

export default AssessmentSaveProgress;

import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/icon/close.svg";
import rightIcon from "../../Assets/icon/chevron-right.svg";
import Allset from "../../Components/AllsetComponent";
import ConfirmationModal from "./ManageMembershipCancel";
import { ReactComponent as Point } from "../../Assets/icon/Ellipse 88.svg";
import { ReactComponent as Info } from "../../Assets/icon/Info.svg";
import "./style.css";
import CoachingModal from "../Pricing/CoachingModal";
import { useSearchParams } from "react-router-dom";

const AnnualMembershipList = ({
  isArisAnnualMembership,
  setisArisAnnualMembership,
  showdetails,
  metadata,
  formatDate,
  addon,
}) => {
    const [searchParams] = useSearchParams();
    const ispurchased = searchParams.get("ispurchased");
  const [isCancelModal, setIsCancelModal] = useState(false);
  // const [isAllSet, setIsAllSet] = useState(false);
  const [activeModal, setActiveModal] = useState("");

  const openCancelMembershipModal = () => {
    setisArisAnnualMembership(false);
    setIsCancelModal(true);
  };

  const closeCancelMembershipModal = () => {
    setIsCancelModal(false);
    setisArisAnnualMembership(true);
  };

  const handleCancelMembership = () => {
    // setIsAllSet(true);
    setIsCancelModal(false);
  };

  console.log(showdetails, "showdetails");
  return (
    <>
      {isArisAnnualMembership && (
        <div className="membershipmodal-overlay">
          <div className="membershipmodal-container">
            <div className="membershipmodal-header" style={{ margin: "0px" }}>
              <h2 className="membershipmodal-heading">
                Aris Annual Membership
              </h2>
              <button
                className="membershipclose-btn"
                onClick={() => setisArisAnnualMembership(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <div
              className="annualMembershipsubtxt"
              style={{ textAlign: "left", margin: "0px" }}
            >
              Your plan will renew on{" "}
              <span className="annualMembershipsubtxt-blue">{formatDate}</span>.
              Your next bill will be{" "}
              <span className="annualMembershipsubtxt-blue"> $240 </span>
            </div>
            <div className="manage-modal-content membership-payment plan-info-membership-container ">
              <div className="plan-info-membership-heading-container">
                <div className="plan-info-membership-heading">
                  {" "}
                  Your plan includes:
                </div>

                {/* <div className="membership-info-txt-container">
                  <div className="point-style">
                    <Point />
                  </div>
                  <div className="membership-info-txt">60 Coaching Minutes</div>
                  <div className="point-style">
                    <Info />
                  </div>
                </div>
                <div className="membership-info-txt-container">
                  <div className="point-style">
                    <Point />
                  </div>
                  <div className="membership-info-txt">
                    Unlock Deep Self-Knowledge
                  </div>
                  <div className="point-style">
                    <Info />
                  </div>
                </div>
                <div className="membership-info-txt-container">
                  <div className="point-style">
                    <Point />
                  </div>
                  <div className="membership-info-txt">
                    24/7 AI Coaching for Better Relationships
                  </div>
                  <div className="point-style">
                    <Info />
                  </div>
                </div>
                <div className="membership-info-txt-container">
                  <div className="point-style">
                    <Point />
                  </div>
                  <div className="membership-info-txt">
                    Premium AI Voice Coaching Sessions
                  </div>
                  <div className="point-style">
                    <Info />
                  </div>
                </div>
                <div className="membership-info-txt-container">
                  <div className="point-style">
                    <Point />
                  </div>
                  <div className="membership-info-txt">
                    Science-Driven Relationship Insights
                  </div>
                  <div className="point-style">
                    <Info />
                  </div>
                </div>
                <div className="membership-info-txt-container">
                  <div className="point-style">
                    <Point />
                  </div>
                  <div className="membership-info-txt">
                    Mindset, Well-Being, and Career Coaching
                  </div>
                  <div className="point-style">
                    <Info />
                  </div>
                </div> */}
                <div className="pricing-life-coach-plans-container">
                  {showdetails?.map((topic, index) => {
                    const parsedData = JSON.parse(topic.name);
                    console.log(topic, index, "showdetails");
                    return (
                      <>
                        <div
                          key={index}
                          className="pricing-life-coach-plan-points"
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveModal(parsedData.key);
                          }}
                        >
                          <Point className="pricing-point" />
                          {parsedData?.title}
                          <Info />
                        </div>
                        <CoachingModal
                          // key={topic.id}
                          isOpen={activeModal === parsedData.key}
                          onClose={() => setActiveModal(null)}
                          title={parsedData?.title}
                          {...JSON.parse(metadata[parsedData.key])}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
              {addon?.length > 0 && (
                <div>
                  <div className="plan-info-membership-heading-container">
                    <div className="plan-info-membership-heading">
                      Your additional purchases:
                    </div>

                    <div className="pricing-life-coach-plans-container">
                      {addon.map((topic, index) => (
                        <div
                          key={index}
                          className="pricing-life-coach-plan-points"
                        >
                          <Point className="pricing-point" />
                          {topic?.product_name} x {topic?.quantity}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <ConfirmationModal
        isOpen={isCancelModal}
        onClose={closeCancelMembershipModal}
        onConfirm={handleCancelMembership}
        heading="Are you sure you want to cancel membership"
        subheading="You won't be able to get advise from Aris anymore"
        confirmButtonText="Yes, Cancel"
        cancelButtonText="Back"
      />

      {/* {isAllSet && (
        <Allset
          closeModal={() => {
            setIsAllSet(false);
            setisArisAnnualMembership(false);
          }}
          heading={ispurchased==="confirm" ?`You're all set`:"Oops!"}
          subText={ispurchased==="confirm" ?`Minutes purchased succesfully`:"Something went wrong!"}
          isFailure={ispurchased === "confirm" ? false : true}
        />
      )} */}
    </>
  );
};

export default AnnualMembershipList;

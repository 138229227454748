import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, Search, X } from "lucide-react";
import { getFaithPackages, getProducts } from "../../Services/subscription";
import "./style.css";

const SearchableDropdown = ({
  onBoarding = false,
  setSelectedFaith,
  isFullWidth,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPath, setSelectedPath] = useState([]);
  const [finalSelectedValue, setFinalSelectedValue] = useState("");
  const [expandedItems, setExpandedItems] = useState({});
  const dropdownRef = useRef(null);
  const [religiousData, setReligiousData] = useState({});

  useEffect(() => {
    fetchFaithData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // This effect auto-expands parent items when searching
  useEffect(() => {
    if (searchTerm) {
      // Auto-expand paths that might contain search results
      const newExpandedItems = { ...expandedItems };
      const expandPaths = (obj, currentPath = []) => {
        if (!obj || typeof obj !== "object") return;

        Object.entries(obj).forEach(([key, value]) => {
          const path = [...currentPath, key];
          const pathString = path.join(".");

          // Check if this item or any children match the search term
          if (key.toLowerCase().includes(searchTerm.toLowerCase())) {
            // Expand all parent paths
            for (let i = 0; i < currentPath.length; i++) {
              const parentPath = currentPath.slice(0, i + 1).join(".");
              if (parentPath) newExpandedItems[parentPath] = true;
            }
          }

          // Recursively check children
          if (value && typeof value === "object") {
            expandPaths(value, path);
          }
        });
      };

      expandPaths(religiousData);
      setExpandedItems(newExpandedItems);
    }
  }, [searchTerm, religiousData]);

  const toggleExpand = (path) => {
    setExpandedItems((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const findFinalLeafValue = (obj) => {
    if (!obj || typeof obj !== "object") return null;

    // Recursive function to find the deepest leaf
    const traverse = (currentObj) => {
      if (!currentObj || typeof currentObj !== "object") return null;

      const entries = Object.entries(currentObj);

      // If no entries, return null
      if (entries.length === 0) return null;

      // Find the deepest leaf
      for (const [key, value] of entries) {
        // If value is a primitive or empty object, return it
        if (
          !value ||
          typeof value !== "object" ||
          Object.keys(value).length === 0
        ) {
          return key;
        }

        // Recursively search nested objects
        const deepLeaf = traverse(value);
        if (deepLeaf) return deepLeaf;
      }

      return null;
    };

    return traverse(obj);
  };

  const processDataForRendering = (data, term) => {
    if (!term) return data;

    const filterRecursively = (obj) => {
      const filteredObj = {};

      Object.entries(obj).forEach(([key, value]) => {
        // If this key matches the search term
        if (key.toLowerCase().includes(term.toLowerCase())) {
          // Include this item and all its children
          filteredObj[key] = value;
        }
        // If it's an object, recursively filter
        else if (typeof value === "object" && value !== null) {
          const subFiltered = filterRecursively(value);
          if (Object.keys(subFiltered).length > 0) {
            filteredObj[key] = subFiltered;
          }
        }
      });

      return filteredObj;
    };

    return filterRecursively(data);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);

    // If we're searching, show the dropdown
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  // Clear the selected value
  const handleClearSelection = (e) => {
    e.stopPropagation(); // Prevent dropdown from opening when clicking the clear button
    setFinalSelectedValue("");
    setSelectedPath([]);
    setSearchTerm("");

    if (onBoarding && setSelectedFaith) {
      setSelectedFaith(""); // Clear the selected faith in parent component
    }
  };

  const fetchFaithData = async () => {
    try {
      const response = await getFaithPackages();
      setReligiousData(response.payload || {});
    } catch (error) {
      console.error("Error fetching faith data:", error);
    }
  };

  // Helper function to check if an item or its children match the search term
  const itemMatchesSearch = (key, value, term) => {
    if (!term) return false;

    // Check if the current key matches
    if (key.toLowerCase().includes(term.toLowerCase())) {
      return true;
    }

    // Check if any child keys match
    if (value && typeof value === "object") {
      return Object.entries(value).some(([childKey, childValue]) =>
        itemMatchesSearch(childKey, childValue, term)
      );
    }

    return false;
  };

  const renderItems = (items, level = 0, parentPath = []) => {
    return Object.entries(items).map(([key, value]) => {
      const currentPath = [...parentPath, key];
      const pathString = currentPath.join(".");

      const hasChildren =
        value && typeof value === "object" && Object.keys(value).length > 0;

      // Check if this item should be expanded
      // - Either it's already expanded manually
      // - Or we're searching and this item or its children match the search
      const isExpanded =
        expandedItems[pathString] ||
        (searchTerm && itemMatchesSearch(key, value, searchTerm));

      const finalLeaf = findFinalLeafValue(value);

      // Highlight the item if it matches the search term
      const isMatch =
        searchTerm && key.toLowerCase().includes(searchTerm.toLowerCase());

      return (
        <div
          key={pathString}
          className="dropdown-item"
          style={{ paddingLeft: `${level * 16}px` }}
        >
          <div
            className={`item-content ${
              selectedPath.join(".") === pathString ? "selected" : ""
            } ${isMatch ? "search-match" : ""}`}
            onClick={() => {
              // If has children
              if (hasChildren) {
                // If it has a final leaf value
                if (finalLeaf) {
                  // Select the current key as an intermediate step
                  setSelectedPath(currentPath);
                  // Expand the dropdown
                  toggleExpand(pathString);
                } else {
                  // Just expand if no final leaf
                  toggleExpand(pathString);
                }
              } else {
                // Leaf node or final selectable item
                setSelectedPath(currentPath);
                setFinalSelectedValue(finalLeaf || key);

                if (onBoarding && setSelectedFaith) {
                  setSelectedFaith(finalLeaf || key);
                }

                setIsOpen(false);
              }
            }}
          >
            <span>{key}</span>
            {hasChildren && (
              <ChevronDown
                className={`chevron ${isExpanded ? "chevexpanded" : ""}`}
              />
            )}
          </div>
          {hasChildren &&
            isExpanded &&
            renderItems(value, level + 1, currentPath)}
        </div>
      );
    });
  };

  return (
    <div
      className={`dropdown-container ${isFullWidth ? "isFullWidth" : ""}`}
      ref={dropdownRef}
    >
      <div
        className={`dropdown-header ${onBoarding ? "onboarding" : ""} ${
          isOpen ? "is-open" : ""
        }`}
        onClick={toggleDropdown}
      >
        <div className="header-search-wrapper">
          <input
            type="text"
            className="header-search-input"
            placeholder="Select your faith"
            value={finalSelectedValue || searchTerm}
            onChange={handleInputChange}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }}
          />
          {(finalSelectedValue || searchTerm) && (
            <X
              className="clear-selection-btn"
              onClick={handleClearSelection}
              size={18}
            />
          )}
        </div>
        <ChevronDown
          className={`chevron ${isOpen ? "chevexpanded" : ""}`}
          onClick={toggleDropdown}
        />
      </div>

      {isOpen && (
        <div className={`dropdown-menu ${onBoarding ? "onboarding" : ""}`}>
          <div className="dropdown-items">
            {renderItems(processDataForRendering(religiousData, searchTerm))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;

import React from "react";

const WordList = ({ words, currentWord }) => {
  console.log(words, currentWord, "words11222333333");

  return (
    <div className="word-map">
      {words?.map((word, index) => (
        <span
          key={index}
          className={`${
            word?.toLowerCase()?.includes(currentWord?.toLowerCase())
              ? ""
              : "reduced-opacity"
          }`}
        >
          {word}
        </span>
      ))}
    </div>
  );
};

export default WordList;

import React from "react";

function Personality2({ width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 106"
      fill="none"
    >
      <g clip-path="url(#clip0_2161_30137)">
        <path
          d="M18.5039 38.1221L84.4438 4.06934L77.281 96.9788L26.2987 62.0852L18.5039 38.1221Z"
          fill="#45D9BF"
        />
        <path
          d="M18.5039 38.1221L84.4438 4.06934M18.5039 38.1221L26.2987 62.0852M18.5039 38.1221L77.281 96.9788M84.4438 4.06934L77.281 96.9788M84.4438 4.06934L26.2987 62.0852M77.281 96.9788L26.2987 62.0852"
          stroke="white"
          stroke-opacity="0.7"
          stroke-width="0.211394"
          stroke-miterlimit="10"
        />
        <path
          d="M84.4813 4.01465L26.2949 62.0424L77.241 97.0282L84.4813 4.01465Z"
          fill="#31C5AB"
        />
        <g filter="url(#filter0_f_2161_30137)">
          <path
            d="M76.9619 84.0732L79.6737 92.3929H88.4491L81.3496 97.5347L84.0614 105.854L76.9619 100.712L69.8624 105.854L72.5741 97.5347L65.4746 92.3929H74.2501L76.9619 84.0732Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="1;0.5;1"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter1_f_2161_30137)">
          <path
            d="M36.4392 17.915L38.3356 23.7287H44.4722L39.5076 27.3218L41.4039 33.1354L36.4392 29.5424L31.4746 33.1354L33.3709 27.3218L28.4062 23.7287H34.5429L36.4392 17.915Z"
            fill="white"
            fill-opacity="0.4"
          >
            <animate
              attributeName="opacity"
              values="1;0.5;1"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter2_f_2161_30137)">
          <path
            d="M81.5734 58.502L83.1953 63.5082H88.4438L84.1977 66.6022L85.8195 71.6084L81.5734 68.5144L77.3274 71.6084L78.9492 66.6022L74.7031 63.5082H79.9516L81.5734 58.502Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="1;0.5;1"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter3_f_2161_30137)">
          <path
            d="M55.2027 29.4341L59.6316 43.0397H73.9639L62.3688 51.4484L66.7978 65.054L55.2027 56.6453L43.6076 65.054L48.0365 51.4484L36.4414 43.0397H50.7737L55.2027 29.4341Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="1;0.5;1"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter4_f_2161_30137)">
          <path
            d="M25.697 53.3232L27.4029 58.5479H32.9233L28.4572 61.7769L30.1631 67.0016L25.697 63.7726L21.2309 67.0016L22.9368 61.7769L18.4707 58.5479H23.9911L25.697 53.3232Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="1;0.5;1"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter5_f_2161_30137)">
          <path
            d="M78.7556 66.9585L80.4615 72.1832H85.9819L81.5158 75.4122L83.2217 80.6368L78.7556 77.4078L74.2895 80.6368L75.9954 75.4122L71.5293 72.1832H77.0497L78.7556 66.9585Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="1;0.5;1"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30137"
          x="55.1951"
          y="73.7937"
          width="43.5336"
          height="42.3403"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.13975"
            result="effect1_foregroundBlur_2161_30137"
          />
        </filter>
        <filter
          id="filter1_f_2161_30137"
          x="18.1325"
          y="7.64127"
          width="36.6139"
          height="35.7678"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.13689"
            result="effect1_foregroundBlur_2161_30137"
          />
        </filter>
        <filter
          id="filter2_f_2161_30137"
          x="61.8926"
          y="45.6914"
          width="39.3612"
          height="38.7275"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6.40525"
            result="effect1_foregroundBlur_2161_30137"
          />
        </filter>
        <filter
          id="filter3_f_2161_30137"
          x="17.1622"
          y="10.1549"
          width="76.0818"
          height="74.1785"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="9.63959"
            result="effect1_foregroundBlur_2161_30137"
          />
        </filter>
        <filter
          id="filter4_f_2161_30137"
          x="9.33846"
          y="44.191"
          width="32.7176"
          height="31.9427"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.56612"
            result="effect1_foregroundBlur_2161_30137"
          />
        </filter>
        <filter
          id="filter5_f_2161_30137"
          x="62.3971"
          y="57.8263"
          width="32.7176"
          height="31.9427"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.56612"
            result="effect1_foregroundBlur_2161_30137"
          />
        </filter>
        <clipPath id="clip0_2161_30137">
          <rect
            width="105.697"
            height="105.697"
            fill="white"
            transform="translate(0.609375 0.105469)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Personality2;

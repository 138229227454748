// src/components/TopicsListUI.js
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaPlay } from "react-icons/fa";
import { topics } from "./mappings.js";
import { useNavigate } from "react-router-dom"; // Use React Router for navigation
import "./style.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useHeader } from "../../Providers/HeaderContextProvider.jsx";
import ArisLogo from "../../Assets/images/logo.png";
import { fetchProgressData, fetchTopicData } from "../../Services/arisStory.js";

const StoryList = () => {
  const navigate = useNavigate();
  const { setHeaderContent } = useHeader();
  const [progressData, setprogressData] = useState({});

  useEffect(() => {
    setHeaderContent(false);
    fetchProgressData().then((res) => setprogressData(res.progress_counter));
  }, []);

  const handlePlay = (category) => {
    console.log(
      `Play clicked for: ${category}`,
      progressData[category]?.read_count
    );
    let storere =
      progressData[category]?.read_count <= 0
        ? 1
        : progressData[category]?.read_count;
    localStorage.setItem("selectedPageNo", storere);

    // Navigate to the topic screen and pass the selected topic info
    navigate(`/topic/${category}`);
  };

  function CircularImage({ category, progressData }) {
    const [progressToShow, setprogressToShow] = useState(0);
    useEffect(() => {
      setprogressToShow(roundedValue(category));
    }, [progressData]);
    // console.log("🟩 progress1111: ", progress);

    const roundedValue = (category) => {
      return (
        (progressData?.[category]?.read_count /
          progressData?.[category]?.total_count) *
        100
      );
    };
    return (
      <CircularProgressbarWithChildren
        value={progressToShow}
        strokeWidth={8}
        className={`header-progress`}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor: " #BCF5A7",
          textColor: "#f88",
          trailColor: "#274079",
          backgroundColor: "#3e98c7",
        })}
      >
        <div onClick={() => handlePlay(category.category)}>
          <FaPlay className="play-button" />
        </div>
      </CircularProgressbarWithChildren>
    );
  }

  return (
    <div className="story-container1">
      <div className="story-navbar">
        <div
          className="story-back-button"
          onClick={() => navigate("/dashboard")}
        >
          <FaArrowLeft />
        </div>
        <div className="story-navbar-logo">
          <img src={ArisLogo} alt="Aris Logo" width={40} height={40} />
        </div>
        <div className="title">Explore your Aris Story</div>
        <div></div>
      </div>
      <div className="topic-list">
        <div className="topic-header">Explore your Aris Story</div>
        {topics.map((topic, index) => (
          <div className="topic-item" key={topic.category}>
            <div
              className="play-button-container"
              onClick={() => handlePlay(topic.category)}
            >
              <CircularImage
                category={topic.category}
                progressData={progressData}
              />
            </div>
            <div className="story-topic">
              <div className="topic-label">{topic.label}</div>
              <div className="duration">{topic.duration}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StoryList;

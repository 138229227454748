import React, { useEffect, useState } from "react";
import { ReactComponent as UserPlus } from "../../Assets/images/UserPlus.svg";
import { ReactComponent as Plus } from "../../Assets/icon/Plus-big.svg";
import { fetchPendingRequests } from "../../Services/Realtionship";
import { useNavigate } from "react-router-dom";
import ContentLoader from "../../Pages/LoadingPage/ContentLoader"; // Assuming this is a valid loader component
import "./style.css";

const EmptyState = ({ onAddClick }) => {
  const [pendingRequests, setPendingRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Loader state
  const navigate = useNavigate(); // React Router's hook for navigation

  // Fetch pending requests
  useEffect(() => {
    const loadPendingRequests = async () => {
      try {
        const requests = await fetchPendingRequests();
        setPendingRequests(requests.length);
      } catch (error) {
        console.error("Failed to fetch pending requests", error);
      } finally {
        setIsLoading(false); // Stop loader after API call
      }
    };

    loadPendingRequests();
  }, []);

  // Handle navigation
  const routeToAddReq = () => {
    const queryParams = new URLSearchParams();
    queryParams.append("isPendingRequest", "true");
    window.location.href = `/manageRelationship?${queryParams.toString()}`;
  };

  return (
    <>
      {isLoading ? (
        <div className="emptystate-loader-container">
          <ContentLoader />
        </div>
      ) : (
        <>
          {pendingRequests > 0 ? (
            <div className="empty-pending-content">
              <div className="profile-container" style={{ marginTop: "0px", width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h2 className="emptystate-pendingrelationship">Pending Relationships</h2>
                  <p className="empty-pending-requests-para" onClick={routeToAddReq}>
                    {pendingRequests} Request(s)
                  </p>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "24px" }}>
                  <div className="circle-wrapper" > 
                    <div className="circle circle-1"></div>
                    <div className="circle circle-2"></div>
                    <div className="circle circle-3"></div>
                    <UserPlus className="profile-image" />
                  </div>
                  <h2 style={{ marginBottom: "16px" }}>Add a relationship</h2>
                  <p className="add-relationship-para">There is no one in your circle yet.</p>
                  <button className="add-person-btn" onClick={onAddClick}>
                    <Plus /> Add Person
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="content">
              <div className="profile-container" style={{ marginTop: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div className="circle-wrapper">
                    <div className="circle circle-1"></div>
                    <div className="circle circle-2"></div>
                    <div className="circle circle-3"></div>
                    <UserPlus className="profile-image" />
                  </div>
                  <h2 style={{ marginBottom: "16px" }}>Add a relationship</h2>
                  <p className="add-relationship-para">There is no one in your circle yet.</p>
                  <button className="add-person-btn" onClick={onAddClick}>
                    <Plus /> Add Person
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EmptyState;

import React from 'react'

function ResumeCoachingSVG() {
    return (
        <svg 
            width="21" 
            height="20" 
            viewBox="0 0 21 20" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M18.8609 16.5625C17.6711 14.5055 15.8375 13.0305 13.6977 12.3312C14.7561 11.7011 15.5785 10.741 16.0384 9.59827C16.4984 8.45554 16.5706 7.19343 16.2438 6.00574C15.917 4.81805 15.2094 3.77045 14.2297 3.02384C13.2499 2.27722 12.0521 1.87286 10.8203 1.87286C9.58849 1.87286 8.39072 2.27722 7.41095 3.02384C6.43119 3.77045 5.72359 4.81805 5.39683 6.00574C5.07006 7.19343 5.14221 8.45554 5.60217 9.59827C6.06214 10.741 6.8845 11.7011 7.94297 12.3312C5.80312 13.0297 3.96953 14.5047 2.77969 16.5625C2.73605 16.6336 2.70711 16.7128 2.69457 16.7953C2.68202 16.8778 2.68614 16.962 2.70666 17.0429C2.72718 17.1238 2.7637 17.1998 2.81405 17.2663C2.86441 17.3329 2.92759 17.3887 2.99986 17.4304C3.07213 17.4722 3.15203 17.499 3.23485 17.5094C3.31766 17.5198 3.40172 17.5134 3.48205 17.4908C3.56238 17.4682 3.63736 17.4296 3.70257 17.3776C3.76777 17.3255 3.82189 17.2608 3.86172 17.1875C5.33359 14.6437 7.93515 13.125 10.8203 13.125C13.7055 13.125 16.307 14.6437 17.7789 17.1875C17.8187 17.2608 17.8728 17.3255 17.9381 17.3776C18.0033 17.4296 18.0782 17.4682 18.1586 17.4908C18.2389 17.5134 18.323 17.5198 18.4058 17.5094C18.4886 17.499 18.5685 17.4722 18.6408 17.4304C18.713 17.3887 18.7762 17.3329 18.8266 17.2663C18.8769 17.1998 18.9134 17.1238 18.934 17.0429C18.9545 16.962 18.9586 16.8778 18.9461 16.7953C18.9335 16.7128 18.9046 16.6336 18.8609 16.5625ZM6.44531 7.49999C6.44531 6.6347 6.7019 5.78883 7.18263 5.06937C7.66336 4.3499 8.34664 3.78915 9.14607 3.45802C9.9455 3.12688 10.8252 3.04024 11.6738 3.20905C12.5225 3.37786 13.302 3.79454 13.9139 4.4064C14.5258 5.01825 14.9424 5.7978 15.1112 6.64647C15.2801 7.49514 15.1934 8.3748 14.8623 9.17423C14.5311 9.97366 13.9704 10.6569 13.2509 11.1377C12.5315 11.6184 11.6856 11.875 10.8203 11.875C9.66037 11.8737 8.54829 11.4124 7.72809 10.5922C6.90789 9.77201 6.44655 8.65993 6.44531 7.49999Z" 
                fill="white"
            />
        </svg>
    )
}

export default ResumeCoachingSVG
import React from "react";

function MindSetGuidanceSVG({
  color = "white",
  width = "1.6rem",
  height = "1.6rem",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.72086 20.52V18.19H11.5009C13.2609 18.19 14.6809 16.77 14.6809 15.01V14.33H16.8809L14.6809 10.47V8.23998C14.6809 6.35998 13.9209 4.67998 12.7009 3.45998C11.4709 2.23998 9.77086 1.47998 7.90086 1.47998C4.16086 1.47998 1.13086 4.50998 1.13086 8.24998V20.53"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6618 8.03016L12.4518 7.77016C12.4218 7.45016 12.3618 7.14016 12.2618 6.83016C12.1618 6.51016 12.0218 6.22016 11.8618 5.95016L11.0918 6.20016C10.5918 5.41016 9.8018 4.83016 8.8618 4.59016V3.77016C8.5518 3.70016 8.2318 3.66016 7.9118 3.66016C7.5918 3.66016 7.2618 3.70016 6.9518 3.77016V4.60016C6.0218 4.84016 5.2318 5.42016 4.7218 6.21016L3.9418 5.96016C3.7818 6.23016 3.6418 6.53016 3.5418 6.83016C3.4418 7.15016 3.3718 7.46016 3.3418 7.78016L4.1318 8.04016C4.1318 8.11016 4.1118 8.18016 4.1118 8.25016C4.1118 9.16016 4.4418 9.99016 4.9818 10.6402L4.4918 11.3102C4.7018 11.5502 4.9418 11.7702 5.2018 11.9502C5.4718 12.1502 5.7518 12.3002 6.0418 12.4302L6.5218 11.7702C6.9518 11.9402 7.4118 12.0402 7.9018 12.0402C8.3918 12.0402 8.8418 11.9402 9.2718 11.7802L9.7518 12.4402C10.0418 12.3202 10.3218 12.1602 10.5818 11.9702C10.8518 11.7702 11.0918 11.5602 11.3018 11.3202L10.8118 10.6502C11.3418 10.0002 11.6818 9.17016 11.6818 8.26016C11.6818 8.19016 11.6618 8.12016 11.6618 8.05016V8.03016ZM6.7018 8.25016C6.7018 7.59016 7.2318 7.05016 7.9118 7.05016C8.5918 7.05016 9.1118 7.58016 9.1118 8.25016C9.1118 8.92016 8.5818 9.46016 7.9118 9.46016C7.2418 9.46016 6.7018 8.93016 6.7018 8.25016Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default MindSetGuidanceSVG;

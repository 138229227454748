import React from "react";

function CareerAdviceSVG({
  color = "white",
  width = "1.6rem",
  height = "1.6rem",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9693 4.32977V11.1498C19.9693 12.8498 18.5993 14.2198 16.9193 14.2198H15.9493V16.7398L13.4293 14.2198H4.0793C2.3793 14.2198 1.0293 12.8498 1.0293 11.1498V4.32977C1.0293 2.62977 2.3793 1.25977 4.0793 1.25977H16.9193C18.5993 1.25977 19.9693 2.62977 19.9693 4.32977Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.7296 5.60986H7.26961C6.76151 5.60986 6.34961 6.02176 6.34961 6.52986V10.6499C6.34961 11.158 6.76151 11.5699 7.26961 11.5699H13.7296C14.2377 11.5699 14.6496 11.158 14.6496 10.6499V6.52986C14.6496 6.02176 14.2377 5.60986 13.7296 5.60986Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.58008 8.58984H11.4201"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16016 5.6098V3.7998H11.8402V5.6098"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CareerAdviceSVG;

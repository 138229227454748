// import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { axiosAssessmentInstance } from "../AxiosConfiguration";

export const getSurveyQuestions = async (first_render = false) => {
  try {
    const response = await axiosAssessmentInstance.get(
      `/api/survey/next?first_render=${first_render}`
    );
    if (response.data && response.data.status === "success") {
      return response.data.payload; // Return the payload from the response
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

export const submitSurveyAnswer = async (question_id, answer) => {
  try {
    const response = await axiosAssessmentInstance.post("/api/survey/answer", {
      question_id: question_id, // question_id
      answer: answer, // answer selected or written by user
    });
    if (response.data && response.data.status === "success") {
      return response.data.payload;
    } else {
      throw new Error(response.data.message || "Unexpected response format");
    }
  } catch (error) {
    console.error("Error fetching user profile summary:", error);
    throw error;
  }
};

import React, { memo, useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const GradientGraph = ({ data }) => {
  const svgRef = useRef();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous SVG content

    const width = screenWidth < 580 ? 320 : screenWidth > 1840 ? 700 : 600;
    const height = data.length * 100;

    // const rectHeight = height * 0.1;
    // const gap = 2;
    const cornerRadius = 6;
    const topMargin = 30;
    const rectHeight = width * 0.11; // 11% of the width

    const rectWidth = width * 0.08; // 8% of the width
    const gap = Math.max(2, width * 0.005); // Minimum gap of 2, 0.5% of width otherwise

    svg.attr("width", width).attr("height", height);

    // Create Gradients
    const defs = svg.append("defs");

    data.forEach((item, index) => {
      const {
        leftPercent,
        rightPercent,
        leftColor,
        rightColor,
        leftLabel,
        rightLabel,
      } = item;

      // Gradient for Left
      const gradientLeftId = `gradient-left-${index}`;
      defs
        .append("linearGradient")
        .attr("id", gradientLeftId)
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0%")
        .attr("y2", "0%")
        .selectAll("stop")
        .data(leftColor)
        .enter()
        .append("stop")
        .attr("offset", (d, i) => `${i * 100}%`)
        .attr("stop-color", (d) => d);

      // Gradient for Right
      const gradientRightId = `gradient-right-${index}`;
      defs
        .append("linearGradient")
        .attr("id", gradientRightId)
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0%")
        .attr("y2", "0%")
        .selectAll("stop")
        .data(rightColor)
        .enter()
        .append("stop")
        .attr("offset", (d, i) => `${i * 100}%`)
        .attr("stop-color", (d) => d);

      const y = index * 100 + topMargin;

      // Add Labels at the Top
      svg
        .append("text")
        .attr("x", 0)
        .attr("y", y - 10)
        .attr("text-anchor", "start")
        .attr("font-size", "1rem")
        .attr("font-family", "Urbanist")
        .attr("fill", "#F3F3F3")
        .text(leftLabel);

      svg
        .append("text")
        .attr("x", width - 40)
        .attr("y", y - 10)
        .attr("text-anchor", "end")
        .attr("font-size", "1rem")
        .attr("font-family", "Urbanist")
        .attr("fill", "#F3F3F3")
        .text(rightLabel);

      // Helper to calculate filled rectangles and partial width
      const calcRectFill = (percentage) => {
        const fullRects = Math.floor((percentage / 100) * 5); // Full rectangles
        const partialWidth = ((percentage / 100) * 5 - fullRects) * rectWidth; // Partial rectangle width
        return { fullRects, partialWidth };
      };

      const leftFill = calcRectFill(leftPercent);
      const rightFill = calcRectFill(rightPercent);

      // Animate Left Rectangles
      for (let i = 0; i < 5; i++) {
        // Background Rectangle
        svg
          .append("rect")
          .attr("x", (5 - i - 1) * (rectWidth + gap)) // Start from the rightmost
          .attr("y", y)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("rx", cornerRadius)
          .attr("ry", cornerRadius)
          .attr("fill", "#F3F3F3"); // Default background color

        if (i < leftFill.fullRects) {
          // Full Gradient Rectangle
          svg
            .append("rect")
            .attr("x", (5 - i - 1) * (rectWidth + gap) + rectWidth) // Start at the right edge
            .attr("y", y)
            .attr("width", 0) // Initial width is 0
            .attr("height", rectHeight)
            .attr("rx", cornerRadius)
            .attr("ry", cornerRadius)
            .attr("fill", `url(#${gradientLeftId})`)
            .transition()
            .delay(i * 200)
            .duration(400)
            .attr("x", (5 - i - 1) * (rectWidth + gap)) // Adjust x position to move leftward
            .attr("width", rectWidth); // Fill to full width
        } else if (i === leftFill.fullRects && leftFill.partialWidth > 0) {
          // Partial Gradient Rectangle
          svg
            .append("rect")
            .attr(
              "x",
              (5 - i - 1) * (rectWidth + gap) + rectWidth // Start at the right edge
            )
            .attr("y", y)
            .attr("width", 0) // Initial width is 0
            .attr("height", rectHeight)
            .attr("rx", cornerRadius)
            .attr("ry", cornerRadius)
            .attr("fill", `url(#${gradientLeftId})`)
            .transition()
            .delay(i * 200)
            .duration(400)
            .attr(
              "x",
              (5 - i - 1) * (rectWidth + gap) +
                (rectWidth - leftFill.partialWidth) // Move x leftward
            )
            .attr("width", leftFill.partialWidth); // Fill to partial width
        }
        if (i === 0) {
          svg
            .append("text")
            .attr("x", (5 - i - 1) * (rectWidth + gap) + rectWidth / 2)
            .attr("y", y + rectHeight / 2)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .attr("font-size", screenWidth < 580 ? ".8rem" : "1.1rem")
            .attr("font-family", "Urbanist")
            .attr("font-weight", "500")
            .attr("fill", "#000")
            .text(leftPercent + "%");
        }
      }

      // Animate Right Rectangles
      for (let i = 0; i < 5; i++) {
        // Background Rectangle
        svg
          .append("rect")
          .attr("x", width / 2 + i * (rectWidth + gap)) // Start from the leftmost
          .attr("y", y)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("rx", cornerRadius)
          .attr("ry", cornerRadius)
          .attr("fill", "#F3F3F3"); // Default background color

        if (i < rightFill.fullRects) {
          // Full Gradient Rectangle
          svg
            .append("rect")
            .attr("x", width / 2 + i * (rectWidth + gap))
            .attr("y", y)
            .attr("width", 0)
            .attr("height", rectHeight)
            .attr("rx", cornerRadius)
            .attr("ry", cornerRadius)
            .attr("fill", `url(#${gradientRightId})`)
            .transition()
            .delay(i * 200)
            .duration(400)
            .attr("width", rectWidth);
        } else if (i === rightFill.fullRects && rightFill.partialWidth > 0) {
          // Partial Gradient Rectangle
          svg
            .append("rect")
            .attr("x", width / 2 + i * (rectWidth + gap))
            .attr("y", y)
            .attr("width", 0)
            .attr("height", rectHeight)
            .attr("rx", cornerRadius)
            .attr("ry", cornerRadius)
            .attr("fill", `url(#${gradientRightId})`)
            .transition()
            .delay(i * 200)
            .duration(400)
            .attr("width", rightFill.partialWidth);
        }
        if (i === 0) {
          svg
            .append("text")
            .attr("x", width / 2 + i * (rectWidth + gap) + rectWidth / 2)
            .attr("y", y + rectHeight / 2)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .attr("font-size", screenWidth < 580 ? ".8rem" : "1.1rem")
            .attr("font-family", "Urbanist")
            .attr("fill", "#000")
            .attr("font-weight", "500") // Make the text bold

            .text(rightPercent + "%");
        }
      }
    });
  }, []);

  return <svg ref={svgRef} />;
};

export default memo(GradientGraph);

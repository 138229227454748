import React from "react";

function Personality5() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 107"
      fill="none"
    >
      <g clip-path="url(#clip0_2161_30161)">
        <path
          d="M10.3535 39.3331L101.787 14.8413L53.6218 83.257L22.8095 93.7463L10.3535 39.3331Z"
          fill="#3BCFB5"
        />
        <path
          d="M10.3535 39.3331L101.787 14.8413M10.3535 39.3331L22.8095 93.7463M10.3535 39.3331L53.6218 83.257M101.787 14.8413L53.6218 83.257M101.787 14.8413L22.8095 93.7463M53.6218 83.257L22.8095 93.7463"
          stroke="white"
          stroke-opacity="0.7"
          stroke-width="0.211394"
          stroke-miterlimit="10"
        />
        <path
          d="M54.0825 83.9994L101.479 14.4214L10.3535 39.3334L54.0825 83.9994Z"
          fill="#4FE3D3"
        />
        <g filter="url(#filter0_f_2161_30161)">
          <path
            d="M53.0326 59.7822L56.2715 69.7019H66.7527L58.2732 75.8327L61.5121 85.7524L53.0326 79.6217L44.5531 85.7524L47.792 75.8327L39.3125 69.7019H49.7937L53.0326 59.7822Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter1_f_2161_30161)">
          <path
            d="M30.3805 28.6025L33.1127 36.9597H41.9543L34.8013 42.1247L37.5335 50.4819L30.3805 45.3169L23.2275 50.4819L25.9597 42.1247L18.8066 36.9597H27.6483L30.3805 28.6025Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g opacity="0.6" filter="url(#filter2_f_2161_30161)">
          <path
            d="M53.3167 74.8979L55.0508 80.2272H60.6626L56.1226 83.5208L57.8567 88.85L53.3167 85.5564L48.7766 88.85L50.5108 83.5208L45.9707 80.2272H51.5825L53.3167 74.8979Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30161"
          x="21.4802"
          y="41.95"
          width="63.104"
          height="61.6348"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="8.91614"
            result="effect1_foregroundBlur_2161_30161"
          />
        </filter>
        <filter
          id="filter1_f_2161_30161"
          x="0.974367"
          y="10.7703"
          width="58.813"
          height="57.5439"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="8.91614"
            result="effect1_foregroundBlur_2161_30161"
          />
        </filter>
        <filter
          id="filter2_f_2161_30161"
          x="38.2855"
          y="67.2127"
          width="30.0619"
          height="29.3226"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30161"
          />
        </filter>
        <clipPath id="clip0_2161_30161">
          <rect
            width="105.697"
            height="105.697"
            fill="white"
            transform="translate(0.523438 0.802734)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Personality5;

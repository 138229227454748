import React, { useEffect, useState, useRef } from "react";
import arrowLeft from "../../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../Providers/HeaderContextProvider";
import arrowRight from "../../../Assets/images/arrow-right.svg";
import { checkOTP } from "../../../Services/settingsServices";
import { resendOTP } from "../../../Services/settingsServices";

import "./style.css";
function MobilePhoneSettingsScreen3() {
  const navigate = useNavigate();
  const { setHeaderContent } = useHeader();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  useEffect(() => {
    setHeaderContent(false);
  }, []);
  const length = 6;
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    // Allow digits and alphabets (both uppercase and lowercase)
    if (/^[A-Za-z0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if valid and the input is not empty
      if (value && index < length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData
      .getData("text")
      .split("")
      .slice(0, length);
    const newOtp = [...otp];

    pasteData.forEach((char, i) => {
      if (/^[A-Za-z0-9]$/.test(char)) {
        newOtp[i] = char;
      }
    });

    setOtp(newOtp);

    // Automatically focus the last field
    inputRefs.current[pasteData.length - 1]?.focus();
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      // Move focus to the previous input if the current input is empty
      inputRefs.current[index - 1].focus();
    }
  };

  const onVerifyOtpClick = async () => {
    setIsLoading(true);
    const countryCode = sessionStorage.getItem("newcountryCode");
    const contact = sessionStorage.getItem("newContact");
    try {
      const response = await checkOTP({
        contact: contact,
        country_code: countryCode,
        otp: parseInt(otp.join(""), 10),
      });
      if (response.status === "success") {
        navigate("/settings/phone/1");
        localStorage.setItem("isContactUpdated", "true");
        sessionStorage.removeItem("newContact");
        sessionStorage.removeItem("newcountryCode");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onResendOTP = async (e) => {
    e.preventDefault();
    setIsLoading1(true);
    const countryCode = sessionStorage.getItem("newcountryCode");
    const contact = sessionStorage.getItem("newContact");
    try {
      const response = await resendOTP({
        country_code: countryCode,
        contact: contact,
      });
      if (response.status === "success") {
        console.log("Email sent successfully");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {" "}
      <header className="termsofuse-header">
        <div className="termsofuse-gradient-background">
          <div className="termsofuse-nav-container">
            <div
              className="termsofuse-logo-container"
              onClick={() => navigate(-1)}
            >
              <img
                src={arrowLeft}
                alt="Back"
                className="termsofuse-back-button"
              />
            </div>
            <h1 className="termsofuse-title">Verify your mobile number</h1>
            <div className="termsofuse-space"></div>
            <div className="set-phone-modal-support mobile-sub-txt-set-phone">
              We sent a six-digit confirmation code to your number. It will
              expire in 30 minutes, enter it soon! Keep the window open while
              checking for your code.
            </div>
          </div>
        </div>
      </header>
      <div className="set-phone-int-cnt mt-mobile-view mobile-set-cnt">
        {/* <div className="set-phone-modal-input-container">
          <div className="set-phone-input-label">Mobile number</div>
          <div className="mobile-input-wrapper">
            <input
              type="text"
              id="mobile-number"
              className="mobile-input"
              placeholder="+1 (555) 324-3459"
            />
          </div>
        </div> */}
        <div className="otp-verifier">
          <div className="otp-container" onPaste={handlePaste}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
                className="otp-box"
              />
            ))}
          </div>
          <div className="otp-rote">
            <div className="otp-typo-cnt">
              <div className="otp-get-code">Didn’t get a code?</div>
              <div
                className="otp-resend"
                onClick={onResendOTP}
                disabled={isLoading1}
                style={{
                  cursor: isLoading1 ? "not-allowed" : "pointer",
                  opacity: isLoading1 ? "0.6" : "1",
                }}
              >
                Resend code
              </div>
            </div>
            <div
              className="otp-resend changepassword"
              onClick={() => navigate("/settings/phone/2")}
              style={{ cursor: "pointer" }}
            >
              Change phone number
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button
            className="set-phone-continue-button"
            onClick={onVerifyOtpClick}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? "0.6" : "1",
            }}
          >
            {" "}
            Continue <img src={arrowRight} alt="edit" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobilePhoneSettingsScreen3;

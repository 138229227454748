import React, { useEffect, useState } from "react";
import arrowLeft from "../../../Assets/icon/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../Providers/HeaderContextProvider";
import arrowRight from "../../../Assets/images/arrow-right.svg";
import edit from "../../../Assets/images/edit.svg";
import tickIcon from "../../../Assets/icon/tick-icon.svg";

import "./style.css";
function EmailSettingPhoneScreen1() {
  const navigate = useNavigate();
  const { setHeaderContent, profileContent } = useHeader();
  const [userEmail, setUserEmail] = useState(profileContent?.email);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    setHeaderContent(false);
    const urlParams = new URLSearchParams(window.location.search);
    const isEmailUpdated = urlParams.get("isEmailUpdated");
    if (isEmailUpdated === "true") {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        window.history.pushState({}, "", window.location.pathname);
      }, 3000);
    }
  }, []);

  const onEditClick = () => {
    navigate("/settings/email/2", { state: { currentEmail: userEmail } });
  };

  return (
    <div>
      {" "}
      <header className="termsofuse-header">
        <div className="termsofuse-gradient-background">
          <div className="termsofuse-nav-container">
            <div
              className="termsofuse-logo-container"
              onClick={() => navigate("/settings")}
            >
              <img
                src={arrowLeft}
                alt="Back"
                className="termsofuse-back-button"
              />
            </div>
            <h1 className="termsofuse-title">Email</h1>
            <div className="termsofuse-space"></div>
            <div className="set-phone-modal-support mobile-sub-txt-set-phone">
              Your email is used to recover your account.
            </div>
          </div>
        </div>
      </header>
      <div className="set-phone-int-cnt mobile-set-cnt ">
        <div className="set-phone-modal-input-container mt-mobile-view">
          <div className="set-phone-input-label">Email</div>
          <div className="mobile-input-wrapper">
            <input
              type="text"
              id="mobile-number"
              className="mobile-input"
              placeholder="mail@mail.com"
              value={userEmail}
              disabled={true}
              style={{ cursor: "not-allowed" }}
            />
            <button className="edit-button" onClick={onEditClick}>
              <span role="img" aria-label="edit">
                <img src={edit} alt="edit" />
              </span>{" "}
              Edit
            </button>
          </div>
        </div>
      </div>
      {showSuccessMessage && (
        <div className="success-message-box">
          <img src={tickIcon} alt="success" />
          Your email has been changed
        </div>
      )}
    </div>
  );
}

export default EmailSettingPhoneScreen1;

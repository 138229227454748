import React, { useState } from "react";
import arrowRight from "../../../Assets/images/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { sendUpdatedEmail } from "../../../Services/settingsServices";

function EmailSettingsScreen2({ onUpdateEmailClick, currentEmail }) {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const onUpdateEmail = async () => {
    if (isLoading) return; // Prevent multiple clicks
    setIsLoading(true);
    setErrorMessage(""); // Reset previous errors

    if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setErrorMessage("Invalid email format.");
      setIsLoading(false);
      return;
    }

    if (email === currentEmail) {
      setErrorMessage("New email cannot be the same as the current email.");
      setIsLoading(false);
      return;
    } else {
      try {
        const response = await sendUpdatedEmail({ email });
        if (response.status === "success") {
          setSuccessMessage("Email sent successfully!");
          sessionStorage.setItem("updatedEmail", email);
          setTimeout(() => {
            onUpdateEmailClick();
          }, 2000);
        } else {
          throw new Error(response.message || "Failed to update email.");
        }
      } catch (error) {
        setErrorMessage(error?.response?.data?.message || error?.message || "");
      } finally {
        setIsLoading(false); // Reset button state
      }
    }
  };

  return (
    <div className="set-phone-modal-container">
      <div>
        <div className="set-phone-modal-head">Enter new email</div>
        <div className="set-phone-modal-support">
          Giving us your email will allow you to access your Aris Portrait even
          if you lose your account.
        </div>
      </div>
      <div className="set-phone-int-cnt" style={{ marginBottom: "10px" }}>
        <div className="set-phone-modal-input-container">
          <div className="set-phone-input-label">New Email</div>
          <div
            className="mobile-input-wrapper"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="text"
              id="mobile-number"
              className="mobile-input"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button
            className="set-phone-continue-button"
            onClick={onUpdateEmail}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? "0.6" : "1",
            }}
          >
            Update email <img src={arrowRight} alt="edit" />
          </button>
        </div>
        {errorMessage && (
          <div
            style={{
              color: "red",
              textAlign: "center",
            }}
          >
            {errorMessage}
          </div>
        )}
        {successMessage && (
          <div
            style={{
              color: "green",
              textAlign: "center",
            }}
          >
            {successMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailSettingsScreen2;

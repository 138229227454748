import React, { useEffect, useState, useRef } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./style.css";
import { memo } from "react";

// Custom modifier to restrict dragging to vertical axis
const restrictToVerticalAxis = ({ transform }) => {
  return {
    ...transform,
    x: 0, // Lock horizontal movement
  };
};

// Custom modifier to restrict dragging to container bounds
const restrictToContainer = ({
  transform,
  draggingNodeRect,
  containerNodeRect,
}) => {
  if (!draggingNodeRect || !containerNodeRect) {
    return transform;
  }

  // Calculate the maximum allowed vertical movement
  const maxTop = containerNodeRect.top - draggingNodeRect.top;
  const maxBottom = containerNodeRect.bottom - draggingNodeRect.bottom;

  return {
    ...transform,
    y: Math.max(Math.min(transform.y, maxBottom), maxTop), // Restrict vertical movement
  };
};

const SortableItem = ({ id, index }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    margin: "5px 0",
    backgroundColor: "#F7F7F7",
    borderRadius: "5px",
    cursor: "grab", // Cursor style for desktop
    touchAction: "none", // Prevent default touch actions on mobile
    height: "25px",
    padding: "12px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    fontWeight: 500,
    justifyContent: "space-between",
    gap: "10px",
    userSelect: "none", // Prevent text selection during drag
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div style={{ marginRight: "2px" }}> {id}</div>
      <DragIndicatorIcon />
    </div>
  );
};

const DragAndDropList = ({ options, isMostLeast, onSubmit, answerId }) => {
  const [items, setItems] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    if (answerId) {
      if (isMostLeast) {
        const filtered = options.filter((item) => !answerId.includes(item.id));
        const rearrangedList = [
          options.find((item) => item.id === answerId[0]), // First element from the answer array
          ...filtered, // Other elements
          options.find((item) => item.id === answerId[1]), // Last element from the answer array
        ]
          .filter(Boolean)
          .map((item) => item?.text);

        setItems([...rearrangedList]);
      } else {
        const rearrangedList = answerId
          .map((answer) => options.find((option) => option.id === answer)?.text)
          .filter(Boolean);
        setItems(rearrangedList);
      }
    } else {
      const textValue = options
        ?.map((option) => {
          return option?.text;
        })
        .filter(Boolean);

      setItems(textValue || []);
    }
  }, [options, answerId, isMostLeast]);

  useEffect(() => {
    if (!items?.length || !options?.length) return;

    const returnIDs = items
      .map((item) => {
        return options.find((option) => option?.text === item)?.id;
      })
      .filter(Boolean);

    const mostLeastArr = [
      (returnIDs && returnIDs[0]) || "",
      (returnIDs && returnIDs[returnIDs.length - 1]) || "",
    ];

    onSubmit(isMostLeast ? mostLeastArr : returnIDs);
  }, [items, options, isMostLeast, onSubmit]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Custom modifier function that uses the containerRef
  const restrictToDndContainer = ({ transform, draggingNodeRect }) => {
    if (!containerRef.current || !draggingNodeRect) {
      return transform;
    }

    const containerRect = containerRef.current.getBoundingClientRect();

    // Calculate the maximum allowed vertical movement
    const maxTop = containerRect.top - draggingNodeRect.top;
    const maxBottom = containerRect.bottom - draggingNodeRect.bottom;

    return {
      ...transform,
      x: 0, // Lock horizontal movement
      y: Math.max(Math.min(transform.y, maxBottom), maxTop), // Restrict vertical movement
    };
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setItems((prevItems) => {
        const oldIndex = prevItems.indexOf(active.id);
        const newIndex = prevItems.indexOf(over.id);
        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }
  };

  function getOrdinalSuperscript(num) {
    if (typeof num !== "number" || !Number.isInteger(num)) {
      throw new Error("Input must be an integer.");
    }

    const lastDigit = num % 10;
    const lastTwoDigits = num % 100;

    let suffix = "th"; // Default suffix

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      suffix = "th"; // Special case for 11, 12, 13
    } else {
      switch (lastDigit) {
        case 1:
          suffix = "st";
          break;
        case 2:
          suffix = "nd";
          break;
        case 3:
          suffix = "rd";
          break;
        default:
          suffix = "th";
      }
    }

    return suffix;
  }

  if (!items?.length) return null;

  return (
    <div
      className="dnd-container"
      style={{ position: "relative", overflow: "hidden", width: "100%" }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToDndContainer]}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              width: isMostLeast ? "8%" : "2%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              padding: "0 20px",
            }}
          >
            {isMostLeast
              ? items?.map((id, index) => (
                  <div
                    key={`index-${index}`}
                    className={
                      "index-container no-bc" +
                      (index === 0 || index === options.length - 1
                        ? ""
                        : " no-bc")
                    }
                  >
                    <div
                      className={
                        "index-container-inner" +
                        (index === 0 || index === options.length - 1
                          ? ""
                          : " no-bc")
                      }
                    >
                      {index === 0
                        ? "Most"
                        : index === options.length - 1
                        ? "Least"
                        : ""}
                    </div>
                  </div>
                ))
              : items?.map((id, index) => (
                  <div key={`index-${index}`} className="index-container">
                    <p style={{ display: "flex" }}>
                      {index + 1} <sup>{getOrdinalSuperscript(index + 1)}</sup>
                    </p>
                  </div>
                ))}
          </div>
          <div
            id="parent-dnd-container"
            ref={containerRef}
            style={{
              width: "90%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              maxHeight: "300px",
              overflow: "auto",
            }}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items?.map((id, index) => (
                <SortableItem key={id} id={id} index={index} />
              ))}
            </SortableContext>
          </div>
        </div>
      </DndContext>
    </div>
  );
};

export default memo(DragAndDropList);

import React from "react";
import { Tooltip } from "react-tooltip";
import { Info } from "lucide-react";

const InfoTooltip = ({ index, content }) => {
  console.log(content, index, "::::::::content");

  return (
    <>
      <Info
        data-tooltip-id="my-tooltip"
        data-tooltip-content={content || ""}
        className="info-icon"
      />
      <Tooltip
        id="my-tooltip"
        style={{
          backgroundColor: "white",
          color: "#333",
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "8px 12px",
          maxWidth: "250px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      />
    </>
  );
};

export default InfoTooltip;

import React, { useState, useRef, useEffect, memo } from "react";
import { FaPlay } from "react-icons/fa";
import speaker from "../../Assets/icon/SpeakerHigh.svg";
import close from "../../Assets/icon/x-close-white.svg";
import pause from "../../Assets/icon/Pause.svg";
import "./style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

const AudioPlayer = ({
  audioSrc,
  onTextUpdate,
  timestamps,
  duration,
  audioCompleted,
  paused,
}) => {
  const [searchParams] = useSearchParams();

  const fromOnBoarding = searchParams.get("fromOnBoarding");
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);

  const audioRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (audioRef.current !== null || audioRef.current !== undefined) {
      audioRef.current.play().catch((error) => {
        console.error("Audio play error:", error);
      });
    }
  }, []);

  useEffect(() => {
    const handleTimeUpdate = () => {
      const currentTime = audioRef.current.currentTime;

      // Find the word matching the current time
      const currentWordObj = timestamps.find(
        ({ start, end }) => currentTime >= start && currentTime < end
      );

      if (currentWordObj) {
        onTextUpdate(currentWordObj.word);
      }
    };

    const audio = audioRef.current;
    audio.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [timestamps, onTextUpdate]);

  const togglePlayPause = () => {
    paused();
    if (!isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    setIsPlaying(!isPlaying);
  };

  const handleClose = () => {
    // audioRef.current.pause();
    // audioRef.current.currentTime = 0;
    setIsPlaying(false);
    // onTextUpdate(""); // Clear the current word
    if (fromOnBoarding) navigate(`/onboarding/chat`);
    else navigate(`/story`);
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      duration(audioRef.current.duration);
    }
  };

  const handleAudioEnd = () => {
    audioCompleted();
  };

  const handleMute = () => {
    if (isMuted) {
      if (audioRef.current) {
        audioRef.current.muted = false; // Unmute the audio
      }
      setIsMuted(false);
    } else {
      if (audioRef.current) {
        audioRef.current.muted = true; // Mute the audio
      }
      setIsMuted(true);
    }
  };

  console.log(fromOnBoarding, "fromOnBoarding");
  return (
    <div className="audio-player-container">
      <audio
        ref={audioRef}
        src={audioSrc}
        onLoadedMetadata={handleLoadedMetadata}
        autoPlay={true}
        onEnded={handleAudioEnd}
      />
      <div className="speaker-container" onClick={handleMute}>
        {isMuted ? (
          <VolumeOffIcon />
        ) : (
          <img src={speaker} alt="speaker" width={24} height={24} />
        )}
      </div>
      <div className="pause-container" onClick={togglePlayPause}>
        {!isPlaying ? (
          <FaPlay
            size={32}
            style={{ color: "#fff", cursor: "pointer", marginLeft: "2px" }}
          />
        ) : (
          <img src={pause} alt="pause" width={32} height={32} />
        )}
      </div>
      <div className="close-container" onClick={handleClose}>
        <img src={close} alt="close" width={24} height={24} />
      </div>
    </div>
  );
};

export default memo(AudioPlayer);

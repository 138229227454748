import React, { useState, useEffect } from "react";
import people from "../../../../Assets/images/people.png";
import { ReactComponent as Info } from "../../../../Assets/icon/Info-ref.svg";
import { ReactComponent as Backbutton } from "../../../../Assets/icon/backbutton.svg";
import ReactMarkdown from "react-markdown";
import InfoTooltip from "./InfoTooltip";

export function DecisionMaking({ data, onBack, profilePic1, profilePic2, initials1, initials2, name1, name2, tooltip, user1Metrics, user2Metrics }) {
  const isClose = (value1, value2) => Math.abs(value1 - value2) < 5;
  
  const [user1Position, setUser1Position] = useState(50);
  const [user2Position, setUser2Position] = useState(50);
  const [user1CarefulPosition, setUser1CarefulPosition] = useState(50);
  const [user2CarefulPosition, setUser2CarefulPosition] = useState(50);

  useEffect(() => {
    setTimeout(() => {
      if (user1Metrics) setUser1Position(100 - user1Metrics.Inward);
      if (user2Metrics) setUser2Position(100 - user2Metrics.Inward);
      if (user1Metrics) setUser1CarefulPosition(100 - user1Metrics.Careful);
      if (user2Metrics) setUser2CarefulPosition(100 - user2Metrics.Careful);
    }, 100);
  }, [user1Metrics, user2Metrics]);

  return (
    <div className="Processing-container">
      <div className="Processing-header">
        <button className="backbutton-insights" onClick={onBack}><Backbutton /> Back</button>
        <div className="Processing-txt">Decision Making</div>
        <div><InfoTooltip content={tooltip} /></div>
      </div>

      {[{ labelLeft: "Outward", labelRight: "Inward", metric: "Inward", user1Pos: user1Position, user2Pos: user2Position }, 
        { labelLeft: "Rapid", labelRight: "Careful", metric: "Careful", user1Pos: user1CarefulPosition, user2Pos: user2CarefulPosition }].map(({ labelLeft, labelRight, metric, user1Pos, user2Pos }) => {
        const areClose = user1Metrics && user2Metrics && isClose(user1Metrics[metric], user2Metrics[metric]);
        // Choose middle position for combined display
        const combinedPosition = areClose ? (user1Pos + user2Pos) / 2 : 0;
        
        return (
          <div className="insight-progress-container" key={metric}>
            <div className="insight-progress-info-container">
              <div>{labelLeft}</div>
              <div>{labelRight}</div>
            </div>
            <div className="insight-progress-line"></div>
            {[20, 40, 60, 80].map((pos) => (
              <div key={pos} className="insight-progress-line-inner" style={{ height: "10%", left: `${pos}%`, transform: "translateY(420%)" }}></div>
            ))}

            {/* Show combined display when metrics are close */}
            {areClose && user1Metrics && user2Metrics && (
              <div className="insight-person-container" style={{ left: `${combinedPosition}%`, transition: "left 0.8s ease-in-out" }}>
                <div className="insight-combined-avatars" style={{ display: "flex", gap: "4px" }}>
                  {profilePic1 ? (
                    <img src={profilePic1} alt={initials1 || "Profile"} style={{ width: "24px", height: "24px", fontSize: "10px" }}
                      onError={(e) => { e.target.style.display = "none"; e.target.nextElementSibling.style.display = "flex"; }}
                    />
                  ) : <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>{initials1 || ""}</div>}
                  
                  {profilePic2 ? (
                    <img src={profilePic2} alt={initials2 || "Profile"} style={{ width: "24px", height: "24px", fontSize: "10px" }}
                      onError={(e) => { e.target.style.display = "none"; e.target.nextElementSibling.style.display = "flex"; }}
                    />
                  ) : <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>{initials2 || ""}</div>}
                </div>
                <div className="combined-names">
                  {name1} & {name2}
                </div>
              </div>
            )}

            {/* Show individual profiles when metrics are NOT close */}
            {!areClose && user1Metrics && (
              <div className="insight-person-container" style={{ left: `${user1Pos}%`, transition: "left 0.8s ease-in-out" }}>
                {profilePic1 ? (
                  <img src={profilePic1} alt={initials1 || "Profile"} style={{ width: "24px", height: "24px", fontSize: "10px" }}
                    onError={(e) => { e.target.style.display = "none"; e.target.nextElementSibling.style.display = "flex"; }}
                  />
                ) : <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>{initials1 || ""}</div>}
                {name1}
              </div>
            )}
            {!areClose && user2Metrics && (
              <div className="insight-person-container" style={{ left: `${user2Pos}%`, transition: "left 0.8s ease-in-out" }}>
                {profilePic2 ? (
                  <img src={profilePic2} alt={initials2 || "Profile"} style={{ width: "24px", height: "24px", fontSize: "10px" }}
                    onError={(e) => { e.target.style.display = "none"; e.target.nextElementSibling.style.display = "flex"; }}
                  />
                ) : <div className="profile-initials" style={{ width: "24px", height: "24px", fontSize: "10px" }}>{initials2 || ""}</div>}
                {name2}
              </div>
            )}
          </div>
        );
      })}

      <div style={{ textAlign: "left" }}>
        <ReactMarkdown components={{ h1: "h3", h2: "h4" }}>
          {data?.replace(/\n/g, "\n\n") || ""}
        </ReactMarkdown>
      </div>
    </div>
  );
}
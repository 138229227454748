// // import React, { useEffect, useState, useRef } from "react";

// // import arrowRight from "../../../Assets/images/arrow-right.svg";
// // import { checkOTP } from "../../../Services/settingsServices";
// // import { resendOTP } from "../../../Services/settingsServices";

// // function PhoneSettingsScreen3({ onVerifyOTP }) {
// //   const [isLoading, setIsLoading] = useState(false);
// //   const [isLoading1, setIsLoading1] = useState(false);

// //   const length = 6;
// //   const [otp, setOtp] = useState(new Array(length).fill(""));
// //   const inputRefs = useRef([]);

// //   const handleChange = (value, index) => {
// //     // Allow digits and alphabets (both uppercase and lowercase)
// //     if (/^[A-Za-z0-9]$/.test(value) || value === "") {
// //       const newOtp = [...otp];
// //       newOtp[index] = value;
// //       setOtp(newOtp);

// //       // Move to the next input field if valid and the input is not empty
// //       if (value && index < length - 1) {
// //         inputRefs.current[index + 1].focus();
// //       }
// //     }
// //   };

// //   const handlePaste = (e) => {
// //     const pasteData = e.clipboardData
// //       .getData("text")
// //       .split("")
// //       .slice(0, length);
// //     const newOtp = [...otp];

// //     pasteData.forEach((char, i) => {
// //       if (/^[A-Za-z0-9]$/.test(char)) {
// //         newOtp[i] = char;
// //       }
// //     });

// //     setOtp(newOtp);

// //     inputRefs.current[pasteData.length - 1]?.focus();
// //   };

// //   const handleKeyDown = (e, index) => {
// //     if (e.key === "Backspace" && !otp[index] && index > 0) {
// //       inputRefs.current[index - 1].focus();
// //     }
// //   };

// //   const onVerifyOtpClick = async () => {
// //     setIsLoading(true);
// //     const countryCode = sessionStorage.getItem("newcountryCode");
// //     const contact = sessionStorage.getItem("newContact");
// //     try {
// //       const response = await checkOTP({
// //         contact: contact,
// //         country_code: countryCode,
// //         otp: parseInt(otp.join(""), 10),
// //       });
// //       if (response.status === "success") {
// //         onVerifyOTP();
// //         sessionStorage.removeItem("newcountryCode");
// //         sessionStorage.removeItem("newContact");
// //       }
// //     } catch (error) {
// //       console.error("Error:", error);
// //     }
// //   };

// //   const onResendOTP = async () => {
// //     setIsLoading1(true);
// //     const countryCode = sessionStorage.getItem("newcountryCode");
// //     const contact = sessionStorage.getItem("newContact");
// //     try {
// //       const response = await resendOTP({
// //         country_code: countryCode,
// //         contact: contact,
// //       });
// //       if (response.status === "success") {
// //         console.log("Email sent successfully");
// //         //  setIsLoading(false);
// //       }
// //     } catch (error) {
// //       console.error("Error:", error);
// //     }
// //   };

// //   return (
// //     <div className="set-phone-modal-container">
// //       <div>
// //         <div className="set-phone-modal-head">Verify your mobile number</div>
// //         <div className="set-phone-modal-support">
// //           We sent a six-digit confirmation code to your number. It will expire
// //           in 30 minutes, enter it soon! Keep the window open while checking for
// //           your code.
// //         </div>
// //       </div>
// //       <div className="set-phone-int-cnt">
// //         {/* <div className="set-phone-modal-input-container">
// //           <div className="set-phone-input-label">Mobile number</div>
// //           <div className="mobile-input-wrapper">
// //             <input
// //               type="text"
// //               id="mobile-number"
// //               className="mobile-input"
// //               placeholder="+1 (555) 324-3459"
// //             />
// //           </div>
// //         </div> */}
// //         <div className="otp-verifier">
// //           <div className="otp-container" onPaste={handlePaste}>
// //             {otp.map((digit, index) => (
// //               <input
// //                 key={index}
// //                 type="text"
// //                 maxLength={1}
// //                 value={digit}
// //                 onClick={(e) => e.stopPropagation()} // Prevents event propagation
// //                 onChange={(e) => handleChange(e.target.value, index)}
// //                 onKeyDown={(e) => handleKeyDown(e, index)}
// //                 ref={(el) => (inputRefs.current[index] = el)}
// //                 className="otp-box"
// //               />
// //             ))}
// //           </div>
// //           <div className="otp-rote">
// //             <div className="otp-typo-cnt">
// //               <div className="otp-get-code">Didn’t get a code?</div>
// //               <div
// //                 className="otp-resend"
// //                 onClick={(e) => {
// //                   e.stopPropagation(); // Prevent event propagation
// //                   onResendOTP();
// //                 }}
// //                 disabled={isLoading1}
// //                 style={{
// //                   cursor: isLoading1 ? "not-allowed" : "pointer",
// //                   opacity: isLoading1 ? "0.6" : "1",
// //                 }}
// //               >
// //                 Resend code
// //               </div>
// //             </div>
// //             <div className="otp-resend  ">Change phone number</div>
// //           </div>
// //         </div>

// //         <div
// //           style={{
// //             display: "flex",
// //             alignItems: "center",
// //             justifyContent: "center",
// //             marginBottom: "20px",
// //             marginTop: "20px",
// //           }}
// //         >
// //           <button
// //             className="set-phone-continue-button"
// //             onClick={(e) => {
// //               e.stopPropagation(); // Prevent event propagation
// //               onVerifyOtpClick();
// //             }}
// //             disabled={isLoading}
// //             style={{
// //               cursor: isLoading ? "not-allowed" : "pointer",
// //               opacity: isLoading ? "0.6" : "1",
// //             }}
// //           >
// //             {" "}
// //             Continue <img src={arrowRight} alt="edit" />
// //           </button>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// // export default PhoneSettingsScreen3;




// import React, { useEffect, useState, useRef } from "react";
// import arrowRight from "../../../Assets/images/arrow-right.svg";
// import { checkOTP } from "../../../Services/settingsServices";
// import { resendOTP } from "../../../Services/settingsServices";

// function PhoneSettingsScreen3({ onVerifyOTP }) {
//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoading1, setIsLoading1] = useState(false);
//   const [isButtonClicked, setIsButtonClicked] = useState(false); // Track if button clicked
//   const [errorMessage, setErrorMessage] = useState(""); // For error messages

//   const length = 6;
//   const [otp, setOtp] = useState(new Array(length).fill(""));
//   const inputRefs = useRef([]);

//   const handleChange = (value, index) => {
//     // Allow digits and alphabets (both uppercase and lowercase)
//     if (/^[A-Za-z0-9]$/.test(value) || value === "") {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);

//       // Move to the next input field if valid and the input is not empty
//       if (value && index < length - 1) {
//         inputRefs.current[index + 1].focus();
//       }
//     }
//   };

//   const handlePaste = (e) => {
//     const pasteData = e.clipboardData
//       .getData("text")
//       .split("")
//       .slice(0, length);
//     const newOtp = [...otp];

//     pasteData.forEach((char, i) => {
//       if (/^[A-Za-z0-9]$/.test(char)) {
//         newOtp[i] = char;
//       }
//     });

//     setOtp(newOtp);

//     inputRefs.current[pasteData.length - 1]?.focus();
//   };

//   const handleKeyDown = (e, index) => {
//     if (e.key === "Backspace" && !otp[index] && index > 0) {
//       inputRefs.current[index - 1].focus();
//     }
//   };

//   const onVerifyOtpClick = async () => {
//     if (isButtonClicked) return; // Prevent multiple clicks
//     setIsButtonClicked(true); // Mark button as clicked
//     setIsLoading(true);
//     const countryCode = sessionStorage.getItem("newcountryCode");
//     const contact = sessionStorage.getItem("newContact");

//     try {
//       const response = await checkOTP({
//         contact: contact,
//         country_code: countryCode,
//         otp: parseInt(otp.join(""), 10),
//       });

//       if (response.status === "success") {
//         onVerifyOTP();
//         sessionStorage.removeItem("newcountryCode");
//         sessionStorage.removeItem("newContact");
//       } else {
//         setErrorMessage("Invalid OTP.");
//       }
//     } catch (error) {
//       setErrorMessage("Error verifying OTP. Please try again.");
//       console.error("Error:", error);
//     } finally {
//       setIsLoading(false); // Reset loading state
//     }
//   };

//   const onResendOTP = async () => {
//     setIsLoading1(true);
//     const countryCode = sessionStorage.getItem("newcountryCode");
//     const contact = sessionStorage.getItem("newContact");
//     try {
//       const response = await resendOTP({
//         country_code: countryCode,
//         contact: contact,
//       });
//       if (response.status === "success") {
//         console.log("OTP sent successfully");
//         // Optionally show a success message here
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     } finally {
//       setIsLoading1(false);
//     }
//   };

//   return (
//     <div className="set-phone-modal-container">
//       <div>
//         <div className="set-phone-modal-head">Verify your mobile number</div>
//         <div className="set-phone-modal-support">
//           We sent a six-digit confirmation code to your number. It will expire
//           in 30 minutes, enter it soon! Keep the window open while checking for
//           your code.
//         </div>
//       </div>
//       <div className="set-phone-int-cnt">
//         <div className="otp-verifier">
//           <div className="otp-container" onPaste={handlePaste}>
//             {otp.map((digit, index) => (
//               <input
//                 key={index}
//                 type="text"
//                 maxLength={1}
//                 value={digit}
//                 onClick={(e) => e.stopPropagation()} // Prevents event propagation
//                 onChange={(e) => handleChange(e.target.value, index)}
//                 onKeyDown={(e) => handleKeyDown(e, index)}
//                 ref={(el) => (inputRefs.current[index] = el)}
//                 className="otp-box"
//               />
//             ))}
//           </div>
//           <div className="otp-rote">
//             <div className="otp-typo-cnt">
//               <div className="otp-get-code">Didn’t get a code?</div>
//               <div
//                 className="otp-resend"
//                 onClick={(e) => {
//                   e.stopPropagation(); // Prevent event propagation
//                   onResendOTP();
//                 }}
//                 style={{
//                   cursor: isLoading1 ? "not-allowed" : "pointer",
//                   opacity: isLoading1 ? "0.6" : "1",
//                 }}
//               >
//                 Resend code
//               </div>
//             </div>
//             <div className="otp-resend  ">Change phone number</div>
//           </div>
//         </div>

   

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             marginBottom: "20px",
//             marginTop: "20px",
//           }}
//         >
//           <button
//             className="set-phone-continue-button"
//             onClick={(e) => {
//               e.stopPropagation(); // Prevent event propagation
//               onVerifyOtpClick();
//             }}
          
//           >
//             Continue <img src={arrowRight} alt="edit" />
//           </button>
          
//         </div>
//         {errorMessage && (
//           <div style={{ color: "red", textAlign: "center", marginBottom: "10px" }}>
//             {errorMessage}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default PhoneSettingsScreen3;




import React, { useState, useRef } from "react";
import arrowRight from "../../../Assets/images/arrow-right.svg";
import { checkOTP } from "../../../Services/settingsServices";
import { resendOTP } from "../../../Services/settingsServices";

function PhoneSettingsScreen3({ onVerifyOTP, onChangePhoneNumber }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false); // Track if button clicked
  const [errorMessage, setErrorMessage] = useState(""); // For error messages

  const length = 6;
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    if (/^[A-Za-z0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData
      .getData("text")
      .split("")
      .slice(0, length);
    const newOtp = [...otp];

    pasteData.forEach((char, i) => {
      if (/^[A-Za-z0-9]$/.test(char)) {
        newOtp[i] = char;
      }
    });

    setOtp(newOtp);

    inputRefs.current[pasteData.length - 1]?.focus();
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const onVerifyOtpClick = async () => {
    if (isButtonClicked) return;
    setIsButtonClicked(true);
    setIsLoading(true);
    const countryCode = sessionStorage.getItem("newcountryCode");
    const contact = sessionStorage.getItem("newContact");

    try {
      const response = await checkOTP({
        contact: contact,
        country_code: countryCode,
        otp: parseInt(otp.join(""), 10),
      });

      if (response.status === "success") {
        onVerifyOTP();
        sessionStorage.removeItem("newcountryCode");
        sessionStorage.removeItem("newContact");
      } else {
        setErrorMessage("Invalid OTP.");
      }
    } catch (error) {
      setErrorMessage("Error verifying OTP. Please try again.");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onResendOTP = async () => {
    setIsLoading1(true);
    const countryCode = sessionStorage.getItem("newcountryCode");
    const contact = sessionStorage.getItem("newContact");
    try {
      const response = await resendOTP({
        country_code: countryCode,
        contact: contact,
      });
      if (response.status === "success") {
        console.log("OTP sent successfully");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading1(false);
    }
  };

  return (
    <div className="set-phone-modal-container">
      <div>
        <div className="set-phone-modal-head">Verify your mobile number</div>
        <div className="set-phone-modal-support">
          We sent a six-digit confirmation code to your number. It will expire
          in 30 minutes, enter it soon! Keep the window open while checking for
          your code.
        </div>
      </div>
      <div className="set-phone-int-cnt">
        <div className="otp-verifier">
          <div className="otp-container" onPaste={handlePaste}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
                className="otp-box"
              />
            ))}
          </div>
          <div className="otp-rote">
            <div className="otp-typo-cnt">
              <div className="otp-get-code">Didn’t get a code?</div>
              <div
                className="otp-resend"
                onClick={(e) => {
                  e.stopPropagation();
                  onResendOTP();
                }}
                style={{
                  cursor: isLoading1 ? "not-allowed" : "pointer",
                  opacity: isLoading1 ? "0.6" : "1",
                }}
              >
                Resend code
              </div>
            </div>
            <div
              className="otp-resend"
              style={{cursor: "pointer"}}
              onClick={(e) => {
                e.stopPropagation();
                onChangePhoneNumber(); // Trigger change phone number function
              }}
            >
              Change phone number
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button
            className="set-phone-continue-button"
            onClick={(e) => {
              e.stopPropagation();
              onVerifyOtpClick();
            }}
          >
            Continue <img src={arrowRight} alt="edit" />
          </button>
        </div>
        {errorMessage && (
          <div style={{ color: "red", textAlign: "center", marginBottom: "10px" }}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default PhoneSettingsScreen3;

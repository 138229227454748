import React from 'react'
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import { ReactComponent as ICS } from "../../../../../Assets/icon/ics-file-format.svg";
import { ReactComponent as CloseIcon } from "../../../../../Assets/icon/close.svg";

const AddToCalendar = ({onClose, hideCloseIcon = false, setPage, selectedSession}) => {

    const handleAddToCalendar = () => {
        if(selectedSession === 'group') {
            setPage('sendInvite');
        } else {
            onClose();
        }
    }

  return (
    <div className='add-session-container'>
        <div className='add-session-header'>
            <p>Add your session to your calendar</p>
            {!hideCloseIcon && <CloseIcon style={{cursor:'pointer'}} onClick={onClose}/>}
        </div>
        <div className="buttons-container">
            <div className="schedule-button" onClick={handleAddToCalendar}>
                <FcGoogle size={24} />
                Google
            </div>
            <div className="schedule-button">
                <FaApple size={24} />
                Apple
            </div>
            <div className="schedule-button">
                <ICS size={24} />
                ICS File
            </div>
        </div>
    </div>
  )
}

export default AddToCalendar
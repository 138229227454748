import React from 'react';
import './style.css';

const Card = ({ number, heading, text, gradientColor}) => {
  return (
    <div 
      className="about-aris-card"
      style={{ background: gradientColor }}
    >
      <div className="about-aris-card-number">
        {number}
      </div>
      <h2 className="about-aris-card-heading">
        {heading}
      </h2>
      <p className="about-aris-card-text">
        {text}
      </p>
    </div>
  );
};

export default Card;
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import * as d3 from "d3";

const RadarChartManipulator = ({ text = "" }) => {
  const chartRef = useRef();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [option, setOption] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const determineOption = () => {
      const textLower = text.toLowerCase();
      const optionsToShow = [];

      if (textLower.includes("big picture")) optionsToShow.push(1);
      if (textLower.includes("people")) optionsToShow.push(2);
      if (textLower.includes("detail")) optionsToShow.push(3);
      if (textLower.includes("results")) optionsToShow.push(4);

      if (optionsToShow.length > 0) {
        let currentIndex = 0;
        setOption(optionsToShow[currentIndex]);

        if (optionsToShow.length > 1) {
          const timeout = setTimeout(() => {
            currentIndex += 1;
            setOption(optionsToShow[currentIndex]);
          }, 3000);

          return () => clearTimeout(timeout);
        }
      }
    };

    determineOption();
  }, [text]);

  const drawX = (svg, width, height) => {
    // Draw the X lines extending to full width/height
    svg
      .append("line")
      .attr("x1", -width / 2)
      .attr("y1", -height / 2)
      .attr("x2", width / 2)
      .attr("y2", height / 2)
      .attr("stroke", "white")
      .attr("stroke-width", 0.5);

    svg
      .append("line")
      .attr("x1", -width / 2)
      .attr("y1", height / 2)
      .attr("x2", width / 2)
      .attr("y2", -height / 2)
      .attr("stroke", "white")
      .attr("stroke-width", 0.5);
  };

  const animateVShape = (option, svg, rScale) => {
    const createVPath = (points) => {
      const { startX, startY, point1X, point1Y, point2X, point2Y } = points;
      return `M ${startX} ${startY}
            L ${point1X} ${point1Y}
            L ${point2X} ${point2Y}
            Z`;
    };

    const applyFillAnimation = (clipPath) => {
      clipPath
        .transition()
        .duration(1000)
        .ease(d3.easeCubicInOut)
        .attr("transform", "scale(1, 1)"); // Animate to full height
    };

    const centerX = 0;
    const centerY = 0;
    const topY = -rScale(1);
    const bottomY = rScale(1);
    const leftX = -rScale(1);
    const rightX = rScale(1);

    if (option === 1) {
      const vPath = createVPath({
        startX: centerX,
        startY: centerY,
        point1X: leftX,
        point1Y: topY,
        point2X: rightX,
        point2Y: topY,
      });

      const clipPath = svg
        .append("clipPath")
        .attr("id", "v-clip")
        .append("path")
        .attr("d", vPath)
        .attr("transform", "scale(1, 0)"); // Start collapsed

      svg
        .append("path")
        .attr("d", vPath)
        .attr("clip-path", "url(#v-clip)")
        .attr("fill", "white")
        .attr("fill-opacity", 0.8);

      applyFillAnimation(clipPath);
    } else if (option === 2) {
      // V shape filling right side
      const vPath = createVPath({
        startX: centerX,
        startY: centerY,
        point1X: rightX,
        point1Y: topY,
        point2X: rightX,
        point2Y: bottomY,
      });

      const clipPath = svg
        .append("clipPath")
        .attr("id", "right-clip")
        .append("path")
        .attr("d", vPath)
        .attr("transform", "scale(1, 0)");

      svg
        .append("path")
        .attr("d", vPath)
        .attr("clip-path", "url(#right-clip)")
        .attr("fill", "white")
        .attr("fill-opacity", 0.8);

      applyFillAnimation(clipPath);
    } else if (option === 3) {
      // V shape filling bottom
      const vPath = createVPath({
        startX: centerX,
        startY: centerY,
        point1X: leftX,
        point1Y: bottomY,
        point2X: rightX,
        point2Y: bottomY,
      });

      const clipPath = svg
        .append("clipPath")
        .attr("id", "bottom-clip")
        .append("path")
        .attr("d", vPath)
        .attr("transform", "scale(1, 0)");

      svg
        .append("path")
        .attr("d", vPath)
        .attr("clip-path", "url(#bottom-clip)")
        .attr("fill", "white")
        .attr("fill-opacity", 0.8);

      applyFillAnimation(clipPath);
    } else if (option === 4) {
      // V shape filling left side
      const vPath = createVPath({
        startX: centerX,
        startY: centerY,
        point1X: leftX,
        point1Y: topY,
        point2X: leftX,
        point2Y: bottomY,
      });

      const clipPath = svg
        .append("clipPath")
        .attr("id", "left-clip")
        .append("path")
        .attr("d", vPath)
        .attr("transform", "scale(1, 0)");

      svg
        .append("path")
        .attr("d", vPath)
        .attr("clip-path", "url(#left-clip)")
        .attr("fill", "white")
        .attr("fill-opacity", 0.8);

      applyFillAnimation(clipPath);
    }
  };

  useEffect(() => {
    const width = isMobile
      ? 280
      : screenWidth > 1240
      ? 350
      : screenWidth > 1840
      ? 700
      : 256;
    const height = width;
    const radius = Math.min(width, height) / 2 - 50;

    const rScale = d3
      .scaleLinear()
      .range([0, radius * 2])
      .domain([0, 1]);

    d3.select(chartRef.current).selectAll("*").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Draw background
    svg
      .append("rect")
      .attr("x", -width / 2)
      .attr("y", -height / 2)
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "rgba(0, 0, 0, 0.04)");

    // Draw inner square
    svg
      .append("rect")
      .attr("x", -(width - width * 0.7))
      .attr("y", -(height - height * 0.7))
      .attr("width", width * 0.625)
      .attr("height", height * 0.625)
      .attr("fill", "rgba(0, 0, 0, 0.10)");

    // Draw the X using the full outer square dimensions
    drawX(svg, width, height);

    // Animate the V shape based on option
    animateVShape(option, svg, rScale);

    const sideText = [
      { text: ["Big Picture", "Oriented"], x: 0, y: -radius * 1 },
      { text: ["People", "Driven"], x: radius * 1, y: 0 },
      { text: ["Detail", "Oriented"], x: 0, y: radius * 1 },
      { text: ["Results", "Driven"], x: -radius * 1, y: 0 },
    ];

    sideText.forEach((side) => {
      const textElement = svg
        .append("text")
        .attr("x", side.x)
        .attr("y", side.y)
        .style("text-anchor", "middle")
        .style("font-size", "1.0625rem")
        .style("font-weight", "600")
        .style("font-family", "Urbanist")
        .style("fill", "rgba(19, 19, 22, 0.80)");

      side.text.forEach((line, i) => {
        textElement
          .append("tspan")
          .attr("x", side.x)
          .attr("dy", i === 0 ? "0em" : "1.2em") // Position lines
          .text(line);
      });
    });
  }, [option, screenWidth]);

  return (
    <div style={{ margin: "80px" }}>
      <div ref={chartRef} />
    </div>
  );
};

export default RadarChartManipulator;

import React from 'react';
import './style.css';

const ProgressBar = ({ step, totalSteps }) => {
  return (
    <div className="signup-progress-bar">
      {/* Dynamically generate steps based on the totalSteps */}
      {[...Array(totalSteps)].map((_, index) => (
        <div
          key={index}
          className={`progress-step ${step >= index + 1 ? 'active' : ''}`}
        >
          <span>{index + 1}</span> {/* Display the step number */}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;

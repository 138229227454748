// import React, { useState } from "react";
// import { ReactComponent as DownShowMore } from "../../Assets/images/chevron-down.svg";
// import { ReactComponent as UpShowMore } from "../../Assets/images/chevron-up.svg";
// function ShowMoreLess({
//   primarHeading,
//   primaryData,
//   type = 1,
//   secondaryData,
//   textedData,
// }) {
//   const [showMore1, setshowMore1] = useState(false);
//   const showMore1Click = () => {
//     setshowMore1((prev) => !prev);
//   };

//   return (
//     <div className="show-more-less-hero">
//       <div className="personality-summary ">
//         <div className="summary-head show-more-less-heading">
//           {primarHeading}
//         </div>
//         {showMore1 && type === 1 && (
//           <div className="summary-txt">
//             {Object.entries(primaryData)?.map(([key, value], index) => (
//               <div className="inside-show-less-cntr" key={index}>
//                 <div className="show-less-in-head">{key}</div>

//                 <div className="show-less-in">{value}</div>
//               </div>
//             ))}
//             {/* {primaryData?.summary} */}
//           </div>
//         )}

//         {showMore1 && type === 2 && (
//           <div className="summary-txt">
//             {secondaryData?.split("\n")?.map((_list, index) => (
//               <li key={index}>{_list}</li>
//             ))}
//           </div>
//         )}

//         {showMore1 && type === 3 && (
//           <div className="summary-txt">{textedData}</div>
//         )}
//       </div>
//       <div className="potraits-show-more-container" onClick={showMore1Click}>
//         {showMore1 ? (
//           <>
//             Show Less <UpShowMore />
//           </>
//         ) : (
//           <>
//             Show More <DownShowMore />
//           </>
//         )}
//       </div>
//     </div>
//   );
// }

// export default ShowMoreLess;

import React, { useState } from "react";
import { ReactComponent as DownShowMore } from "../../Assets/images/chevron-down.svg";
import { ReactComponent as UpShowMore } from "../../Assets/images/chevron-up.svg";
import { ReactComponent as Tick } from "../../Assets/icon/check.svg";
import { ReactComponent as Warning } from "../../Assets/icon/Warning.svg";

function ShowMoreLess({
  primarHeading,
  primaryData,
  underPressureData,
  type = 1,
  descriptionType,
  secondaryData,
  textedData,
}) {
  const [showMore1, setshowMore1] = useState(false);

  const showMore1Click = () => {
    setshowMore1((prev) => !prev);
  };

  // Function to format text handling both \n and \n\n with double trimming
  const formatText = (text) => {
    if (!text) return [];

    // Check if text contains double newlines
    if (text.includes("\n\n")) {
      return text
        .split("\n\n")
        .map((paragraph) => paragraph.trim().trim()) // Apply trim twice for \n\n
        .filter((item) => item !== "")
        .map((paragraph) => {
          // Handle single newlines within paragraphs
          return paragraph
            .split("\n")
            .map((line) => line.trim())
            .filter((line) => line !== "");
        });
    } else {
      // If no double newlines, just split on single newlines
      return [
        text
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line !== ""),
      ];
    }
  };

  // Function to render formatted text
  const renderFormattedText = (text, bullet, descType) => {
    console.log("text", text, descType);
    const formattedText = formatText(text);

    return formattedText.map((paragraph, pIndex) => (
      <div
        key={pIndex}
        className="paragraph-container"
        style={{
          marginBottom: "2rem", // Add significant space between paragraphs
        }}
      >
        {paragraph.map((line, lIndex) => (
          <div
            key={`${pIndex}-${lIndex}`}
            className="line"
            style={{
              marginBottom: lIndex < paragraph.length - 1 ? "0.5rem" : 0, // Add space between lines within paragraph
            }}
          >
            <div className="bullet-line-container">
              {(descType === "BULLETS_POSTIVE" ||
                descType === "BULLETS_NEGATIVE") && <div>{bullet}</div>}
              <div> {line}</div>
            </div>
          </div>
        ))}
      </div>
    ));
  };

  // Function to render content based on key type
  const renderContent = (key, value) => {
    if (key.toLowerCase() === "description") {
      return (
        <div className="inside-show-less-cntr" key={key}>
          <div className="show-less-in">
            {typeof value === "string" ? renderFormattedText(value) : value}
          </div>
        </div>
      );
    } else if (key.toLowerCase() !== "title") {
      return (
        <div className="inside-show-less-cntr" key={key}>
          <div className="show-less-in-head">{key}</div>
          <div className="show-less-in">
            {typeof value === "string" ? renderFormattedText(value) : value}
          </div>
        </div>
      );
    }
    return null;
  };

  console.log("underPressureData", underPressureData, type);

  return (
    <div className="show-more-less-hero">
      <div className="personality-summary">
        <div className="summary-head show-more-less-heading">
          {primarHeading}
        </div>

        {showMore1 && type === 1 && (
          <div className="summary-txt">
            {primaryData?.formattedContent
              ? primaryData.formattedContent.map((item, index) => (
                  <div
                    key={index}
                    className={
                      item.isSectionTitle ? "section-title" : "content-item"
                    }
                  >
                    {item.isSectionTitle ? (
                      <h3>{item.title}</h3>
                    ) : (
                      <div className="description-with-icon">
                        {/* {item.iconType === "tick" && <Tick />}
                        {item.iconType === "warning" && <Warning />} */}
                        {item.description &&
                          renderFormattedText(
                            item.description,
                            item.iconType === "tick" ? <Tick className="portrait-tick"/> : <Warning className="portrait-warning" />,
                            item.descriptionType
                          )}
                      </div>
                    )}
                  </div>
                ))
              : primaryData?.description
              ? renderFormattedText(primaryData.description)
              : ""}
          </div>
        )}

        {showMore1 && type === 2 && (
          <div className="summary-txt">
            {typeof secondaryData === "string" &&
              (descriptionType === "BULLETS_HIPHEN"
                ? renderFormattedText(secondaryData, "•")
                : renderFormattedText(secondaryData))}
          </div>
        )}

        {showMore1 && type === 3 && (
          <div className="summary-txt">
            {typeof textedData === "string" && renderFormattedText(textedData)}
          </div>
        )}

        {showMore1 && type === 4 && (
          <div className="summary-txt">
            {underPressureData?.formattedContent
              ? underPressureData.formattedContent.map((item, index) => (
                  <div
                    key={index}
                    className={
                      item.isSectionTitle ? "section-title" : "content-item"
                    }
                  >
                    {item.isSectionTitle ? (
                      <h3>{item.title}</h3>
                    ) : (
                      <div className="description-with-icon">
                        {/* {item.iconType === "tick" && <Tick />}
                        {item.iconType === "warning" && <Warning />} */}
                        {item.description &&
                          renderFormattedText(
                            item.description,
                            item.iconType === "tick" ? <Tick /> : <Warning />,
                            item.descriptionType
                          )}
                      </div>
                    )}
                  </div>
                ))
              : underPressureData?.description
              ? renderFormattedText(underPressureData.description)
              : ""}
          </div>
        )}
      </div>
      <div className="potraits-show-more-container" onClick={showMore1Click}>
        {showMore1 ? (
          <>
            Show Less <UpShowMore />
          </>
        ) : (
          <>
            Show More <DownShowMore />
          </>
        )}
      </div>
    </div>
  );
}

export default ShowMoreLess;

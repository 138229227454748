import { axiosAssessmentInstance } from "../AxiosConfiguration";

export const sendMessage = async (msg) => {
  try {
    const response = await axiosAssessmentInstance.post(
      `/api/static/messages`,
      { message_text: msg }
    );
    return response?.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return error;
  }
};

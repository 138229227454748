import React, { useEffect, useState } from "react";

function StrengthsBlindSpots({ arr, isStrength = true, isStop }) {
  const [visibleWords, setVisibleWords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // Tracks which word to show

  useEffect(() => {
    // Reset when `arr` changes
    // setVisibleWords([]);
    setCurrentIndex(0);
    console.log("is paused", isStop);
    if (arr.length > 0) {
      const interval = setInterval(() => {
        if (isStop) {
          return;
        }
        setVisibleWords((prevWords) => {
          const nextIndex = prevWords.length;
          if (nextIndex < arr.length) {
            return [...prevWords, arr[nextIndex]];
          } else {
            clearInterval(interval); // Stop when all words are shown
            return prevWords;
          }
        });
      }, 2000);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [isStop]);
  console.log(arr, "currentVisuals2222222");
  return (
    <div className="as-strength-blind-hero-wrapper">
      <div className="as-strength-txt">
        {isStrength ? "Your Strengths" : "Blindspots"}
      </div>
      <div className="as-strength-wrapper">
        {visibleWords.map((word, index) => (
          <div
            className={`as-strength-list ${!isStrength ? "as-blind" : ""}`}
            key={index}
          >
            {word}
          </div>
        ))}
      </div>
    </div>
  );
}

export default StrengthsBlindSpots;

import React from "react";

function Personality10() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 107 107"
      fill="none"
    >
      <g clip-path="url(#clip0_2161_30195)">
        <path
          d="M20.8457 17.8652L99.2644 21.1799L76.9747 73.725L20.8457 99.9158V17.8652Z"
          fill="#31C5AB"
        />
        <path
          d="M20.8457 17.8652L99.2644 21.1799M20.8457 17.8652V99.9158M20.8457 17.8652L76.9747 73.725M99.2644 21.1799L76.9747 73.725M99.2644 21.1799L20.8457 99.9158M76.9747 73.725L20.8457 99.9158"
          stroke="white"
          stroke-opacity="0.7"
          stroke-width="0.211394"
          stroke-miterlimit="10"
        />
        <path
          d="M20.8457 99.9846L76.8818 73.6272L20.8457 17.7969V99.9846Z"
          fill="#4FE3D3"
        />
        <g opacity="0.6" filter="url(#filter0_f_2161_30195)">
          <path
            d="M22.1373 6.68262L24.7743 14.7864H33.3078L26.4041 19.7948L29.0411 27.8986L22.1373 22.8902L15.2336 27.8986L17.8706 19.7948L10.9668 14.7864H19.5003L22.1373 6.68262Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter1_f_2161_30195)">
          <path
            d="M41.9753 31.106L47.838 49.1346H66.81L51.4613 60.2769L57.324 78.3056L41.9753 67.1633L26.6266 78.3056L32.4893 60.2769L17.1406 49.1346H36.1126L41.9753 31.106Z"
            fill="white"
            fill-opacity="0.4"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <g filter="url(#filter2_f_2161_30195)">
          <path
            d="M83.2726 27.9492L85.4095 34.5324H92.3246L86.7302 38.601L88.867 45.1841L83.2726 41.1155L77.6782 45.1841L79.8151 38.601L74.2207 34.5324H81.1358L83.2726 27.9492Z"
            fill="white"
          >
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_2161_30195"
          x="3.28155"
          y="-1.00263"
          width="37.7123"
          height="36.5863"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30195"
          />
        </filter>
        <filter
          id="filter1_f_2161_30195"
          x="-1.53713"
          y="12.4282"
          width="87.0254"
          height="84.5552"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="9.33888"
            result="effect1_foregroundBlur_2161_30195"
          />
        </filter>
        <filter
          id="filter2_f_2161_30195"
          x="66.5355"
          y="20.264"
          width="33.474"
          height="32.6054"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3.84262"
            result="effect1_foregroundBlur_2161_30195"
          />
        </filter>
        <clipPath id="clip0_2161_30195">
          <rect
            width="105.697"
            height="105.697"
            fill="white"
            transform="translate(0.609375 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Personality10;

import React, { useState, useEffect } from 'react';
import './style.css';
import '../../App.css';
import nextbtn from '../../Assets/images/arrow-right-blue.png';
import PasswordInput from '../../Components/PasswordInput/index.jsx';
import { createPassword } from '../../Services/auth.js';
import checkmark from '../../Assets/images/check-circle.svg';
import NavigationButton from "../../Components/NavigationButton";

const PasswordPage = ({ oobCode, onNext, onBack }) => {
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkError, setLinkError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    hasMinLength: false,
    hasNumber: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialChar: false,
  });

  useEffect(() => {
    if (!oobCode) {
      setLinkError('Invalid link. Please check your email for the correct link.');
    }
  }, [oobCode]);

  const validatePassword = (value) => {
    const newCriteria = {
      hasMinLength: value.length >= 8,
      hasNumber: /\d/.test(value),
      hasUpperCase: /[A-Z]/.test(value),
      hasLowerCase: /[a-z]/.test(value),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    };

    setPasswordCriteria(newCriteria);
    const isValid = Object.values(newCriteria).every(Boolean);
    setIsPasswordValid(isValid);
    
    return isValid;
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setErrorMessage('');
    setSubmitted(false);
    setIsSubmitting(false);
    validatePassword(value);
  };

  const handlePasswordSubmit = async () => {
    if (isSubmitting) return; // Prevent multiple submissions
    
    setSubmitted(true);
    setIsSubmitting(true);
    
    const isValid = validatePassword(password);

    if (isValid) {
      try {
        const response = await createPassword(oobCode, password);
        
        if (response?.payload?.idToken) {
          localStorage.setItem('idtoken', response.payload.idToken);
          onNext();
        } else {
          console.warn('Response does not contain expected payload structure:', response);
          setErrorMessage('Unexpected response format. Please try again.');
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Detailed error:', error);
        if (error.response?.status === 422) {
          setErrorMessage(
            error.response.data?.error || 
            'Invalid password format or OOB code. Please try again.'
          );
        } else {
          setErrorMessage('Failed to create password. Please try again later.');
        }
        setIsSubmitting(false);
      }
    } else {
      const missingCriteria = [];
      if (!passwordCriteria.hasMinLength) missingCriteria.push('be at least 8 characters long');
      if (!passwordCriteria.hasNumber) missingCriteria.push('include a number');
      if (!passwordCriteria.hasUpperCase) missingCriteria.push('include an uppercase letter');
      if (!passwordCriteria.hasLowerCase) missingCriteria.push('include a lowercase letter');
      if (!passwordCriteria.hasSpecialChar) missingCriteria.push('include a special character');
      
      setErrorMessage(`Password must: ${missingCriteria.join(', ')}.`);
      setIsSubmitting(false);
    }
  };

  return (
    <div className='Fullpage-container-email'>
      <div className="email-container">
        <div className='password-header'>
          <NavigationButton
            onClick={onBack}
            direction="left"
            label="Back"
            className="back-button"
          />
          <div className="email-header-container">
            <h2 className="heading-h2">Create Password</h2>
          </div>
          <p className="sign-up-para-title">Password</p>
          <PasswordInput 
            value={password}
            placeholder={'Create your password'}
            onChange={handlePasswordChange}
            error={errorMessage}
            submitted={submitted}
            autoFocus={true}
          />
          
          {linkError && <p className='signup-error'>{linkError}</p>}

          {submitted && errorMessage && (
            <p style={{ color: 'red', marginTop: '5px', fontFamily: "Urbanist" }}>
              {errorMessage}
            </p>
          )}

          <div className="password-criteria">
            <div className={`criteria-item ${passwordCriteria.hasMinLength ? 'valid' : ''}`}>
              {passwordCriteria.hasMinLength && <img src={checkmark} alt="check" className="signup-checkmark" />}
              Must be at least 8 characters.
            </div>
            <div className={`criteria-item ${passwordCriteria.hasNumber ? 'valid' : ''}`}>
              {passwordCriteria.hasNumber && <img src={checkmark} alt="check" className="signup-checkmark" />}
              Include a number
            </div>
            <div className={`criteria-item ${passwordCriteria.hasUpperCase ? 'valid' : ''}`}>
              {passwordCriteria.hasUpperCase && <img src={checkmark} alt="check" className="signup-checkmark" />}
              Include an uppercase letter
            </div>
            <div className={`criteria-item ${passwordCriteria.hasLowerCase ? 'valid' : ''}`}>
              {passwordCriteria.hasLowerCase && <img src={checkmark} alt="check" className="signup-checkmark" />}
              Include a lowercase letter
            </div>
            <div className={`criteria-item ${passwordCriteria.hasSpecialChar ? 'valid' : ''}`}>
              {passwordCriteria.hasSpecialChar && <img src={checkmark} alt="check" className="signup-checkmark" />}
              Include a special character
            </div>
          </div>
        </div>
        
        <div>   
          <button 
            className={`submit-button ${isPasswordValid ? 'valid' : 'invalid'} ${isSubmitting ? 'submitting' : ''}`}
            onClick={handlePasswordSubmit}
            disabled={!isPasswordValid || isSubmitting}
            style={{ pointerEvents: isSubmitting ? 'none' : 'auto' }}
          >
            Continue
            <img src={nextbtn} alt='nxtbtn' className='nxtbtn' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordPage;